import React from 'react';
import { useSnackbar } from 'notistack';

import { playbooksActions } from './store';

export default function LoadPlaybooks() {
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        const load = async () => {
            try {
                await playbooksActions.load();
            } catch (e) {
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            }
        };

        load();
    }, [enqueueSnackbar]);

    return null;
}
