import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import chunk from 'lodash/chunk';
import clamp from 'lodash/clamp';

import { ReactComponent as ArrowLeftIcon } from 'images/icons/path_arrow_left.svg';
import { ReactComponent as ArrowRightIcon } from 'images/icons/path_arrow_right.svg';
import { Row, Column, Copy } from 'ui';
import { H3Headline } from 'components/ui/Headline';
import UIButton from 'components/ui/Button';
import { useHistory } from 'react-router';
import TextButton from 'components/ui/Button/TextButton';
import { SpinnerOverlay } from 'components/Spinner';
import PlaybooksList from './PlaybooksList';

const Container = styled(Column)`
    position: relative;
    width: 455px;

    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    border-radius: 8px;

    padding: 24px;
    box-sizing: border-box;
`;

const Title = styled(H3Headline)`
    font-weight: 700;
`;

const SubHeading = styled(Copy)`
    font-size: 12px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.74)};
`;

const ArrowBack = styled(ArrowLeftIcon)``;
const ArrowForward = styled(ArrowRightIcon)``;
const ArrowControls = styled.div`
    display: flex;
    width: 79px;
    font-size: 12px;

    ${ArrowBack}, ${ArrowForward} {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack)};
        height: auto;
        width: 4px;
        cursor: pointer;
        padding: 2px 10px;
    }
`;

const Button = styled(UIButton)``;
const Controls = styled(Row)`
    width: auto;
    align-items: center;

    ${Button} {
        height: 21px;
        width: fit-content;

        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
        background-color: #6236a3;

        padding: 0 10px;
        border: none;
        border-radius: 6px;

        font-size: 12px;
        font-weight: 400;
    }
`;

type TPagedPlaybooksProps = {
    playbooks: BizlyAPI.Complete.Playbook[];
    title?: string;
    subheading?: string;
    hideMenu?: boolean;
    showAll?: boolean;
    onShowAll?: () => void;
    onBack?: () => void;
    isSummary?: boolean;
    isLoading?: boolean;
};

const MAX_ENTRIES = 5;

const BACK = -1,
    FORWARD = 1;

type TPageDirection = typeof BACK | typeof FORWARD;

export default function PagedPlaybooks({
    playbooks,
    title,
    subheading,
    showAll,
    onShowAll,
    onBack,
    isSummary,
    isLoading,
}: TPagedPlaybooksProps) {
    const [page, setPage] = useState<number>(1);
    const history = useHistory();

    const totalPages = Math.ceil(playbooks.length / MAX_ENTRIES);

    const goToPlaybooks = () => history.push(`/playbooks`);

    const handlePageChange = (direction: TPageDirection) =>
        setPage(prevPage => clamp(prevPage + direction, 1, totalPages));

    const playbooksChunk = useMemo(() => chunk(playbooks, MAX_ENTRIES)[page - 1], [playbooks, page]);

    return (
        <Container itemSpacing="small">
            {(title || onShowAll) && (
                <Row justifyContent="space-between" alignItems="flex-start">
                    <Column>
                        {title && <Title>{title}</Title>}
                        {subheading && <SubHeading>{subheading}</SubHeading>}
                    </Column>
                    <Controls itemSpacing="xsmall">
                        {!isSummary && !showAll && playbooks.length > MAX_ENTRIES && (
                            <>
                                <ArrowControls>
                                    <ArrowBack onClick={() => handlePageChange(BACK)} />
                                    {page} / {totalPages}
                                    <ArrowForward onClick={() => handlePageChange(FORWARD)} />
                                </ArrowControls>
                                {onShowAll && (
                                    <Button secondary small onClick={onShowAll}>
                                        Show All
                                    </Button>
                                )}
                            </>
                        )}
                        {!isSummary && showAll && onBack && (
                            <Button onClick={onBack} secondary small>
                                Back
                            </Button>
                        )}
                    </Controls>
                </Row>
            )}

            {!!playbooks.length && <PlaybooksList playbooks={showAll ? playbooks : playbooksChunk} />}

            {isSummary && (
                <Row justifyContent="center">
                    <TextButton onClick={goToPlaybooks} asLink secondary>
                        View all playbooks
                    </TextButton>
                </Row>
            )}
            {isLoading && <SpinnerOverlay transparent delay />}
        </Container>
    );
}
