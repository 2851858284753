import React from 'react';
import styled from 'styled-components/macro';

import { H5Headline } from 'components/ui/Headline';
import { Column } from 'ui';
import { TProposalForm } from './types';

import { getProposalFormStatus, getStatusAttributes, TAction } from './statusUtils';

const Width100Column = styled(Column)`
    width: 100%;
`;

const Copy = styled.div`
    font-size: 18px;
    line-height: 1.5;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkerGrey)};
`;

export default function ProposalFormStatus({
    userChangedStatus,
    action,
    ...rest
}: { userChangedStatus?: boolean; action?: TAction } & Partial<TProposalForm>) {
    const status = getProposalFormStatus(rest);
    const statusAttributes = getStatusAttributes(status, action, userChangedStatus);

    return (
        <Width100Column itemSpacing="small">
            <H5Headline>{statusAttributes.heading(rest)}</H5Headline>
            <Copy>{statusAttributes.copy(rest)}</Copy>
        </Width100Column>
    );
}
