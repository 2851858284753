import React, { useState, useCallback, useMemo, forwardRef } from 'react';

import { useUser } from '../../providers/user';

import Button from 'components/ui/Button';
import { Row } from '../../ui';

import VenueSearchFilters, { TFilterValue } from './VenueSearchFilters';
import { getTokens } from './TokenUtils';
import Tokenizer from '../Tokenizer';
import styled from 'styled-components';

const FiltersButtonButton = styled(Button)`
    margin-right: 16px;
    white-space: nowrap;
`;

type TFiltersButton = {
    toggleFiltersMenu: () => void;
    filterCount: number;
};
const FiltersButton = forwardRef(
    ({ toggleFiltersMenu, filterCount }: TFiltersButton, ref: React.Ref<HTMLButtonElement>) => {
        const hasFilters = filterCount > 0;
        return (
            <FiltersButtonButton secondary={!hasFilters} onClick={toggleFiltersMenu} ref={ref}>
                {hasFilters ? `Filters ${filterCount}` : 'Filters'}
            </FiltersButtonButton>
        );
    }
);

type TFiltersRow = {
    filters: TFilterValue;
    setFilters: (newFilters: TFilterValue) => void;
};

export default function FiltersRow({ filters, setFilters }: TFiltersRow) {
    const filtersButtonRef = React.useRef<HTMLButtonElement>(null);
    const [showFiltersMenu, setShowFiltersMenu] = useState(false);
    const toggleFiltersMenu = useCallback(() => setShowFiltersMenu(prevShowFiltersMenu => !prevShowFiltersMenu), []);
    const { user } = useUser();
    const teamName = useMemo(() => (user.team ? user.team.name : ''), [user.team]);
    const onApply = (newFilters: TFilterValue) => {
        setFilters(newFilters);
        toggleFiltersMenu();
    };
    const tokens = useMemo(() => getTokens(filters, setFilters, { teamName }), [filters, setFilters, teamName]);

    return (
        <Row style={{ flex: '1 1 0' }}>
            <FiltersButton toggleFiltersMenu={toggleFiltersMenu} filterCount={tokens.length} ref={filtersButtonRef} />
            <VenueSearchFilters
                filters={filters}
                onApply={onApply}
                anchor={filtersButtonRef.current}
                open={showFiltersMenu}
                onClose={toggleFiltersMenu}
                teamName={teamName}
            />
            <Tokenizer tokens={tokens} />
        </Row>
    );
}
