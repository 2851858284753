import React from 'react';

import styled from 'styled-components';

import { format, differenceInMilliseconds, startOfDay, addMilliseconds } from 'date-fns';
import { is12AM } from 'utils/date_util';
import { msPassedInDay } from '../utils';

import { EventWrapperProps } from 'react-big-calendar';
import ScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed';

import colorFns from 'colorFns';

import { Row, Column, TruncatingSingleLineCopy, Copy } from 'ui';
import { Tooltip } from '@material-ui/core';
import { SpinnerOverlay } from 'components/Spinner';
import { getColorPickerColor } from 'components/Form';
import { ReactComponent as CircleIconSVG } from 'images/icons/circle.svg';

import { OnlySingleLine } from 'shared';

import { LABEL_OFFSET, HEADER_BORDER, BLOCK_PADDING } from '../styles';

const BLOCK_TIME_FORMAT = 'h:mmaa';

const Circle = styled(CircleIconSVG)<{ color: Themed.Color }>`
    min-width: 15px;
    min-height: 15px;
    color: ${({ color }) => color};
`;

const CirclesRow = styled(Row)`
    overflow: hidden;
`;

const SimpleCol = styled(Column)`
    max-width: 100%;
`;

const Block = styled(Row)<{ small?: boolean; selected?: boolean; simple?: boolean }>`
    position: absolute;
    margin-left: -1px;

    background-color: ${colorFns.listItemBackground};
    border: 1px solid ${colorFns.softBorder};
    border-radius: 0px;

    box-sizing: border-box;
    margin-top: 0.5px;

    padding: ${BLOCK_PADDING}px;
    ${({ small }) =>
        small
            ? `
    padding: 0 ${BLOCK_PADDING}px;
    align-items: center;
    `
            : ''}
    overflow: hidden;

    ${({ selected, theme }) =>
        selected
            ? `
    box-shadow: -5px 5px 10px 0px ${colorFns.pureBlack.alpha(0.15)({ theme })};
        `
            : ''}

    ${({ simple, ...props }) =>
        simple
            ? `
        background-color: ${colorFns.lightGrey(props)};
        border: 1px solid ${colorFns.grey(props)};
    `
            : ''}
`;

const BlockLineColor = styled.div<{ fill: string }>`
    display: block;
    height: 100%;
    width: 6px;

    position: absolute;
    left: 0;
    top: 0;

    background-color: ${({ fill }) => fill};
`;

const ScrollerWrapper = styled.div<{ draft?: boolean }>`
    position: absolute;
    ${({ draft }) => (!draft ? `margin-top: ${(HEADER_BORDER + LABEL_OFFSET + BLOCK_PADDING) * -1}px;` : '')}
`;

const DescriptionCol = styled(Column)`
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    min-width: 0;
`;

const BlockTimes = styled(TruncatingSingleLineCopy)`
    font-size: 12px;
    font-weight: 700;
`;

const BlockName = styled(Copy)`
    ${OnlySingleLine}
    color: ${colorFns.pureWhite};
    line-height: 24px;
    font-size:12px;
    margin-top: -3px;
`;

const BlockNameSimple = styled(Copy)`
    ${OnlySingleLine}
    color: ${colorFns.darkGrey};
    line-height: 24px;
    margin-top: -3px;
`;

const getTopAndHeightPercent = (
    {
        start,
        end,
        continuesEarlier,
        continuesLater,
    }: {
        start: Date;
        end: Date;
        continuesEarlier?: boolean;
        continuesLater?: boolean;
    },
    min?: string | Date,
    max?: string | Date
) => {
    const msInDay = (max ? msPassedInDay(max) : 24 * 60 * 60 * 1000) - msPassedInDay(min);
    const msElapsedInDay = (date: Date) =>
        differenceInMilliseconds(date, addMilliseconds(startOfDay(date), msPassedInDay(min)));

    const top = 100 * (continuesEarlier ? 0 : msElapsedInDay(start) / msInDay);
    const bottom = 100 * (continuesLater ? 1 : msElapsedInDay(end) / msInDay);
    const height = bottom - top;

    return { top, height };
};

type TBaseBlock<TId extends number | string> = {
    id: TId;
    name?: string;

    start: Date;
    end: Date;

    colorId?: number | null;

    loading?: boolean;
};

type TEventBlockProps = {
    draft?: boolean;
    selected?: boolean;
    min?: string | Date;
    max?: string | Date;

    blockRef?: React.MutableRefObject<HTMLDivElement | null>;
    onMouseDown?: React.MouseEventHandler<HTMLElement>;

    clickable?: boolean;
    simpleColors?: { color: Themed.Color; label?: string }[];
};

type TEventBlock<TId extends number | string, TBlock extends TBaseBlock<TId> = TBaseBlock<TId>> = EventWrapperProps<
    TBlock
> &
    TEventBlockProps;

export default function EventBlock<TId extends number | string, TBlock extends TBaseBlock<TId>>({
    style,
    event,
    continuesEarlier,
    continuesLater,
    draft,
    selected,
    clickable,
    simpleColors,
    min,
    max,

    blockRef,
    ...rest
}: TEventBlock<TId, TBlock>) {
    if (continuesEarlier && !continuesLater && is12AM(event.end)) return null;

    const { xOffset, width, color, backgroundColor, ...restOfStyle } = style || {};

    const { top, height } = getTopAndHeightPercent({ ...event, continuesEarlier, continuesLater }, min, max);

    const styleProp = {
        ...restOfStyle,

        top: top + '%',
        left: xOffset + '%',
        height: height + '%',
        width: width + '%',
        // prevent overflow past 100%
        maxHeight: `calc(${100 - top}% - ${LABEL_OFFSET}px)`,

        cursor: clickable ? 'pointer' : 'unset',
    };

    const small = height < 4;
    const times = [format(event.start, BLOCK_TIME_FORMAT), format(event.end, BLOCK_TIME_FORMAT)].join(' to ');

    const showTimes = !small || !event.name;
    const showName = !small || event.name;

    if (simpleColors) {
        const tooltipTitle =
            simpleColors.length >= 1 ? (
                <Column>
                    {event.name && <Copy>{event.name}</Copy>}
                    {simpleColors.map(({ color, label }) => (
                        <Row key={color} itemSpacing="xsmall">
                            <Circle color={color} />
                            {label}
                        </Row>
                    ))}
                </Column>
            ) : (
                ''
            );

        const blockContent =
            simpleColors.length >= 1 ? (
                <CirclesRow>
                    {simpleColors.map(({ color }) => (
                        <Circle key={color} color={color} />
                    ))}
                </CirclesRow>
            ) : null;

        const block = (
            <Block
                style={styleProp}
                {...rest}
                simple
                small={small}
                selected={selected}
                ref={inst => inst && blockRef && (blockRef.current = inst)}
            >
                <SimpleCol>
                    {height >= 3 && event.name && <BlockNameSimple>{event.name}</BlockNameSimple>}
                    {blockContent}
                </SimpleCol>
            </Block>
        );

        return <Tooltip title={tooltipTitle}>{block}</Tooltip>;
    }

    return (
        <Tooltip title={event.name ?? ''}>
            <Block
                style={styleProp}
                {...rest}
                small={small}
                selected={selected}
                ref={inst => inst && blockRef && (blockRef.current = inst)}
            >
                {selected && (draft ? !continuesEarlier : true) && (
                    <ScrollerWrapper draft={draft}>
                        <ScrollIntoViewIfNeeded
                            options={{
                                behavior: 'smooth',
                                block: draft ? 'center' : 'start',
                                inline: 'center',
                                scrollMode: 'if-needed',
                            }}
                        >
                            <span />
                        </ScrollIntoViewIfNeeded>
                    </ScrollerWrapper>
                )}
                <DescriptionCol itemSpacing="xsmall">
                    {showTimes && <BlockTimes small>{times}</BlockTimes>}
                    {showName && <BlockName>{event.name}</BlockName>}
                </DescriptionCol>
                <BlockLineColor fill={getColorPickerColor(event.colorId ?? null)} />
                {event.loading && <SpinnerOverlay />}
            </Block>
        </Tooltip>
    );
}
