import React from 'react';
import styled from 'styled-components/macro';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';

import { ReactComponent as EditIconSVG } from '../images/icons/pencil.svg';
import { ReactComponent as DeleteIconSVG } from '../images/icons/trash-can.svg';

import { withInteractibleIconStyles } from '../shared';
import { Column, LabeledDropdown, Dropdown, CopyFaded } from '../ui';
import { capitalize, padArray } from '../util';

const EditIcon = withInteractibleIconStyles(EditIconSVG);
const DeleteIcon = withInteractibleIconStyles(DeleteIconSVG);

const Headline = styled.h3`
    text-align: center;
    margin-bottom: 20px;
    font-size: 30px;
`;

const defaultSort = { id: 'all', label: 'view all' };

let placeholderCounter = 0;
const placeholder = headers => {
    let placeholderData = {};
    headers.forEach(h => {
        placeholderData[h.id] = <Chip style={{ width: '100%', height: '29px', opacity: '0.5' }} />;
    });
    return {
        id: `placeholder_${placeholderCounter++}`,
        data: placeholderData,
    };
};

const renderDynamicHeader = ({ headers, availableHeaders, defaultHeader, onHeaderChange, onSelectAll, rows }) => {
    const options = [defaultHeader, ...availableHeaders];
    const selectedRows = rows.filter(r => r.isSelected);
    const isIndeterminate = selectedRows.length > 0 && selectedRows.length < rows.length;
    const isAllSelected = selectedRows.length > 0 && selectedRows.length === rows.length;

    return (
        <>
            <TableCell key="checkbox" padding="checkbox">
                {onSelectAll && (
                    <Checkbox onClick={onSelectAll} checked={isAllSelected} indeterminate={isIndeterminate} />
                )}
            </TableCell>
            {headers.map((header, idx) => (
                //eslint-disable-next-line react/no-array-index-key
                <TableCell key={idx}>
                    <LabeledDropdown
                        variant="outlined"
                        value={header.id}
                        label={defaultHeader.label}
                        onChange={newValue => onHeaderChange(idx, newValue.target.value)}
                        width={150}
                    >
                        {options.map(o => (
                            <MenuItem key={o.id} value={o.id}>
                                {o.id === '' ? <CopyFaded>{o.label}</CopyFaded> : o.label}
                            </MenuItem>
                        ))}
                    </LabeledDropdown>
                </TableCell>
            ))}
        </>
    );
};

const renderSortOptions = ({ sort, sortOptions, onSortChange }) => (
    <Dropdown onChange={onSortChange} value={sort || defaultSort.id} width={150}>
        {[defaultSort].concat(sortOptions).map(so => (
            <MenuItem key={so.id} value={so.id}>
                {capitalize(so.label)}
            </MenuItem>
        ))}
    </Dropdown>
);

const renderTableRowData = (headerId, rowId, rowData) => (
    <TableCell align="left" key={`${headerId}_${rowId}`}>
        {rowData[headerId] || '-'}
    </TableCell>
);

const renderTableRowButtons = ({ rowId, onEdit, onRemove, editable }) => (
    <TableCell>
        {onEdit && <EditIcon style={{ marginRight: '16px' }} onClick={() => onEdit(rowId)} />}
        {onRemove && editable && <DeleteIcon onClick={() => onRemove(rowId)} />}
    </TableCell>
);

const renderTableBody = ({
    rows,
    placeholderLength,
    dynamicHeaders,
    headers,
    onEdit,
    onRemove,
    onSelect,
    editable,
}) => {
    const paddedRows = rows.length === 0 ? padArray(rows, placeholderLength || 0) : rows;

    return paddedRows.map(({ data, id, isSelected } = placeholder(headers)) => (
        <TableRow
            key={id}
            {...(onSelect
                ? {
                      role: 'checkbox',
                      onClick: () => onSelect(id),
                  }
                : {})}
        >
            {onSelect && (
                <TableCell key={`checkbox_${id}`} padding="checkbox">
                    <Checkbox checked={isSelected} />
                </TableCell>
            )}
            {/*
        with dynamic headers, the headers default to blank
        so the headers don't correspond to a particular key like "name" in the input data obj
        the headers and the row data are connected by index
      */}
            {headers.map((h, idx) => {
                /* TODO: The below two lines of code are a workaround.
          The API only sets the "name" attribute of an attendee when
          both "firstName" and "lastName" have been added to the attendee.
          We need to configure the backend API to always set the "name"
          attribute to either "firstName", "lastName", or both, no matter what
          data was present. This is in response to ticket 1021, displaying partial names
        */
                const parsedNameId = (data.firstName && 'firstName') || (data.lastName && 'lastName');
                const headerIndex = h.id === 'name' && !data.name ? parsedNameId : h.id;
                return renderTableRowData(dynamicHeaders ? idx : headerIndex, id, data);
            })}
            {!id.toString().match(/placeholder_[\d]+/) &&
                renderTableRowButtons({ rowId: id, onEdit, onRemove, editable })}
        </TableRow>
    ));
};

export const MaterialTable = ({
    name,
    rows,
    headers,
    dynamicHeaders,
    availableHeaders,
    defaultHeader,
    onHeaderChange,
    onSelect,
    onSelectAll,
    onEdit,
    onRemove,
    onSortChange,
    placeholderLength,
    sortOptions,
    sort,
    editable,
}) => (
    <Column style={{ width: '100%', overflowX: 'scroll' }}>
        {name && <Headline>{name}</Headline>}
        <Table padding="dense">
            <TableHead>
                <TableRow>
                    {dynamicHeaders
                        ? renderDynamicHeader({
                              headers,
                              availableHeaders,
                              defaultHeader,
                              onHeaderChange,
                              onSelectAll,
                              rows,
                          })
                        : headers.map(h => <TableCell key={h.id}>{h.label}</TableCell>)}
                    <TableCell>
                        {sortOptions &&
                            renderSortOptions({
                                sort,
                                sortOptions,
                                onSortChange,
                            })}
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {renderTableBody({
                    rows,
                    placeholderLength,
                    dynamicHeaders,
                    headers,
                    onEdit,
                    onRemove,
                    onSelect,
                    editable,
                })}
            </TableBody>
        </Table>
    </Column>
);

export const MaterialPaperTable = props => (
    <Paper>
        <MaterialTable {...props} />
    </Paper>
);
