import React, { useState, useCallback, useMemo } from 'react';
import { Link, Switch, Route, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import omit from 'lodash/omit';

import { sendMagicLink, isAuthenticationPartialSuccess, isSSOOnlyAccount, loginWithGoogleId, loginWithMSId } from 'api';
import { TAuthProps, TGoogleAuth, TMSAuth, TAuthForm } from './types';
import { emailIsValid } from 'utils';

import ContentCard from 'components/ui/ContentCard';
import SSOView from './SSOView';
import Policies from 'components/ui/Policies';
import LinkSentView from './LinkSentView';
import MSTokenExchange from './MSTokenExchange';

import { SpinnerOverlay } from 'components/Spinner';
import { Copy } from 'ui';
import { H2Headline } from 'components/ui/Headline';
import UITextButton from 'components/ui/Button/TextButton';

import Form from 'components/Form';
import { makeAuthFields, makeSignUpEmailSchema, signUpCompanySchema } from './formSchema';

const SubCopy = styled(Copy)`
    font-size: 16px;
    line-height: 24px;
`;

const TextButton = styled(UITextButton)`
    font-size: inherit;
    font-weight: inherit;
    padding: 0;
`;

const LinkCopy = styled(Link)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
    cursor: pointer;
`;

export default function SignUpPage({
    authFormValue,
    onChange,
    onSignUpSuccess,
}: TAuthProps & { onSignUpSuccess: (user: BizlyAPI.User) => void }) {
    const [isLoading, setIsLoading] = useState(false);
    const [hasNewEmail, setHasNewEmail] = useState(false);
    const [linkIsSent, setLinkIsSent] = useState(false);
    const [SSOLink, setSSOLink] = useState<Nullable<string>>(null);

    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { signInRedirect } = history.location.state || {};

    const onVerifyEmail = useCallback(
        async e => {
            e.preventDefault();

            if (!authFormValue.email || !emailIsValid(authFormValue.email)) {
                return enqueueSnackbar('Please enter a valid email.', {
                    variant: 'error',
                });
            }

            setIsLoading(true);
            try {
                const { success } = await sendMagicLink({ email: authFormValue.email });
                if (success) setLinkIsSent(true);
            } catch (e) {
                if (isAuthenticationPartialSuccess(e)) {
                    return setHasNewEmail(true);
                }

                if (isSSOOnlyAccount(e)) {
                    return setSSOLink(e.raw.ssoLink);
                }

                enqueueSnackbar('Something went wrong, please try again.', {
                    variant: 'error',
                });
            } finally {
                setIsLoading(false);
            }
        },
        [enqueueSnackbar, authFormValue]
    );

    const onAuthSuccess = useCallback(
        async (resp: TGoogleAuth | TMSAuth) => {
            let authFn: (() => Promise<{ user: BizlyAPI.User }>) | undefined = undefined;
            let newUserForm: TAuthForm | undefined = undefined;

            if (resp.client === 'google') {
                if (resp.tokenId === undefined) {
                    return enqueueSnackbar('Something went wrong, please try again.', {
                        variant: 'error',
                    });
                }

                const { tokenId: googleIdToken, profileObj } = resp;
                const email = profileObj?.email;

                if (!email || !googleIdToken) {
                    return enqueueSnackbar('Something went wrong, please try again.', {
                        variant: 'error',
                    });
                }

                authFn = () => loginWithGoogleId({ email, googleIdToken });
                newUserForm = { client: 'google', email, idToken: googleIdToken };
            } else if (resp.client === 'ms') {
                const { idToken, accessToken, email } = resp;

                if (!email || !idToken || !accessToken) {
                    return enqueueSnackbar('Something went wrong, please try again.', {
                        variant: 'error',
                    });
                }
                authFn = () => loginWithMSId({ email, msIdToken: idToken, msAccessToken: accessToken });
                newUserForm = { client: 'ms', email, idToken, accessToken };
            }

            if (!authFn) return;

            setIsLoading(true);
            try {
                const { user } = await authFn();
                if (user?.authToken) onSignUpSuccess(user);
            } catch (e) {
                if (isAuthenticationPartialSuccess(e)) {
                    if (newUserForm) onChange({ value: newUserForm });
                    return setHasNewEmail(true);
                }

                enqueueSnackbar('Something went wrong, please try again.', {
                    variant: 'error',
                });
            } finally {
                setIsLoading(false);
            }
        },
        [enqueueSnackbar, onSignUpSuccess, onChange]
    );

    const onSubmitForm = useCallback(
        async e => {
            e.preventDefault();

            if (!authFormValue.teamName) {
                return enqueueSnackbar('Please enter a company or team name.', {
                    variant: 'error',
                });
            }

            setIsLoading(true);
            try {
                if (authFormValue.email && authFormValue.idToken) {
                    if (authFormValue.client === 'google') {
                        const { user } = await loginWithGoogleId({
                            email: authFormValue.email,
                            googleIdToken: authFormValue.idToken,
                            teamName: authFormValue.teamName,
                        });
                        return onSignUpSuccess(user);
                    } else if (authFormValue.client === 'ms' && authFormValue.accessToken) {
                        const { user } = await loginWithMSId({
                            email: authFormValue.email,
                            msIdToken: authFormValue.idToken,
                            msAccessToken: authFormValue.accessToken,
                            teamName: authFormValue.teamName,
                        });
                        return onSignUpSuccess(user);
                    }
                }

                const { success } = await sendMagicLink(authFormValue);
                if (success) setLinkIsSent(true);
            } catch {
                enqueueSnackbar('Something went wrong, please try again.', {
                    variant: 'error',
                });
            } finally {
                setIsLoading(false);
            }
        },
        [enqueueSnackbar, authFormValue, onSignUpSuccess]
    );

    const onResendLink = async () => {
        if (isLoading) return;

        if (!authFormValue.email || !emailIsValid(authFormValue.email)) {
            return enqueueSnackbar('Please enter a valid email.', {
                variant: 'error',
            });
        }

        setIsLoading(true);
        try {
            const { success } = await sendMagicLink({ email: authFormValue.email });
            if (success) setLinkIsSent(true);
        } catch {
            enqueueSnackbar('Something went wrong, please try again.', {
                variant: 'error',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const onContinueAs = React.useCallback(() => {
        setHasNewEmail(true);
        onChange({ value: { email: authFormValue.preAuthEmail } });
    }, [setHasNewEmail, onChange, authFormValue]);

    const PathToSignIn = (
        <>
            Already have an account? You can <LinkCopy to="/sign-in">sign in here.</LinkCopy>
        </>
    );

    const ResendEmail = (
        <>
            Didn't receive an email? <TextButton onClick={onResendLink}>Resend Email</TextButton>
        </>
    );

    const dynamicHeadline = hasNewEmail
        ? 'Almost done!'
        : signInRedirect
        ? 'Looks like you don’t have a Bizly Account.'
        : 'Welcome to Bizly';
    const dynamicSubCopy = hasNewEmail
        ? 'Enter the name of your company or team.'
        : signInRedirect
        ? 'Enter your email address to create a Bizly account.'
        : 'First, let’s create your account. Please enter your work email address.';

    const authFields = useMemo(
        () =>
            makeAuthFields({
                onVerifyEmail,
                onSubmitForm,
                onGoogleAuthSuccess: onAuthSuccess,
                onMSAuthSuccess: onAuthSuccess,
                onContinueAs: authFormValue.preAuthEmail ? onContinueAs : undefined,
            }),
        [onVerifyEmail, onSubmitForm, onAuthSuccess, onContinueAs, authFormValue]
    );

    const checkEmailSchema = useMemo(
        () =>
            makeSignUpEmailSchema({
                onGoogleAuthSuccess: onAuthSuccess,
                onMSAuthSuccess: onAuthSuccess,
                onContinueAs: authFormValue.preAuthEmail ? onContinueAs : undefined,
            }),
        [onAuthSuccess, authFormValue, onContinueAs]
    );

    const location = useLocation<{ msAuth: TMSAuth }>();
    React.useEffect(() => {
        if (location.state?.msAuth) {
            onAuthSuccess(location.state.msAuth);
            history.replace({ ...history.location, state: omit({ ...location.state }, 'msAuth') });
        }
    }, [location.state, onAuthSuccess, history]);

    const match = useRouteMatch();

    return (
        <Switch>
            <Route path={`${match.path}/msauth`}>
                <ContentCard headlineCopy="Sign Up" footer={PathToSignIn} centerSelf>
                    <MSTokenExchange />
                </ContentCard>
            </Route>
            <Route>
                <ContentCard headlineCopy="Sign Up" footer={!linkIsSent ? PathToSignIn : ResendEmail} centerSelf>
                    {!linkIsSent ? (
                        <>
                            {!SSOLink ? (
                                <>
                                    <H2Headline>{dynamicHeadline}</H2Headline>
                                    <SubCopy>{dynamicSubCopy}</SubCopy>
                                    <Form
                                        fields={authFields}
                                        schema={!hasNewEmail ? checkEmailSchema : signUpCompanySchema}
                                        value={authFormValue}
                                        onChange={onChange}
                                        disabled={isLoading}
                                        isNested
                                    />
                                </>
                            ) : (
                                <SSOView SSOLink={SSOLink} />
                            )}

                            {!hasNewEmail && <Policies prefix="By signing up or logging in" />}
                        </>
                    ) : (
                        <LinkSentView />
                    )}

                    {isLoading && <SpinnerOverlay />}
                </ContentCard>
            </Route>
        </Switch>
    );
}
