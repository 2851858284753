import React from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';

import { InlineRow, Row, Copy, Column, Card } from 'ui';
import { SpinnerOverlay } from 'components/Spinner';
import DeleteConfirmationModal from 'components/DeleteConfirmationModal';

import { ReactComponent as DeleteIconSVG } from 'images/icons/trash-can.svg';
import { ReactComponent as EditIconSVG } from 'images/icons/pencil.svg';
import { withInteractibleIconStyles } from 'shared';
import colorFns from 'colorFns';

const DeleteIcon = styled(withInteractibleIconStyles(DeleteIconSVG)).attrs({ viewBox: '2 4 15 21' })`
    height: 21px;
    width: 21px;
    color: ${colorFns.pureWhite};
    opacity: 0.74;
`;
const EditIcon = styled(withInteractibleIconStyles(EditIconSVG)).attrs({ viewBox: '1.5 2.5 18 21.5' })`
    height: 21px;
    width: 21px;
    color: ${colorFns.pureWhite};
    opacity: 0.74;
`;

const ListItem = styled(Card)`
    margin: 0 !important;
    padding: 12px;
    cursor: pointer;
    background-color: ${colorFns.listItemBackground};
`;

export default function UnschedList({
    meetings,
    loading,
    onClick,
    onDelete,
}: {
    meetings: BizlyAPI.Meeting[];
    loading?: boolean;
    onClick: (meeting: BizlyAPI.Meeting) => void;
    onDelete?: (meeting: BizlyAPI.Meeting) => Promise<void>;
}) {
    const [deleteMeeting, setDeleteMeeting] = React.useState<BizlyAPI.Meeting | undefined>();
    const [deleting, setDeleting] = React.useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const proceed = async (meeting: BizlyAPI.Meeting) => {
        if (!onDelete) return;
        setDeleting(true);
        try {
            await onDelete?.(meeting);
            setDeleteMeeting(undefined);
        } catch (e) {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        } finally {
            setDeleting(false);
        }
    };

    return (
        <Column itemSpacing="default">
            {meetings.map(meeting => (
                <div key={meeting.id}>
                    <ListItem onClick={() => onClick(meeting)}>
                        <Row itemSpacing="default" alignItems="center" justifyContent="space-between">
                            <Column>
                                <Copy>{meeting.name || 'Untitled'}</Copy>
                                <Copy small>{[meeting.createdBy.firstName, meeting.createdBy.lastName].join(' ')}</Copy>
                            </Column>
                            <InlineRow itemSpacing="smallish">
                                <EditIcon />
                                {onDelete && (
                                    <DeleteIcon
                                        onClick={e => {
                                            setDeleteMeeting(meeting);
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                    />
                                )}
                            </InlineRow>
                        </Row>
                    </ListItem>
                </div>
            ))}
            {loading && <SpinnerOverlay transparent clickThrough delay />}
            {deleteMeeting && onDelete && (
                <DeleteConfirmationModal
                    isActive={!!deleteMeeting}
                    loading={deleting}
                    prompt={`Are you sure you want to delete ${deleteMeeting.name ?? 'Untitled'}?`}
                    onProceed={() => proceed(deleteMeeting)}
                    onDismiss={() => setDeleteMeeting(undefined)}
                />
            )}
        </Column>
    );
}
