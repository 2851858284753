import React from 'react';
import styled from 'styled-components';

import usePersistentSnackbar from 'hooks/usePersistentSnackbar';

import { createPlaybookActions, useCreatePlaybook } from './store';

import { Column } from 'ui';
import { LargeHeadline } from 'components/ui/Headline';
import CategoriesField from 'components/Templates/CategoriesField';
import Form from 'components/Form';
import Errors from './components/Errors';

const Container = styled(Column)`
    width: 706px; // TODO: Maybe more dynamic?
`;

const fields = {
    tagIds: {
        type: CategoriesField,
        options: {
            perRow: 3,
            circleIcon: true,
        },
    },
};

const schema = [
    {
        fields: ['tagIds'],
        spacing: false,
    },
];

export type TTagsValue = number[];

export default function TagsForm() {
    const { tagIds, tagsErrors, stepIdx } = useCreatePlaybook();

    const enqueueSnackbar = usePersistentSnackbar([tagsErrors, stepIdx]);
    React.useEffect(() => {
        const hasErrors = Object.values(tagsErrors).some(v => v);
        if (hasErrors) {
            enqueueSnackbar(<Errors errors={tagsErrors} />, { variant: 'error' });
        }
    }, [tagsErrors, enqueueSnackbar]);

    return (
        <Container>
            <LargeHeadline>Select Tags</LargeHeadline>
            <Form fields={fields} schema={schema} value={{ tagIds }} onChange={createPlaybookActions.updateTags} />
        </Container>
    );
}
