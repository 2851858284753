import React, { useState } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';

import { Column as UIColumn } from '../../ui';
import Button from '../ui/Button';

const Column = styled(UIColumn)`
    align-items: center;
`;

const ImageCircle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 104px;
    width: 104px;
    border-radius: 32px;
    margin-bottom: 12px;
    overflow: hidden;

    img {
        height: inherit;
    }
`;

const ImageButton = styled(Button)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.brand)};
    font-size: 11px;
    font-weight: normal;
`;

type ManageAvatarProps = {
    imageUrl: string;
    setStagedImage: (file: File) => void;
};

const ManageAvatar = ({ imageUrl, setStagedImage }: ManageAvatarProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const [stagedURI, setStagedURI] = useState<string | undefined>();

    const handleStagingImage = (files: File[]) => {
        const maxSize = 1024000; // 1024kb
        const file = files[0];

        try {
            if (file.size > maxSize) {
                throw new Error();
            } else if (!['image/jpeg', 'image/png'].includes(file.type)) {
                throw new Error();
            } else {
                const reader = new FileReader();
                reader.onload = e => e.target && typeof e.target.result === 'string' && setStagedURI(e.target.result);
                reader.readAsDataURL(file);
                setStagedImage(file);
            }
        } catch {
            enqueueSnackbar(`Files must be JPEG or PNG and no larger than ${maxSize / 1000}kb`, { variant: 'error' });
        }
    };

    const onDrop = (files: File[]) => handleStagingImage(files);
    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    const { color: inputColor, ...inputProps } = getInputProps();
    const { color: rootColor, ...rootProps } = getRootProps();

    return (
        <Column>
            <ImageCircle>
                <img alt="User avatar" src={stagedURI || imageUrl} />
            </ImageCircle>
            <div {...rootProps}>
                <input {...inputProps} />
                <ImageButton secondary small type="button" onClick={() => null}>
                    {imageUrl.includes('default-avatar')
                        ? 'Upload photo'
                        : 'Update photo' /* TODO: Update this logic once we handle setting the default avatar from the frontend */}
                </ImageButton>
            </div>
        </Column>
    );
};

export default ManageAvatar;
