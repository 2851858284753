import React from 'react';

import styled from 'styled-components/macro';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';

import { Column, PlaceholderIcon, SpacedRow, TruncatingSmallerCopy } from '../../ui';

import useThemedColor from 'hooks/useThemedColor';

const ToggleButton = ({
    children,
    selected,
    ...props
}: {
    children: React.ReactNode;
    selected: boolean;
    readOnly?: boolean;
}) => (
    <Button color="primary" variant="outlined" {...props}>
        {children}
    </Button>
);

const StyledToggleButton = styled(ToggleButton)`
    padding: 20px;
    ${props => props.readOnly && 'pointer-events: none'};
`;

type IconToggleButtonProps = {
    children?: React.ReactNode;
    iconUrl: string;
    label: string;
    onChange: (change: boolean) => void;
    readOnly?: boolean;
    selected: boolean;
    style: object;
    'aria-label': string;
};

const IconToggleButton = ({
    children,
    iconUrl,
    label,
    onChange,
    readOnly,
    selected,
    ...props
}: IconToggleButtonProps) => {
    const { primaryDisplayIcon, darkerGrey } = useThemedColor();

    return (
        <StyledToggleButton selected={selected} readOnly={readOnly} {...props}>
            <Column style={{ width: '100%' }}>
                <SpacedRow onClick={() => onChange(!selected)}>
                    {iconUrl ? (
                        <img
                            alt={`${label}-icon`}
                            src={iconUrl}
                            width="29"
                            height="29"
                            style={{ marginRight: '8px' }}
                        />
                    ) : (
                        <PlaceholderIcon color={primaryDisplayIcon} style={{ marginRight: '8px' }} />
                    )}
                    <TruncatingSmallerCopy style={{ width: '115px' }} $color={darkerGrey}>
                        {label}
                    </TruncatingSmallerCopy>
                    {!readOnly && (
                        <FormControl margin="dense">
                            <Checkbox inputProps={{ 'aria-label': props['aria-label'] }} checked={selected} />
                        </FormControl>
                    )}
                </SpacedRow>
                {children}
            </Column>
        </StyledToggleButton>
    );
};

export default IconToggleButton;
