import React from 'react';

import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import usePrevious from 'hooks/usePrevious';

import {
    Calendar as BigCal,
    dateFnsLocalizer,
    CalendarProps,
    View,
    Components,
    NavigateAction,
} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import {
    format,
    parse,
    getDay,
    startOfDay,
    endOfDay,
    startOfWeek,
    endOfWeek,
    parseISO,
    isBefore,
    isAfter,
} from 'date-fns';
import moment from 'moment';
import { roundEndOfDayto12AM } from 'utils/date_util';

import { Column } from 'ui';
import { Backdrop } from '@material-ui/core';
import colorFns from 'colorFns';

import styles, { BLOCK_Z_INDEX, BACKDROP_Z_INDEX } from './styles';
import Toolbar from './components/Toolbar';
import EventBlock from './components/EventBlock';
import SelectedPopper from './components/SelectedPopper';

import { getScrollToTime, msPassedInDay } from './utils';

const BLOCK_TIME_FORMAT = 'h:mmaa';
const GUTTER_TIME_FORMAT = 'h aa';
const DAY_HEADER_FORMAT = "dd'\n'EEE";

const locales = {
    'en-US': require('date-fns/locale/en-US'),
};
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

const CalCol = styled(Column)<{ view?: View; animateScroll?: boolean }>`
    width: 100%;
    height: 100%;

    ${({ view }) => (view !== 'month' && view !== 'agenda' ? `` : ``)};

    ${styles.NoShadedToday};

    ${styles.Header}
    ${styles.TimeView}
    ${styles.TimeGutter}
    ${styles.WeekColumn}
    ${styles.Slots}
`;

const StyledToolbar = styled(Toolbar)`
    margin-bottom: 32px;
`;

const SelectedBackdrop = styled(Backdrop)`
    background: ${colorFns.pureWhite.alpha(0.5)};
    z-index: ${BACKDROP_Z_INDEX};
`;

const eventWithin = ({ start, end }: { start: Date; end: Date }, minTimeStr: string, maxTimeStr: string) => {
    const mStart = moment(start)
        .utcOffset(moment().utcOffset())
        .format('HH:mm:ss');
    const mEnd = moment(end)
        .utcOffset(moment().utcOffset())
        .format('HH:mm:ss');

    return (
        (mStart >= minTimeStr && mStart >= maxTimeStr) ||
        (mEnd >= minTimeStr && mEnd <= maxTimeStr) ||
        (mStart <= minTimeStr && mEnd >= maxTimeStr)
    );
};

type TBaseBlock<TId extends number | string> = {
    id: TId;
    name?: string;

    start: Date;
    end: Date;

    colorId?: number | null;

    multiDay?: boolean;
    loading?: boolean;

    simpleColors?: { color: Themed.Color; label?: string }[];
};

type TBigCalendar<TId extends number | string, TEvent extends TBaseBlock<TId>, TResource extends object> = PartialPick<
    CalendarProps<TEvent, TResource>,
    'localizer'
> & {
    events: TEvent[];
    selectedId?: TId;
    isDraftId?: (id: TId) => boolean;
    renderEventDetails?: (event: TEvent) => React.ReactNode;
    onClearSelected?: () => void;

    initScrollTo?: Date;
    deferFocus?: boolean;

    noToolbar?: boolean;
    lockView?: boolean;
    clickableIds?: Set<TId>;
};

export default function BigCalendar<
    TId extends number | string,
    TEvent extends TBaseBlock<TId>,
    TResource extends object
>({
    events: eventsProp,
    defaultView,
    formats,
    view,
    selectedId,
    isDraftId,
    renderEventDetails,
    initScrollTo,
    deferFocus,

    lockView,
    noToolbar,
    clickableIds,
    ...props
}: TBigCalendar<TId, TEvent, TResource>) {
    const minTime = props.min ? moment(props.min) : moment().startOf('day');
    const maxTime = props.max ? moment(props.max) : moment().endOf('day');
    const minTimeStr = minTime.format('HH:mm:ss');
    const maxTimeStr = maxTime.format('HH:mm:ss');

    const events = React.useMemo(() => eventsProp.filter(e => eventWithin(e, minTimeStr, maxTimeStr)), [
        eventsProp,
        minTimeStr,
        maxTimeStr,
    ]);

    const date = typeof props.date === 'string' ? parseISO(props.date) : props.date ?? events?.[0]?.start ?? new Date();
    // TODO: handle selected + min/max
    const startOfView = (view === 'week' ? startOfWeek : startOfDay)(date);
    const endOfView = (view === 'week' ? endOfWeek : endOfDay)(date);

    const selectedEvent = events
        ?.filter(e => isBefore(e.start, endOfView) && isAfter(e.end, startOfView))
        .find(e => e.id === selectedId);

    const [selectedRange, setSelectedRange] = React.useState<
        | {
              continuesEarlier?: boolean;
              continuesLater?: boolean;
          }
        | undefined
    >();

    React.useEffect(() => {
        if (!selectedEvent) setSelectedRange(undefined);
    }, [selectedEvent]);

    const selectedBlockRef = React.useRef<HTMLDivElement | null>(null);

    const blockOnSelectSlot: React.MouseEventHandler = e => e.nativeEvent.stopImmediatePropagation();

    const components: Components<TEvent> = {
        toolbar: props => (noToolbar ? null : <StyledToolbar {...props} hideRange={lockView} />),
        eventWrapper: eventWrapperProps => {
            const { event, continuesEarlier, continuesLater } = eventWrapperProps;
            const eventRange = { continuesEarlier, continuesLater };

            const selected = event.id === selectedId && (selectedRange ? isEqual(eventRange, selectedRange) : true);

            return (
                <EventBlock
                    {...eventWrapperProps}
                    draft={isDraftId && isDraftId(event.id)}
                    selected={selected}
                    {...(selected ? { blockRef: selectedBlockRef } : {})}
                    style={{
                        ...(selected ? { zIndex: BLOCK_Z_INDEX, xOffset: 0 } : { xOffset: 0 }),
                        ...eventWrapperProps.style,
                    }}
                    onClick={e => {
                        setSelectedRange(eventRange);
                        eventWrapperProps.onClick(e);
                    }}
                    onMouseDown={!!props.onSelectEvent ? blockOnSelectSlot : undefined}
                    {...(clickableIds && { clickable: clickableIds.has(event.id) })}
                    simpleColors={event.simpleColors}
                    min={props.min}
                    max={props.max}
                />
            );
        },
    };

    // setting a proper scroll start removes jerky scrolling between views
    const [scrollStart, setScrollStart] = React.useState<Date>(
        initScrollTo ??
            ((events &&
                events.length > 0 &&
                getScrollToTime(events, date, view, props.min ? msPassedInDay(props.min) : 0)) ||
                date)
    );
    const [scrollEnd, setScrollEnd] = React.useState<Date | undefined>(initScrollTo);
    const [queueFocus, setQueueFocus] = React.useState(false);
    React.useLayoutEffect(() => {
        setQueueFocus(true);
    }, [view, date]);

    React.useLayoutEffect(() => {
        if (queueFocus && !deferFocus) {
            setQueueFocus(false);
            setScrollEnd(curScrollEnd => {
                curScrollEnd && setScrollStart(curScrollEnd);
                return undefined;
            });
        }
    }, [queueFocus, deferFocus]);

    React.useEffect(() => {
        if (scrollStart && !scrollEnd) {
            setScrollEnd(
                events && events.length > 0
                    ? getScrollToTime(events, date, view, props.min ? msPassedInDay(props.min) : 0)
                    : undefined
            );
        }
    }, [scrollStart, scrollEnd, events, date, view, props.min]);

    const onNavigate = (newDate: Date, view: View, action: NavigateAction) => props.onNavigate?.(newDate, view, action);
    const onView = (newView: View) => props.onView?.(newView);
    const prevView = usePrevious(view);
    const prevDate = usePrevious(date.toISOString());

    return (
        <CalCol
            view={view}
            animateScroll={
                prevDate === date.toISOString() && prevView === view && scrollStart !== scrollEnd && !!scrollEnd
            }
        >
            <BigCal
                localizer={localizer}
                step={30}
                timeslots={2}
                components={components}
                defaultView={defaultView}
                view={view}
                onView={onView}
                onNavigate={onNavigate}
                events={events}
                {...props}
                style={{ height: 'unset', flex: '1 0 0', minHeight: 0 }}
                formats={{
                    selectRangeFormat: ({ start, end }) =>
                        [
                            moment(end).diff(moment(start), 'minutes') === 30
                                ? format(start, 'h')
                                : format(start, BLOCK_TIME_FORMAT),
                            format(roundEndOfDayto12AM(end), BLOCK_TIME_FORMAT),
                        ].join(moment(end).diff(moment(start), 'minutes') === 30 ? '-' : ' to '),
                    timeGutterFormat: GUTTER_TIME_FORMAT,
                    dayFormat: DAY_HEADER_FORMAT,

                    ...formats,
                }}
                scrollToTime={scrollEnd || scrollStart}
            />

            {selectedEvent && (
                <>
                    <SelectedBackdrop open />
                    {!isDraftId?.(selectedEvent.id) && renderEventDetails && (
                        <SelectedPopper
                            blockRef={selectedBlockRef}
                            onBackdropClick={() => {
                                props.onClearSelected?.();
                                selectedBlockRef.current = null;
                            }}
                            view={view}
                        >
                            {renderEventDetails(selectedEvent)}
                        </SelectedPopper>
                    )}
                </>
            )}
        </CalCol>
    );
}
