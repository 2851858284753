import React from 'react';
import styled, { css } from 'styled-components/macro';

import { Column, Row, Spacer } from '../../ui';

const NumberCircle = styled.div`
    width: 28px;
    height: 28px;
    border-radius: 14px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryDisplayIcon)};

    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    font-size: 15px;

    display: flex;
    justify-content: center;
    align-items: center;
`;

const AccentedHeadline = styled.h3`
    font-size: 18px;
    letter-spacing: -0.1px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.accentedHeadline)};
    margin: 0;
    font-weight: normal;
`;

type StepsColumnProps = React.PropsWithChildren<{
    number: number;
    headline: string;
    copy?: string;
}>;

const StepsColumn = ({ number, headline, children, copy }: StepsColumnProps) => {
    return (
        <Column
            css={css`
                p {
                    margin: 0;
                    margin-bottom: 16px;
                }

                ul,
                ol {
                    padding-inline-start: 1.2em;
                }

                li {
                    margin-bottom: 16px;
                }

                a {
                    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
                    &:hover {
                        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryActionHover)};
                    }
                }
            `}
        >
            <Row
                css={css`
                    & > * {
                        margin-right: 8px;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                `}
            >
                <NumberCircle>
                    <div>{number}</div>
                </NumberCircle>
                <AccentedHeadline>{headline}</AccentedHeadline>
            </Row>
            <Spacer />
            {copy && <Column dangerouslySetInnerHTML={{ __html: copy }} />}
            {!copy && children}
        </Column>
    );
};

export default StepsColumn;
