import React from 'react';
import styled from 'styled-components/macro';

import { Row, Copy } from '../../ui';

import { ReactComponent as DinnerIcon } from '../../images/icons/dinner.svg';
import { ReactComponent as GuestRoomIcon } from '../../images/icons/guest_room.svg';
import { ReactComponent as ProjectorIcon } from '../../images/icons/projector.svg';
import { ReactComponent as SpaceIcon } from '../../images/icons/space.svg';
import { ReactComponent as LunchIcon } from '../../images/icons/lunch.svg';


const FEATURES_TO_ICONS = {
    av: ProjectorIcon,
    catering: LunchIcon,
    waterfront: SpaceIcon,
};
const SPACE_TYPES_TO_ICONS: { [id: number]: React.FunctionComponent } = {
    1: DinnerIcon,
    2: SpaceIcon,
    3: GuestRoomIcon,
    4: SpaceIcon,
    5: SpaceIcon,
    6: DinnerIcon,
};
const FEATURES_TO_LABELS = {
    av: 'Audio / Visual',
    catering: 'Food & Beverage',
    waterfront: 'Waterfront',
};

export const getVenueFeatures: (
    venue: Bizly.Venue
) => {
    id: number | string;
    icon: React.FunctionComponent<{ style: any }>;
    label: string;
}[] = (venue: Bizly.Venue) => [
    ...(venue.av
        ? [
              {
                  id: 'av',
                  icon: FEATURES_TO_ICONS.av,
                  label: FEATURES_TO_LABELS.av,
              },
          ]
        : []),
    ...(venue.catering
        ? [
              {
                  id: 'catering',
                  icon: FEATURES_TO_ICONS.catering,
                  label: FEATURES_TO_LABELS.catering,
              },
          ]
        : []),
    ...(venue.spaceTypes && venue.spaceTypes.length > 0
        ? venue.spaceTypes.map((s: { id: number; name: string }) => ({
              id: s.id,
              icon: SPACE_TYPES_TO_ICONS[s.id],
              label: s.name,
          }))
        : []),
    ...(venue.waterfront
        ? [
              {
                  id: 'waterfront',
                  icon: FEATURES_TO_ICONS.waterfront,
                  label: FEATURES_TO_LABELS.waterfront,
              },
          ]
        : []),
];

const VenueFeatureLabel = styled(Copy)`
    font-size: 16px;
    letter-spacing: -0.09px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
`;
const VenueFeaturesGrid = styled(Row)`
    flex-wrap: wrap;
    margin: -10px;
`;
const VenueFeature = styled(Row)`
    align-items: center;
    max-width: 200px;
    margin: 10px;

    & > *:not(:last-child) {
        margin-right: 8px;
    }
`;

const VenueFeatures = ({ venue }: { venue: Bizly.Venue }) => {
    return (
        <VenueFeaturesGrid>
            {getVenueFeatures(venue).map(f => {
                const Icon = f.icon || SpaceIcon;

                return (
                    <VenueFeature key={f.id}>
                        <Icon style={{ minWidth: 32 }} />
                        <VenueFeatureLabel>{f.label}</VenueFeatureLabel>
                    </VenueFeature>
                );
            })}
        </VenueFeaturesGrid>
    );
};

export default VenueFeatures;
