import React from 'react';

import styled, { css } from 'styled-components/macro';

import Popover from '@material-ui/core/Popover';

import TextButton from '../ui/Button/TextButton';

import colorFns from 'colorFns';

import { AlignedRow, Column } from '../../ui';
import SearchCollaborators from './SearchCollaborators';
import { useEvent } from '../../providers/event';
import { useEventCollaborators } from '../../providers/event-collaborators';
import { useSnackbar } from 'notistack';
import { useUser } from 'providers/user';

const Container = styled(Column)`
    width: 460px;
    padding: 32px;
    max-height: 679px;
    flex: 1;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    position: relative;
`;

const AccentedHeadline = styled.h2`
    margin-top: 0;

    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.4px;
    color: ${colorFns.accentedHeadline};
`;

const CollaboratorsFlyout = ({ anchor, onClose }: { anchor: Nullable<Element>; onClose: () => void }) => {
    const [newCollaborators, setNewCollaborators] = React.useState<BizlyAPI.NewEventCollaborator[]>([]);
    const { event } = useEvent();
    const {
        collaborators: existingCollaborators,
        addCollaborators,
        isAddCollaboratorsPartialError,
    } = useEventCollaborators();
    const { enqueueSnackbar } = useSnackbar();
    const { toggles } = useUser();

    const closeFlyout = () => {
        setNewCollaborators([]);
        onClose();
    };

    const handleSave = async () => {
        closeFlyout();
        try {
            await addCollaborators(newCollaborators);
            enqueueSnackbar(
                `Collaborators ${toggles.gate.inviteViaCollaborator ? 'invited' : 'added'} to this meeting.`,
                {
                    variant: 'info',
                }
            );
        } catch (e) {
            const { failedEmails = [] } = isAddCollaboratorsPartialError(e) ? e.raw : {};
            const message = [
                `Something went wrong when ${
                    toggles.gate.inviteViaCollaborator ? 'inviting' : 'adding'
                } collaborators.`,
                ...(failedEmails.length > 0
                    ? [`Some emails could not be ${toggles.gate.inviteViaCollaborator ? 'invited' : 'added'}.`]
                    : []),
                `Please try again.`,
            ].join(' ');

            enqueueSnackbar(message, { variant: 'error' });
        }
    };

    return (
        <Popover
            anchorEl={anchor}
            open={!!anchor}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            onClose={closeFlyout}
        >
            <Container>
                <AccentedHeadline>
                    {toggles.gate.inviteViaCollaborator ? 'Invite' : 'Add Collaborators'}
                </AccentedHeadline>
                <SearchCollaborators
                    eventId={event.id}
                    existingCollaboratorEmails={[
                        ...existingCollaborators.map(collaborator => collaborator.email),
                        event.plannedBy.email,
                    ]}
                    onChange={setNewCollaborators}
                    allowNewEmails={toggles.gate.inviteViaCollaborator}
                />
                <AlignedRow
                    css={css`
                        justify-content: flex-end;
                        margin-top: 32px;
                    `}
                >
                    <TextButton onClick={closeFlyout} secondary>
                        Cancel
                    </TextButton>

                    <TextButton onClick={handleSave} disabled={newCollaborators.length === 0}>
                        {toggles.gate.inviteViaCollaborator ? 'Invite' : 'Add'}
                    </TextButton>
                </AlignedRow>
            </Container>
        </Popover>
    );
};

export default CollaboratorsFlyout;
