import React, { useState, useMemo, useEffect } from 'react';
import capitalize from 'lodash/capitalize';
import keyBy from 'lodash/keyBy';

import styled from 'styled-components/macro';
import Popover from '@material-ui/core/Popover';

import { Column, Row, Copy, Spacer } from 'ui';
import TextButton from 'components/ui/Button/TextButton';
import Form from 'components/Form';

import { EColors } from 'theme';

import { SLIDER_VAL_TO_GRADE } from './VenueSearchGrades';

const Pop = styled(Column)`
    min-height: 538px;
    width: 574px;

    box-sizing: border-box;
    padding: 32px;
`;

type TVenueGrade = { value: number; label: string };
export const gradeLabels = SLIDER_VAL_TO_GRADE.map(g => capitalize(g));
const venueGrades: TVenueGrade[] = gradeLabels.map((grade, idx) => ({
    value: idx,
    label: grade,
}));

type TVenueType = { id: BizlyAPI.Venue.Types; label: string };
const venueTypes: TVenueType[] = [
    {
        id: 'hotel',
        label: 'Hotels',
    },
    {
        id: 'restaurant',
        label: 'Restaurants',
    },
    {
        id: 'unique venue',
        label: 'Unique',
    },
    {
        id: 'activity',
        label: 'Activity',
    },
    {
        id: 'conference center',
        label: 'Conference Center',
    },
    {
        id: 'event venue',
        label: 'Meeting Venue',
    },
];
export const venueTypesDict = keyBy(venueTypes, type => type.id) as Record<BizlyAPI.Venue.Types, TVenueType>;

type TVenueStyle = { id: BizlyAPI.Venue.Decors; label: string };
const venueStyles: TVenueStyle[] = [
    {
        id: 'Modern',
        label: 'Modern',
    },
    {
        id: 'Elegant',
        label: 'Elegant',
    },
    {
        id: 'Traditional',
        label: 'Traditional',
    },
];
export const venueStylesDict = keyBy(venueStyles, style => style.id) as Record<BizlyAPI.Venue.Decors, TVenueStyle>;

export const formatRadius = (val: Distance.Mile) => `${val === 100 ? '100+' : val} mi`;

const fields = (teamName?: string) => ({
    grades: {
        prompt: 'Grade',
        type: 'slider',
        perRow: '2/3',
        options: {
            min: 0,
            max: 2,
            marks: venueGrades,
        },
    },
    hideUngraded: {
        type: 'checkbox',
        options: {
            label: 'Hide nongraded venues',
        },
    },
    types: {
        prompt: 'Venue Type',
        type: 'multiselect',
        options: {
            options: teamName
                ? [
                      ...venueTypes,
                      {
                          id: 'corporate office',
                          label: teamName,
                      },
                  ]
                : venueTypes,
            perRow: 3,
        },
    },
    decors: {
        prompt: 'Venue Style',
        type: 'multiselect',
        options: {
            options: venueStyles,
            perRow: 3,
        },
    },
    preferredOnly: {
        prompt: 'Preferred Venue',
        type: 'checkbox',
        options: {
            label: 'Only show Preferred Venues',
        },
    },
    radius: {
        prompt: 'Distance',
        type: 'slider',
        perRow: '2/3',
        options: {
            min: 1,
            max: 100,
            valueLabelFormat: formatRadius,
        },
    },
});

export const schema = [
    {
        key: 'grades',
        fields: ['grades'],
        spacing: 'small',
    },
    {
        key: 'hideUngraded',
        fields: ['hideUngraded'],
        spacing: 'large',
    },
    {
        key: 'types',
        fields: ['types'],
        spacing: 'large',
    },
    {
        key: 'decors',
        fields: ['decors'],
        spacing: 'large',
    },
    {
        key: 'preferred',
        fields: ['preferredOnly'],
        spacing: 'large',
    },
    {
        key: 'radius',
        fields: ['radius'],
        spacing: 'large',
    },
] as const;

const errors = {};

export type TFilterValueBase = {
    grades: [number, number];
    hideUngraded: boolean;
    types: BizlyAPI.Venue.Types[];
    decors: BizlyAPI.Venue.Decors[];
    preferredOnly: boolean;
    radius: Distance.Mile;
};
export type TFilterValue = Partial<TFilterValueBase>;

type TFilterValueInitial = TFilterValueBase & Omit<TFilterValue, 'grades' | 'types'>;

type TProps = {
    filters: TFilterValue;
    onApply: (filterVal: TFilterValue) => void;
    anchor: any;
    open: boolean;
    onClose: () => void;
    teamName: string;
};
const baseFormProps = {
    isNested: undefined,
    readonly: undefined,
    densePadding: undefined,
};
export default function VenueSearchFilters({ filters: filtersProp, onApply, anchor, open, onClose, teamName }: TProps) {
    const computedFilters = useMemo(
        () => ({
            ...filtersProp,
            // to be clear, these are the default search params:
            grades: filtersProp.grades || [0, 2],
            radius: filtersProp.radius || (15 as Distance.Mile),
        }),
        [filtersProp]
    );
    const [filters, setFilters] = useState(computedFilters);
    useEffect(() => {
        setFilters(computedFilters);
    }, [computedFilters]);
    const fieldsWithTeam = useMemo(() => fields(teamName), [teamName]);
    const handleEventChange = ({ value }: { value: TFilterValueInitial }) => setFilters(value);

    return (
        <Popover
            anchorEl={anchor}
            open={open}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transitionDuration={150}
            onClose={onClose}
        >
            <Pop>
                <Copy $color={EColors.darkGrey}>Filter by</Copy>
                <Spacer />
                <Form
                    fields={fieldsWithTeam}
                    schema={schema}
                    value={filters}
                    errors={errors}
                    onChange={handleEventChange}
                    {...baseFormProps}
                />
                <Row style={{ justifyContent: 'flex-end' }}>
                    <TextButton secondary onClick={onClose}>
                        Cancel
                    </TextButton>
                    <Spacer col smallish />
                    <TextButton onClick={() => onApply(filters)}>Apply</TextButton>
                </Row>
            </Pop>
        </Popover>
    );
}
