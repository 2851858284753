import React from 'react';

import { TOption, optionsToListForm, EVENT_SPACES_REQUEST_WIDTH, OPTIONS_RADIO_CHECKBOX_WIDTH } from './utils';
import Form from 'components/Form';

/*
    An AVForm of AVFields/AVSchema has the following value:
    
    Form Value: {
        proposedAvIds: [{id: 1, response: true}, {id: 2, response: false}],
        provided: true/false
    }

    requestedAvIds and proposedAvIds can be represented with AVField, which is just a list of AVOptionField[]:

    Form Value: {
        proposedAvIds: AVField or AVOptionField[],
        provided: 'checkbox'
    }
*/

const AVOptionFields = {
    response: {
        type: 'checkbox',
        fixedWidth: OPTIONS_RADIO_CHECKBOX_WIDTH,
        options: {
            asRadio: true,
        },
    },
};

const AVOptionSchema = (request: TOption, onlyShowRequest?: boolean) => [
    {
        fields: [
            {
                type: 'display',
                fixedWidth: EVENT_SPACES_REQUEST_WIDTH,
                options: {
                    label: request.name,
                    iconUrl: request.iconUrl,
                    iconAlt: request.name,
                },
            },
            ...(onlyShowRequest ? [] : ['response']),
        ],
        itemSpacing: 'default',
        spacing: false,
    },
];

const AVOptionField = ({
    field,
    value,
    readonly,
    disabled,
    onChange,
    errors,

    request,
    onlyShowRequest,
}: {
    field: string;
    value: { id?: number; response?: boolean };
    readonly?: boolean;
    disabled?: boolean;
    onChange: any;
    errors: any;
    request: TOption;
    onlyShowRequest?: boolean;
}) => (
    <Form
        fields={AVOptionFields}
        schema={AVOptionSchema(request, onlyShowRequest)}
        readonly={readonly}
        disabled={disabled}
        value={{ id: request.id, ...value }}
        errors={errors}
        onChange={({ value, errors }: any) =>
            onChange({
                // this entire form here represents a single option in a list of options
                // so a value of {id, response} should refer back to the option in the list so we use the given field here:

                // These types of custom fields defined using a <Form /> should be easy to build
                field,
                value,
                errors,
            })
        }
    />
);
const AVField = ({
    onlyShowRequest,
    ...props
}: {
    options: TOption[];
    field: string;
    onChange: any;
    readonly?: boolean;
    onlyShowRequest?: boolean;
    value: { id: number; response?: boolean }[];
}) =>
    optionsToListForm({
        ...props,
        getOptionId: option => option.id.toString(),
        optionToField: option => ({
            type: AVOptionField,
            options: {
                request: option,
                onlyShowRequest,
            },
        }),

        optionToSchema: option => ({ key: option.id, spacing: 'xsmall' }),

        getValueId: value => value.id.toString(),
    });

export const AVFields = (requestedAvIds: number[], options: { [key: number]: TOption }, onlyShowRequest?: boolean) => ({
    proposedAvIds: {
        type: AVField,
        prompt: 'Audio/Visual',
        options: {
            options: requestedAvIds.map(id => options[id]),
            onlyShowRequest,
        },
    },
    provided: {
        type: 'checkbox',
        options: {
            label: 'Audio/Visual will be provided by a third party',
        },
    },
});

export const AVSchema = [
    {
        key: 'AvIds',
        fields: ['proposedAvIds'],
        spacing: false,
    },
];
