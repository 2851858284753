import React from 'react';

import { useSnackbar } from 'notistack';

import { meetingsActions } from './store';

import moment from 'moment';

export default function LoadMeetings({ date, limit }: { date?: Date | string; limit?: number }) {
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        const load = async () => {
            try {
                await meetingsActions.load(
                    date
                        ? moment(date)
                              .startOf('week')
                              .toDate()
                        : undefined,
                    limit
                );
            } catch (e) {
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            }
        };

        load();
    }, [date, limit, enqueueSnackbar]);

    return null;
}
