import React from 'react';

import { Column } from 'ui';
import { H3Headline } from 'components/ui/Headline';
import { statusColors } from '../../shared';

import RespondentModal from './RespondentModal';

import { CustomQuestionsForm } from 'pages/RSVPForm';

export default function SurveyeeModal({
    surveyee,
    onDismiss,
}: {
    surveyee: Bizly.SurveyRespondent & Pick<BizlyAPI.Attendee, 'customQuestions'>;
    onDismiss: () => void;
}) {
    const { customQuestions, responded, ...respondentBase } = surveyee;
    const respondent = {
        ...respondentBase,
        status: responded ? 'Responded' : 'Pending',
        statusColor: statusColors[responded ? 'responded' : 'pending'],
    };

    return (
        <RespondentModal
            respondent={respondent}
            content={
                <Column itemSpacing="large">
                    <H3Headline>Responses:</H3Headline>
                    <CustomQuestionsForm
                        field=""
                        value={customQuestions}
                        onChange={() => {}}
                        questions={customQuestions}
                        readonly
                        placeholder="-"
                        // remove bottom Spacer:
                        isNested
                    />
                </Column>
            }
            onDismiss={onDismiss}
        />
    );
}
