import React from 'react';
import styled, { keyframes, css } from 'styled-components';

import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import { Copy } from 'ui';

import colorFns from 'colorFns';

const fadeInKeyframes = keyframes`
  0%   { opacity: 0; }
  100% { opacity: 1; }
`;

const fadeInStyle = css`
    animation: ${fadeInKeyframes} 0s 1s both;
    opacity: 0;
`;

const Progress = styled(CircularProgress)<{ delay?: boolean }>`
    color: ${colorFns.pureWhite.alpha(0.74)};

    ${({ delay }) => (delay ? fadeInStyle : '')};
`;

type SpinnerProps = {
    suppressMargin?: boolean;
    unsetMargin?: boolean;
    className?: string;
    delay?: boolean;
};

export const Spinner = ({
    suppressMargin,
    unsetMargin,
    className,
    delay,
    ...props
}: SpinnerProps & CircularProgressProps) => (
    <Progress
        style={unsetMargin ? {} : { margin: suppressMargin ? '0' : '20px auto' }}
        delay={delay}
        className={className}
        {...props}
    />
);

const SpinnerOverlayContainer = styled.div<{
    fixed?: boolean;
    fixedSpinner?: boolean;
    transparent?: boolean;
    clickThrough?: boolean;
    delay?: boolean;
}>`
    position: absolute;
    ${({ fixed }) =>
        fixed
            ? `
    position: fixed;
    z-index: 1300;

    `
            : ''}
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    background-color: ${({ transparent, theme: { getColor, EColors } }) =>
        getColor(EColors.brand, transparent ? 0 : 0.8)};
    opacity: 0;
    animation: ${fadeInKeyframes} 0.3s ${({ delay }) => (delay ? '1s' : '')} ease-in both;

    > ${Progress} {
        ${({ fixedSpinner }) =>
            fixedSpinner
                ? `
        position: fixed;
        top: 50vh;
        transform: translateY(-50%);
        `
                : ''}
    }

    ${({ clickThrough }) => (clickThrough ? `pointer-events: none;` : '')}
`;

export const SpinnerOverlay = ({
    message,
    ...props
}: {
    className?: string;
    fixed?: boolean;
    fixedSpinner?: boolean;
    transparent?: boolean;
    clickThrough?: boolean;
    message?: string;
    delay?: boolean;
}) => (
    <SpinnerOverlayContainer {...props}>
        <Spinner />
        {message && <Copy>{message}</Copy>}
    </SpinnerOverlayContainer>
);
