import React from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { generatePath, useHistory } from 'react-router';
import useUnsavedPrompt from 'hooks/useUnsavedPrompt';

import { createPlaybookActions, useCreatePlaybook, stepList, maxStep, ValidationError } from '../store';
import { eventTemplatesActions } from 'stores/eventTemplates';

import { Step, StepLabel, Stepper as StepperMui } from '@material-ui/core';
import Button from 'components/ui/Button';
import { Row } from 'ui';
import colorFns from 'colorFns';

const HeaderRow = styled(Row)`
    background: transparent;
`;

const AutoRow = styled(Row)`
    width: auto;
`;

const Stepper = styled(StepperMui)`
    min-width: 200px;
    padding-left: 0;
    background: none;

    .MuiStep-root,
    .MuiStepLabel-iconContainer {
        padding: 0;
    }

    .MuiStepIcon-root.MuiStepIcon-active {
        color: ${colorFns.primaryAction};
    }
`;

export default function Header() {
    const { stepIdx, saved } = useCreatePlaybook();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const goToPlaybook = (playbook: BizlyAPI.Complete.Playbook) => history.push(`/playbooks/${playbook.id}`);

    const [createdId, setCreatedId] = React.useState<number | null>(null);

    const renderPrompt = useUnsavedPrompt(!saved, newLocation => {
        const path = createdId ? generatePath('/playbooks/:id', { id: createdId }) : '';
        return newLocation.pathname === history.location.pathname || path === newLocation.pathname;
    });

    const create = async () => {
        try {
            const created = await createPlaybookActions.create();
            if (created) {
                eventTemplatesActions.add(created);
                setCreatedId(created.id);
                goToPlaybook(created);
            }
        } catch (e) {
            if (!(e instanceof ValidationError))
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    };

    return (
        <HeaderRow alignItems="center" justifyContent="space-between">
            <AutoRow>
                <Stepper>
                    {stepList.map((name, idx) => (
                        <Step key={idx} completed={false} active={idx <= stepIdx}>
                            <StepLabel>{}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </AutoRow>
            <AutoRow itemSpacing="smallish">
                {stepIdx > 0 && (
                    <Button width="60px" onClick={createPlaybookActions.prevStep}>
                        Prev
                    </Button>
                )}
                {stepIdx < maxStep && (
                    <Button width="140px" onClick={createPlaybookActions.nextStep}>
                        Next
                    </Button>
                )}
                {stepIdx === maxStep && (
                    <Button width="140px" onClick={create}>
                        Create Playbook
                    </Button>
                )}
            </AutoRow>
            {renderPrompt()}
        </HeaderRow>
    );
}
