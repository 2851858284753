import React from 'react';
import styled from 'styled-components/macro';
import isPlainObject from 'lodash/isPlainObject';

import fontFns from 'fontFns';

import { Row, Column, Spacer } from 'ui';
import TextButton from 'components/ui/Button/TextButton';
import {
    perRowStyles,
    fixedWidthStyles,
    NoneField,
    DisplayField,
    SelectField,
    Checkbox,
    Multiselect,
    RadioSelect,
    DatePickerField,
    DatePickerOutlinedField,
    DatePickerOutlined,
    LabeledTextField,
    TextArea,
    Slider,
    UploadList,
    UploadSingle,
    ImageUploadField,
    CounterField,
    LinearScaleField,
} from 'components/FormFields';
import { RichTextEditor, ColorPicker, TimeZonePicker } from './fields';

// FORM FIELD
export const FieldHeader = styled.div`
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.1px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formLabel)};
    ${fontFns.formLabel}

    min-height: 16px;
`;

const FieldOptionalLabel = styled.span`
    font-size: 13px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.optionalSpecifier)};

    margin-left: 12px;
`;

const FormField = styled(Column)`
    ${({ fixedWidth, perRow }) => (fixedWidth !== undefined ? fixedWidthStyles(fixedWidth) : perRowStyles(perRow))}
`;

const CollapsedButton = styled(TextButton)`
    height: 40px;

    padding: 0;
    margin-right: auto;
`;

const Field = ({
    field,
    prompt,
    optional,
    perRow,
    fixedWidth,
    readonly,
    disabled,
    densePadding,
    collapsed,
    options,
    Component,
    value,
    onChange,
    errors,
}) => {
    const [hidden, setHidden] = React.useState(collapsed !== undefined);
    React.useEffect(() => {
        if (value !== null && value !== undefined) setHidden(false);
    }, [value]);

    return React.useMemo(
        () => (
            <FormField fixedWidth={fixedWidth} perRow={perRow}>
                {hidden ? (
                    <CollapsedButton width="auto" secondary onClick={() => setHidden(false)}>
                        {typeof collapsed === 'string' ? collapsed : `Add ${prompt}`}
                    </CollapsedButton>
                ) : (
                    <>
                        {prompt && (
                            <FieldHeader>
                                {prompt}
                                {optional && !readonly && <FieldOptionalLabel>(optional)</FieldOptionalLabel>}
                            </FieldHeader>
                        )}
                        {prompt && <Spacer small />}
                        {Component && (
                            <Component
                                field={field}
                                isNested
                                optional={optional}
                                readonly={readonly}
                                disabled={disabled}
                                densePadding={densePadding}
                                {...options}
                                value={value}
                                onChange={onChange}
                                errors={errors}
                            />
                        )}
                    </>
                )}
            </FormField>
        ),
        [
            Component,
            densePadding,
            collapsed,
            hidden,
            disabled,
            errors,
            field,
            fixedWidth,
            onChange,
            optional,
            options,
            perRow,
            prompt,
            readonly,
            value,
        ]
    );
};

// FORM
const FormHeading = styled.div`
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.1px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formHeading)};
    ${fontFns.formHeading}

    min-height: 18px;
`;

const FieldsRow = styled(Row)`
    width: auto;
    align-items: stretch;

    ${({ combined }) =>
        combined
            ? `
    color: blue;

    > * {
        &:hover,
        & .Mui-focused {
            z-index: 1;
        }

        &:not(:last-child) fieldset,
        &:not(:last-child) ${DatePickerOutlined} input {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            margin-right: -0.5px;
            padding-right: 0;
        }

        &:not(:first-child) fieldset,
        &:not(:first-child) ${DatePickerOutlined} input {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
            margin-left: -0.5px;
            padding-left: 0;
        }

        &:not(:last-child) .MuiInputBase-input {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
    }
    `
            : ''}
`;

const NestedSchema = styled(FormField)``;

const TYPE_TO_COMPONENT = {
    none: NoneField,
    display: DisplayField,
    select: SelectField,
    checkbox: Checkbox,
    multiselect: Multiselect,
    radioselect: RadioSelect,
    text: LabeledTextField,
    textarea: TextArea,
    rich_text: RichTextEditor,
    date: DatePickerField,
    timezone_select: TimeZonePicker,
    date_outlined: DatePickerOutlinedField,
    slider: Slider,
    upload_list: UploadList,
    image_uploader: ImageUploadField,
    upload_single: UploadSingle,
    counter: CounterField,
    linear_scale: LinearScaleField,
    color_picker: ColorPicker,
};

const noOptions = {};

const Form = ({
    fields,
    schema,
    value,
    onChange,
    errors = noOptions,
    isNested = false,
    readonly: formReadonly = false,
    disabled: formDisabled = false,
    densePadding: formDensePadding = false,
    className = undefined,
}) => {
    const curVal = React.useRef(value);
    const curErr = React.useRef(errors);
    React.useEffect(() => {
        curVal.current = value;
    }, [curVal, value]);
    React.useEffect(() => {
        curErr.current = errors;
    }, [curErr, errors]);

    const fieldOnChange = React.useMemo(
        () => ({ field, value: fieldValue, errors: fieldErrors }) => {
            /* TODO: This is a workaround for some field onChange events sending a
      DOM event rather than a short term value. This sort of inconsistency
      will be fixed after migrating all input controls to newer implementations,
      such as the case with the newer <Input /> utility component`
    */
            const parsedValue = fieldValue?.target ? fieldValue.target.value : fieldValue;

            return onChange({
                value: deepSet(curVal.current, field, parsedValue),
                errors: deepSet(curErr.current, field, fieldErrors),
                field,
            });
        },
        [onChange]
    );

    const buildField = field => {
        const {
            prompt,
            type,
            optional,
            perRow,
            fixedWidth,
            readonly: fieldReadonly,
            disabled: fieldDisabled,
            collapsed,
            options = noOptions,
        } = fields[field];
        const Component = typeof type === 'string' ? TYPE_TO_COMPONENT[type] : type;
        const fieldValue = value[field];
        const fieldErrors = errors[field];
        const props = {
            field,
            prompt,
            optional,
            perRow,
            fixedWidth,
            readonly: formReadonly || fieldReadonly,
            disabled: formDisabled || fieldDisabled,
            densePadding: formDensePadding || (formReadonly && !isNested),
            collapsed,
            options,
            Component,
            value: fieldValue,
            onChange: fieldOnChange,
            errors: fieldErrors,
        };

        return <Field key={field} {...props} />;
    };

    const getSchemaFieldType = schemaField =>
        typeof schemaField === 'string' || typeof schemaField === 'number'
            ? 'field'
            : isPlainObject(schemaField)
            ? schemaField.type
            : '';

    const getKeyForNestedSchema = nestedSchema =>
        nestedSchema.schema.map(schemaRow => getKeyForRowDef(schemaRow)).join('|');

    const getKeyForRowDef = rowDef =>
        rowDef.fields
            .map(field => {
                switch (getSchemaFieldType(field)) {
                    case 'field':
                        return field;
                    case 'nested':
                        return getKeyForNestedSchema(field);
                    case 'none':
                    case 'display':
                        return field.key;
                    default:
                        return '';
                }
            })
            .join('|') + 'row';

    const mapSchemaField = schemaField => {
        switch (getSchemaFieldType(schemaField)) {
            case 'field':
                return buildField(schemaField);
            case 'nested':
                return (
                    <NestedSchema
                        fixedWidth={schemaField.fixedWidth}
                        perRow={schemaField.perRow}
                        key={getKeyForNestedSchema(schemaField)}
                    >
                        {buildSchema(schemaField.schema)}
                    </NestedSchema>
                );
            case 'none':
            case 'display':
                const { key, text, type, perRow, ...fieldDef } = schemaField;
                return (
                    <Field
                        key={schemaField.text || schemaField.key}
                        Component={TYPE_TO_COMPONENT[type]}
                        perRow={perRow || 'auto'}
                        {...fieldDef}
                    />
                );

            default:
                return null;
        }
    };

    const buildSchema = schema =>
        schema.map(rowDef => {
            const {
                key = getKeyForRowDef(rowDef),
                header,
                headerSpacing = 'medium',
                fields: s_fields,
                spacing = 'larger',
                itemSpacing = 'smallish',
                maxWidth,
                combined,
            } = rowDef;

            return (
                <Column style={{ maxWidth }} key={key}>
                    {header && <FormHeading>{header}</FormHeading>}
                    {header && <Spacer {...{ [headerSpacing]: true }} />}
                    <FieldsRow itemSpacing={itemSpacing} paddingSpacing combined={combined}>
                        {s_fields.map(mapSchemaField)}
                    </FieldsRow>
                    {spacing && <Spacer {...{ [spacing]: true }} />}
                </Column>
            );
        });

    const FormOrDiv = isNested ? 'form' : 'div';

    return <FormOrDiv className={className}>{buildSchema(schema)}</FormOrDiv>;
};

// Helper to copy and set a deep value given an object path like: 'social.facebook'
const deepSet = (obj, path, value) => {
    const objCopy = { ...obj };

    const pathPieces = ('' + path).split('.');
    const lastKey = pathPieces[pathPieces.length - 1];
    const beforeLast = pathPieces[pathPieces.length - 2];

    pathPieces.reduce((newObj, key) => {
        // duplicate the parent of the object/key to set
        if (key === beforeLast) {
            newObj[key] = { ...newObj[key] };
        }

        // set the value
        return key === lastKey ? (newObj[key] = value) : newObj[key];
    }, objCopy);

    return objCopy;
};

export default Form;
