import React, { useEffect } from 'react';
import { css } from 'styled-components/macro';
import styled from 'styled-components';

import { useLocation } from 'react-router';
import { Redirect } from 'react-router-dom';
import { parsePhoneNumber } from 'libphonenumber-js';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useSnackbar } from 'notistack';

import usePrevious from 'hooks/usePrevious';

import { uploadFile } from 'cloudinary';
import { useUser } from 'providers/user';
import { nudgesActions } from 'stores/nudges';

import ManageAvatar from 'components/Profile/ManageAvatar';

import MenuItem from '@material-ui/core/MenuItem';
import Input, { StyledPhoneInput } from 'components/ui/Input';
import LogoBackground from 'components/ui/LogoBackground';
import ContentCard from 'components/ui/ContentCard';
import { Column, Spacer, Button } from 'ui';
import { ReactComponent as BizlyDonut } from 'images/icons/bizly-donut.svg';
import { ReactComponent as BizlyQuarterOne } from 'images/icons/bizly-quarter-one.svg';
import { ReactComponent as BizlyQuarterTwo } from 'images/icons/bizly-quarter-two.svg';

import { ReactComponent as DownArrow } from 'images/icons/down-arrow.svg';

import colorFns from 'colorFns';
import fontFns from 'fontFns';

import { EndRow, InlineButtonRight, Dropdown } from './ui';

const ROLE_OPTIONS = [
    { value: 'Sales', name: 'Sales' },
    { value: 'Marketing', name: 'Marketing' },
    { value: 'Engineering', name: 'Engineering' },
    { value: 'Business Development', name: 'Business Development' },
    { value: 'HR', name: 'HR' },
    { value: 'Other', name: 'Other' },
];

const OutlinedButton = styled(Button)`
    margin-top: 10px;
    padding: 10px;
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.1)};
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.brand)};
    &:hover {
        background: ${({ theme: { getColor, EColors } }) => getColor(EColors.brand)};
    }
`;

const Donut = styled(BizlyDonut)`
    position: fixed;
    right: -10px;
    bottom: 10%;
    transform: scale(3);
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.bizlyPink)};
`;

const QuarterOne = styled(BizlyQuarterOne)`
    position: fixed;
    left: -5px;
    bottom: 40%;
    transform: scale(3);
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
`;

const QuarterTwo = styled(BizlyQuarterTwo)`
    position: fixed;
    right: 25%;
    top: -2px;
    transform: scale(2);
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.bizlyBlue)};
`;

export default function ProfileForm() {
    const { user, updateUser } = useUser();

    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();

    const [stagedImage, setStagedImage] = React.useState<File>();
    const [firstName, setFirstName] = React.useState(user.firstName || '');
    const [lastName, setLastName] = React.useState(user.lastName || '');
    const [phone, setPhone] = React.useState(user.phone || '');
    const [role, setRole] = React.useState(user.role || '');
    const [error, setError] = React.useState(false);

    const [toHomePage, setToHomePage] = React.useState(false);

    const previousPhone = usePrevious(phone);

    useEffect(() => {
        if (error && previousPhone !== phone) setError(false);
    }, [error, previousPhone, phone]);

    async function handleSubmit() {
        const promptUserAboutIntegrations = !user.firstName && !user.lastName && !user.phone && !user.role;

        try {
            // parsing something too long will throw an exception
            const phoneNumber = parsePhoneNumber(phone);
            // isPossible catches most cases, but isValidPhoneNumber catches 11111111111
            if (!phoneNumber.isPossible() || !isValidPhoneNumber(phone)) {
                throw new Error();
            }
        } catch (e) {
            enqueueSnackbar('Please enter a valid phone number', {
                variant: 'error',
            });
            setError(true);
            return;
        }

        let newImageUrl;
        if (stagedImage) {
            const uploadedImage = await uploadFile(stagedImage);
            newImageUrl = uploadedImage.url;
        }
        const imageUrl = newImageUrl || user.imageUrl;
        await updateUser({ firstName, lastName, phone, role, imageUrl });
        nudgesActions.mergeNudges({
            showFirstTimeIntegrations: promptUserAboutIntegrations,
        });
        setToHomePage(true);
    }

    if (toHomePage) {
        return <Redirect to={location.state?.redirectTo ?? '/'} />;
    }

    return (
        <LogoBackground>
            <QuarterOne />
            <QuarterTwo />
            <Donut />
            <ContentCard centerSelf>
                <Column>
                    <form
                        onSubmit={(event: React.FormEvent<EventTarget>) => {
                            event.preventDefault();
                            handleSubmit();
                        }}
                    >
                        <Column>
                            <ManageAvatar imageUrl={user.imageUrl} setStagedImage={setStagedImage} />
                            <Spacer medium />
                            <Input
                                label="First Name"
                                type="text"
                                placeholder="Enter first name"
                                onChange={e => setFirstName(e.currentTarget.value)}
                                value={firstName}
                            />
                            <Input
                                label="Last Name"
                                type="text"
                                placeholder="Enter last name"
                                onChange={e => setLastName(e.currentTarget.value)}
                                value={lastName}
                            />
                            <StyledPhoneInput
                                label="Phone Number"
                                placeholder="(000) 000-0000"
                                value={phone}
                                onChange={setPhone}
                                error={error}
                            />
                            <label
                                id="role-select-label"
                                css={css`
                                    margin: 10px 0 15px;
                                    font-size: 15px;
                                    line-height: 1;
                                    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formLabel)};
                                    ${fontFns.formLabel}
                                `}
                            >
                                Role
                            </label>
                            <Dropdown
                                labelId="role-select-label"
                                id="role-select"
                                value={role}
                                onChange={e => setRole(e.target.value as string)}
                                IconComponent={DownArrow}
                                displayEmpty
                                renderValue={value =>
                                    value ? (
                                        <span>{value as string}</span>
                                    ) : (
                                        <span
                                            css={css`
                                                color: ${colorFns.darkGrey};
                                            `}
                                        >
                                            Select
                                        </span>
                                    )
                                }
                            >
                                {ROLE_OPTIONS.map(role => (
                                    <MenuItem key={role.value} value={role.value}>
                                        {role.name}
                                    </MenuItem>
                                ))}
                            </Dropdown>
                            <Spacer />
                            <EndRow>
                                <InlineButtonRight
                                    disabled={!firstName || !lastName || !role || !phone}
                                    width="100%"
                                    type="submit"
                                >
                                    Save
                                </InlineButtonRight>
                            </EndRow>
                            <OutlinedButton onClick={() => setToHomePage(true)}>Back to the show</OutlinedButton>
                        </Column>
                    </form>
                </Column>
            </ContentCard>
        </LogoBackground>
    );
}
