import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';

import moment from 'moment';

import { Row } from 'ui';
import { Spinner } from 'components/Spinner';
import { Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Button from 'components/ui/Button';
import Toolbar from 'components/BigCalendar/components/Toolbar';

import { ReactComponent as ListIconSVG } from 'images/icons/list.svg';
import { ReactComponent as CalDayIconSVG } from 'images/icons/calendar-day.svg';
import { ReactComponent as CalWeekIconSVG } from 'images/icons/calendar-week.svg';

const ListIcon = styled(ListIconSVG)`
    height: 18px;
    width: 18px;
    margin-bottom: -2px;
`;
const CalDayIcon = styled(CalDayIconSVG).attrs({ viewBox: '-3 0 27 27' })`
    width: 22px;
    height: 22px;
`;
const CalWeekIcon = styled(CalWeekIconSVG).attrs({ viewBox: '-3 0 27 27' })`
    width: 22px;
    height: 22px;
`;

const CircleButton = styled(Button)`
    border-radius: 100px;
    text-align: center;
    height: 36px;
    width: 36px;
`;

const AutoRow = styled(Row)`
    width: auto;
`;

const TwoButtonRow = styled(AutoRow)`
    > *:first-child ${CircleButton} {
        &${CircleButton}, ${CircleButton} {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            padding-right: 3px;
            margin-right: -1px;
        }
    }
    > *:last-child {
        &${CircleButton}, ${CircleButton} {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            padding-left: 3px;
        }
    }
`;

export default function NavBar({
    calendar,
    day,
    setDay,
    date,
    setDate,
    isLoadingCal,
    isLoadingList,
}: {
    calendar?: boolean;
    day?: boolean;
    setDay: (isDay: boolean) => void;
    date: Date;
    setDate: (newDate: Date) => void;
    isLoadingCal?: boolean;
    isLoadingList?: boolean;
}) {
    const location = useLocation();

    return (
        <Row alignItems="center" justifyContent="space-between">
            <AutoRow alignItems="center" itemSpacing="large">
                <Toolbar
                    date={date}
                    view={day ? 'day' : 'week'}
                    onView={() => {}}
                    onNavigate={(action, newDate) => {
                        if (action === 'DATE' && newDate) return setDate(newDate);
                        setDate(
                            moment(date)
                                .add(action === 'NEXT' ? 1 : action === 'PREV' ? -1 : 0, day ? 'days' : 'weeks')
                                .toDate()
                        );
                    }}
                    hideRange
                    noMargin
                    noIcon
                    noBorder
                />
                {(calendar ? isLoadingCal : isLoadingList) && <Spinner suppressMargin delay />}
            </AutoRow>
            <AutoRow itemSpacing="small">
                <Tooltip title="list">
                    <Link to={{ ...location, pathname: '/meetings' }}>
                        <CircleButton width="auto" secondary={!!calendar}>
                            <ListIcon />
                        </CircleButton>
                    </Link>
                </Tooltip>
                <TwoButtonRow>
                    <Tooltip title="week">
                        <Link to={{ ...location, pathname: '/meetings/calendar' }}>
                            <CircleButton width="auto" secondary={!calendar || day} onClick={() => setDay(false)}>
                                <CalWeekIcon />
                            </CircleButton>
                        </Link>
                    </Tooltip>

                    <Tooltip title="day">
                        <Link to={{ ...location, pathname: '/meetings/calendar' }}>
                            <CircleButton width="auto" secondary={!calendar || !day} onClick={() => setDay(true)}>
                                <CalDayIcon />
                            </CircleButton>
                        </Link>
                    </Tooltip>
                </TwoButtonRow>
            </AutoRow>
        </Row>
    );
}
