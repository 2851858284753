import React, { ImgHTMLAttributes } from 'react';
import styled from 'styled-components';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import partition from 'lodash/partition';

import { useAttendees } from 'stores/attendees';
import { useUser } from 'providers/user';

import {
    LocalVideo,
    RemoteVideo,
    useRemoteVideoTileState,
    useRosterState,
    Grid,
    Cell,
    useFeaturedTileState,
    useActiveSpeakersState,
    useContentShareState,
    ContentShare,
    MicrophoneActivity,
    ZoomIn,
    useLocalVideo,
} from 'amazon-chime-sdk-component-library-react';

import { getName } from '../utils';

import { Dialog, Tooltip } from '@material-ui/core';
import SmallCircleButton from 'components/ui/SmallCircleButton';
import colorFns from 'colorFns';

const GalleryGrid = styled(Grid)<{ firstPage?: boolean }>`
    min-height: 200px;

    > * {
        position: relative;
    }

    video {
        border-radius: 8px;
    }

    ${({ firstPage }) =>
        firstPage
            ? `> *:first-child {
        grid-column: auto/span 2;
        grid-row: auto/span 2;
    }`
            : ''}
`;

const AlwaysVisibleLocalVideo = styled(LocalVideo)`
    display: block;
`;

const ZoomInOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    cursor: pointer;

    background: ${colorFns.pureBlack.alpha(0.3)};
    opacity: 0;
    transition: all 0.3s ease-in-out;

    &:hover {
        opacity: 1;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
        width: 4rem;
        color: ${colorFns.pureWhite.alpha(0.75)};
    }
`;

const MicActivity = styled(MicrophoneActivity)`
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 0.5rem;
`;

const BasicList = styled.ul`
    margin: 0;
    padding: 0;
    list-style-type: none;
`;

const withIndex = <TItem extends any>(fn: (item: TItem, idx: number) => boolean) => {
    let index = 0;
    return (item: TItem) => fn(item, index++);
};

const PageButton = styled(
    ({ names, onClick, className }: { names: string[]; onClick: () => void; className?: string }) => (
        <Tooltip
            title={
                <BasicList>
                    {names.map(n => (
                        <li key={n}>{n}</li>
                    ))}
                </BasicList>
            }
        >
            <SmallCircleButton secondary={false} className={className} onClick={onClick}>
                +{Math.min(names.length, 9)}
            </SmallCircleButton>
        </Tooltip>
    )
)``;

const PrevPageButton = styled(PageButton)`
    left: 0;
    transform: translate(-50%, 50%);
`;

const NextPageButton = styled(PageButton)`
    right: 0;
    transform: translate(50%, 50%);
`;

const GalleryContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100;

    > ${PageButton} {
        position: absolute;
        bottom: 0;
    }
`;

const DUMMY_TILE_ID = -1;

const CenteredImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;

    & > img {
        max-height: 80px;
        max-width: 80px;
    }
`;

const VoiceUserImg = ({ color, alt, ...props }: ImgHTMLAttributes<HTMLImageElement>) => (
    <CenteredImageContainer>
        <img {...props} alt={alt} />
    </CenteredImageContainer>
);

export default function VideoGallery({ joinInfo }: { joinInfo: any }) {
    const state = useRemoteVideoTileState();
    const tileIds = state.tiles;

    // self
    const { user } = useUser();
    const selfChimeId = joinInfo.attendeeInfo.AttendeeId;
    const { isVideoEnabled: selfVideoEnabled } = useLocalVideo();

    // featured
    const { tileId: featuredTileId } = useFeaturedTileState();
    const [mostActiveSpeakerId] = useActiveSpeakersState();
    const activeSpeakerId = featuredTileId ?? mostActiveSpeakerId === selfChimeId ? null : mostActiveSpeakerId;
    //featuredId never is yourself, activeSpeakerId can be yourself

    // screen share
    const { sharingAttendeeId } = useContentShareState();
    const [fullscreen, setFullscreen] = React.useState(false);
    React.useEffect(() => {
        if (!sharingAttendeeId) setFullscreen(false);
    }, [sharingAttendeeId]);

    // gallery tiles
    const withoutFeatureTiles = tileIds.filter(id => id !== featuredTileId).sort((a, b) => a - b);

    // attendees
    const { roster } = useRosterState();
    const { attendees } = useAttendees();
    const attendeesByExtId = keyBy(attendees || [], attendee => `bizly-${attendee.userId}`);
    const chimeIdToAttendee: Partial<{
        [id: string]: BizlyAPI.Attendee;
    }> = mapValues(roster, person => attendeesByExtId[person.externalUserId ?? '']);

    const tileIdToAttendee = (tileId: string | number) => {
        const chimeId = state.tileIdToAttendeeId[tileId];
        return chimeIdToAttendee[chimeId];
    };

    const videoAttendeesSet = new Set(
        tileIds.map(tileId => {
            const attendeeUserId = tileIdToAttendee(tileId)?.userId;
            return attendeeUserId ? `bizly-${attendeeUserId}` : undefined;
        })
    );
    const voiceAttendees = Object.values(roster).filter(
        rosterAttendee =>
            !videoAttendeesSet.has(rosterAttendee.externalUserId) &&
            rosterAttendee.chimeAttendeeId !== selfChimeId &&
            rosterAttendee.chimeAttendeeId !== activeSpeakerId
    );

    // gallery pages
    type TFirst = 'CONTENT_SHARE' | 'FEATURED' | 'SELF';

    const firstSection = [
        ...(sharingAttendeeId ? ['CONTENT_SHARE'] : []),
        ...(featuredTileId || activeSpeakerId ? ['FEATURED'] : []),
        'SELF',
    ] as TFirst[];

    const [firstPage, rest] = partition(
        [...withoutFeatureTiles, ...voiceAttendees],
        withIndex((_, idx) => idx <= 5 - firstSection.length)
    );

    const [page, setPage] = React.useState(0);
    const pageItems = page === 0 ? firstPage : rest.slice((page - 1) * 9, page * 9);

    const names = [...firstSection, ...withoutFeatureTiles].map(id => {
        if (typeof id === 'string') {
            if (id === 'CONTENT_SHARE' && sharingAttendeeId)
                return getName(tileIdToAttendee(sharingAttendeeId.replace('#content', ''))) + 'screen';
            if (id === 'FEATURED' && (featuredTileId || activeSpeakerId)) {
                if (featuredTileId) return getName(tileIdToAttendee(featuredTileId));
                if (activeSpeakerId) return getName(chimeIdToAttendee[activeSpeakerId]);
            }
            if (id === 'SELF') return getName(user);
            return '';
        }
        return getName(tileIdToAttendee(id));
    });

    const namesPrev = names.slice(0, 6 + (page - 1) * 9);
    const namesNext = names.slice(6 + page * 9);

    return (
        <GalleryContainer style={{ width: '100%', height: '100%', position: 'relative' }}>
            <GalleryGrid
                gridGap=".25rem"
                gridAutoFlow="row"
                gridTemplateColumns="1fr 1fr 1fr"
                gridTemplateRows="1fr 1fr 1fr"
                firstPage={page === 0}
            >
                {page === 0 && (
                    <>
                        {sharingAttendeeId && (
                            <Cell key={'content share'}>
                                <ContentShare />
                                <ZoomInOverlay onClick={() => setFullscreen(true)}>
                                    <ZoomIn />
                                </ZoomInOverlay>
                            </Cell>
                        )}

                        {(featuredTileId || activeSpeakerId) && (
                            <Cell key="featured">
                                {featuredTileId ? (
                                    <>
                                        <RemoteVideo
                                            tileId={featuredTileId}
                                            name={getName(tileIdToAttendee(featuredTileId))}
                                        />
                                        <MicActivity attendeeId={state.tileIdToAttendeeId[featuredTileId]} />
                                    </>
                                ) : activeSpeakerId ? (
                                    <>
                                        <VoiceUserImg
                                            src={chimeIdToAttendee[activeSpeakerId]?.imageUrl ?? ''}
                                            alt={getName(chimeIdToAttendee[activeSpeakerId])}
                                        />
                                        <RemoteVideo
                                            tileId={DUMMY_TILE_ID}
                                            name={getName(chimeIdToAttendee[activeSpeakerId])}
                                        />
                                        <MicActivity attendeeId={activeSpeakerId} />
                                    </>
                                ) : null}
                            </Cell>
                        )}

                        <Cell key={0}>
                            {!selfVideoEnabled && <VoiceUserImg src={user?.imageUrl ?? ''} alt={user?.email ?? ''} />}
                            <AlwaysVisibleLocalVideo nameplate={getName(user)} />
                            <MicActivity attendeeId={selfChimeId} />
                        </Cell>
                    </>
                )}
                {pageItems.map(tile =>
                    typeof tile === 'number' ? (
                        <Cell key={tile}>
                            <RemoteVideo tileId={tile} name={getName(tileIdToAttendee(tile))} />
                            <MicActivity attendeeId={state.tileIdToAttendeeId[tile]} />
                        </Cell>
                    ) : (
                        <Cell key={tile.chimeAttendeeId}>
                            <VoiceUserImg
                                src={chimeIdToAttendee[tile.chimeAttendeeId]?.imageUrl ?? ''}
                                alt={getName(chimeIdToAttendee[tile.chimeAttendeeId])}
                            />
                            <RemoteVideo
                                tileId={DUMMY_TILE_ID}
                                name={getName(chimeIdToAttendee[tile.chimeAttendeeId])}
                            />
                            <MicActivity attendeeId={tile.chimeAttendeeId} />
                        </Cell>
                    )
                )}
            </GalleryGrid>
            {fullscreen && sharingAttendeeId && (
                <Dialog open fullScreen onClick={() => setFullscreen(false)}>
                    <ContentShare />
                    <MicActivity attendeeId={sharingAttendeeId} />
                </Dialog>
            )}
            {page > 0 && namesPrev.length > 0 && (
                <PrevPageButton names={namesPrev} onClick={() => setPage(curPage => curPage - 1)} />
            )}
            {namesNext.length > 0 && (
                <NextPageButton names={namesNext} onClick={() => setPage(curPage => curPage + 1)} />
            )}
        </GalleryContainer>
    );
}
