import React from 'react';
import styled, { css } from 'styled-components';

import GenericNavButtons from 'components/GenericNavButtons';
import { ReactComponent as AgendaIconSvg } from 'images/icons/agenda.svg';
import { ReactComponent as ChatIconSvg } from 'images/icons/message_bubble.svg';
import { ReactComponent as GuestsIconSvg } from 'images/icons/guests.svg';
import colorFns from 'colorFns';

const iconStyles = css`
    height: 16px;
    width: 15px;
    color: ${colorFns.pureWhite};
    margin-left: 18px;
    margin-right: 9px;
`;

const AgendaIcon = styled(AgendaIconSvg)`
    ${iconStyles}
`;
const ChatIcon = styled(ChatIconSvg)`
    ${iconStyles}
`;
const GuestsIcon = styled(GuestsIconSvg)`
    ${iconStyles}
`;

export default function({
    onBack,
    view,
    setView,
}: {
    onBack?: () => void;
    view: 'video' | 'chat' | 'guests';
    setView: (view: 'video' | 'chat' | 'guests') => void;
}) {
    return (
        <GenericNavButtons
            onBack={onBack}
            links={[
                {
                    key: 'video',
                    active: view === 'video',
                    onClick: view === 'video' ? undefined : () => setView('video'),
                    children: <AgendaIcon />,
                },
                {
                    key: 'chat',
                    active: view === 'chat',
                    onClick: view === 'chat' ? undefined : () => setView('chat'),
                    children: <ChatIcon />,
                },
                {
                    key: 'guests',
                    active: view === 'guests',
                    onClick: view === 'guests' ? undefined : () => setView('guests'),
                    children: <GuestsIcon />,
                },
            ]}
        />
    );
}
