import React, { useEffect, useState, useMemo } from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';

const MIN_WIDTH = 1200;

const ResponsiveMetaTag = () => {
    const getViewportWidth = () =>
        Math.min(
            window.innerWidth || MIN_WIDTH,
            window.outerWidth || MIN_WIDTH,
            document.documentElement.clientWidth || MIN_WIDTH
        );
    const [viewportWidth, setViewportWidth] = useState<number>(getViewportWidth());

    useEffect(() => {
        const updateViewportWidth = () => setViewportWidth(getViewportWidth());
        updateViewportWidth();
        window.addEventListener('resize', updateViewportWidth);

        return () => window.removeEventListener('resize', updateViewportWidth);
    }, []);

    const zoomScale = useMemo(() => (viewportWidth > 750 ? 1 : viewportWidth / 750), [viewportWidth]);

    return (
        <ReactHelmet>
            <meta
                name="viewport"
                content={`width=device-width,initial-scale=${zoomScale},maximum-scale=1,shrink-to-fit=no,viewport-fit=cover`}
            />
            <style>{`
                #root > div { min-width: auto !important; }
                html {
                    -webkit-text-size-adjust: none;
                }
            `}</style>
        </ReactHelmet>
    );
};

export default ResponsiveMetaTag;
