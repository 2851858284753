import React from 'react';

import { Copy, ExternalLink } from 'ui';

const TAC_URL = 'https://www.bizly.com/documents/bizly-terms-of-use.pdf';
const PRIV_POLICY_URL = 'https://www.bizly.com/documents/bizly-privacy-policy.pdf';

export default function Policies({ prefix }: { prefix: string }) {
    return (
        <Copy small>
            {prefix}, I accept the{' '}
            <ExternalLink href={TAC_URL} openInNewTab>
                Terms and Conditions
            </ExternalLink>{' '}
            and confirm that I have read the{' '}
            <ExternalLink href={PRIV_POLICY_URL} openInNewTab>
                Privacy Policy
            </ExternalLink>
            .
        </Copy>
    );
}
