import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Dialog from '@material-ui/core/Dialog';
import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';

import Button from '../ui/Button';
import useKey from '../../hooks/useKey';
import { H2Headline } from '../ui/Headline';
import Form from '../Form';
import { Row, Spacer } from '../../ui';
import TextButton from '../ui/Button/TextButton';
import pluckFileName from '../../utils/pluckFileName';

import { fields, schema, schemaWithoutGuestrooms } from './uploadFormSchema';

const Container = styled.div`
    width: 530px;
    padding: 32px;
    flex: 1;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    position: relative;
`;

const Headline = styled(H2Headline)`
    margin-bottom: 32px;
`;

const Box = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.listItemBackground)};
    height: 60px;
    margin: 12px 0;
    padding: 0 16.9px;
`;

const PlaceholderText = styled.p`
    font-size: 15px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};
`;

type UploadBoxProps = {
    fileName?: string;
    target: string;
    onUpload: (files: File[], target: string) => void;
};

const UploadBox = ({ fileName, target, onUpload }: UploadBoxProps) => {
    const onDrop = (files: File[]) => onUpload(files, target);
    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    const { color: inputColor, ...inputProps } = getInputProps();
    const { color: rootColor, ...rootProps } = getRootProps();

    return (
        <Box>
            {fileName ? <p>{fileName}</p> : <PlaceholderText>No file selected</PlaceholderText>}
            <input {...inputProps} />
            <div {...rootProps}>
                <Button onClick={() => null} small secondary width={92}>
                    {fileName ? 'Replace' : 'Select File'}
                </Button>
            </div>
        </Box>
    );
};

const SmallCopy = styled.p`
    font-size: 13px;
`;

const RowRight = styled(Row)`
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
`;

type StagedDocs = {
    contract1: { name?: string };
    contract2: { name?: string };
};

const UploadDocModal = ({
    open,
    closeModal,
    onFormUpdate,
    formValues,
    existingDocs,
    handleSubmit,
    teamCompliance,
}: {
    open: boolean;
    closeModal: () => void;
    onFormUpdate: (estimatesForm: object) => void;
    formValues: {
        cateringCost?: string | number;
        guestroomCost?: string | number;
        guestroomNights?: string | number;
        guests?: string | number;
        roomCost?: string | number;
        total?: string | number;
    };
    existingDocs: {
        contract1?: string;
        contract2?: string;
    };
    handleSubmit: (stagedDocs: StagedDocs) => void;
    teamCompliance: Bizly.TeamCompliance;
}) => {
    const [stagedDocs, setStagedDocs] = useState<StagedDocs>({ contract1: {}, contract2: {} });
    const { contract1, contract2 } = stagedDocs;
    const { enqueueSnackbar } = useSnackbar();

    useKey('Escape', closeModal);

    const handleStagingDoc = async (files: File[], target: string) => {
        const maxMB = 41943040; // 40mb
        const file = files[0] || {};
        if (file.size > maxMB) {
            enqueueSnackbar(`File ${file.name} is too large.`, {
                variant: 'error',
            });
        } else {
            setStagedDocs({ ...stagedDocs, [target]: file });
        }
    };

    return (
        <Dialog open={open} maxWidth="md" onClose={closeModal}>
            <Container>
                <Headline>Upload Signed Documents</Headline>

                <UploadBox
                    onUpload={handleStagingDoc}
                    fileName={contract1.name || pluckFileName(existingDocs.contract1)}
                    target={'contract1'}
                />
                <UploadBox
                    onUpload={handleStagingDoc}
                    fileName={contract2.name || pluckFileName(existingDocs.contract2)}
                    target={'contract2'}
                />

                <SmallCopy>Max file upload size: 40MB.</SmallCopy>

                <Spacer />

                <Form
                    fields={fields}
                    schema={teamCompliance?.guestroomsDisabled ? schemaWithoutGuestrooms : schema}
                    value={formValues}
                    onChange={(newValue: { value: object }) => onFormUpdate(newValue.value)}
                    isNested={false}
                    readonly={false}
                    densePadding={false}
                />

                <RowRight itemSpacing="smallish">
                    <TextButton onClick={closeModal} secondary>
                        Cancel
                    </TextButton>

                    <TextButton
                        onClick={() => handleSubmit(stagedDocs)}
                        disabled={!existingDocs.contract1 && !(contract1.name || contract2.name)}
                    >
                        Upload
                    </TextButton>
                </RowRight>
            </Container>
        </Dialog>
    );
};

export default UploadDocModal;
