import React from 'react';

import { StandardInput } from './StandardInput';
import { AlignedRow } from '../ui';

import { useDebounce } from '../util';

const EditableContent = ({
    children,
    headline,
    placeholder,
    onChange,
    onClick = undefined,
    readOnly = false,
    dynamicWidth = false,
}) => {
    const [draft, setDraft] = React.useState(headline);
    const node = React.useRef();

    const [handleSubmit] = useDebounce(onChange, 200);

    return (
        <AlignedRow
            ref={node}
            onClick={e => {
                if (onClick) onClick(e);
            }}
            style={{
                width: dynamicWidth ? '100%' : 'unset',
                ...(dynamicWidth ? { minWidth: 0 } : {}),
            }}
        >
            {!readOnly && (
                <StandardInput
                    style={{ width: '200px' }}
                    value={draft}
                    onChange={e => {
                        setDraft(e.target.value);
                        handleSubmit(e.target.value);
                    }}
                    margin="dense"
                    placeholder={placeholder || 'Enter text here.'}
                    variant="outlined"
                />
            )}
            {readOnly && children}
        </AlignedRow>
    );
};

export default EditableContent;
