import { format, parseISO } from 'date-fns';

import { TProposalForm } from './types';

type TProposalFormStatus =
    | 'Inquiry Invalid'
    | 'Inquiry Submitted'
    | 'Inquiry Cancelled'
    | 'Inquiry Rejected Reversible'
    | 'Inquiry Rejected Terminary'
    | 'Proposal Submitted'
    | 'Proposal Accepted'
    | 'Proposal Rejected'
    | 'Proposal Cancelled Reversible'
    | 'Proposal Cancelled Terminary';

export const submittedProposal = (proposal: TProposalForm['proposal'] = {}) => proposal.status !== 'draft';

export const getProposalFormStatus = ({
    proposal = {},
    inquiry = {},
}: Partial<TProposalForm>): TProposalFormStatus | undefined => {
    const { status: proposalStatus } = proposal;
    const { status: inquiryStatus, isValid } = inquiry;

    if (!proposalStatus || !inquiryStatus) {
        return undefined;
    }

    if (submittedProposal(proposal)) {
        switch (proposalStatus) {
            case 'submitted':
                return 'Proposal Submitted';
            case 'accepted':
                return 'Proposal Accepted';
            case 'rejected':
                return 'Proposal Rejected';
            case 'cancelled':
                return isValid ? 'Proposal Cancelled Reversible' : 'Proposal Cancelled Terminary';
            default:
                return undefined;
        }
    } else {
        switch (inquiryStatus) {
            case 'submitted':
                return isValid ? 'Inquiry Submitted' : 'Inquiry Invalid';
            case 'rejected':
                return isValid ? 'Inquiry Rejected Reversible' : 'Inquiry Rejected Terminary';
            case 'cancelled':
                return 'Inquiry Cancelled';
            default:
                return isValid ? 'Inquiry Submitted' : 'Inquiry Cancelled';
        }
    }
};

export type TAction = 'accept' | 'reject';

const rejectedInquiryHeading = (action?: TAction) => () =>
    action === 'reject' ? 'The inquiry has already been rejected' : 'The inquiry has been rejected';

const cancelledProposalHeading = (action?: TAction) => () =>
    action === 'reject' ? 'The proposal has already been cancelled' : 'The proposal has been cancelled';

export type TStatusAttributes = {
    skipToStatus: boolean;

    noProposal: boolean;
    readonly: boolean;

    canView: boolean;
    canCreate: boolean;
    canSubmit: boolean;
    isResubmit: boolean;
    canWithdraw: boolean;

    heading: (proposalForm: Partial<TProposalForm>) => string;
    copy: (proposalForm: Partial<TProposalForm>) => string;
};

export const getStatusAttributes = (
    status?: TProposalFormStatus,
    action?: TAction,
    userChangedStatus?: boolean
): TStatusAttributes => {
    const defaults = {
        skipToStatus: true,

        noProposal: false,
        readonly: false,

        canView: true,
        canCreate: false,
        canSubmit: false,
        isResubmit: false,
        canWithdraw: false,

        heading: () => '',
        copy: () => '',
    };

    switch (status) {
        case 'Inquiry Submitted':
            return {
                ...defaults,
                skipToStatus: false,
                canSubmit: true,
            };

        case 'Inquiry Invalid':
            return {
                ...defaults,
                noProposal: true,
                canView: false,

                heading: () => 'Unfortuntely, this inquiry is no longer open to new proposals.',
            };

        case 'Inquiry Rejected Reversible':
            return {
                ...defaults,
                skipToStatus: action === 'reject',
                noProposal: false,
                canCreate: true,

                heading: rejectedInquiryHeading(action),
            };

        case 'Inquiry Rejected Terminary':
            return {
                ...defaults,
                noProposal: true,
                canView: false,

                heading: rejectedInquiryHeading(action),
            };
        case 'Inquiry Cancelled':
            return {
                ...defaults,
                noProposal: true,
                canView: false,

                heading: () => 'Unfortunately, the inquiry has been withdrawn.',
            };
        case 'Proposal Submitted':
            return {
                ...defaults,
                canSubmit: true, // no need to worry about isValid because accepting a proposal rejects all others
                isResubmit: true,
                canWithdraw: true,

                heading: () =>
                    userChangedStatus
                        ? 'Congrats! Your proposal has been submitted.'
                        : 'Your proposal has been submitted.',

                copy: ({ client = {}, proposal = {} }: Partial<TProposalForm>) =>
                    `${[client.firstName, client.lastName].join(' ')} has until ${format(
                        parseISO(proposal.expiryDate || ''),
                        'EEEE, MMMM dd, yyyy'
                    )} to respond.`,
            };
        case 'Proposal Accepted':
            return {
                ...defaults,
                readonly: true,

                heading: ({ client = {} }: Partial<TProposalForm>) =>
                    `Congrats! Your proposal was accepted by ${[client.firstName, client.lastName].join(' ')}.`,
            };
        case 'Proposal Rejected':
            return {
                ...defaults,
                readonly: true,

                heading: () => 'Unfortunately, your proposal was rejected.',
            };
        case 'Proposal Cancelled Terminary':
            return {
                ...defaults,
                readonly: true,

                heading: cancelledProposalHeading(action),
            };
        case 'Proposal Cancelled Reversible':
            return {
                ...defaults,
                canSubmit: true,

                heading: cancelledProposalHeading(action),
            };
        default:
            return defaults;
    }
};
