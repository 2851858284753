import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { Row } from 'ui';
import { SIDE_NAV_WIDTH } from 'components/SideNav';
import TextButton from './ui/Button/TextButton';
import colorFns from 'colorFns';

const Fixed = styled(Row)`
    position: fixed;
    left: ${SIDE_NAV_WIDTH}px;
`;
const Hidden = styled(Row)`
    visibility: hidden;
`;

const Banner = styled(Row)<{ warning?: boolean }>`
    padding: 8px 0;

    ${({ warning, ...props }) =>
        warning
            ? `    
    background: ${colorFns.snackbarError(props)};
    color: ${colorFns.pureWhite(props)};
`
            : `
    background: ${colorFns.secondaryTextAction(props)};
    color: ${colorFns.pureBlack(props)};
                `}
`;

const getTimeLeft = (time: Date) => {
    const now = moment();
    const end = moment(time);
    const timeLeft = moment.duration(end.diff(now));
    return timeLeft;
};

export default function CountdownBanner({
    time,
    format = 'mm:ss',
    showMilliseconds,
    message = time => time ?? '',
    showLinkMilliseconds = 0,
    linkText = () => '',
    onLink,
    warning,
}: {
    time: Date;
    format?: string;
    showMilliseconds?: number;
    message?: (time?: string) => string;
    showLinkMilliseconds?: number;
    linkText?: (over: boolean) => React.ReactNode;
    onLink?: () => void;
    warning?: boolean;
}) {
    const [countdown, setCountdown] = React.useState(getTimeLeft(time));
    const countdownFormatted = countdown.asMilliseconds() >= 0 ? countdown.format(format, { trim: false }) : undefined;

    React.useEffect(() => {
        const handle = setInterval(() => {
            setCountdown(getTimeLeft(time));
        }, 1000);

        return () => clearInterval(handle);
    }, [time]);

    const countdownDisplay = (
        <Banner alignItems="center" justifyContent="center" warning={warning} itemSpacing="medium">
            <span>{message(countdownFormatted)}</span>
            {onLink && countdown.asMilliseconds() <= showLinkMilliseconds && (
                <TextButton onClick={onLink}> {linkText(countdown.asMilliseconds() <= 0)}</TextButton>
            )}
        </Banner>
    );

    return countdown.asMilliseconds() <= (showMilliseconds ?? countdown.asMilliseconds()) ? (
        <>
            <Fixed>{countdownDisplay}</Fixed>
            <Hidden>{countdownDisplay}</Hidden>
        </>
    ) : null;
}
