import createStore from 'stores';
import shallow from 'zustand/shallow';
import moment from 'moment';

import { isEmptyString } from 'components/ProposalForm/utils';
import { userTimeZone } from 'utils/moment';
import { RRule, Frequency } from 'rrule';

import { meetingsActions } from 'stores/meetings';
import { addAttendees, loadAttendees, removeAttendee } from 'api/attendees';

import { MOVE_UP, MOVE_DOWN, TDirection } from 'components/Schedule/ManageAgenda';

import { fixDates, TBasicInfoValue } from '../BasicInfoForm';
import { TInviteValue } from '../InvitesForm';
import { emailIsValid } from 'utils';
import { createMeeting, publishMeeting, updateMeeting } from 'api/meetings';
import { TVMValue } from 'components/Schedule/VirtualMeetingField';
import { connectorToId, getServiceProviderId, idToConnector } from 'utils/virtualMeeting';
import { weekdayOfMonth } from '../utils';
import { isCount, isDate } from '../components/RecurrenceForm';

export enum ESteps {
    basic,
    agenda,
    attendees,
    invites,
}

type TErrors<T> = Partial<Record<keyof T, string>>;
const hasErrors = <T extends Partial<Record<string, string>>>(errors: T) => Object.values(errors).some(v => v);

type TAgendaErrors = TErrors<BizlyAPI.ScheduleAgendaEntry> & { count?: string };
type TAttendeeErrors = { invalidEmails?: string; count?: string };

type State = {
    stepIdx: number;
    stepList: ESteps[];

    isPublished: boolean | null;

    basicInfo: TBasicInfoValue;
    basicInfoErrors: TErrors<TBasicInfoValue>;
    additionalRequired: (keyof TBasicInfoValue)[];
    hasRecurrence?: boolean;

    curAgendaId: number;
    agenda: BizlyAPI.ScheduleAgendaEntry[];
    agendaErrors: TAgendaErrors;

    loadedAttendees: BizlyAPI.Attendee[];
    attendeesChanged: boolean;
    attendees: BizlyAPI.BasicAttendee[];
    attendeeErrors: TAttendeeErrors;

    invite: TInviteValue;
    inviteDescriptionChanged: boolean;

    loading?: boolean;
    saving?: boolean;
    saved?: boolean;
    publishing?: boolean;

    playbookChanged?: boolean;
    playbookKey: number;
};
type Store = State;

const initialState: State = {
    stepIdx: 0,
    stepList: [ESteps.basic, ESteps.agenda, ESteps.attendees],

    isPublished: null,

    basicInfo: { timeZone: userTimeZone },
    basicInfoErrors: {},
    additionalRequired: [],

    curAgendaId: 0,
    agenda: [],
    agendaErrors: {},

    loadedAttendees: [],
    attendeesChanged: false,
    attendees: [],
    attendeeErrors: {},

    invite: {
        type: 'simple',
    },
    inviteDescriptionChanged: false,

    loading: false,
    saving: false,
    saved: true,
    publishing: false,

    playbookChanged: false,
    playbookKey: 0,
};

export const [useCreateMeeting, createMeetingApi] = createStore<Store>(() => initialState);

const { setState, getState } = createMeetingApi;

const agendaFormActions = {
    setAgenda: (agenda: BizlyAPI.ScheduleAgendaEntry[]) => {
        setState({ agenda, playbookChanged: true });
    },
    addAgendaItem: (meetingId: string = 'new') => {
        setState(prevState => ({
            ...prevState,
            agenda: [...prevState.agenda, { id: prevState.curAgendaId, duration: 0 }],
            curAgendaId: prevState.curAgendaId + 1,
            playbookChanged: true,
        }));
    },
    updateAgendaItem: (updatedAgendaItem: { value: BizlyAPI.ScheduleAgendaEntry }, targetIdx: number) => {
        setState(prevState => {
            const updatedAgenda = prevState.agenda.slice();
            updatedAgenda[targetIdx] = updatedAgendaItem.value;
            return {
                ...prevState,
                agenda: updatedAgenda,
                playbookChanged: true,
            };
        });
    },
    arrangeAgendaItem: (targetIdx: number, direction: TDirection) =>
        setState(prevState => {
            const { agenda = [] } = prevState;
            const updatedAgenda = agenda.slice();
            const targetEntry = { ...updatedAgenda[targetIdx] };

            if (direction === MOVE_UP && !!updatedAgenda[targetIdx - 1]) {
                const entryBefore = { ...updatedAgenda[targetIdx - 1] };
                updatedAgenda[targetIdx - 1] = targetEntry;
                updatedAgenda[targetIdx] = entryBefore;
            } else if (direction === MOVE_DOWN && !!updatedAgenda[targetIdx + 1]) {
                const entryAfter = { ...updatedAgenda[targetIdx + 1] };
                updatedAgenda[targetIdx + 1] = targetEntry;
                updatedAgenda[targetIdx] = entryAfter;
            }

            return {
                ...prevState,
                agenda: updatedAgenda,
                playbookChanged: true,
            };
        }),
    removeAgendaItem: (targetIdx: number) =>
        setState(prevState => {
            const { agenda = [] } = prevState;
            const updatedAgenda = agenda.filter((entry: any, idx: number) => idx !== targetIdx);

            return {
                ...prevState,
                agenda: updatedAgenda,
                playbookChanged: true,
            };
        }),
    setAgendaErrors: (agendaErrors: TAgendaErrors) => {
        setState({ agendaErrors });
    },
    validateAgenda: () => {
        const agendaErrors: TAgendaErrors = {};
        const { agenda } = getState();

        agenda.forEach(item => {
            if (isEmptyString(item.title)) agendaErrors.title = 'Agenda title is required';
            if (Number.isNaN(Number(item.duration))) agendaErrors.duration = 'Agenda duration is required';
            // if (isEmptyString(item.description)) agendaErrors.description = 'Agenda description is required';
        });

        if (!agenda.length) agendaErrors.count = 'Agenda is required';

        setState({ agendaErrors });
    },
};

const navActions = {
    setSteps: (stepList: ESteps[]) => {
        setState({ stepList });
    },
    prevStep: () => {
        setState({ stepIdx: Math.max(getState().stepIdx - 1, 0) });
    },
    nextStep: () => {
        const curStep = selCurStep(getState());
        const maxStep = selMaxStep(getState());
        stepToValidator[curStep]();
        if (!hasErrors(selectErrors(curStep))) {
            setState({ stepIdx: Math.min(getState().stepIdx + 1, maxStep) });
        }
    },
    goToStep: (toStep: ESteps) => {
        setState({ stepIdx: getState().stepList.findIndex(step => step === toStep) });
    },
    reset: () => setState(initialState),
};

export const selCurStep = (state: State) => state.stepList[state.stepIdx];
export const selMaxStep = (state: State) => state.stepList.length - 1;

const basicFormActions = {
    setBasicForm: (basicInfo: TBasicInfoValue, unchanged?: boolean) => {
        setState({ basicInfo, basicInfoErrors: {}, ...(!unchanged && { playbookChanged: true }) });
    },
    mergeBasicForm: (basicInfo: TBasicInfoValue) => {
        setState({ basicInfo: { ...getState().basicInfo, ...basicInfo }, playbookChanged: true });
    },
    initDefaultDate: () => {
        if (!getState().basicInfo.startDate) {
            setState({
                basicInfo: {
                    ...getState().basicInfo,
                    startDate: moment().toDate(),
                    startTime: moment()
                        .startOf('day')
                        .add(moment().get('hours') + 1, 'hours')
                        .format('HH:mm:ss'),
                },
            });
            setState({ basicInfo: { ...getState().basicInfo, ...fixDates(getState().basicInfo, 'start') } });
            createMeetingActions.setSaved(true);
        }
    },
    setBasicFormErrors: (basicInfoErrors: TErrors<TBasicInfoValue>) => {
        setState({ basicInfoErrors });
    },
    validateBasicForm: () => {
        const basicInfoErrors: TErrors<TBasicInfoValue> = {};
        const data = getState().basicInfo;

        if (isEmptyString(data.name)) basicInfoErrors.name = 'Meeting name is required';
        if (isEmptyString(data.description)) basicInfoErrors.description = 'Meeting description is required';
        if (isEmptyString(data.purpose)) basicInfoErrors.purpose = 'Meeting objective is required';

        if (getState().additionalRequired.length > 0) {
            const errors: { [key in keyof TBasicInfoValue]: false | string } = {
                internalReference: isEmptyString(data.internalReference) && 'Internal reference is required',
                type: isEmptyString(data.type) && 'Internal/External type is required',
                costCenter: isEmptyString(data.costCenter) && 'Cost center is required',
                location: isEmptyString(data.location?.location) && 'Location is required',
            };

            getState().additionalRequired.forEach(field => {
                const error = errors[field];
                if (error) {
                    basicInfoErrors[field] = error;
                }
            });
        }

        setState({ basicInfoErrors });
    },
};

const attendeeFormActions = {
    setAttendees: (attendees: BizlyAPI.BasicAttendee[]) => {
        setState({
            attendees,
            attendeesChanged: false,
        });
    },
    addAttendee: (newAttendee: BizlyAPI.BasicAttendee) => {
        if (getState().attendees.some(attendee => attendee.email === newAttendee.email)) return;
        setState({
            attendees: [...getState().attendees, newAttendee],
            attendeesChanged: true,
        });
    },
    addAttendees: (newAttendees: BizlyAPI.BasicAttendee[]) => {
        const existing = new Set(getState().attendees.map(attendee => attendee.email));
        const newAdditions = newAttendees.filter(attendee => !existing.has(attendee.email));

        setState({
            attendees: [...getState().attendees, ...newAdditions],
            attendeesChanged: true,
        });
    },
    delAttendee: (email?: string) => {
        setState({
            attendees: getState().attendees.filter(a => a.email !== email),
            attendeesChanged: true,
        });
    },
    mergeAttendee: (attendee: BizlyAPI.BasicAttendee) => {
        setState({
            attendees: getState().attendees.map(a => (a.email === attendee.email ? { ...attendee } : a)),
        });
    },
    validateAttendees: () => {
        const attendeeErrors: TAttendeeErrors = {};
        const data = getState().attendees;

        if (!data || data.length === 0) {
            attendeeErrors.count = 'Your meeting has no attendees.';
        }
        const invalidEmails = data?.filter(attendee => !emailIsValid(attendee.email));
        if (invalidEmails?.length > 0) {
            attendeeErrors.invalidEmails = `Some attendee emails are invalid: ${invalidEmails
                .map(attendee => attendee.email)
                .join(', ')}.`;
        }

        setState({ attendeeErrors });
    },
};

const inviteActions = {
    setInvite: (invite?: TInviteValue, descriptionChanged?: boolean) => {
        setState({
            invite,
            playbookChanged: true,
            inviteDescriptionChanged: getState().inviteDescriptionChanged || descriptionChanged,
        });
    },
    setInviteType: (type?: TInviteValue['type']) => {
        setState({ invite: { ...getState().invite, type } });
    },
    copyPurpose: () => {
        setState({ invite: { ...getState().invite, description: getState().basicInfo.purpose } });
    },
};

export class ValidationError extends Error {}

const stepToValidator = {
    [ESteps.basic]: basicFormActions.validateBasicForm,
    [ESteps.agenda]: agendaFormActions.validateAgenda,
    [ESteps.attendees]: attendeeFormActions.validateAttendees,
    [ESteps.invites]: () => {},
};

const selectErrors = (step: ESteps) => {
    const stepToError = {
        [ESteps.basic]: getState().basicInfoErrors,
        [ESteps.agenda]: getState().agendaErrors,
        [ESteps.attendees]: getState().attendeeErrors,
        [ESteps.invites]: {},
    };

    return stepToError[step];
};

const loadVM = (virtualMeeting?: Bizly.VirtualMeeting | null) =>
    virtualMeeting &&
    virtualMeeting.serviceProvider?.id !== undefined &&
    !virtualMeeting.link &&
    idToConnector[virtualMeeting.serviceProvider?.id]
        ? {
              ...virtualMeeting,
              link: undefined,
              deferredService: idToConnector[virtualMeeting.serviceProvider?.id],
              notes: 'A link will be created when the meeting is published',
          }
        : virtualMeeting;

const encodeVM = (virtualMeeting?: TVMValue) => {
    if (virtualMeeting?.deferredService) {
        const id = connectorToId[virtualMeeting.deferredService];
        if (id) {
            return {
                serviceProvider: { id },
            };
        }
    }

    if (virtualMeeting?.link) {
        const id = getServiceProviderId(virtualMeeting.link);
        return {
            ...virtualMeeting,
            serviceProvider: { id },
        };
    }

    return virtualMeeting;
};

export type Recurrence = {
    interval: number;
    freq: Frequency.DAILY | Frequency.WEEKLY | Frequency.MONTHLY | Frequency.YEARLY;
    byDay?: number[];
    byMonth?: 'day' | 'weekday';
    ends: null | Date | number;
};

const rruleToRecurrence = (rruleString?: string): { recurrence?: Recurrence } => {
    const recurrenceObject = rruleString ? RRule.fromString('RRULE:' + rruleString) : undefined;
    const recurrenceOptions = recurrenceObject
        ? { ...recurrenceObject?.origOptions, byweekday: recurrenceObject?.options.byweekday }
        : undefined;

    return {
        ...(recurrenceOptions
            ? {
                  recurrence: {
                      freq:
                          recurrenceOptions.freq === undefined ||
                          recurrenceOptions.freq === RRule.HOURLY ||
                          recurrenceOptions.freq === RRule.MINUTELY ||
                          recurrenceOptions.freq === RRule.SECONDLY
                              ? RRule.WEEKLY
                              : recurrenceOptions.freq,
                      interval: recurrenceOptions.interval ?? 1,
                      byDay: !recurrenceOptions.byweekday ? [RRule.SU.weekday] : recurrenceOptions.byweekday,
                      byMonth: recurrenceOptions.bysetpos !== undefined ? 'weekday' : 'day',
                      ends: recurrenceOptions.count ? recurrenceOptions.count : recurrenceOptions.until ?? null,
                  },
              }
            : {}),
    };
};

const meetingToBasicInfo = (meeting: BizlyAPI.Meeting): TBasicInfoValue => {
    const { startsAt, endsAt, location, googlePlaceId, virtualMeeting, recurrence, ...meetingInfo } = meeting;

    const start = moment(startsAt);
    const end = moment(endsAt);

    return {
        ...meetingInfo,
        startDate: start.toDate(),
        endDate: end.toDate(),
        startTime: start.format('HH:mm:ss'),
        endTime: end.format('HH:mm:ss'),

        ...rruleToRecurrence(recurrence?.rruleString),

        ...(location && {
            location: {
                location,
                googlePlaceId: googlePlaceId || undefined,
            },
        }),

        virtualMeeting: loadVM(virtualMeeting) || undefined,
    };
};

const basicInfoToMeeting = (basicInfo: TBasicInfoValue) => {
    const {
        startDate,
        endDate,
        startTime,
        endTime,
        location,
        virtualMeeting,
        recurrence,
        ...basicInfoValues
    } = basicInfo;

    const start = moment(startDate)
        .startOf('day')
        .add(moment.duration(startTime));
    const end = moment(endDate)
        .startOf('day')
        .add(moment.duration(endTime));

    return {
        ...basicInfoValues,
        startsAt: start.format('YYYY-MM-DD HH:mm:ss'),
        endsAt: end.format('YYYY-MM-DD HH:mm:ss'),

        location: location?.location,
        googlePlaceId: location?.googlePlaceId || null,

        virtualMeeting: encodeVM(virtualMeeting),
    };
};

function recurrenceToMeeting(
    recurrence: Recurrence,
    start: moment.Moment,
    timeZone?: string
): { recurrence: { rruleString: string } } | {};
function recurrenceToMeeting(
    recurrence: Recurrence,
    start: moment.Moment,
    timeZone?: string,
    type?: 'current' | 'forward' | 'all'
): { recurrence: { rruleString?: string; applyTo: { past: boolean; future: boolean } } } | {};
function recurrenceToMeeting(
    recurrence: Recurrence,
    start: moment.Moment,
    timeZone?: string,
    type?: 'current' | 'forward' | 'all'
) {
    const recurrenceObject = recurrence
        ? new RRule({
              freq: recurrence.freq,
              interval: recurrence.interval,
              ...(recurrence.freq === RRule.WEEKLY ? { byweekday: recurrence.byDay } : {}),
              ...(recurrence.freq === RRule.MONTHLY
                  ? recurrence.byMonth === 'weekday'
                      ? {
                            byweekday:
                                recurrence.byDay ?? recurrence.byMonth === 'weekday'
                                    ? weekdayOfMonth(start).byDay
                                    : undefined,
                            bysetpos: recurrence.byMonth === 'weekday' ? weekdayOfMonth(start).bySetPos : undefined,
                        }
                      : { bymonthday: recurrence.byMonth === 'day' ? start.date() : undefined }
                  : {}),
              count: isCount(recurrence.ends) ? recurrence.ends : undefined,
              until: isDate(recurrence.ends)
                  ? moment(recurrence.ends)
                        .startOf('day')
                        .toDate()
                  : undefined,
              tzid: timeZone,
          })
        : undefined;

    const applyTo = type
        ? type === 'all'
            ? {
                  past: true,
                  future: true,
              }
            : type === 'forward'
            ? { past: false, future: true }
            : { past: false, future: false }
        : undefined;

    return applyTo && recurrenceObject
        ? {
              recurrence: {
                  rruleString: recurrenceObject.toString().replace('RRULE:', ''),
                  applyTo,
              },
          }
        : applyTo
        ? {
              recurrence: {
                  applyTo,
              },
          }
        : recurrenceObject
        ? {
              recurrence: {
                  rruleString: recurrenceObject.toString().replace('RRULE:', ''),
              },
          }
        : {};
}

const splitAttendees = <T extends BizlyAPI.BasicAttendee, S extends BizlyAPI.Attendee>(
    attendees: T[],
    existingAttendees: S[]
) => {
    const attendeesSet = new Set(attendees.map(attendee => attendee.email));
    const existingAttendeesSet = new Set(existingAttendees.map(attendee => attendee.email));
    const deleteAttendees = existingAttendees.filter(attendee => !attendeesSet.has(attendee.email));
    const newAttendees = attendees.filter(attendee => !existingAttendeesSet.has(attendee.email));

    return { deleteAttendees, newAttendees };
};

createMeetingApi.subscribe(
    () => setState({ saved: false }),
    state => [state.basicInfo, state.agenda, state.attendees],
    shallow
);

export const createMeetingActions = {
    ...navActions,
    ...basicFormActions,
    ...agendaFormActions,
    ...attendeeFormActions,
    ...inviteActions,
    setAdditionalRequired: (additionalRequired: (keyof TBasicInfoValue)[]) => {
        setState({ ...getState(), additionalRequired });
    },

    setChanged: (playbookChanged: boolean) => setState({ playbookChanged }),
    setSaved: (saved: boolean) => setState({ saved }),

    load: async (id: string | number) => {
        if (getState().loading) {
            return;
        }
        setState({
            loading: true,
        });

        const lastStepIdx = loadStep(id);
        try {
            const meeting = await meetingsActions.loadSingle(id);
            const attendees = await loadAttendees(id);

            const basicInfo = meetingToBasicInfo(meeting);

            let curId = getState().curAgendaId;

            basicFormActions.setBasicForm(basicInfo);
            agendaFormActions.setAgenda(meeting.agenda.map(item => ({ ...item, id: curId++ })));
            attendeeFormActions.setAttendees(attendees);
            const published = !!meeting.published;
            const newStepList = published
                ? [ESteps.basic, ESteps.agenda, ESteps.attendees]
                : [ESteps.basic, ESteps.agenda, ESteps.attendees, ESteps.invites];
            setState({
                ...(lastStepIdx !== undefined ? { stepIdx: lastStepIdx > newStepList.length ? 0 : lastStepIdx } : {}),
                stepList: newStepList,
                isPublished: !!meeting.published,
                hasRecurrence: !!meeting.recurrence?.rruleString,
                loadedAttendees: attendees,
                loading: false,
                saved: true,
            });

            return meeting;
        } catch (e) {
            setState({
                loading: false,
            });
            throw e;
        }
    },

    saveDraft: async (id?: string | number) => {
        return createMeetingActions.save(id);
    },

    savePublished: async (id: string | number, type?: 'current' | 'forward' | 'all') => {
        const stepList = getState().stepList;

        stepList.forEach(step => stepToValidator[step]());
        stepList.forEach(step => {
            if (Object.values(selectErrors(step)).some(v => v)) {
                navActions.goToStep(step);
                throw new ValidationError();
            }
        });

        return createMeetingActions.save(id, type);
    },

    save: async (id?: string | number, type?: 'current' | 'forward' | 'all') => {
        if (getState().saving || getState().loading) {
            return;
        }
        setState({ saving: true });

        const data = {
            ...basicInfoToMeeting(getState().basicInfo),
            agenda: getState().agenda,
        };

        const start = moment(getState().basicInfo.startDate)
            .startOf('day')
            .add(moment.duration(getState().basicInfo.startTime));
        const recurrence = getState().basicInfo.recurrence;

        const promise =
            id === undefined
                ? createMeeting({
                      ...data,
                      ...(recurrence ? recurrenceToMeeting(recurrence, start, getState().basicInfo.timeZone) : {}),
                  })
                : updateMeeting({
                      id: typeof id === 'string' ? parseInt(id) : id,
                      ...data,
                      ...(recurrence
                          ? recurrenceToMeeting(recurrence, start, getState().basicInfo.timeZone, type)
                          : {}),
                  });

        const { attendees, loadedAttendees } = getState();
        const { deleteAttendees, newAttendees } = splitAttendees(attendees, loadedAttendees);

        try {
            const { meeting } = await promise;
            const newId = meeting.id;
            persistStep(
                newId,
                getState().stepList.findIndex(step => step === selCurStep(getState()))
            );
            await Promise.all(deleteAttendees.map(attendee => removeAttendee(newId, attendee)));
            const loadedAttendees = await addAttendees(newId, newAttendees);

            const basicInfo = meetingToBasicInfo(meeting);

            basicFormActions.setBasicForm(basicInfo);
            agendaFormActions.setAgenda(meeting.agenda);
            attendeeFormActions.setAttendees(loadedAttendees);

            setState({
                hasRecurrence: !!basicInfo.recurrence,
                loadedAttendees,
                saving: false,
                saved: true,
                attendeesChanged: false,
            });

            meetingsActions.merge(meeting);
            return meeting;
        } catch (e) {
            setState({
                saving: false,
            });
            throw e;
        }
    },

    applyPlaybook: (playbook: BizlyAPI.Complete.Playbook) => {
        const { id, description, purpose, agenda, agendaDescription } = playbook;

        basicFormActions.mergeBasicForm({
            description,
            purpose,
            agendaDescription,
            playbookId: id,
        });
        if (agenda) agendaFormActions.setAgenda(agenda);
        inviteActions.setInvite({
            ...getState().invite,
            image: playbook.imageUrl,
            description: playbook.purpose,
        });
        setState({ playbookChanged: false, playbookKey: getState().playbookKey + 1 });
    },

    publish: async (id?: number | string) => {
        if (getState().saving || getState().publishing) {
            return;
        }

        let meeting: BizlyAPI.Meeting | undefined;

        try {
            setState({ publishing: true });
            meeting = await createMeetingActions.save(id);

            if (meeting) {
                const invite = getState().invite;
                ({ meeting } = await publishMeeting({
                    id: meeting.id,
                    inviteType: invite.type === 'simple' ? 'calendar' : 'registration',
                    inviteImageUrl: invite.image,
                    inviteDescription: invite.description,
                }));

                meetingsActions.merge(meeting);
                eraseStep(meeting.id);
            }
            return meeting;
        } catch (e) {
            setState({ publishing: false });
            if (meeting) return meeting;
            else throw e;
        }
    },
};

type DraftsSteps = Partial<Record<string | number, number>>;

const localStorageStepKey = 'meetingDraftsSteps';

function loadStep(id: string | number) {
    const steps = JSON.parse(localStorage.getItem(localStorageStepKey) || '{}') as DraftsSteps;
    return steps[id];
}

function persistStep(id: string | number, stepIdx: number) {
    const steps = JSON.parse(localStorage.getItem(localStorageStepKey) || '{}') as DraftsSteps;
    steps[id] = stepIdx;
    localStorage.setItem(localStorageStepKey, JSON.stringify(steps));
}

function eraseStep(id: string | number) {
    const steps = JSON.parse(localStorage.getItem(localStorageStepKey) || '{}') as DraftsSteps;
    delete steps[id];
    localStorage.setItem(localStorageStepKey, JSON.stringify(steps));
}
