import React from 'react';
import styled from 'styled-components';

import { withInteractibleIconStyles } from 'shared';
import { agendaFields, agendaFieldsShort, agendaSchema } from 'components/Schedule/scheduleFormSchema';
import { Column, Row } from 'ui';
import { ReactComponent as TrashIcon } from 'images/icons/trash-can.svg';
import { ReactComponent as ArrowIcon } from 'images/icons/down-arrow.svg';
import { H3Headline } from 'components/ui/Headline';
import LongButton from 'components/ui/Button/LongButton';
import Form from 'components/Form';

export const MOVE_UP = 'MOVE_UP';
export const MOVE_DOWN = 'MOVE_DOWN';
export type TDirection = typeof MOVE_UP | typeof MOVE_DOWN;

const Container = styled(Column)`
    border-radius: 8px;
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    overflow: hidden;
`;

const EntryContainer = styled(Container)`
    overflow: unset;
`;

const Head = styled(Row)`
    border-bottom: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.brand)};
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    padding: 16px;
    width: auto;
    overflow: hidden;
    border-radius: 8px 8px 0 0;
`;

const EntryHead = styled(Head)`
    justify-content: space-between;
    min-height: 29px;
`;

const Body = styled(Column)`
    padding: 16px;
    margin-top: 0;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.brand)};
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    border-radius: 0 0 8px 8px;
    min-height: 810px;
`;

const EntryBody = styled(Body)`
    min-height: auto;
    overflow: hidden;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.brand)};
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

const MoveDownButton = styled(withInteractibleIconStyles(ArrowIcon))`
    height: 16px;
    width: 16px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.74)};
`;
const MoveUpButton = styled(MoveDownButton)`
    transform: rotate(180deg);
`;

const RemoveEntryButton = styled(TrashIcon)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
`;

type TEntryHandlers = {
    updateAgendaEntry: (updatedData: { value: BizlyAPI.ScheduleAgendaEntry }, idx: number) => void;
    arrangeAgendaEntry: (targetIdx: number, direction: TDirection) => void;
    removeAgendaEntry: (targetIdx: number) => void;
};

const Entry = ({
    idx,
    value,
    entriesLength,
    updateAgendaEntry,
    arrangeAgendaEntry,
    removeAgendaEntry,
    disabled,
    condensed,
}: {
    idx: number;
    value: BizlyAPI.ScheduleAgendaEntry;
    entriesLength: number;
    disabled?: boolean;
    condensed?: boolean;
} & TEntryHandlers) => {
    const isFirst = idx === 0;
    const isLast = idx === entriesLength - 1;
    return (
        <EntryContainer key={`agenda-entry-${idx}`}>
            <EntryHead>
                <Column>
                    {!disabled && entriesLength > 1 && (
                        <>
                            {!isFirst && <MoveUpButton onClick={() => arrangeAgendaEntry(idx, MOVE_UP)} />}
                            {!isLast && <MoveDownButton onClick={() => arrangeAgendaEntry(idx, MOVE_DOWN)} />}
                        </>
                    )}
                </Column>
                {!disabled && <RemoveEntryButton onClick={() => removeAgendaEntry(idx)} />}
            </EntryHead>
            <EntryBody>
                <Form
                    fields={condensed ? agendaFieldsShort : agendaFields}
                    schema={agendaSchema}
                    value={value}
                    disabled={disabled}
                    onChange={(e: { value: BizlyAPI.ScheduleAgendaEntry }) => updateAgendaEntry(e, idx)}
                />
            </EntryBody>
        </EntryContainer>
    );
};

const ManageAgenda = ({
    agendaEntries,
    addAgendaEntry,
    disabled,
    condensed,
    noContainer,
    ...entryHandlers
}: {
    agendaEntries: BizlyAPI.ScheduleAgendaEntry[];
    addAgendaEntry: () => void;
    disabled?: boolean;
    condensed?: boolean;
    noContainer?: boolean;
} & TEntryHandlers) => {
    const renderedAgenda = (
        <Column itemSpacing="smallish">
            {agendaEntries.map((agendaEntry: BizlyAPI.ScheduleAgendaEntry, idx) => (
                <Entry
                    key={agendaEntry?.id?.toString()} // TODO: Simply this key once the Backend is supplying UUIDs for Agenda items
                    idx={idx}
                    value={agendaEntry}
                    entriesLength={agendaEntries.length}
                    disabled={disabled}
                    condensed={condensed}
                    {...entryHandlers}
                />
            ))}
            {!disabled && (
                <LongButton onClick={addAgendaEntry} isSecondary>
                    Add Agenda Item
                </LongButton>
            )}
        </Column>
    );
    return noContainer ? (
        renderedAgenda
    ) : (
        <Container>
            <Head>
                <H3Headline>Agenda</H3Headline>
            </Head>
            <Body>{renderedAgenda}</Body>
        </Container>
    );
};

export default ManageAgenda;
