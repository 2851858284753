import React from 'react';
import styled from 'styled-components/macro';
import Dialog from '@material-ui/core/Dialog';
import { useSnackbar } from 'notistack';

import { removeVenueFromInquiry } from '../api';
import { AlignedRow as UIAlignedRow, Column as UIColumn, Copy } from '../ui';
import TextButton from './ui/Button/TextButton';

import useKey from '../hooks/useKey';

const Column = styled(UIColumn)`
    padding: 20px;
    width: 400px;
`;

const Heading = styled.h2`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.warningText)};

    font-size: 25px;
    line-height: 1.28;
    letter-spacing: -0.5px;

    font-weight: normal;
    font-style: normal;
    font-stretch: normal;

    margin-top: 0;
`;

const AlignedRow = styled(UIAlignedRow)`
    justify-content: flex-end;
    margin-top: 20px;
`;

const VenueCancelModal = ({
    open,
    closeModal,
    venueDetails,
    inquiredVenues,
    onCancelled,
}: {
    open: boolean;
    closeModal: () => void;
    venueDetails?: any;
    inquiredVenues: Array<any>;
    onCancelled: (venues: any[]) => void;
}) => {
    useKey('Escape', closeModal);
    const { enqueueSnackbar } = useSnackbar();

    const handleConfirmation = async () => {
        try {
            await removeVenueFromInquiry(venueDetails.inquiryId, venueDetails.id);
            const updatedVenues = inquiredVenues.map(entry =>
                entry.id === venueDetails.id ? { ...entry, status: 'Inquiry Cancelled' } : entry
            );
            onCancelled(updatedVenues);
            closeModal();
        } catch (err) {
            // TODO: Placeholder copy until formal copy is submitted
            enqueueSnackbar('Something went wrong! Please try again.', {
                variant: 'error',
            });
        }
    };

    return (
        <Dialog open={open} maxWidth="md">
            <Column>
                <Heading>Are you sure?</Heading>
                <Copy>This will notify the venue of your cancellation.</Copy>
                <AlignedRow itemSpacing="smallish">
                    <TextButton secondary onClick={closeModal}>
                        Cancel
                    </TextButton>
                    <TextButton warning onClick={handleConfirmation}>
                        Confirm
                    </TextButton>
                </AlignedRow>
            </Column>
        </Dialog>
    );
};

export default VenueCancelModal;
