import React from 'react';
import styled from 'styled-components';

import { createPlaybookActions, useCreatePlaybook, ESteps, selCurStep } from './store';

import { Spacer } from 'ui';
import Header from './components/Header';
import BasicInfoForm from './BasicInfoForm';
import AgendaForm from './AgendaForm';
import TagsForm from './TagsForm';
import Preview from './Preview';

const Sticky = styled.div`
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;
`;

const stepToForm = {
    [ESteps.basic]: <BasicInfoForm />,
    [ESteps.agenda]: <AgendaForm />,
    [ESteps.tags]: <TagsForm />,
    [ESteps.preview]: <Preview />,
};

export default function CreatePlaybook() {
    React.useEffect(() => createPlaybookActions.reset, []);

    const curStep = useCreatePlaybook(selCurStep);

    return (
        <>
            <Sticky>
                <Header />
            </Sticky>

            <Spacer larger />
            {stepToForm[curStep]}
        </>
    );
}
