import { get, put, post, deleteCall } from '.';

import { ALL_GRADES } from '../components/VenueSearch/VenueSearchGrades';
import { toMeters } from '../util';
import debounce from 'awesome-debounce-promise';
import VenueSearchFetcher from './VenueSearchFetcher';

import { getInquiriesForEvent, createInquiryDraft } from './inquiry';

export const DEBOUNCE_DELAY = 1000;

export const loadSuggestedVenuesForEvent = (id: string | number, facets?: BizlyAPI.VenueFacets) =>
    new VenueSearchFetcher(id, { facets, perPage: 20 }).getNextPage();

export const getVenueInquiries = (eventId: string | number): Promise<Bizly.Venue[]> =>
    get(`events/${eventId}/venues`).then(res => res.venues);

export const getVenueInquiriesAdded = (eventId: number | string) =>
    getVenueInquiries(eventId).then(venues => venues.filter((venue: Bizly.Venue) => venue.status === 'Added'));

export const getVenueInquiriesNotAdded = (eventId: number | string): Promise<Bizly.Venue[]> =>
    getVenueInquiries(eventId).then(venues => venues.filter(venue => venue.status !== 'Added'));

export const getVenueProposalAccepted = (proposalId: number): Promise<Bizly.VenueProposal | undefined> =>
    get(`proposals/${proposalId}?include=booking,contact,payments`).then(res => res.proposal);

export const getInquiry = (inquiryId: number) =>
    get(`booking-inquiries/${inquiryId}`) as Promise<{ inquiry: BizlyAPI.Inquiry }>;

export const getInquiryDraft = async (eventId: number | string) => {
    const { inquiries: existingInquiries } = await getInquiriesForEvent(eventId);
    return existingInquiries.find((i: any) => i.draft);
};

const addVenueToInquiry = (inquiryId: number, venueId: number) =>
    post(`booking-inquiries/${inquiryId}/venues`, { venue_id: venueId }) as Promise<{ inquiry: BizlyAPI.Inquiry }>;

export const addVenueToInquiryDraft = async (
    eventId: number | string,
    { inquiryId, venueId }: { inquiryId?: number; venueId: number }
) => {
    // put
    if (inquiryId) return await addVenueToInquiry(inquiryId, venueId);

    // post
    const { inquiry } = await createInquiryDraft(eventId);
    const draftId = inquiry.id;
    return await addVenueToInquiry(draftId, venueId);
};

const updateBookingInquiry = (
    bookingId: number,
    { datesFlexible, notes, scheduleItemIds }: { datesFlexible?: boolean; notes?: string; scheduleItemIds?: number[] }
) =>
    put(`booking-inquiries/${bookingId}`, {
        flexible_dates: datesFlexible,
        notes,
        scheduleItemIds,
    });

export const updateInquiryDraft = debounce(
    async (
        eventId: number | string,
        {
            inquiryId,
            ...data
        }: { inquiryId?: number; datesFlexible?: boolean; notes?: string; scheduleItemIds?: number[] }
    ) => {
        // put
        if (inquiryId) return await updateBookingInquiry(inquiryId, data);

        // post
        const { inquiry } = await createInquiryDraft(eventId);
        const draftId = inquiry.id;
        return await updateBookingInquiry(draftId, data);
    },
    DEBOUNCE_DELAY
);

export { removeVenueFromInquiry, submitInquiry } from './inquiry';

export const selectVenue = (event: Bizly.Event, venue: Bizly.Venue) =>
    post(`events/${event.id}/venues`, { venue_id: venue.id });

export const deselectVenue = (event: Bizly.Event, venue: Bizly.Venue) =>
    deleteCall(`events/${event.id}/venues/${venue.id}`);

export const searchVenues = (
    eventId: string | number,
    facets = {} as BizlyAPI.VenueFacets,
    pageConfig = {}
): Promise<{ venues: Bizly.Venue[]; meta: any }> =>
    post(`venues/search`, {
        eventId,
        ...facets,
        radius: facets.radius ? facets.radius : toMeters(15 as Distance.Mile), // default to 15 mi
        grades: facets.grades ? facets.grades : ALL_GRADES, // default to all grades
        page: 1,
        perPage: 10,
        ...pageConfig,
    }).then(res => ({ venues: res.venues, meta: res.searchMeta }));

export const getVenue = (venueId: number) => get(`venues/${venueId}`);

export const getVenueSpaces = (venueId: number) => get(`venues/${venueId}/spaces`);

export const getProposalsForInquiry = (inquiryId: number) => get(`booking-inquiries/${inquiryId}/proposals`);

// TODO: We are calling this same endpoint (not helper, but the literal endpoint) more often than we should. We need to revisit the proposal's page and set it up to GET a single proposal only once, using only one function.
export const getVenueProposal = (proposalId: number | string) => get(`proposals/${proposalId}`);

export const acceptProposal = (proposalId: number | string) => post(`proposals/${proposalId}/accept`);

export const rejectProposal = (proposalId: number | string) => post(`proposals/${proposalId}/reject`);

export const getProposalMessages = (proposalId?: number | string) =>
    get(`proposals/${proposalId}/notes`, { requireToken: true }) as Promise<{
        notes: BizlyAPI.VenueMessage[];
        venue: BizlyAPI.ProposalNotesVenue;
    }>;

export const sendProposalMessage = (
    proposalId?: number | string,
    message?: BizlyAPI.VenueMessage.Text | BizlyAPI.VenueMessage.Attachment
) => post(`proposals/${proposalId}/notes`, message) as Promise<{ note: BizlyAPI.VenueMessage }>;
