/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import styled, { css } from 'styled-components/macro';
import queryString from 'query-string';

import { useEvent } from '../../providers/event';

import { withInteractibleIconStyles } from '../../shared';

import { AlignedRow, Row } from '../../ui';
import MuiSearchIcon from '@material-ui/icons/Search';

import PlaceInput from '../PlaceInput';
import { queryParamsToPlace } from './SearchUtils';

const AlwaysCenteredRow = styled(AlignedRow)<{ pageOffset: number }>`
    position: absolute;

    width: calc(100vw - ${({ pageOffset }) => pageOffset}px);
    margin: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    height: 100%;
    pointer-events: none;
`;

const Width100Row = styled(Row)`
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    pointer-events: none;
    min-width: 1200px;
`;

const FieldWidth = css<{ pageOffset: number; maxWidth: number }>`
    @media only screen and (max-width: 1200px) {
        min-width: 324px;
        max-width: 324px;
    }

    @media only screen and (min-width: 1200px) {
        min-width: 324px;
        width: calc(
            100vw - ${({ pageOffset }) => pageOffset}px - 876px
        ); /* 876 is 1200 - 324 (min-width of field), this rule allows the field to grow to 440px */
        max-width: ${({ maxWidth }) => maxWidth}px;
    }
`;

const FieldContainer = styled.div`
    ${FieldWidth}
    pointer-events: all;
`;

const SearchIcon = withInteractibleIconStyles(MuiSearchIcon);

const SearchIconButton = ({ onClick }: { onClick: () => void }) => {
    return (
        <SearchIcon
            inverted
            style={{
                pointerEvents: 'all',
                position: 'absolute',
            }}
            onClick={onClick}
        />
    );
};

type TProps = {
    disabled: boolean;
    pageOffset?: number;
    maxWidth?: number;
} & RouteComponentProps;

const VenueSearchField = ({ history, location, disabled, pageOffset = 0, maxWidth = 440 }: TProps) => {
    const [searchFocused, setFocused] = useState(false);
    const focus = () => setFocused(true);
    const blur = () => setFocused(false);

    // transparentHeader is visible from venue listing
    const { event, transparentHeader: transparent } = useEvent();

    // if location changes, we sync our internal value with location
    // we store our own because it allows us to control the input without doing a submit
    const q = useMemo(() => queryParamsToPlace(location.search)?.q, [location.search]);

    const performSearch = (query: string) => {
        history.push(`/event/${event.id}/venue/search?${query}`);
        blur();
    };

    const updateUrl = (s: string, placeId: string) => {
        const queryParamsUrl = queryString.stringify({ q: s, place_id: placeId });
        performSearch(queryParamsUrl);
    };

    const locationBias = useMemo(() => (event.lat && event.lng ? { lat: event.lat, lng: event.lng } : undefined), [
        event.lat,
        event.lng,
    ]);

    const showSearchIconButton = transparent && !searchFocused;
    const showSearchField = !transparent || (transparent && searchFocused);

    return (
        <Width100Row>
            <AlwaysCenteredRow pageOffset={pageOffset}>
                {showSearchIconButton && <SearchIconButton onClick={focus} />}
                {showSearchField && (
                    <FieldContainer pageOffset={pageOffset} maxWidth={maxWidth}>
                        <PlaceInput
                            onChange={(city, placeId) => {
                                updateUrl(city, placeId);
                            }}
                            defaultValue={q}
                            onBlur={blur}
                            autoFocus={transparent && searchFocused}
                            disabled={disabled}
                            locationBias={locationBias}
                            placeholder="Search by location"
                            inModalOrPopover
                            asFormField
                            showSearchIcon
                        />
                    </FieldContainer>
                )}
            </AlwaysCenteredRow>
        </Width100Row>
    );
};

export default VenueSearchField;
