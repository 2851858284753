import React, { useState, useEffect, useCallback, useMemo } from 'react';

import styled from 'styled-components/macro';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useSnackbar } from 'notistack';

import { CircularProgress } from '@material-ui/core';
import { Column, Row, AlignedRow, Spacer, Switch, Line, Copy } from '../ui';
import { H2Headline } from 'components/ui/Headline';

import { TFilterValue } from 'components/VenueSearch/VenueSearchFilters';
import FiltersRow from 'components/VenueSearch/VenueSearchFiltersRow';
import { getTokens } from 'components/VenueSearch/TokenUtils';
import { InfiniteGrid, NoSearchGrid } from 'components/VenueSearch/VenueSearchInfiniteGrid';
import VenueMap from 'components/VenueMap';

import VenueSearchFetcher from 'api/VenueSearchFetcher';
import {
    queryParamsToPlace as parsePlace,
    queryParamsToFormFilters as parseFilters,
    toQueryParams,
    TPlaceQuery,
} from 'components/VenueSearch/SearchUtils';
import { getPlace } from 'api';

const AutoAlignedRow = styled(AlignedRow)`
    flex: 0 0 auto;
    width: auto;
`;

const FittedVenueMap = styled(VenueMap)`
    min-width: 440px;
    max-width: 440px;
    height: 807px;
    max-height: calc(100vh - 200px);
    position: sticky;
    /* TODO: this shouldn't be hard-coded */
    top: 149px;
`;

const CenteredItemsRow = styled(Row)`
    align-items: center;
    justify-content: center;
    height: 200px;
`;

const NeverCollapseH2 = styled(H2Headline)`
    &::after {
        content: '.';
        visibility: hidden;
    }
`;

/*
    Venue Search Page has 2 primary responsibilities that work together:
    1. Managing search params in the URL:
        a. Converting URL google place ids into lat/lng and writing them to the URL
        b. Updating filter params in the URL
    2. Perform venue searches 

    These 2 responsibilities are managed by the hooks defined below
*/

const useManageUrlParams = (event: Bizly.Event, filters: TFilterValue, query?: TPlaceQuery) => {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const updateUrl = useCallback(
        (filters, query) => {
            const stringified = queryString.stringify(
                toQueryParams({
                    ...filters,
                    ...query,
                })
            );
            history.replace(`/event/${event.id}/venue/search?${stringified}`);
        },
        [event.id, history]
    );

    const latLng = useMemo(() => {
        if (query && (!query.lat || !query.lng)) return undefined;
        if (query && query.lat && query.lng) return { lat: query.lat, lng: query.lng };
        if (event.lat && event.lng) return { lat: event.lat, lng: event.lng };
    }, [event.lat, event.lng, query]);

    // write lat/lng to URL
    React.useEffect(() => {
        const placeToURLLatLng = async (placeId?: string) => {
            try {
                const res = await getPlace(placeId);
                const loc = res.result?.geometry.location;

                updateUrl(filters, { ...query, ...loc });
            } catch {
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            }
        };

        if (!latLng && query?.place_id) {
            placeToURLLatLng(query?.place_id);
        }
    }, [event.id, filters, latLng, query, updateUrl, enqueueSnackbar]);

    const setFilters = useCallback(newFilters => updateUrl(newFilters, query), [query, updateUrl]);

    return { latLng, setFilters };
};

const useVenueSearch = (event: Bizly.Event, filters: TFilterValue, latLng?: { lat: number; lng: number }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [{ results, hasMore }, setCollection] = useState({} as { results?: Bizly.Venue[]; hasMore?: boolean });

    // invalidate old results by tracking the most up-to-date fetcher
    const curFetcher = React.useRef<VenueSearchFetcher>();

    const fetcher = useMemo(() => {
        if (!latLng) return undefined;

        const filtersQuery = toQueryParams(filters);
        return new VenueSearchFetcher(event.id, {
            facets: { ...filtersQuery, ...latLng },
            perPage: 10,
        });
    }, [event.id, filters, latLng]);
    curFetcher.current = fetcher;

    const performSearch = useMemo(() => {
        if (!fetcher) return undefined;

        return async () => {
            try {
                const venues = await fetcher.getNextPage();

                // if our closured fetcher is up-to-date:
                if (fetcher === curFetcher.current)
                    setCollection(({ results: prevRes }) => ({
                        results: (prevRes || []).concat(venues),
                        hasMore: fetcher.hasMore(),
                    }));
            } catch {
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            }
        };
    }, [fetcher, enqueueSnackbar]);

    useEffect(() => {
        setCollection({});
        performSearch && performSearch();
    }, [performSearch]);

    const loadMore = useCallback(() => performSearch && performSearch(), [performSearch]);

    return { hasMore, loadMore, results };
};

const getResultsMessage = ({
    query,
    hasSearched,
    hasResults,
    hasFilters,
}: {
    query?: string;
    hasSearched: boolean;
    hasResults: boolean;
    hasFilters: boolean;
}) => {
    let resultsMessage = '';
    if (hasSearched) {
        if (hasResults) resultsMessage = query ? `Showing you results near "${query}"` : '';
        else {
            if (hasFilters) resultsMessage = `We couldn’t find any venues matching your search. Try removing filters`;
            else if (query) resultsMessage = `We couldn’t find any venues near "${query}". Try again`;
        }
    }

    return resultsMessage;
};

type TVenueSearchPage = {
    event: Bizly.Event;
    onSelect: (venue: Bizly.Venue) => void;
    onDeselect: (venue: Bizly.Venue) => void;
    viewVenueListing: (venueId: number) => void;
    selectedVenues: { [venueId: number]: Bizly.Venue };
};
export default function VenueSearchPage({
    event,
    onSelect,
    onDeselect,
    viewVenueListing,
    selectedVenues,
}: TVenueSearchPage) {
    const [showMap, setShowMap] = useState(true);
    const [highlightedId, setHighlightedId] = useState<number>();

    const location = useLocation();

    const query = useMemo(() => parsePlace(location.search), [location.search]);
    const filters = useMemo(() => parseFilters(location.search), [location.search]);

    const { latLng, setFilters } = useManageUrlParams(event, filters, query);

    const { hasMore, loadMore, results } = useVenueSearch(event, filters, latLng);

    const tokens = useMemo(() => getTokens(filters, () => {}), [filters]);
    const resultsMessage = getResultsMessage({
        query: query?.q,
        hasSearched: !!results,
        hasResults: !!(results && results.length > 0),
        hasFilters: tokens.length > 0,
    });

    return (
        <Column style={{ width: '100%' }}>
            <Column>
                <NeverCollapseH2>{resultsMessage}</NeverCollapseH2>
                <Spacer large />
                <AlignedRow>
                    <FiltersRow filters={filters} setFilters={setFilters} />
                    <AutoAlignedRow>
                        <Copy style={{ marginRight: 12 }}>Show Map</Copy>
                        <Switch checked={showMap} onChange={e => setShowMap(e.target.checked)} value="showMap" />
                    </AutoAlignedRow>
                </AlignedRow>
                <Spacer small />
                <Line />

                {!results ? (
                    <CenteredItemsRow>
                        <CircularProgress />
                    </CenteredItemsRow>
                ) : (
                    <>
                        <Spacer large />
                        <Row>
                            <Column style={{ flex: '1 1 828px' }}>
                                {results.length > 0 ? (
                                    <InfiniteGrid
                                        data={results}
                                        hasMore={hasMore}
                                        loadMore={loadMore}
                                        onSelect={onSelect}
                                        onDeselect={onDeselect}
                                        onVenueHover={id => setHighlightedId(id || undefined)}
                                        onVisit={viewVenueListing}
                                        selectedVenues={selectedVenues}
                                    />
                                ) : (
                                    <NoSearchGrid />
                                )}
                            </Column>
                            {showMap && results && latLng && (
                                <FittedVenueMap center={latLng} venues={results} highlightedVenueId={highlightedId} />
                            )}
                        </Row>
                    </>
                )}
            </Column>
        </Column>
    );
}
