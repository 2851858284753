import React from 'react';

import styled from 'styled-components';

import { INPUT_PADDING, TAG_HEIGHT, TAG_MARGIN } from './AutoCompleteTypes';
import { InputBaseComponentProps, OutlinedInputProps } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { RenderInputParams } from '@material-ui/lab/Autocomplete';

import { Row } from '../../../ui';
import CircularProgress from '@material-ui/core/CircularProgress';

// translation: (height of rows) x (height of top + bottom margin) - (a set of top + bottom margins)
// the last subtraction is because the parent, SpacedChildrenRow, has negative margins
// refer to this: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Mastering_Wrapping_of_Flex_Items#Creating_gutters_between_items
// for an explanation of the common negative margins flex parent trick
const maxRowsToMaxHeight = (rows: number = 0) =>
    rows ? rows * TAG_HEIGHT + rows * 2 * TAG_MARGIN - 2 * TAG_MARGIN : 0;

const ScrollableInputWrap = styled(Row)<{ rowsMax?: number }>`
    ${props => (props.rowsMax ? `max-height: ${maxRowsToMaxHeight(props.rowsMax)}px;` : ``)}
    overflow: auto;
    padding: ${TAG_MARGIN}px;
`;

const SpacedChildrenRow = styled(Row)`
    flex-flow: row wrap;
    align-items: center;

    margin: -${TAG_MARGIN}px;
    & > * {
        margin: ${TAG_MARGIN}px;
    }
`;

const Adornment = styled(Row)<{ hidden?: boolean; right?: number }>`
    width: auto;
    height: 100%;

    align-items: center;
    align-self: center;

    pointer-events: none;
    & * {
        pointer-events: all;
    }

    ${props =>
        props.hidden
            ? `visibility: hidden;
            & * {
                visibility: hidden;
            }
        `
            : ''}
    ${props =>
        props.right || props.right === 0
            ? `
        position: absolute;
        right: ${props.right}px;
    `
            : ``}
`;

const useTrackScrollbarAndScrollToEnd = ({
    initialWidth,
    inputValue,
    tagsComponent,
    inputContainerRef,
}: {
    initialWidth: number;
    inputValue?: string;
    tagsComponent: OutlinedInputProps['startAdornment'];
    inputContainerRef: React.RefObject<HTMLDivElement>;
}) => {
    const [scrollbarWidth, setScrollbarWidth] = React.useState(initialWidth);

    React.useEffect(() => {
        const updateScrollbarWidth = () => {
            const newWidth =
                (inputContainerRef.current?.offsetWidth || 0) - (inputContainerRef.current?.clientWidth || 0);
            setScrollbarWidth(newWidth);
        };
        const scrollToBottom = () =>
            inputContainerRef.current && (inputContainerRef.current.scrollTop = inputContainerRef.current.scrollHeight);

        updateScrollbarWidth();
        scrollToBottom(); // if the input or tags changes we want to scroll to the bottom of the field
    }, [
        inputValue, // changing the text input can trigger scrollbars
        tagsComponent, // changing number of tags can trigger scrollbars,
        inputContainerRef, // ref variables never change, only the .current value changes
    ]);

    return scrollbarWidth;
};

type TWrappedInput = Partial<
    InputBaseComponentProps &
        Pick<OutlinedInputProps, 'inputRef' | 'endAdornment' | 'startAdornment'> & {
            rowsMax?: number;
        }
>;

const ScrollableInput = (props: TWrappedInput = {}) => {
    const { inputRef, startAdornment, endAdornment, rowsMax, color, ...inputProps } = props;

    const tagsAndInputRef = React.createRef<HTMLDivElement>();
    const scrollbarWidth = useTrackScrollbarAndScrollToEnd({
        initialWidth: 0,
        inputValue: inputProps.value,
        tagsComponent: startAdornment,
        inputContainerRef: tagsAndInputRef,
    });

    const hasOverflow = !!scrollbarWidth;

    return (
        <ScrollableInputWrap rowsMax={rowsMax} ref={tagsAndInputRef}>
            <SpacedChildrenRow>
                {startAdornment}
                <input {...inputProps} ref={inputRef} />
            </SpacedChildrenRow>
            <Adornment hidden={hasOverflow}>{endAdornment}</Adornment>
            {hasOverflow && <Adornment right={scrollbarWidth + INPUT_PADDING}>{endAdornment}</Adornment>}
        </ScrollableInputWrap>
    );
};

const SPINNER_MARGIN = 12;
const SPINNER_WIDTH = 20;

// investigate at some point
const FixWeirdOffsetWithPositionFixed = `transform: translateX(0.5px) translateY(0.5px);`;

const StyledTextField = styled(TextField)<{ open?: boolean; inModalOrPopover?: boolean }>`
    margin-top: 0px;
    box-shadow: 0px !important;
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.brand)};
    border: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.74)} 1px solid;

    ${({ inModalOrPopover }) => (inModalOrPopover ? FixWeirdOffsetWithPositionFixed : '')}

    border-radius: 8px;
    ${({ open }) =>
        open
            ? `
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    /* flattens the border bottom when options are shown */
    fieldset {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
        `
            : ''}
`;

const HideableSpinner = styled(CircularProgress).attrs({ size: SPINNER_WIDTH, color: 'inherit' })<{
    visible?: boolean;
}>`
    margin-left: ${SPINNER_MARGIN}px;
    &:not(:last-child) {
        margin-right: ${SPINNER_MARGIN}px;
    }

    visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`;

export default function getAutocompleteRenderScrollableInput({
    InputProps,
    visible,
    focus,
    loading,
    ignoreEnterKeyPress,
    inModalOrPopover,
}: {
    InputProps?: {
        placeholder?: string;
        startAdornment?: React.ReactNode;
        endAdornment?: React.ReactNode;
        autoFocus?: boolean;
    };
    visible?: boolean;
    focus?: boolean;
    loading?: boolean;
    ignoreEnterKeyPress?: () => boolean;
    inModalOrPopover?: boolean;
}) {
    const getPropsToUseScrollableInput = ({
        inputProps: baseinputProps,
        InputProps: baseInputProps,
    }: RenderInputParams) => {
        // this is where Autocomplete puts tags
        const autocompleteTagComponents = baseInputProps.startAdornment;

        // To use our own input component we need to change inputComponent
        // and move props to inputProps
        const InputPropsToUseScrollableInput: Partial<OutlinedInputProps> = {
            ...baseInputProps,
            inputComponent: ScrollableInput,
            startAdornment: undefined,
            endAdornment: undefined,
        };

        const PropsForScrollableInput = {
            ...baseinputProps,
            startAdornment: (
                <>
                    {InputProps?.startAdornment}
                    {autocompleteTagComponents}
                </>
            ),
            endAdornment: (
                <>
                    <HideableSpinner visible={focus && loading} />
                    {InputProps?.endAdornment}
                </>
            ),
            rowsMax: 3,
        };

        return { InputProps: InputPropsToUseScrollableInput, inputProps: PropsForScrollableInput };
    };

    return (autocompleteParams: RenderInputParams) => (
        <StyledTextField
            {...autocompleteParams}
            variant="outlined"
            fullWidth
            InputProps={getPropsToUseScrollableInput(autocompleteParams).InputProps}
            inputProps={getPropsToUseScrollableInput(autocompleteParams).inputProps}
            autoFocus={InputProps?.autoFocus}
            placeholder={InputProps?.placeholder}
            open={visible && focus}
            onKeyDown={e => {
                const target = e.target as HTMLInputElement | HTMLTextAreaElement;
                if (e.key === 'Enter' && (!target.value || ignoreEnterKeyPress?.())) {
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                }
            }}
            inModalOrPopover={inModalOrPopover}
        />
    );
}
