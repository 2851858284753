import createStore from 'zustand';

import { getChimeInfo } from 'api/chime';

import { withCache } from 'stores/utils';

type State = {
    loading: boolean;
} & (
    | {
          loaded: false;
          meetingId?: string | number;
          chimeInfo: null;
          joinInfo: null;
      }
    | {
          loaded: true;
          meetingId: string | number;
          chimeInfo: BizlyAPI.ChimeInfo;
          joinInfo: { meetingInfo: any; attendeeInfo: any };
      }
);

type Store = State;

const initialState: State = {
    loading: false,
    loaded: false,

    chimeInfo: null,
    joinInfo: null,
};
export const [useChimeVideo, chimeVideoStoreApi] = createStore<Store>(() => initialState);

const { setState, getState } = chimeVideoStoreApi;
const loadWithCache = withCache({
    storeApi: chimeVideoStoreApi,
    cacheFn: state => (state.chimeInfo ? { chimeInfo: state.chimeInfo, meetingId: state.meetingId } : {}),
    key: 'meetingId',
    shouldCache: state => !!state.chimeInfo,
});

export const chimeVideoActions = {
    load: loadWithCache(async (meetingId: string | number) => {
        let { meetingId: curMeetingId } = getState();
        setState({
            loading: true,
            ...(meetingId !== curMeetingId
                ? {
                      chimeInfo: null,
                      joinInfo: null,
                      meetingId,
                      loaded: false,
                  }
                : {}),
        });

        try {
            const { chimeInfo } = await getChimeInfo(meetingId);
            const joinInfo = {
                meetingInfo: {
                    MeetingId: chimeInfo.meetingId,
                    ExternalMeetingId: chimeInfo.externalMeetingId,
                    MediaPlacement: {
                        AudioHostUrl: chimeInfo.audioHostUrl,
                        AudioFallbackUrl: chimeInfo.audioFallbackUrl,
                        ScreenDataUrl: chimeInfo.screenDataUrl,
                        ScreenSharingUrl: chimeInfo.screenSharingUrl,
                        ScreenViewingUrl: chimeInfo.screenViewingUrl,
                        SignalingUrl: chimeInfo.signalingUrl,
                        TurnControlUrl: chimeInfo.turnControlUrl,
                    },
                    MediaRegion: chimeInfo.mediaRegion,
                },
                attendeeInfo: { AttendeeId: chimeInfo.attendeeId, JoinToken: chimeInfo.joinToken },
            };

            setState({
                chimeInfo,
                joinInfo,
                loading: false,
                loaded: true,
            });

            return chimeInfo;
        } catch (e) {
            setState({
                loading: false,
            });
            throw e;
        }
    }),
    clear: () => setState(initialState),
};
