import createStore from '../';

import { getAuthUrl, postCallback, disconnect, appendRedirect } from 'api/connectors';

type State = {
    isAuthLoading: boolean;
    isCallbackLoading: boolean;
    isDisconnecting: boolean;
};
type Store = State;

const initialState: State = {
    isAuthLoading: false,
    isCallbackLoading: false,
    isDisconnecting: false,
};

export const [useIntegrations, integrationsStoreApi] = createStore<Store>(() => initialState);

const { setState, getState } = integrationsStoreApi;

export const integrationsActions = {
    auth: async (connectorType: BizlyAPI.Connector, redirect?: string) => {
        if (getState().isAuthLoading) {
            return;
        }

        setState({ isAuthLoading: true });

        try {
            const { data } = await getAuthUrl(connectorType);

            if (data) {
                const appendedLink = appendRedirect(data, redirect);
                window.location.href = appendedLink;
            } else {
                throw new Error('Unable to connect, please try again later.');
            }
        } catch (e) {
            throw e;
        }
    },

    callback: async (connectorType: BizlyAPI.Connector, code: string) => {
        if (getState().isCallbackLoading) {
            return;
        }

        setState({ isCallbackLoading: true });
        try {
            await postCallback(connectorType, code);

            return true;
        } catch (e) {
            throw e;
        } finally {
            setState({ isCallbackLoading: false });
        }
    },

    disconnect: async (connector: BizlyAPI.Connector) => {
        if (getState().isDisconnecting) {
            return;
        }

        setState({ isDisconnecting: true });

        try {
            await disconnect(connector);

            return true;
        } catch (e) {
            throw e;
        } finally {
            setState({ isDisconnecting: false });
        }
    },
};
