import React from 'react';
import { useSnackbar } from 'notistack';

import { alertsActions } from './store';

export default function ClearUnreadAlerts() {
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        const run = async () => {
            try {
                await alertsActions.clearUnread();
            } catch (e) {
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            }
        };

        run();
    }, [enqueueSnackbar]);

    return null;
}
