import React from 'react';

import styled, { css } from 'styled-components/macro';

import Dialog from '@material-ui/core/Dialog';

import { format, parseISO } from 'date-fns';

import useKey from '../hooks/useKey';

import { EventOptionsContext } from '../providers/event-options';

import { AlignedRow, Copy, Spacer } from '../ui';

import { ReactComponent as CloseIcon } from '../images/icons/close.svg';
import fontFns from 'fontFns';
import { SpinnerOverlay } from './Spinner';

const AccentedHeadline = styled.h3`
    font-size: 18px;
    letter-spacing: -0.1px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.accentedHeadline)};
`;

const InquiryDetailsModal = ({
    inquiryDetails,
    open,
    closeModal,
}: {
    inquiryDetails?: { schedule: any[]; submittedAt?: string; notes?: string; datesFlexible?: boolean };
    open: boolean;
    closeModal: () => void;
}) => {
    useKey('Escape', closeModal);

    const { avOptions, fbOptions, setupOptions } = React.useContext(EventOptionsContext);

    return (
        <Dialog open={open} maxWidth="md" onClose={closeModal}>
            <div
                css={css`
                    width: 480px;

                    max-height: 571px;

                    flex: 1;
                    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.softAccentedBackground)};

                    height: 100%;
                    box-sizing: border-box;

                    overflow: auto;

                    position: relative;

                    & > *:not(:first-child) {
                        padding: 32px;
                    }
                `}
            >
                <CloseIcon
                    css={css`
                        position: absolute;
                        top: 30px;
                        right: 30px;

                        cursor: pointer;
                    `}
                    onClick={closeModal}
                />
                <section
                    css={css`
                        background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
                    `}
                >
                    <AccentedHeadline
                        css={css`
                            margin-top: 0;
                        `}
                    >
                        Inquiry Details
                    </AccentedHeadline>
                    {inquiryDetails && inquiryDetails.submittedAt && (
                        <Copy>Submitted on {format(parseISO(inquiryDetails.submittedAt), 'iii, LLL dd, yyyy')}</Copy>
                    )}
                </section>
                <section>
                    {inquiryDetails && inquiryDetails.datesFlexible && (
                        <>
                            <Copy>Dates are flexible</Copy>
                            <Spacer />
                        </>
                    )}
                    <Spacer />
                    {inquiryDetails &&
                        inquiryDetails.schedule &&
                        inquiryDetails.schedule.map((day, idx) => (
                            <div key={day.id}>
                                <div
                                    css={css`
                                        height: 60px;
                                        background-color: ${({ theme: { getColor, EColors } }) =>
                                            getColor(EColors.agendaDayPane)};
                                        border-top-left-radius: 8px;
                                        border-top-right-radius: 8px;

                                        color: ${({ theme: { getColor, EColors } }) =>
                                            getColor(EColors.agendaDayPaneText)};

                                        display: flex;
                                        align-items: center;

                                        & > * {
                                            margin: 0 10px;
                                        }
                                    `}
                                >
                                    <div>Day {idx + 1}</div>
                                    {day.start && <div>{format(parseISO(day.start), 'EEE, LLL dd, yyyy')}</div>}
                                </div>
                                <div
                                    css={css`
                                        & > * {
                                            border-bottom: 1px solid
                                                ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};

                                            &:last-child {
                                                border: none;
                                                border-bottom-left-radius: 8px;
                                                border-bottom-right-radius: 8px;
                                            }
                                        }
                                    `}
                                >
                                    {!!day.guestrooms.length && (
                                        <div
                                            css={css`
                                                display: flex;
                                                align-items: center;
                                                padding: 28px 0;
                                                background: ${({ theme: { getColor, EColors } }) =>
                                                    getColor(EColors.pureWhite)};

                                                border-bottom: 1px solid
                                                    ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};

                                                & > * {
                                                    margin: 0 10px;
                                                }
                                            `}
                                        >
                                            <img
                                                src="https://res.cloudinary.com/hdd626jg7/image/upload/v1562953405/stencil/icons/guest-room.svg"
                                                alt="guestroom-icon"
                                            />
                                            <div>{day.guestrooms[0].count} guest rooms</div>
                                        </div>
                                    )}
                                    {day.agenda.map((agendaItem: any, idx: number) => (
                                        <div
                                            css={css`
                                                background: ${({ theme: { getColor, EColors } }) =>
                                                    getColor(EColors.pureWhite)};
                                                padding-bottom: 28px;
                                            `}
                                            key={agendaItem.id}
                                        >
                                            <div
                                                css={css`
                                                    display: flex;
                                                    align-items: center;
                                                    padding: 14px 0;

                                                    & > * {
                                                        margin: 0 10px;
                                                    }
                                                `}
                                            >
                                                <div
                                                    css={css`
                                                        display: flex;
                                                        align-items: center;
                                                        padding: 14px 0;
                                                        flex-wrap: wrap;

                                                        & > * {
                                                            margin: 0 10px;
                                                        }
                                                    `}
                                                >
                                                    <img
                                                        src={setupOptions[agendaItem.roomSetup].iconUrl}
                                                        alt="meetingspace-icon"
                                                    />
                                                    <div>{agendaItem.name || `Meeting Space ${idx + 1}`}</div>
                                                    <div>
                                                        {format(parseISO(agendaItem.start), 'h:mm aaaa')} -{' '}
                                                        {format(parseISO(agendaItem.end), 'h:mm aaaa')}
                                                    </div>
                                                </div>
                                            </div>
                                            <AlignedRow
                                                css={css`
                                                    width: unset;
                                                    margin: 0 40px;
                                                    flex-wrap: wrap;

                                                    & > * {
                                                        margin: 0 16px;
                                                    }
                                                `}
                                            >
                                                {agendaItem.amenities.av.map((avId: number) => (
                                                    <img
                                                        src={
                                                            (avOptions as any[]).find(avOption => avOption.id === avId)
                                                                ? (avOptions as any[]).find(
                                                                      avOption => avOption.id === avId
                                                                  ).iconUrl
                                                                : ''
                                                        }
                                                        alt="meetingspace-icon"
                                                        key={avId}
                                                    />
                                                ))}
                                                {agendaItem.amenities.foodAndBeverage.map(
                                                    (foodAndBeverageItem: { optionId: number }) => (
                                                        <img
                                                            src={
                                                                (fbOptions as any[]).find(
                                                                    fbOption =>
                                                                        fbOption.id === foodAndBeverageItem.optionId
                                                                )
                                                                    ? (fbOptions as any[]).find(
                                                                          fbOption =>
                                                                              fbOption.id ===
                                                                              foodAndBeverageItem.optionId
                                                                      ).iconUrl
                                                                    : ''
                                                            }
                                                            alt="meetingspace-icon"
                                                            key={foodAndBeverageItem.optionId}
                                                        />
                                                    )
                                                )}
                                            </AlignedRow>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    {inquiryDetails && inquiryDetails.notes && (
                        <>
                            <Spacer />
                            <div
                                css={css`
                                    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formLabel)};
                                    ${fontFns.formLabel}
                                `}
                            >
                                Notes
                            </div>
                            <div>{inquiryDetails.notes}</div>
                        </>
                    )}
                </section>
            </div>
            {!inquiryDetails && <SpinnerOverlay />}
        </Dialog>
    );
};

export default InquiryDetailsModal;
