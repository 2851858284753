import React from 'react';

import { Copy } from 'ui';

import PlaceInput from 'components/PlaceInput';
import { EventSettingsFields } from 'components/EventSettingsFlyout';

export type City = {
    location: Bizly.Event['location'];
    googlePlaceId: Bizly.Event['googlePlaceId'];
};

type TCityField = {
    field: string;
    defaultValue: City;
    error: Object | null;
    onChange: ({ value, field, error }: { value: City; field: string; error: Object | null }) => void;
};

const CityField = ({ field, onChange: onChangeProp, defaultValue }: TCityField) => (
    <PlaceInput
        asFormField
        inModalOrPopover
        onChange={(location: string, googlePlaceId: string) =>
            onChangeProp({
                field,
                error: {},
                value: {
                    location,
                    googlePlaceId,
                },
            })
        }
        citiesOnly
        defaultValue={defaultValue?.location}
        defaultValueId={defaultValue?.googlePlaceId}
    />
);

export type EventForm = Partial<
    {
        city: City;
        templateId: number;
    } & Pick<Bizly.Event, 'name' | 'cventId' | 'type' | 'costCenter'>
>;

export const EventCreateFields = (city?: City | null, templates?: Bizly.EventTemplate[]) => ({
    ...EventSettingsFields,
    name: {
        prompt: 'Meeting Name',
        type: 'text',
        options: {
            autoFocus: true,
        },
    },
    city: {
        prompt: 'City',
        type: CityField,
        ...(city && {
            options: {
                defaultValue: city,
            },
        }),
    },
    cityNote: {
        type: () => <Copy small>For virtual gatherings, input your current location to set the time zone.</Copy>,
        perRow: 2,
    },
    ...(templates?.length
        ? {
              templateId: {
                  prompt: 'Playbook',
                  type: 'select',
                  options: {
                      options: templates,
                  },
              },
          }
        : {}),
});

export const schema = [
    {
        key: 'nameRow',
        fields: ['name'],
        spacing: 'medium',
    },
    {
        key: 'cityAndType',
        fields: ['city', 'type'],
        spacing: 'xsmall',
    },
    {
        fields: ['cityNote'],
        spacing: 'medium',
    },
    {
        key: 'costCenterAndCventId',
        fields: ['costCenter', 'cventId'],
        spacing: false,
    },
];

export const schemaWithTemplate = [
    {
        key: 'templateId',
        fields: ['templateId'],
        spacing: 'medium',
    },
    ...schema,
];

export const toBizlyEvent = ({ name, cventId, type, costCenter, city, templateId }: EventForm) => ({
    name,
    type,
    cventId,
    costCenter,
    templateId,
    ...city,
});

export const validateForm = ({ name, cventId, type, costCenter, city }: EventForm) =>
    name?.trim() && cventId?.toString().trim() && type && costCenter?.trim() && city?.location && city?.googlePlaceId;
