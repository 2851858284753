import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';

import { H5Headline } from 'components/ui/Headline';
import { Spacer } from 'ui';

import SideNav from 'components/SideNav';

import IntegrationConnection from './IntegrationConnection';
import IntegrationCallback from './IntegrationCallback';

export default function IntegrationsPage() {
    let { path } = useRouteMatch();

    return (
        <SideNav>
            <H5Headline>Integrations</H5Headline>
            <Spacer />

            <Switch>
                <Route exact path={`${path}`}>
                    <IntegrationConnection />
                </Route>
                <Route exact path={`${path}/callback/:integration`}>
                    <IntegrationCallback />
                </Route>
            </Switch>
        </SideNav>
    );
}
