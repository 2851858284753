import React from 'react';

import { searchCollaboratorsAndGroups, isGroup } from 'api';

import { Column, Copy } from 'ui';

import AutoComplete from 'components/ui/AutoComplete';
import { emailIsValid } from 'utils';

type TOption = PartialExcept<BizlyAPI.EventCollaboratorSuggestion, 'email'> | BizlyAPI.GroupSuggestion;

const isGroupOption = (option: TOption): option is BizlyAPI.GroupSuggestion =>
    isGroup(option as BizlyAPI.GroupSuggestion);

export const AddAttendees = ({
    attendees,
    addAttendee,
    delAttendee,
    addGroup,
}: {
    attendees: BizlyAPI.BasicAttendee[];
    addAttendee: (attendee: BizlyAPI.BasicAttendee) => void;
    delAttendee: (email?: string) => void;
    addGroup: (group: BizlyAPI.ContactGroup) => void;
}) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [options, setOptions] = React.useState<TOption[]>([]);
    const [value, setValue] = React.useState<TOption>({ email: '' });

    async function handleSuggestions(query: string) {
        if (!query) {
            setLoading(false);
        }
        setValue({ email: query });
        if (query?.length < 3) {
            setOptions([]);
        }
        setLoading(true);
        const response = await searchCollaboratorsAndGroups(query);
        setLoading(false);

        const queryIsValidEmail = emailIsValid(query);
        const emailIsNew =
            queryIsValidEmail &&
            !response.suggestions.find(
                response => !isGroup(response) && response.email.toLowerCase() === query.toLowerCase()
            );

        setOptions([...(emailIsNew ? [{ email: query }] : []), ...response.suggestions]);
    }

    const clear = () => {
        setValue({ email: '' });
        setLoading(false);
    };

    return (
        <AutoComplete
            blurOnSelect
            options={options}
            loading={loading}
            getOptionLabel={option => (isGroupOption(option) ? option.name : option?.email?.toString() || '')}
            getOptionSelected={option => !isGroupOption(option) && attendees.some(p => p.email === option.email)}
            noOptionsText={!isGroupOption(value) && (value?.email?.length ?? 0) < 3 ? 'No results' : 'No results'}
            value={value}
            onChange={(_, newValue) => {
                if (isGroupOption(newValue)) {
                    addGroup(newValue);
                } else {
                    if (attendees.some(p => p.email === newValue.email)) {
                        delAttendee(newValue.email);
                        clear();
                        return;
                    }
                    addAttendee(newValue);
                }
                clear();
            }}
            asField
            inModalOrPopover
            InputProps={{
                autoFocus: true,
                placeholder: 'Add attendee by email',
            }}
            onKeyUp={e => {
                if (e.key === 'Enter') {
                    if (isGroupOption(value)) {
                        addGroup(value);
                    } else if (emailIsValid(value.email)) {
                        addAttendee(value);
                    }
                    clear();
                }
            }}
            filterOptions={o => o}
            onInputChange={handleSuggestions}
            renderOption={option => (
                <Column itemSpacing="xsmall">
                    <b>
                        <Copy>
                            {isGroupOption(option) ? option.name : [option.firstName, option.lastName].join(' ')}
                        </Copy>
                    </b>
                    {!isGroupOption(option) && <Copy>{option.email}</Copy>}
                </Column>
            )}
        />
    );
};
