import React from 'react';
import styled from 'styled-components';
import { getExtension } from 'cloudinary';

import { Column, CopyFaded, ExternalLink, Spacer, TopRounded } from 'ui';
import { H3Headline } from 'components/ui/Headline';
import { UploadList } from 'components/FormFields';

import { ReactComponent as DeleteIconSVG } from 'images/icons/trash-can.svg';
import { withInteractibleIconStyles } from 'shared';
import colorFns from 'colorFns';

const DeleteIcon = styled(withInteractibleIconStyles(DeleteIconSVG)).attrs({ viewBox: '2 4 15 21' })`
    height: 21px;
    width: 21px;
    flex: 0 0 auto;
    color: ${colorFns.pureWhite.alpha(0.74)};
`;

const Attachment = styled(TopRounded)`
    justify-content: space-between;
    border: 1px solid ${colorFns.softBorder};
    background: ${colorFns.brand};
`;

const AddButton = styled.div<{ disabled?: boolean }>`
    max-width: 215px;
    border: 1px solid ${colorFns.softBorder};
    border-radius: 8px;
    ${({ disabled }) =>
        disabled
            ? `
    opacity: 0.5;
    pointer-events: none;
    `
            : ''}
`;

const ScrollCol = styled(Column)`
    width: 100%;
    max-height: 40vh;
    overflow: auto;
`;

export default function DocumentsCard({
    attachments,
    onUpload,
    onDelete,
    disabled,
}: {
    attachments: BizlyAPI.Meeting['attachments'];
    onUpload?: (attachment: BizlyAPI.Attachment) => void;
    onDelete?: (attachment: BizlyAPI.Attachment) => void;
    disabled?: boolean;
}) {
    return (
        <Column itemSpacing="small">
            <H3Headline>Documents</H3Headline>
            <CopyFaded small>These are documents you would like to share with team for meeting</CopyFaded>
            <Spacer xsmall />
            <ScrollCol itemSpacing="small">
                {attachments?.map(attachment => {
                    const { title, url } = attachment;
                    return (
                        <Attachment key={url}>
                            <ExternalLink href={url} openInNewTab underline>
                                {[title, url && getExtension(url)].join('.')}
                            </ExternalLink>
                            {onDelete && (
                                <DeleteIcon
                                    onClick={e => {
                                        onDelete(attachment);
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                />
                            )}
                        </Attachment>
                    );
                })}
            </ScrollCol>
            {onUpload && (
                <AddButton disabled={disabled}>
                    <UploadList
                        limit={1}
                        field=""
                        onChange={({ value }: { value: BizlyAPI.Attachment[] }) => {
                            onUpload(value[0]);
                        }}
                        nested
                        prompt="Add Document"
                    />
                </AddButton>
            )}
        </Column>
    );
}
