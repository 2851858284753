import React from 'react';
import { useSnackbar } from 'notistack';

import { contactsActions } from './store';

export default function LoadContacts() {
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        const load = async () => {
            try {
                await contactsActions.load();
            } catch (e) {
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            }
        };

        load();
    }, [enqueueSnackbar]);

    return null;
}
