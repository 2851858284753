import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { RRule, Frequency } from 'rrule';
import { weekdayFromMonday, weekdayOfMonth } from '../utils';

import FormBase from 'components/Form';
import { Dialog } from '@material-ui/core';
import { LabeledCheckbox, Row } from 'ui';
import SmallCircleButton from 'components/ui/SmallCircleButton';
import TextButton from 'components/ui/Button/TextButton';

import colorFns from 'colorFns';

function ButtonRow({
    field,
    value = [],
    onChange,
}: {
    field: string;
    value: number[];
    onChange: (update: { value: number[]; field: string }) => void;
}) {
    const options: {
        id: number;
        name: string;
    }[] = [
        { id: RRule.SU.weekday, name: 'S' },
        { id: RRule.MO.weekday, name: 'M' },
        { id: RRule.TU.weekday, name: 'T' },
        { id: RRule.WE.weekday, name: 'W' },
        { id: RRule.TH.weekday, name: 'T' },
        { id: RRule.FR.weekday, name: 'F' },
        { id: RRule.SA.weekday, name: 'S' },
    ];
    return (
        <Row itemSpacing="small">
            {options.map(({ id, name }) => (
                <SmallCircleButton
                    key={id}
                    tertiary
                    tertiaryReverse={value.includes(id)}
                    onClick={() =>
                        onChange({
                            value: value.includes(id) ? value.filter(v => v !== id) : [...value, id].sort(),
                            field,
                        })
                    }
                >
                    {name}
                </SmallCircleButton>
            ))}
        </Row>
    );
}

const endsFields = {
    ends: {
        type: 'radioselect',
        fixedWidth: 110,
        options: {
            perRow: 1,
            tertiary: true,
            options: [
                { id: 'never', name: 'never' },
                { id: 'date', name: 'on' },
                { id: 'count', name: 'after' },
            ],
        },
    },
    date: {
        type: 'date_outlined',
        perRow: '1/3',
        options: {
            format: 'MMM d, yyyy',
            placeholder: moment().format('MMM DD, YYYY'),
            inline: true,
        },
    },
    count: {
        type: 'counter',
        perRow: 'auto',
        options: {
            min: 1,
            tertiary: true,
        },
    },
};

const endsSchema = (count: number) => [
    {
        fields: [
            'ends',
            {
                type: 'nested',
                schema: [
                    {
                        fields: [{ type: 'display', options: { label: String.fromCharCode(0x200b) } }],
                        spacing: false,
                    },
                    {
                        fields: ['date'],
                        spacing: false,
                    },
                    {
                        fields: [
                            'count',
                            { type: 'display', options: { label: count > 1 ? 'occurrences' : 'occurrence' } },
                        ],
                        itemSpacing: 'xsmall',
                        spacing: false,
                    },
                ],
            },
        ],
    },
];

type EndsFormValue = { ends: 'never' | 'date' | 'count'; date: Date; count: number };
type EndsValue = null | Date | number;

export const isNever = (val: EndsValue): val is null => val === null;
export const isCount = (val: EndsValue): val is number => typeof val === 'number';
export const isDate = (val: EndsValue): val is Date => !isNever(val) && !isCount(val);

function Ends({
    field,
    value = null,
    onChange,
    initialDate,
}: {
    field: string;
    value?: null | Date | number;
    onChange: (update: { field: string; value?: null | Date | number }) => void;
    initialDate: Date;
}) {
    const choice = React.useMemo(() => (isNever(value) ? 'never' : isCount(value) ? 'count' : 'date'), [value]);

    const [formValue, setFormValue] = React.useState<EndsFormValue>({
        ends: choice,
        date: isDate(value) ? value : initialDate,
        count: isCount(value) ? value : 13,
    });

    return (
        <Form
            fields={{
                ...endsFields,
                ...{
                    date: {
                        ...endsFields.date,
                        disabled: formValue.ends !== 'date',
                    },
                    count: {
                        ...endsFields.count,
                        disabled: formValue.ends !== 'count',
                    },
                },
            }}
            schema={endsSchema(formValue.count)}
            value={formValue}
            onChange={({ value }: { value: EndsFormValue }) => {
                setFormValue(value);
                onChange({
                    field,
                    value: value.ends === 'date' ? value.date : value.ends === 'count' ? value.count : null,
                });
            }}
        />
    );
}

const fields = (date: Date, interval: number) => ({
    interval: {
        type: 'counter',
        perRow: 'auto',
        options: {
            min: 1,
            tertiary: true,
        },
    },
    freq: {
        type: 'select',
        perRow: '1/3',
        options: {
            options:
                interval > 1
                    ? [
                          { id: RRule.DAILY, name: 'days' },
                          { id: RRule.WEEKLY, name: 'weeks' },
                          { id: RRule.MONTHLY, name: 'months' },
                          { id: RRule.YEARLY, name: 'years' },
                      ]
                    : [
                          { id: RRule.DAILY, name: 'day' },
                          { id: RRule.WEEKLY, name: 'week' },
                          { id: RRule.MONTHLY, name: 'month' },
                          { id: RRule.YEARLY, name: 'year' },
                      ],
        },
    },
    byDay: {
        type: ButtonRow,
        prompt: 'Repeat on',
    },
    byMonth: {
        type: 'select',
        options: {
            options: [
                { id: 'day', name: `Monthly on day ${moment(date).format('D')}` },
                {
                    id: 'weekday',
                    name: `Monthly on the ${weekdayOfMonth(moment(date)).count} ${moment(date).format('dddd')}`,
                },
            ],
        },
    },
    ends: {
        type: Ends,
        prompt: 'Ends',
        options: {
            initialDate: date,
        },
    },
});

const repeatMessage = { type: 'display', options: { label: 'Repeat every ' } };

const schema = [
    {
        header: 'Recurring Options',
        fields: [repeatMessage, 'interval', 'freq'],
        itemSpacing: 'xsmall',
        spacing: 'smallish',
    },
    {
        fields: ['ends'],
        spacing: false,
    },
];

const schemaWeek = [
    {
        header: 'Recurring Options',
        fields: [repeatMessage, 'interval', 'freq'],
        itemSpacing: 'xsmall',
        spacing: 'small',
    },
    {
        fields: ['byDay'],
        spacing: 'medium',
    },
    {
        fields: ['ends'],
        spacing: false,
    },
];

const schemaMonth = [
    {
        header: 'Recurring Options',
        fields: [repeatMessage, 'interval', 'freq'],
        itemSpacing: 'xsmall',
        spacing: 'small',
    },
    {
        fields: ['byMonth'],
        spacing: 'medium',
    },
    {
        fields: ['ends'],
        spacing: false,
    },
];

const CardDialog = styled(Dialog)`
    .MuiPaper-root {
        background: ${colorFns.darkGrey};
        border: 0px solid ${colorFns.darkGrey};
        color: unset;

        padding: 24px;
    }
`;

const Form = styled(FormBase)`
    width: 400px;
`;

export type Recurrence = {
    interval: number;
    freq: Frequency.DAILY | Frequency.WEEKLY | Frequency.MONTHLY | Frequency.YEARLY;
    byDay?: number[];
    byMonth?: 'day' | 'weekday';
    ends: null | Date | number;
};

const singleInterval = {
    [RRule.DAILY]: 'Daily',
    [RRule.WEEKLY]: 'Weekly',
    [RRule.MONTHLY]: 'Monthly',
    [RRule.YEARLY]: 'Yearly',
};

const multipleInterval = {
    [RRule.DAILY]: 'days',
    [RRule.WEEKLY]: 'weeks',
    [RRule.MONTHLY]: 'months',
    [RRule.YEARLY]: 'years',
};

const weekdayName: Record<number, string> = {
    [RRule.SU.weekday]: 'Sunday',
    [RRule.MO.weekday]: 'Monday',
    [RRule.TU.weekday]: 'Tuesday',
    [RRule.WE.weekday]: 'Wednesday',
    [RRule.TH.weekday]: 'Thursday',
    [RRule.FR.weekday]: 'Friday',
    [RRule.SA.weekday]: 'Saturday',
};

const sundayFirst = (weekdayA: number, weekdayB: number) =>
    weekdayA === 6 ? -1 : weekdayB === 6 ? 1 : weekdayA - weekdayB;

const buildSummary = (formValue: Recurrence, initialDate: Date) => {
    const weekdays =
        formValue.freq === RRule.WEEKLY
            ? ` on ${formValue.byDay
                  ?.sort(sundayFirst)
                  .map(wd => weekdayName[wd])
                  .join(', ')}`
            : formValue.freq === RRule.MONTHLY
            ? formValue.byMonth === 'weekday'
                ? ` on the ${weekdayOfMonth(moment(initialDate)).count} ${moment(initialDate).format('dddd')}`
                : ` on day ${moment(initialDate).format('D')}`
            : '';

    return isCount(formValue.ends) && formValue.ends === 1
        ? 'Once'
        : [
              formValue.interval === 1
                  ? singleInterval[formValue.freq] + weekdays
                  : `Every ${formValue.interval} ${multipleInterval[formValue.freq]}` + weekdays,

              ...(!isNever(formValue.ends)
                  ? isDate(formValue.ends)
                      ? [`Until ${moment(formValue.ends).format('MMM DD, YYYY')}`]
                      : [`${formValue.ends} times`]
                  : []),
          ].join(', ');
};

const initialRecurrence = (date: Date): Recurrence => ({
    interval: 1,
    freq: RRule.WEEKLY,
    byDay: [weekdayFromMonday(date)],
    byMonth: 'day',
    ends: null,
});

const SummaryButton = styled(TextButton)`
    padding-left: 0px;
`;

const Checkbox = styled(LabeledCheckbox)`
    svg {
        fill: ${colorFns.pureWhite};
    }

    .Mui-checked {
        svg {
            fill: ${colorFns.secondaryTextAction};
        }
    }
`;

export default function RecurrenceForm({
    field,
    value,
    onChange,
    initialDate = new Date(),
}: {
    field: string;
    value?: Recurrence;
    onChange: (update: { field: string; value?: Recurrence }) => void;
    initialDate?: Date;
}) {
    const [formValue, setFormValue] = React.useState<Recurrence>(value ?? initialRecurrence(initialDate));
    React.useEffect(() => {
        if (formValue.freq === RRule.WEEKLY && formValue.byDay?.length === 0) {
            setFormValue(val => ({ ...val, byDay: [RRule.SU.weekday] }));
        }
    }, [formValue]);

    const [open, setOpen] = React.useState(false);

    const summary = buildSummary(formValue, initialDate);

    return (
        <Row>
            <Checkbox
                isChecked={!!value}
                label={
                    <SummaryButton secondary onClick={() => setOpen(true)}>
                        {summary}
                    </SummaryButton>
                }
                disabled={false}
                onChange={(e, checked) => onChange({ field, value: checked ? formValue : undefined })}
            />

            <CardDialog
                open={open}
                onBackdropClick={() => {
                    onChange({ field, value: undefined });
                    setOpen(false);
                }}
            >
                <Form
                    fields={fields(initialDate, formValue.interval)}
                    schema={
                        formValue.freq === RRule.WEEKLY
                            ? schemaWeek
                            : formValue.freq === RRule.MONTHLY
                            ? schemaMonth
                            : schema
                    }
                    value={formValue}
                    onChange={({ value }: { value: Recurrence }) => setFormValue(value)}
                />
                <Row justifyContent="flex-end">
                    <TextButton
                        secondary
                        onClick={() => {
                            onChange({ field, value: formValue });
                            setOpen(false);
                        }}
                    >
                        OK
                    </TextButton>
                </Row>
            </CardDialog>
        </Row>
    );
}
