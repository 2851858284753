import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import partition from 'lodash/partition';
import { tz as momentTz } from 'moment-timezone';
import { userFormattedTimestamp } from 'utils/moment';

import { alertsActions, LoadAlerts, useAlerts, PollAlerts, ClearUnreadAlerts } from 'stores/alerts';

import { Row, Column, Copy, Spacer } from 'ui';
import { LargeCircleButton } from 'components/ui/SmallCircleButton';
import { Modal, Tooltip } from '@material-ui/core';
import TextButton from 'components/ui/Button/TextButton';
import { Link } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';

import { ReactComponent as AlertIconSVG } from 'images/icons/alert.svg';
import { ReactComponent as MessageBubbleSVG } from 'images/icons/message_bubble.svg';
import { ReactComponent as CalendarSVG } from 'images/icons/calendar-picker.svg';
import { ReactComponent as RegistrationIconSvg } from 'images/icons/registration.svg';

import colorFns from 'colorFns';

const AlertsIcon = styled(AlertIconSVG)`
    width: 20px;
    height: 20px;
`;

const ChatIcon = styled(MessageBubbleSVG)`
    width: 28px;
    height: 28px;
`;

const SurveyIcon = styled(RegistrationIconSvg)`
    width: 26px;
    height: 26px;
`;

const MeetingIcon = styled(CalendarSVG)`
    width: 26px;
    height: 26px;
`;

const AlertsCount = styled(Copy)`
    position: absolute;

    width: 28px;
    height: 28px;

    top: -9px;
    right: -9px;

    border-radius: 28px;
    background: ${colorFns.warningAction} !important;
    color: ${colorFns.pureWhite} !important;

    text-align: center;
    line-height: 26px;
`;

const AlertsButton = styled(LargeCircleButton)<{ open?: boolean; hasNew?: boolean }>`
    position: fixed;
    bottom: 20px;
    right: 20px;

    z-index: 2000;

    background: ${colorFns.grey} !important;
    color: ${({ hasNew, ...props }) => (hasNew ? colorFns.warningAction(props) : colorFns.pureBlack(props))} !important;
    border-color: transparent;

    &:hover {
        background: ${({ hasNew, ...props }) => (hasNew ? colorFns.lightGrey(props) : colorFns.grey(props))} !important;
        color: ${colorFns.warningAction};
    }

    ${({ open, ...props }) =>
        open &&
        `
        background: ${colorFns.warningAction(props)} !important;
        color: ${colorFns.pureBlack(props)};

    &:hover {
        background: ${colorFns.warningActionHover(props)} !important;
        color: ${colorFns.pureBlack(props)};
    }
    `}
`;

const AlertBubble = styled(Row)<{ unread?: boolean }>`
    width: 400px;
    background: ${colorFns.brand};
    color: ${colorFns.pureWhite.alpha(0.74)};
    border: 1px solid ${colorFns.pureWhite.alpha(0.74)};
    padding: 16px;
    border-radius: 8px;

    margin-left: 0;
    margin-right: 0;

    ${({ unread, ...props }) =>
        unread
            ? `
    border-color: ${colorFns.secondaryTextAction(props)};
    svg {
        color: ${colorFns.secondaryTextAction(props)};
    }
    `
            : ``}
`;

const AlertText = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    &.AlertMessage {
        padding-right: 4px;
    }
`;

const growKeyframes = keyframes`
  0%   {  
      transform: translateY(100vh);
      opacity: 0;
  }
  100% {  
      transform: translateY(0vh);
      opacity: 1;
  }
`;
const hideKeyframes = keyframes`
  0%   {  
      transform: translateY(0vh);
      opacity: 1;
  }
  100% {  
      transform: translateY(100vh);
      opacity: 0;
  }
`;

const AlertsList = styled(Column)`
    flex-direction: column;

    overflow: scroll;

    ${({ reverse }: { reverse?: boolean }) =>
        reverse
            ? css`
                  animation: ${hideKeyframes} 0.15s 0s both;
                  transform: translateY(0vh);
                  opacity: 1;
              `
            : css`
                  animation: ${growKeyframes} 0.2s 0s both;
                  transform: translateY(100vh);
                  opacity: 0;
              `}
`;
const AlertsListInner = styled(Column)`
    flex-direction: column-reverse;
    margin: 0;
`;

const AlertsOuter = styled(Column)`
    position: absolute;

    top: 20px;
    right: 20px;
    bottom: 80px;

    justify-content: flex-end;

    &:focus {
        outline: none;
    }

    overflow: hidden;
`;

const Underline = styled.span`
    text-decoration: underline;
`;

const alertToIcon = {
    'new chat message': <ChatIcon />,
    'meeting requires nps': <SurveyIcon />,
    'meeting changed': <MeetingIcon />,
};

const AlertMessage = ({ alert }: { alert: BizlyAPI.Alert & { count?: number } }) => {
    if (alert.type === 'new chat message')
        return (
            <Copy small>
                {`${alert.count} new message${alert.count === 1 ? '' : 's'} in `}
                <Underline>{alert.meeting.name}</Underline>
            </Copy>
        );

    if (alert.type === 'meeting requires nps')
        return (
            <Copy small>
                {`Don't forget to fill out the NPS score for `}
                <Underline>{alert.meeting.name}</Underline>
            </Copy>
        );

    if (alert.type === 'meeting changed')
        return (
            <Copy small>
                <Underline>{alert.meeting.name}</Underline> was updated
            </Copy>
        );

    return null;
};

const formatAlertTime = ({ alert }: { alert: BizlyAPI.Alert }) => {
    // Alert time formatting:
    // within 24 hours -> relative/humanized time i.e. "2 hours ago"
    // same month -> date without year
    // older -> date with year

    const alertTime = momentTz(alert.createdAt, 'UTC');
    const now = momentTz();

    if (now.diff(alertTime, 'days') < 1) {
        return alertTime.fromNow();
    }

    if (alertTime.month() === now.month() && alertTime.year() === now.year()) {
        return alertTime.format('MMMM Do');
    }

    return alertTime.format('MMMM Do, YYYY');
};

const Alert = ({ alert, onClick }: { alert: BizlyAPI.Alert & { count?: number }; onClick?: () => void }) => (
    <Link to={`/meetings/${alert.meeting.id}`} onClick={onClick}>
        <AlertBubble alignItems="center" itemSpacing="smallish">
            {alertToIcon[alert.type]}
            <AlertText>
                <AlertMessage alert={alert} />
                <Spacer smallish />
                <Tooltip title={userFormattedTimestamp(alert.createdAt)}>
                    <Copy small>{formatAlertTime({ alert })}</Copy>
                </Tooltip>
            </AlertText>
        </AlertBubble>
    </Link>
);

export default function Alerts() {
    const { page, loaded, hasOlder, unreadCount, groupedAlerts = [] } = useAlerts();

    const [open, setOpen] = React.useState(false);
    const [showRead, setShowRead] = React.useState(true);
    const [closing, setClosing] = React.useState(true);

    const openAlerts = () => {
        if (!loaded) return;

        setShowRead(unreadCount === 0);
        setClosing(false);
        setOpen(true);
    };

    const closeAlerts = () => {
        setClosing(true);
        setTimeout(() => setOpen(false), 150);
        alertsActions.clearUnread(true);
    };

    const toggleAlerts = () => {
        if (!open) {
            openAlerts();
        } else {
            closeAlerts();
        }
    };

    const [readAlerts, unreadAlerts] = partition(groupedAlerts, alert => alert.read);

    return (
        <>
            <LoadAlerts />
            <PollAlerts />

            <AlertsButton onClick={() => toggleAlerts()} open={open} hasNew={unreadCount > 0}>
                <AlertsIcon />
                {!open && unreadCount > 0 && <AlertsCount>{unreadCount}</AlertsCount>}
            </AlertsButton>

            <Modal open={open} onBackdropClick={closeAlerts} BackdropProps={{ style: { background: 'transparent' } }}>
                <AlertsOuter>
                    <AlertsList reverse={closing}>
                        <AlertsListInner itemSpacing="small">
                            {showRead ? (
                                <>
                                    {loaded && hasOlder && (
                                        <Waypoint key={page} onEnter={() => alertsActions.pullOlder()} />
                                    )}
                                    {readAlerts.map(alert => (
                                        <Alert key={alert.id} alert={alert} onClick={closeAlerts} />
                                    ))}
                                </>
                            ) : (
                                <TextButton secondary onClick={() => setShowRead(true)}>
                                    Show Read
                                </TextButton>
                            )}
                            {unreadAlerts.map(alert => (
                                <Alert key={alert.id} alert={alert} onClick={closeAlerts} />
                            ))}
                        </AlertsListInner>
                    </AlertsList>

                    {unreadCount > 0 && <ClearUnreadAlerts />}
                </AlertsOuter>
            </Modal>
        </>
    );
}
