import React, { useMemo } from 'react';
import styled from 'styled-components/macro';

import { OnlySingleLine } from 'shared';

import { Column, Row } from '../../ui';
import VenuePill, { TPillTypes } from './VenuePill';
import { TTileFormats } from './VenueTile';

const BasicsCaps = styled.div`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    text-transform: uppercase;
`;
const Subtext = styled.div`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;
const Title = styled(Row)`
    min-height: 0;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    white-space: normal;

    align-items: flex-start;
    max-height: 100%;
    text-overflow: clip;
    overflow: hidden;
`;
/* When the title is not overflowing we want flex-end so that it looks like this:
 _________
|         |
|T̲i̲t̲l̲e̲____|

However during an overflow, flex-end causes the title to do this:
 T̲i̲t̲l̲e̲____
|that     |
|o̲v̲e̲r̲f̲l̲o̲w̲s̲|

What we need to do is have the title itself be flex-start, but have it's wrapper be flex-end, giving us:
 _________
|Title    |
|t̲h̲a̲t̲_____|
 overflows
*/
const TitleText = ({ children }: { children: React.ReactNode }) => (
    <Row>
        <Title>{children}</Title>
    </Row>
);
// for when the title contains more than 1 thing:
const TitleRow = styled(Row)`
    min-height: 0;
    > * {
        display: flex;
        align-items: flex-end;
        height: 100%; /* each child is generally a Row */
    }
    > *:first-child {
        padding-right: 24px;
        box-sizing: border-box;
    }
    > ${Title} {
        flex: 60% 0 0;
        justify-content: flex-start;
    }
    > ${Subtext} {
        flex: 40% 0 0;
        justify-content: flex-end;
    }
`;

const TileText = styled(Column).attrs(({ format = 'default' }: { format: TTileFormats }) => ({ format }))`
    flex: 1 0 0;
    max-height: 100%;
    justify-content: space-between;

    & > * {
        flex: 0 1 auto;
    }

    & > *:first-child {
        flex: 1 0 0;
        min-height: 0;
        display: flex;
        align-items: flex-end; /* first child should have space above it, this rule affects when title overflows */
    }

    ${Title} {
        ${({ format, theme: { getColor, EColors } }) => {
            switch (format) {
                case 'tile':
                    return `
            font-size: 16px;
            font-weight: bold;
            line-height: 1.25;
            letter-spacing: -0.09px;
            min-height: 16px;
        `;
                case 'proposal':
                case 'featured':
                    return `
            font-size: 36px;
            font-weight: 600;
            line-height: 1.22;
            letter-spacing: -1.1px;
            color: ${getColor(EColors.featuredVenueOrSpaceName)};
            min-height: 36px;
        `;
                case 'thumb':
                case 'default':
                case 'proposalThumb':
                default:
                    return `
            font-size: 20px;
            font-weight: bold;
            line-height: 1;
            letter-spacing: -0.4px;
            color: ${getColor(EColors.featuredVenueOrSpaceName)};
            min-height: 20px;
            `;
            }
        }}
    }

    ${Subtext} {
        ${({ format }) => {
            switch (format) {
                case 'tile':
                case 'proposalThumb':
                    return `
            font-size: 13px;
            font-weight: normal;
            line-height: 1;
            letter-spacing: normal;
            min-height: 13px;
            ${format === 'proposalThumb' ? `margin-top: 8px` : ``}
            ${OnlySingleLine}
        `;
                case 'proposal':
                    return `
            font-size: 15px;
            font-weight: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: right;
            min-height: 15px;
        `;
                case 'featured':
                    return `
            font-size: 18px;
            font-weight: normal;
            line-height: 1.5;
            letter-spacing: normal;
            margin-top: 8px;
            min-height: 18px;
        `;
                case 'thumb':
                case 'default':
                default:
                    return ``;
            }
        }}
    }

    ${BasicsCaps} {
        ${OnlySingleLine}

        ${({ format }) => {
            switch (format) {
                case 'proposal':
                case 'featured':
                    return `
            font-size: 13px;
            font-weight: bold;
            line-height: 1;
            letter-spacing: normal;
            ${format === 'featured' ? `margin-bottom: 12px;` : ``}
            min-height: 13px;
        `;
                case 'tile':
                case 'thumb':
                case 'default':
                case 'proposalThumb':
                default:
                    return `
            font-size: 11px;
            font-weight: 500;
            line-height: 1.18;
            letter-spacing: normal;
            margin-top: 4px;
            min-height: 11px;
        `;
            }
        }}
    }
`;

const FloatingRow = styled(Row)`
    z-index: 5;
`;

const InfoColumn = styled(Column)`
    flex: 1 0 0;
    overflow: hidden;
`;

function tileTextPropsByFormat(
    venue: Bizly.Venue,
    format?: TTileFormats
): Partial<{
    padding: number;
    title: string;
    subtext: string;
    caps: string;
    superCaps: boolean;
    subtextRight: boolean;
    subtextUnderCaps: boolean;
    imgTransform: string;
}> {
    const { name, description, headline, address, qualityLevel, primaryDecor, type, neighborhood } = venue;

    const decor = primaryDecor ? primaryDecor.name + ' ' : '';
    const typeName = type ? type.name + ' ' : '';

    const fullBasics =
        headline ||
        `A ${[
            ...(decor ? [decor] : []),
            ...(qualityLevel ? [qualityLevel] : []),
            ...(typeName ? [typeName] : []),
            ...(neighborhood ? ['in ', neighborhood] : []),
        ].join(' ')}`;

    const shortBasics = `${qualityLevel ? qualityLevel + ' ' : ''}${typeName}`;
    const base = { title: name };

    switch (format) {
        case 'tile':
            return {
                ...base,
                padding: 12,
                subtext: neighborhood,
                caps: shortBasics,
                imgTransform: 'w_300,q_auto:best',
            };
        case 'thumb':
            return {
                ...base,
                padding: 20,
                caps: shortBasics,
                imgTransform: 'w_400,q_auto:best',
            };
        case 'proposalThumb':
            return {
                ...base,
                padding: 24,
                subtext: address,
                caps: fullBasics,
                subtextUnderCaps: true,
                imgTransform: 'w_600,q_auto:best',
            };
        case 'proposal':
            return {
                ...base,
                padding: 24,
                subtext: address,
                caps: fullBasics,
                superCaps: true,
                subtextRight: true,
            };
        case 'featured':
            return {
                ...base,
                padding: 48,
                subtext: description,
                caps: fullBasics,
                superCaps: true,
            };
        case 'default':
        default:
            return {
                ...base,
                padding: 24,
                caps: fullBasics,
                imgTransform: 'w_500,q_auto:best',
            };
    }
}

type TTileInfoProps = {
    venue: Bizly.Venue;
    format?: TTileFormats;
    pillType?: TPillTypes;
};

export default function TileInfo({ venue, format, pillType }: TTileInfoProps) {
    const { title, subtext, caps, superCaps, subtextRight, subtextUnderCaps } = useMemo(
        () => tileTextPropsByFormat(venue, format),
        [format, venue]
    );

    return (
        <InfoColumn>
            {pillType === 'inquiryStatus' && (
                <FloatingRow>
                    <VenuePill venue={venue} pillType="inquiryStatus" />
                </FloatingRow>
            )}
            {(pillType === 'inquiryStatus' || pillType === 'preferenceCategory') && (
                <FloatingRow>
                    <VenuePill venue={venue} pillType="preferenceCategory" />
                </FloatingRow>
            )}

            <TileText format={format}>
                {superCaps && caps && <BasicsCaps>{caps}</BasicsCaps>}
                {title &&
                    (subtextRight && subtext ? (
                        <TitleRow>
                            <TitleText>{title}</TitleText>
                            <Subtext>{subtext}</Subtext>
                        </TitleRow>
                    ) : (
                        <TitleText>{title}</TitleText>
                    ))}

                {subtextUnderCaps ? (
                    <>
                        {!superCaps && caps && <BasicsCaps>{caps}</BasicsCaps>}
                        {!subtextRight && subtext && <Subtext>{subtext}</Subtext>}
                    </>
                ) : (
                    <>
                        {!subtextRight && subtext && <Subtext>{subtext}</Subtext>}
                        {!superCaps && caps && <BasicsCaps>{caps}</BasicsCaps>}
                    </>
                )}
            </TileText>
        </InfoColumn>
    );
}
