import React from 'react';

import { useAttendees } from 'stores/attendees';

import {
    useMeetingStatus,
    useAttendeeStatus,
    useRosterState,
    Roster,
    RosterGroup,
    RosterCell,
} from 'amazon-chime-sdk-component-library-react';
import { getName } from '../utils';

import { Spacer } from 'ui';

const ChimeRosterAttendee = ({ id, attendee }: { id: string; attendee?: BizlyAPI.Attendee }) => {
    const { muted, videoEnabled, sharingContent } = useAttendeeStatus(id);

    return (
        <RosterCell
            name={getName(attendee)}
            subtitle={sharingContent ? 'presenting' : ''}
            muted={muted}
            videoEnabled={videoEnabled}
        />
    );
};

export default function AttendeeRoster() {
    const { roster } = useRosterState();
    const rosterAttendees = Object.values(roster);
    const rosterAttendeesExtIds = new Set(rosterAttendees.map(({ externalUserId }) => externalUserId));

    const attendeesStore = useAttendees();
    const attendeesList = attendeesStore.attendees ?? [];
    const invited = attendeesList.filter(
        attendee =>
            (attendee.status === 'attending' || attendee.status === 'invited') &&
            !rosterAttendeesExtIds.has('bizly-' + attendee.userId)
    );

    const meetingStatus = useMeetingStatus();
    const joined = meetingStatus === 1;

    return (
        <Roster>
            {joined && (
                <RosterGroup title="Joined" badge={rosterAttendees.length}>
                    {rosterAttendees.map(({ chimeAttendeeId, externalUserId }) => (
                        <ChimeRosterAttendee
                            key={externalUserId}
                            id={chimeAttendeeId}
                            attendee={attendeesList.find(attendee => 'bizly-' + attendee.userId === externalUserId)}
                        />
                    ))}
                </RosterGroup>
            )}
            <Spacer />
            {invited.length > 0 && (
                <RosterGroup title={joined ? 'Invited' : undefined} badge={joined ? invited.length : undefined}>
                    {invited.map(attendee => (
                        <RosterCell key={'bizly-' + attendee.userId} name={getName(attendee)} />
                    ))}
                </RosterGroup>
            )}
        </Roster>
    );
}
