import React, { useMemo } from 'react';
import styled from 'styled-components/macro';

import { Column, Spacer, Copy, RoundedImageWithText } from '../../ui';

const SpaceTitle = styled(Copy)`
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.4px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.featuredVenueOrSpaceName)};

    margin-bottom: 4px;
`;
const SpaceCapacity = styled(Copy)`
    font-size: 15px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;
const SpaceDescription = styled(Copy)`
    font-size: 15px;
    line-height: 1.47;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};

    > p {
        margin: 0;
    }
`;

type SpaceThumbProps = {
    space: Bizly.Space;
    size?: number;
    width?: string | number;
    height?: string | number;
    fixedRatio?: string;
    perRow?: number;
};

export const VenueSpaceThumb = ({ space, size = 120, fixedRatio, perRow = 1, ...props }: SpaceThumbProps) => {
    const spaceCapacity = useMemo(() => (space.maxCapacity ? `Up to ${space.maxCapacity} guests` : null), [
        space.maxCapacity,
    ]);
    const innerPadding = 32;
    const perRowFlex = `${100 / perRow}%`;

    return (
        <RoundedImageWithText
            url={space.images[0] ? space.images[0].srcUrl : ''}
            alt={space.images[0] ? space.images[0].description : ''}
            {...(fixedRatio ? { fixedRatio } : { size })}
            linearGradient
            style={{
                flex: `0 0 ${perRowFlex}`,
                maxWidth: perRowFlex,
            }}
            {...props}
        >
            <Column
                style={{
                    justifyContent: 'flex-end',
                    ...(fixedRatio
                        ? {
                              position: 'absolute',
                              top: innerPadding,
                              bottom: innerPadding,
                              left: innerPadding,
                              right: innerPadding,
                          }
                        : { width: size }),
                }}
            >
                <SpaceTitle>{space.name}</SpaceTitle>
                {spaceCapacity && <SpaceCapacity>{spaceCapacity}</SpaceCapacity>}
                <Spacer small />
                <SpaceDescription dangerouslySetInnerHTML={{ __html: space.description }} />
            </Column>
        </RoundedImageWithText>
    );
};

export default VenueSpaceThumb;
