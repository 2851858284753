import { StoreApi } from 'zustand';
import shallow from 'zustand/shallow';

export function withCache<
    TStore extends Record<string | number | symbol, any>,
    TKey extends keyof TStore,
    TCached extends Partial<TStore>
>({
    storeApi,
    cacheFn,
    key,
    shouldCache,
}: {
    storeApi: StoreApi<TStore>;
    cacheFn: (store: TStore) => TCached;
    key: TKey;
    shouldCache?: (update: TCached) => boolean;
}) {
    type TKeyValue = TStore[TKey];
    const cache = {} as Record<TKeyValue, TCached>;

    storeApi.subscribe(
        cacheValue => {
            if (cacheValue !== null) {
                if (shouldCache && !shouldCache(cacheValue)) return;
                cache[storeApi.getState()[key]] = cacheValue;
            }
        },
        cacheFn,
        shallow
    );

    return <TTrigger extends TKeyValue>(load: (trigger: TTrigger) => any) => (trigger: TTrigger) => {
        const cached = cache[trigger];
        if (cached) {
            storeApi.setState(cached);
        }
        return load(trigger);
    };
}
