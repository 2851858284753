import React from 'react';
import styled from 'styled-components/macro';
import moment from 'moment-timezone';
import { tzMoment } from 'utils/moment';

import { ATTENDING_STATUSES } from 'pages/RSVP';

import { Column, Row, PaddedCard, Copy, Spacer, Line, Button as FlatButton, ExternalLink, InlineRow } from 'ui';
import { H2Headline, WhiteHeadliner } from '../ui/Headline';
import { RichTextEditor } from 'components/Form/fields';
import Policies from 'components/ui/Policies';
import { CloudinaryUploader } from 'components/CloudinaryUploader';

import { ReactComponent as _CalendarIcon } from 'images/icons/calendar.svg';
import { ReactComponent as _VenueIcon } from 'images/icons/venue_pin.svg';
import { ReactComponent as _VideoConferenceIcon } from 'images/icons/Video_Conference.svg';
import { ReactComponent as EditIconSVG } from 'images/icons/pencil.svg';
import { ReactComponent as DeleteIconSVG } from 'images/icons/trash-can.svg';

import RSVPForm from 'pages/RSVPForm';
import { CustomQuestionsForm } from 'pages/RSVPForm';
import colorFns from 'colorFns';
import { withInteractibleIconStyles } from 'shared';
import useThemedColor from 'hooks/useThemedColor';
import { Spinner } from 'components/Spinner';

const PREVIEW_CARD_WIDTH = 600;
const CARD_WIDTH = 680;
const IMAGE_HEIGHT = 400;

// vertical-align bottom remove space under image
const FittedImage = styled.img`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    object-fit: cover;

    vertical-align: bottom;
`;

const ImageBackgroundGradient = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background: url('https://res.cloudinary.com/hdd626jg7/image/upload/t_email_overlay_gradient/emails/email_image_gradient.png');
    background-size: cover;
`;

const InviteTitle = styled(WhiteHeadliner)`
    position: absolute;
    left: 0;
    bottom: 0;

    padding: 0 32px 19px 32px;
`;

const LogoWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;

    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};

    border-bottom-right-radius: 8px;
`;

const CompanyLogo = styled(FittedImage)`
    width: 48px;
    height: 48px;
    padding: 18px;

    object-position: 0;
`;

const VenueIcon = styled(_VenueIcon)`
    color: ${colorFns.primaryDisplayIcon.themeOverride('bizly')};
`;

const CalendarIcon = styled(_CalendarIcon)`
    color: ${colorFns.primaryDisplayIcon.themeOverride('bizly')};
`;

const VideoConferenceIcon = styled(_VideoConferenceIcon)`
    margin-top: 5px;
    margin-right: 6px;
`;

const DetailCopy = styled(Copy)`
    line-height: 1.6;
`;

const PreWrapText = styled(Copy)`
    white-space: pre-wrap;
`;

const StyledHTML = styled.div`
    a {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
        text-decoration: underline;
    }
`;

const RSVPDetailSection = ({ icon, text, textArray }: { icon: React.ReactNode; text?: string; textArray?: string[] }) =>
    text || textArray ? (
        <Row>
            <Column>{icon}</Column>
            <Spacer col xsmall />
            <Column>
                {text && <DetailCopy>{text}</DetailCopy>}
                {textArray &&
                    textArray.map(
                        (textLine?: string, idx?: number) =>
                            textLine && <DetailCopy key={textLine + idx}>{textLine}</DetailCopy>
                    )}
            </Column>
        </Row>
    ) : null;

const formatWithTimezone = (date: string, format: string, timezone?: string, tzFormat?: string) =>
    timezone ? moment.tz(date, timezone).format(tzFormat ?? format) : moment(date).format(format);

const renderScheduleDateTimes = (start?: string, end?: string, timezone?: string) => {
    return [
        start && formatWithTimezone(start, 'ddd, MMM Do, yyyy', timezone),
        start &&
            end &&
            `${formatWithTimezone(start, 'h:mma', timezone)} - ${formatWithTimezone(
                end,
                'h:mma',
                timezone,
                'h:mma z'
            )}`,
    ].filter(str => str);
};

const renderParcelDateTimes = (
    {
        startDate,
        startTime,
        endDate,
        endTime,
    }: {
        startDate: string;
        startTime: string;
        endDate: string;
        endTime: string;
    },
    timezone?: string
) => {
    const start = tzMoment(startDate + ' ' + startTime, timezone);
    const end = tzMoment(endDate + ' ' + endTime, timezone);

    const sameDay = start.isSame(end, 'day');

    return [
        `${formatWithTimezone(
            startDate + ' ' + startTime,
            sameDay ? 'MMM Do, yyyy' : `MMM Do, yyyy, h:mma${String.fromCharCode(160)}-`,
            timezone
        )}`,
        `${sameDay ? formatWithTimezone(startDate + ' ' + startTime, `h:mma - `, timezone) : ''}${formatWithTimezone(
            endDate + ' ' + endTime,
            sameDay ? 'h:mma' : 'MMM Do, yyyy, h:mma',
            timezone,
            sameDay ? 'h:mma z' : 'MMM Do, yyyy, h:mma z'
        )}`,
    ];
};

const renderPlannerScheduleDateTimes = ({
    date,
    startTime,
    endTime,
    timezone,
}: BizlyAPI.ScheduleBlock & Partial<{ timezone?: string }>) => {
    return [
        date && formatWithTimezone(date + ' ' + startTime, 'ddd, MMM Do, yyyy', timezone),
        startTime &&
            endTime &&
            `${formatWithTimezone(`${date} ${startTime}`, 'h:mma', timezone)} -
        ${formatWithTimezone(`${date} ${endTime}`, 'h:mma', timezone, 'h:mma z')}`,
    ].filter(str => str);
};

const RSVPCardContent = ({
    color,
    firstName,
    lastName,
    name,
    headline,
    description,
    startsAt,
    endsAt,

    schedule = [],
    plannerSchedule,
    parcelDates,
    useParcelDates,

    timezone,

    location,
    address,
    cityState,

    vmSharingMethod,
    virtualMeeting,
    attending,
    submitted,

    isNote,
    hasQuestions,
    isPreview,
}: any) => {
    const parcelDatesText = parcelDates && renderParcelDateTimes(parcelDates, timezone);
    const { brand, primaryAction } = useThemedColor();
    const scheduleDetailsText = plannerSchedule
        ? plannerSchedule.map(renderPlannerScheduleDateTimes).flat()
        : schedule.length
        ? schedule
              .map(({ start, end }: { start: string; end: string }) => renderScheduleDateTimes(start, end, timezone))
              .flat()
        : renderScheduleDateTimes(startsAt, endsAt, timezone);

    const vmServiceName = virtualMeeting?.serviceProvider.name;
    const unknownVMService = vmServiceName === 'Other';
    const virtualMeetingDetailsText = `Virtual attendance is available${
        !unknownVMService ? ` by  ${vmServiceName} services` : ''
    }`;

    const shareVMDetails = virtualMeeting && vmSharingMethod === 'share';
    const showVMLink = !isPreview && !isNote && submitted && shareVMDetails && virtualMeeting.link;

    const noteHeadline = isPreview
        ? headline || `${firstName} ${lastName} has sent you a message:`
        : !submitted
        ? headline
        : '';
    const RSVPHeadline = isPreview ? `${firstName} ${lastName} has invited you to ${name}` : '';
    const cardHeadline = isNote ? noteHeadline : RSVPHeadline;

    const renderedDescription =
        description && RichTextEditor({ field: '', value: description, onChange: () => {}, readonly: true });

    return (
        <>
            <Column style={{ padding: 32 }} itemSpacing="largest" paddingSpacing>
                <Column itemSpacing="large">
                    {(cardHeadline || description) && (
                        <Column itemSpacing="small">
                            {cardHeadline && <H2Headline>{cardHeadline}</H2Headline>}
                            {renderedDescription && <StyledHTML>{renderedDescription}</StyledHTML>}
                        </Column>
                    )}

                    {!isNote && (
                        <Row>
                            {parcelDatesText ? (
                                <RSVPDetailSection icon={<CalendarIcon />} textArray={parcelDatesText} />
                            ) : (
                                !useParcelDates &&
                                scheduleDetailsText.length > 0 && (
                                    <RSVPDetailSection icon={<CalendarIcon />} textArray={scheduleDetailsText} />
                                )
                            )}

                            {location && (
                                <RSVPDetailSection icon={<VenueIcon />} textArray={[location, address, cityState]} />
                            )}

                            {shareVMDetails && (
                                <RSVPDetailSection icon={<VideoConferenceIcon />} text={virtualMeetingDetailsText} />
                            )}
                        </Row>
                    )}

                    {showVMLink && (
                        <Column>
                            <Copy>
                                To join, just click the link below or copy and paste it into your internet browser:
                            </Copy>
                            <Spacer small />
                            <ExternalLink href={virtualMeeting.link} openInNewTab>
                                {virtualMeeting.link}
                            </ExternalLink>
                            {virtualMeeting.notes && (
                                <PreWrapText>
                                    <Spacer small />
                                    <RichTextEditor
                                        readonly
                                        field="virtualMeetingNotes"
                                        value={virtualMeeting.notes}
                                        onChange={() => null}
                                    />
                                </PreWrapText>
                            )}
                        </Column>
                    )}
                </Column>

                {isPreview && (
                    <Row>
                        {isNote && hasQuestions && (
                            <FlatButton style={{ backgroundColor: brand, marginRight: 20 }}>
                                Click here to respond
                            </FlatButton>
                        )}
                        {!isNote && (
                            <>
                                <FlatButton
                                    style={{
                                        backgroundColor: primaryAction,
                                        marginRight: 20,
                                    }}
                                >
                                    Register to Attend
                                </FlatButton>
                                <FlatButton style={{ backgroundColor: brand }}>Not Attending</FlatButton>
                            </>
                        )}
                    </Row>
                )}
            </Column>
        </>
    );
};

const HoverUpload = styled(Row)`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    background: ${colorFns.pureBlack.alpha(0.4)};
    opacity: 0;

    cursor: pointer;

    transition: all 0.3s ease-in-out;

    &:hover {
        opacity: 1;
    }
`;

const EditIcon = styled(withInteractibleIconStyles(EditIconSVG)).attrs({ viewBox: '1.5 2.5 18 21.5' })`
    height: 21px;
    width: 21px;
    color: ${colorFns.pureWhite};
`;
const DeleteIcon = styled(withInteractibleIconStyles(DeleteIconSVG)).attrs({ viewBox: '2 4 15 21' })`
    height: 21px;
    width: 21px;
    color: ${colorFns.pureWhite};
`;

const RSVPCard = ({
    team = {},
    image,
    onImageUpload,
    name,
    headline,
    description,
    startsAt,
    endsAt,

    schedule,
    plannerSchedule,
    plannedBy,

    parcelDates = null,
    useParcelDates,

    timezone,

    location,
    address,
    cityState,

    vmSharingMethod,
    virtualMeeting,

    isNote,
    surveyTitle,
    surveyQuestions,
    hasQuestions,
    isPreview = true,
    attendee,
    formSettings,
    attending,
    submitted,
    onChange,
    handleSubmit,
    saveButtonDisabled,
}: Record<string, any> & {
    onImageUpload?: (file: { title: string; cloudinarId: string; url: string } | null) => void;
}) => {
    const { firstName, lastName } = plannedBy;
    const { color, imageUrl } = team as { color: Themed.Color; imageUrl: string };

    const [pending, setPending] = React.useState(false);

    const renderedImage = (
        <div style={{ position: 'relative' }}>
            {image ? (
                <FittedImage src={image} width={isPreview ? PREVIEW_CARD_WIDTH : CARD_WIDTH} height={IMAGE_HEIGHT} />
            ) : (
                <div
                    style={{
                        width: isPreview ? PREVIEW_CARD_WIDTH : CARD_WIDTH,
                        height: IMAGE_HEIGHT,
                        backgroundColor: color,
                    }}
                />
            )}
            <ImageBackgroundGradient />
            {onImageUpload && (
                <CloudinaryUploader
                    onUploadSuccess={(file: any) => {
                        setPending(false);
                        onImageUpload(file);
                    }}
                    onUploadStart={() => setPending(true)}
                >
                    <HoverUpload alignItems="center" justifyContent="center">
                        {pending ? (
                            <Spinner />
                        ) : (
                            <InlineRow itemSpacing="smallish">
                                <EditIcon onClick={e => e.preventDefault()} />
                                {image && (
                                    <DeleteIcon
                                        onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            onImageUpload(null);
                                        }}
                                    />
                                )}
                            </InlineRow>
                        )}
                    </HoverUpload>
                </CloudinaryUploader>
            )}
            {<InviteTitle noDot>{name}</InviteTitle>}
        </div>
    );

    return (
        <PaddedCard padding={0} style={{ position: 'relative', wordWrap: 'break-word' }}>
            <div style={{ position: 'relative' }}>{renderedImage}</div>
            <LogoWrapper>
                <CompanyLogo src={imageUrl} />
            </LogoWrapper>

            <RSVPCardContent
                color={color}
                firstName={firstName}
                lastName={lastName}
                name={name}
                headline={headline}
                description={description}
                startsAt={startsAt}
                endsAt={endsAt}
                schedule={schedule}
                plannerSchedule={plannerSchedule}
                parcelDates={parcelDates}
                useParcelDates={useParcelDates}
                timezone={timezone}
                location={location}
                address={address}
                cityState={cityState}
                vmSharingMethod={vmSharingMethod}
                virtualMeeting={virtualMeeting}
                isNote={isNote}
                hasQuestions={hasQuestions}
                isPreview={isPreview}
                attending={attending}
                attendee={attendee}
                submitted={submitted}
            />
            {!isPreview && attending && !submitted && attendee && (
                <>
                    <Line />
                    <Column style={{ padding: 32 }}>
                        <H2Headline>Your Details</H2Headline>
                        <Spacer largest />
                        <RSVPForm attendee={attendee} formSettings={formSettings} onChange={onChange} />
                        <Row>
                            <FlatButton
                                onClick={handleSubmit}
                                style={{ backgroundColor: color }}
                                disabled={saveButtonDisabled}
                            >
                                {attendee.status === ATTENDING_STATUSES.ATTENDING ? 'Submit' : 'Register'}
                            </FlatButton>
                        </Row>
                        <Spacer xsmall />
                        <Policies prefix="By submitting" />
                    </Column>
                </>
            )}
            {!isPreview && !!surveyQuestions?.length && !submitted && (
                <>
                    <Line />
                    <Column style={{ padding: 32 }}>
                        <Column itemSpacing="largest">
                            {surveyTitle && <H2Headline>{surveyTitle}</H2Headline>}
                            <CustomQuestionsForm
                                onChange={onChange}
                                questions={surveyQuestions}
                                value={surveyQuestions}
                                field=""
                            />
                        </Column>
                        <Row>
                            <FlatButton
                                onClick={handleSubmit}
                                style={{ backgroundColor: color }}
                                disabled={saveButtonDisabled}
                            >
                                Submit
                            </FlatButton>
                        </Row>
                    </Column>
                </>
            )}
        </PaddedCard>
    );
};

export default RSVPCard;
