import { deleteCall, get, post } from 'api';
import { parse, stringify } from 'query-string';
import * as URI from 'uri-js';
import uuid from 'uuid-random';
import camelizeFromPascal from 'camelcase-keys';
import camelizeFromSnake from 'camelize';
import { webSocket } from 'rxjs/webSocket';

const removeSecurityToken = (wssLink: string) => {
    const parsedLink = URI.parse(wssLink);
    const { 'X-Amz-Security-Token': secToken, ...updatedQS } = parse(parsedLink.query || '');

    const updatedLink = URI.serialize({
        ...parsedLink,
        query: stringify(updatedQS),
    });

    return updatedLink;
};

export const getChimeInfo = (id?: string | number): Promise<{ chimeInfo: BizlyAPI.ChimeInfo }> =>
    get(`meetings/${id}/chime`);

export const getWebsocket = async (sessionId = uuid()) => {
    const { wssUrl } = await (get(`chime/wss-url?${stringify({ session_id: sessionId })}`) as Promise<{
        wssUrl: string;
    }>).then(({ wssUrl }) => ({
        wssUrl: removeSecurityToken(wssUrl),
    }));

    return webSocket<BizlyChime.WebsocketRaw>(wssUrl);
};

const processMetadata = (metadata: string) => {
    let parsedPayload = {};
    try {
        parsedPayload = JSON.parse(metadata);
    } catch (e) {}

    return camelizeFromSnake(parsedPayload) as BizlyChime.ProcessedMetadata;
};

export const getMessages = (channelArn: string, nextToken?: string) =>
    (get(`chime/messages?${stringify({ channel_arn: channelArn, next_token: nextToken })}`) as Promise<{
        messages: BizlyChime.RawChatMessage[];
        nextToken: string | null;
    }>).then(resp => {
        const camelized = (camelizeFromPascal(resp, { deep: true }) as unknown) as {
            messages: BizlyChime.ChatMessage & { metadata?: string }[];
            nextToken: string | null;
        };
        return {
            ...camelized,
            messages: camelized.messages.map(message => ({
                ...message,
                ...(message.metadata ? { metadata: processMetadata(message.metadata) } : {}),
            })),
        };
    }) as Promise<{
        messages: BizlyChime.ChatMessage[];
        nextToken: string | null;
    }>;

export const sendMessage = (channelArn: string, content: string, metadata?: string): Promise<{ messageId: string }> =>
    post(`chime/messages`, {
        channelArn,
        content,
        metadata,
        persistence: 'PERSISTENT',
        type: 'STANDARD',
    });

export const addMembership = (meetingId?: string | number) =>
    (get(`meetings/${meetingId}`, {
        showErrorCode: true,
    }) as Promise<{ meeting: BizlyAPI.Meeting }>).then(({ meeting }) => meeting.chimeChat);

export const clearMemberships = (): Promise<{}> => deleteCall(`chime/channel-memberships`);
