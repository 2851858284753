import React from 'react';
import styled from 'styled-components';

import { Column, Copy, Row } from 'ui';
import { H2Headline } from './ui/Headline';
import TextButton from './ui/Button/TextButton';
import { Dialog } from '@material-ui/core';
import colorFns from 'colorFns';

import { TeamSettings } from 'pages/TeamSettings';

const PaddedDialog = styled(Dialog)`
    .MuiPaper-root > *:not(:last-child) {
        border-bottom: 1px solid ${colorFns.softBorder};
    }
`;

const ModalContent = styled.div`
    overflow: auto;
    padding: 20px 20px 20px 36px;
    box-sizing: border-box;
`;

const Header = styled(Row)`
    padding: 20px;
    box-sizing: border-box;
`;

const Footer = styled(Row)`
    padding: 20px;
    box-sizing: border-box;
`;

export default function TeamSettingsPrompt({
    title,
    prompt,
    onCancel,
    onIgnore,
}: {
    title?: string;
    prompt?: string;
    onCancel: () => void;
    onIgnore: () => void;
}) {
    return (
        <PaddedDialog open maxWidth="md" onBackdropClick={() => onCancel()}>
            <Header>
                <div>
                    <Column itemSpacing="small">
                        <H2Headline>{title || 'Update Your Team Settings'}</H2Headline>
                        {prompt && <Copy>{prompt}</Copy>}
                    </Column>
                </div>
            </Header>
            <ModalContent>
                <TeamSettings />
            </ModalContent>
            <Footer alignItems="center" justifyContent="flex-end" itemSpacing="smallish">
                <TextButton onClick={() => onCancel()} secondary>
                    Nevermind
                </TextButton>
                <TextButton onClick={() => onIgnore()} warning>
                    These settings are OK
                </TextButton>
            </Footer>
        </PaddedDialog>
    );
}
