import React, { useCallback, useMemo } from 'react';

import styled from 'styled-components/macro';

import { Grid } from '../../ui';

import { Waypoint } from 'react-waypoint';

import VenueTile, { VenueTileEmpty } from '../VenueTile';

const JustifiedStartGrid = styled(Grid)`
    justify-content: flex-start;
    margin: -12px;
`;

export const NoSearchGrid = () => (
    <JustifiedStartGrid>
        {new Array(12).fill('').map((p, idx) => (
            <VenueTileEmpty width="232px" fixedRatio="calc(160 / 208 * 100%)" padding="12px" key={idx} />
        ))}
    </JustifiedStartGrid>
);

type TInfiniteGrid = {
    data: Bizly.Venue[];
    selectedVenues: { [venueId: number]: Bizly.Venue };

    onSelect?: (venue: Bizly.Venue) => void;
    onDeselect?: (venue: Bizly.Venue) => void;
    onVenueHover?: (venueId: number | null) => void;
    onVisit?: (venueId: number) => void;

    hasMore?: boolean;
    loadMore: () => void;
    paddingCount?: number;
};

export const InfiniteGrid = ({
    data,
    selectedVenues,
    onSelect,
    onDeselect,
    onVenueHover,
    onVisit,
    hasMore,
    loadMore,
    paddingCount = 10,
}: TInfiniteGrid) => {
    const components = useMemo(
        () => [...data, ...(hasMore ? [null] : []), ...(hasMore ? new Array(paddingCount).fill('') : [])],
        [data, hasMore, paddingCount]
    );

    const mouseLeave = useCallback(() => onVenueHover && onVenueHover(null), [onVenueHover]);

    return (
        <JustifiedStartGrid>
            {components.map((v, idx) => {
                if (v === null) {
                    // key is data.length because we should remount so it can retrigger
                    return <Waypoint key={data.length} onEnter={loadMore} />;
                }

                if (v === '') {
                    return (
                        <VenueTileEmpty width="232px" fixedRatio="calc(160 / 208 * 100%)" padding="12px" key={idx} />
                    );
                }

                return (
                    <VenueTile
                        key={v.id}
                        venue={v}
                        format="tile"
                        pillType="preferenceCategory"
                        width="232px"
                        fixedRatio="calc(160 / 208 * 100%)"
                        padding="12px"
                        onMouseLeave={mouseLeave}
                        onMouseOver={onVenueHover}
                        selected={!!selectedVenues[v.id]}
                        onSelect={onSelect}
                        onDeselect={onDeselect}
                        onClick={onVisit}
                    />
                );
            })}
        </JustifiedStartGrid>
    );
};
