import React from 'react';

import BottomBanner from 'components/ui/BottomBanner';

type THomeScreenBanner = { message: string; teamImage: string };

export default function HomeScreenBanner({ message, teamImage }: THomeScreenBanner) {
    return (
        <BottomBanner>
            <div
                dangerouslySetInnerHTML={{
                    __html: message,
                }}
                style={{ marginLeft: 40 }}
            />
        </BottomBanner>
    );
}
