import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Column as UIColumn, Row, SpacedRow, Copy, Spacer } from 'ui';
import { Headline as UIHeadline } from 'components/ui/Headline';
import Button from 'components/ui/Button';
import { ReactComponent as TrashIcon } from 'images/icons/trash-can.svg';
import { withInteractibleIconStyles } from 'shared';

import Form from 'components/Form';

import { TPlannerDataChange, TPlannerForm } from 'components/Planner/types';
import { defaultVMFields, VMSchemaLink, VMSchemaNotes } from 'components/Planner/plannerFormSchema';
import LongButton from 'components/ui/Button/LongButton';

const Column = styled(UIColumn)`
    width: 100%;
`;

const Content = styled.div`
    border-radius: 8px;
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    overflow: hidden;
`;

const Head = styled(SpacedRow)`
    border-bottom: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
    border-radius: 8px 8px 0px 0px;
    padding: 16px 0 16px 0;

    div {
        margin: 0 16px;
    }
`;

const Body = styled(UIColumn)`
    border-radius: 0px 0px 8px 8px;
    padding: 24px 16px;
`;

const Headline = styled(UIHeadline)`
    font-size: 24px;
    font-weight: 500;
`;

const RecServiceCol = styled(UIColumn)`
    max-width: 50%;
`;

const RecServiceRow = styled(Row)`
    > ${RecServiceCol} {
        flex-grow: 1;
    }
`;

const RemoveIconButton = styled(withInteractibleIconStyles(TrashIcon))`
    margin-right: 8px;
`;

const AddVMCTA = styled(Copy)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack)};
    font-size: 16px;
    font-weight: 500;
`;

const SaveButton = styled(Button)`
    align-self: flex-end;
    margin-top: 24px;
    font-weight: 400;
`;

const PreferredService = ({ name, notes }: Partial<Bizly.VirtualMeetingServiceProvider>) => (
    <RecServiceCol>
        <Copy>
            <strong>{name}</strong>
        </Copy>
        <Spacer small />
        <Copy>{notes}</Copy>
    </RecServiceCol>
);

type TVirtualMeetingUpdate = TPlannerDataChange & { value: TPlannerForm['virtualMeeting'] };

type TVirtualMeetingDetailsProps = {
    editable: boolean;
    virtualSettings?: Partial<{
        recommendationCopy: string;
    }>;
    serviceProviders: Partial<Bizly.VirtualMeetingServiceProvider>[];
    virtualMeeting?: TPlannerForm['virtualMeeting'] | null;
    setVirtualMeetingsData: (vmFormData: TVirtualMeetingUpdate) => void;
    hasSaved: boolean;
    addVirtualMeeting: () => void;
    onSave: () => void;
    onRemove: () => void;
};

const VirtualMeetingDetails = ({
    editable,
    virtualSettings = {},
    serviceProviders,
    virtualMeeting,
    setVirtualMeetingsData,
    hasSaved,
    addVirtualMeeting,
    onSave,
    onRemove,
}: TVirtualMeetingDetailsProps) => {
    const { recommendationCopy } = virtualSettings;
    const preferredServices = useMemo(() => serviceProviders.filter(sp => sp.preferred).slice(0, 3), [
        serviceProviders,
    ]);
    const VMFields = useMemo(
        () =>
            defaultVMFields(
                serviceProviders.map(({ name, ...provider }) => ({
                    ...provider,
                    name: provider.preferred ? name + ' *' : name,
                }))
            ),
        [serviceProviders]
    );

    return (
        <Column>
            {virtualMeeting?.deleted ? (
                editable && (
                    <LongButton onClick={addVirtualMeeting} isSecondary>
                        <AddVMCTA>Add a Virtual Meeting</AddVMCTA>
                    </LongButton>
                )
            ) : (
                <Content>
                    <Head>
                        <Copy>Virtual Meeting Information</Copy>
                        {editable && virtualMeeting && <RemoveIconButton onClick={onRemove} />}
                    </Head>
                    <Body itemSpacing="default">
                        {recommendationCopy && (
                            <>
                                <Headline>Recommendation</Headline>
                                <Copy>{recommendationCopy}</Copy>
                            </>
                        )}

                        {!!preferredServices?.length && (
                            <RecServiceRow itemSpacing="small">
                                {preferredServices.map(({ name, notes }) => (
                                    <PreferredService name={name} notes={notes} />
                                ))}
                            </RecServiceRow>
                        )}

                        <Column>
                            <Form
                                fields={VMFields}
                                schema={VMSchemaLink}
                                value={virtualMeeting || {}}
                                onChange={setVirtualMeetingsData}
                                disabled={!editable}
                            />
                            <Spacer larger />
                            <Form
                                fields={VMFields}
                                schema={VMSchemaNotes}
                                value={virtualMeeting || {}}
                                onChange={setVirtualMeetingsData}
                                disabled={!editable}
                            />
                        </Column>
                    </Body>
                </Content>
            )}
            {editable && (
                <SaveButton onClick={onSave} disabled={!editable || hasSaved} small width={68}>
                    Save
                </SaveButton>
            )}
        </Column>
    );
};

export default VirtualMeetingDetails;
