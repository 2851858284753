import React from 'react';
import styled from 'styled-components';
import * as URI from 'uri-js';

import usePersistentSnackbar from 'hooks/usePersistentSnackbar';

import { ReactComponent as _VideoConferenceIcon } from 'images/icons/Video_Conference.svg';
import { ReactComponent as _PencilIcon } from 'images/icons/pencil.svg';
import { ReactComponent as _TrashIcon } from 'images/icons/trash-can.svg';
import { withIconStyles, withInteractibleIconStyles } from 'shared';
import { Column, Row, Copy, ExternalLink as UIExternalLink } from 'ui';
import UILongButton, { LongButtonMenu as UILongButtonMenu } from 'components/ui/Button/LongButton';
import { RichTextEditor } from 'components/Form/fields';
import { Dialog, MenuItem } from '@material-ui/core';
import { SpinnerOverlay } from 'components/Spinner';
import { ManageVirtualMeetingComponent } from './ManageVirtualMeeting';

export const serviceNameMap: { [key in BizlyAPI.Connector]?: string } = {
    zoom: 'Zoom',
    google: 'Google Meet',
    'ms-graph': 'Microsoft',
};

const ListDetailsColumn = styled(Column)`
    width: 100%;

    span > *:not(:first-child) {
        margin-left: 8px;
    }

    ${Copy} > * {
        margin: 0;
    }
`;
const ExternalLink = styled(UIExternalLink)`
    word-break: break-word;
`;

const ControlContainer = styled.span`
    flex: 0 0 auto;
`;

const VideoConferenceIcon = withIconStyles(_VideoConferenceIcon);
const PencilIcon = withInteractibleIconStyles(_PencilIcon);
const TrashIcon = withInteractibleIconStyles(_TrashIcon);

const VMEntry = ({
    vmUrl,
    vmDeferredService,
    vmNotes,
    onEdit,
    onDelete,
    disabled,
}: {
    vmUrl?: string;
    vmDeferredService?: BizlyAPI.Connector;
    vmNotes: string;
    onEdit?: () => void;
    onDelete: () => void;
    disabled?: boolean;
}) => (
    <Row itemSpacing="small">
        <Column>
            <VideoConferenceIcon />
        </Column>
        <ListDetailsColumn>
            <Row justifyContent="space-between">
                {vmDeferredService ? (
                    serviceNameMap[vmDeferredService]
                ) : (
                    <ExternalLink href={vmUrl} openInNewTab>
                        {vmUrl}
                    </ExternalLink>
                )}
                {!disabled && (
                    <ControlContainer>
                        {onEdit && <PencilIcon onClick={onEdit} />}
                        <TrashIcon onClick={onDelete} />
                    </ControlContainer>
                )}
            </Row>
            <RichTextEditor readonly field="virtualMeetingNotes" value={vmNotes} onChange={() => null} />
        </ListDetailsColumn>
    </Row>
);

const Container = styled(Column)`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.1)};
    padding: 12px;
    border-radius: 8px;
`;

const longButtonStyles = `
    font-size: 16px;
    font-weight: normal;
    background:none !important;
`;
const LongButton = styled(UILongButton)`
    ${longButtonStyles}
    background:none !important;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;
const LongButtonMenu = styled(UILongButtonMenu)`
    ${longButtonStyles}
    background:none !important;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

const AddVMButton = ({
    onSelect,
    cta,
    services,
}: {
    services?: BizlyAPI.Connector[];
    cta?: string;
    onSelect: (service?: BizlyAPI.Connector) => Promise<void>;
}) => {
    const [isLoading, setIsLoading] = React.useState(false);

    const handleOnClick = async (service: BizlyAPI.Connector) => {
        setIsLoading(true);

        try {
            await onSelect(service);
        } finally {
            setIsLoading(false);
        }
    };

    const hasServices = (services || []).filter(service => serviceNameMap[service]).length > 0;

    return hasServices ? (
        <LongButtonMenu
            label={
                <>
                    {cta}
                    {isLoading && <SpinnerOverlay />}
                </>
            }
        >
            {services?.map(
                service =>
                    serviceNameMap[service] && (
                        <MenuItem key={service} onClick={() => handleOnClick(service)}>
                            {serviceNameMap[service]}
                        </MenuItem>
                    )
            )}
            <MenuItem onClick={() => onSelect()}>Other</MenuItem>
        </LongButtonMenu>
    ) : (
        <LongButton onClick={() => onSelect()}>{cta}</LongButton>
    );
};

export type TVMValue = { id?: number; notes?: string } & (
    | { link?: string; deferredService?: undefined }
    | { link?: undefined; deferredService?: BizlyAPI.Connector }
);

export default function VirtualMeetingField({
    value,
    integratedVmServices,

    onAdd,
    onEdit,
    onDelete,
    cta,
    disabled,
}: {
    value: TVMValue | null;
    integratedVmServices: BizlyAPI.Connector[];
    onAdd: (service?: BizlyAPI.Connector) => Promise<void>;
    onEdit?: () => void;
    onDelete: () => void;
    cta?: string;
    disabled?: boolean;
}) {
    return !disabled || value?.id ? (
        value?.link || value?.deferredService ? (
            <Container>
                <VMEntry
                    vmUrl={value.link ?? ''}
                    vmDeferredService={value.deferredService}
                    vmNotes={value.notes ?? ''}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    disabled={disabled}
                />
            </Container>
        ) : (
            <AddVMButton onSelect={onAdd} cta={cta} services={integratedVmServices} />
        )
    ) : null;
}

const BigDialog = styled(Dialog)`
    .MuiPaper-root {
        width: 600px;
    }
`;

export const VirtualMeetingFieldDeferred = ({
    onChange,
    field,
    value,
    disabled,
    openModal,
    ...rest
}: {
    value: TVMValue | null;
    integratedVmServices: BizlyAPI.Connector[];
    cta?: string;
    disabled?: boolean;
    field?: string;
    onChange?: (change: { field?: string; value?: TVMValue }) => void;
    openModal?: boolean;
}) => {
    const [stagedVal, setStagedVal] = React.useState<TVMValue | undefined>(openModal ? {} : undefined);
    const enqueueSnackbar = usePersistentSnackbar([stagedVal?.link]);

    const getErrorVirtualMeeting = () => {
        if (!stagedVal?.link) {
            return 'A link is required for a virtual meeting';
        }

        const parsedLink = URI.parse(stagedVal?.link);
        if (!parsedLink.scheme) {
            return 'Make sure the link includes the protocol (http or https)';
        }

        return false;
    };

    const onApply = () => {
        const error = getErrorVirtualMeeting();
        if (error) {
            return enqueueSnackbar(error, { variant: 'error' });
        }
        onChange?.({ field, value: stagedVal });
        setStagedVal(undefined);
    };

    const onAdd = async (service?: BizlyAPI.Connector) =>
        service
            ? onChange?.({
                  field,
                  value: {
                      deferredService: service,
                  },
              })
            : setStagedVal({});
    const onEdit = value?.deferredService ? undefined : () => setStagedVal(value ?? {});
    const onDelete = () => onChange?.({ field, value: undefined });

    return (
        <>
            <VirtualMeetingField {...{ onAdd, onEdit, onDelete, field, value, disabled }} {...rest} />
            <BigDialog open={!!stagedVal} onBackdropClick={() => setStagedVal(undefined)}>
                <ManageVirtualMeetingComponent
                    disabled={disabled}
                    value={stagedVal ?? {}}
                    onChange={setStagedVal}
                    onClose={() => setStagedVal(undefined)}
                    onApply={onApply}
                    saveCta="Apply"
                />
            </BigDialog>
        </>
    );
};
