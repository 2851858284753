import React from 'react';
import styled, { css } from 'styled-components/macro';
import { H2Headline } from '../ui/Headline';
import { Copy, Row, Spacer } from '../../ui';

import fontFns from 'fontFns';

const SpacedCopy = styled(Copy)`
    line-height: 1.6;
`;

const SpacedRow = styled(Row)`
    align-items: flex-start;
`;

const Label = styled(Copy)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formLabel)};
    ${fontFns.formLabel}
    margin-right: 12px;
    white-space: nowrap;
`;

const TextRowText = styled(Copy)`
    text-align: right;
`;

const TextRow = ({ label, value }: { label: string; value?: React.ReactText }) => (
    <SpacedRow>
        <Label>{label}</Label>
        <TextRowText>{value}</TextRowText>
    </SpacedRow>
);

const GreyBox = styled.div`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.softAccentedBackground)};
    padding: 20px;
    border-radius: 8px;
`;

const ErrorBox = () => (
    <div
        css={css`
            text-align: center;
            margin-top: 25%;
        `}
    >
        <h1>Something went wrong!</h1>
        <p>Please refresh and try again.</p>
    </div>
);

type TCredentials = {
    start: string;
    end: string;
    expDate: string;
    cardNumber: string;
    securityCode: string;
    zipCode: string;
};

const ViewPaymentCard = ({ credentials, error }: { credentials?: TCredentials; error: boolean }) =>
    !error ? (
        <>
            <H2Headline>Success! Here's your account information.</H2Headline>
            <Spacer />

            <SpacedCopy>
                Keep this information secure just as you would your own credit card number. If you need to access it
                again, check back here and we can pull it up again for you. If you have any issues or questions, please
                reach out to your administrator.
            </SpacedCopy>
            <Spacer />

            <SpacedCopy>Your account will be active from:</SpacedCopy>
            <Spacer small />

            <TextRow label="Start" value={credentials?.start} />
            <Spacer xsmall />
            <TextRow label="End" value={credentials?.end} />
            <Spacer />

            <GreyBox>
                <TextRow label="Number" value={credentials?.cardNumber} />
                <Spacer xsmall />
                <TextRow label="Exp date" value={credentials?.expDate} />
                <Spacer xsmall />
                <TextRow label="CVC" value={credentials?.securityCode} />
                <Spacer xsmall />
                <TextRow label="Billing zip" value={credentials?.zipCode} />
            </GreyBox>
        </>
    ) : (
        <ErrorBox />
    );

export default ViewPaymentCard;
