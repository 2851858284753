import React from 'react';
import styled from 'styled-components';

import ContentCard from 'components/ui/ContentCard';

import { InlineRow, Spacer } from 'ui';
import { H5Headline } from 'components/ui/Headline';
import SideNav from 'components/SideNav';
import MembersTable from './MembersTable';
import TeamSettings from './TeamSettings';
import { Tab, TabsSecondary } from 'components/Tabs';

const LimitedMembersTable = styled(MembersTable)`
    max-width: 800px;
`;

export default function TeamSettingsPage() {
    const [tab, setTab] = React.useState('profile');

    return (
        <SideNav>
            <H5Headline>Team Settings</H5Headline>
            <Spacer />
            <InlineRow>
                <TabsSecondary value={tab}>
                    <Tab value="profile" label="Profile" onClick={() => setTab('profile')} />
                    <Tab value="members" label="Members" onClick={() => setTab('members')} />
                </TabsSecondary>
            </InlineRow>

            {tab === 'profile' && (
                <>
                    <Spacer larger />
                    <ContentCard>
                        <TeamSettings />
                    </ContentCard>
                </>
            )}
            {tab === 'members' && <LimitedMembersTable />}
        </SideNav>
    );
}
