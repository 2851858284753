import React from 'react';

import { css } from 'styled-components/macro';

import { useHistory } from 'react-router';

import Dialog from '@material-ui/core/Dialog';

import { useSnackbar } from 'notistack';

import { acceptProposal } from '../api';

import { AlignedRow, Column, Copy } from '../ui';
import TextButton from './ui/Button/TextButton';

import useKey from '../hooks/useKey';

const ProposalAcceptModal = ({
    eventId,
    proposal,
    open,
    closeModal,
    setVenueBooked,
}: {
    eventId: number | string;
    proposal?: Bizly.VenueProposal;
    open: boolean;
    closeModal: () => void;
    setVenueBooked: (booked: boolean) => void;
}) => {
    useKey('Escape', closeModal);
    const { enqueueSnackbar } = useSnackbar();

    const history = useHistory();

    return (
        <Dialog open={open} maxWidth="md">
            <Column
                css={css`
                    padding: 20px;
                    width: 400px;
                `}
            >
                <h2
                    css={css`
                        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.accentedHeadline)};

                        font-size: 25px;
                        line-height: 1.28;
                        letter-spacing: -0.5px;

                        font-weight: normal;
                        font-style: normal;
                        font-stretch: normal;
                    `}
                >
                    Are you sure?
                </h2>
                <Copy>
                    Accepting this proposal will notify the venue and cancel all remaining active inquiries and
                    proposals.
                </Copy>
                <AlignedRow
                    css={css`
                        justify-content: flex-end;
                        margin-top: 20px;
                    `}
                    itemSpacing="smallish"
                >
                    <TextButton secondary onClick={closeModal}>
                        Cancel
                    </TextButton>
                    <TextButton
                        onClick={async () => {
                            if (proposal) {
                                if (new Date() > new Date(proposal.onHoldUntil)) {
                                    enqueueSnackbar('This proposal has expired. Please contact the venue', {
                                        variant: 'error',
                                    });
                                    return;
                                }
                                try {
                                    await acceptProposal(proposal.id);
                                    setVenueBooked(true);
                                    history.push(`/event/${eventId}/venue/proposal/${proposal.id}`);
                                    closeModal();
                                } catch (error) {
                                    enqueueSnackbar(error, { variant: 'error' });
                                }
                            }
                        }}
                    >
                        Confirm
                    </TextButton>
                </AlignedRow>
            </Column>
        </Dialog>
    );
};

export default ProposalAcceptModal;
