import React from 'react';

import styled from 'styled-components';

import MuiStepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import MuiStepLabel from '@material-ui/core/StepLabel';

import { FORM_SECTIONS } from './types';

const Stepper = styled(MuiStepper)`
    padding: 12px 0 12px 12px;
    background-color: transparent;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};

    .MuiStepConnector-line {
        border-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.5)};

        &::after {
            content: '';
            position: absolute;
            display: block;

            background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
            border-radius: 2px;

            transition: 0.15s 0.15s all ease-out;
            transition-property: height, width;
        }
    }

    .MuiStepConnector-active,
    .MuiStepConnector-completed {
        .MuiStepConnector-line::after {
            transition: 0.15s all ease-out;
            transition-property: height, width;
        }
    }

    .MuiStepConnector-horizontal,
    .MuiStepConnector-vertical {
        padding: 0;
    }

    /* Horizontal */

    .MuiStepConnector-horizontal {
        .MuiStepConnector-line {
            min-width: 60px;
            transform: translateY(-1px);
            border-top-width: 2px;

            &::after {
                height: 4px;
                width: 0px;

                transform: translateY(-3px) translateX(-2px);
            }
        }

        &.MuiStepConnector-active,
        &.MuiStepConnector-completed {
            .MuiStepConnector-line::after {
                width: 100%;
                padding: 0 2px;
            }
        }
    }

    /* Vertical */

    .MuiStepConnector-vertical {
        .MuiStepConnector-line {
            min-height: 60px;
            transform: translateX(-1px);
            border-left-width: 2px;

            &::after {
                height: 0px;
                width: 4px;

                transform: translateX(-3px) translateY(-2px);
            }
        }

        &.MuiStepConnector-active,
        &.MuiStepConnector-completed {
            .MuiStepConnector-line::after {
                height: 64px;
            }
        }
    }

    /* Alternative Label */

    &.MuiStepper-alternativeLabel {
        min-width: 700px;
        padding: 0;
        padding-top: 6px;

        .MuiStepLabel-alternativeLabel {
            padding-right: 0;
        }

        .MuiStepConnector-alternativeLabel {
            left: calc(-50% + 12px);
            right: calc(50% + 12px);
        }
    }
`;

const StepLabel = styled(MuiStepLabel)`
    .MuiStepIcon-root {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.5)};
        transition: 0.15s 0s all ease-out;
    }

    .MuiStepIcon-active,
    .MuiStepIcon-completed {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
        transition: 0.15s 0.15s all ease-out;
    }

    .MuiStepIcon-completed {
        background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
        border-radius: 12px;
    }

    .MuiStepLabel-iconContainer {
        padding-right: 24px;
    }

    .MuiStepLabel-label {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)} !important;
        opacity: 0.5;
        transition: 0.15s 0s all ease-out;
    }

    .MuiStepLabel-active,
    .MuiStepLabel-completed {
        opacity: 1;
        transition: 0.15s 0.15s all ease-out;
    }
`;

const SECTION_TO_LABEL = {
    [FORM_SECTIONS.CONTACT]: 'Contact Information',
    [FORM_SECTIONS.GUEST_ROOMS]: 'Guest Rooms',
    [FORM_SECTIONS.EVENT_SPACES]: 'Meeting Spaces',
    [FORM_SECTIONS.NOTES]: 'Finishing Touches',
    [FORM_SECTIONS.PROPOSAL_STATUS]: 'Proposal Status',
};

const StepIconProps = { icon: '' };

type TProgressTracker = {
    sections: FORM_SECTIONS[];
    currentSection: number;
    horizontal?: boolean;
};

export default function ProgressTracker({ currentSection, sections, horizontal }: TProgressTracker) {
    return (
        <Stepper
            activeStep={currentSection}
            orientation={horizontal ? 'horizontal' : 'vertical'}
            alternativeLabel={horizontal}
        >
            {sections.map((sectionName: FORM_SECTIONS) => (
                <Step key={sectionName}>
                    <StepLabel StepIconProps={StepIconProps}>{SECTION_TO_LABEL[sectionName]}</StepLabel>
                </Step>
            ))}
        </Stepper>
    );
}
