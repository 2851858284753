import React, { useState, CSSProperties } from 'react';
import styled from 'styled-components';

import { useUser } from 'providers/user';

import { Column, SpacedColumn, Spacer, AlignedRow } from 'ui';
import { Link, Switch, Route } from 'react-router-dom';
import ThemedLogo from 'components/ui/ThemedLogo';
import Avatar from 'components/ui/Avatar';
import UserMenu from 'components/UserMenu';
import TeamSelector from 'components/TeamSelector';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

import { ReactComponent as HomeIconSvg } from 'images/icons/home.svg';
import { ReactComponent as CalendarIconSvg } from 'images/icons/calendar2.svg';
import { ReactComponent as PlaybookIconSvg } from 'images/icons/playbook.svg';
import colorFns from 'colorFns';

export const SIDE_NAV_WIDTH = 124;

const ContentsWrapper = styled.div<{ fillWidth?: boolean; fullWidth?: boolean; fullHeight?: boolean }>`
    position: relative;
    margin-left: ${SIDE_NAV_WIDTH}px;

    max-width: 1694px; /* Exactly 8 event tiles */
    padding: 35px 70px 0px;
    padding-bottom: 48px;
    padding-bottom: 128px; /* Space for homescreenbanner */

    box-sizing: border-box;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.brand)};
    ${({ fillWidth }) =>
        fillWidth
            ? `
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    width: calc(100% - ${SIDE_NAV_WIDTH}px);
    min-height: calc(100vh);
    padding-bottom: 24px;
    flex: 1 0 auto;
    `
            : ''}

    ${({ fullWidth }) =>
        fullWidth
            ? `
    padding-left: 0;
    padding-right: 0;`
            : ''}

    ${({ fullHeight }) =>
        fullHeight
            ? `
        padding-top: 0;
        padding-bottom: 0;
    `
            : ''}
`;

const Side = styled(SpacedColumn)`
    position: fixed;
    width: ${SIDE_NAV_WIDTH}px;
    height: 100%;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    padding: 36px;
    padding-top: 40px;
    box-sizing: border-box;
    border-right: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.15)};
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.brand)};
    z-index: 200;
    overflow: hidden;
`;

const SideBarBottom = styled(AlignedRow)`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    display: block;
`;

const HomeIcon = styled(HomeIconSvg)<{ active?: boolean }>`
    height: 16px;
    width: 15px;
    fill: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.74)};

    ${props =>
        props.active &&
        `
         fill: ${props.theme.getColor(props.theme.EColors.pureWhite, 0.74)};
    `}
`;

const CalendarIcon = styled(CalendarIconSvg)<{ active?: boolean }>`
    height: 16px;
    width: 15px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.74)};

    ${props =>
        props.active &&
        `
        color: ${props.theme.getColor(props.theme.EColors.pureWhite, 0.74)};
    `}
`;

const PlaybookIcon = styled(PlaybookIconSvg)<{ active?: boolean }>`
    height: 16px;
    width: 15px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.74)};

    ${props =>
        props.active &&
        `
        color: ${props.theme.getColor(props.theme.EColors.pureWhite, 0.74)};
    `}
`;

const SidebarLink = styled.span<{ active?: boolean; icon?: string }>`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    cursor: pointer;
    width: auto;
    padding: 11px 0;
    display: flex;
    align-items: center;

    ${props =>
        props.active &&
        `
        border:1px solid ${props.theme.getColor(props.theme.EColors.pureWhite, 0.1)};
        color:${colorFns.pureWhite(props)};
        font-weight:600;
        border-radius:8px;
        box-shadow: 0px 2px 4px 0px ${props.theme.getColor(props.theme.EColors.brand)};
    `}

    > svg {
        margin: 0 auto;
    }
`;

const HOME_PATH = '/',
    EVENTS_PATH = '/meetings',
    PLAYBOOKS_PATH = '/playbooks';

type TPaths = typeof HOME_PATH | typeof EVENTS_PATH | typeof PLAYBOOKS_PATH;

export default function SideNav({
    fillWidth,
    fullWidth,
    fullHeight,
    hideUser,
    routeChildren,
    additionalRoutes,
    children,
    className,
    style,
}: {
    fillWidth?: boolean;
    fullWidth?: boolean;
    fullHeight?: boolean;
    hideUser?: boolean;
    routeChildren?: React.ReactNode;
    additionalRoutes?: { icon: React.ReactNode; name: string; path: string }[];
    children?: React.ReactNode;
    className?: string;
    style?: CSSProperties;
}) {
    const [avatarAnchor, setAvatarAnchor] = useState<React.ReactNode | null>(null);
    const { user } = useUser();

    const RouteButtons = ({ path }: { path?: TPaths }) => (
        <>
            <Spacer small />
            <Tooltip title="Home" enterDelay={1000} TransitionComponent={Zoom} placement="right">
                <Link to={HOME_PATH}>
                    <SidebarLink active={path === HOME_PATH}>
                        <HomeIcon active={path === HOME_PATH} />
                    </SidebarLink>
                </Link>
            </Tooltip>

            <Tooltip title="My Meetings" enterDelay={1000} TransitionComponent={Zoom} placement="right">
                <Link to={EVENTS_PATH}>
                    <SidebarLink active={path === EVENTS_PATH}>
                        <CalendarIcon active={path === EVENTS_PATH} />
                    </SidebarLink>
                </Link>
            </Tooltip>
            <Tooltip title="Playbooks" enterDelay={1000} TransitionComponent={Zoom} placement="right">
                <Link to={PLAYBOOKS_PATH}>
                    <SidebarLink active={path === PLAYBOOKS_PATH}>
                        <PlaybookIcon active={path === PLAYBOOKS_PATH} />
                    </SidebarLink>
                </Link>
            </Tooltip>

            {additionalRoutes?.map(({ icon, name, path: pathProp }) => (
                <Tooltip key={name} title={name} enterDelay={1000} TransitionComponent={Zoom} placement="right">
                    <Link to={pathProp}>
                        <SidebarLink active={path === pathProp}>{icon}</SidebarLink>
                    </Link>
                </Tooltip>
            ))}
        </>
    );

    const DefaultRouteButtons = (
        <Switch>
            <Route path={`/`} exact>
                <RouteButtons path={HOME_PATH} />
            </Route>
            <Route path={[`/meetings`, `/meetings/unscheduled`, `/meetings/drafts`, `/meetings/calendar`]} exact>
                <RouteButtons path={EVENTS_PATH} />
            </Route>
            <Route path={`/playbooks`} exact>
                <RouteButtons path={PLAYBOOKS_PATH} />
            </Route>
            <Route>
                <RouteButtons />
            </Route>
        </Switch>
    );

    return (
        <>
            <Side>
                <Column itemSpacing="medium">
                    <ThemedLogo />
                    <Column itemSpacing="medium">{routeChildren ?? DefaultRouteButtons}</Column>
                </Column>
                {!hideUser && (
                    <SideBarBottom itemSpacing="small">
                        <UserMenu
                            style={{ textAlign: 'center', marginBottom: '20px' }}
                            label={
                                <Avatar user={user} onClick={e => setAvatarAnchor(avatarAnchor ? null : e.target)} />
                            }
                        />
                        <TeamSelector />
                    </SideBarBottom>
                )}
            </Side>
            {children && (
                <ContentsWrapper
                    fillWidth={fillWidth}
                    fullWidth={fullWidth}
                    fullHeight={fullHeight}
                    className={className}
                    style={style}
                >
                    {
                        children // TODO: Only necessary while supporting OldHome page
                    }
                </ContentsWrapper>
            )}
        </>
    );
}
