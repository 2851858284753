import createStore from '../';
import { acceptTeamInvite, getUserTeams, rejectTeamInvite, setUserTeam } from 'api/settings';

type State = {
    loading: boolean;
    changing: boolean;
    accepting: boolean;
    rejecting: boolean;
} & (
    | {
          loaded: false;
          memberships: null;
      }
    | {
          loaded: true;
          memberships: BizlyAPI.TeamMembership[];
      }
);
type Store = State;

const initialState: State = {
    memberships: null,
    loaded: false,
    loading: false,
    changing: false,
    accepting: false,
    rejecting: false,
};

export const [useTeams, teamsStoreApi] = createStore<Store>(() => initialState);

const { setState, getState } = teamsStoreApi;
export const teamsActions = {
    load: async (userId: string | number) => {
        if (getState().loading) return;
        setState({ loading: true });

        try {
            const { teamMemberships } = await getUserTeams(userId);

            setState({
                loaded: true,
                memberships: teamMemberships.sort((membershipA, membershipB) =>
                    membershipA.status.int === 4
                        ? Infinity
                        : membershipA.team.currentlySelected
                        ? -1
                        : membershipB.team.currentlySelected
                        ? 1
                        : 0
                ),
            });
        } catch (e) {
            setState({ loading: false });
            throw e;
        }
    },

    changeTeam: async (userId: string | number, teamId: string | number) => {
        if (!getState().loaded) return;
        if (getState().changing) {
            return;
        }

        try {
            setState({ changing: true });
            const { user } = await setUserTeam(userId, teamId);

            const curMemberships = getState().memberships;
            if (curMemberships !== null) {
                setState({
                    changing: false,
                    memberships: curMemberships.map(membership => ({
                        ...membership,
                        team: { ...membership.team, currentlySelected: membership.team.id === teamId },
                    })),
                });
            } else {
                setState({ changing: false });
            }

            return user;
        } catch (e) {
            setState({ changing: false });
            throw e;
        }
    },

    acceptTeam: async (teamId: string | number, membershipId: string | number) => {
        if (!getState().loaded) return;
        if (getState().accepting) {
            return;
        }
        setState({ accepting: true });

        try {
            await acceptTeamInvite(teamId, membershipId);

            const curMemberships = getState().memberships;
            if (curMemberships !== null) {
                setState({
                    accepting: false,
                    memberships: curMemberships.map(membership =>
                        membership.id === membershipId
                            ? {
                                  ...membership,
                                  status: { int: 1, string: 'Active' },
                              }
                            : membership
                    ),
                });
            } else {
                setState({ accepting: false });
            }
        } catch (e) {
            setState({ accepting: false });
            throw e;
        }
    },

    rejectTeam: async (teamId: string | number, membershipId: string | number) => {
        if (!getState().loaded) return;
        if (getState().accepting) {
            return;
        }
        setState({ rejecting: true });

        try {
            await rejectTeamInvite(teamId, membershipId);

            const curMemberships = getState().memberships;
            if (curMemberships !== null) {
                setState({
                    rejecting: false,
                    memberships: curMemberships.filter(memberships => memberships.id !== membershipId),
                });
            } else {
                setState({ rejecting: false });
            }
        } catch (e) {
            setState({ rejecting: false });
            throw e;
        }
    },
};
