import { get, patch, post } from '.';

type bookingEstimateTypes = Bizly.Estimates;

type spendTotalTypes = {
    total: number | string;
    guestrooms: number | string;
    room_rental: number | string;
    catering: number | string;
    av: number | string;
    misc: number | string;
};

export const getBookingsForEvent = (eventId: number | string) => get(`/events/${eventId}/bookings`);

export const setBookingContractUrl = (
    bookingId: number | string,
    contractUrls: { contractUrl: string; contractUrl2?: string }
) => patch(`bookings/${bookingId}/contract`, contractUrls);

export const setBookingEstimates = (bookingId: number | string, estimates: bookingEstimateTypes) =>
    patch(`bookings/${bookingId}/estimates`, estimates);

export const setBookingInvoiceUrl = (
    bookingId: number | string,
    invoiceUrls: { invoiceUrl: string; invoiceUrl2?: string }
) => patch(`bookings/${bookingId}/invoice`, invoiceUrls);

export const setBookingFinalSpend = (bookingId: number | string, spendTotals: spendTotalTypes) =>
    patch(`bookings/${bookingId}/final-spend`, spendTotals);

export const submitPaymentCard = (bookingId: number | string, userId: string) =>
    post(`bookings/${bookingId}/payment-cards`, { userId });
