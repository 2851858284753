import createStore from '../';
import { getContacts } from 'api/contacts';

type State = {
    loading: boolean;
} & (
    | {
          loaded: false;
          contacts: undefined;
      }
    | {
          loaded: true;
          contacts: BizlyAPI.Contact[];
      }
);
type Store = State;

const initialState: State = {
    loading: false,
    loaded: false,
    contacts: undefined,
};

export const [useContacts, contactsApi] = createStore<Store>(() => initialState);

const { setState, getState } = contactsApi;

export const contactsActions = {
    load: async () => {
        setState({
            ...getState(),
            loading: true,
        });

        try {
            const { contacts } = await getContacts();

            setState({
                loaded: true,
                loading: false,
                contacts,
            });

            return contacts;
        } catch (e) {
            setState({
                loading: false,
            });
            throw e;
        }
    },
};
