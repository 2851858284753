import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation, useParams } from 'react-router';
import { parse } from 'query-string';

import { isSupportedConnector } from 'api/connectors';

import { integrationsActions } from 'stores/integrations';
import { useUser } from 'providers/user';

import { SpinnerOverlay } from 'components/Spinner';
import { takeFirstIfArray } from 'utils';

export default function IntegrationCallback() {
    const location = useLocation();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { addConnector } = useUser();

    const { integration } = useParams<{ integration?: string }>();

    useEffect(() => {
        const { code: codeParam, state: stateParam } = takeFirstIfArray(parse(location.search));
        const code = String(codeParam);
        if (!isSupportedConnector(integration)) return history.push('/integrations');
        const state = stateParam || '';
        const { redirect } = takeFirstIfArray(parse(state));

        integrationsActions
            .callback(integration, code)
            .then(() => {
                return addConnector(integration);
            })
            .catch(() => {
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            })
            .finally(() => {
                if (redirect) {
                    history.push(redirect);
                } else history.push('/integrations');
            });
    }, [location, history, enqueueSnackbar, integration, addConnector]);

    return <SpinnerOverlay />;
}
