import React from 'react';

import { Prompt } from 'react-router';
import { Location } from 'history';

export default function useUnsavedPrompt(when?: boolean, isLocationSame?: (newLocation: Location) => boolean) {
    React.useEffect(() => {
        const listener = (event: BeforeUnloadEvent) => {
            if (when) {
                event.preventDefault();
                (event || window.event).returnValue = '';
            }
        };

        window.addEventListener('beforeunload', listener);
        return () => window.removeEventListener('beforeunload', listener);
    }, [when]);

    const renderPrompt = () => (
        <Prompt
            message={location =>
                isLocationSame?.(location) || false ? true : 'You have unsaved changes, are you sure you want to leave?'
            }
            when={when}
        />
    );

    return renderPrompt;
}
