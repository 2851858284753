import { post } from '.';
import { deleteCall } from './index';

import * as URI from 'uri-js';
import qs from 'query-string';

export const CONNECTOR_TYPES = {
    ZOOM: 'zoom',
    MS: 'ms-graph',
    GOOGLE: 'google',
} as const;

export const isSupportedConnector = (type?: string): type is BizlyAPI.Connector =>
    Object.values(CONNECTOR_TYPES).includes((type ?? '') as BizlyAPI.Connector);

export const getAuthUrl = (connector: BizlyAPI.Connector) =>
    post(`connectors/${connector}/auth`) as Promise<{ data: string }>;

export const postCallback = (connector: BizlyAPI.Connector, code: string) =>
    post(`connectors/${connector}/callback`, { code }) as Promise<{}>;

export const disconnect = (connector: BizlyAPI.Connector) => deleteCall(`connectors/${connector}`) as Promise<{}>;

export const appendRedirect = (authLink: string, redirect?: string) => {
    if (!redirect) return authLink;

    const parsedLink = URI.parse(authLink);
    const parsedQS = qs.parse(parsedLink.query || '');

    const updatedQS = { ...parsedQS, state: parsedQS.state + `&redirect=${redirect}` };

    const updatedLink = URI.serialize({
        ...parsedLink,
        query: qs.stringify(updatedQS),
    });

    return updatedLink;
};
