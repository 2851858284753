import React from 'react';

import { useSnackbar } from 'notistack';

import { calendarActions } from './store';

import moment from 'moment';

export default function LoadCalendar({ date, limit }: { date: Date | string; limit?: number }) {
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        const load = async () => {
            try {
                await calendarActions.load(
                    moment(date)
                        .startOf('week')
                        .toDate(),
                    limit
                );
            } catch (e) {
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            }
        };

        load();
    }, [date, limit, enqueueSnackbar]);

    return null;
}
