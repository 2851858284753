import React, { useMemo } from 'react';
import styled from 'styled-components';

import { EVENT_SPACES } from 'components/Planner/utils';
import { ReactComponent as TrashIcon } from 'images/icons/trash-can.svg';
import { withInteractibleIconStyles } from 'shared';
import { Column as UIColumn, Row, Copy } from 'ui';
import Button from 'components/ui/Button';
import LongButton from 'components/ui/Button/LongButton';
import Form from 'components/Form';
import { TEventSpace, TPlannerDataChange, TPlannerSections } from 'components/Planner/types';
import {
    makeEventSpaceNameField,
    defaultEventSpaceFields,
    eventSpaceNameSchema,
    eventSpaceSchema,
} from 'components/Planner/plannerFormSchema';
import { useEventOptions } from 'providers/event-options';

const Column = styled(UIColumn)`
    width: 100%;
`;

const Content = styled.div`
    border-radius: 8px;
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

const Head = styled(Row)`
    border-radius: 8px 8px 0px 0px;
    padding: 16px 0 16px 16px;
    justify-content: space-between;
`;

const Body = styled(UIColumn)`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    border-radius: 0px 0px 8px 8px;
    padding: 16px 16px 24px;
`;

const RemoveSpaceButton = styled(withInteractibleIconStyles(TrashIcon))`
    align-self: center;
    margin-right: 24px;
`;

const AddEventSpaceCTA = styled(Copy)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack)};
    font-size: 16px;
    font-weight: 500;
`;

const SaveButton = styled(Button)`
    align-self: flex-end;
    margin-top: 24px;
    font-weight: 400;
`;

type TEventSpaceUpdate = TPlannerDataChange & { value: TEventSpace };

type TEventSpaceDetailsProps = {
    editable: boolean;
    eventSpaces: TEventSpace[];
    addEventSpace: (targetSection: TPlannerSections) => void;
    removeEventSpace: (idx: number, targetSection: TPlannerSections) => void;
    setEventSpaceData: (eventSpaceData: TEventSpaceUpdate, idx: number, targetSection: TPlannerSections) => void;
    hasSaved: boolean;
    onSave: () => void;
};

const EventSpaceDetails = ({
    editable,
    eventSpaces,
    addEventSpace,
    removeEventSpace,
    setEventSpaceData,
    hasSaved,
    onSave,
}: TEventSpaceDetailsProps) => {
    const { setupOptions, avOptions, fbOptions } = useEventOptions();
    const eventSpaceFields = useMemo(
        () => defaultEventSpaceFields({ setupOptions: setupOptions.filter(o => o.id !== null), avOptions, fbOptions }),
        [setupOptions, avOptions, fbOptions]
    );

    return (
        <Column itemSpacing="small">
            {!!eventSpaces?.length &&
                eventSpaces
                    .map((eventSpace, originIndex) => ({ ...eventSpace, originIndex }))
                    .filter(eventSpace => !eventSpace.delete)
                    .map((eventSpace, idx) => {
                        const eventSpaceNameField = makeEventSpaceNameField({
                            onBlur: () => {
                                if (!eventSpace?.spaceName?.length) {
                                    setEventSpaceData(
                                        {
                                            error: null,
                                            field: 'spaceName',
                                            value: { ...eventSpace, spaceName: `Meeting Space ${idx + 1}` },
                                        },
                                        eventSpace.originIndex,
                                        EVENT_SPACES
                                    );
                                }
                            },
                        });

                        return (
                            <div key={eventSpace.id || `meetingspace-${idx + 1}`}>
                                <Content>
                                    <Head>
                                        <Form
                                            fields={eventSpaceNameField}
                                            schema={eventSpaceNameSchema}
                                            value={eventSpace}
                                            onChange={(e: TEventSpaceUpdate) =>
                                                setEventSpaceData(e, eventSpace.originIndex, EVENT_SPACES)
                                            }
                                            disabled={!editable}
                                        />
                                        {editable && (
                                            <RemoveSpaceButton
                                                onClick={() => removeEventSpace(eventSpace.originIndex, EVENT_SPACES)}
                                            />
                                        )}
                                    </Head>
                                    <Body>
                                        <Form
                                            fields={eventSpaceFields}
                                            schema={eventSpaceSchema}
                                            value={eventSpace}
                                            onChange={(e: TEventSpaceUpdate) =>
                                                setEventSpaceData(e, eventSpace.originIndex, EVENT_SPACES)
                                            }
                                            disabled={!editable}
                                        />
                                    </Body>
                                </Content>
                            </div>
                        );
                    })}

            {editable && (
                <LongButton onClick={() => addEventSpace(EVENT_SPACES)} isSecondary>
                    <AddEventSpaceCTA>Add Meeting Space</AddEventSpaceCTA>
                </LongButton>
            )}
            {editable && (
                <SaveButton onClick={onSave} disabled={hasSaved} small width={68}>
                    Save
                </SaveButton>
            )}
        </Column>
    );
};

export default EventSpaceDetails;
