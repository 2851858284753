import React from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router';
import useShowModal from 'hooks/useShowModal';

import { createMeetingActions, useCreateMeeting, selMaxStep, ValidationError } from '../store';
import { nudgesActions, useNudges } from 'stores/nudges';

import { Step, StepLabel, Stepper as StepperMui } from '@material-ui/core';
import Button from 'components/ui/Button';
import { Row } from 'ui';
import IntegrationsPrompt from 'components/IntegrationsPrompt';
import colorFns from 'colorFns';
import SeriesUpdatePrompt from './SeriesUpdatePrompt';

const HeaderRow = styled(Row)<{ noBackground?: boolean }>`
    background: transparent;
`;

const AutoRow = styled(Row)`
    width: auto;
`;

const Stepper = styled(StepperMui)`
    min-width: 200px;
    padding-left: 0;
    background: transparent;

    .MuiStep-root,
    .MuiStepLabel-iconContainer {
        padding: 0;
    }

    .MuiStepIcon-root.MuiStepIcon-active {
        color: ${colorFns.primaryAction};
    }
`;

export default function Header({ noBackground, className }: { noBackground?: boolean; className?: string }) {
    const { stepIdx, stepList, isPublished, loading, hasRecurrence } = useCreateMeeting();
    const maxStep = useCreateMeeting(selMaxStep);
    const { id } = useParams<{ id?: string }>();
    const history = useHistory<{ skipLoading?: boolean }>();
    const { enqueueSnackbar } = useSnackbar();

    const { showPublishIntegrations } = useNudges();
    const { modalShown, showModal, hideModal } = useShowModal();

    const skipIntegration = async (forever?: boolean) => {
        if (forever) {
            await nudgesActions.updateIntegrationsPrompts('showPublishIntegrations', false);
        } else {
            nudgesActions.mergeNudges({
                showPublishIntegrations: false,
            });
        }
        hideModal();
        await publish();
    };

    const { modalShown: seriesPrompt, showModal: showSeriesPrompt, hideModal: hideSeriesPrompt } = useShowModal();

    const save = async (type?: 'current' | 'forward' | 'all') => {
        if (isPublished && id && hasRecurrence) {
            if (!type) return showSeriesPrompt();
            hideSeriesPrompt();
        }

        try {
            if (isPublished && id) {
                await createMeetingActions.savePublished(id, type);
                history.replace(`/meetings/${id}`);
                return enqueueSnackbar('Meeting updated', { variant: 'info' });
            }

            const resultMeeting = await createMeetingActions.saveDraft(id);
            if (id === undefined && resultMeeting) {
                history.replace(`/meetings/${resultMeeting.id}/edit`, { skipLoading: true });
            }
        } catch (e) {
            if (!(e instanceof ValidationError))
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    };

    const publish = async () => {
        try {
            const meeting = await createMeetingActions.publish(id);
            if (meeting) {
                if (meeting.published) {
                    history.replace(`/meetings/${meeting.id}`);
                    return;
                } else if (id === undefined) {
                    history.replace(`/meetings/${meeting.id}/edit`, { skipLoading: true });
                }
            }
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        } catch (e) {
            if (!(e instanceof ValidationError))
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    };

    const saveable = isPublished ? stepIdx >= 0 : stepIdx > 0;

    return (
        <HeaderRow alignItems="center" justifyContent="space-between" noBackground={noBackground} className={className}>
            <AutoRow>
                <Stepper>
                    {stepList.map((name, idx) => (
                        <Step key={idx} completed={false} active={idx <= stepIdx}>
                            <StepLabel>{}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </AutoRow>
            {!loading && (
                <AutoRow itemSpacing="smallish">
                    {stepIdx > 0 && (
                        <Button width="60px" secondary onClick={createMeetingActions.prevStep}>
                            Prev
                        </Button>
                    )}
                    {saveable && (
                        <Button width="140px" onClick={() => save()}>
                            {isPublished ? 'Save' : 'Save Draft'}
                        </Button>
                    )}
                    {stepIdx < maxStep && (
                        <Button width="80px" onClick={createMeetingActions.nextStep}>
                            Next
                        </Button>
                    )}
                    {!isPublished && stepIdx === maxStep && (
                        <Button
                            width="80px"
                            onClick={() => {
                                if (showPublishIntegrations) {
                                    return showModal();
                                }
                                return publish();
                            }}
                        >
                            Send
                        </Button>
                    )}
                </AutoRow>
            )}
            {modalShown && showPublishIntegrations ? (
                <IntegrationsPrompt
                    title="Wait! Before Publishing..."
                    connectors={['ms-graph', 'google']}
                    benefits={{
                        'ms-graph': ['Sync this meeting with your Microsoft Calendar'],
                        google: ['Sync this meeting with your Google Calendar'],
                    }}
                    onSkip={skipIntegration}
                    withRedirect
                    beforeLeave={() => save()}
                    loadingMessage="Saving your work"
                />
            ) : null}
            {seriesPrompt && <SeriesUpdatePrompt onCancel={hideSeriesPrompt} onProceed={save} />}
        </HeaderRow>
    );
}
