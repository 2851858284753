import React from 'react';
import styled from 'styled-components';

import { PreviewVideo, useMeetingStatus } from 'amazon-chime-sdk-component-library-react';

import { Row, Column } from 'ui';

import VideoControls from './VideoControls';
import VideoGallery from './VideoGallery';

const Controls = styled(VideoControls)``;

const VideoContainer = styled(Row)``;

const VideoSection = styled(Column)`
    width: 600px;
    height: 600px;
    min-height: 600px;

    > ${VideoContainer} {
        flex: 1 0 0;
        min-height: 0;
    }

    > ${Controls} {
        flex: 0 0 auto;
    }

    & video {
        border-radius: 8px;
    }
`;

export default function ChimeVideo({
    joining,
    joinInfo,
    leave,
}: {
    joinInfo: { meetingInfo: any; attendeeInfo: any };
    joining?: boolean;
    leave?: () => void;
}) {
    const meetingStatus = useMeetingStatus();

    return (
        <VideoSection itemSpacing="large">
            <VideoContainer alignItems="center">
                {meetingStatus !== 1 ? <PreviewVideo /> : <VideoGallery joinInfo={joinInfo} />}
            </VideoContainer>
            <Controls joining={joining} onLeave={leave} />
        </VideoSection>
    );
}
