import React from 'react';
import { useSnackbar } from 'notistack';

import { addMembership, clearMemberships } from 'api/chime';
import { isDeniedAccess } from 'api/meetings';
import { chimeChatActions } from 'stores/chime-chat';
import { meetingsActions } from 'stores/meetings';
import { useUser } from 'providers/user';

export default function LoadChatMessages({ meetingId }: { meetingId?: string | number }) {
    const { user } = useUser();
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        const load = async () => {
            if (meetingId) {
                try {
                    await clearMemberships();
                    const { channelArn } = (await addMembership(meetingId)) ?? {};
                    if (channelArn) {
                        await chimeChatActions.load(channelArn);
                        await chimeChatActions.connectSocket(user.email);
                    }
                    meetingsActions.chatViewed(meetingId);
                } catch (e) {
                    if (isDeniedAccess(e)) return;
                    enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
                }
            }
        };
        load();

        return () => {
            chimeChatActions.disconnectSocket();
        };
    }, [meetingId, user, enqueueSnackbar]);

    return null;
}
