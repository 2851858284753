import React from 'react';

import { useHistory, useLocation, Prompt } from 'react-router';
import { useSnackbar } from 'notistack';
import { parse, stringify } from 'query-string';
import omit from 'lodash/omit';
import { Location } from 'history';

import { takeFirstIfArray } from '../../util';

import { FORM_SECTIONS, TProposalFormUpdate, TProposalForm } from './types';
import { TSpace } from './EventSpacesForm/utils';
import { TAction } from './statusUtils';
import { TSpacePOST } from 'api';

import { DeleteConfirmationModal } from 'components/DeleteConfirmationModal';

export const isEmptyString = (str: string | null = '') => !(str || '').trim();

export const isEmptyStringOrNumber = (str: string | number | null = '') =>
    typeof str === 'string' ? isEmptyString(str || '') : typeof str !== 'number';

export const useFormSectionsHistory = (sections: FORM_SECTIONS[], initial = 0) => {
    const history = useHistory<{ sectionIndex?: number }>();
    const [immutableInitial] = React.useState(initial);

    React.useEffect(() => {
        history.replace(history.location.pathname + history.location.search, { sectionIndex: immutableInitial });
    }, [history, immutableInitial]);

    const sectionIndex = history.location.state?.sectionIndex || 0;

    const nextSection = () => {
        // get most up-to-date version of current index
        const curIdx = history.location.state.sectionIndex;
        if (curIdx !== undefined && curIdx < sections.length - 1)
            history.push(history.location.pathname + history.location.search, { sectionIndex: curIdx + 1 });
    };
    const goBack = sectionIndex
        ? () => {
              const curIdx = history.location.state.sectionIndex;
              if (curIdx) {
                  initial > 0
                      ? history.push(history.location.pathname + history.location.search, { sectionIndex: curIdx - 1 })
                      : history.goBack();
              }
          }
        : undefined;

    const queryParams = takeFirstIfArray(parse(useLocation().search));
    const removedAction = omit(queryParams, ['action']);
    const search = stringify(removedAction);

    const restart = () => history.push(history.location.pathname + '?' + search, { sectionIndex: 0 });
    const goToEnd = () => history.push(history.location.pathname + '?' + search, { sectionIndex: sections.length - 1 });
    return { sectionIndex, goBack, nextSection, restart, goToEnd };
};

export const useUnsavedPrompt = (when?: boolean, isLocationSame?: (newLocation: Location) => boolean) => {
    React.useEffect(() => {
        const listener = (event: BeforeUnloadEvent) => {
            if (when) {
                event.preventDefault();
                (event || window.event).returnValue = '';
            }
        };

        window.addEventListener('beforeunload', listener);
        return () => window.removeEventListener('beforeunload', listener);
    }, [when]);

    const renderPrompt = () => (
        <Prompt
            message={location =>
                isLocationSame?.(location) || false ? true : 'You have unsaved changes, are you sure you want to leave?'
            }
            when={when}
        />
    );

    return renderPrompt;
};

type TValidationFn = (isContinue?: boolean) => false | Partial<TProposalFormUpdate>;

type TValidator = Partial<{ section?: FORM_SECTIONS; validationFn: TValidationFn }>;

export const useFormValidator = (section: FORM_SECTIONS) => {
    const validator = React.useRef<TValidator>({});

    const validationFn = React.useCallback(
        (isContinue?: boolean) => {
            if (validator.current.section === section) {
                return validator.current?.validationFn === undefined ? {} : validator.current?.validationFn(isContinue);
            }

            return {};
        },
        [section]
    );

    const registerValidator = React.useCallback(
        (validationFn: TValidationFn) => (validator.current = { section, validationFn }),
        [section]
    );

    return { validationFn, registerValidator };
};

export function useRegisterValidator<T>(
    data: T,
    registerValidator: (validationFn: TValidationFn) => void,
    getErrorMessage: (data: T, isContinue?: boolean) => React.ReactNode,
    formDataToProposalForm: (data: T) => Partial<TProposalFormUpdate>
) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [snackbarKey, setSnackbarKey] = React.useState<string | number | undefined>();

    React.useEffect(
        () => () => {
            if (snackbarKey) closeSnackbar(snackbarKey);
        },
        [data, snackbarKey, closeSnackbar]
    );

    React.useEffect(() => {
        const validate = (isContinue?: boolean) => {
            const error = getErrorMessage(data, isContinue);
            if (!error) return formDataToProposalForm(data);

            setSnackbarKey(enqueueSnackbar(error, { variant: 'error', persist: true }) || undefined);
            return false;
        };
        registerValidator(validate);
    }, [enqueueSnackbar, data, formDataToProposalForm, getErrorMessage, registerValidator]);
}

export type TFormSectionProps = {
    onChange: () => void;
    onUpdateVenueSpaces: (space: TSpacePOST) => Promise<TSpace[] | false>;
    registerValidator: (validationFn: TValidationFn) => void;
    action: TAction;
    userChangedStatus: boolean;
    disabled?: boolean;
} & Partial<TProposalForm>;

export const useConfirmModal = ({
    showOnLoad,
    prompt,
    onConfirm,
    onDismiss: onDismissProp,
    onSuccess: onSuccessProp,
}: {
    showOnLoad: boolean;
    prompt: string;
    onConfirm: () => Promise<boolean>;
    onDismiss: () => void;
    onSuccess: () => void;
}) => {
    const [showModal, setShowModal] = React.useState(showOnLoad);
    const [loading, setLoading] = React.useState(false);

    const onProceed = React.useCallback(async () => {
        setLoading(true);
        const success = await onConfirm();
        setLoading(false);

        if (success) {
            setShowModal(false);
            onSuccessProp();
        }
    }, [onConfirm, setShowModal, setLoading, onSuccessProp]);

    const onDismiss = () => {
        setShowModal(false);
        onDismissProp();
    };

    const openModal = React.useCallback(() => setShowModal(true), [setShowModal]);

    const renderModal = () => (
        <DeleteConfirmationModal
            isActive={showModal}
            onDismiss={onDismiss}
            onProceed={onProceed}
            prompt={prompt}
            ctaLabel="Yes"
            dismissLabel="No"
            loading={loading}
        />
    );

    return { renderModal, loading, openModal };
};
