export const fields = {
    total: {
        prompt: 'Final Total (incl. taxes & fees)',
        type: 'text',
        options: { placeholder: '$0' },
    },
    guestrooms: {
        prompt: 'Final Guest Room Cost',
        type: 'text',
        options: { placeholder: '$0' },
    },
    roomRental: {
        prompt: 'Final Room Rental',
        type: 'text',
        options: { placeholder: '$0' },
    },
    catering: {
        prompt: 'Final Food & Beverage Cost',
        type: 'text',
        options: { placeholder: '$0' },
    },
    av: {
        prompt: 'Final A/V Cost',
        type: 'text',
        options: { placeholder: '$0' },
    },
    misc: {
        prompt: 'Misc. Costs',
        type: 'text',
        options: { placeholder: '$0' },
    },
};

export const schema = [
    {
        key: 'rowOne',
        fields: ['total', 'guestrooms'],
        spacing: 'small',
    },
    {
        key: 'rowTwo',
        fields: ['roomRental', 'catering'],
        spacing: 'small',
    },
    {
        key: 'rowThree',
        fields: ['av', 'misc'],
        spacing: 'small',
    },
];

export const schemaWithoutGuestrooms = [
    {
        key: 'rowOne',
        fields: ['total', 'catering'],
        spacing: 'small',
    },
    {
        key: 'rowTwo',
        fields: ['roomRental', 'av'],
        spacing: 'small',
    },
    {
        key: 'rowThree',
        fields: ['misc'],
        spacing: 'small',
    },
];
