import React from 'react';
import styled from 'styled-components';

import useThemedColor from 'hooks/useThemedColor';

import { Column, FixedBackground } from 'ui';
import ThemedLogo from 'components/ui/ThemedLogo';

const CenteredColumn = styled(Column)`
    height: 100%;
    box-sizing: border-box;

    justify-content: flex-start;

    margin: 0 auto;
    padding-top: 100px;
`;

const PaddedLogo = styled(ThemedLogo)`
    position: absolute;
    padding: 36px;
`;

export default function LogoBackground({ useCard, children }: { useCard?: boolean; children: React.ReactNode }) {
    const { brand } = useThemedColor();

    return (
        <CenteredColumn>
            <FixedBackground backgroundColor={brand}>
                <PaddedLogo />
            </FixedBackground>
            {children}
        </CenteredColumn>
    );
}
