import { parse, stringify } from 'query-string';
import { History } from 'history';

export const ESECTION = {
    PERSONAL: 'personal',
    SHARED: 'shared',
    GLOBAL: 'global',
} as const;
export type ESECTION = Bizly.EventTemplateCategory;

const { PERSONAL, SHARED, GLOBAL } = ESECTION;

export const sectionToTitle = (section: ESECTION, user: Bizly.User) =>
    ({
        [PERSONAL]: 'My Playbooks',
        [SHARED]: `${user?.team?.name || 'Your Team'} Playbooks`,
        [GLOBAL]: 'Recommended Playbooks',
    }[section]);

export const sectionToSubheading = (section: ESECTION) =>
    ({
        [PERSONAL]: 'These are the playbooks you’ve created.',
        [SHARED]: `These are the playbooks your team members have created.`,
        [GLOBAL]: 'These are playbooks available to all.',
    }[section]);

export type TTemplateFilterValue = Partial<{
    section: ESECTION;
    tags: number[];
}>;

export const getTemplatesUrlFilters = (location: History['location']): TTemplateFilterValue => {
    const parsedFilters = parse(location.search, { parseNumbers: true });
    return {
        ...parsedFilters,
        ...(parsedFilters.tags
            ? {
                  tags: ([] as (string | number)[]).concat(parsedFilters.tags).map(id => parseInt('' + id)),
              }
            : {}),
    };
};

export const setTemplatesUrlFilters = (
    history: History,
    merger: (curFilterValue: TTemplateFilterValue) => TTemplateFilterValue
) => {
    const currentFilters = getTemplatesUrlFilters(history.location);
    history.replace({ ...history.location, search: stringify(merger(currentFilters)) });
};
