import createStore from '../';
import { clearUnreadAlerts, getAlerts } from 'api/alerts';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import { tzMoment } from 'utils/moment';

type State = {
    loadingOlder: boolean;
    loadingNewer: boolean;
    clearing: boolean;
} & (
    | {
          loaded: false;
          alerts?: undefined;
          groupedAlerts?: undefined;
          hasOlder: true;
          page: 0;
          unreadCount: 0;
      }
    | {
          loaded: true;
          alerts: BizlyAPI.Alert[];
          groupedAlerts: (BizlyAPI.Alert & { count: number })[];

          hasOlder: boolean;
          page: number;
          unreadCount: number;
      }
);
type Store = State;

const initialState: State = {
    loadingOlder: false,
    loadingNewer: false,
    clearing: false,
    loaded: false,
    alerts: undefined,
    hasOlder: true,
    page: 0,
    unreadCount: 0,
};

export const [useAlerts, alertsApi] = createStore<Store>(() => initialState);

const { setState, getState } = alertsApi;

const groupAlerts = (alerts: BizlyAPI.Alert[]) => {
    const grouped = groupBy(alerts, alert => [alert.meeting.id, alert.type, alert.read ? 1 : 2].join('.'));
    const summarized = Object.values(grouped).map(group => {
        const sorted = orderBy(group, ['createdAt', 'id'], ['asc', 'asc']);
        return { ...sorted[sorted.length - 1], count: group.length };
    });

    return orderBy(summarized, ['createdAt', 'id'], ['asc', 'asc']);
};

export const alertsActions = {
    load: async () => {
        await alertsActions.pullOlder(true);

        setState({
            loaded: true,
        });
    },

    pullOlder: async (init?: boolean) => {
        if (!init && !getState().loaded) return;
        if (getState().loadingOlder) return;

        setState({
            ...getState(),
            loadingOlder: true,
        });

        try {
            const prevAlerts = getState().alerts ?? [];
            const oldIds = new Set(prevAlerts.map(alert => alert.id));

            const oldest = prevAlerts[0];
            const before = oldest ? tzMoment(oldest.createdAt).toDate() : undefined;

            const { alerts: rawAlerts, meta } = await getAlerts({ before, limit: 20 });
            const newAlerts = sortBy(
                rawAlerts.filter(alert => !oldIds.has(alert.id)),
                alert => alert.createdAt
            );

            const hasNew = newAlerts.length > 0;

            const updatedAlerts = uniqBy([...newAlerts, ...(getState().alerts ?? [])], alert => alert.id);
            const grouped = groupAlerts(updatedAlerts);

            setState({
                loadingOlder: false,
                alerts: updatedAlerts,
                hasOlder: hasNew,
                page: getState().page + 1,
                unreadCount: meta.unreadCount,
                groupedAlerts: grouped,
            });

            return {
                updatedAlerts,
                hasNew,
            };
        } catch (e) {
            setState({
                loadingOlder: false,
            });
            throw e;
        }
    },

    pullNewer: async () => {
        if (!getState().loaded) return;
        if (getState().loadingNewer) return;

        setState({
            ...getState(),
            loadingNewer: true,
        });

        try {
            const prevAlerts = getState().alerts ?? [];
            const oldIds = new Set(prevAlerts.map(alert => alert.id));

            const newest = prevAlerts[prevAlerts.length - 1];
            const after = newest ? tzMoment(newest.createdAt).toDate() : undefined;

            const { alerts: rawAlerts, meta } = await getAlerts({ after, limit: 100 });
            const newAlerts = sortBy(
                rawAlerts.filter(alert => !oldIds.has(alert.id)),
                alert => alert.createdAt
            );

            const hasNew = newAlerts.length > 0;

            const updatedAlerts = uniqBy([...(getState().alerts ?? []), ...newAlerts], alert => alert.id);
            const grouped = groupAlerts(updatedAlerts);

            setState({
                loadingNewer: false,
                alerts: updatedAlerts,
                unreadCount: meta.unreadCount,
                groupedAlerts: grouped,
            });

            return {
                updatedAlerts,
                hasNew,
            };
        } catch (e) {
            setState({
                loadingNewer: false,
            });
            throw e;
        }
    },

    clearUnread: async (clearCache?: boolean) => {
        if (!getState().loaded) return;
        if (getState().clearing) return;

        try {
            const updatedIds = new Set((getState().alerts ?? []).map(a => a.id));
            const curUnread = getState().unreadCount;

            await clearUnreadAlerts();
            if (clearCache) {
                const updatedAlerts = (getState().alerts ?? []).map(a =>
                    updatedIds.has(a.id) ? { ...a, read: true } : a
                );
                const grouped = groupAlerts(updatedAlerts);

                setState({
                    alerts: updatedAlerts,
                    groupedAlerts: grouped,
                    clearing: false,
                    unreadCount: curUnread === getState().unreadCount ? 0 : getState().unreadCount,
                });
                return;
            }

            setState({
                clearing: false,
                unreadCount: curUnread === getState().unreadCount ? 0 : getState().unreadCount,
            });
        } catch (e) {
            setState({
                clearing: false,
            });
            throw e;
        }
    },
};
