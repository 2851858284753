const formFilename = (filename: string) => filename.replace(/\s/g, '_').replace(/[^a-zA-Z0-9_]/g, '');

const downloadFileFromLink = (blob: Blob, fileName: string, format: string) => {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', `${formFilename(fileName)}.${format}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export default downloadFileFromLink;
