import React from 'react';
import { css } from 'styled-components/macro';

import Form from 'components/Form';
import { StyledPhoneInput } from 'components/ui/Input';
import { CustomQuestionsForm, DietaryQuestionsField } from 'pages/RSVPForm';
import { useUser } from 'providers/user';
import { attendanceTypeOptions, statusOptions } from 'shared';

const FormAny = Form as any;

type AttendeeFormPhoneFieldProps = {
    field: string;
    value: string;
    onChange: (options: { value: string; errors: {}; field: string }) => void;
    errors: object;
};

const AttendeeFormPhoneField = ({ field, value, onChange, errors }: AttendeeFormPhoneFieldProps) => {
    return (
        <div
            css={css`
                input {
                    padding: 18.5px 14px;
                    padding-top: 10.5px;
                    padding-bottom: 10.5px;
                    height: 1.1875em;
                    margin-top: 8px;
                    margin-bottom: 4px;

                    &:hover {
                        box-shadow: 0 2px 4px 0
                            ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack, 0.06)};
                    }
                }
            `}
        >
            <StyledPhoneInput
                value={value}
                error={!!errors}
                onChange={newValue => {
                    onChange({
                        value: newValue,
                        errors: {},
                        field: field,
                    });
                }}
            />
        </div>
    );
};

type AttendeeFormProps<TReadonly extends boolean | undefined> = {
    attendee: BizlyAPI.Attendee &
        Partial<{ dietaryRestrictions: unknown; foodAllergies: unknown; checkInDate: unknown }>;
    eventOptions: { dietaryRestrictionOptions: unknown; foodAllergyOptions: unknown };
    readonly?: TReadonly;
} & (true extends TReadonly
    ? { readonly: true }
    : {
          readonly?: false;
          externalIdFieldLabel?: string;
          onChange: (value: BizlyAPI.Attendee) => void;
          error: boolean;
      });

function AttendeeForm(props: AttendeeFormProps<true> | AttendeeFormProps<false | undefined>) {
    const {
        attendee,
        eventOptions,
        readonly,
        externalIdFieldLabel = '',
        onChange: onChangeProp,
        error = false,
    } = props as AttendeeFormProps<false>;

    const { toggles } = useUser();
    const { dietaryRestrictionOptions, foodAllergyOptions } = eventOptions;
    const formValue = {
        ...attendee,
        dietary: {
            dietaryRestrictions: attendee.dietaryRestrictions,
            foodAllergies: attendee.foodAllergies,
        },
        accommodation: attendee.checkInDate
            ? 'Overnight accommodation required.'
            : 'No overnight accommodation required.',
    };

    const fields = {
        firstName: {
            prompt: 'First Name',
            type: 'text',
            perRow: 2,
            options: { inverted: true },
        },
        lastName: {
            prompt: 'Last Name',
            type: 'text',
            perRow: 2,
            options: { inverted: true },
        },
        phone: {
            prompt: 'Phone Number',
            type: AttendeeFormPhoneField,
            perRow: 2,
        },
        externalId: {
            prompt: externalIdFieldLabel,
            type: 'text',
            perRow: 2,
            options: { inverted: true },
        },
        checkInDate: {
            prompt: 'Check-in Date',
            type: 'date',
            perRow: 3,
        },
        checkOutDate: {
            prompt: 'Check-out Date',
            type: 'date',
            perRow: 3,
        },
        status: {
            prompt: 'Status',
            type: 'select',
            perRow: 3,
            options: {
                options: statusOptions,
                inverted: true,
            },
        },
        dietary: {
            type: DietaryQuestionsField,
            readonly,
            options: {
                prompt: 'Dietary Needs',
                dietaryRestrictionOptions,
                foodAllergyOptions,
                perRow: readonly ? 1 : 4,
            },
        },
        rsvpComments: {
            prompt: 'Notes',
            type: 'textarea',
            readonly,
            perRow: 2,
            options: {
                placeholder: readonly ? 'No notes.' : 'Add a note about this attendee.',
                inverted: true,
            },
        },
        attendanceType: {
            prompt: 'Attendance Type',
            type: 'radioselect',
            perRow: 2,
            options: {
                options: attendanceTypeOptions,
            },
        },
        customQuestions: {
            type: CustomQuestionsForm,
            options: {
                questions: formValue.customQuestions,
            },
        },
        accommodation: {
            prompt: 'Accommodation',
            type: 'text',
            perRow: 2,
        },
    };

    const schema = [
        {
            key: 'name',
            fields: ['firstName', 'lastName'],
        },
        {
            key: 'phoneExternalId',
            fields: ['phone', 'externalId'],
        },
        {
            key: 'dates',
            fields: ['checkInDate', 'checkOutDate', 'status'],
        },
        {
            key: 'dietary',
            fields: ['dietary'],
        },
        {
            key: 'rsvpComments',
            fields: ['rsvpComments'],
        },
        ...(toggles.gate.useNewPlanner
            ? [
                  {
                      key: 'attendanceType',
                      fields: ['attendanceType'],
                  },
              ]
            : []),
        {
            key: 'customQuestions',
            header: 'Custom RSVP Questions',
            fields: ['customQuestions'],
        },
    ];

    const readOnlySchema = [
        {
            key: 'accommodation',
            fields: ['accommodation'],
        },
        ...(formValue.checkInDate
            ? [
                  {
                      key: 'checkDates',
                      fields: ['checkInDate', 'checkOutDate'],
                  },
              ]
            : []),
        {
            key: 'dietaryRsvpComments',
            fields: ['dietary', 'rsvpComments'],
        },
        ...(toggles.gate.useNewPlanner
            ? [
                  {
                      key: 'attendanceType',
                      fields: ['attendanceType'],
                  },
              ]
            : []),
        {
            key: 'customQuestions',
            header: 'Custom RSVP Questions',
            fields: ['customQuestions'],
        },
    ];

    const onChange = ({ value: { dietary, ...newValue } }: { value: BizlyAPI.Attendee & { dietary: any } }) => {
        onChangeProp({ ...newValue, ...dietary });
    };

    return (
        <FormAny
            fields={fields}
            schema={readonly ? readOnlySchema : schema}
            value={formValue}
            readonly={readonly}
            errors={error ? { phone: true } : {}}
            onChange={onChange}
        />
    );
}

export default AttendeeForm;
