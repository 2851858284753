import { timeOptions } from 'utils/date_util';
import { CheckboxFields, CheckboxDropdownFields } from './EventSpaceCheckboxFields';

/* Schedule Section */

export const scheduleFields = {
    date: {
        prompt: 'Date',
        type: 'date_outlined',
        options: {
            minDate: Date.now(),
            parseFormat: 'yyyy-MM-dd',
            placeholder: 'Date',
        },
    },
    startTime: {
        prompt: 'Start Time',
        type: 'select',
        options: {
            options: timeOptions,
            placeholder: 'Start Time',
            autoFocusOptionKey: '12:00:00',
        },
    },
    endTime: {
        prompt: 'End Time',
        type: 'select',
        options: {
            options: timeOptions,
            placeholder: 'End Time',
            autoFocusOptionKey: '12:00:00',
        },
    },
};

export const scheduleSchema = [
    {
        key: 'scheduleFields',
        fields: ['date', 'startTime', 'endTime'],
        spacing: false,
    },
];

/* Event Spaces Section */

type TEventOption = { name: string; iconUrl: string; id: number; diningStyle?: { id: number; name: string }[] };
type TEventSpaceFieldOptions = {
    setupOptions: TEventOption[];
    avOptions: TEventOption[];
    fbOptions: TEventOption[];
};

export const makeEventSpaceNameField = ({ onBlur }: { onBlur: () => void }) => ({
    spaceName: {
        type: 'text',
        options: {
            placeholder: 'Name this meeting space',
            onBlur,
        },
    },
});

export const defaultEventSpaceFields = ({ setupOptions, avOptions, fbOptions }: TEventSpaceFieldOptions) => ({
    date: {
        prompt: 'Date',
        type: 'date_outlined',
        options: {
            minDate: Date.now(),
            parseFormat: 'yyyy-MM-dd',
            placeholder: 'Date',
        },
    },
    startTime: {
        prompt: 'Start Time',
        type: 'select',
        options: {
            options: timeOptions,
            placeholder: 'Start Time',
            autoFocusOptionKey: '12:00:00',
        },
    },
    endTime: {
        prompt: 'End Time',
        type: 'select',
        options: {
            options: timeOptions,
            placeholder: 'End Time',
            autoFocusOptionKey: '12:00:00',
        },
    },
    setupId: {
        prompt: 'Room Setup',
        type: 'select',
        options: {
            options: setupOptions,
            placeholder: 'Select Setup',
        },
    },
    attendees: {
        prompt: 'No. of Attendees',
        type: 'counter',
        options: {
            min: 0,
        },
    },
    avNeeds: {
        prompt: 'Audio/Visual',
        type: CheckboxFields,
        options: {
            options: avOptions,
            perRow: 3,
            iconBox: true,
        },
    },
    fbNeeds: {
        prompt: 'Food & Beverage',
        type: CheckboxDropdownFields,
        options: {
            options: fbOptions,
            perRow: 3,
            iconBox: true,
        },
    },
});

export const eventSpaceNameSchema = [
    {
        key: 'spaceName',
        fields: ['spaceName'],
        spacing: false,
    },
];

export const eventSpaceSchema = [
    {
        key: 'eventSpaceDates',
        fields: ['date', 'startTime', 'endTime'],
        spacing: 'medium',
    },
    {
        key: 'roomAndAttendees',
        fields: ['setupId', 'attendees'],
        spacing: 'medium',
    },
    {
        key: 'avNeeds',
        fields: ['avNeeds'],
        spacing: 'medium',
    },
    {
        key: 'fbNeeds',
        fields: ['fbNeeds'],
        spacing: false,
    },
];

/* Accommodations Section */

export const defaultAccommodationsFields = ({ datesToDisable }: { datesToDisable: Partial<string[]> }) => ({
    date: {
        prompt: 'Date',
        type: 'date_outlined',
        options: {
            minDate: Date.now(),
            parseFormat: 'yyyy-MM-dd',
            datesToDisable,
            placeholder: 'Date',
        },
    },
    count: {
        prompt: 'No. of Rooms',
        type: 'counter',
        options: {
            min: 0,
        },
    },
    notes: {
        prompt: 'Notes',
        type: 'textarea',
        options: { placeholder: 'Notes' },
    },
});

export const accommodationsSchema = [
    {
        key: 'dateAndRooms',
        fields: ['date', 'count'],
        spacing: 'medium',
    },
    {
        key: 'notes',
        fields: ['notes'],
        spacing: false,
    },
];

/* Virtual Meetings Section */

export const defaultVMFields = (teamServiceTypes: Partial<Bizly.VirtualMeetingServiceProvider>[] = []) => ({
    serviceProvider: {
        prompt: 'Service Type',
        type: 'select',
        fixedWidth: 168,
        options: {
            options: teamServiceTypes,
            placeholder: 'Select service',
            overflowTruncate: true,
            valueAsOption: true,
        },
    },
    link: {
        prompt: 'Link',
        type: 'text',
        options: {
            placeholder: 'https://www.host.com/meeting_id',
        },
    },
    notes: {
        prompt: 'Additional Meeting Information',
        type: 'rich_text',
    },
});

export const VMSchemaLink = [
    {
        key: 'serviceProvider',
        fields: ['link'],
        spacing: false,
    },
];

export const VMSchemaNotes = [
    {
        key: 'additionalInfo',
        fields: ['notes'],
        spacing: false,
    },
];
