import React from 'react';
import styled, { css } from 'styled-components';

import {
    useMeetingManager,
    useLocalVideo,
    useToggleLocalMute,
    useMeetingStatus,
    useContentShareState,
    useContentShareControls,
    LeaveMeeting,
    Play,
    Camera,
    Microphone,
    ScreenShare,
} from 'amazon-chime-sdk-component-library-react';

import { Row } from 'ui';
import { Tooltip } from '@material-ui/core';
import colorFns from 'colorFns';

const iconStylesFn = css<{ active?: boolean; disabled?: boolean }>`
    ${({ active }: { active?: boolean }) => (active ? `color: ${colorFns.defaultIconColor}` : '')};
    ${({ disabled }: { disabled?: boolean }) => (disabled ? `color: ${colorFns.lightGrey}` : '')};
`;

const Circle = styled.button<{ label?: string }>`
    border-radius: 2rem;
    background: white;
    cursor: pointer;

    width: 3rem;
    height: 3rem;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid transparent;
    ${iconStylesFn}

    ${({ label, ...props }) =>
        label
            ? `
    position: relative;

    &::after {
        content: "${label}";
        color: ${colorFns.pureWhite(props)};
        position: absolute;
        margin-top: calc(100%);
        transform: translateY(50%);
    }`
            : ''}
`;

const LeaveIcon = styled(LeaveMeeting)``;
const JoinIcon = styled(Play)``;
const CameraIcon = styled(Camera)``;
const MicrophoneIcon = styled(Microphone)``;
const ScreenShareIcon = styled(ScreenShare)``;

export default function VideoControls({
    joining,
    onLeave,
    className,
}: {
    joining?: boolean;
    onLeave?: () => void;
    className?: string;
}) {
    const meetingManager = useMeetingManager();

    const { isVideoEnabled, toggleVideo } = useLocalVideo();
    const { muted, toggleMute } = useToggleLocalMute();

    const meetingStatus = useMeetingStatus();

    const start = async () => await meetingManager.start();

    const { toggleContentShare } = useContentShareControls();

    const { isLocalUserSharing, sharingAttendeeId } = useContentShareState();

    return (
        <Row alignItems="center" justifyContent="center" itemSpacing="large" className={className}>
            {meetingStatus !== 1 && (
                <Circle onClick={start} disabled={joining} label="Join">
                    <JoinIcon />
                </Circle>
            )}
            {meetingStatus === 1 && (
                <Circle onClick={onLeave} disabled={joining} label="Leave">
                    <LeaveIcon />
                </Circle>
            )}
            <Tooltip title={meetingStatus !== 1 ? 'Cannot turn on before joining' : ''} placement="top">
                <span>
                    <Circle
                        onClick={toggleVideo}
                        disabled={joining || meetingStatus !== 1}
                        label={isVideoEnabled ? 'Camera Off' : 'Camera On'}
                    >
                        <CameraIcon disabled={!isVideoEnabled} />
                    </Circle>
                </span>
            </Tooltip>
            <Circle onClick={toggleMute} disabled={joining} label={muted ? 'Unmute' : 'Mute'}>
                <MicrophoneIcon muted={muted} />
            </Circle>
            {meetingStatus === 1 && (
                <Tooltip
                    title={!!sharingAttendeeId && !isLocalUserSharing ? 'Someone else is sharing' : ''}
                    placement="top"
                >
                    <span>
                        <Circle
                            onClick={toggleContentShare}
                            disabled={joining || (!!sharingAttendeeId && !isLocalUserSharing)}
                            label="Share Screen"
                        >
                            <ScreenShareIcon />
                        </Circle>
                    </span>
                </Tooltip>
            )}
        </Row>
    );
}
