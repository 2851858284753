// Pinched from https://css-tricks.com/image-upload-manipulation-react and migrated to new react-dropzone api
// Requires React >= 16.8.0

import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import pick from 'lodash/pick';

import { Spinner } from './Spinner';
import { Button } from '../ui';
import { uploadFile } from '../cloudinary';

export class CloudinaryUploader extends Component {
    state = {
        pending: false,
        uploadedFile: null,
        uploadedFileCloudinaryUrl: '',
    };

    onImageDrop = async files => {
        if (files.length === 0) return;
        this.setState({
            uploadedFile: files[0],
        });

        await this.handleImageUpload(files[0]);
    };

    async handleImageUpload(file) {
        const { onUploadError, onUploadStart, onUploadSuccess } = this.props;
        this.setState({
            pending: true,
        });

        onUploadStart && onUploadStart();

        await uploadFile(file)
            .then(successObj => {
                this.setState({
                    uploadedFileCloudinaryUrl: successObj.url,
                });
                const cloudinaryResponse = pick(successObj, ['cloudinaryId', 'title', 'url']);
                onUploadSuccess && onUploadSuccess(cloudinaryResponse);
            })
            .catch(message => {
                console.error(message);
                onUploadError && onUploadError(message);
            });

        this.setState({
            pending: false,
        });
    }

    render() {
        const { ctaLabel, children, accept, disabled, noHideDisabled } = this.props;
        const { pending } = this.state;
        return (
            <form style={this.props.style}>
                <div>
                    <Dropzone onDrop={this.onImageDrop} multiple={false} accept={accept} disabled={pending || disabled}>
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div {...getRootProps()}>
                                    {this.props.content
                                        ? this.props.content
                                        : (!disabled || noHideDisabled) && (
                                              <>
                                                  <input {...getInputProps()} />
                                                  {children ? (
                                                      children
                                                  ) : !pending ? (
                                                      <Button onClick={e => e.preventDefault()}>
                                                          {ctaLabel || 'Upload'}
                                                      </Button>
                                                  ) : (
                                                      <Spinner suppressMargin={true} />
                                                  )}
                                              </>
                                          )}
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </div>
            </form>
        );
    }
}
