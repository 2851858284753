import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';

import { EventContext } from '../../providers/event';
import { useUser } from '../../providers/user';

import { stripFirstSymbol, prependSymbolToAll, stripSymbolFromAll } from '../../utils/manageSymbols';
import { getBookingsForEvent, setBookingFinalSpend, setBookingInvoiceUrl } from '../../api';
import { uploadFile } from '../../cloudinary';
import pluckFileName from '../../utils/pluckFileName';

import { H2Headline } from '../ui/Headline';
import { Row, Spacer, Copy } from '../../ui';
import Form from '../Form';
import Button from '../ui/Button';
import TextButton from '../ui/Button/TextButton';
import Popover from '@material-ui/core/Popover';

import fontFns from 'fontFns';

import { fields, schema, schemaWithoutGuestrooms } from './finalSpendFormSchema';

const Container = styled.div`
    width: 530px;
    padding: 32px;
    max-height: 679px;
    flex: 1;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.brand)};
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    position: relative;
`;

const Headline = styled(H2Headline)`
    margin-bottom: 32px;
`;

const Box = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.listItemBackground)};
    height: 60px;
    margin: 12px 0;
    padding: 0 16.9px;
`;

const PlaceholderText = styled.p`
    font-size: 15px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};
`;

type UploadBoxProps = {
    fileName?: string;
    target: string;
    onUpload: any;
    link?: string;
    readOnly?: boolean;
};

const UploadBox = ({ fileName, target, onUpload, link, readOnly = false }: UploadBoxProps) => {
    const onDrop = (files: any) => onUpload(files, target);
    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    const { color: inputColor, ...inputProps } = getInputProps();
    const { color: rootColor, ...rootProps } = getRootProps();

    return (
        <Box>
            {fileName ? (
                <a href={link} target="_blank" rel="noopener noreferrer">
                    {fileName}
                </a>
            ) : (
                <PlaceholderText>No file selected</PlaceholderText>
            )}
            <input {...inputProps} />
            {!readOnly && (
                <div {...rootProps}>
                    <Button onClick={() => null} small secondary width={92}>
                        {fileName ? 'Replace' : 'Select File'}
                    </Button>
                </div>
            )}
        </Box>
    );
};

const HighlightedCopy = styled(Copy)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.highlightedText)};
`;

const FormLabel = styled(Copy)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formLabel)};
    ${fontFns.formLabel}
`;

const SmallCopy = styled(Copy)`
    font-size: 13px;
`;

const RowRight = styled(Row)`
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
`;

type FinalSpendFlyoutProps = {
    eventId: number | string;
    finalSpendAnchor: Element;
    setFinalSpendAnchor: (anchor: any) => void;
};

const FinalSpendFlyout = ({ eventId, finalSpendAnchor, setFinalSpendAnchor }: FinalSpendFlyoutProps) => {
    const { event, onEventChange } = React.useContext(EventContext);

    return (
        <Popover
            anchorEl={finalSpendAnchor}
            open={!!finalSpendAnchor}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            onClose={() => setFinalSpendAnchor(null)}
        >
            <FinalSpendContent
                eventId={eventId}
                editable={event.editable}
                onSpendTracked={() => onEventChange({ ...event, finalSpendTracked: true })}
                onClose={() => setFinalSpendAnchor(null)}
            />
        </Popover>
    );
};

export const FinalSpendContent = ({
    eventId,
    editable,
    onSpendTracked,
    onClose,
}: {
    eventId: number | string;
    editable: boolean;
    onSpendTracked: () => void;
    onClose: () => void;
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState<boolean>(true);
    const [booking, setBooking] = useState<any>({});
    const [stagedDocs, setStagedDocs] = useState<any>({ invoice1: {}, invoice2: {} });
    const { invoice1, invoice2 } = stagedDocs;
    const [finalSpendForm, setFinalSpendForm] = useState<any>({});
    const { user } = useUser();
    const teamCompliance = user.team?.compliance || {};

    const dynamicFields: any = { ...fields };

    for (let key in dynamicFields) {
        dynamicFields[key].options.disabled = !editable;
    }

    useEffect(() => {
        getBookingsForEvent(eventId)
            .then(({ bookings = [] }) => {
                // TODO: This works while we support only single booking (orders) per event. Need to update logic to handle multiple possible bookings when that becomes supported
                const firstBooking = bookings[0] || {};
                setBooking(firstBooking);
                setFinalSpendForm({ ...(prependSymbolToAll(firstBooking.finalSpend, '$') || {}) });
                setLoading(false);
            })
            .catch(() => setLoading(false));
    }, [eventId]);

    const handleStagingDoc = async (files: any, target: string) => {
        const maxMB = 41943040; // 40mb
        const file = files[0] || {};
        if (file.size > maxMB) {
            enqueueSnackbar(`File ${file.name} is too large.`, {
                variant: 'error',
            });
        } else {
            setStagedDocs({ ...stagedDocs, [target]: file });
        }
    };

    const handleChange = ({ value, field }: { value: any; field: string }) => {
        const regEx = /^\d*\.?\d*$/;
        const isNumber = regEx.test(stripFirstSymbol(value[field], '$'));
        if (isNumber) {
            const newValue = prependSymbolToAll(value, '$');
            setFinalSpendForm(newValue);
        }
    };

    const handleSubmit = async () => {
        const { id: bookingId, invoiceUrl: existingInvoice1 } = booking;
        const { invoice1, invoice2 } = stagedDocs;
        let stagedUpload1, stagedUpload2;

        try {
            // TODO: Setting an invoice and setting final spend are two seperate endpoints; look into making a unified one for less calls
            if (invoice1.name) {
                const { url: firstUrl } = await uploadFile(invoice1);
                stagedUpload1 = firstUrl;
            }

            if (invoice2.name) {
                const { url: secondUrl } = await uploadFile(invoice2);
                stagedUpload2 = secondUrl;
            }

            if (stagedUpload1 || stagedUpload2) {
                const firstInvoice = stagedUpload1 || existingInvoice1;
                const secondInvoice = stagedUpload2 || null;
                const invoiceUrls = {
                    invoiceUrl: firstInvoice || secondInvoice,
                    invoice2Url: !!firstInvoice ? secondInvoice : null,
                };

                const { booking: updatedBookingInvoice } = await setBookingInvoiceUrl(bookingId, invoiceUrls);
                setBooking(updatedBookingInvoice);
            }

            const parsedSpendForm = stripSymbolFromAll(finalSpendForm, '$');
            const { booking: updatedBookingTotals } = await setBookingFinalSpend(bookingId, parsedSpendForm);
            setBooking(updatedBookingTotals);
            enqueueSnackbar('Meeting spend and invoice were successfully saved.', {
                variant: 'info',
            });
            onSpendTracked();
            onClose();
        } catch (err) {
            enqueueSnackbar(`Error attempting to submit data`, {
                variant: 'error',
            });
        }
    };

    return (
        <Container>
            {!loading && (
                <>
                    <Headline>Final Spend Tracker</Headline>
                    {booking.id ? (
                        <>
                            <FormLabel>Upload Final Invoice</FormLabel>
                            <UploadBox
                                onUpload={handleStagingDoc}
                                link={!invoice1.name && booking.invoiceUrl}
                                fileName={invoice1.name || pluckFileName(booking.invoiceUrl)}
                                target={'invoice1'}
                                readOnly={!editable}
                            />
                            <UploadBox
                                onUpload={handleStagingDoc}
                                link={!invoice1.name && booking.invoice2Url}
                                fileName={invoice2.name || pluckFileName(booking.invoice2Url)}
                                target={'invoice2'}
                                readOnly={!editable}
                            />

                            <SmallCopy>Max file upload size: 40MB.</SmallCopy>

                            <Spacer large />

                            <Form
                                readonly={false}
                                fields={dynamicFields}
                                schema={teamCompliance?.guestroomsDisabled ? schemaWithoutGuestrooms : schema}
                                value={finalSpendForm}
                                onChange={handleChange}
                                isNested={false}
                                densePadding={false}
                            />

                            <RowRight>
                                <TextButton onClick={onClose} secondary>
                                    Cancel
                                </TextButton>

                                {editable && (
                                    <TextButton
                                        onClick={handleSubmit}
                                        disabled={!booking.invoiceUrl && !(invoice1.name || invoice2.name)}
                                    >
                                        Save
                                    </TextButton>
                                )}
                            </RowRight>
                        </>
                    ) : (
                        <HighlightedCopy>
                            Spend tracking becomes available once a venue has been booked.
                        </HighlightedCopy>
                    )}
                </>
            )}
        </Container>
    );
};

export default FinalSpendFlyout;
