import React from 'react';
import styled from 'styled-components';

import { Column, Row } from 'ui';
import { H2Headline } from 'components/ui/Headline';

import colorFns from 'colorFns';

const Content = styled(Column)`
    min-width: 500px;
`;

const PlaybookTags = styled(Row)`
    padding-top: 6px;
    margin: -6px;
    > * {
        margin: 6px;
    }
    flex-wrap: wrap;
`;

const PlaybookTag = styled.div<{ background?: string }>`
    border-radius: 3px;
    padding: 5px 20px;
    cursor: default;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid transparent;
    border-color: ${({ background, theme: { getColor, EColors } }) => background ?? getColor(EColors.primaryAction)};
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

const Purpose = styled.p`
    padding: 16px 18px;
    margin: 0;
    border-radius: 8px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.brand)};
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

const Heading = styled(H2Headline)`
    color: ${colorFns.secondaryTextAction};
`;

interface CoverImageProps {
    src: string;
}

export const Tags = ({ tags }: { tags?: { id: number; name?: string; color?: string }[] }) =>
    tags && tags?.length > 0 ? (
        <PlaybookTags>
            {tags.map(({ name, color }) => (name ? <PlaybookTag background={color}>{name}</PlaybookTag> : null))}
        </PlaybookTags>
    ) : null;

const PlaybookDetail = ({
    playbook,
}: {
    playbook: {
        name?: string;
        purpose?: string;
        image?: string;
        description?: string;
        tags?: { id: number; name?: string; color?: string }[];
    };
}) => {
    return (
        <Content itemSpacing="large">
            {playbook.purpose && (
                <div>
                    <Heading>Objective</Heading>
                    <Purpose>{playbook.purpose}</Purpose>
                </div>
            )}
            <div>
                <Heading>Description</Heading>
                <p>{playbook.description}</p>
            </div>
        </Content>
    );
};

export default PlaybookDetail;
