import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as DownArrow } from '../../../images/icons/down-arrow.svg';
import { ReactComponent as CheckMark } from '../../../images/icons/check-mark.svg';
import fontFns from 'fontFns';

const StyledForm = styled.form`
    position: relative;
    display: flex;
    flex-direction: column;

    label {
        margin: 10px 0 15px;
        font-size: 15px;
        line-height: 1;
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formLabel)};
        ${fontFns.formLabel}
    }
`;

type wrapperProps = {
    disabled?: boolean;
    width?: string;
    isPlaceholder: boolean;
    isOpen: boolean;
};

const StyledWrapper = styled.div<wrapperProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    border: solid 1px ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
    border-radius: 8px;
    height: 36px;
    min-width: 103px;
    width: ${({ width }) => (width ? `${width}px` : 'auto')};
    outline: none;
    font-size: 15px;
    font-weight: 400;
    color: ${({ isPlaceholder, theme: { getColor, EColors } }) =>
        isPlaceholder ? getColor(EColors.darkGrey) : getColor(EColors.darkestGrey)};
    padding: 0 16px;
    cursor: pointer;

    ${({ isOpen, theme: { getColor, EColors } }) =>
        isOpen && `box-shadow: 0 2px 4px 0 ${getColor(EColors.pureBlack, 0.06)};`}
    ${({ disabled, theme: { getColor, EColors } }) =>
        disabled &&
        `
        pointer-events: none;
        color: ${getColor(EColors.pureBlack, 0.38)};
    `}
`;

const StyledMenu = styled.div`
    z-index: 9999;
    position: absolute;
    top: 86px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    border-radius: 8px;
    box-shadow: 0 2px 44px 0 ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack, 0.1)};
    min-width: 180px;
    max-height: 180px;
    overflow-y: scroll;

    ul {
        list-style-type: none;
        padding: 0;

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            padding: 6px 16px 6px 24px;

            &:hover {
                background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.dropdownItemHover)};
            }
        }
    }
`;

type dropdownProps = {
    disabled?: boolean;
    label: string;
    value: any;
    placeholder: string;
    options: any;
    targetValue?: any;
    useCheckMark?: boolean;
    onClick: (dropdownItem: { name: string; value: string; id: number }) => void;
};

const DropdownMenu = ({ disabled, label, value, placeholder, options, useCheckMark, onClick }: dropdownProps) => {
    const formRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleClickOutside = (event: any) => {
        // @ts-ignore
        if (formRef.current && !formRef.current.contains(event.target)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    const handleOnClick = (option: any) => {
        // @ts-ignore
        onClick(option);
        setOpen(false);
    };

    return (
        <StyledForm ref={formRef}>
            <input hidden id={label} value={value} disabled={disabled} />
            {label && <label htmlFor={label}>{label}</label>}
            <StyledWrapper
                width={undefined}
                onClick={() => setOpen(!open)}
                isPlaceholder={!value}
                isOpen={open}
                disabled={disabled}
            >
                {value || placeholder || ''}
                <DownArrow />
            </StyledWrapper>
            {open && (
                <StyledMenu>
                    <ul>
                        {options.map((option: any, i: number) => (
                            <li key={option.id || i} onClick={() => handleOnClick(option)}>
                                {option.name}
                                {!useCheckMark && value === option.name && <CheckMark />}
                            </li>
                        ))}
                    </ul>
                </StyledMenu>
            )}
        </StyledForm>
    );
};

export default DropdownMenu;
