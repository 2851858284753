import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';

import { createEvent } from 'api/events';

import { Spacer, Copy, Row, Column } from 'ui';
import { H2Headline } from 'components/ui/Headline';
import TextButton from 'components/ui/Button/TextButton';
import { SpinnerOverlay } from 'components/Spinner';

import { Dialog, DialogContent } from '@material-ui/core';

import Form from 'components/Form';
import { EventForm, EventCreateFields, schema, schemaWithTemplate, toBizlyEvent, validateForm } from './formSchema';

const WideDialogContent = styled(DialogContent)`
    width: 640px;
    padding: 32px !important;
`;

const SpacedCopy = styled(Copy)`
    line-height: 1.6;
`;

export default function EventCreateModal({
    template,
    loadingTemplates,
    defaultFormValues,
    templateOptions,
    onClose,
}: {
    template?: Bizly.EventTemplate;
    loadingTemplates?: boolean;
    defaultFormValues?: Partial<{
        name: string;
        costCenter: string;
        cventId: string;
        type: string;
        templateId: number;
    }>;
    templateOptions?: Bizly.EventTemplate[];
    onClose: () => void;
}) {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = React.useState(false);
    const locationData =
        template?.location?.name && template?.location?.googlePlaceId
            ? {
                  location: template?.location?.name,
                  googlePlaceId: template?.location?.googlePlaceId,
              }
            : null;
    const [data, setData] = React.useState<EventForm>({
        ...(locationData && { city: locationData }),
        ...(template ? { templateId: template.id } : {}),
        ...defaultFormValues,
    });
    const memFormFields = useMemo(() => EventCreateFields(locationData, templateOptions), [
        locationData,
        templateOptions,
    ]);

    const updateData = ({ value }: { value: EventForm }) => setData(value);

    const onSubmit = async () => {
        if (!validateForm(data)) {
            return enqueueSnackbar('All fields are required.', { variant: 'error' });
        }

        setLoading(true);
        try {
            const { event } = await createEvent(toBizlyEvent(data));
            history.push(`/event/${event.id}`);
            onClose();
        } catch {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            setLoading(false);
        }
    };

    return (
        <Dialog open maxWidth="xl" onBackdropClick={loading ? undefined : onClose}>
            <WideDialogContent>
                <Column itemSpacing="small">
                    <H2Headline>Create a new {template?.name ? template?.name + ' ' : ''}meeting</H2Headline>
                    <SpacedCopy>
                        Almost there! To create a new meeting, we need some basic information to get you started.
                        <br />
                        You'll be able to view and edit this later if something changes.
                    </SpacedCopy>
                </Column>

                <Spacer large />

                <Form
                    fields={memFormFields}
                    schema={!!templateOptions?.length ? schemaWithTemplate : schema}
                    value={data}
                    onChange={updateData}
                />
                <Spacer largest />

                <Row justifyContent="flex-end" itemSpacing="smallish">
                    <TextButton onClick={onClose} secondary>
                        Cancel
                    </TextButton>
                    <TextButton onClick={onSubmit}>Create</TextButton>
                </Row>

                {(loading || loadingTemplates) && <SpinnerOverlay />}
            </WideDialogContent>
        </Dialog>
    );
}
