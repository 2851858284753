import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { Switch, Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { Provider as SlotFillProvider } from 'react-slot-fill';
import ReactGA from 'react-ga';
import { createLocation } from 'history';
import { parse } from 'query-string';
import styled from 'styled-components/macro';

import { authProposalForm } from 'api';
import { takeFirstIfArray } from './util';
import { removeCookie } from 'utils/tokens';
import mixpanel from 'utils/mixpanel';
import EventProvider from 'providers/event';
import EventOptionsProvider from 'providers/event-options';
import { useUser, UserProvider } from 'providers/user';
import { ETeams } from 'theme';
import { BizlyThemeOverride } from 'ThemedApp';
import { LoadEventTemplates } from 'stores/eventTemplates';
import { LoadContacts } from 'stores/contacts';
import { LoadContactGroups } from 'stores/contact-groups';

import Homebase from 'pages/Homebase';
import HealthSafety from 'pages/HealthSafety';
import { Login, ActivateNewUser } from 'pages/Login';
import Event from 'pages/Event';
import Meetings from 'routes/Meetings';
import RSVP from 'pages/RSVP';
import Survey from 'pages/Survey';
import { PageNotFound } from 'pages/PageNotFound';
import ProposalForm from 'pages/ProposalForm';
import { ProfileForm } from 'pages/Login';
import AuthenticationPage from 'pages/Authentication';
import Integrations from 'pages/Integrations';
import TeamSettings from 'pages/TeamSettings';

import TemplatesRoute from 'routes/Templates';

import { Column, SmallerCopy } from 'ui';
import { SpinnerOverlay } from 'components/Spinner';
import Alerts from 'components/Alerts/Alerts';
import TeamSelector from 'components/TeamSelector';
import colorFns from 'colorFns';

const Logout = ({ clearUser }: { clearUser: () => void }) => {
    useEffect(() => {
        removeCookie();
        clearUser();

        mixpanel.track('Logged out');
        mixpanel.reset();
    }, [clearUser]);

    return <Redirect to="/sign-in" />;
};

const GOOGLE_ANALYTICS_ID =
    process.env.REACT_APP_ENV === 'prod'
        ? process.env.REACT_APP_GOOGLE_ANALYTICS_ID_PRODUCTION
        : process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

const DemoRibbon = styled(SmallerCopy)`
    position: fixed;
    z-index: 10000;
    top: 0;
    right: 286px;
    color: ${colorFns.pureWhite};
    background-color: ${colorFns.accentedHeadline};
    padding: 16px 20px;
    border-radius: 0 0 18px 18px;
    box-shadow: 0 0 1px ${colorFns.pureWhite};
`;

const RespectfulRedirect = () => {
    const location = useLocation();
    const { redirect } = takeFirstIfArray(parse(location.search));

    return <Redirect to={redirect ?? '/'} />;
};

const EventsRedirect = () => {
    const location = useLocation();

    return <Redirect to={{ ...location, pathname: location.pathname.replace('events', 'meetings') }} />;
};

const AuthBlockPassed = () => {
    const { clearUser, isInDemoMode, toggles, user, isTeamAdmin } = useUser();

    return (
        <Column style={{ height: '100%', minWidth: 1200 }}>
            {isInDemoMode && <DemoRibbon>Demo</DemoRibbon>}

            <LoadContacts />
            <LoadContactGroups />
            <Switch>
                <Route exact path={['/', '/team-picker']}>
                    <LoadEventTemplates />
                    <Homebase />

                    <Switch>
                        <Route path="/team-picker">
                            <TeamSelector initOpen />
                        </Route>
                    </Switch>
                </Route>
                <Route path="/profile">
                    <ProfileForm />
                </Route>
                <Route path={['/login', '/sign-in', '/sign-up']}>
                    {user.email ? <RespectfulRedirect /> : <AuthenticationPage />}
                </Route>
                <Route path="/logout">
                    <Logout clearUser={clearUser} />
                </Route>
                <Route exact path="/health-safety" component={HealthSafety} />

                <Route
                    path="/event/:eventId"
                    render={(props: RouteComponentProps<{ eventId: string }>) => (
                        <SlotFillProvider>
                            <EventOptionsProvider eventId={props.match.params.eventId}>
                                <EventProvider eventId={props.match.params.eventId}>
                                    <Event {...props} />
                                </EventProvider>
                            </EventOptionsProvider>
                        </SlotFillProvider>
                    )}
                />
                <Route path="/events/:eventId/rsvp">
                    <BizlyThemeOverride>
                        <RSVP />
                    </BizlyThemeOverride>
                </Route>
                <Route path="/events/:eventId/survey">
                    <BizlyThemeOverride>
                        <Survey />
                    </BizlyThemeOverride>
                </Route>

                <Route path="/events">
                    <EventsRedirect />
                </Route>

                <Route path="/meetings">
                    <Meetings />
                </Route>

                <Route path="/playbooks">
                    <TemplatesRoute />
                </Route>

                {toggles.gate.integrations && (
                    <Route path="/integrations">
                        <Integrations />
                    </Route>
                )}
                {isTeamAdmin && (
                    <Route path="/settings/team">
                        <TeamSettings />
                    </Route>
                )}
                <Route component={PageNotFound} />
            </Switch>
            <Alerts />
        </Column>
    );
};

const RedirectToSignIn = () => {
    const history = useHistory();

    const curPath = encodeURIComponent(history.location.pathname + history.location.search);
    return <Redirect to={`/sign-in?redirect=${curPath}`} />;
};

const AuthBlocked = () => (
    <Column style={{ height: '100%' }}>
        <Switch>
            <Route exact path="/accept-team-invite">
                <ActivateNewUser />
            </Route>
            <Route path="/login">
                <Login />
            </Route>
            <Route path="/events/:eventId/rsvp">
                <RSVP userAnonymous />
            </Route>
            <Route path="/events/:eventId/survey">
                <Survey />
            </Route>
            {process.env.REACT_APP_SIGN_UP_V2 === 'true' ? ( // TODO: Remove this env check once Sign Up is available in all environments
                <Route path={['/sign-in', '/sign-up']}>
                    <AuthenticationPage />
                </Route>
            ) : (
                <Route path="/sign-in">
                    <AuthenticationPage />
                </Route>
            )}
            <Route>
                <RedirectToSignIn />
            </Route>
        </Switch>
    </Column>
);

export type TResult = { code: number; message: string };
export const useAuthLocation = () => useLocation<{ error: TResult | undefined }>();

const AuthProposalForm = ({ token, onComplete }: { token: string; onComplete: (result: TResult) => void }) => {
    const [venueId] = React.useState<string>(takeFirstIfArray(parse(useLocation().search)).venueId || '');
    const { proposalId } = useParams<{ proposalId: string }>();

    useEffect(() => {
        const performAuth = async () => {
            try {
                const response = await authProposalForm(venueId, proposalId, token);
                onComplete(response);
            } catch (e) {
                onComplete(e);
            }
        };

        performAuth();
    }, [venueId, proposalId, token, onComplete]);

    return <SpinnerOverlay />;
};

const Auth = () => {
    const queryParams = takeFirstIfArray(parse(useLocation().search));
    const intendedPage = decodeURI(queryParams.redirect || '');
    const intendedLocation = createLocation(intendedPage);

    const token = queryParams.token || '';

    const [authResult, setAuthResult] = React.useState<TResult | null>(null);

    const errorLocation = createLocation(intendedPage, { error: authResult });

    if (authResult === null) {
        return (
            <Switch location={intendedLocation}>
                <Route path="/proposals/:proposalId">
                    <AuthProposalForm token={token} onComplete={setAuthResult} />
                </Route>
            </Switch>
        );
    }

    return authResult.code === 200 ? (
        <Redirect to={intendedLocation} />
    ) : (
        <Switch location={errorLocation}>
            <Route>
                <App />
            </Route>
        </Switch>
    );
};

const AppWithUser = () => {
    const { user } = useUser();

    return user.loaded ? user.token ? <AuthBlockPassed /> : <AuthBlocked /> : <SpinnerOverlay />;
};

type TApp = {
    setTheme?: (team: ETeams) => void;
};

const App = ({ setTheme }: TApp) => {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        // https://reacttraining.com/react-router/web/guides/scroll-restoration
        if (history.action !== 'POP' && history.action !== 'REPLACE') {
            window.scrollTo(0, 0);
        }

        // Track a pageview in Google Analytics
        if (GOOGLE_ANALYTICS_ID) {
            ReactGA.pageview(location.pathname + location.search);
        }
    }, [location, history.action]);

    return (
        <Switch>
            <Route path="/auth">
                <Auth />
            </Route>
            <Route path="/proposals/:proposalId">
                <ProposalForm />
            </Route>

            <Route>
                <UserProvider setTheme={setTheme}>
                    <AppWithUser />
                </UserProvider>
            </Route>
        </Switch>
    );
};

export default App;
