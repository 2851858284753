import { getServiceProviderId } from 'utils/virtualMeeting';
import { get, post, put, deleteCall } from '.';

export const getAllVirtualMeetings = (eventId: number | string) => get(`events/${eventId}/virtual-meetings`);

export const createVirtualMeeting = (eventId: number | string, data: Partial<Bizly.VirtualMeeting>) =>
    post(`events/${eventId}/virtual-meetings`, {
        ...data,
        serviceProvider: data.link ? { id: getServiceProviderId(data.link) } : {},
    });

export const createVirtualMeetingFromConnector = (
    eventId: number | string,
    data: Partial<Bizly.VirtualMeeting> & {
        connectorType: string;
        startsAt?: string;
        endsAt?: string;
        timeZone?: string;
    }
) => post(`events/${eventId}/virtual-meetings`, data);

export const updateVirtualMeeting = (
    eventId: number | string,
    data: Partial<Bizly.VirtualMeeting>,
    virtualMeetingId: number
) =>
    put(`events/${eventId}/virtual-meetings/${virtualMeetingId}`, {
        ...data,
        serviceProvider: data.link ? { id: getServiceProviderId(data.link) } : {},
    });

export const deleteVirtualMeeting = (eventId: number | string, virtualMeetingId: number) =>
    deleteCall(`events/${eventId}/virtual-meetings/${virtualMeetingId}`);
