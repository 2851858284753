import React from 'react';
import styled from 'styled-components';

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

const Icon = styled.svg`
    display: block;
    fill: none;
    stroke: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    stroke-width: 2px;
`;

type StyledCheckboxProps = {
    checked: boolean;
    indeterminate: boolean;
};

const StyledCheckbox = styled.div<StyledCheckboxProps>`
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: solid 1px ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
    background-color: ${({ checked, theme: { getColor, EColors } }) =>
        checked ? getColor(EColors.primaryAction) : getColor(EColors.pureWhite)};

    cursor: pointer;

    ${Icon} {
        visibility: ${props => (props.checked ? 'visible' : 'hidden')};
    }
`;

const CheckboxContainer = styled.div`
    display: inline-block;
    vertical-align: middle;
`;

const Checkbox = ({ checked, indeterminate, onChange, ...props }: any) => {
    return (
        <CheckboxContainer onClick={onChange}>
            <HiddenCheckbox type="checkbox" onChange={onChange} indeterminate={indeterminate} {...props} />
            <StyledCheckbox checked={checked} indeterminate={indeterminate}>
                <Icon viewBox="0 0 24 24">
                    <polyline points="20 6 9 17 4 12" />
                </Icon>
            </StyledCheckbox>
        </CheckboxContainer>
    );
};

export default Checkbox;
