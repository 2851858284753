import React from 'react';
import styled from 'styled-components/macro';

import StepsColumn from './StepsColumn';
import { Copy as UICopy, Spacer } from '../../ui';
import { ReactComponent as FileIcon } from '../../images/icons/file.svg';
import Button from '../ui/Button';
import pluckFileName from '../../utils/pluckFileName';

const Copy = styled(UICopy)<any>`
    font-size: 15px;
    line-height: 1.47;

    ul {
        padding-inline-start: 1.2em;
    }
`;

const DocContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    width: 324px;
`;

const DocRow = styled.div<{ hasDoc: boolean }>`
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 8px;
    justify-content: space-between;
    margin-bottom: 12px;

    div {
        display: flex;
        align-items: center;
        margin-left: 16px;

        p {
            font-size: 15px;
        }

        svg {
            color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryDisplayIcon)};
        }
    }

    ${({ hasDoc, theme: { getColor, EColors } }) =>
        hasDoc &&
        `
    background-color: ${getColor(EColors.listItemBackground)};

    button {
      margin-right: 16px;
    }
  `}
`;

type UploadSignedDocsProps = {
    number: number;
    openUploadModal: () => void;
    existingDocs: {
        contract1?: string;
        contract2?: string;
    };
    copy: string;
    readonly: boolean;
};

const defaultCopy = 'Once both parties have signed, upload the final contract and your venue will be marked as booked.';

const UploadSignedDocs = ({
    number,
    openUploadModal,
    existingDocs,
    copy = defaultCopy,
    readonly,
}: UploadSignedDocsProps) => {
    const hasDoc = !!existingDocs.contract1;
    return (
        <StepsColumn number={number} headline={'Upload signed documents'}>
            <Copy dangerouslySetInnerHTML={{ __html: copy }} />
            <Spacer />
            <DocContainer>
                <DocRow hasDoc={hasDoc}>
                    {existingDocs.contract1 && (
                        <div>
                            <FileIcon />
                            <a href={existingDocs.contract1} target="_blank" rel="noopener noreferrer">
                                {pluckFileName(existingDocs.contract1)}
                            </a>
                        </div>
                    )}
                    {!readonly && (
                        <Button width={hasDoc ? 81 : 99} onClick={openUploadModal} secondary={hasDoc}>
                            {hasDoc ? 'Replace' : 'Upload'}
                        </Button>
                    )}
                </DocRow>
                {existingDocs.contract2 && (
                    <DocRow hasDoc={true}>
                        <div>
                            <FileIcon />
                            <a href={existingDocs.contract2} target="_blank" rel="noopener noreferrer">
                                {pluckFileName(existingDocs.contract2)}
                            </a>
                        </div>
                        {!readonly && (
                            <Button width={81} onClick={openUploadModal} secondary>
                                Replace
                            </Button>
                        )}
                    </DocRow>
                )}
            </DocContainer>
        </StepsColumn>
    );
};

export default UploadSignedDocs;
