import React from 'react';
import styled, { css } from 'styled-components/macro';

import { useHistory, useLocation } from 'react-router';
import { Redirect, Route, Switch, RouteComponentProps } from 'react-router-dom';

import { LoadSchedule } from 'stores/schedule';
import { useEvent } from 'providers/event';
import { useUser } from 'providers/user';

import { PageNotFound } from './PageNotFound';
import { Attendees } from './Attendees';
import Planner from './Planner';
import OldPlanner from './OldPlanner';
import VenuePage from './Venue';
import Communication from './Communication';
import EditParcel from './EditParcel';
import EditSchedule from './EditSchedule';
import ScheduleCalendarPage from './Calendar';
import EventDashboard from 'pages/EventDashboard';

import { Column, MaxWidthRow, Row } from 'ui';
import EventHeader, { getLast } from 'components/EventHeader';
import { Spinner } from 'components/Spinner';
import SideNav, { SIDE_NAV_WIDTH } from 'components/SideNav';
import GenericNavButtons from 'components/GenericNavButtons';
import { ReactComponent as RegistrationIconSvg } from 'images/icons/registration.svg';
import colorFns from 'colorFns';

const CenteredRow = styled(Row)`
    justify-content: center;

    flex-grow: 1;
    flex-shrink: 0;
    min-height: 0;
`;

export const PAGE_PADDING = 36;

const PaddedContent = styled(MaxWidthRow)`
    display: flex;
    width: 100%;
    padding: ${PAGE_PADDING}px;
    box-sizing: content-box;

    align-items: flex-start;

    min-height: calc(100% - 72px);
    height: calc(100% - 72px);
`;

const FullWidthAndHeight = styled(Column)`
    flex: 1 1 0;
    display: flex;
    justify-content: center;

    width: 100%;
`;

const HideableColumn = styled(Column)<{ hidden: boolean }>`
    flex-shrink: 0;
    min-height: 100%;
    width: 100%;

    ${props =>
        props.hidden
            ? `
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: -1;
    opacity: 0; /* without this, certain things still show */
    visibility: hidden; /* performance reasons */

    pointer-events: none
  `
            : ``}
`;

const EVENT_HEADER_BLACKLIST = ['/communication/*', '/guest-list/*', '/agenda/*'];

const Header = ({
    match,
    loading,
    noBackButton,
    pageOffset,
    maxSearchWidth,
}: {
    match: RouteComponentProps['match'];
    loading: boolean;
    noBackButton?: boolean;
    pageOffset?: number;
    maxSearchWidth?: number;
}) => (
    <Switch>
        {EVENT_HEADER_BLACKLIST.map(path => (
            <Route key={path} path={match.path + path} component={() => null} />
        ))}
        <Route
            render={props => (
                <EventHeader
                    loading={loading}
                    {...props}
                    noBackButton={noBackButton}
                    pageOffset={pageOffset}
                    maxSearchWidth={maxSearchWidth}
                />
            )}
        />
    </Switch>
);

const Event = ({
    location,
    match,
    history,
    backgroundLocation,
}: RouteComponentProps<{ eventId: string }> & {
    backgroundLocation?: RouteComponentProps['location'] | false;
}) => {
    const { loading, event, error, suggestedVenues, updateAttendeesCount } = useEvent();
    const { toggles } = useUser();

    // let the foreground location handle loading/error
    if (backgroundLocation && (error || loading)) {
        return null;
    }

    if (error) {
        return <Route component={PageNotFound} />;
    }

    if (loading) {
        return (
            <FullWidthAndHeight>
                <Spinner />
            </FullWidthAndHeight>
        );
    }
    const isEventDashboard = location.pathname === `/event/${event.id}`;

    const viewVenueListing = (venueId: number) =>
        history.push(`/event/${event.id}/venue/listing/${venueId}`, {
            shouldGoBack: true,
            backgroundLocation: location,
        });
    const viewInquiries = () => history.push(`/event/${event.id}/venue/inquiries`);

    const viewProposal = (proposalId: number) => {
        let url = `/event/${event.id}/venue/proposal/${proposalId}`;
        history.push(url, {
            shouldGoBack: true,
        });
    };

    return (
        <HideableColumn hidden={Boolean(backgroundLocation)}>
            <Header match={match} loading={false} noBackButton pageOffset={SIDE_NAV_WIDTH} maxSearchWidth={360} />
            {!isEventDashboard && (
                <MaxWidthRow>
                    {/* TODO: repair from migration from stencil */}
                    {/* <Summary event={event} /> */}
                </MaxWidthRow>
            )}
            <CenteredRow>
                <LoadSchedule />
                <Switch {...(backgroundLocation ? { location: backgroundLocation } : {})}>
                    <Route path={`${match.path}/venue`}>
                        <VenuePage
                            event={event}
                            suggestedVenues={suggestedVenues}
                            viewVenueListing={viewVenueListing}
                            viewInquiries={viewInquiries}
                            viewProposal={viewProposal}
                            sideMargin={68}
                            pageOffset={SIDE_NAV_WIDTH}
                        />
                    </Route>

                    <Route exact path="/event/:eventId">
                        <EventDashboard />
                    </Route>

                    <Route
                        render={() => (
                            <PaddedContent>
                                <Switch {...(backgroundLocation ? { location: backgroundLocation } : {})}>
                                    <Route exact path="/event/:eventId/agenda">
                                        <LoadSchedule />
                                        <ScheduleCalendarPage />
                                    </Route>
                                    <Route exact path="/event/:eventId/planner">
                                        {toggles.gate.useNewPlanner ? <Planner /> : <OldPlanner />}
                                    </Route>
                                    {toggles.gate.useActivityCalendar && (
                                        <Route exact path="/event/:eventId/agenda/:agendaId">
                                            <EditSchedule />
                                        </Route>
                                    )}
                                    <Route path="/event/:eventId/guest-list">
                                        <Attendees />
                                    </Route>
                                    <Route exact path="/event/:eventId/communication">
                                        <Communication />
                                    </Route>
                                    <Route exact path="/event/:eventId/communication/create/:type">
                                        {event.editable ? (
                                            <EditParcel event={event} updateAttendeesCount={updateAttendeesCount} />
                                        ) : (
                                            <Redirect to={`/event/${event.id}/communication`} />
                                        )}
                                    </Route>
                                    <Route exact path="/event/:eventId/communication/edit/:type/:parcelId">
                                        <EditParcel event={event} updateAttendeesCount={updateAttendeesCount} />
                                    </Route>
                                    <Route>
                                        <PageNotFound eventId={event.id} />
                                    </Route>
                                </Switch>
                            </PaddedContent>
                        )}
                    />
                </Switch>
            </CenteredRow>
        </HideableColumn>
    );
};

const iconStyles = css<{ active?: boolean }>`
    height: 16px;
    width: 15px;
    color: ${colorFns.pureWhite};
    fill: ${colorFns.pureWhite};
    margin-left: 18px;
    margin-right: 9px;
`;

const RegistrationIcon = styled(RegistrationIconSvg)`
    ${iconStyles}
`;

export default function EventPage(props: any) {
    const location = useLocation();
    const backgroundLocation = location.state && location.state.backgroundLocation;
    const { event } = useEvent();
    const history = useHistory<{ shouldGoBack?: boolean }>();

    const content = (
        <>
            {/* We must be careful not to conditionally render this base event and just change it's props so we don't lose it's state */}
            <Event {...props} backgroundLocation={backgroundLocation} />
            {backgroundLocation && <Event {...props} />}
        </>
    );

    const cameFromApp = history.action === 'PUSH';
    const shouldGoBack = history.location.state && history.location.state.shouldGoBack;

    const buttonProps = shouldGoBack ? { onBack: history.goBack } : { backTo: getLast(history.location) };
    const commProps = shouldGoBack || cameFromApp ? { onBack: history.goBack } : { backTo: `/meetings/${event.id}` };

    return (
        <SideNav
            fillWidth
            fullWidth
            routeChildren={
                <Switch>
                    <Route path="/event/:eventId/communication/*/invite">
                        <GenericNavButtons
                            {...commProps}
                            links={[
                                {
                                    key: 'Edit Invite',
                                    active: true,
                                    children: (
                                        <>
                                            <RegistrationIcon active />
                                            Edit Invite
                                        </>
                                    ),
                                },
                            ]}
                        />
                    </Route>
                    <Route path="/event/:eventId/communication/*/note">
                        <GenericNavButtons
                            {...commProps}
                            links={[
                                {
                                    key: 'Edit Note/Survey',
                                    active: true,
                                    children: (
                                        <>
                                            <RegistrationIcon active />
                                            Edit Note/Survey
                                        </>
                                    ),
                                },
                            ]}
                        />
                    </Route>
                    <Route>
                        <GenericNavButtons {...buttonProps} />
                    </Route>
                </Switch>
            }
        >
            {content}
        </SideNav>
    );
}
