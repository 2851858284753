import React from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router';

import { ReactComponent as CircleIcon } from 'images/icons/circle.svg';

import { useEventTemplateTags } from 'stores/event-template-tags';
import { getTemplatesUrlFilters, setTemplatesUrlFilters } from 'components/Templates/utils';

import { Column } from 'ui';
import { Spinner } from 'components/Spinner';
import TextButton from 'components/ui/Button/TextButton';
import Form from 'components/Form';
import colorFns from 'colorFns';

const CircularBubble = styled(CircleIcon)<{ checked?: boolean }>`
    color: ${colorFns.lightGrey};
    stroke: ${colorFns.grey};

    ${({ checked }) =>
        checked &&
        `
        color: currentColor;
        stroke: currentColor;
        `}
`;

export default function FilterList({ readonly, disabled }: { readonly?: boolean; disabled?: boolean }) {
    const tagsStore = useEventTemplateTags();
    const location = useLocation();
    const [filterValue, setFilterValue] = React.useState(getTemplatesUrlFilters(location));
    React.useEffect(() => setFilterValue(getTemplatesUrlFilters(location)), [location]);

    const fields = {
        categories: {
            type: 'multiselect',
            options: {
                options:
                    tagsStore.tags?.map(tag => ({
                        ...tag,
                        iconStyle: { color: tag.color },
                    })) ?? [],
                perRow: 1,
                customIcon: <CircularBubble />,
                customIconChecked: <CircularBubble checked />,
            },
        },
    };
    const schema = [{ fields: ['categories'], spacing: 'default' }];
    const value = { categories: filterValue.tags };
    const history = useHistory();
    const onChange = ({ value }: { value: { categories: number[] } }) => {
        const updatedFilters = { tags: value.categories };
        setFilterValue(updatedFilters);
        setTemplatesUrlFilters(history, curFilters => ({ ...curFilters, ...updatedFilters }));
    };

    const onClear = () => {
        setFilterValue(curFilters => ({ ...curFilters, tags: [] }));
        setTemplatesUrlFilters(history, curFilters => ({ ...curFilters, tags: [] }));
    };

    return !tagsStore.loaded ? (
        <Spinner />
    ) : (
        <Column alignItems="flex-start" itemSpacing="xsmall">
            {filterValue?.tags?.length ? <TextButton onClick={onClear}>Clear</TextButton> : null}
            <Form
                fields={fields}
                schema={schema}
                value={value}
                onChange={onChange}
                readonly={readonly}
                disabled={disabled}
            />
        </Column>
    );
}
