import { EditorState, ContentState, convertFromHTML, CompositeDecorator } from 'draft-js';
import { stateToHTML, Options } from 'draft-js-export-html';

import linkDecorator from './linkDecorator';

declare module 'draft-js-export-html' {
    // Workaround until draft-js-export-html releases PR: https://github.com/sstur/draft-js-utils/pull/215
    export function stateToHTML(
        content: ContentState,
        options?: Omit<Options, 'defaultBlockTag'> & { defaultBlockTag?: string | null }
    ): string;
}

const stateToHtmlOptions = {
    /*
        defaultBlockingTag defaults to 'p'
        DraftJS combines white spaces (including BR tags) that are wrapped in 'p' tags
        This removes the 'p' wrapping each block, allowing whitespaces to be retained.
    */

    defaultBlockTag: null,
};

const scrubHTML = (html: string) =>
    html
        .replace(/&nbsp;/g, '') // Strip non breaking spaces
        // Draft JS relies on P tags to break up lines; Since removing P tags, there aren't enough BR tags to accurately represent whitespace
        .replace(/<br>/g, '') // We remove all instances of initial BR tags
        .replace(/\n/g, '<br>'); // And use the newlines to calculate the correct number of BR tags required

export const contentToHtml = (editorState: EditorState) => {
    const contentState = editorState.getCurrentContent();
    return scrubHTML(stateToHTML(contentState, stateToHtmlOptions));
};

const htmlToContent = (htmlString: string) => {
    const { contentBlocks, entityMap } = convertFromHTML(htmlString);
    return ContentState.createFromBlockArray(contentBlocks, entityMap);
};

const decorator = new CompositeDecorator([linkDecorator]);

export const getInitialEditorState = (initialValue?: string) => {
    if (initialValue) {
        const contentState = htmlToContent(initialValue);
        return EditorState.createWithContent(contentState, decorator);
    } else {
        return EditorState.createEmpty(decorator);
    }
};

export const isEmptyRichText = (html?: string | null) => !htmlToContent(html || '').getPlainText();
