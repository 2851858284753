import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import { ReactComponent as CloseIcon } from 'images/icons/close.svg';
import { ReactComponent as VenuePin } from 'images/icons/venue_pin.svg';
import colorFns from 'colorFns';

import { Column, Row } from 'ui';
import CircleCheckIcon from 'components/CircleCheckIcon';
import { VenueCardPopover, REJECTED_STATUSES } from 'components/VenueCard';
import { TPillTypes } from './VenuePill';
import TileInfo from './TileInfo';

export type TTileFormats = 'tile' | 'thumb' | 'default' | 'proposalThumb' | 'proposal' | 'featured';

type TTileWrapperProps = {
    width?: string;
    minWidth?: string;
    padding?: string;
};
const TileWrapper = styled.div.attrs(() => ({
    'data-testid': 'venue-thumb',
}))<TTileWrapperProps>`
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;

    ${props =>
        props.width &&
        `
    width: ${props.width};
    min-width: ${props.width};
    max-width: ${props.width};
    `}
    ${props =>
        props.minWidth &&
        `
    min-width: ${props.minWidth};
    `}
    ${props =>
        props.padding &&
        `
    padding: ${props.padding};
    `}
`;

const FixedRatio = styled.div<{ backgroundColor?: Themed.Color; fixedRatio?: string }>`
    position: relative;
    padding: 0;

    ${({ backgroundColor, theme: { getColor, EColors } }) =>
        `background-color: ${backgroundColor || getColor(EColors.softAccentedBackground)};`}
    border-radius: 8px;

    ${props =>
        props.fixedRatio
            ? `
      padding-top: ${props.fixedRatio};
      width: 100%;
    `
            : `
    `}
`;

type RoundedImageProps = {
    url?: string;
    width: string | number;
    height: string | number;
    fixedRatio: string;
    onClick?: (venueId: number) => void;
};

const RoundedImage = styled(FixedRatio)<Partial<RoundedImageProps>>`
    background-image: url(${props => props.url});
    background-size: cover;

    ${props =>
        props.onClick
            ? `
    cursor: pointer;
    `
            : ``}

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 8px;
        background-image: linear-gradient(
            to bottom,
            ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack, 0)},
            ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack, 0.87)}
        );

        opacity: 1;
        transition: 0.2s all ease-out;
    }

    &:hover::before {
        opacity: 0.8;
    }
`;

const TileContent = styled(Row)<{ padding?: number }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    padding: ${props => props.padding || 0}px;
    box-sizing: border-box;

    & > * {
        height: 100%;
    }
`;

const FadedOverlay = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
    opacity: 0.7;
    border-radius: 8px;
    pointer-events: none;
`;

const TileButtons = styled(Column)`
    flex: 0 0 auto;
    justify-content: space-between;

    min-width: 28px;
    margin-left: 8px;

    z-index: 5;
`;
const PropagationBlocker = styled(Row).attrs(() => ({
    onClick: (e: React.SyntheticEvent) => e.stopPropagation(),
}))``;
const TopIcons = styled(PropagationBlocker)`
    display: flex;
    flex: 0 0 auto;
    min-height: 0;
    align-items: flex-start;
`;
const Blank = styled(Row)`
    flex: 1 0 0;
`;
const BottomIcons = styled(PropagationBlocker)`
    display: flex;
    flex: 0 0 auto;
    min-height: 0;
    align-items: flex-end;
`;
type TClickableProps = {
    onClick?: (e: React.SyntheticEvent) => void;
};
const VenueAddCircleIcon = styled(PlusIcon)<TClickableProps>`
    width: 12px;
    height: 12px;
    padding: 7px;
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    border-radius: 14px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};

    flex: 0 0 auto;

    ${props => (props.onClick ? `cursor: pointer;` : ``)}

    opacity: 0;
    transition: 0.2s all ease-out;

    ${TileContent}:hover & {
        opacity: 1;
    }
`;

export const VenueSelectedIcon = CircleCheckIcon;

const VenueCloseIcon = styled(CloseIcon)`
    color: ${colorFns.pureWhite};
`;

const Pin = styled(VenuePin)`
    color: ${colorFns.tilePinIcon};
    position: absolute;
`;
type TVenueTileEmptyProps = {
    width: string;
    minWidth: string;
    fixedRatio: string;
    withPin: boolean;
    backgroundColor: Themed.Color;
    padding: string;
};
export function VenueTileEmpty({
    width,
    minWidth,
    fixedRatio,
    withPin,
    backgroundColor,
    padding,
    ...props
}: Partial<TVenueTileEmptyProps>) {
    return (
        <TileWrapper width={width} minWidth={minWidth} padding={padding} {...props}>
            <FixedRatio fixedRatio={fixedRatio} backgroundColor={backgroundColor} />
            {withPin && <Pin />}
        </TileWrapper>
    );
}

const TILE_PROPS_BY_FORMAT: {
    [key in TTileFormats]: {
        padding: number;
        imgTransform?: string;
    };
} & {
    undefined: {
        padding: number;
        imgTransform?: string;
    };
} = {
    tile: {
        padding: 12,
        imgTransform: 'w_300,q_auto:best',
    },
    thumb: {
        padding: 20,
        imgTransform: 'w_400,q_auto:best',
    },
    proposalThumb: {
        padding: 24,
        imgTransform: 'w_600,q_auto:best',
    },
    proposal: {
        padding: 24,
    },
    featured: {
        padding: 48,
    },
    default: {
        padding: 24,
        imgTransform: 'w_500,q_auto:best',
    },
    // interestingly this will work if we did tilePropsByFormat[undefined] or tilePropsByFormat.undefined
    // because key access coerces undefined to 'undefined'
    undefined: {
        padding: 24,
        imgTransform: 'w_500,q_auto:best',
    },
};

type TActions = {
    selected?: boolean;
    menuOptions?: { label: string; handler: () => void }[];

    hideButtons?: boolean;
    onSelect?: (venue: Bizly.Venue) => void;
    onDeselect?: (venue: Bizly.Venue) => void;
    onClose?: () => void;
};

type VenueTileProps = {
    className?: string;
    venue: Bizly.Venue;
    format?: TTileFormats;
    width?: string;
    minWidth?: string;
    fixedRatio: string;
    padding?: string;

    pillType?: TPillTypes;

    onClick?: (venueId: number) => void;
    onMouseOver?: (venueId: number) => void;
    onMouseLeave?: () => void;
};

type TBaseVenueTile = VenueTileProps & TActions;

function BaseVenueTile({
    venue,
    format,
    width,
    minWidth,
    fixedRatio,
    padding,

    selected = false,
    menuOptions = [],
    pillType,

    onClick,
    onMouseOver,
    onMouseLeave,

    hideButtons,
    onSelect,
    onDeselect,
    onClose,
}: TBaseVenueTile) {
    const { padding: contentPadding, imgTransform } = useMemo(() => TILE_PROPS_BY_FORMAT[format || 'undefined'], [
        format,
    ]);

    const canSelect = !(venue.externalUrl || venue.type.id === 10) && onSelect;

    const onSelectVenue = useCallback(() => onSelect && onSelect(venue), [onSelect, venue]);
    const onDeselectVenue = useCallback(() => onDeselect && onDeselect(venue), [onDeselect, venue]);

    const mouseover = useCallback(() => onMouseOver && onMouseOver(venue.id), [onMouseOver, venue.id]);
    const mouseleave = useCallback(() => onMouseLeave && onMouseLeave(), [onMouseLeave]);

    return (
        <TileWrapper width={width} minWidth={minWidth} padding={padding}>
            <RoundedImage
                url={
                    imgTransform
                        ? venue.imageUrl.replace('image/upload/', `image/upload/${imgTransform}/`)
                        : venue.imageUrl
                }
                onClick={onClick ? () => onClick(venue.id) : undefined}
                onMouseOver={mouseover}
                onMouseLeave={mouseleave}
                fixedRatio={fixedRatio}
            >
                <TileContent padding={contentPadding}>
                    <TileInfo venue={venue} format={format} pillType={pillType} />

                    {hideButtons ? null : (
                        <TileButtons>
                            <TopIcons>{onClose && <VenueCloseIcon onClick={onClose} />}</TopIcons>
                            <Blank />
                            <BottomIcons>
                                {!!menuOptions.length && <VenueCardPopover options={menuOptions} />}
                                {selected ? (
                                    <VenueSelectedIcon onClick={onDeselect && onDeselectVenue} />
                                ) : (
                                    canSelect && <VenueAddCircleIcon onClick={onSelectVenue} />
                                )}
                            </BottomIcons>
                        </TileButtons>
                    )}
                </TileContent>
                {REJECTED_STATUSES.has(venue.status) && <FadedOverlay />}
            </RoundedImage>
        </TileWrapper>
    );
}

type TVenueTileWithActions = Omit<TBaseVenueTile, 'hideButtons'>;
type TVenueTileWithoutActions = VenueTileProps;

export default function VenueTile(props: TVenueTileWithActions) {
    return <BaseVenueTile {...props} />;
}
export const VenueTileWithoutActions = (props: TVenueTileWithoutActions) => <BaseVenueTile {...props} hideButtons />;
