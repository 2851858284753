import React from 'react';
import range from 'lodash/range';

import { Column, PromptedTextField, Spacer } from '../ui';

import Form from 'components/Form';

const fields = {
    lowLabel: {
        type: 'text',
        prompt: 'Label',
        optional: true,
        options: {
            placeholder: 'Label Text',
            maxLength: 20,
        },
    },
    lowValue: {
        type: 'select',
        prompt: 'Low',
        perRow: 6,
        options: {
            options: range(0, 2),
        },
    },
    highLabel: {
        type: 'text',
        prompt: 'Label',
        optional: true,
        options: {
            placeholder: 'Label Text',
            maxLength: 20,
        },
    },
    highValue: {
        type: 'select',
        prompt: 'High',
        perRow: 6,
        options: {
            options: range(3, 11),
        },
    },
};

const dash = { type: 'display', prompt: String.fromCharCode(0x200b), options: { label: 'to' } };
const schema = [{ fields: ['lowLabel', 'lowValue', dash, 'highLabel', 'highValue'], spacing: false }];

type TOptions = Partial<{
    lowLabel: string;
    lowValue: number;
    highLabel: string;
    highValue: number;
}>;

type TLinearScale = Partial<{
    label: string;
    options: TOptions;
    disabled: boolean;
    onLabelChange: (newLabel: string) => void;
    onOptionsChange: (newOptions: TOptions) => void;
}>;

export default function LinearScale({ label, options = {}, disabled, onLabelChange, onOptionsChange }: TLinearScale) {
    return (
        <Column>
            <PromptedTextField
                onChange={e => onLabelChange && onLabelChange(e.target.value)}
                disabled={disabled}
                placeholder="Enter question text"
                prompt="Question"
                value={label}
                variant="outlined"
            />
            <Spacer />
            <Form
                fields={fields}
                schema={schema}
                value={options}
                disabled={disabled}
                onChange={({ value }: { value: TOptions }) => onOptionsChange && onOptionsChange(value)}
            />
            <Spacer />
        </Column>
    );
}
