import createStore from 'stores';
import shallow from 'zustand/shallow';

import { isEmptyString } from 'components/ProposalForm/utils';
import uniqueId from 'lodash/uniqueId';

import { createPlaybook } from 'api/playbooks';

import { TBasicInfoValue } from '../BasicInfoForm';
import { TTagsValue } from '../TagsForm';
import { MOVE_UP, MOVE_DOWN, TDirection } from 'components/Schedule/ManageAgenda';

export enum ESteps {
    basic,
    agenda,
    tags,
    preview,
}
export const stepList = [ESteps.basic, ESteps.agenda, ESteps.tags, ESteps.preview];
export const maxStep = stepList.length - 1;

type TErrors<T> = Partial<Record<keyof T, string>>;
const hasErrors = <T extends Partial<Record<string, string>>>(errors: T) => Object.values(errors).some(v => v);

type TBasicInfoErrors = TErrors<TBasicInfoValue>;
type TAgendaErrors = TErrors<BizlyAPI.ScheduleAgendaEntry> & { count?: string };
type TTagsErrors = TErrors<{ count?: string }>;

type State = {
    stepIdx: number;

    basicInfo: TBasicInfoValue;
    basicInfoErrors: TBasicInfoErrors;

    agenda: BizlyAPI.ScheduleAgendaEntry[];
    agendaErrors: TAgendaErrors;

    tagIds: TTagsValue;
    tagsErrors: TTagsErrors;

    creating?: boolean;
    saved?: boolean;
};
type Store = State;

const initialState: State = {
    stepIdx: 0,

    basicInfo: {},
    basicInfoErrors: {},

    agenda: [],
    agendaErrors: {},

    tagIds: [],
    tagsErrors: {},

    creating: false,
    saved: true,
};

export const [useCreatePlaybook, createPlaybookApi] = createStore<Store>(() => initialState);

const { setState, getState } = createPlaybookApi;

export const selCurStep = (state: State) => stepList[state.stepIdx];

const basicFormActions = {
    setBasicForm: (basicInfo: TBasicInfoValue) => {
        setState({ basicInfo, basicInfoErrors: {} });
    },
    setBasicFormErrors: (basicInfoErrors: TBasicInfoErrors) => {
        setState({ basicInfoErrors });
    },
    validateBasicForm: () => {
        const basicInfoErrors: TBasicInfoErrors = {};
        const data = getState().basicInfo;

        if (isEmptyString(data.name)) basicInfoErrors.name = 'Playbook name is required';
        if (isEmptyString(data.description)) basicInfoErrors.description = 'Playbook description is required';
        if (isEmptyString(data.purpose)) basicInfoErrors.purpose = 'Playbook objective is required';

        setState({ basicInfoErrors });
    },
};

const agendaFormActions = {
    addAgendaItem: () => {
        setState(prevState => ({
            ...prevState,
            agenda: [...prevState.agenda, { id: uniqueId(), duration: 0 }],
        }));
    },
    updateAgendaItem: (updatedAgendaItem: { value: BizlyAPI.ScheduleAgendaEntry }, targetIdx: number) => {
        setState(prevState => {
            const updatedAgenda = prevState.agenda.slice();
            updatedAgenda[targetIdx] = updatedAgendaItem.value;
            return {
                ...prevState,
                agenda: updatedAgenda,
            };
        });
    },
    arrangeAgendaItem: (targetIdx: number, direction: TDirection) =>
        setState(prevState => {
            const { agenda = [] } = prevState;
            const updatedAgenda = agenda.slice();
            const targetEntry = { ...updatedAgenda[targetIdx] };

            if (direction === MOVE_UP && !!updatedAgenda[targetIdx - 1]) {
                const entryBefore = { ...updatedAgenda[targetIdx - 1] };
                updatedAgenda[targetIdx - 1] = targetEntry;
                updatedAgenda[targetIdx] = entryBefore;
            } else if (direction === MOVE_DOWN && !!updatedAgenda[targetIdx + 1]) {
                const entryAfter = { ...updatedAgenda[targetIdx + 1] };
                updatedAgenda[targetIdx + 1] = targetEntry;
                updatedAgenda[targetIdx] = entryAfter;
            }

            return {
                ...prevState,
                agenda: updatedAgenda,
            };
        }),
    removeAgendaItem: (targetIdx: number) =>
        setState(prevState => {
            const { agenda = [] } = prevState;
            const updatedAgenda = agenda.filter((entry: any, idx: number) => idx !== targetIdx);

            return {
                ...prevState,
                agenda: updatedAgenda,
            };
        }),
    setAgendaErrors: (agendaErrors: TAgendaErrors) => {
        setState({ agendaErrors });
    },
    validateAgenda: () => {
        const agendaErrors: TAgendaErrors = {};
        const { agenda } = getState();

        agenda.forEach(item => {
            if (isEmptyString(item.title)) agendaErrors.title = 'Agenda title is required';
            if (Number.isNaN(Number(item.duration))) agendaErrors.duration = 'Agenda duration is required';
            // if (isEmptyString(item.description)) agendaErrors.description = 'Agenda description is required';
        });

        if (!agenda.length) agendaErrors.count = 'Playbook agenda is required';

        setState({ agendaErrors });
    },
};

const tagsFormActions = {
    updateTags: (updatedTags: { value: { tagIds: TTagsValue } }) => {
        setState({ tagIds: updatedTags.value.tagIds });
    },
    setTagsErrors: (tagsErrors: TTagsErrors) => {
        setState({ tagsErrors });
    },
    validateTags: () => {
        const tagsErrors: TTagsErrors = {};
        const { tagIds } = getState();

        if (!tagIds.length) tagsErrors.count = 'At least one tag required';

        setState({ tagsErrors });
    },
};

export class ValidationError extends Error {}

const stepToValidator = {
    [ESteps.basic]: basicFormActions.validateBasicForm,
    [ESteps.agenda]: agendaFormActions.validateAgenda,
    [ESteps.tags]: tagsFormActions.validateTags,
    [ESteps.preview]: () => {},
};

const selectErrors = (step: ESteps) => {
    const stepToError = {
        [ESteps.basic]: getState().basicInfoErrors,
        [ESteps.agenda]: getState().agendaErrors,
        [ESteps.tags]: getState().tagsErrors,
        [ESteps.preview]: {},
    };

    return stepToError[step];
};

const navActions = {
    prevStep: () => {
        setState({ stepIdx: Math.max(getState().stepIdx - 1, 0) });
    },
    nextStep: () => {
        const curStep = selCurStep(getState());
        stepToValidator[curStep]();
        if (!hasErrors(selectErrors(curStep))) {
            setState({ stepIdx: Math.min(getState().stepIdx + 1, maxStep) });
        }
    },
    goToStep: (toStep: ESteps) => {
        setState({ stepIdx: stepList.findIndex(step => step === toStep) });
    },
    reset: () => setState(initialState),
};

createPlaybookApi.subscribe(
    () => setState({ saved: false }),
    state => [state.basicInfo, state.agenda, state.tagIds],
    shallow
);

export const createPlaybookActions = {
    ...navActions,
    ...basicFormActions,
    ...agendaFormActions,
    ...tagsFormActions,

    create: async () => {
        if (getState().creating) return;

        stepList.forEach(step => stepToValidator[step]());
        stepList.forEach(step => {
            if (Object.values(selectErrors(step)).some(v => v)) {
                navActions.goToStep(step);
                throw new ValidationError();
            }
        });

        const curState = getState();
        setState({ creating: true });
        try {
            const agendaNoIds = curState.agenda.map(({ id, ...item }) => ({ ...item }));
            const { playbook } = await createPlaybook({
                ...curState.basicInfo,
                agenda: agendaNoIds,
                tagIds: curState.tagIds,
            });
            return playbook;
        } catch (e) {
            throw e;
        } finally {
            setState({ creating: false });
        }
    },
};
