import React from 'react';
import styled, { css } from 'styled-components';

import { createPlaybookActions, useCreatePlaybook, ESteps, stepList, selCurStep } from './store';

import GenericNavButtons from 'components/GenericNavButtons';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

import { ReactComponent as OverviewIconSvg } from 'images/icons/overview.svg';
import { ReactComponent as AgendaIconSvg } from 'images/icons/agenda.svg';
import { ReactComponent as TagsIconSvg } from 'images/icons/tags.svg';
import { ReactComponent as SendIconSvg } from 'images/icons/send.svg';

const iconStyles = css<{ active?: boolean }>`
    height: 16px;
    width: 15px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.74)};
    fill: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.74)};
    margin-left: 18px;
    margin-right: 9px;
`;

const OverviewIcon = styled(OverviewIconSvg)`
    ${iconStyles}
`;
const AgendaIcon = styled(AgendaIconSvg)`
    ${iconStyles}
`;
const TagsIcon = styled(TagsIconSvg)`
    ${iconStyles}
`;
const SendIcon = styled(SendIconSvg)`
    ${iconStyles}
`;

const stepToIcon = {
    [ESteps.basic]: OverviewIcon,
    [ESteps.agenda]: AgendaIcon,
    [ESteps.tags]: TagsIcon,
    [ESteps.preview]: SendIcon,
};

const stepToName = {
    [ESteps.basic]: 'Overview',
    [ESteps.agenda]: 'Agenda',
    [ESteps.tags]: 'Tags',
    [ESteps.preview]: 'Create',
};

export default function NavButtons() {
    const curStep = useCreatePlaybook(selCurStep);
    const { stepIdx: curStepIdx } = useCreatePlaybook();

    const linksList = stepList.map((step, stepIdx) => {
        const Icon = stepToIcon[step];
        const name = stepToName[step];

        const disabled = stepIdx > curStepIdx;

        return {
            key: name,
            active: curStep === step,
            disabled,
            onClick: disabled ? () => {} : () => createPlaybookActions.goToStep(step),
            children: (
                <>
                    <Tooltip title={name} enterDelay={1000} TransitionComponent={Zoom} placement="right">
                        <Icon active={curStep === step} />
                    </Tooltip>
                </>
            ),
        };
    });

    return <GenericNavButtons backTo="/playbooks" links={linksList} />;
}
