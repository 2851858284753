import createStore from '../';
import { getPlaybooks } from 'api/playbooks';
import keyBy from 'lodash/keyBy';
import { isBlankTemplateId } from 'api/eventTemplates';

type State = {
    loading: boolean;
} & (
    | {
          loaded: false;
          playbooks: null;
      }
    | {
          loaded: true;
          playbooks: {
              [id: number]: BizlyAPI.Complete.Playbook;
              [id: string]: BizlyAPI.Complete.Playbook;
          };
      }
);
type Store = State;

const initialState: State = {
    loading: false,
    loaded: false,
    playbooks: null,
};

export const [usePlaybooks, playbooksApi] = createStore<Store>(() => initialState);

const { setState, getState } = playbooksApi;

export const playbooksActions = {
    load: async () => {
        setState({
            ...getState(),
            loading: true,
        });

        try {
            const { playbooks } = await getPlaybooks();

            setState({
                loaded: true,
                loading: false,
                playbooks: keyBy(
                    playbooks.filter(playbook => !isBlankTemplateId(playbook.id)),
                    playbook => playbook.id
                ),
            });

            return playbooks;
        } catch (e) {
            setState({
                loading: false,
            });
            throw e;
        }
    },
};

export const selectPlaybook = (id?: string | number) => (state: State) =>
    id !== undefined ? state.playbooks?.[id] : undefined;

export const selectPlaybooksAsList = (state: State) => (state.playbooks ? Object.values(state.playbooks) : []);
