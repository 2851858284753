import React, { useContext, useState, useCallback, useMemo, useLayoutEffect } from 'react';
import { EventContext } from '../providers/event';

import SwipeableViews from 'react-swipeable-views';
import Dots from 'material-ui-dots';
import { Waypoint } from 'react-waypoint';
import styled, { keyframes, css } from 'styled-components/macro';

import { Column } from '../ui';
import { ReactComponent as GalleryArrowIcon } from '../images/icons/gallery_arrow.svg';

import { getVenue, getVenueSpaces } from '../api';
import { useAsync } from '../hooks/useAsync';

import VenueListingInfo from '../components/VenueListing/VenueListingInfo';
import { EventHeaderIconsFill } from '../components/EventHeader';
import Button from '../components/ui/Button';
import { VenueSelectedIcon } from '../components/VenueTile/VenueTile';

const RelativeCol = styled(Column)`
    position: relative;
`;

const ScrollbarAgnosticCol = styled(Column)`
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;

    overflow-x: hidden;
    overflow-y: unset;
`;

const Gallery = styled(Column)<{ pageOffset: number }>`
    position: relative;
    justify-content: center;
    align-items: center;
    width: calc(100vw - ${({ pageOffset }) => pageOffset}px);
    min-width: 1200px;
`;

const fadeIn = keyframes`
  0%   { opacity: 0; }
  100% { opacity: 1; }
`;

const GalleryImg = styled.img<{ pageOffset: number }>`
    width: calc(100vw - ${({ pageOffset }) => pageOffset}px);
    min-width: 1200px;
    max-height: 100vh;
    animation: ${fadeIn} 0.05s ease-in;
`;

const ArrowCSS = css`
    position: absolute;

    height: 58px;
    width: 58px;

    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;
const BackArrow = styled(GalleryArrowIcon)`
    ${ArrowCSS}
    left: 36px;
`;
const ForwardArrow = styled(GalleryArrowIcon)`
    ${ArrowCSS}
    transform: rotateZ(180deg);
    right: 36px;
`;

const ProgressDotsClassNames = {
    dots: 'dots',
    dot: 'dot',
    dotOuter: 'dotOuter',
};

// Dots normally are 8px*8px, to match designs we want 6px*6px and 4px*4px, these styles adjust for that
const ProgressDots = styled(Dots)`
    position: absolute;
    bottom: 32px;
    transform: translateY(-100%);

    .${ProgressDotsClassNames.dots} {
        padding: 0;
    }

    .${ProgressDotsClassNames.dot} {
        margin-top: 0 !important;
        margin-left: -2px;
        transform: translateY(25%) scale(0.75);
    }

    .${ProgressDotsClassNames.dotOuter} {
        .${ProgressDotsClassNames.dot} {
            height: 4px;
            width: 4px;
            margin: 0;

            display: flex;
            align-items: center;
            justify-content: center;

            color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};

            transform: unset;
        }
    }
`;

const TextBackgroundGradient = styled.div`
    background: linear-gradient(
        to bottom,
        ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack, 0.3)},
        ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack, 0)} 50%,
        ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack, 0.6)}
    );

    box-shadow: inset 0 0 100px 100px ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack, 0.05)};

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    pointer-events: none;
`;

const ListingTitle = styled.div<{ pageOffset: number }>`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    padding: 0 80px 68px 80px;

    font-size: calc(1 / 12 * (100vw - ${({ pageOffset }) => pageOffset}px));
    font-weight: 500;

    line-height: 1;
    letter-spacing: -3.2px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

const SwipeableViewsStyles = (pageOffset = 0) => ({
    style: { width: `calc(100vw - ${pageOffset}px)`, minWidth: 1200 },
    containerStyle: { width: `calc(100vw - ${pageOffset}px)`, minWidth: 1200, lineHeight: 0 },
    slideStyle: { width: `calc(100vw - ${pageOffset}px)`, minWidth: 1200 },
});

type TGalleryProps = {
    images: Bizly.VenueImage[];
    imgIndex?: number;
    onChangeIndex?: (idx: number) => void;
    shadow?: boolean;
    pageOffset?: number;
};
const ImageGallery = ({ images, imgIndex, onChangeIndex, shadow, pageOffset = 0 }: TGalleryProps) =>
    shadow ? (
        <div style={{ width: '100%', overflow: 'hidden', visibility: 'hidden' }}>
            <SwipeableViews
                {...{
                    ...SwipeableViewsStyles(pageOffset),
                    style: {
                        ...SwipeableViewsStyles(pageOffset).style,
                        position: 'relative',
                    },
                }}
                index={0}
            >
                {(images || []).map(i => (
                    <GalleryImg key={i.id} src={i.srcUrl} alt={`image of ${i.description}`} pageOffset={pageOffset} />
                ))}
            </SwipeableViews>
        </div>
    ) : (
        <SwipeableViews
            enableMouseEvents
            {...SwipeableViewsStyles(pageOffset)}
            index={imgIndex}
            onChangeIndex={onChangeIndex}
        >
            {(images || []).map(i => (
                <GalleryImg key={i.id} src={i.srcUrl} alt={`image of ${i.description}`} pageOffset={pageOffset} />
            ))}
        </SwipeableViews>
    );

type TProps = {
    id: string;
    contentPadding: string;
    addToInquiry?: (venue: Bizly.Venue) => void;
    removeFromInquiry?: (venue: Bizly.Venue) => void;
    selected: boolean;
    pageOffset?: number;
};
const VenueListing = ({ id, contentPadding, addToInquiry, removeFromInquiry, selected, pageOffset = 0 }: TProps) => {
    const { setTransparentHeader, transparentHeader } = useContext(EventContext);

    const transparentize = useCallback(() => setTransparentHeader(true), [setTransparentHeader]);
    const opacifyHeader = useCallback(() => setTransparentHeader(false), [setTransparentHeader]);
    useLayoutEffect(() => {
        transparentize();
        return opacifyHeader;
    }, [transparentize, opacifyHeader]); // it should initialize as transparent, I really never want to run this again

    const getTheVenue: () => Promise<{
        venue: Bizly.Venue;
    }> = useCallback(() => getVenue(parseInt(id)), [id]);
    const { data } = useAsync(getTheVenue);
    const getTheVenueSpaces: () => Promise<{
        spaces: any[];
    }> = useCallback(() => getVenueSpaces(parseInt(id)), [id]);
    const { data: spaces } = useAsync(getTheVenueSpaces);

    const venueData: any = useMemo(() => (data || { venue: {} }).venue, [data]);
    const spacesData = useMemo(() => (spaces || { spaces: [] }).spaces, [spaces]);
    const imagesCount = useMemo(() => (venueData.images || []).length, [venueData]);

    const canAddVenue = !(venueData.externalUrl || venueData.type?.id === 10) && addToInquiry;
    const addVenue = addToInquiry
        ? () => {
              addToInquiry(venueData);
          }
        : undefined;
    const removeVenue = removeFromInquiry
        ? () => {
              removeFromInquiry(venueData);
          }
        : undefined;

    const [imgIndex, setImgIndex] = useState(0);
    const prevImg = useCallback(() => setImgIndex(imgIndex => (imgIndex - 1 + imagesCount) % imagesCount), [
        imagesCount,
    ]);
    const nextImg = useCallback(() => setImgIndex(imgIndex => (imgIndex + 1 + imagesCount) % imagesCount), [
        imagesCount,
    ]);
    const onChangeIndex = useCallback((idx: number) => setImgIndex(idx), []);

    return (
        <RelativeCol>
            <EventHeaderIconsFill>
                {selected ? (
                    <VenueSelectedIcon inverted={transparentHeader} onClick={removeVenue} />
                ) : (
                    canAddVenue && (
                        <Button onClick={addVenue} width={146} height={36} noBorder secondary={transparentHeader}>
                            Add to Inquiry
                        </Button>
                    )
                )}
            </EventHeaderIconsFill>

            <ScrollbarAgnosticCol>
                <Gallery pageOffset={pageOffset}>
                    <ImageGallery
                        images={venueData.images}
                        imgIndex={imgIndex}
                        onChangeIndex={onChangeIndex}
                        pageOffset={pageOffset}
                    />

                    <TextBackgroundGradient />

                    <ListingTitle pageOffset={pageOffset}>{venueData.name}</ListingTitle>
                    {imagesCount > 1 && (
                        <>
                            <ProgressDots
                                index={imgIndex}
                                count={imagesCount}
                                onDotClick={(i: number) => setImgIndex(i)}
                                classes={ProgressDotsClassNames}
                            />
                            <BackArrow onClick={prevImg} />
                            <ForwardArrow onClick={nextImg} />
                        </>
                    )}

                    <Waypoint
                        onEnter={transparentize}
                        onLeave={opacifyHeader}
                        scrollableAncestor="window"
                        bottomOffset={-2}
                        topOffset={72}
                    />
                </Gallery>
            </ScrollbarAgnosticCol>
            <ImageGallery images={venueData.images} shadow />

            <Column style={{ padding: contentPadding }}>
                <VenueListingInfo venue={venueData} spaces={spacesData} />
            </Column>
        </RelativeCol>
    );
};

export default VenueListing;
