import { View } from 'react-big-calendar';

import {
    isSameDay,
    isSameWeek,
    isWithinInterval,
    areIntervalsOverlapping,
    set,
    startOfWeek,
    endOfWeek,
    startOfDay,
    startOfHour,
    differenceInMilliseconds,
    addMilliseconds,
} from 'date-fns';

import moment from 'moment';

export const oneHourEarlier = (date: Date) => set(date, { hours: Math.max(date.getHours() - 1, 0) });

export const getScrollToTime = <TEvent extends { start: Date; end: Date; multiDay?: boolean }>(
    events: TEvent[],
    baseDate: Date,
    view?: View,
    msOffset = 0
) => {
    type TRange = { start: Date; end: Date; multiDay?: boolean };
    const base = startOfDay(baseDate);

    const earlyHourMultiday = (event: TRange) =>
        event.multiDay &&
        (view === 'week'
            ? areIntervalsOverlapping({ start: startOfWeek(base), end: startOfDay(endOfWeek(base)) }, event)
            : isWithinInterval(base, event));

    const Day8AM = set(base, { hours: 8 });
    const Day12AM = set(base, { hours: 0 });

    const getSortDate = (event: TRange) => (earlyHourMultiday(event) ? Day12AM : event.start);

    const earliestEventInView = events
        .filter(event => (view === 'week' ? isSameWeek : isSameDay)(base, event.start) || earlyHourMultiday(event))
        .sort((eventA, eventB) => getSortDate(eventA).getHours() - getSortDate(eventB).getHours())[0];
    const earliestEventStart = (earliestEventInView && oneHourEarlier(getSortDate(earliestEventInView))) || Day8AM;

    const scrollToTime = startOfHour(earliestEventStart);
    const msInDay = differenceInMilliseconds(scrollToTime, startOfDay(scrollToTime));

    return addMilliseconds(scrollToTime, msInDay <= msOffset ? -msInDay : -msOffset);
};

export const msPassedInDay = (day?: string | Date) =>
    day
        ? +moment(day).toDate() -
          +moment(day)
              .startOf('day')
              .toDate()
        : 0;
