import { get, post } from './';

export const getAlerts = ({ before, after, limit }: { before?: Date; after?: Date; limit?: number } | undefined = {}) =>
    get(
        `alerts?${limit ? `limit=${limit}` : 'limit=20'}${before ? `&before=${before.toISOString()}` : ''}${
            after ? `&after=${after.toISOString()}` : ''
        }`
    ) as Promise<{
        alerts: BizlyAPI.Alert[];
        groupedAlerts: (Omit<BizlyAPI.Alert, 'read'> & { unreadCount: number; totalCount: number })[];
        meta: { unreadCount: number; totalCount: number };
    }>;

export const clearUnreadAlerts = () => post(`alerts/reset-unread-count`) as Promise<{}>;
