import React from 'react';
import styled from 'styled-components';

import { useCreatePlaybook } from './store';
import { useEventTemplateTags } from 'stores/event-template-tags';

import { H5Headline } from 'components/ui/Headline';
import { Column, Row, Spacer } from 'ui';
import colorFns from 'colorFns';

import PlaybookDetail, { Tags } from 'components/Playbook/PlaybookDetail';
import AgendaTimeTable from 'components/ui/AgendaTimeTable';
import { RichTextDisplay } from 'components/Form/fields';

const Card = styled(Column)`
    position: relative;
    width: 455px;

    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    border-radius: 8px;

    box-sizing: border-box;

    > * {
        padding: 24px;

        &:not(:last-child) {
            border-bottom: 1px solid ${colorFns.softBorder};
        }
    }
`;

export default function Preview() {
    const { basicInfo, agenda, tagIds } = useCreatePlaybook();
    const { tags } = useEventTemplateTags();

    const tagIdsSet = new Set(tagIds);

    const playbook = {
        purpose: basicInfo.purpose,
        description: basicInfo.description,
        image: basicInfo.imageUrl,
        tags: (tags || []).filter(tag => tagIdsSet.has(tag.id)),
    };
    const renderedAgendaDescription = RichTextDisplay({ value: basicInfo.agendaDescription });
    const hasAgendaCard = renderedAgendaDescription && agenda.length > 0;
    return (
        <>
            <Tags tags={playbook.tags} />
            <Spacer smallish />
            <H5Headline>{basicInfo.name}</H5Headline>
            <Spacer larger />
            <Row itemSpacing="largest" alignItems="stretch">
                <Column itemSpacing="large">
                    <PlaybookDetail playbook={playbook} />
                </Column>
                <Column itemSpacing="large">
                    {hasAgendaCard && (
                        <Card>
                            {renderedAgendaDescription && <div>{renderedAgendaDescription}</div>}
                            {<AgendaTimeTable agenda={agenda} />}
                        </Card>
                    )}
                </Column>
            </Row>
        </>
    );
}
