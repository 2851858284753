import React from 'react';
import { Column } from 'ui';
import styled from 'styled-components';

const StyledColumn = styled(Column)`
    ul {
        padding-left: 20px;
        li {
            line-height: 28px;
        }
    }
`;

export default function Errors({ errors }: { errors: Partial<Record<string, string>> }) {
    const errorKeys = Object.keys(errors);

    return (
        <StyledColumn>
            Some fields are missing:
            <ul>{errorKeys.map(key => (errors[key] ? <li key={key}>{errors[key]}</li> : null))}</ul>
        </StyledColumn>
    );
}
