import createStore from '../';
import { getEventTemplateTags } from 'api/eventTemplates';

type State = {
    loading: boolean;
} & (
    | {
          loaded: false;
          tags: null;
      }
    | {
          loaded: true;
          tags: BizlyAPI.EventTemplateTag[];
      }
);
type Store = State;

const initialState: State = {
    loading: false,
    loaded: false,
    tags: null,
};

export const [useEventTemplateTags, eventTemplateTagsApi] = createStore<Store>(() => initialState);

const { setState, getState } = eventTemplateTagsApi;

export const eventTemplateTagsActions = {
    load: async () => {
        setState({
            ...getState(),
            loading: true,
        });

        try {
            const eventTemplateTags = await getEventTemplateTags();

            setState({
                loaded: true,
                loading: false,
                tags: eventTemplateTags.tags,
            });

            return eventTemplateTags;
        } catch (e) {
            setState({
                loading: false,
            });
            throw e;
        }
    },
};
