import React, { useState, useRef, useEffect } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import styled from 'styled-components/macro';
import { Slot, Fill } from 'react-slot-fill';
import { parse } from 'query-string';

import { ReactComponent as BackArrowIcon } from 'images/icons/back_arrow.svg';
import { ReactComponent as DocumentIconSVG } from 'images/icons/document.svg';
import { ReactComponent as ForwardArrowIcon } from 'images/icons/forward_arrow.svg';
import { ReactComponent as CalculatorSvg } from 'images/icons/calculator.svg';
import { ReactComponent as SettingsIconSVG } from 'images/icons/gear.svg';

import { withInteractibleIconStyles } from '../shared';

import { AlignedRow, Spacer } from '../ui';

import Button, { ButtonProps } from './ui/Button';
import { H3Headline } from './ui/Headline';
import HoverableIcon from './ui/HoverableIcon';

import CollaboratorsHeaderSection from './Collaborators/CollaboratorsHeaderSection';

import { DocumentsPanel } from './DocumentsPanel';
import { EventSettingsFlyout } from './EventSettingsFlyout';
import VenueSearchField from './VenueSearch/VenueSearchField';

import { showFinalSpendReminders } from './FinalSpendTracking';
import FinalSpendBanner from './FinalSpendTracking/Banner';
import FinalSpendFlyout from './FinalSpendTracking/Flyout';

import { useEvent } from '../providers/event';

const DocumentIcon = withInteractibleIconStyles(DocumentIconSVG);
const SettingsIcon = withInteractibleIconStyles(SettingsIconSVG);

const HEADER_HEIGHT = 72;
const TALL_HEADER_HEIGHT = 108;
export const getHeaderHeight = (transparentHeader?: boolean) =>
    transparentHeader ? TALL_HEADER_HEIGHT : HEADER_HEIGHT;

const Header = styled.header<{ transparent?: boolean; pageOffset: number; maxSearchWidth: number }>`
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: fixed;
    top: 0;
    z-index: 1000;
    width: calc(100vw - ${({ pageOffset }) => pageOffset}px);
    box-sizing: border-box;

    transition: 0.3s all ease-in-out;

    ${({ transparent, theme: { getColor, EColors } }) =>
        transparent
            ? `
    height: ${getHeaderHeight(transparent)}px;
    background: transparent;
    border-bottom: 1px solid transparent;`
            : `
    height: ${getHeaderHeight(transparent)}px;
    background: ${getColor(EColors.brand)};
  `}

    & > *:first-child {
        flex: 1 0 0;
        min-width: 0px;
        padding-right: 32px;
        padding-left: 36px;
        box-sizing: border-box;
    }

    & > *:nth-child(2) {
        flex: ${({ maxSearchWidth }) => maxSearchWidth}00 0 0; /* This allows the middle to grow before everything else */
        min-width: 324px;
        max-width: ${({ maxSearchWidth }) => maxSearchWidth}px;
    }

    & > *:last-child {
        padding-left: 32px;
        padding-right: 36px;
        box-sizing: border-box;
    }
    /* If the middle exists, size the right as same as left, if not, don't grow right */
    & > *:last-child:nth-child(2) {
        flex: 0 0 auto;
        width: auto;
        min-width: unset;
        max-width: unset;
    }
    & > *:last-child:nth-child(3) {
        flex: 1 0 0;
        min-width: 0px;
    }
`;

const HeaderShadow = styled(Header)`
    position: relative;
    width: 1px;
    visibility: hidden;
`;

const ItemsRight = styled(AlignedRow)`
    justify-content: flex-end;
`;
const ItemsLeft = styled(AlignedRow)`
    justify-content: flex-start;
`;

const BackButtonWrapper = styled(Button)`
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    align-items: center;
    justify-content: center;
`;

export const BackButton = ({ onClick, inverted }: { onClick: ButtonProps['onClick']; inverted?: boolean }) => {
    return (
        <BackButtonWrapper
            aria-label="navigate-back"
            onClick={onClick}
            secondary={inverted}
            noBorder
            width={30}
            height={30}
        >
            <BackArrowIcon />
        </BackButtonWrapper>
    );
};

// TODO: this function has some brittle assumptions. rethink.
// this component is used for dashboard and subroutes for events, and we want to display additional
// information if on subroutes. we assume here that if the last piece of the current url is a number
// (the id of the event) then we are on a subroute, i.e. https://beta.bizly.com/event/11122/communication
const subroute = (location: RouteComponentProps['location']) => {
    const lastPiece = location.pathname.split('/')[3] || '';
    if (isNaN(Number(lastPiece))) {
        return lastPiece.replace('-', ' ');
    }
};

export const getLast = (location: RouteComponentProps['location']) => {
    if (!subroute(location)) {
        return '/events';
    } else {
        return location.pathname.substr(0, location.pathname.lastIndexOf('/'));
    }
};

const goUp = (history: RouteComponentProps['history'], location: RouteComponentProps['location']) => {
    if (location.state && location.state.shouldGoBack) {
        return history.goBack();
    }

    history.push(getLast(location));
};

const EventTitle = styled(H3Headline).attrs(({ theme: { getColor, EColors } }) => ({
    color: getColor(EColors.darkestGrey),
    maxLines: 1,
}))``;
const EventTitleSub = styled(EventTitle).attrs(({ theme: { getColor, EColors } }) => ({
    color: getColor(EColors.darkGrey),
}))`
    min-width: 0;
`;
const EventTitleStatic = styled(EventTitle)`
    flex: 0 0 auto;
    text-transform: capitalize;
`;
const BreadcrumbArrow = styled(ForwardArrowIcon).attrs(({ theme: { getColor, EColors } }) => ({
    color: getColor(EColors.darkerGrey),
}))`
    flex: 0 0 auto;
    margin: 0 12px;
`;

const CalculatorIcon = styled(withInteractibleIconStyles(CalculatorSvg))`
    margin-left: 6px;
    height: 29px;
    width: 29px;
`;

type EventLeftContent = {
    location: RouteComponentProps['location'];
};

const EventLeftContent = ({ location }: EventLeftContent) => {
    const { event } = useEvent();

    if (subroute(location)) {
        return (
            <>
                <EventTitleSub>{event.name || <em>Untitled Meeting</em>}</EventTitleSub>
                <BreadcrumbArrow />
                <EventTitleStatic>{subroute(location)}</EventTitleStatic>
            </>
        );
    } else {
        return null;
    }
};

type EventRightContent = {
    location: RouteComponentProps['location'];
    match: RouteComponentProps['match'];
};

const EventRightContent = ({ location, match }: EventRightContent) => {
    const [documentsAnchor, setDocumentsAnchor] = useState<Nullable<Element>>(null);
    const [eventSettingsAnchor, setEventSettingsAnchor] = useState<Nullable<Element>>(null);
    const [finalSpendAnchor, setFinalSpendAnchor] = useState<Nullable<Element>>(null);
    const finalSpendAnchorRef = useRef(null);

    const { event, onEventChange } = useEvent();

    useEffect(() => {
        const { trackspending } = parse(location.search, { parseBooleans: true });
        if (trackspending) {
            setFinalSpendAnchor(finalSpendAnchorRef.current);
        }
    }, [location.search]);

    return (
        <>
            <ItemsRight>
                <CollaboratorsHeaderSection />

                <HoverableIcon hoverText="Meeting Settings">
                    <SettingsIcon
                        style={{ marginRight: 16 }}
                        onClick={e => {
                            if (e.target instanceof Element) {
                                setEventSettingsAnchor(eventSettingsAnchor ? null : e.target);
                            }
                        }}
                    />
                </HoverableIcon>
                {eventSettingsAnchor && (
                    <EventSettingsFlyout
                        event={event}
                        onEventChange={onEventChange}
                        anchor={eventSettingsAnchor}
                        onClose={() => setEventSettingsAnchor(null)}
                    />
                )}

                <HoverableIcon hoverText="Documents">
                    <DocumentIcon
                        onClick={e => {
                            if (e.target instanceof Element) {
                                setDocumentsAnchor(documentsAnchor ? null : e.target);
                            }
                        }}
                    />
                </HoverableIcon>
                {documentsAnchor && (
                    <DocumentsPanel
                        eventId={event.id}
                        anchor={documentsAnchor}
                        onClose={() => setDocumentsAnchor(null)}
                    />
                )}

                <HoverableIcon hoverText="Final Spend Tracker">
                    <CalculatorIcon
                        ref={finalSpendAnchorRef}
                        onClick={e => {
                            if (e.target instanceof Element) {
                                setFinalSpendAnchor(finalSpendAnchor ? null : e.target);
                            }
                        }}
                    />
                </HoverableIcon>
                {finalSpendAnchor && (
                    <FinalSpendFlyout
                        eventId={event.id}
                        finalSpendAnchor={finalSpendAnchor}
                        setFinalSpendAnchor={setFinalSpendAnchor}
                    />
                )}

                <Spacer />
            </ItemsRight>

            {showFinalSpendReminders(event) && (
                <Route exact path={`${match.path}`}>
                    <EventHeaderBannerFill>
                        <FinalSpendBanner openFlyout={() => setFinalSpendAnchor(finalSpendAnchorRef.current)} />
                    </EventHeaderBannerFill>
                </Route>
            )}
        </>
    );
};

const Left = ItemsLeft;
const Right = ItemsRight;

const EventHeaderRightSlotName = 'Event.Header.Right.Slot';
const EventHeaderIconsSlot = styled(Slot).attrs({
    name: EventHeaderRightSlotName,
})``;
export const EventHeaderIconsFill = ({ children }: { children: React.ReactNode }) => (
    <Fill name={EventHeaderRightSlotName}>{children}</Fill>
);

const EventHeaderBannerName = 'Event.Header.Banner.Slot';
const EventHeaderBannerSlot = styled(Slot).attrs({
    name: EventHeaderBannerName,
})``;
const EventHeaderBannerFill = ({ children }: { children: React.ReactNode }) => (
    <Fill name={EventHeaderBannerName}>{children}</Fill>
);

const EventHeader = ({
    match,
    history,
    location,
    loading,
    noBackButton,
    pageOffset = 0,
    maxSearchWidth = 440,
}: RouteComponentProps & {
    loading: boolean;
    noBackButton?: boolean;
    pageOffset?: number;
    maxSearchWidth?: number;
}) => {
    const { transparentHeader } = useEvent();

    return (
        <>
            <Header transparent={transparentHeader} pageOffset={pageOffset} maxSearchWidth={maxSearchWidth}>
                <Left>
                    {!noBackButton && (
                        <BackButton onClick={() => goUp(history, location)} inverted={Boolean(transparentHeader)} />
                    )}

                    <Switch>
                        <Route path={`${match.path}/venue/listing/:id`} render={() => null} />
                        <Route path={`${match.path}/venue/inquiries/:id`} render={() => null} />
                        <Route>
                            <EventLeftContent location={location} />
                        </Route>
                    </Switch>
                </Left>

                <Switch>
                    <Route path={`${match.path}/venue/inquiries/:id`} render={() => null} />
                    <Route
                        path={`${match.path}/venue`}
                        render={props => (
                            <AlignedRow>
                                <VenueSearchField
                                    {...props}
                                    pageOffset={pageOffset}
                                    maxWidth={maxSearchWidth}
                                    /* reset input value when search changes in URL */
                                    key={props.location.search}
                                    disabled={loading}
                                />
                            </AlignedRow>
                        )}
                    />
                </Switch>

                <Right>
                    <Switch>
                        <Route path={`${match.path}/venue/proposal/:proposalId`}>
                            <EventHeaderIconsSlot />
                        </Route>
                        <Route path={`${match.path}/venue/listing/:id`} render={() => <EventHeaderIconsSlot />} />
                        <Route path={`${match.path}/venue/inquiries/:id`} render={() => <EventHeaderIconsSlot />} />
                        <Route path={`${match.path}/venue`} render={() => null} />
                        <Route>{!loading ? <EventRightContent location={location} match={match} /> : null}</Route>
                    </Switch>
                </Right>
            </Header>

            <Switch>
                <Route path={`${match.path}/venue/listing/:id`} render={() => null} />
                <Route component={HeaderShadow} />
            </Switch>

            <EventHeaderBannerSlot />
        </>
    );
};

export default EventHeader;
