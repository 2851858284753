import { timeOptions } from 'utils/date_util';

export const defaultEditParcelFields = ({ parcelStartDate }: { parcelStartDate: Date | null }) => ({
    startDate: {
        prompt: 'Start Date',
        type: 'date_outlined',
        options: {
            minDate: Date.now(),
            parseFormat: 'yyyy-MM-dd',
            placeholder: 'Date',
        },
    },
    endDate: {
        prompt: 'End Date',
        type: 'date_outlined',
        options: {
            minDate: parcelStartDate || Date.now(),
            parseFormat: 'yyyy-MM-dd',
            placeholder: 'Date',
        },
    },
    startTime: {
        prompt: 'Start Time',
        type: 'select',
        perRow: 3,
        options: {
            options: timeOptions,
            placeholder: 'Start Time',
            autoFocusOptionKey: '12:00:00',
        },
    },
    endTime: {
        prompt: 'End Time',
        type: 'select',
        perRow: 3,
        options: {
            options: timeOptions,
            placeholder: 'End Time',
            autoFocusOptionKey: '12:00:00',
        },
    },
    timeZone: {
        prompt: ' ',
        type: 'timezone_select',
        perRow: 'auto',
        options: {
            date: parcelStartDate || Date.now(),
        },
    },
});

export const editParcelSchema = [
    {
        key: 'startDateTime',
        fields: ['startDate', 'startTime'],
        spacing: 'medium',
    },
    {
        key: 'endDateTime',
        fields: ['endDate', 'endTime'],
        spacing: false,
    },
];

export const editParcelSchemaWithTz = [
    {
        key: 'startDateTime',
        fields: ['startDate', 'endDate'],
        spacing: 'medium',
    },
    {
        key: 'endDateTime',
        fields: ['startTime', 'endTime', 'timeZone'],
        spacing: false,
    },
];
