import React from 'react';

import { css } from 'styled-components/macro';

import Popover from '@material-ui/core/Popover';

import { useEvent } from '../../providers/event';
import { useEventCollaborators, collaboratorIsPendingAdd } from '../../providers/event-collaborators';

import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import { ReactComponent as PlusIcon } from '../../images/icons/plus.svg';

import Avatar from '../ui/Avatar';
import SmallCircleButton from '../ui/SmallCircleButton';

import { Row } from '../../ui';

import colorFns from 'colorFns';

import CollaboratorsFlyout from './CollaboratorsFlyout';
import RemoveCollaboratorModal from './RemoveCollaboratorModal';

type AvatarProps = {
    collaborator: Pick<BizlyAPI.EventCollaboratorOrPending, 'firstName' | 'lastName' | 'email' | 'imageUrl' | 'id'>;
};

const CollaboratorAvatarWithPopover = ({ collaborator }: AvatarProps) => {
    const [hoverAnchorEl, setHoverAnchorEl] = React.useState<Nullable<Element>>(null);
    const [removeCollaboratorsModalOpen, setRemoveCollaboratorsModalOpen] = React.useState(false);

    return (
        <>
            <div
                css={css`
                    display: flex;
                `}
                onMouseEnter={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
                    setHoverAnchorEl(event.currentTarget);
                }}
                onMouseLeave={() => setHoverAnchorEl(null)}
            >
                <Avatar
                    css={css`
                        margin-right: -12px;
                        border: 2px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
                        ${collaboratorIsPendingAdd(collaborator) ? 'filter: grayscale(100%);' : ''}
                    `}
                    user={{ ...collaborator, imageUrl: collaborator.imageUrl ?? '' }}
                />

                <Popover
                    css={css`
                        pointer-events: none;
                    `}
                    open={!!hoverAnchorEl}
                    anchorEl={hoverAnchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Row
                        css={css`
                            padding: 10px;
                            pointer-events: all;

                            align-items: center;
                        `}
                    >
                        <div
                            css={css`
                                user-select: none;
                            `}
                        >
                            {collaborator.firstName
                                ? collaborator.firstName + ' ' + collaborator.lastName
                                : collaborator.email}
                        </div>
                        {!collaboratorIsPendingAdd(collaborator) && (
                            <button
                                css={css`
                                    padding: 0;
                                    color: inherit;
                                    border: inherit;

                                    cursor: pointer;

                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    margin-top: 2px;
                                `}
                                onClick={() => setRemoveCollaboratorsModalOpen(true)}
                            >
                                <CloseIcon
                                    css={css`
                                        width: 20px;
                                        height: 20px;
                                        color: ${colorFns.primaryAction};
                                        cursor: pointer;

                                        stroke: ${colorFns.primaryAction};
                                        stroke-width: 2px;
                                    `}
                                />
                            </button>
                        )}
                    </Row>
                </Popover>
            </div>
            <RemoveCollaboratorModal
                open={removeCollaboratorsModalOpen}
                closeModal={() => setRemoveCollaboratorsModalOpen(false)}
                collaborator={collaborator}
            />
        </>
    );
};

const CollaboratorsHeaderSection = () => {
    const [collaboratorsHoverAnchor, setCollaboratorsHoverAnchor] = React.useState<Nullable<Element>>(null);
    const [collaboratorsFlyoutAnchor, setCollaboratorsFlyoutAnchor] = React.useState<Nullable<Element>>(null);
    const [plannerHoverAnchor, setPlannerHoverAnchor] = React.useState<Nullable<Element>>(null);

    const { event } = useEvent();
    const planner = event.plannedBy;

    const { collaborators } = useEventCollaborators();

    return (
        <>
            {event.editable && (
                <SmallCircleButton
                    css={css`
                        margin-right: 12px;
                    `}
                    onClick={event => {
                        setCollaboratorsFlyoutAnchor(event.currentTarget);
                        setCollaboratorsHoverAnchor(null);
                    }}
                    onMouseEnter={event => setCollaboratorsHoverAnchor(event.currentTarget)}
                    onMouseLeave={() => setCollaboratorsHoverAnchor(null)}
                >
                    <PlusIcon />
                </SmallCircleButton>
            )}
            <Popover
                css={css`
                    pointer-events: none;
                `}
                open={!!collaboratorsHoverAnchor}
                anchorEl={collaboratorsHoverAnchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div
                    css={css`
                        margin: 10px;
                    `}
                >
                    Add Collaborators
                </div>
            </Popover>

            <CollaboratorsFlyout
                anchor={collaboratorsFlyoutAnchor}
                onClose={() => setCollaboratorsFlyoutAnchor(null)}
            />

            {collaborators.map(collaborator => (
                <CollaboratorAvatarWithPopover key={collaborator.email} collaborator={collaborator} />
            ))}

            <Avatar
                css={css`
                    margin-right: 12px;
                    border: 2px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};

                    /* Avatars have a filter prop that move them to a higher stacking context, this is to put them on the same layer*/
                    filter: grayscale(0);
                `}
                user={planner}
                onMouseEnter={(event: React.MouseEvent<HTMLElement, MouseEvent>) =>
                    setPlannerHoverAnchor(event.currentTarget)
                }
                onMouseLeave={() => setPlannerHoverAnchor(null)}
            />

            <Popover
                css={css`
                    pointer-events: none;
                `}
                open={!!plannerHoverAnchor}
                anchorEl={plannerHoverAnchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div
                    css={css`
                        padding: 10px;
                    `}
                >
                    {planner.firstName + ' ' + planner.lastName} (Meeting Owner)
                </div>
            </Popover>
        </>
    );
};

export default CollaboratorsHeaderSection;
