import React from 'react';
import styled from 'styled-components/macro';

import { moveDayBy, mergeDateTime } from '../utils/date_util';
import { parseISO } from 'date-fns';
import { ProTip } from '../components/ProTip';
import { Column, Copy, Row, Spacer } from '../ui';

import { LargeHeadline } from '../components/ui/Headline';

import Day, { AddButton } from '../components/Planner/Day';

import { contentWidth } from '../shared';
import { EventContext } from '../providers/event';
import { useUser } from '../providers/user'; // TODO: Dates-Flexible / Multiple Event Spaces | Remove this toggle to make this feature go live

const Tips = styled(Column)`
    width: 440px;

    div:not(:last-child) {
        margin-bottom: 24px;
    }
`;

const Content = styled(Row)`
    justify-content: space-between;
`;

export default function Planner() {
    const { event, template, onAddDay, onRemoveDay, onDayChange } = React.useContext(EventContext);
    const { user } = useUser(); // TODO: Dates-Flexible / Multiple Event Spaces | Remove this toggle to make this feature go live
    const TOGGLE_MULTIPLE_SPACES = user?.team?.id === 9; // TODO: Dates-Flexible / Multiple Event Spaces | Remove this toggle to make this feature go live
    const teamCompliance = user?.team?.compliance || {};
    const schedule = event.schedule;
    const proTips = [...user.team?.proTips, ...template?.proTips].filter(proTip => proTip.section === 'planner') ?? [];

    const earliestDate = schedule[0]?.startDate && mergeDateTime(schedule[0].startDate, '00:00');

    return (
        <Content>
            <Column>
                <LargeHeadline>Planner</LargeHeadline>
                <Copy style={{ width: contentWidth }}>
                    Every great meeting was once a blank canvas – and here’s yours! We’ll build the structure and
                    details of your day, including meeting spaces, guest rooms, A/V and catering.
                </Copy>
                <Spacer />
                <Spacer />
                <Row>
                    <Column style={{ width: '696px', marginRight: '24px' }}>
                        {event.editable && earliestDate && new Date() <= parseISO(earliestDate) && (
                            <AddButton onClick={() => onAddDay({ append: false })}>Add a day before</AddButton>
                        )}
                        <Spacer />
                        {schedule.map((day, index) => {
                            const { startDate: prevStartDate } = schedule[index - 1] || {};
                            const { startDate: nextStartDate } = schedule[index + 1] || {};
                            day.selectRangeStart =
                                prevStartDate && index > 0
                                    ? parseISO(mergeDateTime(moveDayBy(prevStartDate, 1), '00:00'))
                                    : new Date();
                            day.selectRangeEnd =
                                nextStartDate && index < schedule.length - 1
                                    ? parseISO(mergeDateTime(moveDayBy(nextStartDate, -1), '00:00'))
                                    : undefined;

                            return (
                                <Day
                                    beginExpanded={index === schedule.length - 1}
                                    TOGGLE_MULTIPLE_SPACES={TOGGLE_MULTIPLE_SPACES} // TODO: Dates-Flexible / Multiple Event Spaces | Remove this toggle to make this feature go live
                                    teamCompliance={teamCompliance}
                                    day={day}
                                    index={index}
                                    key={index}
                                    onChange={(newValue: Bizly.Day) => onDayChange(index, newValue)}
                                    onRemove={schedule.length > 1 ? () => onRemoveDay(index) : undefined}
                                />
                            );
                        })}
                        {event.editable && (
                            <AddButton onClick={() => onAddDay({ append: true })}>Add a day after</AddButton>
                        )}
                    </Column>
                    {proTips && (
                        <Tips>
                            {proTips.map(tip => (
                                <ProTip key={tip.id} message={tip.content} />
                            ))}
                        </Tips>
                    )}
                </Row>
            </Column>
        </Content>
    );
}
