import React from 'react';
import styled from 'styled-components';

import colorFns from 'colorFns';

const Circle = styled.div<{ fill: string }>`
    width: 40px;
    height: 40px;
    border-radius: 40px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: ${({ fill }) => fill};
    color: ${colorFns.pureWhite.alpha(0.75)};
`;

const CircleImg = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 40px;

    object-fit: cover;
`;

export default React.forwardRef(function CircleImage(
    {
        src,
        fallback,
        fallbackColor,
        className,
        ...props
    }: {
        src?: string;
        fallback: string;
        fallbackColor: string;
        className?: string;
    },
    ref: React.Ref<HTMLImageElement>
) {
    const [err, setErr] = React.useState(src ? false : true);
    return err ? (
        <Circle ref={ref} fill={fallbackColor} className={className} {...props}>
            {fallback}
        </Circle>
    ) : (
        <CircleImg ref={ref} src={src} alt={fallback} onError={() => setErr(true)} className={className} {...props} />
    );
});
