import { invert } from 'lodash';

export const services = {
    AdobeConnect: 1,
    BlueJeansVideoConferencing: 2,
    CiscoWebEx: 3,
    GoogleHangouts: 4,
    GoToMeeting: 5,
    GoToWebinar: 6,
    Livestorm: 7,
    MicrosoftLiveMeeting: 8,
    ON24: 9,
    Skype: 10,
    TANDBERGVideoConferencing: 11,
    WebMeeting: 12,
    WorkplacebyFacebook: 13,
    ZohoMeeting: 14,
    Zoom: 15,
    Other: 16,
};

export const serviceToRegexp = {
    [services.Zoom]: /^http(s?):\/\/(www.)?(.*.)?zoom.us\/.*$/,
    [services.GoogleHangouts]: /^http(s?):\/\/(www.)?(.*.)?meet.google.com\/.*$/,
    [services.AdobeConnect]: /^http(s?):\/\/(www.)?(.*.)?adobeconnect.com\/.*$/,
    [services.BlueJeansVideoConferencing]: /^http(s?):\/\/(www.)?(.*.)?bluejeans.com\/.*$/,
    [services.CiscoWebEx]: /^http(s?):\/\/(www.)?(.*.)?webex.com\/.*$/,
    [services.GoToMeeting]: /^http(s?):\/\/(www.)?(.*.)?gotomeeting.com\/.*$/,
    [services.GoToWebinar]: /^http(s?):\/\/(www.)?(.*.)?gotowebinar.com\/.*$/,

    [services.Livestorm]: /^http(s?):\/\/(www.)?(.*.)?livestorm.co\/.*$/,
    [services.MicrosoftLiveMeeting]: /^$/,
    [services.ON24]: /^$/,
    [services.Skype]: /^http(s?):\/\/(www.)?(.*.)?lync.com\/.*$/,

    [services.TANDBERGVideoConferencing]: /^$/,
    [services.WebMeeting]: /^$/,
    [services.WorkplacebyFacebook]: /^$/,
};

export const idToConnector: Partial<{ [id: number]: BizlyAPI.Connector }> = {
    [services.Zoom]: 'zoom',
    [services.GoogleHangouts]: 'google',
    [services.MicrosoftLiveMeeting]: 'ms-graph',
};

export const connectorToId: Partial<{ [connector in BizlyAPI.Connector]: number }> = invert(idToConnector);

const serviceIds = Object.keys(serviceToRegexp).map(id => parseInt(id)) as (keyof typeof serviceToRegexp)[];

export const getServiceProviderId = (url: string) => {
    const serviceId = serviceIds.find(id => serviceToRegexp[id].test(url));
    return serviceId ?? services.Other;
};

const connectorToProvider: Record<BizlyAPI.Connector, keyof typeof services> = {
    zoom: 'Zoom',
    'ms-graph': 'MicrosoftLiveMeeting',
    google: 'GoogleHangouts',
};

export const connectorToProviderId = (connector: BizlyAPI.Connector) => services[connectorToProvider[connector]];
