import React from 'react';

function useKey(key: string, callback: () => any) {
    function handleKeyDown(event: KeyboardEvent) {
        if (event.key === key) {
            callback();
        }
    }

    React.useEffect(() => {
        window.document.addEventListener('keydown', handleKeyDown, false);

        return () => window.document.removeEventListener('keydown', handleKeyDown, false);
    });
}

export default useKey;
