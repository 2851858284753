import React from 'react';
import styled from 'styled-components';

import { TGoogleAuth, TMSAuth } from './types';

import UIButton from 'components/ui/Button';
import { Copy } from 'ui';

import GoogleAuthentication from './GoogleAuthentication';
import MSAuthenticationButton from './MSAuthentication';

const Button = styled(UIButton)`
    height: 40px;
    margin-top: 8px;

    font-size: 16px;
    font-weight: 400;

    display: block;
    width: 100%;
    border-radius: 8px;
`;

const DividerText = styled(Copy)`
    margin: 24px 0;
    font-size: 18px;
    line-height: 15px;
    text-align: center;
`;

const NextButton = styled(UIButton)`
    align-self: flex-end;
`;

const ContinueAsButton = ({ value, ...props }: Parameters<typeof UIButton>[any]) => (
    <UIButton {...props}>{`Continue as ${value}`}</UIButton>
);

type TAuthFields = {
    onSendLink?: (e: React.SyntheticEvent) => void;
    onVerifyEmail?: (e: React.SyntheticEvent) => void;
    onSubmitForm?: (e: React.SyntheticEvent) => void;
    onGoogleAuthSuccess?: (e: TGoogleAuth) => void;
    onMSAuthSuccess?: (e: TMSAuth) => void;
    onContinueAs?: (e: React.SyntheticEvent) => void;
};

export const makeAuthFields = ({
    onSendLink,
    onVerifyEmail,
    onSubmitForm,
    onGoogleAuthSuccess,
    onMSAuthSuccess,
    onContinueAs,
}: TAuthFields) => ({
    ...(onContinueAs
        ? {
              preAuthEmail: {
                  type: ContinueAsButton,
                  options: {
                      onClick: onContinueAs,
                      width: 'auto',
                  },
              },
          }
        : {}),
    emailLabel: {
        prompt: '',
        type: null,
    },
    email: {
        type: 'text',
        perRow: '3/3',
        options: {
            placeholder: 'hello@howareyou.com',
        },
    },
    ...(onSendLink
        ? {
              sendLinkButton: {
                  type: Button,
                  options: {
                      onClick: onSendLink,
                      children: 'Send magic link',
                  },
              },
          }
        : {}),
    ...(onVerifyEmail
        ? {
              verifyEmailButton: {
                  type: Button,
                  options: {
                      onClick: onVerifyEmail,
                      children: 'Verify Email',
                  },
              },
          }
        : {}),
    teamName: {
        prompt: 'Company Name',
        type: 'text',
        options: {
            placeholder: 'Enter Company Name',
        },
    },
    ...(onSubmitForm && {
        submitTeamName: {
            type: NextButton,
            options: {
                children: 'Next',
                onClick: onSubmitForm,
            },
        },
    }),
    dividerText: {
        type: DividerText,
        options: {
            children: 'or',
        },
    },
    ...(onGoogleAuthSuccess
        ? {
              signInWithGoogle: {
                  type: GoogleAuthentication,
                  options: {
                      buttonText: 'Sign in with Google',
                      onSuccess: onGoogleAuthSuccess,
                  },
              },
              signUpWithGoogle: {
                  type: GoogleAuthentication,
                  options: {
                      buttonText: 'Sign up with Google',
                      onSuccess: onGoogleAuthSuccess,
                  },
              },
          }
        : {}),
    ...(onMSAuthSuccess
        ? {
              signInWithMicrosoft: {
                  type: MSAuthenticationButton,
                  options: {
                      buttonText: 'Sign in with Microsoft',
                      onSuccess: onMSAuthSuccess,
                  },
              },
              signUpWithMicrosoft: {
                  type: MSAuthenticationButton,
                  options: {
                      buttonText: 'Sign up with Microsoft',
                      onSuccess: onMSAuthSuccess,
                  },
              },
          }
        : {}),
});

export const makeSignInEmailSchema = ({ onGoogleAuthSuccess, onMSAuthSuccess }: TAuthFields) => [
    {
        fields: ['emailLabel'],
        spacing: false,
    },
    {
        fields: ['email'],
        spacing: false,
    },
    {
        fields: ['sendLinkButton'],
        spacing: false,
    },
    {
        fields: ['dividerText'],
        spacing: false,
    },
    ...(onGoogleAuthSuccess
        ? [
              {
                  fields: ['signInWithGoogle'],
                  spacing: 'small',
              },
          ]
        : []),
    ...(onMSAuthSuccess
        ? [
              {
                  fields: ['signInWithMicrosoft'],
                  spacing: false,
              },
          ]
        : []),
];

export const makeSignUpEmailSchema = ({ onContinueAs, onGoogleAuthSuccess, onMSAuthSuccess }: TAuthFields) => [
    ...(onContinueAs
        ? [
              {
                  fields: ['preAuthEmail'],
                  spacing: false,
              },
              {
                  key: 'preAuthEmailDivider',
                  fields: ['dividerText'],
                  spacing: false,
              },
          ]
        : []),
    {
        fields: ['emailLabel'],
        spacing: false,
    },
    {
        fields: ['email'],
        spacing: false,
    },
    {
        fields: ['verifyEmailButton'],
        spacing: false,
    },
    {
        fields: ['dividerText'],
        spacing: false,
    },
    ...(onGoogleAuthSuccess
        ? [
              {
                  fields: ['signUpWithGoogle'],
                  spacing: 'small',
              },
          ]
        : []),
    ...(onMSAuthSuccess
        ? [
              {
                  fields: ['signUpWithMicrosoft'],
                  spacing: false,
              },
          ]
        : []),
];

export const signUpCompanySchema = [
    {
        fields: ['teamName'],
        spacing: 'default',
    },
    {
        fields: ['submitTeamName'],
        spacing: false,
    },
];
