import React from 'react';
import { ContentBlock, ContentState, CharacterMetadata } from 'draft-js';

import { ExternalLink } from 'ui';

function findLinkEntities(
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState
) {
    contentBlock.findEntityRanges((character: CharacterMetadata) => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
    }, callback);
}

const Link = ({
    contentState,
    entityKey,
    children,
}: {
    contentState: ContentState;
    entityKey: string;
    children: React.ReactNode;
}) => {
    const { url } = contentState.getEntity(entityKey).getData();
    return <ExternalLink href={url}>{children}</ExternalLink>;
};

export default {
    strategy: findLinkEntities,
    component: Link,
};
