import React from 'react';
import styled from 'styled-components';
import useShowModal from 'hooks/useShowModal';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';

import { LoadContacts, useContacts } from 'stores/contacts';
import { contactGroupsActions, LoadContactGroups, useContactGroups } from 'stores/contact-groups';

import Button from 'components/ui/Button';
import { Dialog } from '@material-ui/core';
import { Column, Copy, InlineRow, Row } from 'ui';
import { Tab, TabsSecondary } from 'components/Tabs';
import TextButton from 'components/ui/Button/TextButton';
import SmallCircleButton from 'components/ui/SmallCircleButton';
import { SelectField as FormSelectField } from 'components/FormFields';
import Form from 'components/Form';

import MuiSearchIcon from '@material-ui/icons/Search';
import { ReactComponent as PencilIconSVG } from 'images/icons/pencil.svg';
import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import { ReactComponent as CheckMarkIcon } from 'images/icons/check-mark.svg';
import { withInteractibleIconStyles } from 'shared';
import colorFns from 'colorFns';
import { SpinnerOverlay } from 'components/Spinner';
import { useSnackbar } from 'notistack';

const SearchIcon = styled(MuiSearchIcon)`
    color: ${colorFns.pureWhite.alpha(0.74)};
`;

const EditIcon = styled(withInteractibleIconStyles(PencilIconSVG)).attrs({ viewBox: '1.5 2.5 18 21.5' })`
    height: 21px;
    width: 21px;
    color: ${colorFns.pureWhite.alpha(0.74)};
`;

const CardDialog = styled(Dialog)`
    .MuiPaper-root {
        background: ${colorFns.darkGrey};
        border: 0px solid ${colorFns.darkGrey};
        color: unset;

        padding: 24px;

        min-width: 450px;
        height: 600px;
        max-height: 70vh;
    }
`;

const FlexCol = styled(Column)`
    flex: 1 0 0;
    min-height: 0;
`;

const ContactsColumn = styled(FlexCol)`
    width: 100%;
    max-height: 100%;
    overflow: auto;

    padding: 0 6px;
    margin: 0 -6px;
`;

const ButtonContent = styled(InlineRow)`
    padding: 0 8px;
`;

const LetterHeader = styled(Copy)`
    position: sticky;
    top: 0;
    z-index: 1;

    color: ${colorFns.grey};
    border-bottom: 1px solid ${colorFns.grey};
    background: ${colorFns.darkGrey};

    padding: 2px 0;
`;

const ContactRow = styled(Row)`
    color: ${colorFns.lightGrey};
`;

const SelectField = styled(FormSelectField)`
    .MuiSelect-root {
        color: ${colorFns.secondaryTextAction};
    }

    &&&&& > fieldset {
        border: 1px solid ${colorFns.secondaryTextAction}!important;
    }

    border-radius: 8px;
`;

const CheckMark = styled(withInteractibleIconStyles(CheckMarkIcon))`
    width: 16px;
    color: ${colorFns.secondaryTextAction};
`;

const RemoveIcon = styled(PlusIcon)`
    transform: rotateZ(45deg);
`;

const ContactListRow = ({
    contact,
    hasContact,
    onAdd,
    onDelete,
}: {
    contact: BizlyAPI.Contact;
    hasContact?: boolean;
    onAdd?: (contactId: number) => void;
    onDelete?: (contactId: number) => void;
}) => (
    <ContactRow alignItems="center" justifyContent="space-between">
        <Copy small>
            <b>{[contact.firstName, contact.lastName].join(' ')}</b>
        </Copy>
        <InlineRow alignItems="center" itemSpacing="xsmall">
            <Copy small>{contact.email}</Copy>
            {onDelete ? (
                onAdd ? (
                    hasContact ? (
                        <CheckMark onClick={() => onDelete(contact.id)} />
                    ) : (
                        <SmallCircleButton small tertiary onClick={() => onAdd(contact.id)}>
                            <PlusIcon />
                        </SmallCircleButton>
                    )
                ) : (
                    <SmallCircleButton small tertiary onClick={() => onDelete(contact.id)}>
                        <RemoveIcon />
                    </SmallCircleButton>
                )
            ) : null}
        </InlineRow>
    </ContactRow>
);

const NoGroupsMessage = () => (
    <Copy small>
        <b>
            No group with this name currently exists, <br />
            would you like to add?
        </b>
    </Copy>
);

const CreateGroupButton = ({ onCreate, disabled }: { onCreate: () => void; disabled?: boolean }) => (
    <Button onClick={onCreate} width={160} secondary={disabled} disabled={disabled}>
        <Row alignItems="center" justifyContent="center" itemSpacing="small">
            <SmallCircleButton small>
                <PlusIcon />
            </SmallCircleButton>
            <Copy>Add Group</Copy>
        </Row>
    </Button>
);

const fields = (groups: BizlyAPI.ContactGroup[], goToGroups: () => void) => ({
    filterContacts: {
        type: 'text',
        perRow: '3/5',
        options: {
            outlined: true,
            autoFocus: true,
            placeholder: 'Search by name or email',
            InputProps: { startAdornment: <SearchIcon /> },
        },
    },
    groupId: groups.length
        ? {
              type: SelectField,
              perRow: '2/5',
              options: {
                  placeholder: 'Select a group',
                  options: groups,
              },
          }
        : {
              type: () => (
                  <Row style={{ height: '100%' }} alignItems="center" justifyContent="center">
                      <TextButton secondary onClick={goToGroups}>
                          No Groups Available
                      </TextButton>
                  </Row>
              ),
              perRow: '2/5',
          },
    filterGroups: {
        type: 'text',
        perRow: '2/3',
        options: {
            outlined: true,
            autoFocus: true,
            placeholder: groups.length ? 'Search or create group' : 'New Group Name',
            InputProps: { startAdornment: groups.length ? <SearchIcon /> : undefined },
        },
    },
});

const schema = [{ fields: ['filterContacts', 'groupId'], spacing: false }];
const groupsSchema = [{ fields: ['filterGroups'], spacing: false }];

type FilterForm = {
    filterContacts?: string;
    groupId?: number;
    filterGroups?: string;
};

export default function ContactsEditor() {
    const { enqueueSnackbar } = useSnackbar();
    const { modalShown, showModal, hideModal } = useShowModal(false);

    const [tab, setTab] = React.useState<'contacts' | 'groups'>('contacts');

    const [formValue, setFormValue] = React.useState<FilterForm>({});
    const { filterContacts, groupId, filterGroups } = formValue;

    const filter = tab === 'contacts' ? filterContacts?.trim() : filterGroups?.trim();

    const { groups = [], updating, loaded: groupsLoaded } = useContactGroups();
    const { contacts = [], loaded: contactsLoaded } = useContacts();
    const loaded = groupsLoaded && contactsLoaded;

    const selectedGroup = groups.find(group => group.id === groupId);

    const groupedContacts = groupBy(
        contacts.filter(contact =>
            filter?.trim()
                ? (contact.firstName ?? '').includes(filter) ||
                  (contact.lastName ?? '').includes(filter) ||
                  contact.email.includes(filter)
                : contact
        ),
        contact => (contact.firstName || contact.email || '').charAt(0)?.toLowerCase()
    );

    const contactsDict = keyBy(contacts, contact => contact.id);
    const filteredGroups = sortBy(
        groups.filter(group => (filter?.trim() ? group.name.includes(filter) : group)),
        group => group.name
    );
    const groupsWithContacts = filteredGroups.map(group => ({
        ...group,
        contacts: group.userIds.map(id => contactsDict[id]),
    }));

    const addToGroup = async (userId: number, group: BizlyAPI.ContactGroup) => {
        try {
            contactGroupsActions.update({
                ...group,
                userIds: [...group.userIds, userId],
            });
        } catch {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    };

    const delFromGroup = (userId: number, group: BizlyAPI.ContactGroup) => {
        try {
            contactGroupsActions.update({
                ...group,
                userIds: group.userIds.filter(id => id !== userId),
            });
        } catch {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    };

    const renderContactsList = () => (
        <ContactsColumn itemSpacing="xsmall">
            {Object.entries(groupedContacts).map(([letter, contacts]) => (
                <Column itemSpacing="xsmall" key={letter}>
                    <LetterHeader>
                        {letter.toUpperCase()}
                        {letter.toLowerCase()}
                    </LetterHeader>
                    {contacts.map(contact => (
                        <ContactListRow
                            key={contact.id}
                            contact={contact}
                            hasContact={selectedGroup && selectedGroup?.userIds.includes(contact.id)}
                            onAdd={selectedGroup ? contactId => addToGroup(contactId, selectedGroup) : undefined}
                            onDelete={selectedGroup ? contactId => delFromGroup(contactId, selectedGroup) : undefined}
                        />
                    ))}
                </Column>
            ))}
        </ContactsColumn>
    );

    const goToAddToGroup = (groupId: number) => {
        setTab('contacts');
        setFormValue(val => ({ ...val, groupId }));
    };

    const renderGroupsList = () => (
        <ContactsColumn itemSpacing="xsmall">
            {groupsWithContacts.map(group => (
                <Column itemSpacing="xsmall" key={group.id}>
                    <LetterHeader>{group.name}</LetterHeader>
                    {group.contacts.length === 0 && (
                        <Row justifyContent="center">
                            <TextButton secondary onClick={() => goToAddToGroup(group.id)}>
                                Add Contacts
                            </TextButton>
                        </Row>
                    )}
                    {group.contacts.map(contact => (
                        <ContactListRow
                            key={contact.id}
                            contact={contact}
                            hasContact
                            onDelete={contactId => delFromGroup(contactId, group)}
                        />
                    ))}
                </Column>
            ))}
        </ContactsColumn>
    );

    return (
        <>
            <Button onClick={modalShown ? hideModal : showModal} width="auto">
                <ButtonContent alignItems="center" itemSpacing="small">
                    <EditIcon />
                    <Copy>Contacts</Copy>
                </ButtonContent>
            </Button>
            <CardDialog open={modalShown} onBackdropClick={hideModal}>
                <LoadContacts />
                <LoadContactGroups />

                <FlexCol itemSpacing="small">
                    <InlineRow>
                        <TabsSecondary value={tab}>
                            <Tab value="contacts" label="Contacts" onClick={() => setTab('contacts')} />
                            <Tab value="groups" label="Groups" onClick={() => setTab('groups')} />
                        </TabsSecondary>
                    </InlineRow>
                    <Form
                        key={tab}
                        fields={fields(groups, () => setTab('groups'))}
                        schema={tab === 'contacts' ? schema : groupsSchema}
                        value={formValue}
                        onChange={({ value }: { value: FilterForm }) => setFormValue(value)}
                    />

                    {tab === 'contacts' && renderContactsList()}
                    {tab === 'groups' && (
                        <>
                            {filteredGroups.length === 0 && (
                                <Column itemSpacing="small">
                                    {groups.length > 0 && <NoGroupsMessage />}
                                    <InlineRow>
                                        <CreateGroupButton
                                            onCreate={() => filter && contactGroupsActions.create(filter)}
                                            disabled={!filter || updating}
                                        />
                                    </InlineRow>
                                </Column>
                            )}
                            {renderGroupsList()}
                        </>
                    )}
                </FlexCol>
                {!loaded && <SpinnerOverlay />}
            </CardDialog>
        </>
    );
}
