import React from 'react';

import fromPairs from 'lodash/fromPairs';
import keyBy from 'lodash/keyBy';
import he from 'he';
import { format, parseISO } from 'date-fns';
import groupBy from 'lodash/groupBy';
import flattenDeep from 'lodash/flattenDeep';
import omitBy from 'lodash/omitBy';

import Form from 'components/Form';

import { SPACER_SPACES } from 'ui';

import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import { TProposalForm, TProposal, TESBooking, TESRequestKeys } from '../types';
import { isEmptyString, isEmptyStringOrNumber } from '../utils';
import { submittedProposal } from '../statusUtils';

export type TOption = { id: number; name: string; iconUrl: string };

export type TOptionsDictionary<T extends TOption> = Record<number, T>;

export const GUEST_ROOMS_REQUEST_WIDTH = 320;

export const replaceObjInArray = <T extends any>(arr: T[], targetIndex: number, newVal: T) =>
    arr.map((item, index) => (index === targetIndex ? newVal : item));

export const optionsToListForm = <Value, Option, FieldDef, SchemaDefFirst, SchemaDef>({
    options,
    getOptionId,
    optionToField,
    optionToSchema,
    headingsSchema = undefined,
    field,
    value,
    getValueId,
    onChange,
    disabled,
}: {
    options: Option[];
    getOptionId: (option: Option) => string;
    optionToField: (option: Option, index: number) => FieldDef;

    headingsSchema?: SchemaDefFirst;
    optionToSchema: (option: Option, index: number) => SchemaDef;

    field: string;
    value: Value[];
    getValueId: (value: Value) => string;
    onChange: (change: { field: string; value: Value[]; errors: any }) => void;
    disabled?: boolean;
}) => {
    const firstSchema = headingsSchema
        ? {
              key: 'headings',
              spacing: false,
              ...headingsSchema,
          }
        : undefined;

    const props = {
        fields: fromPairs(options.map((option, index) => [getOptionId(option), optionToField(option, index)])),

        schema: [
            ...(firstSchema ? [firstSchema] : []),
            ...options.map((option, index) => ({
                ...optionToSchema(option, index),
                fields: [getOptionId(option)],
                ...(index === options.length - 1 ? { spacing: false } : {}),
            })),
        ],

        value: keyBy(value, getValueId),

        onChange: ({ value: newVal, errors }: { value: Record<string, Value>; errors: any }) => {
            onChange({
                field,
                value: Object.values(newVal).filter(value => value !== undefined),
                errors,
            });
        },
        disabled: disabled,
    };

    return <Form {...props} />;
};

export const EVENT_SPACES_REQUEST_ITEM_SPACING = 'large';
export const EVENT_SPACES_REQUEST_WIDTH = 200 + SPACER_SPACES[EVENT_SPACES_REQUEST_ITEM_SPACING];
export const OPTIONS_RADIO_CHECKBOX_WIDTH = 180;

type TAVResponse = {
    id: number;
    response?: boolean;
};

// FB Specific:
export type TFBOption = TOption & { hasDiningStyle: boolean };
export type TFBRequest = {
    fbOptionId: number;
    diningStyleId: number | null;
};
export type TFBResponse = TFBRequest & { response?: boolean };
// an object that combines the FB request and DS request
export type TFBOptionRequest = {
    fbId: number;
    fbName: string;
    fbIconUrl: string;

    hasDiningStyle: boolean;

    dsId?: number;
    dsName?: string;
    dsIconUrl?: string;
};

// revisit, this ranks food options (breakfast, lunch, dinner) above others (afternoon break, reception, etc.)
const FB_RANKING = {
    2: 1,
    4: 2,
    3: 3,

    6: 4,
    5: 5,
    1: 6,
} as { [key: number]: number };

const getFBRanking = (id: number) => FB_RANKING[id] || Infinity;
export const sortFBRequest = (request: TFBRequest[]) =>
    request.sort((a, b) => getFBRanking(a.fbOptionId) - getFBRanking(b.fbOptionId));

/*

VENUE SPACES

*/

type TImage = {
    id: number;
    name: string;
    description: string;
    srcUrl: string;
};

export type TSpace = {
    id: number;
    name: string;
    description: string | null;
    size: number | string | null;
    maxCapacity: number | string | null;
    images: TImage[];
};

export const sanitizedSpaces = (spaces: TSpace[]) =>
    spaces.map(space => ({
        ...space,
        description: space?.description && he.decode(space.description.replace(/(<([^>]+)>)/gi, '')),
    }));

export const SPECIAL_OPTION_IDS = {
    none: -1,
    create: -2,
} as const;

export const selectedASpace = (id?: number | null) =>
    id && id !== SPECIAL_OPTION_IDS.none && id !== SPECIAL_OPTION_IDS.create;

export const NEWLY_CREATED_SPACE = {
    id: SPECIAL_OPTION_IDS.create,
    name: '',
    description: '',
    size: null,
    maxCapacity: null,
    images: [],
};

export const spacesToOptions = (spaces: TSpace[], includeNoSpace?: boolean) => [
    ...(includeNoSpace ? [{ id: SPECIAL_OPTION_IDS.none, name: 'No Space Available', emphasize: true }] : []),
    ...spaces.map(({ id, name }) => ({
        id,
        name,
    })),
    { id: SPECIAL_OPTION_IDS.create, name: 'Create a New Space', emphasize: true, icon: <PlusIcon /> },
];

/*

MAIN FORM UTILS

*/

type TESBookingResponse = Omit<TESBooking, 'proposedAvIds' | 'proposedFb'> & {
    proposedAvIds: TAVResponse[];
    proposedFb: TFBResponse[];
};

type TFees = Pick<TProposal, 'gratuity' | 'salesTax' | 'serviceCharge'>;

export type TESFormBooking = TESBookingResponse & TFees;

export type TEventSpacesFormValue = {
    eventSpacesByDay: TESFormBooking[][];
};

const spacesByDay = (eventSpaces: TESBookingResponse[]) =>
    Object.values(groupBy(eventSpaces, space => space.requestedDate));

const NO_PRICE = -1;

const proposedEqualRequested = (eventSpaces: TESBooking[], proposalIsSubmitted: boolean) =>
    eventSpaces.map(({ proposedAvIds, proposedFb, proposedRoomRate, proposedFbMinimum, ...space }) => ({
        ...space,
        proposedDate: space.requestedDate,
        proposedStartTime: space.requestedStartTime,
        proposedEndTime: space.requestedEndTime,
        proposedSetupId: space.requestedSetupId,
        proposedRoomRate: proposedRoomRate === NO_PRICE ? null : proposedRoomRate,
        proposedFbMinimum: proposedFbMinimum === NO_PRICE ? null : proposedFbMinimum,

        proposedVenueSpaceId:
            proposalIsSubmitted && space.proposedVenueSpaceId === null
                ? SPECIAL_OPTION_IDS.none
                : space.proposedVenueSpaceId,

        proposedFb: (space.requestedFb || []).map(fb => ({
            ...fb,
            response: space.proposalSpaceId
                ? (proposedFb || []).some(pfb => pfb.fbOptionId === fb.fbOptionId)
                : undefined,
        })),
        proposedAvIds: (space.requestedAvIds || []).map(av => ({
            id: av,
            response: space.proposalSpaceId ? (proposedAvIds || []).some(pav => pav === av) : undefined,
        })),
    }));

export const setFeeTaxOnAllES = (
    esBookingsByDay: (TESBookingResponse & Partial<TFees>)[][],
    {
        gratuity,
        salesTax,
        serviceCharge,
    }: {
        gratuity?: TProposal['gratuity'];
        salesTax?: TProposal['salesTax'];
        serviceCharge?: TProposal['serviceCharge'];
    }
) =>
    esBookingsByDay.map(dayBookings =>
        dayBookings.map(booking => ({
            ...booking,
            gratuity: gratuity !== undefined ? gratuity : booking?.gratuity || null,
            salesTax: salesTax !== undefined ? salesTax : booking?.salesTax || null,
            serviceCharge: serviceCharge !== undefined ? serviceCharge : booking?.serviceCharge || null,
        }))
    );

export const proposalFormToFormData = ({ eventSpaces = [], proposal = {} }: Partial<TProposalForm>) => {
    const { gratuity = null, salesTax = null, serviceCharge = null } = proposal;
    return {
        eventSpacesByDay: setFeeTaxOnAllES(
            spacesByDay(proposedEqualRequested(eventSpaces, submittedProposal(proposal))),
            {
                gratuity,
                salesTax,
                serviceCharge,
            }
        ),
    };
};

type TESFormBookingProposal = Omit<TESFormBooking, TESRequestKeys>;

export const formDataToProposalForm = ({ eventSpacesByDay = [] }: TEventSpacesFormValue) => {
    const eventSpacesBookings = flattenDeep(eventSpacesByDay);
    const eventSpacesProposals = eventSpacesBookings.map(booking => ({
        ...(omitBy<TESFormBookingProposal>(booking, (value, key) => key.includes('request')) as TESFormBookingProposal),

        proposedFb: booking.proposedFb
            .filter(pfb => pfb.response)
            .map(({ fbOptionId, diningStyleId }) => ({ fbOptionId, diningStyleId })),

        proposedAvIds: booking.proposedAvIds.filter(pav => pav.response).map(({ id }) => id),
    }));

    const { gratuity = null, salesTax = null, serviceCharge = null } = eventSpacesProposals[0] || {};

    const formattedBookings = eventSpacesProposals.map(
        ({ gratuity, salesTax, serviceCharge, proposedRoomRate, proposedFbMinimum, ...booking }) => ({
            ...booking,
            proposedRoomRate: isEmptyStringOrNumber(proposedRoomRate) ? NO_PRICE : proposedRoomRate,
            proposedFbMinimum:
                booking.proposedFb.length === 0
                    ? proposedFbMinimum || 0
                    : isEmptyStringOrNumber(proposedFbMinimum)
                    ? NO_PRICE
                    : proposedFbMinimum,
            ...(booking.proposedVenueSpaceId === SPECIAL_OPTION_IDS.none
                ? {
                      proposedVenueSpaceId: null,
                      proposalSpaceId: null,
                      proposedAvIds: [],
                      proposedFb: [],
                      proposedSetupId: null,
                  }
                : {}),
        })
    );

    return {
        eventSpaces: formattedBookings,
        proposal: { gratuity, salesTax, serviceCharge },
    };
};

const isMissingInfo = (
    {
        proposedStartTime,
        proposedEndTime,
        proposedVenueSpaceId,
        proposedSetupId,
        proposedAvIds,
        proposedFb,
        proposedRoomRate,
        proposedFbMinimum,
        gratuity,
        salesTax,
        serviceCharge,
    }: TESFormBooking,

    blockPartial?: boolean
) => {
    if (proposedVenueSpaceId === SPECIAL_OPTION_IDS.none) return false;
    if (!proposedVenueSpaceId) {
        return blockPartial ? 'respond with a space or "No Space Available"' : false;
    }

    // has proposedVenueSpaceId
    if (isEmptyString(proposedStartTime) || isEmptyString(proposedEndTime) || !proposedSetupId)
        return 'fill out the time and setup field';

    if (proposedAvIds.length > 0 && proposedAvIds.some(pav => typeof pav.response !== 'boolean'))
        return 'respond to all Audio/Visual requests';

    if (proposedFb.length > 0 && proposedFb.some(pfb => typeof pfb.response !== 'boolean'))
        return 'respond to all Food/Beverage requests';

    if (blockPartial && proposedFb.some(pfb => pfb.response === true) && isEmptyStringOrNumber(proposedFbMinimum))
        return 'specify the food and beverage minimum';

    if (
        blockPartial &&
        (isEmptyStringOrNumber(proposedRoomRate) ||
            proposedRoomRate === NO_PRICE ||
            isEmptyStringOrNumber(gratuity) ||
            isEmptyStringOrNumber(salesTax) ||
            isEmptyStringOrNumber(serviceCharge))
    )
        return 'fill out pricing fields';

    return false;
};

const findFirstMissingInfo = (eventSpacesByDay: TESFormBooking[][], blockPartial?: boolean) => {
    let dayIdx = -1;
    let spaceIdx = -1;
    eventSpacesByDay.some((dayBookings, dayIndex) => {
        const spaceIndex = dayBookings.findIndex(booking => isMissingInfo(booking, blockPartial));
        if (spaceIndex !== -1) {
            dayIdx = dayIndex;
            spaceIdx = spaceIndex;
            return true;
        }
        return false;
    });

    return { dayIdx, spaceIdx };
};

export const getErrorMessage = ({ eventSpacesByDay }: TEventSpacesFormValue, isContinue?: boolean) => {
    const { dayIdx, spaceIdx } = findFirstMissingInfo(eventSpacesByDay, isContinue);

    if (dayIdx !== -1 && spaceIdx !== -1) {
        const { requestedDate } = eventSpacesByDay[dayIdx][spaceIdx];
        const error = isMissingInfo(eventSpacesByDay[dayIdx][spaceIdx], isContinue);

        const formattedDate = format(parseISO(requestedDate + 'T00:00:00Z'), 'EEEE, MMMM dd, yyyy');
        return (
            <span>
                {`Please make sure to ${error} for:`}
                <br />
                <br />
                {`Day ${dayIdx + 1} - ${formattedDate}`}
                <br />
                {`Space ${spaceIdx + 1}`}
            </span>
        );
    }
};
