import React from 'react';
import styled from 'styled-components/macro';

import { Column, Row, Copy } from 'ui';
import { H3Headline } from 'components/ui/Headline';

const Head = styled.div`
    padding: 24px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.strongAccentedBackground)};
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

const Headline = styled(Row)`
    display: flex;

    img {
        height: 43px;
        width: 43px;
    }
`;

type TBaseVenue = {
    name?: string;
    icon?: string;
};

const MessageHead = <TVenue extends TBaseVenue>({
    venue,
    pending,
    className,
}: {
    venue: TVenue;
    pending?: boolean;
    className?: string;
}) => (
    <Head className={className}>
        <Column itemSpacing="default">
            <Headline alignItems="center" itemSpacing="small">
                {venue.icon && <img src={venue.icon} alt={`${venue.name} icon`} />}
                <H3Headline>{venue.name}</H3Headline>
            </Headline>
            <Copy small>
                Have a question?
                <br />
                {`Send ${venue.name} a message.`}
            </Copy>
        </Column>
    </Head>
);

export default MessageHead;
