import React from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';

import { meetingsActions, Meeting } from 'stores/meetings';

import Button from 'components/ui/Button';
import { Copy, InlineRow, Spacer } from 'ui';
import { ReactComponent as PlusSVG } from 'images/icons/plus.svg';
import colorFns from 'colorFns';

const PlusIcon = styled(PlusSVG)`
    width: 12px;
    height: 12px;
    padding: 4px;

    background-color: ${colorFns.darkGrey};
    color: ${colorFns.pureWhite};

    border-radius: 50%;
`;

const AlertsButton = styled(Button)`
    padding: 0 12px;

    background: ${colorFns.pureWhite} !important;
    color: ${colorFns.darkGrey} !important;
    border-color: transparent;

    &:hover {
        background: ${colorFns.lightGrey} !important;
    }

    ${({ disabled, ...props }) =>
        disabled &&
        `
    background: ${colorFns.grey(props)} !important;
    border-color: ${colorFns.grey(props)} !important;

    &:hover {
        background: ${colorFns.grey(props)} !important;
    }
    `}
`;

const RequestMessage = styled(Copy)`
    color: ${colorFns.lightGrey};
`;

const PendingMessage = styled(Copy)`
    color: ${colorFns.lightGrey};
`;

export default function RequestSubsume({ meeting }: { meeting: Meeting }) {
    const { enqueueSnackbar } = useSnackbar();

    const makeRequest = async () => {
        if (!meeting.calId) return;
        
        try {
            await meetingsActions.requestSubsume(meeting.calId);
        } catch (e) {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    };

    return (
        <>
            <RequestMessage>
                {!meeting.authorSignedUp ? (
                    <>
                        It looks like the owner may be new to Bizly,
                        <br />
                        would you like to request that they bring this meeting into Bizly?
                    </>
                ) : (
                    <>
                        It looks like this meeting was created outside Bizly,
                        <br />
                        would you like to request that the owner convert it to a Bizly Meeting?
                    </>
                )}
            </RequestMessage>

            <Spacer medium />
            <InlineRow
                alignItems="center"
                itemSpacing="smallish"
                style={meeting.subsumptionRequested ? { cursor: 'not-allowed' } : {}}
            >
                <AlertsButton onClick={makeRequest} width="auto" disabled={meeting.subsumptionRequested}>
                    <InlineRow alignItems="center" itemSpacing="small">
                        <PlusIcon />
                        <Copy>Request Owner</Copy>
                    </InlineRow>
                </AlertsButton>
                {meeting.subsumptionRequested && (
                    <PendingMessage>
                        {!meeting.authorSignedUp
                            ? 'Waiting for user to join...'
                            : 'Waiting for user to convert meeting...'}
                    </PendingMessage>
                )}
            </InlineRow>
        </>
    );
}
