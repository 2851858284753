import { AutocompleteProps, RenderOptionState } from '@material-ui/lab/Autocomplete';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';

import { TStyledTheme } from 'theme';

/* 
    We re-type AutoComplete to:
        1. fix some of the types that rely on Value and Options
        2. disable some props due to design constriants
*/

type Retype = 'onInputChange'; // replaced with simpler version
type Disable =
    | 'inputValue' // controlling inputValue is too complex
    | 'renderInput' // design constraint, use InputProps
    | 'renderTags' // design constraint, use renderTag
    | 'autoHighlight' // design constraint, we always want to highlight first option first
    | 'autoSelect' // design constraint, never use
    | 'disablePortal' // design constraint, never use
    | 'disableClearable'; // design constraint, never use

// Retype to enforce match between options and value
type Generics =
    | 'value'
    | 'defaultValue'
    | 'onChange'
    | 'options'
    | 'renderOption'
    | 'getOptionLabel'
    | 'getOptionSelected'
    | 'getOptionDisabled'
    | 'filterOptions'
    | 'groupBy'
    | 'render';

type Multiple = 'multiple';

type FreeSolo = 'freeSolo';

type OmitProps = Retype | Disable | Generics | Multiple | FreeSolo;

type CleanAutocompleteProps = Omit<AutocompleteProps, OmitProps>;

type NewProps<Option, TMultiple> = Partial<{
    onInputChange: (val: string) => void;
    InputProps: {
        placeholder?: string;
        startAdornment?: React.ReactNode;
        endAdornment?: React.ReactNode;
        autoFocus?: boolean;
    };
    asField: boolean;
    inModalOrPopover: boolean;
}> &
    // We are conditionally adding the renderTag prop here, this presents a clearer error message
    // when the user improperly adds a value for renderTag
    // It shows that renderTag doesn't exist rather than "renderTag does not match undefined"
    (true extends TMultiple
        ? { multiple: true; renderTag: (tag: Option) => { key: any; component: React.ReactNode } }
        : {});

type RetypedProps<
    Option extends {},
    TMultiple extends boolean | undefined,
    TFreeSolo extends boolean | undefined,
    Value = true extends TMultiple ? Option[] : Option
> = Partial<{
    multiple?: TMultiple;
    freeSolo?: TFreeSolo;

    value?: Value;
    defaultValue?: Value;
    onChange?: (e: React.ChangeEvent<{}>, value: true extends TFreeSolo ? (Value | string)[] : Value) => void;
    options?: Option[];
    renderOption?: (option: Option, state: RenderOptionState) => React.ReactNode;
    getOptionLabel?: (option: Option) => string;
    getOptionSelected?: (option: Option, value: Option) => boolean;
    getOptionDisabled?: (option: Option) => boolean;
    filterOptions?: (options: Option[], state: FilterOptionsState) => Option[];
    groupBy?: (option: Option) => string;
}>;

type TAutoComplete<
    Option extends {},
    TMultiple extends boolean | undefined,
    TFreeSolo extends boolean | undefined
> = CleanAutocompleteProps & NewProps<Option, TMultiple> & RetypedProps<Option, TMultiple, TFreeSolo>;

export type TAutoCompleteAllProps<TProps> = TProps extends TAutoComplete<infer Option, infer TMultiple, infer TFreeSolo>
    ? TProps & TAutoComplete<Option, true, true> // TProps is needed as we want to expand the original type, not replace it
    : never;

type TAutoCompleteFn = <
    Option extends {},
    TMultiple extends boolean | undefined = undefined,
    TFreeSolo extends boolean | undefined = undefined
>(
    props: TAutoComplete<Option, TMultiple, TFreeSolo>
) => JSX.Element;

export default TAutoCompleteFn;

// Material styles, replace when properly styling inputs
export const MATERIAL_BOX_SHADOW_STYLED_FN = ({ theme: { getColor, EColors } }: { theme: TStyledTheme }) =>
    `box-shadow: 0 2px 4px 0 ${getColor(EColors.pureBlack, 0.06)};`;
export const MATERIAL_BORDER_COLOR_STYLED_FN = ({ theme: { getColor, EColors } }: { theme: TStyledTheme }) =>
    `border-color: ${getColor(EColors.pureBlack, 0.23)};`;

export const INPUT_PADDING = 16;
export const TAG_MARGIN = 6;
export const TAG_HEIGHT = 26;
