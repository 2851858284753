export const stripFirstSymbol = (field: string | number, symbol: string) => field.toString().replace(symbol, '');

export const prependSymbolToAll = (fields: any, symbol: string) => {
    const newFields = { ...fields };

    for (let key in newFields) {
        const value = stripFirstSymbol(newFields[key], symbol);
        newFields[key] = !!Number(value) ? symbol + value : '';
    }

    return newFields;
};

export const stripSymbolFromAll = (fields: any, symbol: string) => {
    const newFields = { ...fields };

    for (let key in newFields) {
        newFields[key] = stripFirstSymbol(newFields[key], symbol);
    }

    return newFields;
};
