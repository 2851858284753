import React, { useContext } from 'react';

import styled, { ThemeContext } from 'styled-components';

import { useUser } from 'providers/user';

import { ReactComponent as BizlyLogo } from 'images/logo.svg';

import { ETeams } from 'theme';

// useUser can throw an error
class DefaultLogo extends React.Component<{ className?: string }> {
    state = {
        useDefault: false,
    };

    static getDerivedStateFromError() {
        return { useDefault: true };
    }

    render() {
        return (
            <div className={this.props.className}>{this.state.useDefault ? <BizlyLogo /> : this.props.children}</div>
        );
    }
}

const Logo = styled.div`
    width: 36px;
    height: 36px;

    background-image: url("${({ src }: { src?: string }) => src}");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top left;
`;

const UserThemedLogo = () => {
    const theme = useContext(ThemeContext);

    // using theme from styled theme instead of user context respects nested overrided themes
    const usingTheme = theme.theme !== ETeams.bizly;
    const { user } = useUser();

    return usingTheme ? <Logo src={user?.team?.imageUrlWhite} /> : <BizlyLogo />;
};

export default function ThemedLogo({ className }: { className?: string }) {
    return (
        <DefaultLogo className={className}>
            <UserThemedLogo />
        </DefaultLogo>
    );
}
