import React from 'react';

import { css } from 'styled-components/macro';

import { useSnackbar } from 'notistack';

import Dialog from '@material-ui/core/Dialog';

import { useEventCollaborators } from '../../providers/event-collaborators';

import TextButton from '../ui/Button/TextButton';

import { AlignedRow, Column } from '../../ui';

import colorFns from 'colorFns';

import useKey from '../../hooks/useKey';

const RemoveCollaboratorModal = ({
    collaborator,
    open,
    closeModal,
}: {
    collaborator: { id: number; email: string; firstName?: string | null; lastName?: string | null };
    open: boolean;
    closeModal: () => void;
}) => {
    useKey('Escape', closeModal);

    const { removeCollaborator } = useEventCollaborators();

    const { enqueueSnackbar } = useSnackbar();

    async function handleRemove() {
        try {
            await removeCollaborator(collaborator.id);
            enqueueSnackbar('Collaborator successfully removed', {
                variant: 'success',
            });
        } catch {
            const errorMessage = `There was an issue removing this collaborator: ${[
                collaborator.firstName ?? '',
                collaborator.lastName ?? '',
            ].join(' ') || collaborator.email}`;
            enqueueSnackbar(errorMessage, {
                variant: 'error',
            });
        }
    }

    return (
        <Dialog open={open} maxWidth="md">
            <Column
                css={css`
                    padding: 32px;

                    width: 400px;
                `}
            >
                <h2
                    css={css`
                        color: ${colorFns.warningText};

                        font-size: 25px;
                        line-height: 1.28;
                        letter-spacing: -0.5px;

                        font-weight: normal;
                        font-style: normal;
                        font-stretch: normal;

                        margin: 0;
                    `}
                >
                    Are you sure?
                </h2>
                <p
                    css={css`
                        font-size: 18px;
                        line-height: 1.5;
                    `}
                >
                    Removing{' '}
                    {[collaborator.firstName ?? '', collaborator.lastName ?? ''].join(' ') || collaborator.email} will
                    completely remove their access from this meeting.
                </p>
                <AlignedRow
                    css={css`
                        justify-content: flex-end;

                        margin-top: 20px;
                    `}
                >
                    <TextButton onClick={closeModal} secondary>
                        Cancel
                    </TextButton>

                    <TextButton warning onClick={handleRemove}>
                        Remove
                    </TextButton>
                </AlignedRow>
            </Column>
        </Dialog>
    );
};

export default RemoveCollaboratorModal;
