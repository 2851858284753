import React from 'react';

import TextField from '@material-ui/core/TextField';

const onKeyPress = (event, submitCallback) => {
    if (submitCallback && event.key === 'Enter' && event.target.value && event.target.value.length > 0) {
        submitCallback(event);
        event.preventDefault();
    }
};

export const StandardInput = props => (
    <TextField margin="dense" onKeyPress={event => onKeyPress(event, props.onSubmit)} {...props} />
);
