import React from 'react';

import { useHistory } from 'react-router';
import queryString from 'query-string';

import { WhiteActionButton } from '../ui';

type TViewAllButton = {
    label: string;
    facets?: BizlyAPI.VenueFacets;
    eventId: number | string;
};

export default function VenueSearchButton({ label, facets = {}, eventId }: TViewAllButton) {
    const history = useHistory();

    const onClick = () => history.push(`/event/${eventId}/venue/search?${queryString.stringify(facets)}`);

    return <WhiteActionButton onClick={onClick}>{label}</WhiteActionButton>;
}
