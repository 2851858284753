import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { tzMoment } from 'utils/moment';

import { Switch, Route, useRouteMatch, useHistory, useParams } from 'react-router-dom';

import { useUser } from 'providers/user';
import { LoadMeeting, selectMeeting, useMeetings } from 'stores/meetings';
import { LoadChimeVideo } from 'stores/chime-video';

import Meetings from 'pages/Meetings';
import CreateMeeting, { NavButtons } from 'pages/CreateMeeting';
import Meeting from 'pages/Meeting';
import SideNav from 'components/SideNav';
import Chime, { ChimeNavButtons } from 'pages/Chime';
import LoadChatMessages from 'pages/Meeting/components/LoadChatMessages';
import { PageNotFound } from 'pages/PageNotFound';

import { SpinnerOverlay } from 'components/Spinner';
import { ReactComponent as BuildingSvg } from 'images/icons/office-location.svg';
import colorFns from 'colorFns';

const BuildingIcon = styled(BuildingSvg).attrs(() => ({ viewBox: '1 0 24 24' }))`
    height: 18px;
    width: 18px;
    margin-left: -2px;
    color: ${colorFns.pureWhite.alpha(0.74)};
`;

const MeetingDetailsPage = () => {
    const { id } = useParams<{ id: string }>();
    const meeting = useMeetings(selectMeeting(id));

    const { user } = useUser();

    const history = useHistory<{ chimeView?: 'video' | 'chat' | 'guests' }>();
    const chimeView = history.location.state?.chimeView;
    const openChime = () => history.push(history.location.pathname, { ...history.location.state, chimeView: 'video' });
    const setView = React.useCallback(
        (view: 'video' | 'chat' | 'guests') =>
            history.replace(history.location.pathname, { ...history.location.state, chimeView: view }),
        [history]
    );
    const closeChime = React.useCallback(
        () => history.replace(history.location.pathname, { ...history.location.state, chimeView: undefined }),
        [history]
    );

    const start = meeting?.startsAt;
    const end = meeting?.endsAt;
    const timeZone = meeting?.timeZone;

    const untilStart = React.useMemo(
        () =>
            start && timeZone
                ? moment.duration(tzMoment(start, timeZone).diff(tzMoment(undefined, timeZone))).asMilliseconds()
                : undefined,
        [start, timeZone]
    );
    const untilEnd = React.useMemo(
        () =>
            end && timeZone
                ? moment.duration(tzMoment(end, timeZone).diff(tzMoment(undefined, timeZone))).asMilliseconds()
                : undefined,
        [end, timeZone]
    );

    const autoJoin = meeting?.currentUserAttendee?.status === 'attending' && !meeting?.virtualMeeting;

    React.useEffect(() => {
        let handler: number | undefined;
        if (autoJoin && untilStart && untilEnd) {
            if (untilEnd >= 0) {
                const twoMinutes = moment.duration(2, 'minutes').asMilliseconds();

                if (untilStart <= twoMinutes) {
                    setView('video');
                } else {
                    handler = setTimeout(() => setView('video'), untilStart - twoMinutes);
                }
            }
        }

        return () => clearTimeout(handler);
    }, [autoJoin, untilStart, untilEnd, setView]);

    React.useLayoutEffect(() => {
        if (history.location.state?.chimeView) {
            history.replace(history.location.pathname, { ...history.location.state, chimeView: undefined });
        }
    }, [history]);

    const onMeetingEnd = React.useCallback(() => {
        closeChime();
    }, [closeChime]);

    const [denied, setDenied] = React.useState(false);

    const running =
        meeting &&
        meeting.currentUserAttendee?.status === 'attending' &&
        tzMoment(new Date(), meeting?.timeZone).isBetween(
            tzMoment(meeting.startsAt, meeting.timeZone).subtract(2, 'minutes'),
            tzMoment(meeting.endsAt, meeting.timeZone)
        );

    return (
        <SideNav
            fillWidth
            fullHeight
            style={{ paddingRight: 0 }}
            {...(chimeView
                ? {
                      routeChildren: <ChimeNavButtons onBack={closeChime} view={chimeView} setView={setView} />,
                  }
                : user.team?.venuesEnabled
                ? {
                      additionalRoutes: [{ icon: <BuildingIcon />, name: 'Venues', path: `/event/${id}/venue` }],
                  }
                : {})}
        >
            {denied ? (
                <PageNotFound redirect="/events/calendar" />
            ) : !meeting ? (
                <>
                    <LoadMeeting id={id} onDeny={setDenied} />
                    <SpinnerOverlay delay />
                </>
            ) : (
                <>
                    <LoadMeeting id={id} onDeny={setDenied} />
                    <LoadChatMessages meetingId={id} />
                    {running && <LoadChimeVideo id={id} />}

                    {chimeView ? (
                        <>
                            <Chime view={chimeView} onMeetingEnd={onMeetingEnd} />
                        </>
                    ) : (
                        <Meeting onShowChime={openChime} />
                    )}
                </>
            )}
        </SideNav>
    );
};

export default function MeetingsRoute() {
    const match = useRouteMatch();

    return (
        <Route>
            <Switch>
                <Route path={[`${match.path}/unscheduled`, `${match.path}/drafts`]}>
                    <SideNav fillWidth>
                        <Meetings unscheduled />
                    </SideNav>
                </Route>
                <Route path={[`${match.path}/calendar`]}>
                    <SideNav fillWidth>
                        <Meetings calendar />
                    </SideNav>
                </Route>
                <Route path={[`${match.path}/new`, `${match.path}/:id/edit`]}>
                    <SideNav fillWidth fullHeight hideUser routeChildren={<NavButtons />}>
                        <CreateMeeting />
                    </SideNav>
                </Route>

                <Route path={`${match.path}/:id`}>
                    <MeetingDetailsPage />
                </Route>

                <Route>
                    <SideNav fillWidth>
                        <Meetings />
                    </SideNav>
                </Route>
            </Switch>
        </Route>
    );
}
