import React from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { TMSAuth } from './types';

import AzureAuthenticationContext from 'api/msGraphAuth';

import { Row } from 'ui';
import UIButton from 'components/ui/Button';
import { ReactComponent as MSSVG } from 'images/icons/Microsoft_logo.svg';

const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const isIE = msie > 0 || msie11 > 0;

const MSIcon = styled(MSSVG)``;

const StyledButton = styled(UIButton)`
    width: 100%;

    font-size: 15px;
    font-weight: 400;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack)};

    border-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};

    ${MSIcon} {
        width: 21px;
        margin-left: -21px; // SVG takes no width space in the row so button text can take center
    }
`;

type TButtonTypes = {
    onClick: React.MouseEventHandler;
    disabled?: boolean;
    children?: React.ReactNode;
};

const MicrosoftButton = ({ onClick, disabled, children }: TButtonTypes) => (
    <StyledButton onClick={onClick} disabled={disabled} secondary>
        <Row justifyContent="center" alignItems="center" itemSpacing="default">
            <MSIcon />
            {children}
        </Row>
    </StyledButton>
);

type TMicrosoftAuthenticationProps = {
    onSuccess: (resp: TMSAuth) => void;
    buttonText: string;
};

export default function MSAuthenticationButton({ onSuccess, buttonText }: TMicrosoftAuthenticationProps) {
    const { enqueueSnackbar } = useSnackbar();

    const authenticationModule: AzureAuthenticationContext = new AzureAuthenticationContext();

    const logIn = async () => {
        const typeName = 'loginPopup';
        const logInType = isIE ? 'loginRedirect' : typeName;

        try {
            const location = new URL(window.location.href);
            location.pathname = location.pathname + '/msauth';
            location.search = '';
            const resp = await authenticationModule.login(logInType, location.href);
            if (resp?.account?.username)
                onSuccess({
                    client: 'ms',
                    email: resp?.account?.username,
                    idToken: resp?.idToken,
                    accessToken: resp?.accessToken,
                });
        } catch (e) {
            enqueueSnackbar('Something went wrong. Please try again', { variant: 'error' });
        }
    };

    return (
        <MicrosoftButton
            onClick={e => {
                logIn();
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            {buttonText}
        </MicrosoftButton>
    );
}
