import React from 'react';
import styled from 'styled-components';

import { Copy } from 'ui';
import { H2Headline } from 'components/ui/Headline';

const MailSentGif = styled.img`
    max-height: 112px;
    padding-right: 4px; // Adjusts for gif's unusual dimensions

    margin: 24px auto;
`;

const SubCopy = styled(Copy)`
    font-size: 16px;
    line-height: 24px;
`;

const GIF_URL =
    'https://res.cloudinary.com/hdd626jg7/image/upload/h_400,q_auto:best/v1605119849/stencil-event-documents/email-sent_zn8div.gif';

export default function LinkSentView() {
    return (
        <>
            <MailSentGif alt="Mail sent icon" src={GIF_URL} />
            <H2Headline>Magic Link Email Sent</H2Headline>
            <SubCopy>The link expires in 30 minutes and can only be used once to sign in.</SubCopy>
        </>
    );
}
