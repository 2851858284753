import React from 'react';

import { useParams } from 'react-router';

import styled, { css } from 'styled-components/macro';
import he from 'he';

import { Popper, Paper } from '@material-ui/core';

import { format } from 'date-fns';
import { apiDateToDateObj, serializeDate } from 'utils/date_util';

import isEmpty from 'lodash/isEmpty';

import { EventContext } from '../providers/event';
import { EventOptionsContext } from '../providers/event-options';

import { getVenueProposal } from '../api';

import { formatCurrency, formatPercentage } from '../util';

import {
    AlignedRow,
    Column,
    Copy,
    AmenitiesIconHolder,
    IconImg,
    Row,
    SmallerCopy,
    SpacedRow,
    Spacer,
    ExternalLink,
} from '../ui';

import Button from './ui/Button';
import { Headline } from './ui/Headline';
import VenueTile from './VenueTile/VenueTile';
import { EventHeaderIconsFill } from '../components/EventHeader';

import VenueProposalAccepted from './VenueProposalAccepted';
import { MessageCardFlyout } from '../components/MessageCard';

import ProposalAcceptModal from './ProposalAcceptModal';
import ProposalRejectModal from './ProposalRejectModal';

import { ReactComponent as DocumentIcon } from '../images/icons/document.svg';
import { ReactComponent as SpaceMisc } from '../images/icons/space-misc.svg';
import { ReactComponent as MessageBubbleSVG } from '../images/icons/message_bubble.svg';
import { withInteractibleIconStyles } from 'shared';
import colorFns from 'colorFns';
import fontFns from 'fontFns';

const MessageBubble = withInteractibleIconStyles(MessageBubbleSVG);

const SubSectionHeader = styled.h6`
    font-weight: normal;
    font-size: 25px;
    line-height: 1.28;
    letter-spacing: -0.5px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
    margin: 0;
`;

const VenueLabel = styled('div')<{ small?: boolean; href?: string }>`
    font-size: ${props => (props.small ? '15px' : '18px')};
    letter-spacing: -0.1px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formLabel)};
    ${fontFns.formLabel}

    display: flex;
    align-items: center;
`;

const DayCardSection = styled.div`
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
    border-top: none;
`;

const FormHeading = styled.h5`
    font-size: 18px;
    letter-spacing: -0.1px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
`;

const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
    margin: 10px 0;
`;

const NoSpaceImage = styled.div`
    width: 314px;
    height: 217px;
    border-radius: 8px;

    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.placeholderBackground)};

    display: flex;
    align-items: center;
    justify-content: center;
    &::after {
        content: 'No Image Available';
        font-size: 15px;
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};
    }
`;

const StyledPaper = styled(Paper)`
    padding: 20px;
`;

type HoverableAmenitiesIconProps = {
    imgSrc: string;
    alt: string;
    popperContent: React.ReactNode | null;
};

const HoverableAmenitiesIcon = ({ imgSrc, alt, popperContent }: HoverableAmenitiesIconProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    function handleMouseEnter(event: React.MouseEvent<HTMLElement>) {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }

    function handleMouseLeave() {
        setAnchorEl(null);
    }
    return (
        <AmenitiesIconHolder onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {popperContent && (
                <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="top">
                    <StyledPaper>{popperContent}</StyledPaper>
                </Popper>
            )}
            {imgSrc ? (
                <IconImg src={imgSrc} alt={alt} />
            ) : (
                <SpaceMisc
                    css={css`
                        min-width: 32px;
                    `}
                />
            )}
        </AmenitiesIconHolder>
    );
};

const VenueProposal = ({
    viewVenueListing,
    proposalAccepted = false,
    venueBooked,
    setVenueBooked,
    sideMargin,
}: {
    viewVenueListing: (venueId: number) => void;
    proposalAccepted?: boolean;
    venueBooked: boolean;
    setVenueBooked?: (booked: boolean) => void;
    sideMargin: number;
}) => {
    let { proposalId } = useParams();

    const { event } = React.useContext(EventContext);
    const { avOptions, fbOptions } = React.useContext(EventOptionsContext);

    const [venueProposal, setVenueProposal] = React.useState<Bizly.VenueProposal>();

    const [acceptModalOpen, setAcceptModalOpen] = React.useState<Bizly.VenueProposal | undefined>();
    const [rejectModalOpen, setRejectModalOpen] = React.useState<number | undefined>();

    const [venueMessagesAnchor, setVenueMessagesAnchor] = React.useState<SVGSVGElement | null>(null);
    const messageBubbleIconRef = React.useRef<SVGSVGElement>(null);
    const toggleMessagesFlyout = () =>
        setVenueMessagesAnchor(prevAnchor => (prevAnchor ? null : messageBubbleIconRef.current));
    const showMessagesFlyout = () => setVenueMessagesAnchor(messageBubbleIconRef.current);

    React.useEffect(() => {
        if (proposalId) {
            getVenueProposal(proposalId).then(res => setVenueProposal(res.proposal));
        }
    }, [proposalId, venueBooked]);

    const onHoldUntilDate = serializeDate(venueProposal?.onHoldUntil);
    const onHoldUntilDateDisplay = onHoldUntilDate && format(onHoldUntilDate, 'MM/dd/yy');

    return (
        <>
            {proposalId && event.editable && (
                <EventHeaderIconsFill>
                    <MessageBubble ref={messageBubbleIconRef} onClick={toggleMessagesFlyout} />
                    {venueMessagesAnchor && (
                        <MessageCardFlyout
                            anchor={venueMessagesAnchor}
                            onClose={() => setVenueMessagesAnchor(null)}
                            proposalId={proposalId}
                        />
                    )}
                </EventHeaderIconsFill>
            )}
            {venueProposal?.accepted && (
                <VenueProposalAccepted onSendMessage={showMessagesFlyout} sideMargin={sideMargin} />
            )}
            <Spacer largest />
            <main
                css={css`
                    width: 100%;
                `}
            >
                {venueProposal && !venueProposal.accepted && (
                    <SpacedRow>
                        <Headline large>Proposal</Headline>
                        <SpacedRow
                            css={css`
                                width: auto;

                                & > * {
                                    margin-left: 20px;
                                }
                            `}
                        >
                            {event.editable && (
                                <Button
                                    css={css`
                                        width: auto;
                                        padding: 0 16px;
                                    `}
                                    secondary
                                    onClick={showMessagesFlyout}
                                >
                                    Message Venue
                                </Button>
                            )}
                            {venueProposal && event.editable && !venueProposal.rejected && !venueProposal.cancelled && (
                                <>
                                    <Button onClick={() => setRejectModalOpen(venueProposal && venueProposal.id)}>
                                        Reject
                                    </Button>
                                    <Button onClick={() => setAcceptModalOpen(venueProposal)}>Accept</Button>
                                </>
                            )}
                        </SpacedRow>
                    </SpacedRow>
                )}
                {proposalAccepted && (
                    <>
                        <Spacer />
                        <Line />
                        <Spacer />
                    </>
                )}
                <Row
                    css={css`
                        justify-content: space-between;
                    `}
                >
                    <Column
                        css={css`
                            flex: 40% 0 0;
                        `}
                    >
                        {venueProposal && venueProposal.onHoldUntil && !proposalAccepted && (
                            <>
                                <SubSectionHeader
                                    css={css`
                                        color: ${colorFns.highlightedText};
                                    `}
                                >
                                    Response required by:
                                </SubSectionHeader>
                                <SubSectionHeader
                                    css={css`
                                        color: ${colorFns.highlightedText};
                                    `}
                                >
                                    {onHoldUntilDateDisplay}
                                </SubSectionHeader>
                                <Spacer />
                            </>
                        )}
                        <SpacedRow
                            css={css`
                                width: 75%;

                                & > * {
                                    flex: 1;
                                }
                            `}
                        >
                            <VenueLabel small>Meeting Name</VenueLabel>
                            <div>{event.name}</div>
                        </SpacedRow>
                        <Spacer small />
                        <SpacedRow
                            css={css`
                                width: 75%;

                                & > * {
                                    flex: 1;
                                }
                            `}
                        >
                            <VenueLabel small>Meeting Type</VenueLabel>
                            <div>{event.type}</div>
                        </SpacedRow>
                        <Spacer small />
                        <Column itemSpacing="largest">
                            <SpacedRow
                                css={css`
                                    width: 75%;

                                    & > * {
                                        flex: 1;
                                    }
                                `}
                            >
                                <VenueLabel small>Internal Reference</VenueLabel>
                                <div>{event.cventId}</div>
                            </SpacedRow>
                            {venueProposal &&
                            (!isEmpty(venueProposal.notes) || !isEmpty(venueProposal.datesFlexibleNotes)) ? (
                                <Row>
                                    <Column itemSpacing="medium">
                                        {venueProposal && !isEmpty(venueProposal.notes) && (
                                            <div>
                                                <VenueLabel>Notes</VenueLabel>
                                                <Spacer small />
                                                <div>{he.decode(venueProposal.notes.replace(/(<([^>]+)>)/gi, ''))}</div>
                                            </div>
                                        )}
                                        {venueProposal && !isEmpty(venueProposal.datesFlexibleNotes) && (
                                            <div>
                                                <VenueLabel>Flexible Dates</VenueLabel>
                                                <Spacer small />
                                                <div>
                                                    {he.decode(
                                                        venueProposal.datesFlexibleNotes.replace(/(<([^>]+)>)/gi, '')
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </Column>
                                </Row>
                            ) : null}
                            {venueProposal && !isEmpty(venueProposal.attachments) && (
                                <div>
                                    <VenueLabel>Attachments</VenueLabel>
                                    <Spacer />
                                    {venueProposal.attachments.map(attachment => (
                                        <VenueLabel>
                                            <DocumentIcon />
                                            <ExternalLink
                                                href={attachment.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {attachment.name}
                                            </ExternalLink>
                                        </VenueLabel>
                                    ))}
                                </div>
                            )}
                        </Column>
                    </Column>
                    <Spacer />
                    <Column
                        css={css`
                            flex: 60% 0 0;
                        `}
                    >
                        {venueProposal && venueProposal.venue && (
                            <VenueTile
                                venue={venueProposal.venue}
                                format="proposal"
                                width="100%"
                                fixedRatio="calc(307 / 672 * 100%)"
                                pillType="preferenceCategory"
                                onClick={viewVenueListing}
                            />
                        )}
                    </Column>
                </Row>
                <Spacer />
                <Spacer />
                <Spacer />
                <VenueLabel>Details</VenueLabel>
                <Spacer />
                {venueProposal &&
                    venueProposal.byDay.map((day, i) => (
                        <div
                            css={css`
                                & > * {
                                    padding: 24px;
                                }
                                & > div:last-child {
                                    border-bottom-left-radius: 8px;
                                    border-bottom-right-radius: 8px;
                                    margin-bottom: 24px;
                                }
                            `}
                        >
                            <div
                                css={css`
                                    width: 100%;
                                    border-top-left-radius: 8px;
                                    border-top-right-radius: 8px;
                                    background-color: ${({ theme: { getColor, EColors } }) =>
                                        getColor(EColors.agendaDayPane)};
                                    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.agendaDayPaneText)};

                                    display: flex;

                                    box-sizing: border-box;

                                    & > * {
                                        margin-right: 40px;
                                    }
                                `}
                            >
                                <div>Day {i + 1}</div>
                                <div>{day.day && format(apiDateToDateObj(day.day), 'iii, LLL dd, yyyy')}</div>
                            </div>
                            {day.guestRooms[0] && (
                                <DayCardSection>
                                    <Column>
                                        <Spacer />
                                        <AlignedRow
                                            css={css`
                                                & > * {
                                                    margin-right: 12px;
                                                }
                                            `}
                                        >
                                            <img
                                                src="https://res.cloudinary.com/hdd626jg7/image/upload/v1562953405/stencil/icons/guest-room.svg"
                                                alt="meetingspace-icon"
                                            />
                                            <Copy>{day.guestRooms[0].quantity} guest rooms</Copy>
                                        </AlignedRow>
                                        <Spacer />
                                        <Row
                                            css={css`
                                                width: inherit;

                                                justify-content: space-between;

                                                margin: 0 44px;

                                                & > * {
                                                    flex: 1;
                                                    margin-right: 24px;

                                                    &:last-child {
                                                        margin-right: 0;
                                                    }
                                                }
                                            `}
                                        >
                                            <div>
                                                {day.guestRooms[0].imageUrl ? (
                                                    <img
                                                        css={css`
                                                            width: 314px;
                                                            height: 217px;
                                                            border-radius: 8px;
                                                        `}
                                                        src={day.guestRooms[0].imageUrl}
                                                        alt="venue-space"
                                                    />
                                                ) : (
                                                    <NoSpaceImage />
                                                )}
                                            </div>
                                            <Column>
                                                <FormHeading as="label">Guest Room Rental</FormHeading>
                                                <div
                                                    css={css`
                                                        font-size: 40px;
                                                        font-weight: 300;
                                                        line-height: 0.95;
                                                        letter-spacing: -1.22px;
                                                        color: ${({ theme: { getColor, EColors } }) =>
                                                            getColor(EColors.displayPricing)};
                                                    `}
                                                >
                                                    {formatCurrency(day.guestRooms[0].subTotal)}
                                                </div>
                                                <Spacer />
                                                {venueProposal.commissionable && (
                                                    <SmallerCopy>Guestrooms are commissionable.</SmallerCopy>
                                                )}
                                                <SmallerCopy>Amount exclude taxes and fees.</SmallerCopy>
                                                <Spacer />
                                                <Spacer />
                                                <SpacedRow>
                                                    <VenueLabel small>Room Rate</VenueLabel>
                                                    <div>{formatCurrency(day.guestRooms[0].rate)}</div>
                                                </SpacedRow>
                                                <Line />
                                                <SpacedRow>
                                                    <VenueLabel small>Occupancy Tax</VenueLabel>
                                                    <div>{Number(venueProposal.occupancyTax)}%</div>
                                                </SpacedRow>
                                                <Line />
                                                <SpacedRow>
                                                    <VenueLabel small>Resort Fee</VenueLabel>
                                                    <div>{formatCurrency(venueProposal.resortFee)}</div>
                                                </SpacedRow>
                                            </Column>
                                            <Column>
                                                {day.guestRooms[0].name && (
                                                    <SpacedRow>
                                                        <VenueLabel small>Room Name</VenueLabel>
                                                        <div>{day.guestRooms[0].name}</div>
                                                    </SpacedRow>
                                                )}
                                            </Column>
                                        </Row>
                                    </Column>
                                </DayCardSection>
                            )}
                            {day.eventSpaces.map(
                                ({
                                    id,
                                    fbMinimum,
                                    imageUrl: eventSpaceImageUrl,
                                    rate,
                                    requestedSpaceName,
                                    venueSpace,
                                    setup,
                                    startsAt,
                                    endsAt,
                                    fbItems,
                                    avItems,
                                }) => {
                                    const startTime = serializeDate(startsAt);
                                    const startTimeDisplay = startTime ? format(startTime, 'h:mm aaaa') : undefined;
                                    const endTime = serializeDate(endsAt);
                                    const endTimeDisplay = endTime ? format(endTime, 'h:mm aaaa') : undefined;
                                    const timesDisplay = [startTimeDisplay, endTimeDisplay].join(' - ');

                                    return (
                                        <DayCardSection key={id}>
                                            <Column>
                                                <Spacer />
                                                <AlignedRow
                                                    css={css`
                                                        & > * {
                                                            margin-right: 12px;
                                                        }
                                                    `}
                                                >
                                                    <img src={setup.iconUrl} alt="meetingspace-icon" />
                                                    <Copy>{requestedSpaceName || setup.name}</Copy>
                                                    <Spacer col xsmall />
                                                    <Copy>{timesDisplay}</Copy>
                                                </AlignedRow>
                                                <Spacer />
                                                <Row
                                                    css={css`
                                                        width: inherit;

                                                        justify-content: space-between;

                                                        margin: 0 44px;

                                                        & > * {
                                                            flex: 1;
                                                            margin-right: 24px;

                                                            &:last-child {
                                                                margin-right: 0;
                                                            }
                                                        }
                                                    `}
                                                >
                                                    <div>
                                                        {eventSpaceImageUrl ? (
                                                            <img
                                                                css={css`
                                                                    width: 314px;
                                                                    height: 217px;
                                                                    border-radius: 8px;
                                                                `}
                                                                src={eventSpaceImageUrl}
                                                                alt="venue-space"
                                                            />
                                                        ) : (
                                                            <NoSpaceImage />
                                                        )}
                                                    </div>
                                                    <Column>
                                                        <Row
                                                            css={css`
                                                                & > * {
                                                                    margin-right: 62px;

                                                                    &:last-child {
                                                                        margin-right: 0;
                                                                    }
                                                                }
                                                            `}
                                                        >
                                                            <Column>
                                                                <FormHeading as="label">F&amp;B Min.</FormHeading>
                                                                <div
                                                                    css={css`
                                                                        font-size: 40px;
                                                                        font-weight: 300;
                                                                        line-height: 0.95;
                                                                        letter-spacing: -1.22px;
                                                                        color: ${({ theme: { getColor, EColors } }) =>
                                                                            getColor(EColors.displayPricing)};
                                                                    `}
                                                                >
                                                                    {formatCurrency(fbMinimum)}
                                                                </div>
                                                            </Column>
                                                            <Column>
                                                                <FormHeading as="label">Room Rental</FormHeading>
                                                                <div
                                                                    css={css`
                                                                        font-size: 40px;
                                                                        font-weight: 300;
                                                                        line-height: 0.95;
                                                                        letter-spacing: -1.22px;
                                                                        color: ${({ theme: { getColor, EColors } }) =>
                                                                            getColor(EColors.displayPricing)};
                                                                    `}
                                                                >
                                                                    {formatCurrency(rate)}
                                                                </div>
                                                            </Column>
                                                        </Row>
                                                        <Spacer />
                                                        <SmallerCopy>Amount exclude taxes and fees.</SmallerCopy>
                                                        <Spacer />
                                                        <Spacer />
                                                        <SpacedRow>
                                                            <VenueLabel small>Service Charge</VenueLabel>
                                                            <div>{formatPercentage(venueProposal.serviceCharge)}</div>
                                                        </SpacedRow>
                                                        <Line />
                                                        <SpacedRow>
                                                            <VenueLabel small>Sales Tax</VenueLabel>
                                                            <div>{formatPercentage(venueProposal.salesTax)}</div>
                                                        </SpacedRow>
                                                        <Line />
                                                        <SpacedRow>
                                                            <VenueLabel small>Gratuity</VenueLabel>
                                                            <div>{formatPercentage(venueProposal.gratuity)}</div>
                                                        </SpacedRow>
                                                    </Column>
                                                    <Column>
                                                        <SpacedRow>
                                                            <VenueLabel small>Room Name</VenueLabel>
                                                            <div>{venueSpace.name}</div>
                                                        </SpacedRow>
                                                        <Spacer />
                                                        <SpacedRow>
                                                            <VenueLabel small>Room Size (sq ft)</VenueLabel>
                                                            <div>{Number(venueSpace.size).toLocaleString()}</div>
                                                        </SpacedRow>
                                                        <Spacer />
                                                        <SpacedRow>
                                                            <VenueLabel small>Room Max Capacity</VenueLabel>
                                                            <div>{venueSpace.maxCapacity?.toLocaleString()}</div>
                                                        </SpacedRow>
                                                        <Spacer />
                                                        <VenueLabel small>Essentials</VenueLabel>
                                                        <Spacer />
                                                        <Row
                                                            css={css`
                                                                width: unset;
                                                                flex-wrap: wrap;

                                                                & > * {
                                                                    margin: 0 16px;
                                                                }
                                                            `}
                                                        >
                                                            <HoverableAmenitiesIcon
                                                                css={css`
                                                                    max-height: 24px;
                                                                    max-width: 24px;
                                                                `}
                                                                imgSrc={setup ? setup.iconUrl : ''}
                                                                alt="setup-icon"
                                                                key={setup.name}
                                                                popperContent={setup.name}
                                                            />
                                                            {avItems.map(avItem => {
                                                                const avOption = avOptions.find(
                                                                    avOption => avOption.name === avItem.name
                                                                );

                                                                return (
                                                                    <HoverableAmenitiesIcon
                                                                        imgSrc={avOption ? avOption.iconUrl : ''}
                                                                        alt="meetingspace-icon"
                                                                        key={avItem.name}
                                                                        popperContent={avItem.name}
                                                                    />
                                                                );
                                                            })}
                                                            {fbItems.map(fbItem => {
                                                                const fbOption = fbOptions.find(
                                                                    fbOption => fbOption.name === fbItem.name
                                                                );

                                                                return (
                                                                    <HoverableAmenitiesIcon
                                                                        imgSrc={fbOption ? fbOption.iconUrl : ''}
                                                                        alt="meetingspace-icon"
                                                                        key={fbItem.name}
                                                                        popperContent={
                                                                            <Column itemSpacing="xsmall">
                                                                                <div>{fbItem.name}</div>
                                                                                {fbItem.diningStyle && (
                                                                                    <div>{fbItem.diningStyle.name}</div>
                                                                                )}
                                                                            </Column>
                                                                        }
                                                                    />
                                                                );
                                                            })}
                                                        </Row>
                                                    </Column>
                                                </Row>
                                            </Column>
                                        </DayCardSection>
                                    );
                                }
                            )}
                        </div>
                    ))}
            </main>
            {setVenueBooked && (
                <>
                    <ProposalAcceptModal
                        open={!!acceptModalOpen}
                        closeModal={() => setAcceptModalOpen(undefined)}
                        proposal={acceptModalOpen}
                        eventId={event.id}
                        setVenueBooked={setVenueBooked}
                    />
                    <ProposalRejectModal
                        open={!!rejectModalOpen}
                        closeModal={() => setRejectModalOpen(undefined)}
                        proposalId={rejectModalOpen}
                        eventId={event.id}
                    />
                </>
            )}
        </>
    );
};

export default VenueProposal;
