import React from 'react';
import styled from 'styled-components';

import usePersistentSnackbar from 'hooks/usePersistentSnackbar';

import { createPlaybookActions, useCreatePlaybook } from './store';

import { Column, Copy, Row, Spacer } from 'ui';
import ManageAgenda from 'components/Schedule/ManageAgenda';
import { LargeHeadline } from 'components/ui/Headline';
import Errors from './components/Errors';
import colorFns from 'colorFns';
import AgendaTimeTable from 'components/ui/AgendaTimeTable';

const FormCol = styled(Column)`
    width: 706px; // TODO: Maybe more dynamic?
`;

const PreviewCol = styled(Column)`
    position: sticky;
    top: 100px;
    width: 455px;
    max-width: 455px;
`;

const FormPage = styled(Row)`
    align-items: normal;

    ${FormCol} {
        border-right: 1px solid ${colorFns.softBorder};
    }
`;

export default function AgendaForm() {
    const { agenda, agendaErrors, basicInfo, stepIdx } = useCreatePlaybook();

    const enqueueSnackbar = usePersistentSnackbar([agendaErrors, stepIdx]);
    React.useEffect(() => {
        const hasErrors = Object.values(agendaErrors).some(v => v);
        if (hasErrors) {
            enqueueSnackbar(<Errors errors={agendaErrors} />, { variant: 'error' });
        }
    }, [agendaErrors, enqueueSnackbar]);

    return (
        <FormPage itemSpacing="largest" paddingSpacing>
            <FormCol>
                <LargeHeadline>Agenda</LargeHeadline>
                <ManageAgenda
                    agendaEntries={agenda || []}
                    addAgendaEntry={createPlaybookActions.addAgendaItem}
                    updateAgendaEntry={createPlaybookActions.updateAgendaItem}
                    arrangeAgendaEntry={createPlaybookActions.arrangeAgendaItem}
                    removeAgendaEntry={createPlaybookActions.removeAgendaItem}
                    noContainer
                    condensed
                />
                <Spacer largest />
                <Spacer largest />
                <Spacer largest />
            </FormCol>

            <PreviewCol>
                {basicInfo.name && (
                    <Copy>
                        <b>{basicInfo.name}</b>
                    </Copy>
                )}
                <Copy>
                    <b>Agenda</b>
                </Copy>
                <Spacer />
                <AgendaTimeTable agenda={agenda} />
            </PreviewCol>
        </FormPage>
    );
}
