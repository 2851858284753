import React from 'react';
import uniqWith from 'lodash/uniqWith';

import { getCookie } from '../utils/tokens';
import { getLoggedInUser, updateUser as updateUserAPI } from '../api';
import { nudgesActions } from 'stores/nudges';

import connectFullstory from '../utils/connectFullstory';

import mixpanel from 'utils/mixpanel';
import { ETeams } from 'theme';
import { useHistory } from 'react-router';

// const CORE_SUB_DOMAIN = process.env.REACT_APP_CORE_SUB_DOMAIN; TODO: Enable this once we swap Stecil's sub-domain to app.bizly.com

const TEAM_ADMIN = 'team-admin';

const initialState: Bizly.User = {
    firstName: '',
    lastName: '',
    phone: '',
    role: '',
    email: '',
    imageUrl: '',
    loaded: false,
    token: '',
    intercomIdentityVerificationHash: '',
};

const UNUSED_DUMMY_ID = -1;

const UserContext = React.createContext<Bizly.UserContext | undefined>(undefined);

type TUserProvider = {
    setTheme?: (team: ETeams) => void;
    children?: React.ReactNode;
};

const isUser = (user: any): user is Bizly.User => {
    return !!(user as Bizly.User).team;
};

export const UserProvider = ({ setTheme, children }: TUserProvider) => {
    const history = useHistory();
    const [userState, setUserState] = React.useState<Bizly.User>(initialState);

    const setUserTheme = React.useCallback(
        (user: Partial<Bizly.User>) => {
            if (!user?.id) return;

            const theme = user?.team?.theme?.toLowerCase() as ETeams;
            setTheme?.(ETeams[theme] || ETeams.bizly);
        },
        [setTheme]
    );

    const refreshUser = React.useCallback(
        async function refreshUser() {
            let loggedInUser;
            const token = getCookie();
            if (!token)
                return {
                    email: null,
                    loaded: true,
                    token: null,
                }; // getLoggedInUser will unmount the entire app if we don't exit early in this case

            try {
                loggedInUser = await getLoggedInUser();

                /*
            temp disable redirect to core

            if (token && CORE_SUB_DOMAIN && !loggedInUser?.team?.migratedAt) {
                window.location.replace(`https://${CORE_SUB_DOMAIN}.bizly.com`);
            }
            */
            } catch (e) {
                return {
                    email: null,
                    loaded: true,
                    token: null,
                };
            }

            const userNeedsToFillProfile =
                token && (!loggedInUser.firstName || !loggedInUser.lastName || !loggedInUser.role);
            if (userNeedsToFillProfile && history.location.pathname !== '/profile') {
                history.push('/profile', { redirectTo: history.location.pathname });
            }

            return {
                ...loggedInUser,
                loaded: true,
                token,
            };
        },
        [history]
    );

    const fetchMe = React.useCallback(() => {
        refreshUser().then(refreshedUser => {
            // @ts-ignore
            // no typings for custom window variables
            // TODO: use FS as npm package
            const FS = window.FS || {};
            if (FS.identify) {
                connectFullstory({ identify: FS.identify, ...refreshedUser });
            }

            setUserState(refreshedUser);
            setUserTheme(refreshedUser);

            if (isUser(refreshedUser)) {
                nudgesActions.mergeNudges({
                    showTeamSettings: !refreshedUser.team?.name,
                });
            }
        });
    }, [refreshUser, setUserState, setUserTheme]);

    React.useEffect(() => {
        if (userState.connectors) {
            nudgesActions.loadIntegrationsPrompts(
                userState.shownIntegrationsModal || 0,
                (userState.connectors ?? []).length > 0
            );
        }
    }, [userState.connectors, userState.shownIntegrationsModal]);

    React.useEffect(() => {
        fetchMe();
    }, [fetchMe]);

    function clearUser() {
        setUserState({ ...initialState, loaded: true });
        setTheme?.(ETeams.bizly);
    }

    async function updateUser(user: Partial<Bizly.User>) {
        const newUser = await updateUserAPI(user);
        setUserState(currentUser => ({ ...currentUser, ...newUser }));
        setUserTheme(user);
    }

    function setTeam(team: Bizly.Team) {
        setUserState(state => ({ ...state, team }));
    }

    const toggles = React.useMemo(() => {
        const { team, featureFlags: gate = {} } = userState;
        return {
            gate,
            compliance: {
                ...(team?.compliance || {}),
            },
        };
    }, [userState]);

    const isInDemoMode = React.useMemo(() => {
        const { team } = userState;
        return team?.demoMode || false;
    }, [userState]);

    const isTeamAdmin = userState?.team?.role === TEAM_ADMIN;

    return (
        <UserContext.Provider
            value={{
                user: userState,
                toggles,
                isTeamAdmin,
                isInDemoMode,
                clearUser,
                refreshUser,
                updateUser,
                setUserState: user => {
                    setUserState(user);
                    setUserTheme(user);

                    mixpanel.identify(user.id);
                    mixpanel.people.set({
                        $email: user.email,
                        $first_name: user.firstName,
                        $last_name: user.lastName,
                        team_id: user.team?.id,
                        team_name: user.team?.name,
                    });
                },
                setTeam,
                fetchMe,
                // TODO: refactor the entire user state
                // this is necessary to properly handle async updates to the user state
                addConnector: (connector: BizlyAPI.Connector) => {
                    setUserState(curUser => ({
                        ...curUser,
                        ...(curUser.loaded && curUser.token
                            ? {
                                  connectors: uniqWith(
                                      [...(curUser.connectors ?? []), { id: UNUSED_DUMMY_ID, type: connector }],
                                      (a, b) => a.type === b.type
                                  ),
                              }
                            : {}),
                    }));
                    nudgesActions.updateIntegrationsPrompts('showVMIntegrations', false);
                    nudgesActions.updateIntegrationsPrompts('showPublishIntegrations', false);
                },
                delConnector: (connector: BizlyAPI.Connector) => {
                    setUserState(curUser => ({
                        ...curUser,
                        ...(curUser.loaded && curUser.token
                            ? {
                                  connectors: curUser.connectors?.filter(
                                      curConnector => curConnector.type !== connector
                                  ),
                              }
                            : {}),
                    }));
                },
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export function useUser() {
    const context = React.useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
}

export function withUserContext<T>(Component: React.ComponentType<T>) {
    return (props: T) => (
        <UserContext.Consumer>{context => <Component userContext={context} {...props} />}</UserContext.Consumer>
    );
}
