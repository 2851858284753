import createStore from 'zustand';

import { getMeetings } from 'api/calendar';

import moment from 'moment';
import keyBy from 'lodash/keyBy';

export type ItemWithUId = BizlyAPI.CalendarItem & { uId: string };

type State = {
    loadingByWeek: {
        [startOfWeek: string]: boolean;
    };
    idsByWeek: Partial<{
        [startOfWeek: string]: string[];
    }>;
    usersByWeek: Partial<{
        [startOfWeek: string]: string[];
    }>;
    metaByWeek: Partial<{
        [startOfWeek: string]: {
            itemCount: number;
            totalPeopleHours: number;
        };
    }>;

    meetings: { [id: string]: ItemWithUId };
    users: { [id: string]: BizlyAPI.AttendingUser; [id: number]: BizlyAPI.AttendingUser };
};
type Store = State;

const initialState: State = {
    loadingByWeek: {},
    idsByWeek: {},
    usersByWeek: {},
    metaByWeek: {},

    meetings: {},
    users: {},
};
export const [useCalendar, calendarStoreApi] = createStore<Store>(() => initialState);

const { setState, getState } = calendarStoreApi;

const strDate = (date?: Date) => (date ? moment(date).format('YYYY-MM-DD') : '');

const mergeByDate = <CurVal, NewVal extends CurVal, CurStateKey extends string>(
    current: { [key in CurStateKey]: CurVal },
    input: NewVal,
    date?: Date
) => ({
    ...current,
    [strDate(date)]: input,
});

export const isBizly = (event: { origin?: string | null }) => event.origin === 'bizly' || event.origin === null;

const generateId = (item: BizlyAPI.CalendarItem) =>
    isBizly(item) ? `${item.id}` : [item.origin, item.immutableId].join();

export const calendarActions = {
    load: async (startOfWeek: Date, limit?: number) => {
        setState({ loadingByWeek: mergeByDate(getState().loadingByWeek, true, startOfWeek) });

        try {
            const { attendingUsers, calendarItems, meta } = await getMeetings(startOfWeek, limit);
            const items = calendarItems.map(item => ({ ...item, uId: generateId(item) }));
            const meetingsDict = keyBy(items, m => m.uId) as Record<string, ItemWithUId>;

            setState({
                loadingByWeek: mergeByDate(getState().loadingByWeek, false, startOfWeek),
                idsByWeek: mergeByDate(
                    getState().idsByWeek,
                    items.map(m => m.uId),
                    startOfWeek
                ),
                usersByWeek: mergeByDate(
                    getState().usersByWeek,
                    attendingUsers.map(m => m.email),
                    startOfWeek
                ),
                metaByWeek: mergeByDate(getState().metaByWeek, meta, startOfWeek),
                meetings: { ...getState().meetings, ...meetingsDict },
                users: { ...getState().users, ...keyBy(attendingUsers, u => u.id) },
            });

            return items;
        } catch (e) {
            setState({
                loadingByWeek: mergeByDate(getState().loadingByWeek, false, startOfWeek),
            });
            throw e;
        }
    },
    remove: (uId: string | number, startOfWeek: Date) => {
        const weekIds = getState().idsByWeek[strDate(startOfWeek)];
        const { [uId]: remove, ...restItems } = getState().meetings;

        setState({
            ...(weekIds
                ? {
                      idsByWeek: mergeByDate(
                          getState().idsByWeek,
                          weekIds.filter(weekId => weekId !== uId),
                          startOfWeek
                      ),
                  }
                : {}),
            meetings: restItems,
        });
    },
};

export const selectBlocks = (startOfWeek?: Date) => (state: State) =>
    state.idsByWeek[strDate(startOfWeek)]?.map(id => state.meetings[id]) ?? [];

export const selectUsers = (startOfWeek?: Date) => (state: State) => state.usersByWeek[strDate(startOfWeek)] ?? [];

export const selectMeta = (startOfWeek?: Date) => (state: State) => state.metaByWeek[strDate(startOfWeek)];

export function isLoading(startOfWeek?: Date) {
    return (state: State) => state.loadingByWeek[strDate(startOfWeek)] ?? true;
}
