import React from 'react';
import styled from 'styled-components';
import keyBy from 'lodash/keyBy';

import useThemedColor from 'hooks/useThemedColor';

import { useEventTemplateTags } from 'stores/event-template-tags';

import { Link } from 'react-router-dom';
import { Column, Copy, InlineRow } from 'ui';
import colorFns from 'colorFns';

const Container = styled(Column)`
    position: relative;
    width: auto;

    box-sizing: border-box;
`;

type TPagedPlaybooksProps = {
    playbooks: BizlyAPI.Complete.Playbook[];
};

const PlaybookEntry = styled(InlineRow)`
    min-width: 240px;
    cursor: pointer;
    border-radius: 8px;
    color: ${colorFns.pureWhite};
    align-items: stretch;
`;

const Title = styled(Copy)`
    padding: 16px;
`;

const Number = styled(Copy)`
    text-align: center;
    min-width: 64px;
    background: ${colorFns.pureBlack.alpha(0.1)};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default function PlaybooksList({ playbooks }: TPagedPlaybooksProps) {
    const { primaryAction: fallbackColor } = useThemedColor();

    const { tags } = useEventTemplateTags();
    const idToTag = keyBy(tags || [], 'id');

    return (
        <Container itemSpacing="smallish" alignItems="flex-start">
            {playbooks.map((playbook, idx) => {
                const firstTagId = playbook.tags?.[0]?.id;

                const color = (firstTagId && idToTag[firstTagId]?.color) || fallbackColor;
                return (
                    <Link to={`/playbooks/${playbook.id}`}>
                        <PlaybookEntry
                            key={playbook.id}
                            alignItems="center"
                            justifyContent="flex-start"
                            itemSpacing="small"
                            paddingSpacing
                            style={{ background: color }}
                        >
                            <Number large>
                                <b>#{idx + 1}</b>
                            </Number>
                            <Title large>{playbook.name}</Title>
                        </PlaybookEntry>
                    </Link>
                );
            })}
        </Container>
    );
}
