import React from 'react';
import styled from 'styled-components/macro';

import StepsColumn from './StepsColumn';
import { Copy as UICopy } from '../../ui';

const Copy = styled(UICopy)<any>`
    font-size: 15px;
    line-height: 1.47;

    ul {
        padding-inline-start: 1.2em;
    }
`;

type FinalizeDetailsProps = {
    number: number;
    copy: string;
};

const defaultCopy =
    'The venue contact is your go-to for all other meeting details, like catering selections, room setup requirements and any questions about the venue.';

const FinalizeDetails = ({ number, copy = defaultCopy }: FinalizeDetailsProps) => {
    return (
        <StepsColumn number={number} headline={'Finalize your meeting details'}>
            <Copy dangerouslySetInnerHTML={{ __html: copy }} />
        </StepsColumn>
    );
};

export default FinalizeDetails;
