import React from 'react';

import styled from 'styled-components';

import { Row, Column, Copy, TSpacerNames } from 'ui';
import { RichTextDisplay } from 'components/Form/fields';

const AgendaBold = styled(Copy)`
    font-weight: 700;
    font-size: 1.5rem;
    padding-bottom: 15px;
`;

const AgendaDuration = styled.span`
    min-width: 45px;
    border-radius: 8px;
    padding: 2px 10px;
    color: rgba(255, 255, 255, 0.74);
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.1)};
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.74)};
`;

const AgendaTitle = AgendaBold;

export default function AgendaTimeTable({
    agenda,
    withBorderPadding,
}: {
    agenda?: BizlyAPI.ScheduleAgendaEntry[];
    withBorderPadding?: TSpacerNames;
}) {
    return agenda ? (
        <Column itemSpacing="medium" withBorderPadding={withBorderPadding}>
            {agenda?.map(({ title, duration, description }, idx) => (
                <Row key={`${title}-${idx}`} itemSpacing="default">
                    <AgendaDuration>{duration ? `${duration} min${duration !== 1 ? 's' : ''}` : '-'}</AgendaDuration>
                    <Column>
                        <AgendaTitle>{title}</AgendaTitle>
                        {description && <RichTextDisplay value={description} />}
                    </Column>
                </Row>
            ))}
        </Column>
    ) : null;
}
