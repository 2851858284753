// TODO: Replace this file with our own date picker that matches the design specs.
// This is a placeholder here designed only to override the MaterialUI datepicker
import { DatePicker as MUIDatePicker } from '@material-ui/pickers';
import styled from 'styled-components';
import fontFns from 'fontFns';

type DatePickerTypes = {
    id: string;
    label: string;
    margin: string;
    onChange: () => void;
    minDate: Date;
    maxDate: Date;
    value?: string | null;
    placeholder?: string;
};

const DatePicker = styled(MUIDatePicker)<DatePickerTypes>`
    display: flex;
    flex-direction: column;
    ${({ margin }) => (margin === 'dense' ? '' : 'margin: 0;')}

    label {
        position: relative;
        margin: 10px 0 15px;
        font-size: 15px;
        line-height: 1;
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formLabel)} !important;
        ${fontFns.formLabel}
        transform: none;
    }

    div {
        ${({ margin }) => (margin === 'dense' ? '' : 'margin: 0;')}
        border-bottom: none;

        ::before {
            border-bottom: none !important;
            content: "";
            color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};
            font-size: 15px;
            margin-bottom: 10px;
            margin-left: 16px;
        }

        ::after {
            display: none;
        }
    }

    input {
        ${props => props.disabled && 'pointer-events: none;'}
        background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
        border: solid 1px ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
        border-radius: 8px;
        height: 36px;
        min-width: 103px;
        width: auto;
        outline: none;
        font-size: 15px;
        padding: 0 16px;
        cursor: pointer;
    }
`;

export default DatePicker;
