import createStore from '../';
import {
    getCurrentInquiry,
    createInquiryDraft,
    updateBookingInquiry,
    removeVenueFromInquiry,
    submitInquiry,
    cancelAndCopyInquiry,
} from 'api/inquiry';
import { getVenueInquiries } from 'api';

type State = {
    loading: boolean;
} & (
    | {
          loaded: false;
          eventId?: number | string;
          inquiry: null;
          venues: null;
      }
    | {
          loaded: true;
          eventId: number | string;
          inquiry: null | BizlyAPI.Inquiry;
          venues: Bizly.Venue[];
      }
);
type Store = State;

const initialState: State = {
    inquiry: null,
    venues: null,
    loaded: false,
    loading: false,
};

export const [useCurrentInquiry, currentInquiryStoreApi] = createStore<Store>(() => initialState);

const { setState, getState } = currentInquiryStoreApi;

export const hasBookedVenue = (venuesInqs: Bizly.Venue[]) =>
    venuesInqs.filter(venue => venue.status === 'Booked' || venue.status === 'Contract Pending').length > 0;

export const currentInquiryActions = {
    load: async (eventId: number | string) => {
        let { eventId: curEventId } = getState();
        setState({
            loading: true,
            ...(eventId !== curEventId
                ? {
                      inquiry: null,
                      venues: null,
                      eventId,
                      loaded: false,
                  }
                : {}),
        });

        try {
            const inquiry = await getCurrentInquiry(eventId);
            const eventInquiryVenues = await getVenueInquiries(eventId);
            const venues = inquiry ? eventInquiryVenues.filter(venue => venue.inquiryId === inquiry.id) : [];

            ({ eventId: curEventId } = getState());
            if (curEventId === eventId)
                setState({
                    inquiry,
                    venues,
                    eventId,
                    loaded: true,
                    loading: false,
                });

            return inquiry;
        } catch (e) {
            setState({
                loading: false,
            });
            throw e;
        }
    },

    createDraft: async (eventId: number | string) => {
        let currentState = getState();
        if (eventId !== currentState.eventId) return;
        if (currentState.inquiry) throw new Error('Meeting already has an inquiry.');

        setState({ loading: true });

        try {
            const { inquiry } = await createInquiryDraft(eventId);
            currentState = getState();
            if (eventId !== currentState.eventId) return;

            setState({
                inquiry,
                venues: [],
                eventId,
                loaded: true,
                loading: false,
            });

            return inquiry;
        } catch (e) {
            currentState = getState();
            if (eventId !== currentState.eventId) return;

            setState({ loading: false });
            throw e;
        }
    },

    updateDraft: async (inquiryId: number, data: Partial<BizlyAPI.Inquiry>) => {
        let currentState = getState();
        if (!currentState.loaded || !currentState.inquiry || inquiryId !== currentState.inquiry.id) return;
        if (!currentState.inquiry.draft) throw new Error('Can only edit unsubmitted inquiries');

        setState({ loading: true });

        try {
            const { inquiry } = await updateBookingInquiry(inquiryId, data);

            currentState = getState();
            if (!currentState.loaded || !currentState.inquiry || inquiryId !== currentState.inquiry.id) return;

            setState({
                inquiry,
                loading: false,
            });

            return inquiry;
        } catch (e) {
            currentState = getState();
            if (!currentState.loaded || !currentState.inquiry || inquiryId !== currentState.inquiry.id) return;

            setState({ loading: false });
            throw e;
        }
    },

    createAndUpdateDraft: async (eventId: number | string, data: Partial<BizlyAPI.Inquiry>) => {
        try {
            let inquiry = await currentInquiryActions.createDraft(eventId);
            if (!inquiry) return;
            const inquiryId = inquiry.id;
            return await currentInquiryActions.updateDraft(inquiryId, data);
        } catch (e) {
            throw e;
        }
    },

    setInquiry: (eventId: number | string, inquiry: BizlyAPI.Inquiry) => {
        setState({
            inquiry,
            eventId,
            loaded: getState().venues ? true : undefined,
        });
    },

    mergeVenues: (eventId: number | string, updatedVenues: Bizly.Venue[]) => {
        let currentState = getState();
        if (!currentState.loaded || !currentState.inquiry || eventId !== currentState.eventId) return;

        const updatedIds = new Set(updatedVenues.map(venue => venue.id));
        const merged = [...currentState.venues.filter(venue => !updatedIds.has(venue.id)), ...updatedVenues];

        setState({ venues: merged, loaded: getState().inquiry ? true : undefined });
    },

    removeVenueInq: (eventId: number | string, targetVenueId: number) => {
        let currentState = getState();
        if (!currentState.loaded || !currentState.inquiry || eventId !== currentState.eventId) return;

        const toDelete = currentState.venues.find(
            venue => venue.venue.id === targetVenueId && venue.status === 'Added'
        );
        if (!toDelete) return;

        setState({
            venues: currentState.venues.filter(venue => {
                if (venue.venue.id === targetVenueId && venue.status === 'Added') return false;
                return true;
            }),
        });
    },

    removeVenue: async (inquiryId: number, venueId: number) => {
        let currentState = getState();
        if (!currentState.loaded || !currentState.inquiry || inquiryId !== currentState.inquiry.id) return;
        if (!currentState.inquiry.draft) throw new Error('Can only edit unsubmitted inquiries');

        setState({ loading: true });

        try {
            const { inquiry } = await removeVenueFromInquiry(inquiryId, venueId);

            currentState = getState();
            if (!currentState.loaded || !currentState.inquiry || inquiryId !== currentState.inquiry.id) return;

            setState({
                inquiry,
                venues: currentState.venues.filter(venue => venue.id !== venueId),
                loading: false,
            });

            return inquiry;
        } catch (e) {
            currentState = getState();
            if (!currentState.loaded || !currentState.inquiry || inquiryId !== currentState.inquiry.id) return;

            setState({ loading: false });
            throw e;
        }
    },

    submit: async (inquiryId: number) => {
        let currentState = getState();
        if (!currentState.loaded || !currentState.inquiry || inquiryId !== currentState.inquiry.id) return;
        if (!currentState.inquiry.draft) throw new Error('Can only submit unsubmitted inquiries');

        setState({ loading: true });

        try {
            const { inquiry } = await submitInquiry(inquiryId);

            currentState = getState();
            if (!currentState.loaded || !currentState.inquiry || inquiryId !== currentState.inquiry.id) return;

            setState({
                inquiry,
                venues: currentState.venues.map(venue => ({ ...venue, status: 'Submitted' })),
                loading: false,
            });

            return inquiry;
        } catch (e) {
            currentState = getState();
            if (!currentState.loaded || !currentState.inquiry || inquiryId !== currentState.inquiry.id) return;

            setState({ loading: false });
            throw e;
        }
    },

    updateAndSubmit: async (inquiryId: number, data: Partial<BizlyAPI.Inquiry>) => {
        try {
            const inquiry = await currentInquiryActions.updateDraft(inquiryId, data);
            if (!inquiry) return;
            return await currentInquiryActions.submit(inquiryId);
        } catch (e) {
            throw e;
        }
    },

    cancelAndCopy: async (inquiryId: number | string) => {
        let currentState = getState();
        if (!currentState.loaded || !currentState.inquiry || inquiryId !== currentState.inquiry.id) return;
        if (currentState.inquiry.draft) throw new Error('Can only copy a submitted inquiry');

        setState({ loading: true });

        try {
            const { inquiry } = await cancelAndCopyInquiry(inquiryId);

            currentState = getState();
            if (!currentState.loaded || !currentState.inquiry || inquiryId !== currentState.inquiry.id) return;

            setState({
                inquiry,
                loading: false,
            });

            return inquiry;
        } catch (e) {
            currentState = getState();
            if (!currentState.loaded || !currentState.inquiry || inquiryId !== currentState.inquiry.id) return;

            setState({ loading: false });
            throw e;
        }
    },
};
