import React from 'react';

import styled from 'styled-components';
import keyBy from 'lodash/keyBy';

import { Dialog, DialogContent } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { TSpacePOST } from 'api';

import { Spacer, Copy, Row } from 'ui';
import { H2Headline } from 'components/ui/Headline';
import TextButton from 'components/ui/Button/TextButton';
import { SpinnerOverlay } from 'components/Spinner';

import Form from 'components/Form';
import { TSpace, SPECIAL_OPTION_IDS, NEWLY_CREATED_SPACE } from './utils';
import { smallFields, smallSchema, modalFields, modalSchema, TModalValue } from './venueSpacesModalSchema';
import { isEmptyString } from '../utils';

const WideDialogContent = styled(DialogContent)`
    min-width: 900px;
    padding: 32px !important;
`;

const SpacedCopy = styled(Copy)`
    line-height: 1.6;
`;

const ButtonsRow = styled(Row)`
    justify-content: flex-end;

    > *:not(:first-child) {
        margin-left: 15px;
    }
`;

export const spaceToModalVals = ({ id, name, description, size, maxCapacity, images = [] }: Partial<TSpace> = {}) => ({
    id,
    name,
    description,
    size,
    maxCapacity,
    imageUrl: images[0]?.srcUrl,
});

const modalValError = ({ name, size, maxCapacity, imageUrl }: Partial<TModalValue>) => {
    return (
        isEmptyString(name) ||
        isEmptyString(imageUrl) ||
        (typeof size === 'string' ? isEmptyString(size || '') : typeof size !== 'number') ||
        (typeof maxCapacity === 'string' ? isEmptyString(maxCapacity || '') : typeof maxCapacity !== 'number')
    );
};

export default function VenueSpacesModal({
    field,
    value,
    onChange,
    spaces = [],
    onUpdateVenueSpaces,
    disabled,
}: {
    field: string;
    value: number;
    onChange: ({ field, value, error }: any) => void;

    spaces: TSpace[];
    venueId: number | string;
    proposalId: number | string;
    onUpdateVenueSpaces: (space: TSpacePOST) => Promise<TSpace[] | false>;
    disabled?: boolean;
}) {
    const spacesDict = React.useMemo(() => keyBy([...spaces, NEWLY_CREATED_SPACE], space => space.id), [spaces]);
    const isCreate = (id: number) => id === SPECIAL_OPTION_IDS.create;

    const [openModal, setOpenModal] = React.useState(false);
    const [modalValue, setModalValue] = React.useState<Partial<TModalValue>>({});

    const [preModalValue, setPreModalValue] = React.useState<number>(value);
    React.useEffect(() => {
        const intentToCreateNew = isCreate(value);
        if (intentToCreateNew) {
            setModalValue(spaceToModalVals(NEWLY_CREATED_SPACE));
            setOpenModal(true);
        }
        // We'll track the previous value to return the user back if they cancel the modal
        return () => setPreModalValue(value);
    }, [value]);

    const onClose = () => {
        onChange({ field, value: preModalValue });
        setOpenModal(false);
    };

    const onEditSpace = () => {
        const loadedSpace = spacesDict[value];
        if (loadedSpace) {
            setPreModalValue(value);
            setModalValue(spaceToModalVals(loadedSpace));
            setOpenModal(true);
        }
    };

    const [loading, setLoading] = React.useState(false);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [snackbarKey, setSnackbarKey] = React.useState<string | number | undefined>();

    React.useEffect(
        () => () => {
            if (snackbarKey) closeSnackbar(snackbarKey);
        },
        [modalValue, value, openModal, snackbarKey, closeSnackbar, setSnackbarKey]
    );

    const onSubmit = async () => {
        const { id: spaceId, ...data } = modalValue;
        if (!spaceId) return;

        if (modalValError(modalValue)) {
            return setSnackbarKey(
                enqueueSnackbar('Please fill out all fields', { variant: 'error', persist: true }) || undefined
            );
        }

        setLoading(true);

        try {
            const newSpaces = await onUpdateVenueSpaces({ ...data, ...(isCreate(spaceId) ? {} : { id: spaceId }) });
            if (newSpaces) {
                if (isCreate(spaceId)) {
                    const newSpace = newSpaces.filter(s => !spacesDict[s.id]);

                    onChange({ field, value: newSpace[0].id });
                } else {
                    onChange({ field, value: modalValue.id });
                }

                setOpenModal(false);
            }
        } finally {
            setLoading(false);
        }
    };

    const onFormChange = ({ value }: { field: string; value: { id: string } }) => onChange({ field, value: value.id });
    const onModalChange = ({ value: newSpaceVals }: { field: string; value: TModalValue }) => {
        setModalValue(oldSpaceVals => {
            const selectedNewSpace = oldSpaceVals.id !== newSpaceVals.id;
            const loadedSpace = selectedNewSpace && spacesDict[newSpaceVals.id];

            const loadedSpaceVals = loadedSpace ? spaceToModalVals(loadedSpace) : {};

            return { ...oldSpaceVals, ...newSpaceVals, ...loadedSpaceVals };
        });
    };

    return (
        <>
            <Form
                fields={smallFields(spaces, onEditSpace)}
                schema={smallSchema}
                value={{ id: value }}
                onChange={onFormChange}
                disabled={disabled}
            />

            <Dialog open={openModal} maxWidth="xl" onBackdropClick={loading ? undefined : onClose}>
                <WideDialogContent>
                    <H2Headline>Space Editor</H2Headline>
                    <Spacer small />
                    <SpacedCopy>You can update your space here.</SpacedCopy>

                    <Spacer large />

                    <Form
                        fields={modalFields(spaces)}
                        schema={modalSchema}
                        value={modalValue}
                        onChange={onModalChange}
                    />

                    <Spacer largest />

                    <ButtonsRow>
                        <TextButton onClick={onClose} secondary>
                            Cancel
                        </TextButton>
                        <TextButton onClick={onSubmit}>
                            {modalValue.id === SPECIAL_OPTION_IDS.create ? 'Create' : 'Select'}
                        </TextButton>
                    </ButtonsRow>
                    {loading && <SpinnerOverlay />}
                </WideDialogContent>
            </Dialog>
        </>
    );
}
