import React from 'react';
import styled from 'styled-components';

import { Row, Column, Copy, LabeledCheckbox } from 'ui';

import { Divider } from '@material-ui/core';
import TextButton from 'components/ui/Button/TextButton';

const TeamMembers = styled(Column)`
    min-width: 200px;
    height: 100%;
    overflow: auto;
`;

const AttendingList = styled(Column)`
    flex: 0 1 auto;
    overflow: auto;
`;

const NonAttendingList = styled(Column)`
    flex: 1 0 0;
    overflow: auto;
`;

const TeamListSelectBar = styled(Row)`
    flex: 0 0 auto;
    padding: 4px 0;
`;

type TSelectBar = { onSelectAll: () => void; onClear: () => void };

const SelectBar = ({ onSelectAll, onClear }: TSelectBar) => (
    <TeamListSelectBar itemSpacing="smallish">
        <TextButton onClick={() => onSelectAll()}>Select All</TextButton>
        <TextButton onClick={() => onClear()}>Clear</TextButton>
    </TeamListSelectBar>
);

const Attendees = ({
    members,
    onToggle,
    isChecked,
    colorHash,
}: {
    members?: BizlyAPI.TeamMember[];
    onToggle: (id: number) => void;
    isChecked: (id: number) => boolean;
    colorHash: ColorHash;
}) => (
    <>
        {(members || []).map(member => (
            <LabeledCheckbox
                key={member.user.email}
                disabled={false}
                isChecked={isChecked(member.user.id)}
                onChange={() => onToggle(member.user.id)}
                label={[member.user.firstName, member.user.lastName].join(' ').trim() || member.user.email}
                iconStyle={{
                    color: colorHash.hex(member.user.email) as Themed.Color,
                    backgroundColor: 'transparent' as Themed.Color,
                }}
            />
        ))}
    </>
);

export default function TeamMembersList({
    peopleHours,
    active,
    inactive,
    onToggle,
    isChecked,
    colorHash,
    onSelectAll,
    onClear,
}: {
    peopleHours?: number;
    active: BizlyAPI.TeamMember[];
    inactive: BizlyAPI.TeamMember[];
    onToggle: (id: number) => void;
    isChecked: (id: number) => boolean;
    colorHash: ColorHash;
} & TSelectBar) {
    return (
        <TeamMembers>
            <Copy>
                <b>People Hours: </b>
                {peopleHours?.toFixed(2) ?? '-'}
            </Copy>
            {active.length > 0 && (
                <>
                    <AttendingList>
                        <Attendees members={active} onToggle={onToggle} isChecked={isChecked} colorHash={colorHash} />
                    </AttendingList>
                    <Divider />
                </>
            )}
            <NonAttendingList>
                <Attendees
                    members={inactive.sort((a, b) => (isChecked(a.user.id) ? -1 : 1))}
                    onToggle={onToggle}
                    isChecked={isChecked}
                    colorHash={colorHash}
                />
            </NonAttendingList>
            <SelectBar onSelectAll={onSelectAll} onClear={onClear} />
        </TeamMembers>
    );
}
