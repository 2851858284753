import React from 'react';
import styled, { css } from 'styled-components/macro';

import pluckFileName from '../../utils/pluckFileName';

import { ReactComponent as DocumentIconSVG } from '../../images/icons/document.svg';
import { withIconStyles } from 'shared';
import colorFns from 'colorFns';

import { Copy as UICopy, Spacer } from '../../ui';
import Button from '../ui/Button';

import StepsColumn from './StepsColumn';

const DocumentIcon = withIconStyles(DocumentIconSVG);

const Copy = styled(UICopy)`
    font-size: 15px;
    line-height: 1.47;
`;

const MSADocument = styled('div')`
    font-size: 15px;
    letter-spacing: -0.1px;
    color: ${colorFns.highlightedText};

    display: flex;
    align-items: center;

    svg,
    label {
        cursor: auto;
    }
`;

type VenueDocsProps = {
    number: number;
    venue: Bizly.VenueProposal;
    copy: string;
    altCopy: string;
    redText: string;
};

const defaultCopy =
    'The contract spells out the details and rates for your meeting, along with mutually agreed terms. Before both parties have signed is the best time to negotiate!';

const defaultAltCopy = 'You will need to contact the venue for a copy of their contract.';

const VenueDocs = ({ number, venue, copy = defaultCopy, altCopy = defaultAltCopy, redText }: VenueDocsProps) => {
    const { msaDocumentUrl } = venue;
    const hasMSADocument = !!msaDocumentUrl;
    return (
        <StepsColumn number={number} headline={'Download documents'}>
            <Copy dangerouslySetInnerHTML={{ __html: hasMSADocument ? copy : altCopy }} />
            <Spacer />
            {hasMSADocument && (
                <>
                    <MSADocument
                        css={css`
                            & > * {
                                cursor: pointer;
                            }
                        `}
                    >
                        <DocumentIcon />
                        <label>{pluckFileName(msaDocumentUrl)}</label>
                    </MSADocument>
                    <Spacer />
                    <Button width={119} onClick={() => window.open(msaDocumentUrl, '_blank')}>
                        Download
                    </Button>
                </>
            )}
            {redText && (
                <>
                    <Spacer />
                    <Copy
                        css={css`
                            color: ${({ theme: { getColor, EColors } }) => getColor(EColors.warningText)};
                        `}
                        dangerouslySetInnerHTML={{ __html: redText }}
                    />
                </>
            )}
        </StepsColumn>
    );
};

export default VenueDocs;
