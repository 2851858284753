import React from 'react';

import { useEvent } from 'providers/event';

import { Redirect } from 'react-router';

export default function EventDashboard() {
    const { event } = useEvent();

    return <Redirect to={`/meetings/${event.id}`} push={false} />;
}
