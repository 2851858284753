import React from 'react';
import styled from 'styled-components';

import { Column, Copy } from 'ui';
import { H3Headline } from 'components/ui/Headline';
import colorFns from 'colorFns';

const AttendeesCol = styled(Column)`
    > * {
        padding: 12px;

        &:not(:last-child) {
            border-bottom: 1px solid ${colorFns.softBorder};
        }
    }
`;

const AttendeeItem = ({ attendee }: { attendee: BizlyAPI.Attendee }) => {
    const name = [attendee.firstName, attendee.lastName].join(' ').trim();

    return (
        <Column>
            {name && <Copy>{name}</Copy>}
            <Copy faded={!!name}>{attendee.email}</Copy>
        </Column>
    );
};

const AttendeeHeadline = styled(H3Headline)`
    padding: 8px 0;
    text-align: center;
    position: sticky;
    top: 0;
    background: ${colorFns.pureWhite};
    z-index: 1;
`;

const AttendeeListsCol = styled(Column)`
    padding: 0 16px;
`;

function AttendeeList({ attendees, label, full }: { attendees: BizlyAPI.Attendee[]; label: string; full?: boolean }) {
    const picked = full ? attendees : attendees.slice(0, 5);
    const leftover = attendees.length - picked.length;

    return attendees.length > 0 ? (
        <Column itemSpacing="small">
            <AttendeeHeadline>
                <b>{`${label} (${attendees.length})`}</b>
            </AttendeeHeadline>
            <AttendeesCol>
                {picked.map(attendee => (
                    <AttendeeItem key={attendee.email} attendee={attendee} />
                ))}
                {leftover > 0 ? (
                    <Copy>
                        <b>{`+ ${leftover} more`}</b>
                    </Copy>
                ) : null}
            </AttendeesCol>
        </Column>
    ) : null;
}

export default function AttendeesLists({ attendees, full }: { attendees: BizlyAPI.Attendee[]; full?: boolean }) {
    const attending = attendees?.filter(attendee => attendee.status === 'attending') || [];
    const invited = attendees?.filter(attendee => attendee.status !== 'attending') || [];

    return (
        <AttendeeListsCol itemSpacing="default">
            <AttendeeList attendees={attending} label="Attending" full={full} />
            <AttendeeList attendees={invited} label="Invited" full={full} />
        </AttendeeListsCol>
    );
}
