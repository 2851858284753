import React from 'react';

import styled from 'styled-components';

import { TruncatingSingleLineCopy, Copy, Row, Column, Spacer } from 'ui';

import { SelectField } from 'components/FormFields';
import { FittedSquareImage } from 'components/ImageUploader';
import Button from 'components/ui/Button';

import colorFns from 'colorFns';
import fontFns from 'fontFns';

import { spacesToOptions, TSpace } from './utils';

const FittedSingleLine = styled(TruncatingSingleLineCopy)`
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    width: 0;
    min-width: 0;
`;

const FlexColumn = styled(Column)`
    flex-grow: 1;
`;

const CenteredRow = styled(Row)`
    align-items: center;
`;

const SelectFieldWrap = styled(Column)`
    width: 240px;
`;

const ButtonWrap = styled.div`
    margin: 8px 0 4px 0;
`;

const FormLabel = styled.b`
    color: ${colorFns.formLabel};
    ${fontFns.formLabel}
`;

export default function VenueSpacesSelectField({
    field,
    value,
    onChange,
    spaces,
    onEdit,
    disabled,
}: {
    field: string;
    value?: TSpace['id'];
    onChange: (update: { field: string; value: TSpace['id']; error?: {} | null }) => void;
    spaces: TSpace[];
    onEdit: () => void;
    disabled?: boolean;
}) {
    const selectedOption = spaces.find((space: TSpace) => space.id === value);

    const selectField = (
        <SelectFieldWrap>
            <SelectField
                field={field}
                value={value}
                options={spacesToOptions(spaces, true)}
                onChange={onChange}
                readonly={false}
                disabled={disabled}
                placeholder="Select a space..."
            />
        </SelectFieldWrap>
    );

    return selectedOption ? (
        <CenteredRow itemSpacing="default">
            <FittedSquareImage width={140} src={selectedOption?.images[0]?.srcUrl} placeholder="No Image Available" />

            <FlexColumn itemSpacing="small">
                <CenteredRow itemSpacing="small">
                    {selectField}

                    {!disabled && (
                        <ButtonWrap>
                            <Button onClick={onEdit}>Edit Space</Button>
                        </ButtonWrap>
                    )}
                </CenteredRow>

                {selectedOption.description && (
                    <Row>
                        <FittedSingleLine>{selectedOption.description}</FittedSingleLine>
                    </Row>
                )}

                {(selectedOption.size !== null || selectedOption.maxCapacity !== null) && (
                    <Row itemSpacing="large">
                        <Copy regular>
                            <FormLabel>size:</FormLabel> {selectedOption.size || 'n/a'} sqft.
                        </Copy>

                        <Copy regular>
                            <FormLabel>capacity</FormLabel>: {selectedOption.maxCapacity || 'n/a'} people
                        </Copy>
                    </Row>
                )}
                <Spacer xsmall />
            </FlexColumn>
        </CenteredRow>
    ) : (
        selectField
    );
}
