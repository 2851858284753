import React from 'react';
import { useSnackbar, OptionsObject } from 'notistack';

export default function usePersistentSnackbar(deps: any[]) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [snackbarKey, setSnackbarKey] = React.useState<string | number | undefined>();

    const clearSnackbar = () => {
        if (snackbarKey) closeSnackbar(snackbarKey);
    };
    React.useEffect(clearSnackbar, deps);

    const clearIfKeyChanges = () => clearSnackbar;
    React.useEffect(clearIfKeyChanges, [snackbarKey]);

    const persistentSnackbar = React.useCallback(
        (message: React.ReactNode, options: OptionsObject) =>
            setSnackbarKey(
                enqueueSnackbar(message, {
                    ...options,
                    persist: true,
                }) || undefined
            ),
        [enqueueSnackbar, setSnackbarKey]
    );

    return persistentSnackbar;
}
