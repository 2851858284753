import { format, parseISO } from 'date-fns';

import { TScheduleDay, TEventSpace, TAccommodation } from 'components/Planner/types';

export const SCHEDULE = 'schedule';
export const EVENT_SPACES = 'eventSpaces';
export const ACCOMMODATIONS = 'accommodations';
export const VIRTUAL = 'virtualMeetings';

export const formatScheduleSummary = (schedule: TScheduleDay[] = []) =>
    schedule
        .filter(day => day.date)
        .map(day => day.date && format(parseISO(day.date), 'MMM dd, yyyy'))
        .join(', ');

export const formatEventSpacesSummary = (eventSpaces?: TEventSpace[]) =>
    eventSpaces?.length && eventSpaces?.some(space => space.date)
        ? eventSpaces.filter(space => space.date).length.toString()
        : '';

export const formatAccommodationsSummary = (accommodations: TAccommodation[] = []) => {
    const days = accommodations?.length;
    const rooms = accommodations?.reduce((acc, curr) => acc + (curr.count || 0), 0);
    return days && rooms ? `${days} / ${rooms}` : '';
};

export const formatVirtualMeetingsSummary = (serviceProvider?: { id: number; name?: string }) => serviceProvider?.name;
