import React from 'react';
import styled from 'styled-components';
import { tzMoment, userFormattedTimestamp } from 'utils/moment';
import { getExtension, isImage } from 'cloudinary';
import keyBy from 'lodash/keyBy';

import { chimeChatActions, useChimeChat, getPending, getMessagesList } from 'stores/chime-chat';
import { useUser } from 'providers/user';
import { useAttendees } from 'stores/attendees';

import { Column, Copy, ExternalLink, InlineRow, Row } from 'ui';
import { TextArea } from 'components/FormFields';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CloudinaryUploader } from 'components/CloudinaryUploader';
import { Spinner, SpinnerOverlay } from 'components/Spinner';
import CircleImage from 'components/CircleImage';
import Linkify from 'react-linkify';

import { ReactComponent as AirplaneSVG } from 'images/icons/airplane.svg';
import { ReactComponent as DeleteIconSVG } from 'images/icons/trash-can.svg';
import { ReactComponent as PaperclipSVG } from 'images/icons/paperclip.svg';
import { withInteractibleIconStyles } from 'shared';
import colorFns from 'colorFns';

import ColorHash from 'color-hash';
const colorHash = new ColorHash({ lightness: 0.5 });

const Airplane = styled(withInteractibleIconStyles(AirplaneSVG))`
    color: ${colorFns.pureWhite.alpha(0.74)};
    position: absolute;
    right: 10.5px;
`;
const Paperclip = styled(PaperclipSVG).attrs({ viewBox: '10 0 29 29' })`
    display: block;
    flex: 0 0 auto;
    color: ${colorFns.pureWhite.alpha(0.74)};
`;
const DeleteIcon = styled(withInteractibleIconStyles(DeleteIconSVG)).attrs({ viewBox: '2 4 15 21' })`
    height: 21px;
    width: 21px;
    flex: 0 0 auto;
    color: ${colorFns.pureWhite.alpha(0.74)};
`;

const FileName = styled(Column)`
    word-break: break-word;
`;

const FullWidthColumn = styled(Column)`
    width: 100%;
    height: 100%;
    max-height: 90vh;
`;

const MessagesWindowContainer = styled(Column)`
    height: calc(90vh - 140px);
    overflow: auto;
    flex-direction: column-reverse;
`;

const ChatMessageContent = styled(Column)``;

const ChatMessageFiles = styled(Column)``;

const ImgHideOnError = ({ name, src, className }: { name: string; src?: string; className?: string }) => {
    const [err, setErr] = React.useState(src ? false : true);
    return err ? null : <img src={src} alt={name} className={className} onError={() => setErr(true)} />;
};

const PreviewImg = styled(ImgHideOnError)`
    max-width: 100%;
    max-height: 200px;

    border-radius: 16px;
`;

const Name = styled(Copy)`
    color: ${colorFns.pureWhite};
`;

const Timestamp = styled.span`
    font-size: 15px;
    color: ${colorFns.grey};
`;

const HiddenTimestamp = styled(Timestamp)`
    visibility: hidden;
`;

const CircleImg = styled(CircleImage)``;

const ChatMessage = styled(Row)<{ pending?: boolean; self?: boolean; follows?: boolean }>`
    ${({ pending }) => (pending ? `opacity: 0.5;` : '')}

    > ${CircleImg} {
        flex: 0 0 auto;
        ${({ follows }) => (follows ? 'visibility: hidden;' : '')}
    }

    > ${ChatMessageContent} {
        flex: 0 1 auto;
        max-width: 70%;
    }

    ${({ self }) => (self ? 'flex-direction: row-reverse;' : '')}
    ${({ follows }) => (follows ? 'padding-top: 0px !important;' : '')}

    &:hover {
        ${HiddenTimestamp} {
            visibility: visible;
        }
    }

`;

const MessagesWindow = styled(InfiniteScroll)`
    overflow: unset !important;
    display: flex;
    flex-direction: column-reverse;

    ${ChatMessage} {
        padding: 12px;
        width: 100%;
        box-sizing: border-box;
    }
`;

const MessageBubble = styled(Column)<{ self?: boolean }>`
    border-radius: 20px;
    background-color: ${({ self, ...props }) =>
        self ? `${colorFns.primaryAction(props)}` : `${colorFns.darkGrey(props)}`};

    ${({ self }) => (self ? `border-top-right-radius: 2px;` : `border-top-left-radius: 2px;`)}

    padding: 12px;
`;

const MessageContent = styled(Copy)`
    white-space: pre-wrap;
    word-break: break-word;
`;

const FileDisplayName = styled(Name)`
    color: ${colorFns.secondaryTextAction};
    padding-top: 4px;
`;

const chopOffVersion = (arn = '') => arn.replace(/(.*)-v\d+/, '$1');

const Input = styled(TextArea)`
    border-bottom: 0 !important;
    background-color: ${colorFns.darkGrey} !important;
    border-radius: 8px;

    padding: 0 10.5px;
    padding-right: 40px;
`;

const LinkifyLinkFn = (decoratedHref: string, decoratedText: string, key: number) => (
    <ExternalLink key={key} href={decoratedHref} openInNewTab underline>
        {decoratedText}
    </ExternalLink>
);

const MessageItem = ({
    name,
    email,
    image,
    timestamp,
    content,
    attachments,
    pending,
    isSelf,
    follows,
}: {
    name: string;
    email?: string;
    image?: string;
    timestamp: string;
    content: string;
    attachments?: { cloudinaryId: string; title: string; url: string }[];
    pending?: boolean;
    isSelf?: boolean;
    follows?: boolean;
}) => (
    <ChatMessage pending={pending} itemSpacing="small" self={isSelf} follows={follows}>
        <CircleImg
            src={image}
            fallbackColor={colorHash.hex(email ?? name)}
            fallback={(name || email || '')
                .split(' ')
                .map(w => w.charAt(0))
                .join(' ')}
        />
        <ChatMessageContent alignItems={isSelf ? 'flex-end' : 'flex-start'} itemSpacing="xsmall">
            {!follows && (
                <InlineRow alignItems="center" justifyContent={isSelf ? 'flex-end' : 'flex-start'} itemSpacing="small">
                    {!isSelf && (
                        <Name>
                            <b>{name}</b>
                        </Name>
                    )}
                    <Timestamp>{timestamp}</Timestamp>
                </InlineRow>
            )}
            <MessageBubble self={isSelf}>
                <Column itemSpacing="small">
                    <MessageContent>
                        <Linkify componentDecorator={LinkifyLinkFn}>{content}</Linkify>
                    </MessageContent>
                    {attachments && attachments.length > 0 && (
                        <ChatMessageFiles>
                            {attachments.map(({ cloudinaryId, url, title }) => (
                                <Row key={cloudinaryId}>
                                    {!isImage(getExtension(url)) && <Paperclip />}
                                    <ExternalLink href={url} openInNewTab underline>
                                        {!isImage(getExtension(url)) && (
                                            <FileDisplayName>{`${title}.${getExtension(url)}`}</FileDisplayName>
                                        )}
                                        {isImage(getExtension(url)) && <PreviewImg name={title} src={url} />}
                                    </ExternalLink>
                                </Row>
                            ))}
                        </ChatMessageFiles>
                    )}
                </Column>
            </MessageBubble>
        </ChatMessageContent>
        {follows && <HiddenTimestamp>{timestamp}</HiddenTimestamp>}
    </ChatMessage>
);

const formatName = (user?: { firstName?: string | null; lastName?: string | null; email?: string }) =>
    user ? [user.firstName ?? '', user.lastName ?? ''].join(' ').trim() || user.email || '' : '';

type TFile = { title: string; cloudinaryId: string; url: string };

export default function ChimeChat({ channelArn, className }: { channelArn?: string; className?: string }) {
    const { channelArn: storeChannelArn, nextToken, socketConn, loaded } = useChimeChat();
    const messages = useChimeChat(getMessagesList);
    const pendingMessages = useChimeChat(getPending);

    const { user } = useUser();
    const { attendees } = useAttendees();
    const attendeeByArn = keyBy(
        (attendees || []).filter(a => a.chimeInfo?.appInstanceUserArn),
        a => chopOffVersion(a.chimeInfo?.appInstanceUserArn ?? '')
    ) as Partial<Record<string, BizlyAPI.Attendee>>;

    const [message, setMessage] = React.useState<string>('');
    const [file, setFile] = React.useState<TFile>();
    const [uploading, setUploading] = React.useState(false);
    const send = () => {
        if (channelArn && socketConn && message.trim()) {
            chimeChatActions.send(channelArn, message.trim(), file ? { attachments: [file] } : undefined);
            setMessage('');
            setFile(undefined);
        }
    };

    const chatRef = React.useRef<HTMLDivElement | null>(null);
    React.useLayoutEffect(() => {
        const { current: messagesWindow } = chatRef;
        if (messagesWindow) {
            messagesWindow.scrollTop = messagesWindow.scrollHeight;
        }
    }, [pendingMessages, chatRef]);

    const count = [...(messages || []), ...pendingMessages].length;
    const showMessages = channelArn === storeChannelArn && !!attendees && !!count;

    const groupedMessages = (messages || []).reduce((groups, message) => {
        const lastGroup = groups[groups.length - 1] ?? [];
        const lastMessage = lastGroup[lastGroup.length - 1];
        if (
            lastMessage &&
            lastMessage.sender.arn === message.sender.arn &&
            tzMoment(lastMessage.createdTimestamp).diff(tzMoment(message.createdTimestamp), 'minutes') <= 5
        ) {
            lastGroup.push(message);
            return groups;
        }

        groups.push([message]);
        return groups;
    }, [] as BizlyChime.ChatMessage[][]);

    return (
        <FullWidthColumn itemSpacing="xsmall" className={className}>
            <MessagesWindowContainer id="scrollableDiv" ref={chatRef}>
                <MessagesWindow
                    dataLength={[...(messages || []), ...pendingMessages].length}
                    next={() => nextToken && channelArn && chimeChatActions.loadMore(channelArn)}
                    inverse={true}
                    hasMore={!!nextToken}
                    loader={null}
                    scrollableTarget="scrollableDiv"
                >
                    {showMessages &&
                        pendingMessages.map(({ id, content, metadata }, idx) => {
                            return (
                                <MessageItem
                                    key={id}
                                    name={formatName(user)}
                                    email={user.email}
                                    image={user.imageUrl}
                                    timestamp="now"
                                    content={content}
                                    attachments={metadata?.attachments}
                                    pending
                                    isSelf
                                    follows={idx !== pendingMessages.length - 1}
                                />
                            );
                        })}
                    {showMessages &&
                        groupedMessages.map(group => (
                            <React.Fragment key={group[0].messageId}>
                                {group.map(({ messageId, sender, createdTimestamp, content, metadata }, idx) => {
                                    const arn = chopOffVersion(sender.arn);
                                    const attendee =
                                        attendeeByArn[arn]?.email === user.email ? user : attendeeByArn[arn];
                                    const name = formatName(attendee);

                                    return (
                                        <MessageItem
                                            key={messageId}
                                            name={sender.name.trim() || name}
                                            email={attendee?.email}
                                            image={attendee?.imageUrl}
                                            timestamp={userFormattedTimestamp(createdTimestamp)}
                                            content={content}
                                            attachments={metadata?.attachments}
                                            isSelf={user.email === attendee?.email}
                                            follows={idx !== group.length - 1}
                                        />
                                    );
                                })}
                            </React.Fragment>
                        ))}
                </MessagesWindow>
                {!loaded && <SpinnerOverlay delay />}
            </MessagesWindowContainer>

            <Row alignItems="center" style={{ position: 'relative' }}>
                <CloudinaryUploader
                    style={{ flex: '1 0 0' }}
                    onUploadSuccess={(file: TFile) => {
                        setFile(file);
                        setUploading(false);
                    }}
                    onUploadStart={() => setUploading(true)}
                    disabled={uploading}
                    content={
                        <Input
                            field=""
                            placeholder="Type to add your message"
                            rows={2}
                            rowsMax={4}
                            maxLength={2048}
                            value={message}
                            onChange={({ value }: { value: string }) => setMessage(value)}
                            onKeyPress={(e: React.KeyboardEvent) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    send();
                                }
                            }}
                        />
                    }
                />
                {channelArn && socketConn && message.trim() && <Airplane onClick={send} />}
                {!socketConn && <SpinnerOverlay />}
            </Row>

            {file || uploading ? (
                <Row itemSpacing="smallish" justifyContent="space-between">
                    <InlineRow>
                        <Paperclip />
                        {uploading ? (
                            <Spinner suppressMargin />
                        ) : (
                            file && (
                                <ExternalLink href={file.url} openInNewTab underline>
                                    <FileName itemSpacing="small">
                                        <FileDisplayName>{`${file.title}.${getExtension(file.url)}`}</FileDisplayName>
                                    </FileName>
                                </ExternalLink>
                            )
                        )}
                    </InlineRow>
                    {file && !uploading && <DeleteIcon onClick={() => setFile(undefined)} />}
                </Row>
            ) : (
                <Row justifyContent="flex-end">
                    <CloudinaryUploader
                        style={{ flex: '0 0 auto' }}
                        onUploadSuccess={(file: TFile) => {
                            setFile(file);
                            setUploading(false);
                        }}
                        onUploadStart={() => setUploading(true)}
                        disabled={uploading}
                        noHideDisabled
                    >
                        <Paperclip />
                    </CloudinaryUploader>
                </Row>
            )}
        </FullWidthColumn>
    );
}
