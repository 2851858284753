import React, { MutableRefObject } from 'react';

import styled from 'styled-components';
import PopperJs from 'popper.js';

import { View } from 'react-big-calendar';

import { Popover } from '@material-ui/core';
import Popper from 'components/TypedPopper';

import { POPPER_Z_INDEX } from '../styles';

const NoPaperPopover = styled(Popover)`
    & > .MuiPopover-paper {
        display: none;
    }
`;

type TBigCalendarPopper = {
    blockRef?: MutableRefObject<HTMLDivElement | null>;

    onBackdropClick?: () => void;

    view?: View;
    children?: React.ReactNode;
};

export default function BigCalendarPopper({ onBackdropClick, blockRef, view, children }: TBigCalendarPopper) {
    const popperModifiers: PopperJs.Modifiers = {
        flip: {
            enabled: view === 'week' ? true : false,
            flipVariations: true,
            flipVariationsByContent: true,
            boundariesElement: 'viewport',
        },
        preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
            padding: 0,
        },
        keepTogether: {
            enabled: false,
        },
        arrow: {
            enabled: false,
        },
        shiftInwardIfNotFlipped: {
            enabled: true,
            order: 851,

            fn: popperData => {
                const reference = popperData.offsets.reference;
                popperData.styles.left = (popperData.flipped ? 0 : reference.width * -1 * 0.4) + 'px';

                return popperData;
            },
        },
        preventOverflowBottom: {
            enabled: true,
            order: 852,

            fn: popperData => {
                const preventOverflow = popperData.instance.modifiers.find(
                    modifier => modifier.name === 'preventOverflow'
                );
                const overflowBoundaries =
                    preventOverflow?.name === 'preventOverflow' ? preventOverflow.boundaries : undefined;
                const boundaryBottom = overflowBoundaries?.bottom ?? 0;

                const popper = popperData.offsets.popper;
                const popperBottom = popper.top + popper.height;

                popperData.styles.top = (popperBottom >= boundaryBottom ? boundaryBottom - popperBottom : 0) + 'px';

                return popperData;
            },
        },
        appearAboveEventBlock: {
            enabled: true,
            order: 853,

            fn: popperData => {
                popperData.styles.zIndex = POPPER_Z_INDEX + '';
                return popperData;
            },
        },
    };

    return (
        <NoPaperPopover open onBackdropClick={onBackdropClick} anchorEl={document.body}>
            <Popper
                open
                anchorEl={() => blockRef?.current || document.body}
                placement="right-start"
                popperOptions={{ positionFixed: true }}
                modifiers={popperModifiers}
            >
                {children}
            </Popper>
        </NoPaperPopover>
    );
}
