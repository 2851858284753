import React from 'react';
import styled from 'styled-components';

import { TStyledTheme } from 'theme';

export type ButtonProps = React.HTMLProps<HTMLButtonElement> & {
    height?: number;
    width?: string | number;
    secondary?: boolean;
    small?: boolean;
    noBorder?: boolean;
    warning?: boolean;
    variant?: string;
    tertiary?: boolean;
    tertiaryReverse?: boolean;
};

export const buttonColorsStyledFn = ({
    warning,
    secondary,
    tertiary,
    tertiaryReverse,
    noBorder,
    theme: { getColor, EColors },
}: ButtonProps & { theme: TStyledTheme }) => {
    const { pureWhite, disabledAction, secondaryTextAction, secondaryTextActionHover } = EColors;

    const actionColor = warning ? EColors.warningAction : EColors.primaryAction;

    const primaryStyles = {
        base: {
            backgroundColor: getColor(actionColor),
            color: getColor(pureWhite),
            border: `1px solid transparent`,
            whiteSpace: 'nowrap',
        },
        hover: {
            backgroundColor: getColor(actionColor, 0.5),
        },
        disabled: {
            backgroundColor: getColor(disabledAction),
            borderColor: getColor(disabledAction),
            color: getColor(pureWhite),
        },
    } as const; // due to whiteSpace property needing to be 'nowrap' not string;

    const secondaryStyles = {
        base: {
            backgroundColor: getColor(pureWhite),
            color: getColor(actionColor),
            border: `1px solid ${getColor(actionColor)}`,
            whiteSpace: 'nowrap',
        },
        hover: {
            ...primaryStyles.base,
            backgroundColor: getColor(actionColor, 0.5),
        },
        disabled: {
            backgroundColor: getColor(pureWhite, 0.1),
            color: getColor(pureWhite, 0.25),
            borderColor: getColor(pureWhite, 0.05),
        },
    } as const;

    const tertiaryStyles = {
        base: {
            backgroundColor: getColor(pureWhite, 0.25),
            color: getColor(secondaryTextActionHover),
            border: `1px solid transparent`,
            whiteSpace: 'nowrap',
        },
        hover: {
            backgroundColor: getColor(secondaryTextAction),
            color: getColor(pureWhite),
            border: `1px solid transparent`,
        },
        disabled: {
            backgroundColor: getColor(pureWhite, 0.1),
            color: getColor(pureWhite, 0.25),
            borderColor: 'transparent',
        },
    } as const;

    const tertiaryOnStyles = {
        base: tertiaryStyles.hover,
        hover: {
            ...tertiaryStyles.hover,
            backgroundColor: getColor(secondaryTextAction, 0.8),
        },
        disabled: {
            backgroundColor: getColor(pureWhite, 0.1),
            color: getColor(pureWhite, 0.25),
            borderColor: 'transparent',
        },
    } as const;

    const styles = tertiaryReverse
        ? tertiaryOnStyles
        : tertiary
        ? tertiaryStyles
        : secondary
        ? secondaryStyles
        : primaryStyles;

    return {
        ...styles.base,
        ...(noBorder ? { borderWidth: 0 } : {}),
        '&:hover': styles.hover,
        '&:disabled': styles.disabled,
    };
};

const Button = styled('button')<ButtonProps>`
    border-radius: ${({ small }) => (small ? 4 : 8)}px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
    line-height: 1;
    height: ${({ height, small }) => height || (small ? 28 : 41)}px;
    width: ${({ width }) => (width !== undefined ? (typeof width === 'number' ? width + 'px' : width) : '94px')};
    outline: none;
    &:disabled {
        cursor: unset;
        pointer-events: none;
    }
    ${buttonColorsStyledFn}
    transition: 0.2s all ease-out;
`;

export default Button;
