import React from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';

import { CONNECTOR_TYPES } from 'api/connectors';
import { integrationsActions } from 'stores/integrations';
import { useUser } from 'providers/user';
import Switch from '@material-ui/core/Switch';
import { ReactComponent as ZoomLogo } from 'images/icons/zoom.svg';
import { ReactComponent as MicrosoftCalendarLogo } from 'images/icons/microsoft-calendar.svg';
import { ReactComponent as GoogleCalendarLogo } from 'images/icons/google-calendar.svg';

import { Column, Row, ExternalLink } from 'ui';
import { H3Headline } from 'components/ui/Headline';

const Integration = styled(Row)`
    border-bottom: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.1)};
    padding: 20px;
    padding-bottom: 40px;
    h3 {
        font-weight: 800;
    }
    ul {
        padding: 0;
        margin: 5px 0;
        li {
            list-style: none;
            font-size: 13px;
            line-height: 18px;
        }
    }
    .integration-mid {
        min-width: 30%;
    }
    .bizzly-toggle {
        height: 48px;
        width: 80px;
        .Mui-checked + .MuiSwitch-track {
            background: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
        }
        .Mui-checked {
            right: 14px;
            .MuiSwitch-thumb {
                color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.84)};
            }
        }
        .MuiSwitch-switchBase {
            top: 7px;
            left: 7px;
        }
        .MuiSwitch-thumb {
            color: ${({ theme: { getColor, EColors } }) => getColor(EColors.brand)};
            height: 16px;
            width: 16px;
        }
        .MuiSwitch-track {
            opacity: 1;
            border-radius: 20px;
            background: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};
        }
    }
`;

const Zoom = styled(ZoomLogo)`
    height: 40px;
    width: auto;
`;

const Microsoft = styled(MicrosoftCalendarLogo)`
    height: 40px;
    width: auto;
`;

const Google = styled(GoogleCalendarLogo)`
    height: 40px;
    width: auto;
`;

const ConnectorsCol = styled(Column)`
    margin-left: 16px;
`;

const Benefits = {
    [CONNECTOR_TYPES.ZOOM]: ['Generate Zoom links'],
    [CONNECTOR_TYPES.MS]: [
        'Generate Teams links',
        'Invite Microsoft contacts',
        'Synchronize Bizly meetings to your Microsoft Calendar',
    ],
    [CONNECTOR_TYPES.GOOGLE]: [
        'Generate Meet links',
        'Invite Google contacts',
        'Synchronize Bizly meetings to your Google Calendar',
    ],
};

const defaultConnectors: BizlyAPI.Connector[] = ['zoom', 'ms-graph', 'google'];

export default function IntegrationConnection({
    connectors = defaultConnectors,
    benefits = {},
    withRedirect,
    preAuth,
}: {
    connectors?: BizlyAPI.Connector[];
    benefits?: Partial<Record<BizlyAPI.Connector, string[]>>;
    withRedirect?: boolean;
    preAuth?: () => Promise<any>;
}) {
    const { enqueueSnackbar } = useSnackbar();

    const { user, delConnector } = useUser();

    const connected = (type: BizlyAPI.Connector) => user.connectors?.some(c => c.type === type);

    const history = useHistory();
    const handleConnect = async (connector: BizlyAPI.Connector) => {
        if (preAuth) {
            try {
                await preAuth?.();
            } catch (e) {
                throw e;
            }
        }

        try {
            await integrationsActions.auth(connector, withRedirect ? history.location.pathname : undefined);
        } catch (e) {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    };

    const handleDisconnect = async (connector: BizlyAPI.Connector) => {
        try {
            await integrationsActions.disconnect(connector);
            delConnector(connector);
        } catch (e) {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    };

    const connectionHandler = (type: BizlyAPI.Connector) => () =>
        connected(type) ? handleDisconnect(type) : handleConnect(type);

    const showConnector = {
        [CONNECTOR_TYPES.ZOOM]: connectors.includes(CONNECTOR_TYPES.ZOOM) && user?.featureFlags?.zoomIntegration,
        [CONNECTOR_TYPES.MS]: connectors.includes(CONNECTOR_TYPES.MS) && user?.featureFlags?.msGraphIntegration,
        [CONNECTOR_TYPES.GOOGLE]: connectors.includes(CONNECTOR_TYPES.GOOGLE),
    };

    return (
        <Column itemSpacing="small">
            <ConnectorsCol itemSpacing="larger">
                {showConnector[CONNECTOR_TYPES.ZOOM] && (
                    <Integration itemSpacing="larger" alignItems="center">
                        <Column itemSpacing="small">
                            <Zoom />
                        </Column>
                        <Column itemSpacing="larger" className="integration-mid">
                            <H3Headline>Zoom</H3Headline>
                            <ul>
                                {(benefits[CONNECTOR_TYPES.ZOOM] ?? Benefits[CONNECTOR_TYPES.ZOOM]).map(point => (
                                    <li key="item">{point}</li>
                                ))}
                            </ul>
                        </Column>
                        <Column itemSpacing="small">
                            <Switch
                                checked={connected(CONNECTOR_TYPES.ZOOM)}
                                onChange={connectionHandler(CONNECTOR_TYPES.ZOOM)}
                                name="checkedA"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                className="bizzly-toggle"
                            />
                            {connected(CONNECTOR_TYPES.ZOOM) && (
                                <ExternalLink href="https://zoom.us/profile/setting" openInNewTab>
                                    Manage Zoom Settings
                                </ExternalLink>
                            )}
                        </Column>
                    </Integration>
                )}

                {showConnector[CONNECTOR_TYPES.MS] && (
                    <Integration itemSpacing="larger" alignItems="center">
                        <Column itemSpacing="small">
                            <Microsoft />
                        </Column>
                        <Column itemSpacing="large" className="integration-mid">
                            <H3Headline>Microsoft Outlook</H3Headline>
                            <ul>
                                {(benefits[CONNECTOR_TYPES.MS] ?? Benefits[CONNECTOR_TYPES.MS]).map(point => (
                                    <li key="item">{point}</li>
                                ))}
                            </ul>
                        </Column>
                        <Column itemSpacing="small">
                            <Switch
                                checked={connected(CONNECTOR_TYPES.MS)}
                                onChange={connectionHandler(CONNECTOR_TYPES.MS)}
                                name="checkedA"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                className="bizzly-toggle"
                            />
                        </Column>
                    </Integration>
                )}

                {showConnector[CONNECTOR_TYPES.GOOGLE] && (
                    <Integration itemSpacing="larger" alignItems="center">
                        <Column itemSpacing="small">
                            <Google />
                        </Column>
                        <Column itemSpacing="large" className="integration-mid">
                            <H3Headline>Google Cal</H3Headline>
                            <ul>
                                {(benefits[CONNECTOR_TYPES.GOOGLE] ?? Benefits[CONNECTOR_TYPES.GOOGLE]).map(point => (
                                    <li key="item">{point}</li>
                                ))}
                            </ul>
                        </Column>
                        <Column itemSpacing="small">
                            <Switch
                                checked={connected(CONNECTOR_TYPES.GOOGLE)}
                                onChange={connectionHandler(CONNECTOR_TYPES.GOOGLE)}
                                name="checkedA"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                className="bizzly-toggle"
                            />
                        </Column>
                    </Integration>
                )}
            </ConnectorsCol>
        </Column>
    );
}
