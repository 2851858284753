import React from 'react';
import styled from 'styled-components';

import { Column, Copy, Row } from 'ui';
import { H2Headline } from './ui/Headline';
import TextButton from './ui/Button/TextButton';
import { SpinnerOverlay } from './Spinner';
import { Dialog } from '@material-ui/core';
import IntegrationConnection from 'pages/Integrations/IntegrationConnection';
import colorFns from 'colorFns';

const PaddedDialog = styled(Dialog)`
    .MuiPaper-root > *:not(:last-child) {
        border-bottom: 1px solid ${colorFns.softBorder};
    }
`;

const ModalContent = styled.div`
    width: 600px;
    overflow: auto;
    padding: 20px;
    box-sizing: border-box;
`;

const Header = styled(Row)`
    padding: 20px;
    box-sizing: border-box;
`;

const Footer = styled(Row)`
    padding: 20px;
    box-sizing: border-box;
`;

export default function IntegrationsPrompt({
    title,
    prompt,
    connectors,
    benefits,
    onSkip,
    onlySkip,
    withRedirect,
    loadingMessage,
    beforeLeave,
}: {
    title?: string;
    prompt?: string;
    connectors?: BizlyAPI.Connector[];
    benefits?: Partial<Record<BizlyAPI.Connector, string[]>>;
    onSkip: (forever?: boolean) => void;
    onlySkip?: boolean;
    withRedirect?: boolean;
    loadingMessage?: string;
    beforeLeave?: () => Promise<any>;
}) {
    const [loading, setLoading] = React.useState(false);

    const preAuth = async () => {
        if (beforeLeave) {
            setLoading(true);
            try {
                await beforeLeave();
            } catch (e) {
                throw e;
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <PaddedDialog open>
            <Header>
                <div>
                    <Column itemSpacing="small">
                        <H2Headline>{title || 'Connect an Integration?'}</H2Headline>
                        {prompt && <Copy>{prompt}</Copy>}
                    </Column>
                </div>
            </Header>
            <ModalContent>
                <IntegrationConnection
                    connectors={connectors}
                    benefits={benefits}
                    preAuth={preAuth}
                    withRedirect={withRedirect}
                />
            </ModalContent>
            <Footer alignItems="center" justifyContent="flex-end" itemSpacing="smallish">
                {onlySkip ? (
                    <TextButton onClick={() => onSkip()} secondary>
                        Skip
                    </TextButton>
                ) : (
                    <>
                        <TextButton onClick={() => onSkip(true)} secondary>
                            Skip Forever
                        </TextButton>
                        <TextButton onClick={() => onSkip()}>Skip For Now</TextButton>
                    </>
                )}
            </Footer>
            {loading && <SpinnerOverlay message={loadingMessage} />}
        </PaddedDialog>
    );
}
