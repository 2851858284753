import React from 'react';
import styled from 'styled-components';

const BottomBannerDiv = styled.div`
    max-width: 720px;
    padding: 2px;
    box-sizing: border-box;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.brand)};

    line-height: 18px;

    display: flex;
    justify-content: space-around;
    align-items: center;

    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    font-size: 15px;

    pointer-events: auto; // Allow children of overlay to remain clickable
`;

export default function BottomBanner({ children }: { children: React.ReactNode }) {
    return (
        <div>
            <BottomBannerDiv>{children}</BottomBannerDiv>
        </div>
    );
}
