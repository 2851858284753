import React from 'react';

import styled from 'styled-components';
import { parse } from 'query-string';
import { useLocation } from 'react-router';
import { useMediaQuery } from '@material-ui/core';

import { takeFirstIfArray } from '../../util';

import { FORM_SECTIONS, TProposalFormUpdate, TProposal, TProposalForm } from './types';
import { TSpace } from './EventSpacesForm/utils';
import { TSpacePOST } from 'api';

import { Row, Column, SpacedRow, Spacer } from 'ui';
import Button from 'components/ui/Button';
import ThemedLogo from 'components/ui/ThemedLogo';

import { IntroHeader, ProposalDetailsHeader } from './MainFormHeaders';
import ContactInformationForm from './ContactInformationForm';
import GuestroomsForm from './GuestroomsForm';
import EventSpacesForm from './EventSpacesForm';
import ProposalNotesForm from './ProposalNotesForm';
import ProposalFormStatus from './ProposalFormStatus';

import ProgressTracker from './ProgressTracker';
import { SpinnerOverlay } from 'components/Spinner';

import { useFormSectionsHistory, useUnsavedPrompt, useFormValidator, useConfirmModal } from './utils';

import { getProposalFormStatus, getStatusAttributes, TStatusAttributes } from './statusUtils';

const SECTIONS = [
    FORM_SECTIONS.CONTACT,
    FORM_SECTIONS.GUEST_ROOMS,
    FORM_SECTIONS.EVENT_SPACES,
    FORM_SECTIONS.NOTES,
    FORM_SECTIONS.PROPOSAL_STATUS,
];
const PROGRESS_SECTIONS = SECTIONS.slice(0, -1);

const SECTION_TO_COMPONENT = {
    [FORM_SECTIONS.CONTACT]: ContactInformationForm,
    [FORM_SECTIONS.GUEST_ROOMS]: GuestroomsForm,
    [FORM_SECTIONS.EVENT_SPACES]: EventSpacesForm,
    [FORM_SECTIONS.NOTES]: ProposalNotesForm,
    [FORM_SECTIONS.PROPOSAL_STATUS]: ProposalFormStatus,
};

type TFormManager = {
    proposalForm: Partial<TProposalForm>;
    onSave: (newData: Partial<TProposalFormUpdate>) => Promise<boolean>;
    onSubmit: () => Promise<boolean>;
    onReject: () => Promise<boolean>;
    onCancel: () => Promise<boolean>;
    onUpdateVenueSpaces: (space: TSpacePOST) => Promise<TSpace[] | false>;
};

const SCROLLBAR_WIDTH = 20; // at most
const WIDE_PAGE_WIDTH = 1366 - SCROLLBAR_WIDTH;

const Shell = styled(Row)`
    position: relative;
    margin: 0;
    overflow: auto;
`;

const sideWidth = 280;
const topHeight = 120;
const padding = 36;
const contentPadding = 94;

const Side = styled(Column)`
    position: absolute;
    width: ${sideWidth}px;
    height: 100%;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    padding: ${padding}px;
    box-sizing: border-box;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.brand)};
    z-index: 100;
`;

const Top = styled(Row)`
    position: fixed;
    height: ${topHeight}px;
    width: 100vw;
    align-items: center;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    padding: 0 ${padding}px;
    box-sizing: border-box;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.brand)};
    z-index: 100;
`;

const Main = styled(Column)<{ fullscreen?: boolean }>`
    position: relative;
    width: 100%;
    min-width: 968px;
    max-width: 1280px;
    border-radius: 12px;
    margin-top: ${padding}px;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    ${({ fullscreen }) =>
        fullscreen
            ? `
    margin-top: ${topHeight + padding}px;
    `
            : `
    margin-left: ${sideWidth}px;
    `}
    box-sizing: border-box;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

const PaddedColumn = styled(Column)`
    padding: 0 ${contentPadding}px;
    min-height: 100vh;
`;

const BizlyLogo = styled(ThemedLogo)`
    max-width: 0;
`;

const MarginAuto = styled.div`
    margin: auto;
`;

const StickyWrapper = styled(Column)`
    position: sticky;
    top: ${padding}px;
    max-height: calc(100vh - ${padding * 2}px);
    overflow: auto;
`;

const WidthAutoRow = styled(Row)`
    width: auto;
`;

const ButtonsRow = styled(WidthAutoRow)<{ fullscreen?: boolean }>`
    position: fixed;
    left: ${({ fullscreen }) => (fullscreen ? 0 : sideWidth)}px;
    right: 0;
    bottom: 0;

    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    border-top: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};

    z-index: 20;
`;

const ButtonsRowContent = styled(SpacedRow)`
    box-sizing: border-box;
    padding: 24px ${contentPadding}px;
    max-width: 1280px;
    margin: auto;
`;

const FixedSpinnerOverlay = styled(SpinnerOverlay)`
    position: fixed;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 200;
`;

const FormColumn = styled(Column)`
    flex-grow: 1;
    max-width: 1080px;
`;

const NoWrapButton = styled(Button)`
    white-space: nowrap;
`;

const Buttons = ({
    disabled,

    onBack,
    onSave,
    onContinue,
    onRestart,
    onCancel,
    onSubmit,

    isSubmitStep,
    isStatusStep,
    statusAttributes,
}: {
    disabled: boolean;

    onBack?: () => void;
    onSave: () => void;
    onContinue: () => void;
    onRestart: () => void;
    onSubmit: () => void;
    onCancel: () => void;

    isSubmitStep: boolean;
    isStatusStep: boolean;
    statusAttributes: TStatusAttributes;
}) => {
    const { canView, canCreate, canSubmit, isResubmit, canWithdraw } = statusAttributes;

    const renderInProgress = () => (
        <>
            {(canSubmit || canCreate) && (
                <NoWrapButton onClick={onSave} secondary disabled={disabled}>
                    Save
                </NoWrapButton>
            )}
            {isSubmitStep && (canSubmit || canCreate) ? (
                <NoWrapButton onClick={onSubmit} disabled={disabled}>
                    {isResubmit ? 'Resubmit' : 'Submit'}
                </NoWrapButton>
            ) : (
                <NoWrapButton onClick={onContinue} disabled={disabled}>
                    Continue
                </NoWrapButton>
            )}
        </>
    );

    const renderStatus = () => (
        <>
            {canWithdraw && (
                <NoWrapButton onClick={onCancel} disabled={disabled} secondary width={150} warning>
                    Withdraw Proposal
                </NoWrapButton>
            )}

            {(canView || canCreate || canSubmit) && (
                <NoWrapButton onClick={onRestart} disabled={disabled} width={140}>
                    {canSubmit || canCreate ? `${canCreate ? 'Create' : 'Edit'} Proposal` : 'View Proposal'}
                </NoWrapButton>
            )}
        </>
    );

    const isWidthConstrained = useMediaQuery(`(max-width: ${WIDE_PAGE_WIDTH}px)`);

    return (
        <ButtonsRow fullscreen={isWidthConstrained}>
            <ButtonsRowContent>
                <span>
                    {onBack && !isStatusStep && (
                        <Button onClick={onBack} secondary disabled={disabled}>
                            Back
                        </Button>
                    )}
                </span>

                <WidthAutoRow itemSpacing="default">{isStatusStep ? renderStatus() : renderInProgress()}</WidthAutoRow>
            </ButtonsRowContent>
        </ButtonsRow>
    );
};

export default function FormManager({
    proposalForm = {},
    onSave: onSaveProp,
    onSubmit: onSubmitProp,
    onUpdateVenueSpaces,
    onReject,
    onCancel,
}: TFormManager) {
    const [loading, setLoading] = React.useState(false);
    const [hasSaved, setHasSaved] = React.useState(true);

    const [userChangedStatus, setUserChangedStatus] = React.useState(false);

    const action = takeFirstIfArray(parse(useLocation().search)).action || '';
    const userAction = action === 'reject' ? 'reject' : 'accept';

    const status = getProposalFormStatus(proposalForm);
    const statusAttributes = getStatusAttributes(status, userAction, userChangedStatus);

    const {
        sectionIndex,
        goBack: onBack,
        nextSection: onContinueProp,
        restart: onRestartProp,
        goToEnd,
    } = useFormSectionsHistory(SECTIONS, statusAttributes.skipToStatus ? 4 : 0);
    const section = SECTIONS[sectionIndex];

    const FormSection = SECTION_TO_COMPONENT[section];
    const onSectionChange = React.useCallback(() => setHasSaved(false), [setHasSaved]);

    const [headerProposalData, setHeaderProposalData] = React.useState<Partial<TProposal>>();
    const onHeaderChange = React.useCallback(
        (newProposalData: Partial<TProposal>) => {
            setHasSaved(false);
            setHeaderProposalData(newProposalData);
        },
        [setHeaderProposalData]
    );

    React.useEffect(() => {
        setHasSaved(true);
        setHeaderProposalData(undefined);
    }, [FormSection, setHeaderProposalData]);

    const renderPrompt = useUnsavedPrompt(!hasSaved, newLocation => newLocation.state?.sectionIndex === sectionIndex);

    const { validationFn, registerValidator } = useFormValidator(section);

    const onSave = React.useCallback(
        async (isContinue?: boolean) => {
            const skipSaving = statusAttributes.readonly;
            if (skipSaving) return true;

            let newData = validationFn(isContinue);
            if (!newData) return false;
            if (headerProposalData) newData = { ...newData, proposal: { ...newData.proposal, ...headerProposalData } };

            if (!hasSaved || !isContinue) {
                setLoading(true);
                const success = await onSaveProp(newData);
                if (success) {
                    setHasSaved(true);
                }
                if (!isContinue) setLoading(false);
                return success;
            }

            return true;
        },
        [validationFn, hasSaved, onSaveProp, headerProposalData, statusAttributes.readonly]
    );

    const buttonOnSave = React.useCallback(() => onSave(), [onSave]);

    const onContinue = React.useCallback(async () => {
        const saved = await onSave(true);
        setLoading(false);

        if (saved) onContinueProp();
    }, [onSave, onContinueProp]);

    const onSubmit = React.useCallback(async () => {
        const saved = await onSave(true);
        if (saved) {
            setLoading(true);
            const submitted = await onSubmitProp();
            setLoading(false);

            if (submitted) onContinueProp();
        }
    }, [onSave, onSubmitProp, onContinueProp]);

    const onSuccess = React.useCallback(() => {
        goToEnd();
        setUserChangedStatus(true);
    }, [goToEnd, setUserChangedStatus]);

    const rejectModalProps = React.useMemo(
        () => ({
            showOnLoad: userAction === 'reject' && status === 'Inquiry Submitted',
            prompt: 'Are you sure you want to reject the inquiry?',
            onConfirm: onReject,
            onDismiss: onRestartProp,
            onSuccess,
        }),
        [userAction, status, onReject, onRestartProp, onSuccess]
    );

    const { renderModal: renderRejectModal, loading: rejectLoading } = useConfirmModal(rejectModalProps);

    const cancelModalProps = React.useMemo(
        () => ({
            showOnLoad: userAction === 'reject' && status === 'Proposal Submitted',
            prompt: 'Are you sure you want to cancel your proposal?',
            onConfirm: onCancel,
            onDismiss: goToEnd,
            onSuccess,
        }),
        [userAction, status, onCancel, goToEnd, onSuccess]
    );
    const { renderModal: renderCancelModal, loading: cancelLoading, openModal: showCancelModal } = useConfirmModal(
        cancelModalProps
    );

    const loadingAsync = loading || rejectLoading || cancelLoading;

    const isWidthConstrained = useMediaQuery(`(max-width: ${WIDE_PAGE_WIDTH}px)`);

    return (
        <Shell>
            {isWidthConstrained ? (
                <Top>
                    <BizlyLogo />
                    <MarginAuto>
                        {!(statusAttributes.noProposal && section === FORM_SECTIONS.PROPOSAL_STATUS) && (
                            <ProgressTracker sections={PROGRESS_SECTIONS} currentSection={sectionIndex} horizontal />
                        )}
                    </MarginAuto>
                </Top>
            ) : (
                <Side>
                    <IntroHeader {...proposalForm} />
                    <StickyWrapper>
                        {!(statusAttributes.noProposal && section === FORM_SECTIONS.PROPOSAL_STATUS) && (
                            <ProgressTracker sections={PROGRESS_SECTIONS} currentSection={sectionIndex} />
                        )}
                    </StickyWrapper>
                </Side>
            )}
            {renderPrompt()}
            <Main fullscreen={isWidthConstrained}>
                <PaddedColumn>
                    <ProposalDetailsHeader
                        {...proposalForm}
                        data={headerProposalData}
                        onChange={onHeaderChange}
                        disabled={statusAttributes.readonly || section === FORM_SECTIONS.PROPOSAL_STATUS}
                    />

                    <FormColumn>
                        {FormSection && (
                            <FormSection
                                {...proposalForm}
                                onUpdateVenueSpaces={onUpdateVenueSpaces}
                                onChange={onSectionChange}
                                registerValidator={registerValidator}
                                action={userAction}
                                userChangedStatus={userChangedStatus}
                                disabled={statusAttributes.readonly}
                            />
                        )}
                    </FormColumn>
                </PaddedColumn>
                {loadingAsync && <FixedSpinnerOverlay />}

                <Spacer largest />
                <Spacer largest />

                <Buttons
                    onBack={onBack}
                    onSave={buttonOnSave}
                    onContinue={onContinue}
                    onSubmit={onSubmit}
                    onCancel={showCancelModal}
                    onRestart={onRestartProp}
                    disabled={loadingAsync}
                    isSubmitStep={section === FORM_SECTIONS.NOTES}
                    isStatusStep={section === FORM_SECTIONS.PROPOSAL_STATUS}
                    statusAttributes={statusAttributes}
                />

                {renderRejectModal()}
                {renderCancelModal()}
            </Main>
        </Shell>
    );
}
