import React from 'react';

import { css } from 'styled-components/macro';

import Popover from '@material-ui/core/Popover';

const HoverableIcon = ({ children, hoverText }: { children: JSX.Element; hoverText: string }) => {
    const [hoverAnchor, setHoverAnchor] = React.useState<Nullable<Element>>(null);

    return (
        <>
            <Popover
                css={css`
                    pointer-events: none;
                `}
                open={!!hoverAnchor}
                anchorEl={hoverAnchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div
                    css={css`
                        margin: 10px;
                    `}
                >
                    {hoverText}
                </div>
            </Popover>
            {React.cloneElement(children, {
                onMouseEnter: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) =>
                    setHoverAnchor(event.currentTarget),
                onMouseLeave: () => setHoverAnchor(null),
            })}
        </>
    );
};

export default HoverableIcon;
