import zipObject from 'lodash/zipObject';
import range from 'lodash/range';

type TGrade = BizlyAPI.Venue.Grades;
export const SLIDER_VAL_TO_GRADE: TGrade[] = ['casual', 'upscale', 'fancy'];
export const ALL_GRADES = SLIDER_VAL_TO_GRADE;

// The slider uses numerical values because UI-wise it's a range
// Do things like convert it from [0, 2] to [casual, upscale, fancy]
export function sliderValToGrades(sliderRange: [number, number]) {
    const [start, end] = sliderRange;
    const isSingle = start === end;
    const needsExpansion = end > start;
    const expanded = needsExpansion ? range(start, end + 1) : sliderRange;

    return isSingle ? [SLIDER_VAL_TO_GRADE[start]] : expanded.map(v => SLIDER_VAL_TO_GRADE[v]);
}

// Do the reverse, convert from [casual, upscale, fancy] to [0, 2]
const GRADE_TO_SLIDER_VAL = zipObject(
    SLIDER_VAL_TO_GRADE,
    SLIDER_VAL_TO_GRADE.map((_, i) => i)
) as {
    [grade in TGrade]: number;
};

export function gradesToSliderVal(grades: TGrade[]): [number, number] | undefined {
    const asNumbers = grades.map(g => GRADE_TO_SLIDER_VAL[g]);
    const asRange: [number, number] = [Math.min(...asNumbers), Math.max(...asNumbers)];

    // NaN is a possible unwanted number:
    return Number.isInteger(asRange[0]) ? asRange : undefined;
}
