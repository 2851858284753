import React from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { emailIsValid } from 'utils';
import useShowModal from 'hooks/useShowModal';

import { searchCollaboratorsAndGroups, isGroup } from 'api';
import { attendeesActions, LoadAttendees, useAttendees } from 'stores/attendees';
import { hydrateGroup } from 'stores/contact-groups';

import { Column, Copy, Row } from 'ui';
import SmallCircleButton from 'components/ui/SmallCircleButton';
import AutoComplete from 'components/ui/AutoComplete';
import { Link, LinkProps } from 'react-router-dom';
import { Dialog, Tooltip } from '@material-ui/core';
import AttendeesLists from './AttendeesLists';

import { ReactComponent as GuestsSvg } from 'images/icons/guests.svg';
import { withInteractibleIconStyles } from 'shared';
import colorFns from 'colorFns';

const GuestsIcon = styled(withInteractibleIconStyles(GuestsSvg))`
    height: 15px;
    width: 27px;

    > path {
        fill: ${colorFns.pureWhite.alpha(0.74)};
    }
`;

const CircleButton = styled(SmallCircleButton)`
    position: absolute;
    bottom: -14px;
    right: -14px;
`;

type TOption = PartialExcept<BizlyAPI.EventCollaboratorSuggestion, 'email'> | BizlyAPI.GroupSuggestion;

const isGroupOption = (option: TOption): option is BizlyAPI.GroupSuggestion =>
    isGroup(option as BizlyAPI.GroupSuggestion);

const AutoRow = styled(Row)`
    width: auto;
`;

const AttendeesDialog = styled(Dialog)`
    .MuiPaper-root {
        max-height: 50vh;
    }
`;

const CardTooltip = styled(props => <Tooltip classes={{ popper: props.className }} {...props} />)`
    & .MuiTooltip-tooltip {
        background-color: ${colorFns.pureWhite};
        color: ${colorFns.pureBlack};
        font-size: unset;
        padding: 0;
        max-height: 50vh;
    }
`;

const AttendeeCounter = ({
    attendees,
    editable,
    showModal,
    attendingCount,
    loaded,
}: {
    attendees: BizlyAPI.Attendee[];
    editable?: boolean;
    showModal: () => void;
    attendingCount?: number;
    loaded?: boolean;
}) => (
    <CardTooltip
        title={loaded && attendees.length > 0 ? <AttendeesLists attendees={attendees} /> : null}
        placement="bottom-end"
    >
        <div style={{ position: 'relative' }} onClick={editable ? () => {} : showModal}>
            <GuestsIcon />
            <CircleButton secondary={false} disabled={!loaded}>
                {loaded ? attendingCount : <>&nbsp;</>}
            </CircleButton>
        </div>
    </CardTooltip>
);

export default function QuickInviteAttendees({
    meetingId,
    editable,
    editAttendeesLocation,
}: {
    meetingId: string | number;
    editable?: boolean;
    editAttendeesLocation?: LinkProps['to'];
}) {
    const attendeesStore = useAttendees();
    const attending = attendeesStore.attendees?.filter(attendee => attendee.status === 'attending') || [];
    const attendingCount = attending.length;
    const attendees = attendeesStore.attendees ?? [];

    const { enqueueSnackbar } = useSnackbar();
    const addAttendee = async (newAttendee: BizlyAPI.BasicAttendee) => {
        try {
            await attendeesActions.invite(meetingId, newAttendee.email);
            enqueueSnackbar(`${newAttendee.email} invited.`, { variant: 'info' });
        } catch {
            enqueueSnackbar(`Could not invite ${newAttendee.email}. Please try again.`, { variant: 'error' });
        }
    };

    const addGroup = async (group: BizlyAPI.ContactGroup) => {
        const { contacts } = hydrateGroup(group);
        if (contacts) {
            try {
                const failedContacts: Set<BizlyAPI.Contact> = new Set();
                const promises = contacts.map(async contact => {
                    try {
                        await attendeesActions.invite(meetingId, contact.email);
                    } catch {
                        failedContacts.add(contact);
                    }
                });

                await Promise.all(promises);

                if (failedContacts.size > 0) {
                    enqueueSnackbar(
                        <ul>
                            Some contacts could not be invited:
                            {[...failedContacts].map(contact => (
                                <li key={contact.id}>{contact.email}</li>
                            ))}
                        </ul>,
                        { variant: 'error' }
                    );
                } else {
                    enqueueSnackbar(`${group.name} invited.`, { variant: 'info' });
                }
            } catch {
                enqueueSnackbar(`Could not invite the group ${group.name}. Please try again.`, { variant: 'error' });
            }
        }
    };

    const curAttendeesEmailsSet = new Set([
        ...(attendeesStore.attendees?.map(attendee => attendee.email.toLowerCase()) ?? []),
        ...attendeesStore.pendingAttendees.map(attendee => attendee.email.toLowerCase()),
    ]);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [options, setOptions] = React.useState<TOption[]>([]);
    const [value, setValue] = React.useState<TOption>({ email: '' });

    async function handleSuggestions(query: string) {
        if (!query) {
            setLoading(false);
        }
        setValue({ email: query });
        if (query?.length < 3) {
            setOptions([]);
        }
        setLoading(true);
        const response = await searchCollaboratorsAndGroups(query);
        setLoading(false);

        const suggestions = response.suggestions.filter(suggestion =>
            isGroup(suggestion) ? true : !curAttendeesEmailsSet.has(suggestion.email.toLowerCase())
        );

        const queryIsValidEmail = emailIsValid(query);
        const emailIsNew =
            queryIsValidEmail &&
            !suggestions.find(
                suggestion => !isGroup(suggestion) && suggestion.email.toLowerCase() === query.toLowerCase()
            );

        setOptions([...(emailIsNew ? [{ email: query }] : []), ...suggestions]);
    }

    const clear = () => {
        setValue({ email: '' });
        setLoading(false);
    };

    const { modalShown, hideModal, showModal } = useShowModal();

    return (
        <AutoRow alignItems="center" itemSpacing="smallish">
            <LoadAttendees meetingId={meetingId} />
            {editable && editAttendeesLocation ? (
                <Link to={editAttendeesLocation}>
                    <AttendeeCounter
                        attendees={attendees}
                        editable={editable}
                        loaded={attendeesStore.loaded}
                        showModal={showModal}
                        attendingCount={attendingCount}
                    />
                </Link>
            ) : (
                <AttendeeCounter
                    attendees={attendees}
                    editable={editable}
                    loaded={attendeesStore.loaded}
                    showModal={showModal}
                    attendingCount={attendingCount}
                />
            )}
            <AttendeesDialog open={modalShown} onBackdropClick={hideModal}>
                <AttendeesLists attendees={attendees} full />
            </AttendeesDialog>
            {editable && (
                <AutoComplete
                    style={{ width: 240 }}
                    disabled={!attendeesStore.loaded}
                    blurOnSelect
                    options={options}
                    loading={loading}
                    getOptionLabel={option => (isGroupOption(option) ? option.name : option?.email?.toString() || '')}
                    getOptionSelected={option =>
                        !isGroupOption(option) && attendees.some(p => p.email === option.email)
                    }
                    noOptionsText={
                        !isGroupOption(value) && (value?.email?.length ?? 0) < 3
                            ? 'A minimum of 3 characters is required to search'
                            : 'No results'
                    }
                    value={value}
                    onChange={(_, newValue) => {
                        if (isGroupOption(newValue)) {
                            addGroup(newValue);
                        } else {
                            addAttendee(newValue);
                        }
                        clear();
                    }}
                    asField
                    inModalOrPopover
                    InputProps={{
                        autoFocus: true,
                        placeholder: 'Invite by name or email',
                    }}
                    onKeyUp={e => {
                        if (e.key === 'Enter') {
                            if (isGroupOption(value)) {
                                addGroup(value);
                            } else if (emailIsValid(value.email)) {
                                addAttendee(value);
                            }
                            clear();
                        }
                    }}
                    filterOptions={o => o}
                    onInputChange={handleSuggestions}
                    renderOption={option => (
                        <Column itemSpacing="xsmall">
                            <b>
                                <Copy>
                                    {isGroupOption(option)
                                        ? option.name
                                        : [option.firstName, option.lastName].join(' ')}
                                </Copy>
                            </b>
                            {!isGroupOption(option) && <Copy>{option.email}</Copy>}
                        </Column>
                    )}
                />
            )}
        </AutoRow>
    );
}
