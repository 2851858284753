import createStore from 'zustand';

import { getMeeting, getMeetings, updateMeeting, deleteMeeting, rateMeeting, requestSubsumption } from 'api/meetings';
import { createEvent } from 'api/events';
import { subsume } from 'api/calendar';
import { calendarActions } from 'stores/calendar';

import moment from 'moment';
import keyBy from 'lodash/keyBy';
import isDate from 'lodash/isDate';
import omit from 'lodash/omit';

export type Meeting = BizlyAPI.Meeting & { fromExternal?: boolean; calId?: string };

type State = {
    loadingByWeek: {
        [startOfWeek: string]: boolean;
    };
    idsByWeek: {
        [startOfWeek: string]: (number | string)[];
    };

    loading: { [id: string]: boolean; [id: number]: boolean };
    updating: { [id: string]: boolean; [id: number]: boolean };
    deleting: { [id: string]: boolean; [id: number]: boolean };
    subsuming?: boolean;

    meetings: { [id: string]: Meeting; [id: number]: Meeting };
};
type Store = State;

const initialState: State = {
    loadingByWeek: {},
    idsByWeek: {},

    loading: {},
    updating: {},
    deleting: {},
    subsuming: false,

    meetings: {},
};

export const [useMeetings, meetingsStoreApi] = createStore<Store>(() => initialState);

const { setState, getState } = meetingsStoreApi;

const strDate = (limitOrDate?: Number | Date) =>
    typeof limitOrDate === 'number'
        ? limitOrDate.toString()
        : isDate(limitOrDate)
        ? moment(limitOrDate).format('YYYY-MM-DD')
        : '';

const mergeByDate = <CurVal, NewVal extends CurVal, CurStateKey extends string>(
    current: { [key in CurStateKey]: CurVal },
    input: NewVal,
    limitOrDate?: number | Date
) => ({
    ...current,
    [strDate(limitOrDate)]: input,
});

export const eventIsExt = (meeting: Meeting) => meeting.origin !== 'bizly' && meeting.origin !== null;

export const meetingsActions = {
    load: async (startOfWeek?: Date, limit?: number) => {
        setState({ loadingByWeek: mergeByDate(getState().loadingByWeek, true, startOfWeek ?? limit) });

        try {
            const { meetings } = await getMeetings(startOfWeek, limit);
            const meetingsDict = keyBy(meetings, m => m.id);

            setState({
                loadingByWeek: mergeByDate(getState().loadingByWeek, false, startOfWeek ?? limit),
                idsByWeek: mergeByDate(
                    getState().idsByWeek,
                    meetings.map(m => m.id),
                    startOfWeek ?? limit
                ),
                meetings: { ...getState().meetings, ...meetingsDict },
            });

            return meetings;
        } catch (e) {
            setState({
                loadingByWeek: mergeByDate(getState().loadingByWeek, false, startOfWeek),
            });
            throw e;
        }
    },
    loadSingle: async (id: string | number) => {
        try {
            setState({
                loading: { ...getState().loading, [id]: true },
            });
            const { meeting } = await getMeeting(id);

            setState({
                meetings: {
                    ...getState().meetings,
                    [id]: {
                        ...meeting,
                        ...(eventIsExt(meeting)
                            ? {
                                  published: true,
                                  editable: false,
                                  fromExternal: true,
                                  calId: '' + id,
                              }
                            : {}),
                    },
                },
                loading: { ...getState().loading, [id]: false },
            });

            return meeting;
        } catch (e) {
            setState({
                loading: { ...getState().loading, [id]: false },
            });
            throw e;
        }
    },
    create: async (templateId?: number) => {
        return createEvent(templateId ? { templateId } : {});
    },
    merge: async (meeting: BizlyAPI.Meeting) => {
        setState({
            meetings: { ...getState().meetings, [meeting.id]: meeting },
        });
    },
    update: async (meeting: BizlyAPI.MeetingUpdate) => {
        if (isUpdating(meeting.id)(getState())) {
            return;
        }

        try {
            setState({
                updating: { ...getState().updating, [meeting.id]: true },
            });
            const { meeting: updatedMeeting } = await updateMeeting(meeting);
            setState({
                updating: { ...getState().updating, [meeting.id]: false },
                meetings: { ...getState().meetings, [meeting.id]: updatedMeeting },
            });

            return updatedMeeting;
        } catch (e) {
            setState({
                updating: { ...getState().updating, [meeting.id]: false },
            });
            throw e;
        }
    },
    chatViewed: async (meetingId: number | string) => {
        const meeting = getState().meetings[meetingId];

        if (meeting) {
            setState({
                meetings: {
                    ...getState().meetings,
                    [meetingId]: {
                        ...meeting,
                        ...(meeting.currentUserAttendee
                            ? {
                                  currentUserAttendee: {
                                      ...meeting.currentUserAttendee,
                                      userNeedsToViewChat: false,
                                  },
                              }
                            : {}),
                    },
                },
            });
        }
    },
    delete: async (meeting: BizlyAPI.Meeting) => {
        if (isDeleting(meeting.id)(getState())) {
            return;
        }

        try {
            setState({
                deleting: { ...getState().deleting, [meeting.id]: true },
            });
            await deleteMeeting(meeting.id);
            setState({
                deleting: { ...getState().deleting, [meeting.id]: false },
                meetings: omit({ ...getState().meetings }, meeting.id),
            });
        } catch (e) {
            setState({ deleting: { ...getState().deleting, [meeting.id]: false } });
            throw e;
        }
    },
    rate: async (meetingId: string | number, score: number) => {
        if (isUpdating(meetingId)(getState())) {
            return;
        }

        try {
            setState({
                updating: { ...getState().updating, [meetingId]: true },
            });
            const { meeting: updatedMeeting } = await rateMeeting({ id: meetingId, score });
            setState({
                updating: { ...getState().updating, [meetingId]: false },
                meetings: {
                    ...getState().meetings,
                    [meetingId]: { ...getState().meetings[meetingId], ...updatedMeeting },
                },
            });

            return updatedMeeting;
        } catch (e) {
            setState({
                updating: { ...getState().updating, [meetingId]: false },
            });
            throw e;
        }
    },
    subsume: async (calId: string | number, calUId: string | number, startOfWeek: Date) => {
        if (getState().subsuming) return;

        try {
            setState({ subsuming: true });
            const { meeting } = await subsume(calId);

            meeting.published = true;
            setState({
                idsByWeek: mergeByDate(
                    getState().idsByWeek,
                    [...getState().idsByWeek[strDate(startOfWeek)], meeting.id],
                    startOfWeek
                ),
                meetings: {
                    ...getState().meetings,
                    [meeting.id]: meeting,
                },
                subsuming: false,
            });

            calendarActions.remove(calUId, startOfWeek);

            return meeting;
        } catch (e) {
            setState({ subsuming: false });
            throw e;
        }
    },

    requestSubsume: async (meetingId: number | string) => {
        if (getState().subsuming) return;

        try {
            setState({ subsuming: true });
            const { meeting } = await requestSubsumption(meetingId);

            meeting.published = true;
            setState({
                meetings: {
                    ...getState().meetings,
                    [meeting.id]: meeting,
                },
                subsuming: false,
            });

            return meeting;
        } catch (e) {
            setState({ subsuming: false });
            throw e;
        }
    },
};

export const selectMeeting = (id?: string | number) => (state: State) => (id ? state.meetings[id] : undefined);

export const selectMeetings = (startOfWeekOrLimit?: Number | Date) => (state: State) =>
    state.idsByWeek[strDate(startOfWeekOrLimit)]?.map(id => state.meetings[id]).filter(m => m) ?? [];

export function isUpdating(id?: string | number) {
    return (state: State) => (id ? state.updating[id] : false);
}
export function isDeleting(id: string | number) {
    return (state: State) => state.deleting[id];
}

export function isLoading(startOfWeek?: Date): (state: State) => boolean;
export function isLoading(id: string | number): (state: State) => boolean;
export function isLoading(idOrStartOfWeek?: Date | string | number) {
    if (idOrStartOfWeek === undefined || isDate(idOrStartOfWeek)) {
        return (state: State) => state.loadingByWeek[strDate(idOrStartOfWeek)];
    }

    return (state: State) => state.loading[idOrStartOfWeek];
}
export function isLoadingLimit(limit: number) {
    return (state: State) => state.loadingByWeek[strDate(limit)];
}
