import { tz as momentTz } from 'moment-timezone';
import carbonSupportedTimeZones from 'components/Form/TimeZonePicker/carbonSupportedTimeZones';

const guessedTimeZone = momentTz.guess();
export const userTimeZone = carbonSupportedTimeZones.includes(guessedTimeZone) ? guessedTimeZone : 'UTC';

export const tzMoment = (date?: string | Date | null, timeZone = 'UTC') =>
    date && timeZone ? momentTz(date, timeZone) : momentTz(undefined, timeZone);

export const userFormattedTimestamp = (isoTimestamp: string) => {
    const inUserTz = tzMoment(isoTimestamp).tz(userTimeZone);
    const now = tzMoment('', userTimeZone);

    return inUserTz.format(
        inUserTz.isSame(now, 'date') ? 'h:mm a' : inUserTz.isSame(now, 'year') ? 'MMM DD h:mm a' : 'MMM DD, YYYY h:mm a'
    );
};
