import React from 'react';

import { useSnackbar } from 'notistack';

import { chimeVideoActions } from './store';

export default function LoadChimeVideo({ id }: { id?: string | number }) {
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        const load = async () => {
            if (id) {
                try {
                    await chimeVideoActions.load(id);
                } catch (e) {
                    enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
                }
            }
        };
        load();

        return () => {
            chimeVideoActions.clear();
        };
    }, [id, enqueueSnackbar]);

    return null;
}
