import React from 'react';
import styled from 'styled-components';
import { ItemWithUId } from 'stores/calendar';

import moment from 'moment';
import { tzMoment, userTimeZone } from 'utils/moment';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import chunk from 'lodash/chunk';
import useThemedColor from 'hooks/useThemedColor';

import Button from 'components/ui/Button';
import { Row, Copy, Column, TruncatingSingleLineCopy } from 'ui';
import { SpinnerOverlay } from 'components/Spinner';
import CircleImage from 'components/CircleImage';
import { Tooltip } from '@material-ui/core';

import { ReactComponent as ClockIconSVG } from 'images/icons/clock.svg';
import { ReactComponent as PlusSVG } from 'images/icons/plus.svg';
import { ReactComponent as ShapesSVG } from 'images/icons/shapes.svg';
import colorFns from 'colorFns';

import ColorHash from 'color-hash';
const colorHash = new ColorHash({ lightness: 0.5 });

const DotCol = styled(Column)`
    height: 100%;
`;

const Dot = styled.div<{ green?: boolean }>`
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: ${({ green, ...props }) =>
        green ? colorFns.acceptedMeeting(props) : colorFns.secondaryTextAction(props)};
`;

const NoMeetings = styled(Column)`
    width: 100%;
`;

const ClockIcon = styled(ClockIconSVG)`
    width: 22px;
    height: 22px;
`;

const HoverRow = styled(Row)<{ highlight?: boolean }>`
    padding: 12px 0;
    border-radius: 8px;
    cursor: pointer;
    background-color: ${({ highlight }) => (highlight ? colorFns.listItemBackground : 'transparent')};

    &:hover {
        background-color: ${colorFns.listItemBackground};
    }
`;

const AutoRow = styled(Row)`
    width: auto;
`;
const FullWidthCol = styled(Column)`
    position: relative;
    width: 100%;
`;

const ClockCol = styled(Column)`
    flex: 0 0 20px;
    min-width: 20px;
`;
const TimeCol = styled(Column)<{ wide?: boolean }>`
    flex: 0 0 ${({ wide }) => (wide ? 60 : 40)}px;
    min-width: ${({ wide }) => (wide ? 60 : 40)}px;
`;
const NameCol = styled(Column)`
    flex: 1 0 120px;
    min-width: 120px;

    position: relative;
    > ${DotCol} {
        position: absolute;
        top 0;
        left: -20px;
    }
`;

const User = styled(CircleImage)`
    border: 2px solid ${colorFns.brand};
    box-sizing: border-box;

    line-height: 40px;
`;
const UserOverflow = styled(User)`
    color: ${colorFns.pureBlack};
`;

const UsersCol = styled(Row)`
    flex: 0 0 80px;
    min-width: 80px;
    margin-left: -30px;

    > ${User} {
        &:first-child {
            margin-left: -30px;
        }
        margin-right: -30px;

        &:hover {
            z-index: 1;
        }
    }
`;

const UserList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
`;

const PlaceCol = styled(Column)`
    flex: 0 0 200px;
    min-width: 200px;
`;

const ShapesIcon = styled(ShapesSVG)``;
const PlusIcon = styled(PlusSVG)``;
const QuickCreateButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: auto;
    background: none;

    padding: 8px;

    border-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.6)};
    border-radius: 12px;

    ${PlusIcon} {
        display: inline-flex;
        white-space: nowrap;
        padding: 0;
        width: 15px;
        height: 15px;

        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.6)};

        border-radius: 8px;
        min-width: 0;
        align-items: center;
        justify-content: center;
    }
`;

const CreateButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    padding: 20px 40px;
    border-radius: 8px;
    margin: 0 auto;
    margin-bottom: 40px;
    ${PlusIcon} {
        display: inline-flex;
        white-space: nowrap;
        padding: 0;
        width: 16px;
        height: 16px;

        background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};

        border-radius: 50%;
        min-width: 0;
        align-items: center;
        justify-content: center;

        margin-right: 10px;
    }
`;

type BasicBlock = { startsAt?: string | null; endsAt?: string | null; timeZone?: string };

const formatName = (user?: { firstName?: string | null; lastName?: string | null; email?: string }) =>
    user ? [user.firstName ?? '', user.lastName ?? ''].join(' ').trim() || user.email || '' : '';

const groupMeetingsByDay = <TMeeting extends BasicBlock>(meetings: TMeeting[]) => {
    const withUnixTime = meetings.map(meeting => ({
        ...meeting,
        unixTime: tzMoment(meeting.startsAt, meeting.timeZone).valueOf(),
    }));

    return groupBy(withUnixTime, meeting =>
        tzMoment(meeting.startsAt, meeting.timeZone)
            .tz(userTimeZone)
            .startOf('day')
            .valueOf()
    );
};

const mergeGroupsToArray = <TMeeting1 extends BasicBlock, TMeeting2 extends BasicBlock>(
    group1: Partial<Record<number, TMeeting1[]>>,
    group2: Partial<Record<number, TMeeting2[]>>
) => {
    const allKeys = [...new Set([...Object.keys(group1), ...Object.keys(group2)])];

    return sortBy(
        allKeys.map(unixTime => [
            moment(parseInt(unixTime)),
            group1[parseInt(unixTime)] ?? [],
            group2[parseInt(unixTime)] ?? [],
        ]) as [moment.Moment, TMeeting1[], TMeeting2[]][],
        group => group[0].valueOf()
    );
};

const TimesCol = ({ event, stackedTimes }: { event: BasicBlock; stackedTimes?: boolean }) => {
    const daysBetween = moment(event.endsAt).diff(moment(event.startsAt), 'days');

    return (
        <AutoRow itemSpacing="xsmall">
            <TimeCol wide={stackedTimes}>
                <Copy small>
                    {tzMoment(event.startsAt, event.timeZone)
                        .tz(userTimeZone)
                        .format('h:mm')}
                </Copy>
                {stackedTimes && (
                    <Copy small>
                        {tzMoment(event.endsAt, event.timeZone)
                            .tz(userTimeZone)
                            .format('h:mm a')}{' '}
                        {daysBetween > 1 && <span>+{daysBetween}</span>}
                    </Copy>
                )}
            </TimeCol>
            {!stackedTimes && (
                <TimeCol wide>
                    <Copy small>
                        {tzMoment(event.endsAt, event.timeZone)
                            .tz(userTimeZone)
                            .format('h:mm a')}{' '}
                        {daysBetween > 1 && <span>+{daysBetween}</span>}
                    </Copy>
                </TimeCol>
            )}
        </AutoRow>
    );
};

const DateRow = ({
    date,
    narrow,
    onQuickAdd,
}: {
    date: moment.Moment;
    narrow?: boolean;
    onQuickAdd?: (date: moment.Moment) => void;
}) => (
    <Row justifyContent="space-between" alignItems="center">
        <Row alignItems="center">
            <b>
                <Copy>{date.format('dddd')}</Copy>
            </b>
            {!narrow && <Copy>, {date.format('MMMM DD, YYYY')}</Copy>}
        </Row>
        {onQuickAdd && (
            <QuickCreateButton secondary onClick={() => onQuickAdd(date)}>
                <PlusIcon />
            </QuickCreateButton>
        )}
    </Row>
);

const tagMeetings = (meetings: BizlyAPI.Meeting[]): (BizlyAPI.Meeting & { source: 'internal' })[] =>
    meetings.map(meeting => ({
        ...meeting,
        source: 'internal',
    }));

const tagCalItems = (calItems: ItemWithUId[]): (ItemWithUId & { source: 'external' })[] =>
    calItems.map(item => ({ ...item, source: 'external' }));

const MeetingsList = ({
    meetings,
    calItems = [],
    meetingIdToUsers,
    narrow,
    onClick,
}: {
    meetings: BizlyAPI.Meeting[];
    calItems?: ItemWithUId[];
    meetingIdToUsers: Partial<Record<string, BizlyAPI.AttendingUser[]>>;
    narrow?: boolean;
    onClick: (meeting: { id: string | number; createdBy: BizlyAPI.CreatedBy; uId?: string }) => void;
}) => {
    const taggedMeetings = tagMeetings(meetings);
    const taggedCalItems = tagCalItems(calItems);

    const sortedItems = [...taggedMeetings, ...taggedCalItems].sort((itemA, itemB) => {
        const itemAStart = tzMoment(itemA.startsAt, itemA.timeZone);
        const itemBStart = tzMoment(itemB.startsAt, itemB.timeZone);

        const itemAEnd = tzMoment(itemA.endsAt, itemA.timeZone);
        const itemBEnd = tzMoment(itemB.endsAt, itemB.timeZone);

        return itemAStart.isSame(itemBStart)
            ? itemAEnd.isBefore(itemBEnd)
                ? -1
                : 1
            : itemAStart.isBefore(itemBStart)
            ? -1
            : 1;
    });

    const { grey } = useThemedColor();

    return (
        <FullWidthCol itemSpacing="xsmall">
            {sortedItems.map(event => {
                const key = event.source === 'internal' ? event.id : event.uId;
                const name = event.name;
                const createdBy = event.createdBy;
                const virtualLocation = event.source === 'internal' ? event.virtualLocation : '';
                const attendeesList = (meetingIdToUsers[key] ?? []).filter(u => u.email !== createdBy.email);

                const [users = [], ...restChunks] = chunk([createdBy, ...attendeesList], 4);
                const restUsers = restChunks.flat();

                return (
                    <HoverRow
                        key={key}
                        itemSpacing="default"
                        alignItems="center"
                        onClick={() => {
                            return onClick(event);
                        }}
                        highlight={!narrow}
                    >
                        <ClockCol>
                            <ClockIcon />
                        </ClockCol>
                        <TimesCol event={event} stackedTimes={narrow} />
                        <NameCol>
                            {(event.source === 'external' || event.currentUserAttendee?.userNeedsToViewChat) && (
                                <DotCol justifyContent="center">
                                    <Tooltip
                                        title={
                                            event.currentUserAttendee?.userNeedsToViewChat ? 'New chat messages' : ''
                                        }
                                    >
                                        <Dot green={event.currentUserAttendee?.userNeedsToViewChat} />
                                    </Tooltip>
                                </DotCol>
                            )}

                            <TruncatingSingleLineCopy>
                                {name ? <b>{name}</b> : <i>Untitled</i>}
                            </TruncatingSingleLineCopy>
                            {createdBy && <Copy small>{formatName(createdBy)}</Copy>}
                        </NameCol>

                        {!narrow && createdBy && (
                            <PlaceCol>
                                <Copy>{virtualLocation ?? 'Meeting room'}</Copy>
                            </PlaceCol>
                        )}
                        <UsersCol justifyContent="center">
                            {users.map(user => (
                                <Tooltip key={user.email} title={formatName(user)}>
                                    <User
                                        src={user.imageUrl}
                                        fallbackColor={colorHash.hex(user.email ?? formatName(user))}
                                        fallback={(formatName(user) || user.email || '')
                                            .split(' ')
                                            .map(w => w.charAt(0))
                                            .join(' ')}
                                    />
                                </Tooltip>
                            ))}
                            {restUsers.length > 0 && (
                                <Tooltip
                                    title={
                                        <UserList>
                                            {restUsers.map(user => (
                                                <li key={user.email}>{formatName(user)}</li>
                                            ))}
                                        </UserList>
                                    }
                                >
                                    <UserOverflow fallback={'+' + restUsers.length} fallbackColor={grey} />
                                </Tooltip>
                            )}
                        </UsersCol>
                    </HoverRow>
                );
            })}
        </FullWidthCol>
    );
};

export default function ScheduledList({
    meetings,
    calItems = [],
    meetingIdToUsers = {},
    onClick,
    onQuickAdd,
    handleCreateMeetingButtonClick,
    loading,
    noLoadingOverlay,
    narrow,
}: {
    meetings: BizlyAPI.Meeting[];
    calItems?: ItemWithUId[];
    meetingIdToUsers?: Partial<Record<string, BizlyAPI.AttendingUser[]>>;
    onClick: (meeting: { id: string | number; createdBy: BizlyAPI.CreatedBy; uId?: string }) => void;
    onQuickAdd?: (date: moment.Moment) => void;
    handleCreateMeetingButtonClick: () => void;
    loading?: boolean;
    noLoadingOverlay?: boolean;
    narrow?: boolean;
}) {
    const meetingsThisWeek = calItems.length + meetings.length;

    const perWeek = mergeGroupsToArray(groupMeetingsByDay(meetings), groupMeetingsByDay(calItems));

    return (
        <FullWidthCol itemSpacing="small">
            {!meetingsThisWeek && !loading && (
                <NoMeetings justifyContent="center" alignItems="center">
                    <h2>No meetings scheduled this week!</h2>
                    <CreateButton width="auto" onClick={handleCreateMeetingButtonClick}>
                        <Row justifyContent="center" alignItems="center">
                            Create a meeting
                        </Row>
                    </CreateButton>
                    <ShapesIcon />
                </NoMeetings>
            )}
            {perWeek.map(
                ([date, meetings, calItems]) =>
                    (meetings.length > 0 || calItems.length > 0) && (
                        <Row key={date.toISOString()}>
                            <FullWidthCol itemSpacing="small">
                                <DateRow date={date} narrow={narrow} onQuickAdd={onQuickAdd} />
                                <MeetingsList
                                    meetings={meetings}
                                    calItems={calItems}
                                    meetingIdToUsers={meetingIdToUsers}
                                    narrow={narrow}
                                    onClick={onClick}
                                />
                            </FullWidthCol>
                        </Row>
                    )
            )}
            {loading && !noLoadingOverlay && <SpinnerOverlay transparent clickThrough delay />}
        </FullWidthCol>
    );
}
