import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components/macro';
import { LocationDescriptor } from 'history';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    margin-bottom: 100px;
    width: 100%;
`;

const MainTitle = styled.h1`
    font-size: 72px;
    line-height: 0;
`;

const SectionHeader = styled.h2`
    font-size: 44px;
    line-height: 0;
`;

const Copy = styled.p`
    font-size: 25px;
`;

export const PageNotFound = ({ eventId, redirect }: { eventId?: number | string; redirect?: LocationDescriptor }) => {
    const [count, setCount] = useState(10);
    const destination = redirect ?? (eventId ? `/event/${eventId}` : '/');

    useEffect(() => {
        const countDown = setTimeout(() => count > 0 && setCount(count - 1), 1000);

        return () => clearTimeout(countDown);
    }, [count]);

    return count ? (
        <Container>
            <MainTitle>We‘re Sorry!</MainTitle>
            <SectionHeader>This page doesn't exist.</SectionHeader>
            <Copy>You will be redirected in {`${count} second${count > 1 ? 's' : ''}`}</Copy>
        </Container>
    ) : (
        <Redirect to={destination} />
    );
};
