import moment from 'moment-timezone';

import carbonSupportedTimeZones from './carbonSupportedTimeZones';

const parseOffsetNumber = (offset: string) =>
    Number(
        offset
            .replace(':00', '.00')
            .replace(':15', '.25')
            .replace(':30', '.50')
            .replace(':45', '.75')
    );

const shapeData = (array: string[]) =>
    array.map(zone => {
        const tzOffset = moment.tz(zone).format('Z');
        const tzAbbreviation = moment.tz(zone).format('z');
        const formattedZone = zone.replace('_', ' ');

        return {
            offSet: parseOffsetNumber(tzOffset),
            id: zone,
            value: zone,
            name: `(GMT${tzOffset}) ${formattedZone} ${tzAbbreviation ? `(${tzAbbreviation})` : ''}`,
            abbr: tzAbbreviation,
        };
    });

const timeZoneOptions = shapeData(carbonSupportedTimeZones.slice());

export default timeZoneOptions;
