import React, { Component } from 'react';
import styled from 'styled-components/macro';

import { CloudinaryUploader } from './CloudinaryUploader';
import { Column, Copy, CopyFaded, Row, TopRounded, Spacer, ExternalLink } from '../ui';
import SmallCircleButton from './ui/SmallCircleButton';
import { Spinner } from './Spinner';

import { ReactComponent as FileIcon } from '../images/icons/file.svg';
import { ReactComponent as DeleteIcon } from '../images/icons/trash-can.svg';
import { ReactComponent as PlusIcon } from '../images/icons/plus.svg';
import { withIconStyles, withInteractibleIconStyles } from 'shared';
import { buttonColorsStyledFn } from './ui/Button';
import colorFns from 'colorFns';

import { getExtension, getFileName } from 'cloudinary';

const RoundedRow = styled(TopRounded).attrs(props => ({
    ...(props.nested ? { background: colorFns.listItemNestedBackground(props) } : {}),
}))`
    padding: 12px;
    border-radius: 8px;
    background: none;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

const ConstrainedImage = styled.img`
    max-width: ${props => props.width};
    max-height: ${props => props.height};
    object-fit: contain;
`;

const Width100Column = styled(Column)`
    width: 100%;
`;
const CenteredRow = styled(Row)`
    align-items: center;
`;
const BlueFileIcon = styled(withIconStyles(FileIcon)).attrs(() => ({
    viewBox: '-6 0 29 29',
}))`
    height: 28px;
`;
const BlueDeleteIcon = styled(withInteractibleIconStyles(DeleteIcon))`
    height: 28px;
    color: ${colorFns.warningTextAction};
`;

const PaddedCopy = styled(Copy)`
    margin-left: 8px;
`;
const PaddedLink = styled(ExternalLink)`
    margin-left: 8px;
    color: ${colorFns.pureWhite};
    &: {
        visited: ${colorFns.pureWhite};
    }
`;
const ConstrainedRow = styled(CenteredRow)`
    max-height: 28px;
`;

const PlusCircleButton = styled(SmallCircleButton)`
    ${RoundedRow}:hover & {
        ${props => buttonColorsStyledFn(props)['&:hover']}
    }
`;

const RoundedRowWrap = ({ clickable, readonly, nested, children }) =>
    readonly ? (
        children
    ) : (
        <RoundedRow nested={nested} clickable={clickable}>
            {children}
        </RoundedRow>
    );
export class Uploader extends Component {
    state = {
        resource: this.props.resource,
    };

    static getDerivedStateFromProps(newProps, currentState) {
        return newProps.resource !== currentState.resource ? { resource: newProps.resource } : null;
    }

    handleUploadStart() {
        this.setState({
            error: null,
            pending: true,
        });
    }

    handleUploadSuccess(cloudinaryResponse) {
        const { onChange } = this.props;
        this.setState({
            resource: cloudinaryResponse,
            pending: false,
        });
        onChange && onChange(cloudinaryResponse);
    }

    handleUploadError(error) {
        this.setState({
            error,
            pending: false,
        });
    }

    handleCustomImageDeleteClick() {
        const { onChange } = this.props;
        this.setState({
            resource: null,
        });
        onChange && onChange(null);
    }

    uploaderProps = {
        onUploadStart: () => this.handleUploadStart(),
        onUploadSuccess: document => this.handleUploadSuccess(document),
        onUploadError: error => this.handleUploadError(error),
    };

    render() {
        const { ctaLabel, description, prompt, disabled, readonly, nested } = this.props;
        const { resource, pending } = this.state;

        const editable = !disabled && !readonly;

        const roundedRowProps = { readonly, nested };

        const withResource = resource ? (
            <>
                <CenteredRow>
                    {resource.resourceType === 'image' ? (
                        <ConstrainedImage alt="Custom Image Preview" src={resource.url} width="60px" height="40px" />
                    ) : (
                        <BlueFileIcon />
                    )}
                    <PaddedLink href={resource.url} target="_blank" rel="noopener noreferrer">
                        {resource.title
                            ? `${resource.title}.${resource.format || getExtension(resource.url)}`
                            : getFileName(resource.url)}
                    </PaddedLink>
                </CenteredRow>
                {editable && <BlueDeleteIcon onClick={() => this.handleCustomImageDeleteClick()} />}
            </>
        ) : null;

        const withCtaLabel = editable ? (
            <>
                <CopyFaded>{prompt}</CopyFaded>
                <CloudinaryUploader ctaLabel={ctaLabel} {...this.uploaderProps} />
            </>
        ) : null;

        const noCtaLabel = editable ? (
            <CloudinaryUploader {...this.uploaderProps}>
                <RoundedRowWrap clickable {...roundedRowProps}>
                    <ConstrainedRow>
                        {pending ? (
                            <Spinner suppressMargin />
                        ) : (
                            <PlusCircleButton
                                onClick={
                                    e => e.preventDefault()
                                    // There's a <form> inside CloudinaryUploader
                                }
                            >
                                <PlusIcon />
                            </PlusCircleButton>
                        )}
                        <PaddedCopy>{prompt}</PaddedCopy>
                    </ConstrainedRow>
                </RoundedRowWrap>
            </CloudinaryUploader>
        ) : null;

        return (
            <Width100Column>
                {resource ? (
                    <RoundedRowWrap {...roundedRowProps}>{withResource}</RoundedRowWrap>
                ) : ctaLabel ? (
                    <RoundedRowWrap {...roundedRowProps}>{withCtaLabel}</RoundedRowWrap>
                ) : (
                    noCtaLabel
                )}
                {description && (
                    <>
                        <Spacer />
                        <Copy>{description}</Copy>
                    </>
                )}
            </Width100Column>
        );
    }
}
