import createStore from 'zustand';

import { getEventTemplates, shareEventTemplate, deleteEventTemplate } from 'api/eventTemplates';
import { getPlaybook } from 'api/playbooks';

type State = {
    loading: boolean;
    templates: Bizly.EventTemplate[];

    loadingById: Partial<{ [id: number]: boolean; [id: string]: boolean }>;
    byId: Partial<{ [id: number]: BizlyAPI.Complete.Playbook; [id: string]: BizlyAPI.Complete.Playbook }>;
};
type Store = State;

const initialState: State = {
    templates: [],
    loading: false,

    loadingById: {},
    byId: {},
};

export const [useEventTemplates, eventTemplatesStoreApi] = createStore<Store>(() => initialState);

const { setState, getState } = eventTemplatesStoreApi;

const loadAll = async () => {
    setState({ loading: true });

    try {
        const { templates } = await getEventTemplates();

        setState({
            templates,
            loading: false,
        });

        return templates;
    } catch (e) {
        setState({ loading: false });
        throw e;
    }
};

const loadSingle = async (id: number | string) => {
    if (getState().loadingById[id]) return;

    setState({ loadingById: { ...getState().loadingById, [id]: true } });

    try {
        const { playbook } = await getPlaybook(id);
        setState({
            loadingById: { ...getState().loadingById, [id]: false },
            byId: {
                [id]: playbook,
            },
        });
        return playbook;
    } catch (e) {
        setState({ loadingById: { ...getState().loadingById, [id]: false } });
    }
};

function load(id?: undefined): Promise<Bizly.EventTemplate[]>;
function load(id: number | string): Promise<BizlyAPI.Complete.Playbook | undefined>;
function load(
    id?: number | string | undefined
): Promise<Bizly.EventTemplate[] | BizlyAPI.Complete.Playbook | undefined> {
    return id === undefined ? loadAll() : loadSingle(id);
}
export const eventTemplatesActions = {
    load,

    share: async (targetId: number | string) => {
        if (getState().loading) {
            return;
        }
        setState({ loading: true });

        try {
            const { template: sharedTemplate } = await shareEventTemplate(targetId);
            const { templates: currentTemplates } = getState();
            const updatedTemplates = currentTemplates.map(template =>
                template.id === Number(targetId) ? sharedTemplate : template
            );

            setState({
                templates: updatedTemplates,
                loading: false,
            });
        } catch (e) {
            setState({ loading: false });
            throw e;
        }
    },

    delete: async (targetId: number | string) => {
        if (getState().loading) {
            return;
        }
        setState({ loading: true });

        try {
            const { success, message } = await deleteEventTemplate(targetId);
            if (success) {
                const { templates: currentTemplates } = getState();
                const updatedTemplates = currentTemplates.filter(template => template.id !== Number(targetId));

                setState({
                    templates: updatedTemplates,
                    loading: false,
                });
            } else {
                throw new Error(message);
            }
        } catch (e) {
            setState({ loading: false });
            throw e;
        }
    },

    add: (playbook: BizlyAPI.Complete.Playbook) => {
        setState({
            byId: {
                [playbook.id]: playbook,
            },
        });
    },
};

export const selectPlaybook = (id: number | string) => (state: State) => state.byId[id];

export const isLoading = (id: number | string) => (state: State) => state.loadingById[id];
