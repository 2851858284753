import React from 'react';
import styled from 'styled-components/macro';
import { useHistory, Redirect } from 'react-router';
import { useSnackbar } from 'notistack';

import compact from 'lodash/compact';
import partition from 'lodash/partition';
import uniq from 'lodash/uniq';

import { addAttendees } from '../api';
import { emailIsValid } from '../util';
import { contentWidth, withInteractibleIconStyles } from '../shared';
import { EColors } from 'theme';

import { Button, Column, Copy, Spacer, TopRounded, TruncatingSingleLineCopy } from '../ui';

import { LargeHeadline } from '../components/ui/Headline';

import { ReactComponent as DeleteIconSVG } from '../images/icons/trash-can.svg';
import { Spinner } from '../components/Spinner';
import { StandardInput } from '../components/StandardInput';
import { StickyHeader, stickyHeaderHeight } from '../components/StickyHeader';

const DeleteIcon = withInteractibleIconStyles(DeleteIconSVG);

const Centered = styled(Column)`
    width: ${contentWidth};
    margin: ${stickyHeaderHeight} auto;
    padding: 36px 0;
`;

type PendingAddition = {
    key: number;
    email: string;
};

export const AddAttendees = ({
    event,
    attendees,
    setAttendees,
}: {
    event: Bizly.Event;
    attendees: Array<Bizly.EventAttendee>;
    setAttendees: (attendees: Array<Bizly.EventAttendee>) => void;
}) => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [key, setKey] = React.useState(0);
    const [pending, setPending] = React.useState(false);
    const [pendingAddition, setPendingAddition] = React.useState<Array<PendingAddition>>([]);
    const [pendingInput, setPendingInput] = React.useState('');

    async function handleAddClick() {
        const { newEmailsCount, duplicateEmailsCount } = getEmailCounts(pendingAddition);

        let successMessage = newEmailsCount === 1 ? '1 person was added' : `${newEmailsCount} people were added`;

        if (duplicateEmailsCount > 0) {
            successMessage +=
                duplicateEmailsCount === 1
                    ? '. 1 email was a duplicate and was not added to the list.'
                    : `. ${duplicateEmailsCount} emails were duplicates and were not added to the list.`;
        }

        const successUrl = `/event/${event.id}/guest-list`;

        setPending(true);

        const uniquePendingAddition = uniq(pendingAddition);

        try {
            const updatedAttendees = await addAttendees(
                event.id,
                uniquePendingAddition.map(pa => ({ email: pa.email }))
            );

            setAttendees(updatedAttendees);

            enqueueSnackbar(successMessage, {
                variant: 'info',
            });
            history.push(successUrl);
        } catch (e) {
            enqueueSnackbar(`Error: ${e}`, { variant: 'error' });
        }

        setPending(false);
    }

    function getPendingAdditionEmails(pendingAddition: Array<PendingAddition>) {
        return pendingAddition.map(invitee => invitee.email);
    }

    function getEmailCounts(
        pendingAddition: Array<PendingAddition>
    ): { newEmailsCount: number; duplicateEmailsCount: number } {
        const attendeeEmails = attendees.map((attendee: Bizly.EventAttendee) => attendee.email);

        const pendingAdditionEmails = getPendingAdditionEmails(pendingAddition);
        const uniquePendingAdditionEmails = uniq(pendingAdditionEmails);

        const [duplicateEmails, newEmails] = partition(uniquePendingAdditionEmails, email =>
            attendeeEmails.includes(email)
        );

        const newEmailsCount = newEmails.length;

        const duplicateEmailsCount =
            duplicateEmails.length + pendingAdditionEmails.length - uniquePendingAdditionEmails.length;

        return { newEmailsCount, duplicateEmailsCount };
    }

    function handlePendingAdditionsSubmit(newValue: string) {
        const emails = newValue
            .split(' ')
            .join('')
            .split(',')
            .map(email => email.trim())
            .filter(email => email.length);

        const invalidEmails = emails.filter(email => !emailIsValid(email));
        const invalidCount = invalidEmails.length;

        if (invalidCount > 0) {
            return enqueueSnackbar(
                `Error: ${invalidEmails.join(invalidCount > 1 ? ', ' : '')} ${
                    invalidCount > 1 ? 'are not valid emails' : 'is not a valid email'
                }`,
                {
                    variant: 'error',
                }
            );
        }

        setPendingAddition(
            pendingAddition.concat(
                compact(emails).map((inviteeEmail, idx) => ({
                    key: key + idx + 1,
                    email: inviteeEmail,
                }))
            )
        );
        setKey(key + newValue.length);
        setPendingInput('');
    }

    function removeAttendee(attendee: PendingAddition) {
        setPendingAddition(pendingAddition.filter(invitee => invitee.key !== attendee.key));
    }

    if (pending) {
        return <Spinner />;
    }

    if (!event.editable) {
        return <Redirect to={`/event/${event.id}/guest-list`} />;
    }

    return (
        <Column style={{ width: '100%' }}>
            <StickyHeader style={{ justifyContent: 'flex-end' }}>
                <Button
                    onClick={() => history.push(`/event/${event.id}/guest-list`)}
                    variant="outlined"
                    style={{ marginRight: '16px' }}
                >
                    Cancel
                </Button>
                <Button disabled={pendingAddition.length <= 0} onClick={handleAddClick}>
                    Add
                </Button>
            </StickyHeader>
            <Centered>
                <LargeHeadline>Add People</LargeHeadline>
                <Copy>Add one or more people to your meeting by entering their email(s).</Copy>
                <Spacer />
                <Spacer />
                <StandardInput
                    autoFocus
                    fullWidth
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPendingInput(e.target.value)}
                    onSubmit={(e: React.ChangeEvent<HTMLInputElement>) => handlePendingAdditionsSubmit(e.target.value)}
                    helperText="Add multiple people by adding a comma between each email."
                    placeholder="Enter email(s) and press enter"
                    value={pendingInput}
                    variant="outlined"
                />
                <Spacer />
                {pendingAddition.length > 0 && (
                    <Copy $color={EColors.highlightedText} style={{ marginBottom: '16px' }}>
                        {pendingAddition.length > 1
                            ? `${pendingAddition.length} people will be added`
                            : '1 person will be added'}
                    </Copy>
                )}
                {pendingAddition.map(invitee => (
                    <TopRounded
                        key={invitee.key}
                        style={{
                            marginBottom: '16px',
                        }}
                    >
                        <TruncatingSingleLineCopy maxWidth={200}>{invitee.email}</TruncatingSingleLineCopy>
                        <DeleteIcon onClick={() => removeAttendee(invitee)} />
                    </TopRounded>
                ))}
            </Centered>
        </Column>
    );
};
