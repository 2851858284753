import React, { Component } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import styled from 'styled-components/macro';
import { withSnackbar } from 'notistack';
import MenuItem from '@material-ui/core/MenuItem';

import { downloadFromUrl } from '../util';
import { exportAttendees, loadAttendees, removeAttendee, updateAttendee } from 'api';
import { AlignedColumn, Button, Column, Copy, SpacedRow, Row, Spacer, StatusChip } from 'ui';
import { contentWidth, statusColors, statusMap, statusOptions } from 'shared';
import { EventContext } from 'providers/event';
import { LargeHeadline } from 'components/ui/Headline';
import { ButtonMenu } from 'components/ButtonMenu';
import AttendeeModal from 'components/Attendees/AttendeeModal';
import { DeleteConfirmationModal } from 'components/DeleteConfirmationModal';
import { MaterialTable } from 'components/MaterialTable';
import { Spinner } from 'components/Spinner';
import TallyDisplay from 'components/ui/TallyDisplay';
import { AddAttendees } from './AddAttendees';
import { AddAttendeesImport } from './AddAttendeesImport';

const statusTally = (arr, status) => arr.filter(i => i.status === status).length;

const BaselineAlignedRow = styled(SpacedRow)`
    align-items: baseline;
`;

class AttendeesClass extends Component {
    static contextType = EventContext;

    state = {
        attendees: null,
        deleteIntent: {},
        editIntent: null,
        pending: false,
        sort: null,
    };

    updateEventAttendeesCount = attendees => {
        if (!attendees || !Array.isArray(attendees)) {
            return;
        }
        const { updateAttendeesCount } = this.context;

        const attendeeStatusMap = {
            'not sent': 'notSent',
            invited: 'invited',
            attending: 'attending',
            'not attending': 'notAttending',
        };
        const updatedAttendeeCounts = {
            total: 0,
            notSent: 0,
            invited: 0,
            attending: 0,
            notAttending: 0,
        };

        attendees.forEach(attendee => {
            const status = attendeeStatusMap[attendee.status];
            if (status) {
                updatedAttendeeCounts.total++;
                updatedAttendeeCounts[status]++;
            }
        });

        updateAttendeesCount(updatedAttendeeCounts);
    };

    async refreshAttendees(eventId) {
        const { enqueueSnackbar } = this.props;
        let attendees;

        this.setState({
            pending: true,
        });

        try {
            attendees = await loadAttendees(eventId);
            this.updateEventAttendeesCount(attendees);
        } catch (e) {
            console.error(e);
            enqueueSnackbar(`Could not fetch data for meeting ${eventId}`, {
                variant: 'error',
            });
        }
        this.setState({
            attendees,
            pending: false,
        });
    }

    componentDidMount() {
        const { event } = this.context;
        this.refreshAttendees(event.id);
    }

    handleEditClick(id) {
        const { attendees } = this.state;
        this.setState({
            editIntent: attendees.find(a => a.id === id),
        });
    }

    handleRemoveClick(id) {
        const { attendees } = this.state;
        this.setState({
            deleteIntent: attendees.find(a => a.id === id),
        });
    }

    async handleExportClick() {
        const { event } = this.context;
        downloadFromUrl(await exportAttendees(event.id));
    }

    async handleDeleteConfirm() {
        const { enqueueSnackbar } = this.props;
        const { event } = this.context;
        const { deleteIntent } = this.state;
        this.setState({
            pending: true,
        });
        try {
            await removeAttendee(event.id, deleteIntent);
            enqueueSnackbar(`${deleteIntent.email} was removed`, {
                variant: 'info',
            });
        } catch (e) {
            console.error(e);
            enqueueSnackbar(`Could not remove attendee ${deleteIntent.id} from meeting ${event.id}`, {
                variant: 'error',
            });
        }
        this.setState({
            deleteIntent: {},
            pending: false,
        });
        this.refreshAttendees(event.id);
    }

    handleModalDismiss() {
        this.setState({
            editIntent: null,
            deleteIntent: {},
        });
    }

    async handleAttendeeSave(draft) {
        const { enqueueSnackbar } = this.props;
        const { event } = this.context;
        try {
            await updateAttendee(event.id, draft);
            await this.refreshAttendees(event.id);
            enqueueSnackbar(`${draft.email} was updated`, { variant: 'info' });
            this.setState({
                editIntent: null,
            });
        } catch (e) {
            enqueueSnackbar(`Could not save attendee data for ${draft.email}`, {
                variant: 'error',
            });
        }
    }

    handleSortChange(newValue) {
        this.setState({
            sort: newValue,
        });
    }

    render() {
        const { event } = this.context;
        const { attendees, deleteIntent, editIntent, pending, sort } = this.state;
        if (pending || !attendees) {
            return <Spinner />;
        }

        return (
            <Switch>
                <Route exact path={`/event/${event.id}/guest-list/add/by-email`}>
                    <AddAttendees
                        attendees={attendees}
                        setAttendees={attendees => {
                            this.setState({ attendees });
                            this.updateEventAttendeesCount(attendees);
                        }}
                        event={event}
                    />
                </Route>
                <Route exact path={`/event/${event.id}/guest-list/add/by-import`}>
                    <AddAttendeesImport
                        setAttendees={attendees => {
                            this.setState({ attendees });
                            this.updateEventAttendeesCount(attendees);
                        }}
                    />
                </Route>
                <AlignedColumn style={{ width: '100%' }}>
                    <DeleteConfirmationModal
                        isActive={deleteIntent.status}
                        onDismiss={() => this.handleModalDismiss()}
                        onProceed={() => this.handleDeleteConfirm()}
                        prompt={
                            deleteIntent.status === 'attending'
                                ? 'We’ll remove this person from your meeting, along with any details you or they have entered. They’ve RSVPed as ‘Attending’, so we’ll notify them.'
                                : 'We’ll remove this person from your meeting, along with any details you or they have entered. They haven’t RSVPed as ‘Attending’, so we won’t notify them.'
                        }
                    />
                    {editIntent && (
                        <AttendeeModal
                            editable={event.editable}
                            attendee={editIntent}
                            eventId={event.id}
                            onDismiss={() => this.handleModalDismiss()}
                            onSave={draft => this.handleAttendeeSave(draft)}
                        />
                    )}
                    <BaselineAlignedRow>
                        <Column>
                            <LargeHeadline>Guest List</LargeHeadline>
                            <div style={{ width: contentWidth }}>
                                <Copy>
                                    Create your all-star guest list, and keep track of your attendees’ details and
                                    preferences.
                                </Copy>
                                <Spacer largest />
                                <TallyDisplay
                                    style={{ marginLeft: '32px' }}
                                    tallies={[
                                        { label: 'total', tally: attendees.length },
                                        { label: 'invited', tally: statusTally(attendees, 'invited') },
                                        { label: 'attending', tally: statusTally(attendees, 'attending') },
                                        { label: 'not attending', tally: statusTally(attendees, 'not attending') },
                                    ]}
                                />
                            </div>
                        </Column>
                        <Row style={{ justifyContent: 'flex-end' }}>
                            <Button
                                onClick={() => this.handleExportClick()}
                                style={{ marginRight: '16px' }}
                                variant="outlined"
                            >
                                Export list
                            </Button>
                            {event.editable && (
                                <ButtonMenu label="Add people">
                                    <Link to={`/event/${event.id}/guest-list/add/by-email`}>
                                        <MenuItem>Add by email</MenuItem>
                                    </Link>
                                    <Link to={`/event/${event.id}/guest-list/add/by-import`}>
                                        <MenuItem>Import list</MenuItem>
                                    </Link>
                                    {/* <MenuItem>Import File</MenuItem> */}
                                </ButtonMenu>
                            )}
                        </Row>
                    </BaselineAlignedRow>
                    <Spacer />
                    <Spacer />
                    <MaterialTable
                        headers={[
                            { id: 'status', label: 'Status' },
                            { id: 'name', label: 'Name' },
                            { id: 'email', label: 'Email' },
                            { id: 'phone', label: 'Phone' },
                        ]}
                        onEdit={id => this.handleEditClick(id)}
                        onRemove={id => this.handleRemoveClick(id)}
                        onSortChange={newValue => this.handleSortChange(newValue.target.value)}
                        placeholderLength={7}
                        rows={attendees
                            .filter(a => !sort || sort === 'all' || a.status === sort)
                            .map(a => ({
                                id: a.id,
                                data: {
                                    ...a,
                                    name: a.firstName && a.lastName && `${a.firstName} ${a.lastName}`,
                                    status: (
                                        <StatusChip label={statusMap[a.status]} statusColor={statusColors[a.status]} />
                                    ),
                                },
                            }))}
                        sort={sort}
                        sortOptions={statusOptions}
                        editable={event.editable}
                    />
                </AlignedColumn>
            </Switch>
        );
    }
}

export const Attendees = withSnackbar(AttendeesClass);
