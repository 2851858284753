import React, { useState, useRef } from 'react';
import styled from 'styled-components/macro';

import { Column, Spacer } from '../ui';

import { IconHeadline } from './ui/Headline';
import { ReactComponent as LightBulb } from '../images/icons/lightbulb.svg';
import { ReactComponent as LightBulbCircle } from '../images/icons/lightbulb_circle.svg';
import { withIconStyles, withInteractibleIconStyles } from 'shared';
import { Popover as MUIPopover } from '@material-ui/core';
import colorFns from 'colorFns';

const LightBulbIcon = styled(withIconStyles(LightBulb)).attrs(({ theme: { EColors } }) => ({
    colorOverride: EColors.protipsIcon,
}))``;

const LightBulbCircleIcon = styled(withInteractibleIconStyles(LightBulbCircle))`
    circle {
        fill: ${colorFns.protipsBubble};
    }
`;

type ProTipProps = {
    classNames?: string;
    message: string;
    children?: React.ReactNode;
};

const Container = styled(Column)`
    flex-shrink: 0;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

export const ProTip = ({ classNames, message, children }: ProTipProps) => (
    <Container className={`pro-tip ${classNames}`}>
        <IconHeadline headline="Pro Tip" description={message} icon={<LightBulbIcon />} />
        {children && (
            <>
                <Spacer />
                {children}
            </>
        )}
    </Container>
);

const Popover = styled(MUIPopover)`
    .MuiPopover-paper {
        margin-top: 21px;
        background-color: transparent;
        box-shadow: none;
        overflow: inherit;

        ${Column} {
            max-width: 400px;

            ${Container} {
                border: none;
                box-shadow: 0 4px 12px ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};
            }
        }
    }
    z-index: 1501 !important; /* higher than tooltip */
`;

export const filterProtips = (
    proTips: Bizly.ProTip[],
    targetPage: Bizly.ProTip['page'],
    targetSection: Bizly.ProTip['section']
) => proTips.filter(({ page, section }) => page === targetPage && section === targetSection);

export const ProTipsDropdown = ({
    protips = [],
    hover,
    side,
}: {
    protips?: Bizly.ProTip[];
    hover?: boolean;
    side?: boolean;
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const anchorRef = useRef<SVGSVGElement | null>(null);

    const handleToggle = () => setIsOpen(prevState => !prevState);

    return protips?.length > 0 ? (
        <>
            <LightBulbCircleIcon
                ref={anchorRef}
                {...(hover ? { onMouseEnter: handleToggle } : { onClick: handleToggle })}
            />
            <Popover
                anchorEl={anchorRef.current}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: side ? 'left' : 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: side ? 'left' : 'center',
                }}
                onClose={() => setIsOpen(false)}
            >
                <Column itemSpacing="smallish" {...(hover ? { onMouseLeave: handleToggle } : {})}>
                    {protips?.map((protip, idx) => (
                        <ProTip key={protip.id ?? protip.name + idx} message={protip.content} />
                    ))}
                </Column>
            </Popover>
        </>
    ) : null;
};
