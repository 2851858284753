import React from 'react';

import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import App from './App';

import { StylesProvider } from '@material-ui/core/styles';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import {
    getStyledTheme,
    TStyledTheme,
    materialUIThemeStyledFn,
    ETeams,
    setColorTheme,
    bizlyStyledTheme,
    useSnackbarStyles,
    useBizlySnackbarStyles,
} from 'theme';
import { Column } from 'ui';

const fontStyleFn = ({ theme: { getFontStyle, EFonts } }: { theme: TStyledTheme }) => ({
    '& *': getFontStyle(EFonts.body),
    '& h1, & h2, & h3, & h4, & h5, & h6, & h7': getFontStyle(EFonts.heading),
});

const FontProvider = createGlobalStyle(fontStyleFn);

const BizlyFontProvider = styled(Column)`
    width: 100%;
    ${fontStyleFn}
`;

const ThemedMuiThemeProvider = styled(
    // pull out className to avoid proptype error
    ({ className, ...props }) => <MuiThemeProvider {...props} />
).attrs(materialUIThemeStyledFn)``;

export const ThemedSnackbarProvider = ({ children, isBizly }: { children?: React.ReactElement; isBizly?: boolean }) => {
    const snackbarClasses = useSnackbarStyles();
    const bizlySnackbarClasses = useBizlySnackbarStyles();
    return (
        <SnackbarProvider
            classes={isBizly ? bizlySnackbarClasses : snackbarClasses}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            children={children}
        />
    );
};

export const BizlyThemeOverride = ({ children }: { children?: React.ReactElement }) => {
    return (
        <ThemeProvider theme={{ ...bizlyStyledTheme() }}>
            <BizlyFontProvider>
                <ThemedMuiThemeProvider>
                    <ThemedSnackbarProvider isBizly>{children}</ThemedSnackbarProvider>
                </ThemedMuiThemeProvider>
            </BizlyFontProvider>
        </ThemeProvider>
    );
};

export default function ThemedApp() {
    const [, setThemeState] = React.useState<ETeams>();

    const setTheme = React.useCallback(
        (team: ETeams) => {
            setColorTheme(team);
            setThemeState(team);
        },
        [setThemeState]
    );

    return (
        <ThemeProvider theme={{ ...getStyledTheme() }}>
            <StylesProvider injectFirst>
                <FontProvider />
                <ThemedMuiThemeProvider>
                    <ThemedSnackbarProvider>
                        <BrowserRouter>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <App setTheme={setTheme} />
                            </MuiPickersUtilsProvider>
                        </BrowserRouter>
                    </ThemedSnackbarProvider>
                </ThemedMuiThemeProvider>
            </StylesProvider>
        </ThemeProvider>
    );
}
