import React from 'react';
import { format, parseISO } from 'date-fns';
import debounce from 'lodash/debounce';
import { mapValues, keyBy } from 'lodash';

export const padArray = <T, U = undefined>(arr: T[], len: number, fill?: U): (T | U)[] =>
    arr.length > len ? arr : arr.concat(Array(len).fill(fill)).slice(0, len);

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const legacyHostName =
    process.env.NODE_ENV === 'production' ? 'https://app.bizly.com/' : 'https://app-dev.bizly.com/';

export const goToLegacyApp = (path: string) => {
    const win = window.open(`${legacyHostName}${path || 'dashboard/events'}`, '_blank');
    win && win.focus();
};

export const downloadFromUrl = (url: string) => {
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', url);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const getFirstMatchingKey = <T, U>(obj1: T, obj2: U) => {
    for (const key of Object.keys(obj1)) {
        if (obj2[key as keyof U] !== null) {
            return key;
        }
    }

    return 'default';
};

export const matchLastOrDefault = <
    TKeys extends string,
    TValue,
    TIncomingProps extends Partial<Record<TKeys, boolean | undefined> & { [key: string]: any }>
>(
    valuesDict: { [key in TKeys | 'default']?: TValue },
    props: TIncomingProps
) => {
    const match = Object.keys(props)
        .reverse()
        .filter(key => props[key] === true && valuesDict[key as TKeys] !== undefined)[0] as TKeys;

    return valuesDict[match] || valuesDict.default;
};

export const randomSelect = <T>(arr: T[] = []) => arr[Math.floor(Math.random() * arr.length)];

export const parseName = (copy = '', name?: string) => (name ? copy.replace('%N', name) : copy);

const emailRegEx = /^[+a-zA-Z0-9_.!#$%&'*/=?^`{|}~-]+@([a-zA-Z0-9-]+\.)+[a-zA-Z0-9]{2,63}$/;

export const emailIsValid = (email: string) => emailRegEx.test(email);

export const parcelType = (parcel: Bizly.Parcel) => {
    if (parcel.traits && !Array.isArray(parcel.traits) && Object.keys(parcel.traits).includes('rsvp')) {
        return 'invite';
    } else {
        return 'note';
    }
};

const dollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const formatCurrency = (currency: Nullable<number>, withCents?: boolean): string =>
    currency ? dollarFormatter.format(currency).slice(0, withCents ? undefined : -3) : '0';

export const formatPercentage = (percentage: Nullable<number>): string =>
    percentage ? percentage.toString() + '%' : '0%';

export const formatDate = (dateAsString: string) => format(parseISO(dateAsString), 'haaaa');

const METERS_PER_MILE = 1609.34;

export function toMeters(miles: Distance.Mile): Distance.Meter {
    return Math.ceil(miles * METERS_PER_MILE) as Distance.Meter;
}

export function toMiles(meters: Distance.Meter): Distance.Mile {
    return Math.floor(meters / METERS_PER_MILE) as Distance.Mile;
}

export const takeFirstIfArray = (params: { [param: string]: string | string[] | null | undefined }) =>
    Object.keys(params).reduce(
        (agg, param) => ({
            ...agg,
            [param]: Array.isArray(params[param] as string[]) ? params[param]![0] : params[param],
        }),
        params
    ) as { [param: string]: string | null | undefined };

export const fillObject = <TKey extends string, TFill extends any>(keys: TKey[], fill: TFill) =>
    mapValues(keyBy(keys) as Record<TKey, TKey>, () => fill);

/*
    Debouncing is an async activity outside the React lifecycle
    Therefore, it is vulnerable to race conditions

    We will need to give the component a stable debounced function in which subsequent calls cancel prior calls
    The debounced function will need to call the *latest* version of the passed in callback

    Therefore, we must store the latest version of the callback
    But pass out a stable debounced function

    Limitations: changing the delay value will not update the function, this can be added as a future update
*/
export function useDebounce(callback?: (...args: any[]) => any, defaultDelay = 200) {
    const savedCallback = React.useRef(callback);
    React.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    const trigger = React.useRef(
        debounce((...args: any[]) => {
            // the callback may have changed by the time we hit this line, so we must use the latest version
            if (savedCallback.current) {
                return savedCallback.current(...args);
            }
        }, defaultDelay)
    );

    return [trigger.current];
}
