import React from 'react';
import styled from 'styled-components';

import { Copy, ExternalLink, Row } from 'ui';

import { Popover } from '@material-ui/core';

import { ReactComponent as VMIconSvg } from 'images/icons/videocamera.svg';
import { ReactComponent as CopyIconSvg } from 'images/icons/copy.svg';
import { withInteractibleIconStyles } from 'shared';

export const VMIcon = styled(withInteractibleIconStyles(VMIconSvg))`
    width: 25px;
    height: 25px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.74)};
    &:hover {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.74)};
    }
`;

const CopyIcon = withInteractibleIconStyles(CopyIconSvg);

const LinkPopoverRow = styled(Row)`
    position: relative;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.brand)};

    padding: 12px;
    box-sizing: border-box;
    a {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.74)};
        &:hover,
        &:active,
        &:visited {
            color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.44)};
        }
    }
`;

const Copied = styled(Copy)`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    z-index: 1;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.brand, 0.74)};
    width: 100%;
    line-height: 22px;
    text-align: center;
    padding: 12px;
    box-sizing: border-box;
`;

function selectNodeText(node: Element) {
    if ((document.body as any).createTextRange) {
        const range = (document.body as any).createTextRange();
        range.moveToElementText(node);
        range.select();
    } else if (window.getSelection) {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(node);
        selection?.removeAllRanges();
        selection?.addRange(range);
    } else {
        return;
    }
}

function clearTextSelection() {
    if (window.getSelection) {
        if (window?.getSelection()?.empty) {
            // Chrome
            window.getSelection()?.empty();
        } else if (window.getSelection()?.removeAllRanges) {
            // Firefox
            window.getSelection()?.removeAllRanges();
        }
    } else if ((document as any).selection) {
        // IE?
        (document as any).selection.empty();
    }
}

export default function VirtualMeetingPopover({
    virtualMeeting,
}: {
    virtualMeeting?: BizlyAPI.Meeting['virtualMeeting'];
}) {
    const [anchorEl, setAnchorEl] = React.useState<SVGSVGElement | null>(null);
    const linkRef = React.createRef<HTMLAnchorElement>();
    const [copied, setCopied] = React.useState(false);

    const copyLink = () => {
        if (!linkRef.current) {
            return;
        }
        selectNodeText(linkRef.current);
        document.execCommand('copy');
        clearTextSelection();

        setCopied(true);
        setAnchorEl(null);
    };

    if (!virtualMeeting) return null;

    return (
        <>
            <VMIcon
                onClick={e => {
                    setAnchorEl(e.currentTarget);
                    setCopied(false);
                }}
            />

            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transitionDuration={{
                    exit: copied ? 1000 : 300,
                }}
            >
                <LinkPopoverRow itemSpacing="small" alignItems="center">
                    <ExternalLink ref={linkRef} href={virtualMeeting.link} openInNewTab underline>
                        {virtualMeeting.link}
                    </ExternalLink>
                    <CopyIcon onClick={copyLink} />
                    {copied && <Copied>Link Copied!</Copied>}
                </LinkPopoverRow>
            </Popover>
        </>
    );
}
