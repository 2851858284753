import React, { Component } from 'react';
import styled from 'styled-components/macro';
import { format, parseISO } from 'date-fns';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';

import { timeOptions, mergeDateTime } from '../../utils/date_util';
import { Pane } from '../Pane';
import { Button, Copy, Row, Spacer, TextArea } from '../../ui';
import SmallCircleButton from 'components/ui/SmallCircleButton';
import { buttonColorsStyledFn } from 'components/ui/Button';

import { Headline as BlackHeadline } from '../ui/Headline';

import DropdownMenu from '../ui/Dropdown';
import DatePickerOrig from '../ui/DatePicker';

import { EventContext } from '../../providers/event';

import Agenda from './Agenda';

import useThemedColor from 'hooks/useThemedColor';
import fontFns from 'fontFns';

const DatePicker = DatePickerOrig as any;

const timeFormat = 'h:mm a';

const createBaseGuestroom = () => ({
    name: null,
    count: 1,
    notes: null,
});

const createBaseAgenda = (day: Bizly.Day): any => ({
    amenities: {
        av: [],
        foodAndBeverage: [],
    },
    roomSetup: null,
    start: day.start,
    end: day.end,
});

const paneStyles = (borderColor: Themed.Color) => ({
    border: `1px solid ${borderColor}`,
    borderRadius: '12px 12px',
    marginBottom: '16px',
});

const Headline = styled(BlackHeadline)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formHeading)};
    ${fontFns.formHeading}
`;

const ListItemButtonWrap = styled(Button)``;

const NestedCircleButton = styled(SmallCircleButton)`
    margin: 5px 8px 5px 0;

    ${ListItemButtonWrap}:hover & {
        ${props => buttonColorsStyledFn(props)['&:hover']}
    }
`;

export const AddButton = ({
    children,
    isSecondary,
    ...props
}: {
    children: React.ReactNode;
    isSecondary?: boolean;
    onClick: Function;
}) => {
    const { softBorder, paneInsideListItemBackground, paneListItemBackground, pureBlack } = useThemedColor();
    return (
        <ListItemButtonWrap
            {...props}
            style={{
                backgroundColor: isSecondary ? paneInsideListItemBackground : paneListItemBackground,
                borderColor: isSecondary ? softBorder : paneListItemBackground,
                borderRadius: '12px 12px',
                display: 'flex',
                justifyContent: 'flex-start',
            }}
            variant="outlined"
        >
            <NestedCircleButton as="div">
                <PlusIcon />
            </NestedCircleButton>
            <div
                style={{
                    color: pureBlack,
                }}
            >
                {children}
            </div>
        </ListItemButtonWrap>
    );
};

export const GuestRoomsInput = ({
    value,
    onChange,
    id,
    label,
    ...props
}: {
    value: string;
    onChange: Function;
    id: string;
    label: string;
    disabled?: boolean;
}) => (
    <TextField
        defaultValue={value || ''}
        onKeyPress={(e: React.KeyboardEvent) => {
            const keyNumber: unknown = e.key;

            if (isNaN(keyNumber as number) && e.key !== 'Backspace') {
                e.preventDefault();
            }
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const valueNumber: unknown = e.target.value;

            onChange && !isNaN(valueNumber as number) && onChange(e.target.value);
        }}
        margin="dense"
        style={{
            width: '100px',
        }}
        variant="outlined"
        label={label}
        id={id}
        {...props}
    />
);

type TGuestroomsPane = {
    event: Bizly.Event;

    gr: Bizly.GuestRoom;

    onRemove: () => void;
    onGuestroomsChange: (newValue: Bizly.GuestRoom) => void;
    onNotesChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

const GuestroomsPane = ({ gr, event, onRemove, onGuestroomsChange, onNotesChange }: TGuestroomsPane) => {
    const { softBorder, pureWhite } = useThemedColor();

    return (
        <Pane
            collapsible={false}
            readOnly={!event.editable}
            contentStyle={{
                backgroundColor: pureWhite,
            }}
            id="guestRooms"
            label="Guest Rooms"
            onRemove={onRemove}
            style={paneStyles(softBorder)}
            nested
        >
            {() => (
                <Row>
                    <GuestRoomsInput
                        id="guestroom-count"
                        label="Rooms"
                        disabled={!event.editable}
                        value={gr.count.toString()}
                        onChange={onGuestroomsChange}
                    />
                    <Spacer />
                    <TextArea
                        disabled={!event.editable}
                        onChange={onNotesChange}
                        placeholder="Describe the room type (king, double, single) and any additional details."
                        id="guestroom-notes"
                        label="Notes"
                        value={gr.notes}
                    />
                </Row>
            )}
        </Pane>
    );
};

type DayProps = {
    day: Bizly.Day;
    onChange: Function;
    index: number;
    beginExpanded: boolean;
    onRemove?: Function;
    teamCompliance: Bizly.TeamCompliance;
    TOGGLE_MULTIPLE_SPACES?: boolean; // TODO: Dates-Flexible / Multiple Event Spaces | Remove this toggle to make this feature go live
};

class Day extends Component<DayProps> {
    static contextType = EventContext;

    handleDetailChange(key: string, newValue: Date | string) {
        const { day, onChange } = this.props; // TODO: Dates-Flexible / Multiple Event Spaces | Remove this toggle to make this feature go live
        const updated = {
            ...day,
            [key]: newValue,
        };
        onChange && onChange(updated);
    }

    onDateChange(newDate: Date) {
        const { day: currentDayValues, onChange } = this.props;
        const newStartDate = format(newDate, 'yyyy-MM-dd');

        onChange({ ...currentDayValues, startDate: newStartDate });
    }

    onTimeChange(key: 'startTime' | 'endTime', newTime: string) {
        this.handleDetailChange(key, newTime);
    }

    onAgendaChange(agendaIndex: number, newValue: Bizly.AgendaItem) {
        const { day, onChange } = this.props;
        const updated = {
            ...day,
            agenda: day.agenda.map((d, index) => (index === agendaIndex ? newValue : d)),
        };
        onChange && onChange(updated);
    }

    onAgendaRemove(indexToRemove: number) {
        const { day, onChange } = this.props;
        const updated = {
            ...day,
            agenda: day.agenda.filter((a, index) => index !== indexToRemove),
        };
        onChange && onChange(updated);
    }

    onGuestroomRemove(indexToRemove: number) {
        const { day, onChange } = this.props;
        const updated = {
            ...day,
            guestrooms: day.guestrooms.filter((gr, index) => index !== indexToRemove),
        };
        onChange && onChange(updated);
    }

    handleAddEventSpaceClick() {
        const { day, onChange } = this.props;
        const newBase = day.agenda.length > 0 ? { ...day.agenda[day.agenda.length - 1] } : createBaseAgenda(day);
        const updated = {
            ...day,
            agenda: day.agenda.concat(newBase as any),
        };
        onChange && onChange(updated);
    }

    handleAddGuestroomClick() {
        const { day, onChange } = this.props;
        const newBase =
            day.guestrooms.length > 0 ? { ...day.guestrooms[day.guestrooms.length - 1] } : createBaseGuestroom();
        const updated = {
            ...day,
            guestrooms: day.guestrooms.concat(newBase as any),
        };

        onChange && onChange(updated);
    }

    handleGuestroomChange(grIndex: number, key: string, newValue: any) {
        const { day, onChange } = this.props;
        const updated = {
            ...day,
            guestrooms: day.guestrooms.map((d, index) => (index === grIndex ? { ...d, [key]: newValue } : d)),
        };
        onChange && onChange(updated);
    }

    render() {
        const { beginExpanded, day, index, onRemove, teamCompliance, TOGGLE_MULTIPLE_SPACES } = this.props;

        const { event } = this.context;

        const { guestrooms, agenda, startDate, startTime, endTime, selectRangeStart, selectRangeEnd } = day;

        return (
            <Pane
                beginExpanded={beginExpanded}
                id={index.toString()}
                invert
                label={`Day ${index + 1}`}
                onRemove={onRemove ? id => onRemove() : undefined}
                readOnly={!event.editable}
                secondaryLabel={
                    startDate && startTime && endTime
                        ? `${format(parseISO(mergeDateTime(startDate, startTime)), timeFormat)} to ${format(
                              parseISO(mergeDateTime(startDate, endTime)),
                              timeFormat
                          )}`
                        : ''
                }
                style={{
                    marginBottom: '16px',
                }}
            >
                {toggle => (
                    <main>
                        <Headline>Schedule</Headline>
                        <Copy>Set the date and overall start and end time for this day.</Copy>
                        <Spacer />
                        <Row>
                            <DatePicker
                                id="day-date"
                                label="Date"
                                placeholder="Date"
                                disabled={!event.editable}
                                onChange={(newDate: Date) => this.onDateChange(newDate)}
                                minDate={selectRangeStart}
                                maxDate={selectRangeEnd}
                                value={startDate ? parseISO(mergeDateTime(startDate, '00:00')) : null}
                            />
                            <Spacer />
                            <DropdownMenu
                                disabled={!event.editable}
                                value={timeOptions.find(t => t.value === startTime)?.name}
                                onClick={time => this.onTimeChange('startTime', time.value)}
                                label="Start Time"
                                placeholder="Start Time"
                                options={timeOptions}
                            />
                            <Spacer />
                            <DropdownMenu
                                disabled={!event.editable}
                                value={timeOptions.find(t => t.value === endTime)?.name}
                                onClick={time => this.onTimeChange('endTime', time.value)}
                                label="End Time"
                                placeholder="End Time"
                                options={timeOptions}
                            />
                        </Row>
                        <Spacer />
                        {!teamCompliance.guestroomsDisabled && (
                            <>
                                <Divider />
                                <Spacer />
                                <Headline>Accommodations</Headline>
                                <Copy>Need guest rooms on this night? Add them here.</Copy>
                                <Spacer />
                                {guestrooms.map((gr, grIndex) => (
                                    <GuestroomsPane
                                        key={`guestrooms-pane-${grIndex.toString()}`} // TODO: Need guestrooms to have a UUID for the purpose of adding keys to iterable elements
                                        event={event}
                                        gr={gr}
                                        onRemove={() => this.onGuestroomRemove(grIndex)}
                                        onGuestroomsChange={(newValue: Bizly.GuestRoom) =>
                                            this.handleGuestroomChange(grIndex, 'count', newValue)
                                        }
                                        onNotesChange={newValue =>
                                            this.handleGuestroomChange(grIndex, 'notes', newValue.target.value)
                                        }
                                    />
                                ))}
                                {/* TODO: revisit if we want to support multiple guestrooms definitions */}
                                {guestrooms.length === 0 && event.editable && (
                                    <AddButton
                                        isSecondary={true}
                                        onClick={() => {
                                            this.handleAddGuestroomClick();
                                        }}
                                    >
                                        Add {guestrooms.length > 0 ? 'more' : ''} guest rooms
                                    </AddButton>
                                )}
                            </>
                        )}
                        <Spacer />
                        <Divider />
                        <Spacer />
                        <Headline>Meeting Space</Headline>
                        <Copy>
                            Add as many meeting spaces as you need on this day, and select the setup, catering and A/V
                            requirements for each.
                        </Copy>
                        <Spacer />
                        {agenda.map((a, agendaIndex) => (
                            <Agenda
                                beginExpanded={agendaIndex === agenda.length - 1}
                                editable={event.editable}
                                eventId={event.id}
                                index={agendaIndex}
                                key={agendaIndex}
                                data={a}
                                onChange={(newValue: Bizly.AgendaItem) => this.onAgendaChange(agendaIndex, newValue)}
                                onRemove={() => this.onAgendaRemove(agendaIndex)}
                            />
                        ))}
                        {event.editable && (!agenda.length || TOGGLE_MULTIPLE_SPACES) && (
                            <AddButton
                                isSecondary={true}
                                onClick={() => {
                                    this.handleAddEventSpaceClick();
                                }}
                            >
                                Add meeting space on this day
                            </AddButton>
                        )}
                    </main>
                )}
            </Pane>
        );
    }
}

export default Day;
