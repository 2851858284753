import React from 'react';

import { useSnackbar } from 'notistack';

import { createMeetingActions } from './store';

export default function LoadCreateMeeting({ id, skip }: { id?: string | number; skip?: boolean }) {
    const { enqueueSnackbar } = useSnackbar();
    const [loadedId, setLoadedId] = React.useState<typeof id>();

    React.useEffect(() => {
        const loadOnce = async () => {
            if (skip) return setLoadedId(id);

            if (id && id !== loadedId) {
                try {
                    await createMeetingActions.load(id);
                    setLoadedId(id);
                } catch (e) {
                    enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
                }
            }
        };
        loadOnce();
    }, [skip, id, loadedId, enqueueSnackbar]);

    return null;
}
