type User = {
    firstName: string;
    lastName: string;
    email: string;
    id: string;
    identify: (arg0: string, arg2: object) => void;
};

const connectFullstory = ({ identify, firstName, lastName, email, id }: User) => {
    identify(id, {
        displayName: `${firstName} ${lastName}`,
        email: email,
    });
};

export default connectFullstory;
