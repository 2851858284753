import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { useParams } from 'react-router';
import { tzMoment, userTimeZone } from 'utils/moment';
import { MeetingSessionStatusCode } from 'amazon-chime-sdk-js';
import { captureException } from '@sentry/browser';
import { useSnackbar } from 'notistack';

import { useMeetingManager, MeetingProvider, darkTheme, useAudioVideo } from 'amazon-chime-sdk-component-library-react';

import { selectMeeting, useMeetings } from 'stores/meetings';
import { useChimeVideo } from 'stores/chime-video';

import { Column, Row, Spacer } from 'ui';
import AgendaTimeTable from 'components/ui/AgendaTimeTable';
import { H5Headline } from 'components/ui/Headline';
import colorFns from 'colorFns';

import AttendeeRoster from './components/AttendeeRoster';
import ChimeVideo from './components/ChimeVideo';
import ChimeChat from 'pages/Meeting/components/ChimeChat';
import CountdownBanner from 'components/CountdownBanner';
import { SpinnerOverlay } from 'components/Spinner';

const InfoCol = styled(Column)`
    width: 480px;
    max-width: 480px;
`;

const Card = styled(Column)`
    position: relative;
    width: 455px;

    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.74)};
    border-radius: 8px;

    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};

    box-sizing: border-box;

    > * {
        padding: 24px;

        &:not(:last-child) {
            border-bottom: 1px solid ${colorFns.softBorder};
        }
    }

    background-color: ${colorFns.brand};
`;

const Providers = ({ children }: { children?: React.ReactNode }) => {
    return (
        <ThemeProvider theme={darkTheme as any}>
            <MeetingProvider>{children}</MeetingProvider>
        </ThemeProvider>
    );
};

const JoinChime = ({
    setJoining,
    setJoined,
    joinInfo,
    onDisconnect,
}: {
    joinInfo: { meetingInfo: any; attendeeInfo: any };
    setJoining: (joining: boolean) => void;
    setJoined: (joined: boolean) => void;
    onDisconnect?: () => void;
}) => {
    const { enqueueSnackbar } = useSnackbar();

    const meetingManager = useMeetingManager();

    const joinMeeting = React.useCallback(async () => {
        if (joinInfo) {
            setJoining(true);
            setJoined(true);

            await meetingManager.join(joinInfo);
            setJoining(false);
        }
    }, [setJoining, setJoined, meetingManager, joinInfo]);

    React.useLayoutEffect(() => {
        joinMeeting();
    }, [joinMeeting]);

    React.useLayoutEffect(() => {
        return () => {
            meetingManager.leave();
        };
    }, [meetingManager, joinInfo]);

    const AV = useAudioVideo();
    React.useEffect(() => {
        if (AV)
            AV.addObserver({
                audioVideoDidStop: reason => {
                    if (reason.statusCode() === MeetingSessionStatusCode.MeetingEnded) {
                        onDisconnect?.();
                    } else {
                        enqueueSnackbar('Meeting ended unexpectedly. Try again.', { variant: 'error' });
                        captureException(reason);
                    }
                },
            });
    }, [AV, onDisconnect, enqueueSnackbar]);

    return null;
};

const titles = {
    video: 'Agenda',
    chat: 'Chat',
    guests: 'Roster',
};

export default function ChimePage({
    view,
    onMeetingEnd,
}: {
    view: 'video' | 'chat' | 'guests';
    onMeetingEnd?: () => void;
}) {
    const { id } = useParams<{ id: string }>();

    const { joinInfo } = useChimeVideo();
    const meeting = useMeetings(selectMeeting(id));

    const [joining, setJoining] = React.useState(false);
    const [joined, setJoined] = React.useState(false);
    const [key, setKey] = React.useState(0);

    const endsAt = meeting?.endsAt;
    const timeZone = meeting?.timeZone;

    React.useEffect(() => {
        let handle: number;
        if (joined && endsAt) {
            const endTime = tzMoment(endsAt, timeZone).tz(userTimeZone);
            const timeLeft = endTime.diff(tzMoment('', userTimeZone), 'ms');

            if (timeLeft <= 1000) {
                onMeetingEnd?.();
            } else {
                handle = setTimeout(() => {
                    onMeetingEnd?.();
                }, timeLeft - 1000);
            }
        }

        return () => {
            if (handle) clearTimeout(handle);
        };
    }, [joined, endsAt, timeZone, onMeetingEnd]);

    if (!joinInfo) return <SpinnerOverlay />;

    return (
        <Providers key={key}>
            <JoinChime setJoining={setJoining} setJoined={setJoined} joinInfo={joinInfo} onDisconnect={onMeetingEnd} />
            <CountdownBanner
                time={tzMoment(meeting?.endsAt, meeting?.timeZone).toDate()}
                showMilliseconds={5 * 60 * 1000}
                message={time => (time ? `Meeting ending in ${time}.` : 'Meeting ended.')}
                warning
            />
            <Spacer large />
            <Row itemSpacing="large">
                <InfoCol itemSpacing="larger" paddingSpacing>
                    <H5Headline>{titles[view]}</H5Headline>
                    {view === 'video' && <Card>{meeting && <AgendaTimeTable agenda={meeting.agenda} />}</Card>}
                    {view === 'guests' && <AttendeeRoster />}
                    {view === 'chat' && <ChimeChat channelArn={meeting?.chimeChat?.channelArn} />}
                </InfoCol>
                <div>
                    <ChimeVideo joinInfo={joinInfo} joining={joining} leave={() => setKey(k => k + 1)} />
                </div>
            </Row>
        </Providers>
    );
}
