import React from 'react';

import styled, { css } from 'styled-components/macro';

import { format, parseISO } from 'date-fns';

import { EventContext } from '../../providers/event';
import { EventOptionsContext } from '../../providers/event-options';

import { AlignedRow, Copy, Spacer, TextArea } from '../../ui';

import Checkbox from '../ui/Checkbox';
import fontFns from 'fontFns';

const AccentedHeadline = styled.h3`
    font-size: 18px;
    letter-spacing: -0.1px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.accentedHeadline)};
`;

type InquiryScheduleProps = {
    inquiryDraft?: { flexibleDates: boolean; notes: string };
    updateDraft: (draftValues: { datesFlexible: boolean; notes: string }) => void;
};

const InquirySchedule = ({ inquiryDraft = { flexibleDates: false, notes: '' }, updateDraft }: InquiryScheduleProps) => {
    const { flexibleDates: datesFlexible, notes } = inquiryDraft;
    const { event, updateSchedule } = React.useContext(EventContext);

    const { avOptions, fbOptions, setupOptions } = React.useContext(EventOptionsContext);

    async function syncDayCheckState(dayIdx: number) {
        const newSchedule = event.schedule.map((day: Bizly.Day, idx: number) => {
            if (idx === dayIdx) {
                let checkNum = 0;
                day.agenda.forEach((agendaItem: Bizly.AgendaItem) => {
                    if (agendaItem.addToInquiry) checkNum++;
                });

                if (day.guestrooms.length && day.guestrooms[0].addToInquiry) checkNum++;

                day.addToInquiry = checkNum !== 0;
            }
            return day;
        });

        updateSchedule(newSchedule);
    }

    async function checkDay(dayIdx: number) {
        if (dayCheckCount() !== 1 || !dayItemChecked(dayIdx)) {
            const newSchedule = event.schedule.map((day: Bizly.Day, idx: number) => {
                if (idx === dayIdx) {
                    const newCheckState = !day.addToInquiry;

                    day.addToInquiry = newCheckState;

                    day.agenda = day.agenda.map((agendaItem: Bizly.AgendaItem) => ({
                        ...agendaItem,
                        addToInquiry: newCheckState,
                    }));

                    day.guestrooms = day.guestrooms.map((guestroom: Bizly.GuestRoom) => ({
                        ...guestroom,
                        addToInquiry: newCheckState,
                    }));
                }
                return day;
            });

            updateSchedule(newSchedule);
        }
    }

    function dayCheckCount() {
        return event.schedule.reduce((count: number, day: Bizly.Day) => (day.addToInquiry ? count + 1 : count), 0);
    }

    function dayItemChecked(dayIdx: number) {
        return event.schedule[dayIdx].addToInquiry;
    }

    function multipleItemsCheckedForOneDay() {
        return event.schedule.length === 1 && multipleItemsChecked();
    }

    function multipleItemsChecked() {
        return getItemsCheckCount() > 1;
    }

    function getItemsCheckCount() {
        return event.schedule
            .flatMap((day: Bizly.Day) => [...day.agenda, ...day.guestrooms])
            .reduce(
                (count: number, agendaOrGuestroomItem: Bizly.AgendaItem | Bizly.GuestRoom) =>
                    agendaOrGuestroomItem.addToInquiry ? count + 1 : count,
                0
            );
    }

    function agendaItemChecked(dayIdx: number, agendaItemIdx: number) {
        return event.schedule[dayIdx].agenda[agendaItemIdx].addToInquiry;
    }

    async function checkAgendaItem(dayIdx: number, agendaItemIdx: number) {
        if (
            (event.schedule.length > 1 && multipleItemsChecked()) ||
            multipleItemsCheckedForOneDay() ||
            !agendaItemChecked(dayIdx, agendaItemIdx)
        ) {
            const newSchedule = event.schedule.map((day: Bizly.Day, idx: number) => {
                if (idx === dayIdx) {
                    day.agenda = day.agenda.map((agendaItem: Bizly.AgendaItem, idx: number) => {
                        if (idx === agendaItemIdx) {
                            agendaItem.addToInquiry = !agendaItem.addToInquiry;
                        }
                        return agendaItem;
                    });
                }
                return day;
            });

            updateSchedule(newSchedule);
            syncDayCheckState(dayIdx);
        }
    }

    function guestRoomItemChecked(dayIdx: number) {
        return event.schedule[dayIdx].guestrooms[0].addToInquiry;
    }

    async function checkGuestRooms(dayIdx: number) {
        if (
            (event.schedule.length > 1 && multipleItemsChecked()) ||
            multipleItemsCheckedForOneDay() ||
            !guestRoomItemChecked(dayIdx)
        ) {
            const newSchedule = event.schedule.map((day: Bizly.Day, idx: number) => {
                if (idx === dayIdx) {
                    day.guestrooms[0] = {
                        ...day.guestrooms[0],
                        addToInquiry: !day.guestrooms[0].addToInquiry,
                    };
                }
                return day;
            });

            updateSchedule(newSchedule);
            syncDayCheckState(dayIdx);
        }
    }

    async function updateNotes(notes: string) {
        updateDraft({
            datesFlexible,
            notes,
        });
    }

    async function updateDatesFlexible(datesFlexible: boolean) {
        updateDraft({
            datesFlexible,
            notes,
        });
    }

    return (
        <div
            css={css`
                flex: 1;
                background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.softAccentedBackground)};

                height: 100%;
                box-sizing: border-box;

                overflow: auto;
            `}
        >
            <AccentedHeadline>Inquiry Details</AccentedHeadline>
            <Copy>
                We’ve automagically pulled the details from your meeting to give you a headstart on your inquiry. Thank
                us later.
            </Copy>
            <Spacer />
            <AlignedRow>
                <Checkbox checked={datesFlexible} onChange={() => updateDatesFlexible(!datesFlexible)} />
                <label
                    css={css`
                        margin: 0 10px;
                    `}
                    htmlFor="dates-flexible"
                >
                    Dates are flexible
                </label>
            </AlignedRow>
            <Spacer />
            <Spacer />
            {event.schedule.map((day: Bizly.Day, idx: number) => (
                <div>
                    <div
                        css={css`
                            height: 60px;
                            background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.agendaDayPane)};
                            border-top-left-radius: 8px;
                            border-top-right-radius: 8px;

                            color: ${({ theme: { getColor, EColors } }) => getColor(EColors.agendaDayPaneText)};

                            display: flex;
                            align-items: center;

                            & > * {
                                margin: 0 10px;
                            }
                        `}
                    >
                        <Checkbox
                            checked={day.addToInquiry}
                            onChange={() => {
                                checkDay(idx);
                            }}
                            name="date"
                        />
                        <label htmlFor="date">Day {idx + 1}</label>
                        {day.startDate && <div>{format(parseISO(day.startDate), 'EEE, LLL dd')}</div>}
                    </div>
                    <div
                        css={css`
                            & > * {
                                border-bottom: 1px solid
                                    ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};

                                &:last-child {
                                    border: none;
                                    border-bottom-left-radius: 8px;
                                    border-bottom-right-radius: 8px;
                                }
                            }
                        `}
                    >
                        {!!day.guestrooms.length && (
                            <div
                                css={css`
                                    display: flex;
                                    align-items: center;
                                    padding: 28px 0;
                                    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};

                                    border-bottom: 1px solid
                                        ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};

                                    & > * {
                                        margin: 0 10px;
                                    }
                                `}
                            >
                                <Checkbox
                                    checked={day.guestrooms[0].addToInquiry}
                                    onChange={() => checkGuestRooms(idx)}
                                />
                                <img
                                    src="https://res.cloudinary.com/hdd626jg7/image/upload/v1562953405/stencil/icons/guest-room.svg"
                                    alt="guestroom-icon"
                                />
                                <div>{day.guestrooms[0].count} Guest rooms</div>
                            </div>
                        )}
                        {day.agenda.map((agendaItem: Bizly.AgendaItem, agendaIdx: number) => (
                            <div
                                css={css`
                                    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
                                    padding-bottom: 28px;
                                `}
                            >
                                <div
                                    css={css`
                                        display: flex;
                                        align-items: center;
                                        padding: 14px 0;

                                        & > * {
                                            margin: 0 10px;
                                        }
                                    `}
                                >
                                    <Checkbox
                                        checked={agendaItem.addToInquiry}
                                        onChange={() => checkAgendaItem(idx, agendaIdx)}
                                    />
                                    <div
                                        css={css`
                                            display: flex;
                                            align-items: center;
                                            padding: 14px 0;
                                            flex-wrap: wrap;

                                            & > * {
                                                margin: 0 10px;
                                            }
                                        `}
                                    >
                                        <img
                                            css={css`
                                                height: 32px;
                                                width: 32px;
                                            `}
                                            src={setupOptions[agendaItem.roomSetup]?.iconUrl || setupOptions[2].iconUrl}
                                            alt="meetingspace-icon"
                                        />
                                        <div>{agendaItem.name || `Meeting Space ${agendaIdx + 1}`}</div>
                                        {day.startDate && agendaItem.startTime && agendaItem.endTime && (
                                            <div>
                                                {`${format(
                                                    parseISO(`${day.startDate} ${agendaItem.startTime}`),
                                                    'h:mm aaaa'
                                                )} - ${format(
                                                    parseISO(`${day.startDate} ${agendaItem.endTime}`),
                                                    'h:mm	aaaa'
                                                )}`}
                                            </div>
                                        )}
                                        <div>
                                            {agendaItem.attendees} {agendaItem.attendees === 1 ? 'Guest' : 'Guests'}
                                        </div>
                                    </div>
                                </div>
                                <AlignedRow
                                    css={css`
                                        width: unset;
                                        margin: 0 40px;
                                        flex-wrap: wrap;

                                        & > * {
                                            margin: 0 16px;
                                        }
                                    `}
                                >
                                    {agendaItem.amenities.av.map(avId => {
                                        const avOption = avOptions.find(avOption => avOption.id === avId);

                                        return <img src={avOption?.iconUrl ?? ''} alt="meetingspace-icon" key={avId} />;
                                    })}
                                    {agendaItem.amenities.foodAndBeverage.map(foodAndBeverageItem => {
                                        const fbOption = fbOptions.find(
                                            fbOption => fbOption.id === foodAndBeverageItem.optionId
                                        );

                                        return (
                                            <img
                                                src={fbOption?.iconUrl ?? ''}
                                                alt="meetingspace-icon"
                                                key={foodAndBeverageItem.optionId}
                                            />
                                        );
                                    })}
                                </AlignedRow>
                            </div>
                        ))}
                        <Spacer />
                    </div>
                </div>
            ))}
            <Spacer />
            <div
                css={css`
                    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formLabel)};
                    ${fontFns.formLabel}
                `}
            >
                Notes
            </div>
            <TextArea
                fullWidth
                value={notes}
                onChange={(e: any) => updateNotes(e.target.value)}
                placeholder="Add additional details that will be helpful to the venue."
            />
        </div>
    );
};

export default InquirySchedule;
