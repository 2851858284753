import { Dialog } from '@material-ui/core';
import colorFns from 'colorFns';
import { useSnackbar } from 'notistack';
import React from 'react';
import { meetingsActions } from 'stores/meetings';
import styled from 'styled-components';
import { Column, Copy, Row } from 'ui';
import { SpinnerOverlay } from './Spinner';
import TextButton from './ui/Button/TextButton';
import { H3Headline } from './ui/Headline';

const ThemedDialog = styled(Dialog)`
    .MuiPaper-root {
        background: ${colorFns.brand};
        color: ${colorFns.pureWhite};

        border: 1px solid ${colorFns.softBorder};
        border-radius: 8px;

        width: 540px;
        height: 290px;
    }
`;

const Content = styled(Column)`
    position: relative;

    width: 100%;
    height: 100%;
`;

const Main = styled(Column)`
    flex: 1 0 0;
`;

const Emojis = styled(Row)`
    > button {
        font-size: 32px;
    }
`;

const Footer = styled(Row)`
    background: ${colorFns.pureWhite.alpha(0.35)};
    padding: 12px 0;
`;

export default function ScoreModal({ meetingId, onRate }: { meetingId: string | number; onRate?: () => void }) {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = React.useState(false);

    const rate = async (score: number) => {
        try {
            setLoading(true);
            await meetingsActions.rate(meetingId, score);
            onRate?.();
        } catch (e) {
            enqueueSnackbar('Something went wrong. Please try again', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <ThemedDialog open>
            <Content alignItems="stretched" justifyContent="center">
                <Main alignItems="center" justifyContent="center" itemSpacing="large">
                    <H3Headline>What did you think of this meeting?</H3Headline>
                    <Emojis alignItems="center" justifyContent="center" itemSpacing="smallish">
                        <TextButton onClick={() => rate(1)}>
                            <span role="img" aria-label="sick">
                                🤢
                            </span>
                        </TextButton>
                        <TextButton onClick={() => rate(2)}>
                            <span role="img" aria-label="cry">
                                😢
                            </span>
                        </TextButton>

                        <TextButton onClick={() => rate(3)}>
                            <span role="img" aria-label="neutral">
                                😐
                            </span>
                        </TextButton>

                        <TextButton onClick={() => rate(4)}>
                            <span role="img" aria-label="slight smile">
                                🙂
                            </span>
                        </TextButton>

                        <TextButton onClick={() => rate(5)}>
                            <span role="img" aria-label="smile">
                                😃
                            </span>
                        </TextButton>

                        <TextButton onClick={() => rate(6)}>
                            <span role="img" aria-label="star eyes">
                                🤩
                            </span>
                        </TextButton>

                        <TextButton onClick={() => rate(7)}>
                            <span role="img" aria-label="celebration">
                                🥳
                            </span>
                        </TextButton>
                    </Emojis>
                </Main>
                <Footer alignItems="center" justifyContent="center">
                    <Copy small>Reminder: All submissions are confidential</Copy>
                </Footer>
                {loading && <SpinnerOverlay delay />}
            </Content>
        </ThemedDialog>
    );
}
