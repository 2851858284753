import React from 'react';
import styled from 'styled-components';

import { Column, Copy } from 'ui';
import { Headliner } from 'components/ui/Headline';

const Container = styled(Column)<{ centerSelf?: boolean }>`
    width: 423px;
    ${({ centerSelf }) =>
        centerSelf
            ? `
    margin: 0px auto 0;
    `
            : ''}
`;

const Card = styled(Column)`
    position: relative;
    border-radius: 8px;

    padding: 30px;
    box-sizing: border-box;
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    border-radius: 8px;
`;

const FooterCopy = styled(Copy)`
    margin-left: 32px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

type TContentCard = {
    headlineCopy?: string;
    children: React.ReactNode;
    footer?: React.ReactNode;
    centerSelf?: boolean;
};

export default function ContentCard({ headlineCopy, children, footer, centerSelf }: TContentCard) {
    return (
        <Container itemSpacing="medium" centerSelf={centerSelf}>
            {headlineCopy && <Headliner>{headlineCopy}</Headliner>}
            <Card itemSpacing="default">{children}</Card>
            {footer && <FooterCopy>{footer}</FooterCopy>}
        </Container>
    );
}
