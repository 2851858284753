import React from 'react';
import styled from 'styled-components';

import { Column, Copy as UICopy, Spacer } from '../../ui';
import { Headline as UIHeadline } from '../../components/ui/Headline';

const Container = styled(Column)``;

const Headline = styled(UIHeadline)`
    margin: 0;
    font-size: 36px;
    letter-spacing: -1.1px;
`;

const Copy = styled(UICopy)`
    font-size: 18px;
    line-height: 1.5;
    max-width: 466px;
`;

const CongratsMessage = () => (
    <Container>
        <Headline large>Congrats!</Headline>
        <Spacer small />
        <Copy large>
            Now that you’ve accepted a proposal, you’ll work directly with the venue to sign a contract, provide payment
            info and finalize all the details of your meeting.
        </Copy>
    </Container>
);

export default CongratsMessage;
