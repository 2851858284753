import React from 'react';
import styled from 'styled-components/macro';

import Dialog from '@material-ui/core/Dialog';

import { Column, Row, Copy, StatusChip } from 'ui';
import { H2Headline } from 'components/ui/Headline';
import { withInteractibleIconStyles } from 'shared';

import { ReactComponent as CloseIconSVG } from 'images/icons/close.svg';
import { ReactComponent as EditIconSVG } from 'images/icons/pencil.svg';

import { EColors } from 'types/themenum';

const EditIcon = withInteractibleIconStyles(EditIconSVG);

const Modal = styled(Column)<{ width: number }>`
    width: ${({ width }) => width}px;
    flex-shrink: 0;
    padding: 24px;
`;

const NoMarginHeadline = styled(H2Headline)`
    margin: 0;
`;

const AutoWidthRow = styled(Row)`
    width: auto;
`;

const CloseIcon = styled(CloseIconSVG)`
    cursor: pointer;
`;

type TRespondent = {
    name: string;
    email: string;
    phone?: string | null;

    status?: string;
    statusColor?: EColors;
};

export default function RespondentModal({
    respondent,
    width = 480,
    content,
    onDismiss,

    onEdit,
    isEditing,
    editingContent,
}: {
    respondent: TRespondent;
    width?: number;
    content?: React.ReactNode;
    onDismiss: () => void;

    onEdit?: () => void;
    isEditing?: boolean;
    editingContent?: React.ReactNode;
}) {
    const { name, email, phone, status, statusColor } = respondent;

    const respondentDetails = (
        <>
            {status && statusColor && <StatusChip style={{ width: 124 }} label={status} statusColor={statusColor} />}
            {email && <Copy>{email}</Copy>}
            {phone && <Copy>{phone}</Copy>}
        </>
    );

    return (
        <Dialog open onClose={onDismiss} maxWidth="xl">
            <Modal width={width}>
                <Column itemSpacing="larger">
                    <Column itemSpacing="small">
                        {isEditing ? (
                            <Row alignItems="center" justifyContent="space-between">
                                <NoMarginHeadline>Edit Details</NoMarginHeadline>
                                <CloseIcon onClick={onDismiss} />
                            </Row>
                        ) : (
                            <Row alignItems="center" justifyContent="space-between">
                                <NoMarginHeadline>{name}</NoMarginHeadline>
                                <AutoWidthRow itemSpacing="smallish">
                                    {onEdit && <EditIcon onClick={onEdit} />}
                                    <CloseIcon onClick={onDismiss} />
                                </AutoWidthRow>
                            </Row>
                        )}
                        {!isEditing && respondentDetails}
                    </Column>

                    {isEditing ? editingContent : content}
                </Column>
            </Modal>
        </Dialog>
    );
}
