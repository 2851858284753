import { getLoggedInUser, updateUser } from 'api';
import createStore from 'stores';

const masks = {
    showVMIntegrations: 0b00001,
    showPublishIntegrations: 0b00010,
};

type Flags = {
    showTeamSettings: boolean;
    showFirstTimeIntegrations: boolean;
    showVMIntegrations: boolean;
    showPublishIntegrations: boolean;
};

type State = { loading: Partial<Flags> } & Flags;
type Store = State;

const initialState: State = {
    loading: {},

    showTeamSettings: false,
    showFirstTimeIntegrations: false,
    showVMIntegrations: false,
    showPublishIntegrations: false,
};

export const [useNudges, nudgesApi] = createStore<Store>(() => initialState);

const { setState, getState } = nudgesApi;

export const nudgesActions = {
    mergeNudges: setState,
    loadIntegrationsPrompts: (shownIntegrationsModal: number, hasIntegrations: boolean) => {
        setState({
            showVMIntegrations: hasIntegrations ? false : !(shownIntegrationsModal & masks.showVMIntegrations),
            showPublishIntegrations: hasIntegrations
                ? false
                : !(shownIntegrationsModal & masks.showPublishIntegrations),
        });
    },
    updateIntegrationsPrompts: async (prompt: keyof Flags, value: boolean) => {
        if (prompt !== 'showVMIntegrations' && prompt !== 'showPublishIntegrations') {
            return;
        }

        if (getState().loading[prompt]) {
            return;
        }

        try {
            setState({
                loading: { ...getState().loading, [prompt]: true },
                [prompt]: value,
            });

            const user = await (getLoggedInUser() as Promise<Bizly.User>);
            const curShownIntegrationsPrompts = user.shownIntegrationsModal || 0;

            const newShownIntegrationsPrompts = !value
                ? curShownIntegrationsPrompts | masks[prompt]
                : curShownIntegrationsPrompts & ~masks[prompt];
            await updateUser({ ...user, shownIntegrationsModal: newShownIntegrationsPrompts });
        } catch (e) {
            throw e;
        }
    },
};
