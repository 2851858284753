import React, { useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import find from 'lodash/find';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import moment from 'moment';

import { isLoading, LoadPlaybook, selectPlaybook, useEventTemplates } from 'stores/eventTemplates';
import { isBlankTemplateId as isBlankPlaybookId } from 'api/eventTemplates';

import { useUser } from 'providers/user';
import { eventTemplatesActions } from 'stores/eventTemplates';
import { LoadEventTemplateTags, useEventTemplateTags } from 'stores/event-template-tags';

import { Row, Spacer, Column, InlineRow, Copy } from 'ui';
import { H5Headline } from 'components/ui/Headline';
import { Spinner, SpinnerOverlay } from 'components/Spinner';
import Button from 'components/ui/Button';
import TextButton from 'components/ui/Button/TextButton';
import SideNav from 'components/SideNav';
import PlaybookDetail, { Tags } from 'components/Playbook/PlaybookDetail';
import ConfirmationModal from 'components/ConfirmationModal';
import { RichTextDisplay } from 'components/Form/fields';
import AgendaTimeTable from 'components/ui/AgendaTimeTable';

import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import colorFns from 'colorFns';

import { ESECTION } from 'components/Templates/utils';
import { usePlaybooks } from 'stores/playbooks';

const _PlusButtonIcon = ({ className }: { className?: string }) => (
    <div className={className}>
        <PlusIcon />
    </div>
);

const PlusButtonIcon = styled(_PlusButtonIcon)`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
    border-radius: 100px;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const IconButton = styled(Button)`
    height: auto;
    padding: 8px 14px;
    border-radius: 8px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
`;

const LeftColumn = styled(Column)`
    max-width: 580px;
`;

const Header = styled(Row)``;

const Card = styled(Column)`
    position: relative;
    width: 455px;

    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    border-radius: 8px;

    box-sizing: border-box;

    > * {
        padding: 24px;

        &:not(:last-child) {
            border-bottom: 1px solid ${colorFns.softBorder};
        }
    }
`;

const sortByMostRecent = (templates: Bizly.EventTemplate[]) =>
    templates.slice().sort((a, b) => (moment(a.createdAt).isAfter(b.createdAt) ? -1 : 1));

const { PERSONAL, SHARED } = ESECTION;

const filterUndef = <T extends any>(items?: (T | undefined)[]): T[] | undefined =>
    items ? (items.filter(t => t !== undefined) as T[]) : items;

const Playbook = ({ playbookId }: { playbookId: string }) => {
    const history = useHistory();
    const { isTeamAdmin, user } = useUser();

    const [hasShareIntent, setHasShareIntent] = useState(false);
    const [hasDeleteIntent, setHasDeleteIntent] = useState(false);
    const { tags } = useEventTemplateTags();
    const tagsDict = keyBy(tags || [], 'id') as Partial<Record<number, BizlyAPI.EventTemplateTag>>;

    const { playbooks } = usePlaybooks();

    const [, setGlobalPlaybooks] = useState<BizlyAPI.Complete.Playbook[]>([]);

    React.useEffect(() => {
        if (playbooks) {
            const playbooksList = Object.values(playbooks);
            const { global = [] } = groupBy(sortByMostRecent(playbooksList), 'categoryName');
            const globalTempsWithoutBlank = global.filter(({ id }) => !isBlankPlaybookId(id));

            setGlobalPlaybooks(globalTempsWithoutBlank.slice(0, 5));
        }
    }, [playbooks]);

    const { templates } = useEventTemplates();
    const template = find(templates, ['id', Number(playbookId)]);
    const playbookFull = useEventTemplates(selectPlaybook(playbookId));
    const loadingAgenda = useEventTemplates(isLoading(playbookId));
    const playbook = template
        ? {
              id: template.id,
              name: template.name,
              tags: filterUndef(template.tags?.map(tag => tagsDict[tag.id])),
              purpose: playbookFull?.purpose ?? template.objectiveDescription,
              image: template.briefImageLarge ?? template.briefImageSmall,
              description: template.longDescription ?? template.shortDescription,
          }
        : {};

    const handleCreateMeetingButtonClick = async () => {
        if (!playbook.id) return;
        return history.push(`/meetings/new`, { playbookId: playbook.id });
    };

    const isUsersPlaybook = template?.category === PERSONAL && template?.userId === user.id;
    const userCanSharePlaybook = isUsersPlaybook && isTeamAdmin;
    const userCanDeletePlaybook = isUsersPlaybook || (isTeamAdmin && template?.category === SHARED);

    const handleSharePlaybook = () => {
        setHasShareIntent(false);
        return playbook.id && eventTemplatesActions.share(playbook.id);
    };

    const handleDeletePlaybook = () => {
        setHasDeleteIntent(false);
        return (
            playbook.id &&
            eventTemplatesActions.delete(playbook.id).then(() => {
                history.push('/playbooks');
            })
        );
    };

    const renderedAgendaDescription = RichTextDisplay({ value: playbookFull?.agendaDescription || '' });
    const hasAgendaCard = (playbookFull?.agenda && playbookFull?.agenda.length > 0) || renderedAgendaDescription;

    if (!template)
        return (
            <SideNav fillWidth fullHeight>
                <SpinnerOverlay />
            </SideNav>
        );

    return (
        <SideNav fullHeight>
            <LoadEventTemplateTags />
            <LoadPlaybook id={playbookId} />
            <Header itemSpacing="medium">
                <div>
                    <InlineRow alignItems="center" itemSpacing="smallish">
                        <IconButton width="auto" onClick={handleCreateMeetingButtonClick}>
                            <Row alignItems="center" itemSpacing="small">
                                <PlusButtonIcon />
                                <Copy>Create Meeting</Copy>
                            </Row>
                        </IconButton>
                        {userCanSharePlaybook && (
                            <TextButton onClick={() => setHasShareIntent(true)} secondary>
                                Share
                            </TextButton>
                        )}
                        {userCanDeletePlaybook && (
                            <TextButton onClick={() => setHasDeleteIntent(true)} warning>
                                Delete
                            </TextButton>
                        )}
                    </InlineRow>
                </div>
                <Tags tags={playbook.tags} />
            </Header>
            <Spacer smallish />
            <H5Headline>{playbook.name}</H5Headline>
            <Spacer larger />
            <Row itemSpacing="largest" alignItems="stretch">
                <LeftColumn itemSpacing="large">
                    <PlaybookDetail playbook={playbook} />
                </LeftColumn>
                <Column>
                    {loadingAgenda ? (
                        <Spinner />
                    ) : (
                        hasAgendaCard && (
                            <Card>
                                {renderedAgendaDescription && <div>{renderedAgendaDescription}</div>}
                                {playbookFull?.agenda && <AgendaTimeTable agenda={playbookFull.agenda} />}
                            </Card>
                        )
                    )}
                </Column>
            </Row>

            <ConfirmationModal
                headline="Share Playbook with Team"
                onDismiss={() => setHasShareIntent(false)}
                onProceed={handleSharePlaybook}
                prompt="By sharing a playbook, all members of your team will have access to it when creating a future meeting."
                isActive={hasShareIntent}
            />
            <ConfirmationModal
                warning
                headline="Delete Playbook"
                onDismiss={() => setHasDeleteIntent(false)}
                onProceed={handleDeletePlaybook}
                prompt="By deleting this playbook you will be removing it from your Playbooks. If it is shared with a team, they will no longer have access to it as well."
                isActive={hasDeleteIntent}
            />
        </SideNav>
    );
};

export default Playbook;
