import React from 'react';

import Form from 'components/Form';
import { Column, Copy } from 'components/../ui';

import {
    TOption,
    TOptionsDictionary,
    TFBOption,
    TFBRequest,
    TFBResponse,
    TFBOptionRequest,
    sortFBRequest,
    optionsToListForm,
    EVENT_SPACES_REQUEST_WIDTH,
    OPTIONS_RADIO_CHECKBOX_WIDTH,
} from './utils';

// FB means food/beverage
// DS meand dining style

/*
    A FB value looks like:

    Form Value: {
        proposedFb: [{fbOptionId: 1, diningStyleId: 2, response: true/false}]
    }

    FBForm made of FBFields and FBSchema can be used for this form value
    requestedFb and proposedFb can be represented with FBField which is just a list of FBOptionField:

    Form Value: {
        proposedFb: FBField or FBOptionField[]
    }
*/

const RequestLabel = ({ fbName, dsName, hasDiningStyle }: TFBOptionRequest) => (
    <Column>
        <Copy>{fbName}</Copy>
        {hasDiningStyle && <Copy small>{dsName}</Copy>}
    </Column>
);

const FBOptionFields = ({
    DSOptions,
    isSelected,
}: {
    DSOptions?: TOption[];
    showPrompts?: boolean; // we show prompts when any FB option is selected
    isFirstRow?: boolean; // we only show prompts for the first row
    isSelected?: boolean;
}) => ({
    response: {
        type: 'checkbox',
        fixedWidth: OPTIONS_RADIO_CHECKBOX_WIDTH,
        options: {
            asRadio: true,
        },
    },

    diningStyleId: {
        type: DSOptions ? 'select' : 'display',
        perRow: 4,
        disabled: !isSelected,
        options: {
            options: DSOptions,
            label: '-',
        },
    },

    averageCost: {
        type: isSelected ? 'text' : 'display',
        perRow: 3,
        options: {
            placeholder: '$',
        },
    },
});

const FBOptionSchema = ({
    request,
    onlyShowRequest,
    showDiningStyle,
}: {
    request: TFBOptionRequest;
    onlyShowRequest?: boolean;
    showDiningStyle?: boolean;
}) => [
    {
        key: request.fbId,
        fields: [
            {
                type: 'display',
                fixedWidth: EVENT_SPACES_REQUEST_WIDTH,
                options: {
                    label: <RequestLabel {...request} />,
                    iconUrl: request.fbIconUrl,
                    iconAlt: request.fbName,
                },
            },
            ...(onlyShowRequest ? [] : ['response', ...(showDiningStyle ? ['diningStyleId'] : [])]),
        ],
        itemSpacing: 'default',
        spacing: false,
    },
];

const FBOptionHeadingsSchema = (onlyShowRequest?: boolean, hasDiningStyle?: boolean, hasAverageCost?: boolean) => {
    const checkboxHeading = {
        type: 'none',
        fixedWidth: OPTIONS_RADIO_CHECKBOX_WIDTH,
    };

    const diningStyleArr = hasDiningStyle
        ? [
              {
                  type: 'none',
                  prompt: 'Dining Style',
                  perRow: 4,
              },
          ]
        : [];

    return {
        fields: [
            {
                type: 'none',
                prompt: 'Food/Beverage',
                fixedWidth: EVENT_SPACES_REQUEST_WIDTH,
            },

            ...(onlyShowRequest ? [] : [checkboxHeading, ...diningStyleArr]),
        ],
        itemSpacing: 'default',
    };
};

const FBOptionField = ({
    field,
    value,
    onChange,
    errors,
    disabled,

    request,
    DSOptions,
    showDiningStyle,
    onlyShowRequest,
}: {
    field: string;
    value: { fbOptionId?: number; diningStyleId?: number; response?: boolean };
    onChange: any;
    errors: any;
    request: TFBOptionRequest;
    DSOptions: TOptionsDictionary<TOption>;
    showDiningStyle?: boolean;
    onlyShowRequest?: boolean;
    disabled?: boolean;
}) => {
    const isSelected = value?.response;

    const fieldProps = {
        DSOptions: request.hasDiningStyle ? Object.values(DSOptions) : undefined,
        isSelected,
    };

    const schemaProps = {
        request,
        onlyShowRequest,
        showDiningStyle,
    };

    const formattedValue = {
        fbOptionId: request.fbId,
        diningStyleId: request?.dsId,
        ...value,
    };

    return (
        <Form
            fields={FBOptionFields(fieldProps)}
            schema={FBOptionSchema(schemaProps)}
            value={formattedValue}
            errors={errors}
            disabled={disabled}
            onChange={({ value, errors }: any) =>
                onChange({
                    field,
                    value,
                    errors,
                })
            }
        />
    );
};

const FBOptionsField = ({
    value,
    options,
    DSOptions,
    onlyShowRequest,
    ...otherProps
}: {
    field: string;
    value: TFBResponse[];
    readonly?: boolean;
    onChange: ({ field, value }: { field: string; value: TFBResponse[] }) => void;
    options: TFBOptionRequest[];
    DSOptions?: TOptionsDictionary<TOption>;
    onlyShowRequest?: boolean;
}) => {
    const hasDiningStyle = options.some(o => o.hasDiningStyle);
    const anySelected = value.filter(v => v?.response).length > 0;

    const form = optionsToListForm({
        ...otherProps,
        value,
        getValueId: v => v.fbOptionId.toString(),

        options,
        getOptionId: o => o.fbId.toString(),
        optionToField: o => ({
            type: FBOptionField,
            options: {
                request: o,
                DSOptions,
                showDiningStyle: hasDiningStyle,
                onlyShowRequest,
            },
        }),

        optionToSchema: () => ({ spacing: 'xsmall' }),
        headingsSchema: FBOptionHeadingsSchema(onlyShowRequest, hasDiningStyle, anySelected),
    });

    return form;
};

const requestedFbToFieldOptions = (
    request: TFBRequest[],
    fbOptions: TOptionsDictionary<TFBOption>,
    dsOptions: TOptionsDictionary<TOption>
) => {
    const sortedRequest = sortFBRequest(request);

    return sortedRequest.map(o => {
        const { id: fbId, name: fbName, iconUrl: fbIconUrl, hasDiningStyle } = fbOptions[o.fbOptionId];

        const { id: dsId, name: dsName, iconUrl: dsIconUrl } = o.diningStyleId
            ? dsOptions[o.diningStyleId]
            : ({} as Partial<TOption>);

        return {
            fbId,
            fbName,
            fbIconUrl,
            hasDiningStyle,
            dsId,
            dsName,
            dsIconUrl,
        };
    });
};

// FBForm

export const FBFields = (
    requestedFbIds: TFBRequest[],
    FBOptions: TOptionsDictionary<TFBOption>,
    DSOptions: TOptionsDictionary<TOption>,
    onlyShowRequest?: boolean
) => ({
    proposedFb: {
        type: FBOptionsField,
        options: {
            options: requestedFbToFieldOptions(requestedFbIds, FBOptions, DSOptions),
            DSOptions: Object.values(DSOptions),
            onlyShowRequest,
        },
    },
});

export const FBSchema = [
    {
        key: 'fbOptions',
        fields: ['proposedFb'],
        spacing: false,
    },
];
