import { get, put, post, deleteCall } from '.';

export const addAttendees = (eventId: string | number, attendees: Array<{ email: string }>) =>
    post(`events/${eventId}/attendees/batch`, { attendees }).then(json => json.attendees) as Promise<
        Bizly.EventAttendee[]
    >;

export const exportAttendees = (eventId: number | string) =>
    get(`events/${eventId}/attendees/export`).then(res => res.url);

export const loadAttendees = (eventId: number | string) =>
    get(`events/${eventId}/attendees`).then(json => json.attendees) as Promise<Bizly.EventAttendee[]>;

export const removeAttendee = (eventId: number | string, attendee: BizlyAPI.Attendee) =>
    deleteCall(`events/${eventId}/attendees/${attendee.id}`);

export const updateAttendee = (eventId: number | string, attendee: Bizly.EventAttendee) =>
    put(`events/${eventId}/attendees/${attendee.id}`, attendee);

export const quickInviteAttendee = (meetingId: string | number, email: string) =>
    post(`meetings/${meetingId}/send-invite`, { email }) as Promise<{ meeting: BizlyAPI.Meeting }>;

export const acceptInvite = (meetingId: string | number) =>
    post(`meetings/${meetingId}/accept-invite`) as Promise<{ attendees: Bizly.EventAttendee[] }>;

export const rejectInvite = (meetingId: string | number) =>
    post(`meetings/${meetingId}/reject-invite`) as Promise<{ attendees: Bizly.EventAttendee[] }>;
