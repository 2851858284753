import ColorModel from 'color';

import { BizlyColors, AppleColors } from 'themes';
import { CSSProperties } from 'react';

import { createMuiTheme, makeStyles } from '@material-ui/core/styles';

import { ETeams, EColors, EFonts } from 'types/themenum';
export { ETeams, EColors, EFonts };

/*

    DEFINITIONS

*/

type ColorsMapping = Record<EColors, string>;

const THEMED_COLORS: Record<ETeams, ColorsMapping> = {
    [ETeams.bizly]: BizlyColors,
    [ETeams.apple]: AppleColors,
};

type FontsMapping = Record<EFonts, CSSProperties>;

const FALLBACK_FONTS = [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    '"Roboto"',
    '"Oxygen"',
    '"Ubuntu"',
    '"Cantarell"',
    '"Fira Sans"',
    '"Droid Sans"',
    '"Helvetica Neue"',
    'sans-serif',
];

const THEMED_FONTS: Record<ETeams, FontsMapping> = {
    [ETeams.bizly]: {
        body: {
            fontFamily: ['"Sofia-Pro"', ...FALLBACK_FONTS].join(', '),
        },
        heading: {
            fontFamily: ['"Sofia-Pro"', ...FALLBACK_FONTS].join(', '),
        },
        formLabel: {
            fontFamily: ['"Sofia-Pro"', ...FALLBACK_FONTS].join(', '),
        },
        formHeading: {
            fontFamily: ['"Sofia-Pro"', ...FALLBACK_FONTS].join(', '),
        },
    },
    [ETeams.apple]: {
        body: {
            fontFamily: ['"SF Text"', ...FALLBACK_FONTS].join(', '),
        },
        heading: {
            fontFamily: ['"SF Display"', ...FALLBACK_FONTS].join(', '),
        },
        formLabel: {
            fontFamily: ['"SF Text"', ...FALLBACK_FONTS].join(', '),
            fontWeight: 600,
        },
        formHeading: {
            fontFamily: ['"SF Text"', ...FALLBACK_FONTS].join(', '),
        },
    },
};

/*

    HELPERS

*/

let theme: ETeams = ETeams.bizly;

export const setColorTheme = (newTheme: ETeams = ETeams.bizly) => (theme = newTheme);

export function getColor(
    color: EColors,
    opacity: number = 1,
    { themeOverride, asSolidHex }: { themeOverride?: ETeams; asSolidHex?: boolean } = {}
) {
    const colorObj = ColorModel(THEMED_COLORS[themeOverride || theme][color] || THEMED_COLORS.bizly.pureBlack);

    // Color library may support alpha hex (#RGBA or #ARGB) eventually but we only need solid hex for ie11
    if (asSolidHex) return colorObj.alpha(1).hex() as Themed.Color;

    return `${colorObj
        .rgb()
        .alpha(opacity)
        .string()}` as Themed.Color;
}

export const getFontStyle = (font: EFonts, themeOverride?: ETeams) =>
    THEMED_FONTS[themeOverride || theme][font] as Themed.Font;

export const getStyledTheme = () => ({
    getColor,
    getFontStyle,
    EColors,
    EFonts,
    theme,
});

export type TStyledTheme = ReturnType<typeof getStyledTheme>;

type TGetColor = typeof getColor;
type TGetFontStyle = typeof getFontStyle;

export const bizlyStyledTheme = () => {
    const getBizlyColor: TGetColor = (color, opacity = 1, options) => {
        return getColor(color, opacity, { ...options, themeOverride: ETeams.bizly });
    };

    const getBizlyFontStyle: TGetFontStyle = font => {
        return getFontStyle(font, ETeams.bizly);
    };

    return {
        getColor: getBizlyColor,
        EColors,
        getFontStyle: getBizlyFontStyle,
        EFonts,
        theme: ETeams.bizly,
    };
};

function isThemedColor(color: any): color is Themed.Color {
    if (typeof color !== 'string') return false;

    try {
        ColorModel(color);
        return true;
    } catch (e) {
        return false;
    }
}

export type TColorStyledFn = {
    $color?: EColors | Themed.Color | undefined;
    $colorAlpha?: number;
};

export const colorStyledFn = ({ $color, $colorAlpha }: TColorStyledFn) => {
    return $color
        ? `
    color: ${isThemedColor($color) ? $color : getColor($color, $colorAlpha)};
`
        : '';
};

export const materialUIThemeStyledFn = ({ theme }: { theme: TStyledTheme }) => {
    const { getColor, EColors, getFontStyle, EFonts } = theme;
    return {
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: getColor(EColors.primaryAction),
                    contrastText: getColor(EColors.pureWhite),
                },
                secondary: {
                    main: getColor(EColors.primaryAction),
                },
            },
            overrides: {
                // So that our modal fades white instead of black
                MuiBackdrop: {
                    // Name of the component ⚛️ / style sheet
                    root: {
                        // Name of the rule
                        backgroundColor: getColor(EColors.brand, 0.85), // Some CSS
                    },
                },
                // Per designs with larger text than MUI default
                MuiTableCell: {
                    body: {
                        fontSize: '15px',
                    },
                    head: {
                        fontSize: '15px',
                    },
                },
                MuiOutlinedInput: {
                    notchedOutline: {
                        borderRadius: '8px',
                    },
                    root: {
                        '&$focused': {
                            '&& > fieldset': {
                                // TODO: Maximum hax. This cannot be the normal way we override Material UI styles
                                borderColor: `${getColor(EColors.pureWhite, 0.8)} !important`,
                                borderWidth: '2px !important',
                            },
                        },
                        '&:hover': {
                            '&& > fieldset': {
                                borderColor: `${getColor(EColors.pureWhite, 0.8)} !important`,
                            },
                        },
                    },
                },
                MuiButton: {
                    outlinedPrimary: {
                        borderColor: getColor(EColors.primaryAction),
                        '&:hover': {
                            borderColor: getColor(EColors.primaryAction),
                        },
                    },
                },
                MuiSnackbarContent: {
                    root: {
                        '&[class*="variantInfo"]': {
                            backgroundColor: getColor(EColors.snackbarInfo),
                        },
                        '&[class*="variantError"]': {
                            background: 'none',
                            border: `5px solid ${getColor(EColors.snackbarError)}`,
                        },
                        '&[class*="variantSuccess"]': {
                            backgroundColor: getColor(EColors.snackbarSuccess),
                        },
                    },
                },
            },
            props: {
                MuiButtonBase: {
                    disableRipple: true,
                },
            },
            // https://material-ui.com/style/typography/#migration-to-typography-v2
            typography: {
                button: {
                    textTransform: 'none',
                },
                ...getFontStyle(EFonts.body),
            },
            ...theme,
        }),
    };
};

export const useSnackbarStyles = makeStyles(({ getColor, EColors }) => ({
    variantSuccess: {
        backgroundColor: getColor(EColors.snackbarSuccess),
    },
    variantError: {
        backgroundColor: 'none',
    },
    variantInfo: {
        backgroundColor: getColor(EColors.snackbarInfo),
    },
}));

export const useBizlySnackbarStyles = makeStyles(({ getColor, EColors }) => ({
    variantSuccess: {
        backgroundColor: getColor(EColors.snackbarSuccess, 1, { themeOverride: ETeams.bizly }),
    },
    variantError: {
        backgroundColor: getColor(EColors.snackbarError, 1, { themeOverride: ETeams.bizly }),
    },
    variantInfo: {
        backgroundColor: getColor(EColors.snackbarInfo, 1, { themeOverride: ETeams.bizly }),
    },
}));
