import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Menu from '@material-ui/core/Menu';

import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import SmallCircleButton from 'components/ui/SmallCircleButton';
import Button, { buttonColorsStyledFn } from 'components/ui/Button';
import colorFns from 'colorFns';
import { ButtonProps } from '@material-ui/core';

const ListItemButtonWrap = styled(Button)<{ isSecondary?: boolean }>`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    height: auto;
    width: 100%;

    padding: 12px;
    min-height: 52px;
    box-sizing: border-box;

    color: ${colorFns.pureBlack};
    background-color: ${({ isSecondary, theme: { getColor, EColors } }) =>
        getColor(isSecondary ? EColors.brand : EColors.brand)} !important;
    border-color: ${({ isSecondary, theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.74)} !important;
    color: ${({ isSecondary, theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.74)} !important;
`;

const NestedCircleButton = styled(SmallCircleButton)`
    margin-right: 8px;
    ${ListItemButtonWrap}:hover & {
        ${props => buttonColorsStyledFn(props)['&:hover']}
    }
`;

export const LongButtonMenu = ({
    children,
    label,
    isSecondary,
    ...props
}: {
    children: React.ReactNode;
    label?: React.ReactNode;
    isSecondary?: boolean;
}) => {
    const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
    const anchorRef = useRef<HTMLButtonElement>(null);

    const handleClick = () => setMenuAnchor(menuAnchor ? null : anchorRef.current);

    return (
        <>
            <LongButton {...props} isSecondary={isSecondary} ref={anchorRef} onClick={handleClick}>
                {label}
            </LongButton>
            <Menu
                anchorEl={menuAnchor}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                }}
                getContentAnchorEl={null}
                open={!!menuAnchor}
                onClick={() => setMenuAnchor(null)}
                onClose={() => setMenuAnchor(null)}
            >
                {children}
            </Menu>
        </>
    );
};

const LongButton = React.forwardRef(
    (
        {
            children,
            isSecondary,
            nonplussed,
            ...props
        }: {
            children: React.ReactNode;
            isSecondary?: boolean;
            disabled?: boolean;
            onClick: ButtonProps['onClick'];
            nonplussed?: boolean;
        },
        ref: React.Ref<HTMLButtonElement>
    ) => (
        <ListItemButtonWrap {...props} isSecondary={isSecondary} variant="outlined" ref={ref}>
            {!nonplussed && (
                <NestedCircleButton as="div">
                    <PlusIcon />
                </NestedCircleButton>
            )}
            {children}
        </ListItemButtonWrap>
    )
);

export default LongButton;
