export const fields = {
    guests: {
        prompt: 'Total Guests',
        type: 'text',
        options: { placeholder: 'Optional' },
    },
    guestroomNights: {
        prompt: 'Total Guest Room Nights',
        type: 'text',
        options: { placeholder: 'Optional' },
    },
    guestroomCost: {
        prompt: 'Estimated Guest Room Costs',
        type: 'text',
        options: { placeholder: 'Optional', currency: true },
    },
    roomCost: {
        prompt: 'Estimated Room Rental',
        type: 'text',
        options: { placeholder: 'Optional', currency: true },
    },
    cateringCost: {
        prompt: 'Estimated Food & Beverage',
        type: 'text',
        options: { placeholder: 'Optional', currency: true },
    },
    total: {
        prompt: 'Estimated Total',
        type: 'text',
        options: { placeholder: '$', currency: true },
    },
};

export const schema = [
    {
        key: 'rowOne',
        fields: ['guests', 'guestroomNights'],
        spacing: 'medium',
    },
    {
        key: 'rowTwo',
        fields: ['guestroomCost', 'roomCost'],
        spacing: 'medium',
    },
    {
        key: 'rowThree',
        fields: ['cateringCost', 'total'],
        spacing: false,
    },
];

export const schemaWithoutGuestrooms = [
    {
        key: 'rowOne',
        fields: ['guests', 'roomCost'],
        spacing: 'medium',
    },
    {
        key: 'rowTwo',
        fields: ['cateringCost', 'total'],
        spacing: false,
    },
];
