import React from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { emailIsValid } from 'utils';

import { useUser } from 'providers/user';
import { LoadTeamMembers, teamActions, useTeam } from 'stores/team-members';

import { Column, Copy, InlineRow, Row } from 'ui';
import { SelectField as FormSelectField } from 'components/FormFields';
import { LabeledTextField } from 'components/FormFields';
import Button from 'components/ui/Button';
import { Spinner } from 'components/Spinner';
import Table from 'components/ui/Table';

import { ReactComponent as DeleteIconSVG } from 'images/icons/trash-can.svg';
import { withInteractibleIconStyles } from 'shared';
import colorFns from 'colorFns';

const DeleteIcon = styled(withInteractibleIconStyles(DeleteIconSVG))`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};
`;

const SelectField = styled(FormSelectField)`
    min-width: 160px;
    .MuiSelect-root {
        color: ${colorFns.secondaryTextAction};
    }

    &&&&& > fieldset {
        border: 1px solid ${colorFns.secondaryTextAction}!important;
    }

    border-radius: 8px;
`;

const AddAttendee = () => {
    const { members = [], adding, updating, deleting } = useTeam();
    const { enqueueSnackbar } = useSnackbar();

    const [email, setEmail] = React.useState<string | undefined>();

    const addMember = async () => {
        if (!email?.trim()) return;
        if (!emailIsValid(email.trim()))
            return enqueueSnackbar('Invalid email.', {
                variant: 'error',
            });
        if (members.find(member => member.user.email === email.trim()))
            return enqueueSnackbar('Already a member', {
                variant: 'error',
            });

        try {
            await teamActions.add(email);
            setEmail(undefined);
        } catch {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    };

    return (
        <Row alignItems="center" justifyContent="flex-end">
            <InlineRow alignItems="center" itemSpacing="small">
                {(adding || updating || deleting) && <Spinner delay unsetMargin size={30} />}

                <div style={{ maxWidth: 300 }}>
                    <LabeledTextField
                        outlined
                        placeholder="Invite member by email"
                        field=""
                        value={email}
                        onChange={({ value }: { value: string }) => {
                            if (!adding) setEmail(value);
                        }}
                        onKeyPress={(e: React.KeyboardEvent) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                addMember();
                            }
                        }}
                    />
                </div>
                <Button onClick={addMember} disabled={adding || !emailIsValid(email?.trim() ?? '')}>
                    Invite
                </Button>
            </InlineRow>
        </Row>
    );
};

const status = {
    1: 'Active',
    2: 'Invited',
    4: 'Rejected',
};

export default function MembersTable({ className }: { className?: string }) {
    const { members = [], updating, deleting } = useTeam();
    const { user, isTeamAdmin } = useUser();

    const membersSimple = (members ?? []).map(member => ({
        role: member.role.id,
        ...member.user,
        fullName: [member.user.firstName, member.user.lastName].join(' ').trim() || undefined,
        status: member.status.int,
        raw: member,
    }));

    const { enqueueSnackbar } = useSnackbar();

    const updateMember = async (member: BizlyAPI.TeamMember) => {
        try {
            await teamActions.update(member);
        } catch {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    };

    const deleteMember = async (member: BizlyAPI.TeamMember) => {
        try {
            await teamActions.delete(member.id);
        } catch {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    };

    const mainCols = [
        { rowKey: 'fullName', label: 'Name' },
        { rowKey: 'role', label: 'Role', width: 160 },
        { rowKey: 'status', label: 'Status', width: 120 },
    ] as const;

    return (
        <Column className={className}>
            {isTeamAdmin && <AddAttendee />}
            {user.team?.id && <LoadTeamMembers teamId={user.team.id} />}
            <Table
                columnTitles={isTeamAdmin ? [...mainCols, { rowKey: 'buttons', label: '', width: 30 }] : mainCols}
                rowValues={membersSimple}
                dynamicColumns={{
                    fullName: (fullName, member) =>
                        fullName ? (
                            <>
                                <Copy>{fullName}</Copy>
                                <Copy small>{member.email}</Copy>
                            </>
                        ) : (
                            <Copy>{member.email}</Copy>
                        ),
                    role: (role, rowVal) => (
                        <SelectField
                            field=""
                            value={role}
                            onChange={({ value }: { value: React.ChangeEvent<HTMLInputElement> }) => {
                                updateMember({
                                    ...rowVal.raw,
                                    role: { id: (value.target.value as unknown) as number },
                                });
                            }}
                            options={[
                                { id: 100, name: 'Team Admin' },
                                { id: 200, name: 'Team Member' },
                            ]}
                            disabled={updating || deleting}
                            readonly={!isTeamAdmin}
                        />
                    ),
                    status: statusId => status[statusId as keyof typeof status],
                    buttons: (_, rowVal) => (
                        <DeleteIcon
                            onClick={() => {
                                deleteMember(rowVal.raw);
                            }}
                        />
                    ),
                }}
                placeholder="-"
            />
        </Column>
    );
}
