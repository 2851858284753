import React from 'react';
import styled from 'styled-components/macro';

const MessageRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
`;

const Bubble = styled.div`
    width: 303px;
    padding: 18px 16px 17px 16px;
    border-radius: 8px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.warningBackground)};
    font-size: 13px;

    p {
        margin: 0;
    }
`;

const FailedMessage = () => (
    <MessageRow>
        <Bubble>Your message couldn’t be sent. Try again.</Bubble>
    </MessageRow>
);

export default FailedMessage;
