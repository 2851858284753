import createStore from 'zustand';

import { withCache } from 'stores/utils';

import { getSchedule, addScheduleItem, deleteScheduleItem } from 'api';

type State = {
    loading: boolean;
    pendingItems: BizlyAPI.ScheduleBlock[];
    newId: number;
} & (
    | {
          loaded: false;
          eventId?: number | string;
          items: null;
      }
    | {
          loaded: true;
          eventId: number | string;
          items: BizlyAPI.ScheduleItem[];
      }
);
type Store = State;

const initialState: State = {
    items: null,
    loaded: false,
    loading: false,
    pendingItems: [],
    newId: -1,
};

export const [useSchedule, scheduleStoreApi] = createStore<Store>(() => initialState);
const loadWithCache = withCache({
    storeApi: scheduleStoreApi,
    cacheFn: state => (state.items ? { items: state.items, eventId: state.eventId } : {}),
    key: 'eventId',
    shouldCache: state => !!state.items,
});

const isolateScheduleBlocks = (items: BizlyAPI.ScheduleItem[]) =>
    items.filter(({ guestrooms, spaces }) => guestrooms.length === 0 && spaces.length === 0);

const { setState, getState } = scheduleStoreApi;
export const scheduleActions = {
    load: loadWithCache(async (eventId: number | string) => {
        let { eventId: curEventId } = getState();
        setState({
            loading: true,
            ...(eventId !== curEventId
                ? {
                      items: null,
                      eventId,
                      loaded: false,
                  }
                : {}),
        });

        try {
            const { scheduleItems } = await getSchedule(eventId);

            ({ eventId: curEventId } = getState());
            if (curEventId === eventId)
                setState({
                    items: isolateScheduleBlocks(scheduleItems),
                    eventId,
                    loaded: true,
                    loading: false,
                });

            return scheduleItems;
        } catch (e) {
            setState({
                loading: false,
            });
            throw e;
        }
    }),

    mergeItem: (eventId: number | string, updatedItem: BizlyAPI.ScheduleItem) => {
        let currentState = getState();
        if (eventId !== currentState.eventId || !currentState.loaded) return;

        setState({
            items: [...currentState.items.filter(item => item.id !== updatedItem.id), updatedItem],
        });
    },

    add: async (eventId: number | string, data: Omit<BizlyAPI.ScheduleBlock, 'id'>) => {
        let currentState = getState();
        if (eventId !== currentState.eventId || !currentState.loaded) return;

        const savedId = currentState.newId;
        const draftItem = { ...data, id: savedId, addToInquiry: false };
        setState({
            pendingItems: [...currentState.pendingItems, draftItem],
            newId: savedId - 1,
        });

        try {
            const { scheduleItem: newItem } = await addScheduleItem(eventId, draftItem);

            currentState = getState();
            if (eventId !== currentState.eventId || !currentState.loaded) return;

            setState({
                pendingItems: currentState.pendingItems.filter(item => item.id !== savedId),
                items: [...(currentState.items ?? []), newItem],
            });

            return newItem;
        } catch (e) {
            currentState = getState();
            if (eventId !== currentState.eventId || !currentState.loaded) return;

            setState({ pendingItems: currentState.pendingItems.filter(item => item.id !== savedId) });
            throw e;
        }
    },

    remove: (eventId: number | string, targetId: number) => {
        let currentState = getState();
        if (eventId !== currentState.eventId || !currentState.loaded) return;

        const toDelete = currentState.items.find(item => item.id === targetId);
        if (!toDelete) return;

        setState({
            items: currentState.items.filter(item => item.id !== targetId),
        });
    },

    delete: async (eventId: number | string, targetId: number) => {
        let currentState = getState();
        if (eventId !== currentState.eventId || !currentState.loaded) return;

        const toDelete = currentState.items.find(item => item.id === targetId);
        if (!toDelete) return;
        setState({
            items: currentState.items.filter(item => item.id !== targetId),
            pendingItems: [...currentState.pendingItems, toDelete],
        });

        try {
            const { scheduleItems } = await deleteScheduleItem(eventId, targetId);

            currentState = getState();
            if (eventId !== currentState.eventId || !currentState.loaded) return;

            setState({ pendingItems: currentState.pendingItems.filter(item => item.id !== targetId) });

            return scheduleItems;
        } catch (e) {
            currentState = getState();
            if (eventId !== currentState.eventId || !currentState.loaded) return;

            setState({
                items: [...currentState.items, toDelete],
                pendingItems: currentState.pendingItems.filter(item => item.id !== targetId),
            });
            throw e;
        }
    },
};

export const isPendingId = (id: number) => getState().pendingItems.some(item => item.id === id);
