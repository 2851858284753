import React from 'react';

type Action<T> = { type: 'LOADING' } | { type: 'LOADED'; data: T } | { type: 'ERROR'; error: Error };

type AsyncCallback<T> = () => Promise<T>;

function asyncReducer<T>(state: State<T>, action: Action<T>) {
    switch (action.type) {
        case 'LOADING': {
            return { loading: true, data: state.data, error: undefined };
        }
        case 'LOADED': {
            return { loading: false, data: action.data, error: undefined };
        }
        case 'ERROR': {
            return { loading: false, data: state.data, error: action.error };
        }
    }
}

type State<T> = {
    data?: T;
    loading: boolean;
    error?: Error;
};

export function useAsync<T>(asyncCallback: AsyncCallback<T>, initialState?: T) {
    const [state, dispatch] = React.useReducer<React.Reducer<State<T>, any>>(asyncReducer, {
        data: initialState,
        loading: false,
    });
    React.useEffect(() => {
        dispatch({ type: 'LOADING' });
        asyncCallback().then(
            data => {
                dispatch({ type: 'LOADED', data });
            },
            (error: Error) => {
                dispatch({ type: 'ERROR', error });
            }
        );
    }, [asyncCallback]);
    return state;
}
