import { get, post, put, deleteCall } from 'api';
import moment from 'moment';

export const getMeetings = (startOfWeek?: Date, limit: number = 100): Promise<{ meetings: BizlyAPI.Meeting[] }> =>
    startOfWeek
        ? get(`meetings?date_start=${moment(startOfWeek).format('YYYY-MM-DD')}&limit=${limit}`)
        : get(`meetings?unscheduled_only&limit=${limit}`);

export const getMeeting = (id?: string | number): Promise<{ meeting: BizlyAPI.Meeting }> =>
    get(`meetings/${id}`, {
        showErrorCode: true,
    });

export function isDeniedAccess(error: unknown): error is BizlyAPIError.MeetingDeniedAccess {
    const expectedError = error as BizlyAPIError.MeetingDeniedAccess;
    if (expectedError.code === 404) return true;
    return false;
}

export const createMeeting = (data: BizlyAPI.MeetingCreate): Promise<{ meeting: BizlyAPI.Meeting }> =>
    post(`meetings`, data);

export const updateMeeting = (data: BizlyAPI.MeetingUpdate): Promise<{ meeting: BizlyAPI.Meeting }> =>
    put(`meetings/${data.id}`, data);

export const deleteMeeting = (id: number | string): Promise<{ meeting: BizlyAPI.Meeting }> =>
    deleteCall(`meetings/${id}`);

export const publishMeeting = ({
    id,
    ...data
}: {
    id: number | string;
    inviteType: 'registration' | 'calendar';
    inviteImageUrl?: string | null;
    inviteDescription?: string | null;
}): Promise<{ meeting: BizlyAPI.Meeting }> => post(`meetings/${id}/publish`, data);

export const rateMeeting = ({
    id,
    ...data
}: {
    id: number | string;
    score: number;
}): Promise<{ meeting: BizlyAPI.Meeting }> => post(`meetings/${id}/nps`, data);

export const requestSubsumption = (id: string | number): Promise<{ meeting: BizlyAPI.Meeting }> =>
    post(`meetings/${id}/request-subsumption`);
