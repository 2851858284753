import React from 'react';
import styled from 'styled-components';
import { tzMoment, userTimeZone } from 'utils/moment';
import { Location } from 'history';
import { useSnackbar } from 'notistack';
import { Redirect, useHistory, useParams } from 'react-router';
import useShowModal from 'hooks/useShowModal';

import { selectMeeting, useMeetings, isUpdating, meetingsActions, isLoading } from 'stores/meetings';
import { useChimeVideo } from 'stores/chime-video';
import { ESteps } from 'pages/CreateMeeting/store';
import { attendeesActions } from 'stores/attendees';
import { useUser } from 'providers/user';

import { Column, Copy, ExternalLink, InlineRow, Row, Spacer } from 'ui';
import SmallCircleButton from 'components/ui/SmallCircleButton';
import { Dialog, Tooltip } from '@material-ui/core';
import { FinalSpendContent } from 'components/FinalSpendTracking/Flyout';
import MeetingDetails from 'components/MeetingDetails';

import Menu from './components/Menu';
import VirtualMeetingPopover, { VMIcon } from './components/VirtualMeetingPopover';
import QuickInviteAttendees from './components/QuickInviteAttendees';
import DocumentsCard from './components/DocumentsCard';
import CommunicationsCard from './components/CommunicationsCard';
import RespondableHeadline from './components/RespondableHeadline';
import ChimeChat from './components/ChimeChat';
import ScoreModal from 'components/ScoreModal';

import { ReactComponent as PlusIconSvg } from 'images/icons/plus.svg';
import { ReactComponent as CalculatorSvg } from 'images/icons/calculator.svg';
import { ReactComponent as GuestsIconSvg } from 'images/icons/guests.svg';
import { ReactComponent as EditSvg } from 'images/icons/edit.svg';
import { ReactComponent as FileSvg } from 'images/icons/file.svg';
import { ReactComponent as EnvelopeSVG } from 'images/icons/envelope.svg';
import { ReactComponent as BackArrowSvg } from 'images/icons/StealthLeftArrow.svg';

import { withInteractibleIconStyles } from 'shared';
import colorFns from 'colorFns';
import CountdownBanner from 'components/CountdownBanner';
import RequestSubsume from './components/RequestSubsume';

const BackArrowIcon = styled(BackArrowSvg)`
    height: 11px;
    width: 20px;
    transform: rotateZ(180deg);
`;

const DetailsCol = styled(Column)`
    min-width: 500px;
    max-width: 40vw;
`;

const ChatCol = styled(Column)`
    min-width: 400px;
    padding-top: 30px;
    padding-right 30px;
    flex: 1 0 0;

    height: 100%;
`;

const ChimeChatWindow = styled(ChimeChat)`
    position: sticky;
    top: 0;
`;

const DateText = styled(Copy)`
    color: ${colorFns.pureWhite};
    background: ${colorFns.primaryAction};
    border-radius: 0 0 8px 8px;
    margin-top: -15px;
    padding: 22px 16px 12px;
    font-size: 14px;

    display: flex;
    align-items: center;
`;

const LocationLink = styled(ExternalLink)``;

const DetailsRow = styled(Row)`
    & > ${LocationLink} {
        margin-left: 60px;
    }
`;

const Card = styled(Column)`
    position: relative;
    min-width: 400px;
    min-height: 200px;

    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    border-radius: 8px;

    padding: 32px;
    box-sizing: border-box;

    background: ${colorFns.brand};
`;

const PlusIcon = () => (
    <SmallCircleButton secondary={false}>
        <PlusIconSvg />
    </SmallCircleButton>
);

const Circle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 28px;
    height: 28px;
    border-radius: 28px;

    position: absolute;
    bottom: -14px;
    right: -14px;

    background: ${colorFns.primaryAction};
`;

const CalculatorIcon = styled(withInteractibleIconStyles(CalculatorSvg))``;
const GuestsIcon = styled(withInteractibleIconStyles(GuestsIconSvg))``;
const EditIcon = styled(withInteractibleIconStyles(EditSvg))``;
const FileIcon = styled(withInteractibleIconStyles(FileSvg)).attrs(() => ({
    viewBox: '0 4 18 21',
}))`
    color: ${colorFns.pureWhite.alpha(0.74)};
`;
const EnvelopeIcon = styled(withInteractibleIconStyles(EnvelopeSVG)).attrs(() => ({}))`
    color: ${colorFns.pureWhite.alpha(0.74)};
`;

const CardTooltip = styled(Tooltip)`
    position: relative;
`;

const TooltipIconRow = styled(InlineRow)`
    cursor: pointer;
`;

const CardDialog = styled(Dialog)`
    .MuiPaper-root {
        background: transparent;
        color: unset;
    }
`;

const WithTooltip = ({
    title,
    icon,
    count,
    dialog,
}: {
    title: React.ReactNode;
    icon: React.ReactNode;
    count: React.ReactNode;
    dialog: React.ReactNode;
}) => {
    const { modalShown, showModal, hideModal } = useShowModal();

    return title ? (
        <>
            <CardTooltip title={title}>
                <TooltipIconRow alignItems="center" justifyContent="center" onClick={showModal}>
                    {icon}
                    <Circle>{count}</Circle>
                </TooltipIconRow>
            </CardTooltip>
            <CardDialog open={modalShown} maxWidth="xs" onBackdropClick={hideModal}>
                {dialog}
            </CardDialog>
        </>
    ) : null;
};

export default function Meeting({ onShowChime }: { onShowChime: () => void }) {
    const { id } = useParams<{ id: string }>();

    const meeting = useMeetings(selectMeeting(id));
    const { user } = useUser();

    const start = tzMoment(meeting?.startsAt, meeting?.timeZone);
    const end = tzMoment(meeting?.endsAt, meeting?.timeZone);

    const history = useHistory<{
        step?: ESteps;
        shouldGoBack?: boolean;
        backgroundLocation?: Location;
    }>();
    const editAttendeesLocation = () => ({
        pathname: `/meetings/${id}/edit`,
        state: { step: ESteps.attendees },
    });
    const editDocsLocation = () => ({ pathname: `/meetings/${id}/edit` });

    const editable = meeting?.editable;

    const { modalShown, showModal, hideModal } = useShowModal();

    const menuItems = [
        {
            icon: <PlusIcon />,
            label: 'Create Note / Survey',
            to: `/event/${id}/communication/create/note`,
        },
        {
            icon: <PlusIcon />,
            label: 'Create Additional Invites',
            to: `/event/${id}/communication/create/invite`,
        },

        ...(meeting?.endsAt &&
        tzMoment(meeting.endsAt, meeting.timeZone).isBefore(tzMoment(new Date(), meeting?.timeZone))
            ? [
                  {
                      icon: <CalculatorIcon />,
                      label: 'Final Spend Tracking',
                      onClick: showModal,
                  },
              ]
            : []),
        { icon: <GuestsIcon />, label: 'Guest List', to: editAttendeesLocation },
        { icon: <EditIcon />, label: 'Edit Meeting', to: editDocsLocation },
    ];

    const OverflowMenu = () =>
        editable ? (
            <>
                <Menu items={menuItems} />
                {id && (
                    <Dialog open={modalShown} onBackdropClick={hideModal}>
                        <FinalSpendContent
                            eventId={typeof id === 'string' ? parseInt(id) : id}
                            editable={editable}
                            onSpendTracked={hideModal}
                            onClose={hideModal}
                        />
                    </Dialog>
                )}
            </>
        ) : null;

    const { enqueueSnackbar } = useSnackbar();

    const [responding, setResponding] = React.useState(false);
    const respond = async (response: 'accept' | 'reject') => {
        if (!id || !user.email) return;
        const action = response === 'accept' ? attendeesActions.accept : attendeesActions.reject;

        try {
            setResponding(true);
            await action(id, user.email);
        } catch (e) {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        } finally {
            setResponding(false);
        }
    };

    const updating = useMeetings(isUpdating(meeting?.id));
    const onUpload = (attachment: BizlyAPI.Attachment) => {
        if (updating || !meeting) return;
        const { recurrence, ...meetingData } = meeting;

        try {
            meetingsActions.update({
                ...meetingData,
                ...(meeting.recurrence
                    ? { recurrence: { ...meeting.recurrence, applyTo: { past: true, future: true } } }
                    : {}),
                attachments: [...(meeting?.attachments ?? []), attachment],
            });
        } catch {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    };
    const onDeleteFile = (attachment: BizlyAPI.Attachment) => {
        if (updating || !meeting) return;
        const { recurrence, ...meetingData } = meeting;

        try {
            meetingsActions.update({
                ...meetingData,
                ...(meeting.recurrence
                    ? { recurrence: { ...meeting.recurrence, applyTo: { past: true, future: true } } }
                    : {}),
                attachments: (meeting.attachments || []).filter(curAttachment => curAttachment.url !== attachment.url),
            });
        } catch {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    };

    const running =
        meeting &&
        meeting.currentUserAttendee?.status === 'attending' &&
        tzMoment(new Date(), meeting?.timeZone).isBetween(
            tzMoment(meeting.startsAt, meeting.timeZone).subtract(2, 'minutes'),
            tzMoment(meeting.endsAt, meeting.timeZone)
        );

    const [meetingOver, setMeetingOver] = React.useState(false);
    const endsAt = meeting?.endsAt;
    const timeZone = meeting?.timeZone;
    React.useEffect(() => {
        let handle: number;
        if (endsAt) {
            const endTime = tzMoment(endsAt, timeZone).tz(userTimeZone);
            const timeLeft = endTime.diff(tzMoment('', userTimeZone), 'ms');

            if (timeLeft <= 1000) {
                setMeetingOver(true);
            } else {
                handle = setTimeout(() => {
                    setMeetingOver(true);
                }, timeLeft - 1000);
            }
        }

        return () => {
            if (handle) clearTimeout(handle);
        };
    }, [endsAt, timeZone, setMeetingOver]);
    React.useEffect(() => () => setMeetingOver(false), [id]);

    const needsNPS =
        meetingOver &&
        !meeting?.fromExternal &&
        meeting?.currentUserAttendee &&
        meeting.currentUserAttendee.status === 'attending' &&
        !meeting.currentUserAttendee.nps;

    const useChime = running && !meeting?.virtualMeeting && !meeting?.fromExternal;

    const loading = useMeetings(isLoading(id));
    const { joinInfo } = useChimeVideo();

    const canJoin = useChime && joinInfo && !loading && !meetingOver;

    const onJoin = async () => {
        if (meeting?.currentUserAttendee?.status !== 'attending') {
            await respond('accept');
            onShowChime();
        } else {
            onShowChime();
        }
    };

    if (!meeting) {
        return null;
    }

    if (!meeting.published) {
        return <Redirect to={`${history.location.pathname}/edit`} push={false} />;
    }

    return (
        <>
            {needsNPS && <ScoreModal meetingId={id} />}

            {!meetingOver && (
                <CountdownBanner
                    time={start.toDate()}
                    showMilliseconds={5 * 60 * 1000}
                    message={time => (time ? `Meeting starting in ${time}` : '')}
                    showLinkMilliseconds={2 * 60 * 1000}
                    linkText={over => (
                        <InlineRow alignItems="center" justifyContent="center" itemSpacing="small">
                            <span>{over ? 'Meeting Started. Join Video' : 'Join Video Now'}</span>
                            <BackArrowIcon />
                        </InlineRow>
                    )}
                    onLink={onJoin}
                />
            )}
            <DetailsRow alignItems="center" itemSpacing="default">
                <DateText>
                    {meeting.startsAt && meeting.endsAt
                        ? [
                              start
                                  .tz(userTimeZone)
                                  .calendar({ sameElse: 'ddd, MMM Do, yyyy hh:mm A' })
                                  .replace('at ', ''),
                              end.tz(userTimeZone).format('hh:mm A'),
                          ].join(' - ')
                        : 'Unscheduled'}
                </DateText>
                {meeting.virtualMeeting?.link && <VirtualMeetingPopover virtualMeeting={meeting.virtualMeeting} />}
                {canJoin && <VMIcon onClick={onShowChime} />}

                {meeting.location && (
                    <LocationLink
                        href={
                            meeting.googlePlaceId
                                ? `https://www.google.com/maps/place/?q=place_id:${meeting.googlePlaceId}`
                                : `https://www.google.com/maps/place/?q=${meeting.location}`
                        }
                        openInNewTab
                        underline
                    >
                        {meeting.location}
                    </LocationLink>
                )}
                <Row
                    style={{ marginLeft: 'auto', width: 'auto', marginTop: '15px' }}
                    alignItems="center"
                    itemSpacing="smallish"
                >
                    {!meeting.fromExternal && (
                        <WithTooltip
                            title="Documents"
                            icon={<FileIcon />}
                            count={meeting.attachments?.length ?? '0'}
                            dialog={
                                <Card>
                                    <DocumentsCard
                                        attachments={meeting.attachments}
                                        onUpload={editable ? onUpload : undefined}
                                        onDelete={editable ? onDeleteFile : undefined}
                                        disabled={updating}
                                    />
                                </Card>
                            }
                        />
                    )}

                    {!meeting.fromExternal && id && (
                        <>
                            <WithTooltip
                                title="Communications"
                                icon={<EnvelopeIcon />}
                                count={
                                    meeting.currentUserAttendee?.parcels?.filter(parcel => parcel.responded !== true)
                                        .length ?? '0'
                                }
                                dialog={
                                    <Card>
                                        <CommunicationsCard
                                            meetingId={id}
                                            editable={editable}
                                            basicParcels={meeting.currentUserAttendee?.parcels}
                                            toCreateInvite={`/event/${id}/communication/create/invite`}
                                            toCreateNote={`/event/${id}/communication/create/note`}
                                        />
                                    </Card>
                                }
                            />
                        </>
                    )}

                    {!meeting.fromExternal && id && (
                        <QuickInviteAttendees
                            meetingId={id}
                            editable={editable}
                            editAttendeesLocation={editAttendeesLocation}
                        />
                    )}

                    <OverflowMenu />
                </Row>
            </DetailsRow>

            <Row itemSpacing="largest">
                <DetailsCol alignItems="flex-start">
                    <RespondableHeadline meeting={meeting} onRespond={respond} disabled={responding} />
                    <MeetingDetails meeting={meeting} />
                    {meeting.fromExternal && <RequestSubsume meeting={meeting} />}
                </DetailsCol>

                {!meeting.fromExternal && (
                    <ChatCol>
                        <ChimeChatWindow channelArn={meeting.chimeChat?.channelArn} />
                    </ChatCol>
                )}
            </Row>

            <Spacer largest />
            <Spacer largest />
        </>
    );
}
