import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { groupBy } from 'lodash';
import { useHistory } from 'react-router';

import { useUser } from 'providers/user';
import { isBlankTemplateId as isBlankPlaybookId } from 'api/eventTemplates'; // TODO: Update these helper names to use "Playbook" once old "Templates" page is depricated

import { Column, Row, Spacer } from 'ui';
import Button from 'components/ui/Button';
import { ReactComponent as PlusSVG } from 'images/icons/plus.svg';
import { H5Headline } from 'components/ui/Headline';
import PlaybooksList from 'components/Templates/PlaybooksList';
import FilterList from './FilterList';
import { SelectField as FormSelectField } from 'components/FormFields';

import {
    ESECTION,
    sectionToTitle,
    getTemplatesUrlFilters as getPlaybookUrlFilters, // TODO: Update these helper names to use "Playbook" once old "Templates" page is depricated
    setTemplatesUrlFilters as setPlaybookUrlFilters, // TODO: Update these helper names to use "Playbook" once old "Templates" page is depricated
} from 'components/Templates/utils';
import { LoadPlaybooks, usePlaybooks } from 'stores/playbooks';

const { PERSONAL, SHARED, GLOBAL } = ESECTION;

type TPlaybooksByCategory = Record<ESECTION, BizlyAPI.Complete.Playbook[]>;

const DEFAULT_PLAYBOOKS: TPlaybooksByCategory = {
    [PERSONAL]: [],
    [SHARED]: [],
    [GLOBAL]: [],
};
const ORDERED_CATEGORIES: ESECTION[] = [PERSONAL, SHARED, GLOBAL];

const PlusIcon = styled(PlusSVG)``;
const CreateButton = styled(Button)`
    height: 45px;
    padding-left: 10px;
    border-radius: 8px;

    ${PlusIcon} {
        display: inline-flex;
        white-space: nowrap;
        padding: 0;
        width: 16px;
        height: 16px;

        background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};

        border-radius: 50%;
        min-width: 0;
        align-items: center;
        justify-content: center;

        margin-right: 10px;
    }
`;

const ContentRow = styled(Row)`
    position: relative;
    height: 100%;
`;
const LeftColumn = styled(Column)`
    position: relative;
    min-height: 100%;
    width: 300px;
`;
const RightColumn = styled(Column)`
    border-left: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    padding-left: 48px;
    margin-left: 48px;
`;

const Sticky = styled.span`
    position: sticky;
    top: 24px;
`;

const SelectField = styled(FormSelectField)`
    font-size: 22px;

    .MuiSelect-root {
        padding-left: 0;
    }

    width: 320px;
`;

export default function PlaybooksPage() {
    const [playbooksByCategory, setPlaybooksByCategory] = useState<TPlaybooksByCategory>(DEFAULT_PLAYBOOKS);
    const { playbooks } = usePlaybooks();
    useEffect(() => {
        const arrangePlaybooks = (allPlaybooks: BizlyAPI.Complete.Playbook[]) => {
            const { personal, shared, global } = {
                ...DEFAULT_PLAYBOOKS,
                ...groupBy(allPlaybooks, 'categoryName'),
            };
            const globalTempsWithoutBlank = global.filter(({ id }) => !isBlankPlaybookId(id));

            return setPlaybooksByCategory({
                personal: personal,
                shared: shared,
                global: globalTempsWithoutBlank,
            });
        };

        if (playbooks) arrangePlaybooks(Object.values(playbooks).reverse());
    }, [playbooks]);

    const history = useHistory();
    const { user } = useUser();

    const { section: currentSection = PERSONAL, tags: currentTags } = getPlaybookUrlFilters(history.location);
    useEffect(() => {
        if (currentSection && ![PERSONAL, SHARED, GLOBAL].includes(currentSection))
            setPlaybookUrlFilters(history, curFilterValue => ({ ...curFilterValue, section: undefined }));
    }, [currentSection, history]);

    const tagsSet = new Set(currentTags);
    const applyFilter = (playbooks: Bizly.EventTemplate[]) =>
        tagsSet.size ? playbooks.filter(playbook => playbook.tags?.some(tag => tagsSet.has(tag.id))) : playbooks;

    const categoryToTitle = (category: ESECTION) => sectionToTitle(category, user);

    return (
        <>
            <Column alignItems="flex-start">
                <LoadPlaybooks />
                <H5Headline>Playbooks</H5Headline>
                <Spacer small />
                <CreateButton width="auto" onClick={() => history.push('/playbooks/new')} small>
                    <Row alignItems="center">
                        <PlusIcon />
                        Create New Playbook
                    </Row>
                </CreateButton>
                <Spacer large />

                <ContentRow alignItems="stretch">
                    <LeftColumn>
                        <Sticky>
                            <FilterList />
                        </Sticky>
                    </LeftColumn>
                    <RightColumn itemSpacing="small">
                        <SelectField
                            field=""
                            value={currentSection}
                            options={ORDERED_CATEGORIES.map(category => ({
                                id: category,
                                name: categoryToTitle(category),
                            }))}
                            onChange={({ value }: { value: React.ChangeEvent<HTMLSelectElement> }) => {
                                setPlaybookUrlFilters(history, curFilterValue => ({
                                    ...curFilterValue,
                                    section: value.target.value as ESECTION,
                                }));
                            }}
                        />
                        {ORDERED_CATEGORIES.map(
                            category =>
                                category === currentSection && (
                                    <PlaybooksList
                                        key={category}
                                        playbooks={applyFilter(playbooksByCategory[category])}
                                    />
                                )
                        )}
                    </RightColumn>
                </ContentRow>
            </Column>
        </>
    );
}
