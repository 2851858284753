import React, { useState, useCallback, useMemo } from 'react';
import { Link, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import omit from 'lodash/omit';

import { sendMagicLink, isAuthenticationPartialSuccess, isSSOOnlyAccount, loginWithGoogleId, loginWithMSId } from 'api';
import { useAuthenticate } from 'pages/Login/util';

import mixpanel from 'utils/mixpanel';
import { TAuthProps, TGoogleAuth, TMSAuth } from './types';
import { emailIsValid } from 'utils';

import ContentCard from 'components/ui/ContentCard';
import Policies from 'components/ui/Policies';
import SSOView from './SSOView';
import LinkSentView from './LinkSentView';
import MSTokenExchange from './MSTokenExchange';

import { Copy } from 'ui';
import { SpinnerOverlay, Spinner } from 'components/Spinner';
import UITextButton from 'components/ui/Button/TextButton';

import Form from 'components/Form';
import { makeAuthFields, makeSignInEmailSchema } from './formSchema';

const SubCopy = styled(Copy)`
    font-size: 13px;
    line-height: 16px;
    padding: 0;
    margin-bottom: -10px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.74)};
`;

const SignInHeader = styled.h2`
    font-size: 2rem;
    width: 423px;
    text-align: center;
`;

const TextButton = styled(UITextButton)`
    font-size: inherit;
    font-weight: inherit;
    padding: 0;
`;

const StyledLink = styled(Link)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
    cursor: pointer;
`;

export default function SignInPage({
    authFormValue,
    onChange,
    onSignInSuccess,
}: TAuthProps & { onSignInSuccess: (user: BizlyAPI.User) => void }) {
    const [isLoading, setIsLoading] = useState(false);
    const [linkIsSent, setLinkIsSent] = useState(false);
    const [SSOLink, setSSOLink] = useState<Nullable<string>>(null);

    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    React.useEffect(() => {
        mixpanel.track(`Viewed page: ${history.location.pathname}`);
    }, [history]);

    const [authenticating] = useAuthenticate();

    const onSendLink = useCallback(
        async e => {
            e.preventDefault();

            if (!authFormValue.email || !emailIsValid(authFormValue.email)) {
                return enqueueSnackbar('Please enter a valid email.', {
                    variant: 'error',
                });
            }

            setIsLoading(true);
            try {
                await sendMagicLink({ email: authFormValue.email });
                setLinkIsSent(true);

                mixpanel.track('Requested magic link login');
            } catch (e) {
                if (isSSOOnlyAccount(e)) {
                    return setSSOLink(e.raw.ssoLink);
                }

                if (isAuthenticationPartialSuccess(e)) {
                    if (process.env.REACT_APP_SIGN_UP_V2 === 'true') {
                        // TODO: Remove this env check once Sign Up is released for all environments
                        onChange({ value: { email: undefined } });
                        return history.push('/sign-up', { signInRedirect: true });
                    }

                    return enqueueSnackbar(
                        'We couldn’t find your Bizly Account. If you believe this is an error or would like to create an account, please contact concierge@bizly.com',
                        {
                            variant: 'error',
                        }
                    );
                }

                return enqueueSnackbar('Something went wrong, please try again.', {
                    variant: 'error',
                });
            } finally {
                setIsLoading(false);
            }
        },
        [enqueueSnackbar, authFormValue.email, history, onChange]
    );

    const onAuthSuccess = useCallback(
        async (resp: TGoogleAuth | TMSAuth) => {
            let authFn: (() => Promise<{ user: BizlyAPI.User }>) | undefined = undefined;
            let preAuthEmail = '';
            let preAuthClient: 'google' | 'ms' | undefined = undefined;

            if (resp.client === 'google') {
                if (resp.tokenId === undefined) {
                    return enqueueSnackbar('Something went wrong, please try again.', {
                        variant: 'error',
                    });
                }

                const { tokenId: googleIdToken, profileObj } = resp;
                const email = profileObj?.email;

                if (!email || !googleIdToken) {
                    return enqueueSnackbar('Something went wrong, please try again.', {
                        variant: 'error',
                    });
                }
                authFn = () => loginWithGoogleId({ email, googleIdToken });
                preAuthEmail = email;
                preAuthClient = 'google';
            } else if (resp.client === 'ms') {
                const { idToken: msIdToken, accessToken: msAccessToken, email } = resp;

                if (!email || !msIdToken || !msAccessToken) {
                    return enqueueSnackbar('Something went wrong, please try again.', {
                        variant: 'error',
                    });
                }
                authFn = () => loginWithMSId({ email, msIdToken, msAccessToken });
                preAuthEmail = email;
                preAuthClient = 'ms';
            }

            if (authFn === undefined) return;

            setIsLoading(true);
            try {
                const { user } = await authFn();

                if (user?.authToken) onSignInSuccess(user);
            } catch (e) {
                if (isSSOOnlyAccount(e)) {
                    return setSSOLink(e.raw.ssoLink);
                }

                if (isAuthenticationPartialSuccess(e)) {
                    if (process.env.REACT_APP_SIGN_UP_V2 === 'true') {
                        onChange({ value: { email: undefined, preAuthEmail, preAuthClient } });
                        return history.push('/sign-up', { signInRedirect: true });
                    } else {
                        return enqueueSnackbar(
                            // TODO - Replace the below snackbar call with the commented out history push once Sign Up is released for all
                            'We couldn’t find your Bizly Account. If you believe this is an error or would like to create an account, please contact concierge@bizly.com',
                            {
                                variant: 'error',
                            }
                        );
                    }
                }

                return enqueueSnackbar('Something went wrong, please try again.', {
                    variant: 'error',
                });
            } finally {
                setIsLoading(false);
            }
        },
        [enqueueSnackbar, onSignInSuccess, onChange, history]
    );

    // handle ms redirect based auth
    const location = useLocation<{ msAuth: TMSAuth }>();
    React.useEffect(() => {
        if (location.state?.msAuth) {
            onAuthSuccess(location.state.msAuth);
            history.replace({ ...history.location, state: omit({ ...location.state }, 'msAuth') });
        }
    }, [location.state, onAuthSuccess, history]);

    const PathToSignUp = process.env.REACT_APP_SIGN_UP_V2 === 'true' && ( // TODO: Remove this env check once Sign Up is released for all environments
        <>
            Don’t have an account? You can <StyledLink to="/sign-up">sign up here.</StyledLink>
        </>
    );

    const ResendEmail = (
        <>
            Didn't receive an email? <TextButton onClick={onSendLink}>Resend Email</TextButton>
        </>
    );

    const authFields = useMemo(
        () =>
            makeAuthFields({
                onSendLink,
                onGoogleAuthSuccess: onAuthSuccess,
                onMSAuthSuccess: onAuthSuccess,
            }),
        [onSendLink, onAuthSuccess]
    );

    const schema = useMemo(
        () =>
            makeSignInEmailSchema({
                onGoogleAuthSuccess: onAuthSuccess,
                onMSAuthSuccess: onAuthSuccess,
            }),
        [onAuthSuccess]
    );

    const match = useRouteMatch();

    if (authenticating)
        return (
            <ContentCard headlineCopy="Sign In" centerSelf>
                <Spinner />
            </ContentCard>
        );

    return (
        <div>
            <SignInHeader>
                Welcome to Bizly <br /> Sign in to fight crime.
            </SignInHeader>
            <Switch>
                <Route path={`${match.path}/msauth`}>
                    <ContentCard footer={PathToSignUp} centerSelf>
                        <MSTokenExchange />
                    </ContentCard>
                </Route>
                <Route>
                    <ContentCard footer={!linkIsSent ? PathToSignUp : ResendEmail} centerSelf>
                        {!linkIsSent ? (
                            <>
                                {!SSOLink ? (
                                    <>
                                        <SubCopy>Enter your email address</SubCopy>
                                        <Form
                                            fields={authFields}
                                            schema={schema}
                                            value={authFormValue}
                                            onChange={onChange}
                                            disabled={isLoading}
                                            isNested
                                        />
                                    </>
                                ) : (
                                    <SSOView SSOLink={SSOLink} />
                                )}
                                <Policies prefix="By signing up or logging in" />
                            </>
                        ) : (
                            <LinkSentView />
                        )}
                        {isLoading && <SpinnerOverlay />}
                    </ContentCard>
                </Route>
            </Switch>
        </div>
    );
}
