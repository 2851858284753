import React from 'react';
import styled from 'styled-components/macro';

import { InlineRow, Row } from 'ui';
import { LargeHeadline } from 'components/ui/Headline';
import Button from 'components/ui/Button';
import CircleCheckIcon from 'components/CircleCheckIcon';
import CircleXIcon from 'components/CircleXIcon';
import colorFns from 'colorFns';

const BaselineAlignmentSvg = styled.svg.attrs({ height: 30, width: 0 })`
    margin: 0;
`;

const ButtonContainer = styled(Button)`
    background: transparent;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.brand)};
    width: auto;
    padding: 0;
    margin: 0 0px;
    margin-right: 20px;
    border: none;

    &:hover,
    &:disabled {
        background: transparent;
        border: none;
    }
`;

const AcceptButtonIcon = styled(CircleCheckIcon)`
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.1)};
    cursor: pointer;
    width: 15px;
    height: 15px;
`;

const RejectButtonIcon = styled(CircleXIcon)`
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.1)};
    cursor: pointer;
    width: 15px;
    height: 15px;
`;

const AcceptedCheckIcon = styled(CircleCheckIcon)`
    background: ${colorFns.acceptedMeeting};
`;

const Headline = styled(LargeHeadline)`
    font-size: 42px;
    line-height: 42px;
    white-space: normal;
    word-break: break-word;
    flex: 1 1 auto;
`;

const Buttons = styled(InlineRow)`
    flex: 0 0 auto;
`;

export default function RespondableHeadline({
    meeting,
    onRespond,
    disabled,
}: {
    meeting: BizlyAPI.Meeting;
    onRespond: (response: 'accept' | 'reject') => void;
    disabled?: boolean;
}) {
    return (
        <Row alignItems="center" itemSpacing="small">
            <Headline>{meeting.name}</Headline>
            <Buttons alignItems="center" itemSpacing="smallish">
                {meeting.currentUserAttendee && meeting.currentUserAttendee.status !== 'not sent' && (
                    <>
                        <BaselineAlignmentSvg />
                        <InlineRow alignItems="center" itemSpacing="small">
                            {meeting.currentUserAttendee?.status === 'attending' && (
                                <>
                                    <AcceptedCheckIcon />
                                </>
                            )}
                            {meeting.currentUserAttendee?.status === 'not attending' && (
                                <>
                                    <CircleXIcon />
                                </>
                            )}
                        </InlineRow>
                        {meeting.currentUserAttendee.status !== 'attending' && (
                            <ButtonContainer onClick={() => onRespond('accept')} disabled={disabled}>
                                <AcceptButtonIcon />
                            </ButtonContainer>
                        )}
                        {meeting.currentUserAttendee.status !== 'not attending' && (
                            <ButtonContainer onClick={() => onRespond('reject')} disabled={disabled} warning>
                                <RejectButtonIcon />
                            </ButtonContainer>
                        )}
                    </>
                )}
            </Buttons>
        </Row>
    );
}
