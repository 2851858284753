import React from 'react';

import { useSnackbar } from 'notistack';

import { meetingsActions } from './store';
import { isDeniedAccess } from 'api/meetings';

export default function LoadMeeting({ id, onDeny }: { id?: string | number; onDeny?: (deny: boolean) => void }) {
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        const load = async () => {
            if (id) {
                try {
                    await meetingsActions.loadSingle(id);
                } catch (e) {
                    if (isDeniedAccess(e)) return onDeny?.(true);
                    enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
                }
            }
        };
        load();
    }, [id, onDeny, enqueueSnackbar]);

    return null;
}
