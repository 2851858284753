import React from 'react';

import { useParams } from 'react-router';
import { Redirect } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Headline } from '../components/ui/Headline';

import { Spinner } from './Spinner';

import { useEvent } from '../providers/event';
import { useUser } from '../providers/user';

import { Row, Spacer } from '../ui';

import { getVenueProposalAccepted, setBookingContractUrl, setBookingEstimates } from '../api';
import { uploadFile } from '../cloudinary';

// TODO: Move this entire file into the VenueAccepted folder and rename the folder to be VenueProposalAccepted, and rename this file to index.tsx. Adjust the below imports to match accordingly
import CongratsMessage from './VenueAccepted/CongratsMessage';
import VenueContact from './VenueAccepted/VenueContact';
import VenueDocs from './VenueAccepted/VenueDocs';
import CompleteAndSign from './VenueAccepted/CompleteAndSign';
import UploadSignedDocs from './VenueAccepted/UploadSignedDocs';
import PaymentDetails from './VenueAccepted/PaymentDetails';
import FinalizeDetails from './VenueAccepted/FinalizeDetails';
import UploadDocModal from './VenueAccepted/UploadDocModal';
import Carousel from './Carousel';

import { css } from 'styled-components/macro';

const ENABLE_VIRTUAL_PAYMENT = process.env.REACT_APP_ENV !== 'prod';

const ProposalStepsToComponent = {
    download_documents: VenueDocs,
    complete_sign_documents: CompleteAndSign,
    upload_signed_documents: UploadSignedDocs,
    payment_details: PaymentDetails,
    finalize_details: FinalizeDetails,
} as const;

const VenueProposalAccepted = ({ onSendMessage, sideMargin }: { onSendMessage: () => void; sideMargin: number }) => {
    const { proposalId } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const { event } = useEvent();
    const { user } = useUser();

    const proposalSteps = user.team?.proposalSteps ?? [];
    const paymentCardRules = user.team?.paymentCardRules;
    const teamCompliance = user.team?.compliance ?? {};

    const [venueProposal, setVenueProposal] = React.useState<Bizly.VenueProposal | undefined>();
    const [loading, setLoading] = React.useState(true);
    const [uploadModalOpen, setUploadModalOpen] = React.useState<boolean>(false);
    const [estimatesForm, setEstimatesForm] = React.useState<object>({});

    React.useEffect(() => {
        getVenueProposalAccepted(Number(proposalId))
            .then(proposal => {
                proposal && setVenueProposal(proposal);

                if (proposal?.booking) {
                    setEstimatesForm({ ...proposal.booking.estimates });
                }
            })
            .finally(() => setLoading(false));
    }, [event.id, proposalId]);

    const getExisingDocs = () => ({
        contract1: venueProposal?.booking.contractUrl,
        contract2: venueProposal?.booking.contract2Url,
    });

    const updatePaymentCard = (paymentCard: Bizly.PaymentCard) =>
        setVenueProposal(prevVenueProposal =>
            prevVenueProposal
                ? {
                      ...prevVenueProposal,
                      booking: {
                          ...prevVenueProposal.booking,
                          paymentCard,
                      },
                  }
                : prevVenueProposal
        );

    const handleSubmit = async (stagedDocs: any) => {
        const {
            booking: { id: bookingId, contractUrl: existingContract1 },
        } = venueProposal as Bizly.VenueProposal;
        const { contract1, contract2 } = stagedDocs;
        let stagedUpload1, stagedUpload2;

        try {
            setLoading(true);
            // TODO: Setting a contract and setting estimates are two seperate endpoints; look into making a unified one for less calls
            if (contract1.name) {
                const { url: firstUrl } = await uploadFile(contract1);
                stagedUpload1 = firstUrl;
            }

            if (contract2.name) {
                const { url: secondUrl } = await uploadFile(contract2);
                stagedUpload2 = secondUrl;
            }

            if (stagedUpload1 || stagedUpload2) {
                const firstContract = stagedUpload1 || existingContract1;
                const secondContract = stagedUpload2 || null;
                const contractUrls = {
                    contractUrl: firstContract || secondContract,
                    contract2Url: !!firstContract ? secondContract : null,
                };

                const { booking: bookedContract } = await setBookingContractUrl(bookingId, contractUrls);
                setVenueProposal({ ...(venueProposal as Bizly.VenueProposal), booking: bookedContract });
            }

            const { booking: updatedBooking } = await setBookingEstimates(bookingId, estimatesForm);

            setVenueProposal(prevVenueProposal =>
                prevVenueProposal
                    ? {
                          ...prevVenueProposal,
                          booking: {
                              ...prevVenueProposal.booking,
                              ...updatedBooking,
                          },
                      }
                    : prevVenueProposal
            );

            setUploadModalOpen(false);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            enqueueSnackbar(`Error attempting to submit data`, {
                variant: 'error',
            });
        }
    };

    return loading ? (
        <Spinner />
    ) : venueProposal ? (
        <>
            <main
                css={css`
                    margin: 0 12px;
                `}
            >
                <Row
                    css={css`
                        margin: 75px 0;
                        justify-content: space-between;
                        align-items: flex-start;

                        > * {
                            margin-right: 24px;
                        }
                    `}
                >
                    <CongratsMessage />
                    <VenueContact
                        contact={venueProposal.contact}
                        venue={venueProposal.venue}
                        onSendMessage={event.editable ? onSendMessage : undefined}
                    />
                </Row>
                <Headline>Here's what to do next:</Headline>
                <Spacer />
                <Spacer />
                <div
                    style={{
                        margin: `0 -${sideMargin}px`,
                        padding: `0 ${sideMargin}px`,
                        /* TODO: pass these padding values down to this component */

                        overflowX: 'hidden',
                    }}
                >
                    <Carousel perRow={3} spacing={24} overflowPreviews={1} buttonsOnTop>
                        {proposalSteps.map(proposalStep => {
                            const Section = ProposalStepsToComponent[proposalStep.type];
                            return Section ? (
                                <Section
                                    paymentCardRules={paymentCardRules}
                                    key={proposalStep.number}
                                    number={proposalStep.number}
                                    venue={venueProposal}
                                    updatePaymentCard={updatePaymentCard}
                                    openUploadModal={() => setUploadModalOpen(true)}
                                    existingDocs={getExisingDocs()}
                                    copy={proposalStep.copy}
                                    altCopy={proposalStep.altCopy}
                                    redText={proposalStep.redText}
                                    readonly={!event.editable}
                                    enableVirtualPayment={ENABLE_VIRTUAL_PAYMENT && !!paymentCardRules?.enabled}
                                />
                            ) : null;
                        })}
                    </Carousel>
                </div>
            </main>
            <UploadDocModal
                open={uploadModalOpen}
                closeModal={() => setUploadModalOpen(false)}
                onFormUpdate={setEstimatesForm}
                formValues={estimatesForm}
                existingDocs={getExisingDocs()}
                handleSubmit={handleSubmit}
                teamCompliance={teamCompliance}
            />
        </>
    ) : (
        <Redirect to={`/event/${event.id}/venue/inquiries`} />
    );
};

export default VenueProposalAccepted;
