import React from 'react';
import styled from 'styled-components';

import { Column, Row } from 'ui';

import { Popover } from '@material-ui/core';
import { Link, LinkProps } from 'react-router-dom';

import { ReactComponent as GearSvg } from 'images/icons/pencil2.svg';
import { withInteractibleIconStyles } from 'shared';
import colorFns from 'colorFns';

const GearIcon = styled(withInteractibleIconStyles(GearSvg))`
    stroke: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.74)};
`;

type MenuItem = {
    icon: React.ReactNode;
    label: string;
} & (
    | {
          onClick: () => void;
          to?: undefined;
      }
    | {
          onClick?: undefined;
          to: LinkProps['to'];
      }
);

const MenuItemRow = styled(Row)`
    width: 300px;
    padding: 20px;
    height: 68px;
    box-sizing: border-box;

    cursor: pointer;
`;

const IconCol = styled(Column)`
    min-width: 24px;
    max-width: 24px;
`;

const MenuPopover = styled(Popover)`
    .MuiPaper-root > *:not(:last-child) {
        border-bottom: 1px solid ${colorFns.softBorder};
    }
`;

const BlockLink = styled(Link)`
    display: block;
`;

export default function Menu({ items }: { items: MenuItem[] }) {
    const [menuAnchor, setMenuAnchor] = React.useState<Nullable<SVGSVGElement>>(null);

    const menuItems = [...items];

    return (
        <>
            <GearIcon onClick={e => setMenuAnchor(e.currentTarget)} />
            <MenuPopover
                style={{ marginTop: 20 }}
                open={!!menuAnchor}
                anchorEl={menuAnchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={() => setMenuAnchor(null)}
            >
                {menuItems.map(({ icon, label, onClick, to }) => {
                    const content = (
                        <MenuItemRow
                            alignItems="center"
                            itemSpacing="smallish"
                            onClick={() => {
                                onClick?.();
                                setMenuAnchor(null);
                            }}
                        >
                            <IconCol>{icon}</IconCol>
                            <b>{label}</b>
                        </MenuItemRow>
                    );

                    return to ? (
                        <BlockLink key={label} to={to}>
                            {content}
                        </BlockLink>
                    ) : (
                        <React.Fragment key="label">{content}</React.Fragment>
                    );
                })}
            </MenuPopover>
        </>
    );
}
