import {
    PublicClientApplication,
    AuthenticationResult,
    AccountInfo,
    EndSessionRequest,
    RedirectRequest,
    PopupRequest,
    BrowserAuthError,
    BrowserAuthErrorMessage,
} from '@azure/msal-browser';

import { MSAL_CONFIG } from 'msGraphConfig';

export class AzureAuthenticationContext {
    private myMSALObj: PublicClientApplication = new PublicClientApplication(MSAL_CONFIG);
    private account?: AccountInfo;
    private loginRedirectRequest?: RedirectRequest;
    private loginRequest?: PopupRequest;

    public isAuthenticationConfigured = false;

    constructor() {
        // @ts-ignore
        this.account = null;
        this.setRequestObjects();
        if (MSAL_CONFIG?.auth?.clientId) {
            this.isAuthenticationConfigured = true;
        }
    }

    private setRequestObjects(): void {
        this.loginRequest = {
            scopes: [],
            prompt: 'select_account',
        };

        this.loginRedirectRequest = {
            ...this.loginRequest,
        };
    }

    async handleRedirect() {
        try {
            return await this.myMSALObj.handleRedirectPromise(window.location.hash);
        } catch (e) {
            throw e;
        }
    }

    async login(signInType: string, redirect?: string) {
        sessionStorage.clear();

        if (signInType === 'loginPopup') {
            try {
                const resp = await this.myMSALObj.loginPopup(this.loginRequest);
                this.handleResponse(resp);
                return resp;
            } catch (e) {
                if (e instanceof BrowserAuthError) {
                    if (e.errorCode === BrowserAuthErrorMessage.userCancelledError.code) return;
                }
                throw e;
            }
        } else if (signInType === 'loginRedirect') {
            try {
                this.myMSALObj.loginRedirect(
                    this.loginRedirectRequest
                        ? { ...this.loginRedirectRequest, redirectUri: redirect, redirectStartPage: redirect }
                        : this.loginRedirectRequest
                );
            } catch (e) {
                throw e;
            }
        }
    }

    logout(account: AccountInfo): void {
        const logOutRequest: EndSessionRequest = {
            account,
        };

        this.myMSALObj.logout(logOutRequest);
    }

    handleResponse(response: AuthenticationResult, incomingFunction?: (acctInfo: AccountInfo) => void) {
        if (response !== null && response.account !== null) {
            this.account = response.account;
        } else {
            this.account = this.getAccount();
        }

        if (this.account) {
            incomingFunction?.(this.account);
        }
    }

    private getAccount(): AccountInfo | undefined {
        const currentAccounts = this.myMSALObj.getAllAccounts();
        if (currentAccounts === null) {
            // @ts-ignore
            return undefined;
        }

        if (currentAccounts.length > 1) {
            // TBD: Add choose account code here
            // @ts-ignore
            return currentAccounts[0];
        } else if (currentAccounts.length === 1) {
            return currentAccounts[0];
        }
    }
}

export default AzureAuthenticationContext;
