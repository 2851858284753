import { post, get } from '.';

const ORIGIN = `${window.location.origin}/`;

// Move this to main API file when it is converted to typescript
export function isHTTPError(e: any): e is string {
    return typeof e === 'string';
}

export const sendMagicLink = ({
    email,
    teamName,
    redirectUrl,
}: {
    email?: string;
    teamName?: string;
    redirectUrl?: string;
}) =>
    post(
        'authenticate',
        { email, teamName, redirectUrl, origin: ORIGIN, useMagicLink: true },
        { requireToken: false, showErrorCode: true }
    );

export function isAuthenticationPartialSuccess(error: unknown): error is BizlyAPIError.Authentication {
    const expectedError = error as BizlyAPIError.Authentication;
    const teams = expectedError?.raw?.teams;
    return expectedError?.raw?.success === false && Array.isArray(teams) && teams.length === 0;
}

export function isSSOOnlyAccount(error: unknown): error is BizlyAPIError.Authentication {
    const expectedError = error as BizlyAPIError.Authentication;
    return expectedError?.raw?.success === false && typeof expectedError?.raw?.ssoLink === 'string';
}

export const loginWithGoogleId = ({
    email,
    googleIdToken,
    teamName,
}: {
    email: string;
    googleIdToken: string;
    teamName?: string;
}) =>
    post('authenticate', { email, googleIdToken, teamName }, { requireToken: false, showErrorCode: true }) as Promise<{
        user: BizlyAPI.User;
    }>;

export const loginWithMSId = ({
    email,
    msIdToken,
    msAccessToken,
    teamName,
}: {
    email: string;
    msIdToken: string;
    msAccessToken: string;
    teamName?: string;
}) =>
    post(
        'authenticate',
        { email, msIdToken, msAccessToken, teamName },
        { requireToken: false, showErrorCode: true }
    ) as Promise<{
        user: BizlyAPI.User;
    }>;

export const exchangeMagicToken = (token: string) =>
    post('exchange-token', { token }, { requireToken: false }).catch(err => {
        console.error('Error at log in:', err);
        return err;
    });

export const acceptTeamInvite = (token: string, email: string) =>
    post('accept-team-invite', { token, email }, { requireToken: false }).catch(err => {
        console.error('Error at log in:', err);
        return err;
    });

export const getLoggedInUser = () => get('me', { requireToken: true }).then(json => json.user);
