import React, { useEffect } from 'react';

import styled, { css } from 'styled-components/macro';
import isEmpty from 'lodash/isEmpty';
import { useSpring, animated } from 'react-spring';

import { useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import useKey from 'hooks/useKey';
import usePrevious from 'hooks/usePrevious';
import { padArray } from '../../util';

import { EventContext } from 'providers/event';

import Button from 'components/ui/Button';
import { Column, Copy, Row, Spacer } from 'ui';
import VenueTile, { VenueTileEmpty } from 'components/VenueTile/VenueTile';
import { ReactComponent as DownArrow } from 'images/icons/down-arrow.svg';

import InquirySchedule from './InquirySchedule';

const AccentedHeadline = styled.h3`
    font-size: 18px;
    letter-spacing: -0.1px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.accentedHeadline)};
`;

const Wrapper = styled(animated.div)`
    position: sticky;
    top: 72px;
    left: 0;
    width: 100%;

    z-index: 10;
`;

const MainWrapper = styled(animated.main)`
    height: 0;
`;

const NoSlotsRemaining = ({ viewInquiries }: { viewInquiries: () => void }) => (
    <div
        css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            height: 100%;
        `}
    >
        <div
            css={css`
                width: 440px;

                & > * {
                    margin: 20px;
                }
            `}
        >
            <div
                css={css`
                    font-size: 18px;
                    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.highlightedText)};
                `}
            >
                No Slots Remaining
            </div>
            <Copy>
                Looks like you’re an inquiry submitting Jedi and used up all four of your slots. Sit back and watch the
                proposals roll in.
            </Copy>
            <Button
                css={css`
                    width: unset;
                `}
                onClick={viewInquiries}
            >
                View Inquiries
            </Button>
        </div>
    </div>
);

const RedBanner = ({ history, eventId }: { eventId: number | string; history: RouteComponentProps['history'] }) => (
    <Row
        css={css`
            min-height: 90px;
            background: ${({ theme: { getColor, EColors } }) => getColor(EColors.warningBackground)};
            align-items: center;
            justify-content: space-between;

            box-sizing: border-box;

            & > * {
                margin: 0 20px;
            }
        `}
    >
        <div
            css={css`
                font-size: 51px;
                line-height: 1.1;
                letter-spacing: -1.56px;
                color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
                flex-shrink: 0;
            `}
        >
            Uh-oh!
        </div>
        <Copy
            css={css`
                color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
            `}
        >
            Set a date for your meeting and add guest rooms (with room count) or a meeting space (with time, setup and
            guest count) to submit an inquiry.
        </Copy>
        <Button warning secondary width="auto" onClick={() => history.push(`/event/${eventId}/planner`)}>
            Go to Planner
        </Button>
    </Row>
);

const InquiryDrawer = ({
    addedVenues,
    availableSlots,
    removeVenue,
    submitInquiry,
    viewInquiries,
    viewVenueListing,
    inquiryDraft,
    updateDraft,
}: {
    addedVenues: Bizly.Venue[];
    availableSlots: number;
    removeVenue: (venue: Bizly.Venue) => Promise<void>;
    submitInquiry: () => Promise<void>;
    viewInquiries: () => void;
    viewVenueListing: (venueId: number) => void;
    inquiryDraft?: { flexibleDates: boolean; notes: string };
    updateDraft: (draftValues: { datesFlexible: boolean; notes: string }) => void;
}) => {
    const [open, setOpen] = React.useState(false);
    const [attemptingSubmit, setAttemptingSubmit] = React.useState(false);

    const { event } = React.useContext(EventContext);

    // close if submitted:
    const prevDraft = usePrevious(inquiryDraft);
    useEffect(() => {
        const submitted = prevDraft && !inquiryDraft;
        if (submitted) setOpen(false);
    }, [prevDraft, inquiryDraft]);

    async function onSubmitInquiry() {
        setAttemptingSubmit(true);
        await submitInquiry();
        setAttemptingSubmit(false);
    }

    const history = useHistory();

    useKey('Escape', () => {
        if (open) setOpen(false);
    });

    const props = useSpring({
        height: open ? '490px' : '0px',
        opacity: open ? 1 : 0,
    });

    const validAgenda =
        event.schedule.length &&
        event.schedule[0].start &&
        event.schedule.every((day: any) => day.agenda.every((agendaItem: Bizly.AgendaItem) => agendaItem.roomSetup));

    return (
        <Wrapper>
            <div
                css={css`
                    width: 90%;
                    margin: 0 auto;

                    border: solid 1px ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
                    border-top: none;

                    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};

                    border-bottom-right-radius: 8px;
                    border-bottom-left-radius: 8px;
                `}
            >
                {open && (
                    <>
                        {!validAgenda && <RedBanner eventId={event.id} history={history} />}
                        <MainWrapper style={props}>
                            {availableSlots === 0 ? (
                                <NoSlotsRemaining viewInquiries={viewInquiries} />
                            ) : (
                                <Column
                                    css={css`
                                        height: 100%;
                                    `}
                                >
                                    <Row
                                        css={css`
                                            overflow: hidden;
                                            & > * {
                                                padding: 20px;
                                            }
                                            height: 100%;
                                        `}
                                    >
                                        <div
                                            css={css`
                                                flex: 2;

                                                height: 100%;
                                                box-sizing: border-box;
                                                overflow: auto;
                                            `}
                                        >
                                            <AccentedHeadline>Build Your Inquiry</AccentedHeadline>
                                            <Spacer />
                                            <Copy>You can inquire with up to 4 venues at a time.</Copy>
                                            <Copy>Click the + icon on a venue card to add it to the inquiry.</Copy>
                                            <Spacer />
                                            <Spacer />
                                            <Row
                                                css={css`
                                                    flex-wrap: wrap;
                                                    margin: -12px;
                                                    position: relative;
                                                `}
                                            >
                                                {padArray(addedVenues, availableSlots).map((venue, index) =>
                                                    venue ? (
                                                        <VenueTile
                                                            key={venue.id}
                                                            venue={venue}
                                                            format="tile"
                                                            pillType="preferenceCategory"
                                                            width="calc(100% / 4)"
                                                            minWidth="158px"
                                                            fixedRatio="calc(160 / 208 * 100%)"
                                                            padding="12px"
                                                            onClick={viewVenueListing}
                                                            onClose={() => removeVenue(venue)}
                                                        />
                                                    ) : (
                                                        <VenueTileEmpty
                                                            key={index.toString()}
                                                            width="calc(100% / 4)"
                                                            minWidth="158px"
                                                            fixedRatio="calc(160 / 208 * 100%)"
                                                            padding="12px"
                                                            withPin
                                                        />
                                                    )
                                                )}
                                            </Row>
                                            <Spacer />
                                            <Spacer />
                                            <Button
                                                disabled={isEmpty(addedVenues) || attemptingSubmit || !validAgenda}
                                                css={css`
                                                    width: inherit;
                                                `}
                                                onClick={async () => {
                                                    await onSubmitInquiry();
                                                }}
                                            >
                                                Submit Inquiry
                                            </Button>
                                        </div>
                                        <InquirySchedule inquiryDraft={inquiryDraft} updateDraft={updateDraft} />
                                    </Row>
                                </Column>
                            )}
                        </MainWrapper>
                    </>
                )}
                <div
                    css={css`
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        height: 40px;

                        cursor: pointer;

                        border-top: ${({ theme: { getColor, EColors } }) =>
                            open ? `solid 1px ${getColor(EColors.lightGrey)}` : null};

                        & > * {
                            margin: 0 2px;
                        }
                    `}
                    onClick={() => setOpen(!open)}
                >
                    {open ? (
                        <>
                            <div>Close Inquiry</div>
                            <DownArrow
                                css={css`
                                    fill: none;
                                    width: 14px;
                                    transform: rotate(180deg);
                                `}
                            />
                        </>
                    ) : (
                        <>
                            <div>View Inquiry</div>
                            <DownArrow
                                css={css`
                                    fill: none;
                                    width: 14px;
                                `}
                            />
                        </>
                    )}
                </div>
            </div>
        </Wrapper>
    );
};

export default InquiryDrawer;
