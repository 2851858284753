import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { H5Headline } from 'components/ui/Headline';
import { Spacer, Column } from 'ui';

import Form from 'components/Form';
import { fields, schema } from './contactFormSchema';

import { proposalFormToFormData, TContactFormValues, getErrorMessage, formDataToProposalForm } from './utils';
import { useRegisterValidator, TFormSectionProps } from '../utils';

const Copy = styled.div`
    font-size: 18px;
    line-height: 1.5;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkerGrey)};
`;

const AssymmetricalLeftCol = styled(Column)`
    margin-left: 48px;
    margin-right: ${48 * 3}px;
`;

const ContactInformationForm = ({ onChange, registerValidator, disabled, ...rest }: TFormSectionProps) => {
    const [contactValues, setContactValues] = useState<TContactFormValues>(proposalFormToFormData(rest));

    const updateContactValues = ({ value }: { value: TContactFormValues }) => {
        setContactValues(value);
        onChange();
    };

    useRegisterValidator(contactValues, registerValidator, getErrorMessage, formDataToProposalForm);

    return (
        <Column>
            <H5Headline>Let's start with the basics.</H5Headline>
            <Spacer small />
            <Copy>
                If your proposal is accepted, we'll pass your contact information along to our client
                <br />
                so they can reach out and work through the details.
            </Copy>

            <Spacer largest />

            <AssymmetricalLeftCol>
                <Form
                    fields={fields(rest.proposal?.msaDocumentUrl)}
                    schema={schema(rest.proposal?.msaDocumentUrl)}
                    value={contactValues}
                    onChange={updateContactValues}
                    disabled={disabled}
                />
            </AssymmetricalLeftCol>
        </Column>
    );
};

export default ContactInformationForm;
