import styled from 'styled-components';

import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import colorFns from 'colorFns';

type TCircleXIcon = {
    inverted?: boolean;
    onClick?: (e: React.SyntheticEvent) => void;
};
const CircleXIcon = styled(PlusIcon)<TCircleXIcon>`
    transform: rotateZ(45deg);
    width: 16px;
    height: 16px;
    padding: 6px;
    border-radius: 14px;
    background-color: ${({ inverted, theme: { getColor, EColors } }) =>
        inverted ? getColor(EColors.pureWhite) : getColor(EColors.warningAction)};
    color: ${({ inverted, theme: { getColor, EColors } }) =>
        inverted ? getColor(EColors.warningAction) : getColor(EColors.pureWhite)};

    ${props =>
        props.onClick &&
        `
    &:hover,
    &:focus {
        background-color: ${props.inverted ? colorFns.pureWhite(props) : colorFns.warningActionHover(props)};
        color: ${props.inverted ? colorFns.warningActionHover(props) : colorFns.pureWhite(props)};
    }
    `}

    flex: 0 0 auto;
    cursor: ${props => (props.onClick ? 'pointer' : 'default')};
`;

export default CircleXIcon;
