import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { tzMoment, userTimeZone } from 'utils/moment';
import range from 'lodash/range';
import groupBy from 'lodash/groupBy';

import { useUser } from 'providers/user';
import { isBlankTemplateId as isBlankPlaybookId } from 'api/eventTemplates'; // TODO: Update these helper names to use "Playbook" once old "Templates" page is depricated
import {
    useMeetings,
    selectMeetings,
    isLoading as isLoadingMeetings,
    LoadMeetings,
    meetingsActions,
    isLoadingLimit,
} from 'stores/meetings';
import { LoadPlaybooks, usePlaybooks } from 'stores/playbooks';
import { LoadEventTemplates } from 'stores/eventTemplates';
import { LoadCalendar, selectBlocks, useCalendar, isBizly, isLoading as isLoadingCalendar } from 'stores/calendar';
import { nudgesActions, useNudges } from 'stores/nudges';

import { Row, Copy, Spacer, Column } from 'ui';
import { H5Headline, H3Headline } from 'components/ui/Headline';
import Button from 'components/ui/Button';
import TextButton from 'components/ui/Button/TextButton';
import { SpinnerOverlay } from 'components/Spinner';

import SideNav from 'components/SideNav';
import HomeScreenBanner from 'components/Home/HomeScreenBanner';
import PagedPlaybooks from 'components/Templates/PagedPlaybooks';
import ScheduledList from 'pages/Meetings/ScheduledList';
import UnschedList from 'pages/Meetings/UnschedList';
import HealthAndSafetyInfo from 'components/HealthAndSafetyInfo';
import IntegrationsPrompt from 'components/IntegrationsPrompt';
import EventCreateModal from 'components/EventCreateModal';

import { ReactComponent as PlusSVG } from 'images/icons/plus.svg';
import { ReactComponent as CalIconSVG } from 'images/icons/calendar.svg';

import { mapValues } from 'lodash';
import LoadEventTemplateTags from '../stores/event-template-tags/LoadEventTemplateTags';

const CalIcon = styled(CalIconSVG).attrs({ viewBox: '-3 0 27 27' })`
    width: 22px;
    height: 22px;
`;

const Card = styled(Column)`
    position: relative;
    width: 500px;

    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    border-radius: 8px;

    padding: 24px;
    box-sizing: border-box;
`;

const CardTitle = styled(H3Headline)`
    font-weight: 700;
`;

const SubHeading = styled(Copy)`
    font-size: 14px;
    margin-top: 4px;
    font-weight: 900;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.74)};
`;

const PlusIcon = styled(PlusSVG)``;
const CreateButton = styled(Button)`
    height: 45px;
    padding-left: 10px;

    border-radius: 8px;

    ${PlusIcon} {
        display: inline-flex;
        white-space: nowrap;
        padding: 0;
        width: 16px;
        height: 16px;

        background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};

        border-radius: 50%;
        min-width: 0;
        align-items: center;
        justify-content: center;

        margin-right: 10px;
    }
`;

const sortByMostRecent = (templates: Bizly.EventTemplate[]) =>
    templates.slice().sort((a, b) => (moment(a.createdAt).isAfter(b.createdAt) ? -1 : 1));

const involvesUser = (calItem: { createdBy?: { email: string }; attendingUserIds?: number[] }, user: Bizly.User) =>
    calItem.createdBy?.email === user.email || (user.id && (calItem.attendingUserIds ?? []).includes(user.id));

export default function Homebase() {
    const { user } = useUser();
    const { enqueueSnackbar } = useSnackbar();

    const history = useHistory();
    const goToScheduled = () => history.push(`/meetings`);
    const goToUnscheduled = () => history.push(`/meetings/drafts`);

    const viewEvent = async (event: {
        id: string | number;
        published?: boolean;
        createdBy?: BizlyAPI.CreatedBy;
        calId?: number;
        uId?: string;
        immutableId?: string;
        origin?: string | null;
    }) => {
        if (isBizly(event)) {
            return history.push(`/meetings/${event.id}${!event.published ? '/edit' : ''}`);
        }

        if (event.calId !== undefined || event.uId !== undefined) {
            if (event.createdBy?.email !== user.email) {
                return history.push(`/meetings/${event.immutableId}`);
            }

            try {
                const meeting = await meetingsActions.subsume(
                    event.calId ?? event.id,
                    event.uId ?? event.id,
                    startOfWeek
                );
                if (meeting) return history.push(`/meetings/${meeting.id}`);
                return;
            } catch (e) {
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            }
        }
    };

    const date = React.useMemo(() => new Date(), []);

    const startOfWeek = moment(date)
        .startOf('week')
        .toDate();

    const week = range(7).map(days =>
        moment(date)
            .startOf('week')
            .add(days, 'days')
    );

    const ofWeek = useMeetings(selectMeetings(startOfWeek));
    const isLoadingWeek = useMeetings(isLoadingMeetings(startOfWeek));
    const unsched = useMeetings(selectMeetings(5));
    const longUnsched = useMeetings(selectMeetings(1000));
    const loadingUnscheduled = useMeetings(isLoadingLimit(5));

    const { playbooks, loaded: loadedPlaybooks } = usePlaybooks();

    const [globalPlaybooks, setGlobalPlaybooks] = useState<BizlyAPI.Complete.Playbook[]>([]);
    useEffect(() => {
        if (playbooks) {
            const playbooksList = Object.values(playbooks);
            const { global = [] } = groupBy(sortByMostRecent(playbooksList), 'categoryName');
            const globalTempsWithoutBlank = global.filter(({ id }) => !isBlankPlaybookId(id));

            setGlobalPlaybooks(globalTempsWithoutBlank.slice(0, 5));
        }
    }, [playbooks]);

    const today = React.useMemo(() => tzMoment(new Date(), userTimeZone).startOf('day'), []);

    const calItemsWeek = useCalendar(selectBlocks(startOfWeek));
    const isLoadingCalWeek = useCalendar(isLoadingCalendar(startOfWeek));
    const upcomingCalItemsThisWeek = React.useMemo(
        () =>
            Object.values(calItemsWeek)
                .filter(
                    item =>
                        !isBizly(item) &&
                        item.startsAt &&
                        item.duration > 0 &&
                        tzMoment(item.startsAt, item.timeZone)
                            .tz(userTimeZone)
                            .startOf('day')
                            .isSameOrAfter(today) &&
                        involvesUser(item, user)
                )
                .map(item => ({ ...item, calId: item.id })),
        [calItemsWeek, today, user]
    );

    const { users, meetings: allMeetings } = useCalendar();
    const meetingIdToUsers = mapValues(allMeetings, meeting =>
        (meeting.attendingUserIds || []).map(id => users[id]).filter(u => u)
    );

    const upcomingThisWeek = ofWeek.filter(
        m =>
            tzMoment(m.startsAt, m.timeZone)
                .tz(userTimeZone)
                .startOf('day')
                .isSameOrAfter(today) && involvesUser(allMeetings[m.id] ?? {}, user)
    );

    const { subsuming } = useMeetings();

    const handleCreateMeetingButtonClick = () => {
        return history.push(`/meetings/new`);
    };

    const hasHealthSafetyPolicies = React.useMemo(() => {
        const healthSafetyCategories = user?.team?.healthAndSafety?.categories || [];
        return healthSafetyCategories.some(category => category.policies.length > 0);
    }, [user]);

    const Header = () => (
        <>
            <H5Headline>{moment().format('MMMM Do, YYYY')}</H5Headline>
            <Spacer small />

            <CreateButton width="auto" onClick={handleCreateMeetingButtonClick}>
                <Row alignItems="center">
                    <PlusIcon />
                    Create New Meeting
                </Row>
            </CreateButton>
            <Spacer />
        </>
    );

    const { showFirstTimeIntegrations } = useNudges();
    const skip = () => {
        nudgesActions.mergeNudges({
            showFirstTimeIntegrations: false,
        });
    };

    return (
        <SideNav>
            <LoadPlaybooks />
            <LoadEventTemplateTags />
            <Header />

            <Spacer small />
            <Row>
                <Column itemSpacing="default">
                    <Card itemSpacing="small">
                        <LoadMeetings date={date} />
                        <LoadCalendar date={date} />

                        <Row alignItems="flex-start" justifyContent="space-between">
                            <Column>
                                <CardTitle>Meetings</CardTitle>
                                <SubHeading>
                                    {[week[0].format('MM/DD'), week[6].format('MM/DD')].join(' - ')}
                                </SubHeading>
                            </Column>
                            <TextButton onClick={goToScheduled} secondary>
                                <Row alignItems="center" itemSpacing="small">
                                    <CalIcon />
                                    View all meetings
                                </Row>
                            </TextButton>
                        </Row>
                        <ScheduledList
                            meetings={upcomingThisWeek}
                            calItems={upcomingCalItemsThisWeek}
                            meetingIdToUsers={meetingIdToUsers}
                            onClick={meeting => viewEvent({ ...meeting, published: true })}
                            narrow
                            loading={isLoadingWeek || isLoadingCalWeek}
                            handleCreateMeetingButtonClick={handleCreateMeetingButtonClick}
                        />
                    </Card>
                    <Card itemSpacing="small">
                        <LoadMeetings limit={5} />
                        <Row>
                            <CardTitle>Drafts</CardTitle>
                        </Row>

                        <UnschedList
                            meetings={loadingUnscheduled && unsched.length === 0 ? longUnsched.slice(0, 5) : unsched}
                            loading={loadingUnscheduled && longUnsched.length === 0}
                            onClick={viewEvent}
                            onDelete={meetingsActions.delete}
                        />
                        <Row justifyContent="center">
                            <TextButton onClick={goToUnscheduled} asLink secondary>
                                View all drafts
                            </TextButton>
                        </Row>
                    </Card>
                </Column>
                <Spacer small />
                <Column itemSpacing="default">
                    <LoadEventTemplates />
                    <PagedPlaybooks
                        title="Playbooks"
                        subheading="Choose from our well crafted pre-built meetings."
                        playbooks={globalPlaybooks}
                        isSummary
                        isLoading={!loadedPlaybooks}
                    />
                </Column>
            </Row>

            {hasHealthSafetyPolicies && (
                <>
                    <Spacer large />
                    <HealthAndSafetyInfo />
                </>
            )}

            {user.team?.bulletinMessage && (
                <>
                    <Spacer larger />
                    <HomeScreenBanner message={user.team.bulletinMessage} teamImage={user.team.imageUrlWhite} />
                </>
            )}

            {showFirstTimeIntegrations && <IntegrationsPrompt onSkip={skip} onlySkip title="Connect an Integration?" />}

            {false && <EventCreateModal onClose={() => {}} />}
            {subsuming && <SpinnerOverlay fixed />}
        </SideNav>
    );
}
