import React, { Component } from 'react';
import { withRouter, Link, Route } from 'react-router-dom';
import styled from 'styled-components/macro';
import qs from 'query-string';

import { getRSVPInfo, updateRSVPInfo, questionDefinitionIds } from 'api';

import ResponsiveMetaTag from 'components/ResponsiveMetaTag';
import { withSnackbar } from 'notistack';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import isDate from 'lodash/isDate';

import { mergeDateTime } from 'utils/date_util';
import { withUserContext } from 'providers/user';
import { setCookie } from 'utils/tokens';
import { isEmptyString } from 'components/ProposalForm/utils';
import { TRAVEL_NEEDS_VALUES } from 'pages/RSVPForm';
import { getSurveyQuestionError } from './Survey';

import { Column, Button as UIButton, FixedBackground } from 'ui';
import { PageNotFound } from './PageNotFound';

import RSVPHeader from 'components/RSVP/RSVPHeader';
import RSVPCard from 'components/RSVP/RSVPCard';
import { Spinner } from 'components/Spinner';
import Button from 'components/ui/Button';

import { ReactComponent as WhiteBizmark } from 'images/icons/white_bizmark.svg';

const CARD_WIDTH = 680;

const CenteredColumn = styled(Column)`
    padding: 112px 0;
    margin: auto;
`;

const SignInButton = styled(UIButton)`
    background-color: transparent;
    border-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

const SignInLink = styled(Link)`
    position: absolute;
    top: 36px;
    right: 36px;
`;

const FullScreen = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

const SigninAndLogo = ({ showSignIn }) => (
    <FullScreen>
        {showSignIn && (
            <SignInLink to="/login">
                <SignInButton variant="outlined">Sign in to Bizly</SignInButton>
            </SignInLink>
        )}
        <WhiteBizmark style={{ position: 'absolute', bottom: 36, right: 36 }} />
    </FullScreen>
);

const ParcelRSVP = ({
    userAnonymous,
    attending,
    team,
    imageUrl: image,
    name,
    description,
    startsAt,
    endsAt,

    startDate,
    startTime,
    endDate,
    endTime,

    timeZone,
    schedule,
    agenda,
    location,
    address,
    cityState,

    virtualMeeting,
    vmSharingMethod,

    parcelDates,
    attendee,
    formSettings,
    submitted,
    onChange,
    handleSubmit,
    editResponse,

    saveButtonDisabled,
}) => (
    <>
        <Column style={{ position: 'relative', display: 'block' }}>
            <SigninAndLogo showSignIn={userAnonymous && (submitted || !attending)} />
            <CenteredColumn style={{ alignItems: 'center' }}>
                <FixedBackground backgroundColor={team ? team.color : 'transparent'} />
                <RSVPHeader
                    name={name}
                    startsAt={startDate && startTime ? mergeDateTime(startDate, startTime) : startsAt}
                    endsAt={endDate && endTime && mergeDateTime(endDate, endTime)}
                    description={description}
                    location={location}
                    virtualMeeting={virtualMeeting}
                    timeZone={timeZone}
                    attending={attending}
                    submitted={submitted}
                    editResponse={editResponse}
                />
                <Column style={{ width: CARD_WIDTH }}>
                    <RSVPCard
                        team={team}
                        image={image}
                        name={name}
                        description={description}
                        startsAt={startsAt}
                        endsAt={endsAt}
                        schedule={schedule}
                        plannerSchedule={agenda}
                        parcelDates={parcelDates}
                        timezone={timeZone}
                        location={location}
                        address={address}
                        cityState={cityState}
                        vmSharingMethod={vmSharingMethod}
                        virtualMeeting={virtualMeeting}
                        plannedBy={{}}
                        isPreview={false}
                        attendee={attendee}
                        formSettings={formSettings}
                        attending={attending}
                        submitted={submitted}
                        onChange={onChange}
                        handleSubmit={handleSubmit}
                        saveButtonDisabled={saveButtonDisabled}
                    />
                </Column>
            </CenteredColumn>
        </Column>
    </>
);

export const ATTENDING_STATUSES = {
    ATTENDING: 'attending',
    NOT_ATTENDING: 'not attending',
};

const isNullOrEmptyString = strOrNull => strOrNull === null || isEmptyString(strOrNull);

const getAttendeeBaseError = attendee => {
    const { firstName, lastName, phone } = attendee;

    if (isNullOrEmptyString(firstName)) return 'First name is required.';
    if (isNullOrEmptyString(lastName)) return 'Last name is required.';
    if (isNullOrEmptyString(phone)) return 'Phone number is required.';
    if (phone.length < 11 || phone.length > 12) return 'Phone number is invalid.';

    return false;
};

const getAttendeeDefaultsError = (attendee, formSettings) => {
    const enabledDefaultsById = keyBy(formSettings.defaultFieldsEnabled, 'id');
    const { travelDates: travelDatesId, attendanceType: attendanceTypeId } = questionDefinitionIds;

    const { needsTravel, checkInDate, checkOutDate, attendanceType } = attendee;

    if (
        enabledDefaultsById[travelDatesId] &&
        needsTravel === TRAVEL_NEEDS_VALUES.YES &&
        (!isDate(checkInDate) || !isDate(checkOutDate))
    )
        return 'Both check in and check out dates are required for travel accommodations.';

    if (enabledDefaultsById[attendanceTypeId] && isNullOrEmptyString(attendanceType))
        return 'Attendance type is required.';

    return false;
};

const getAttendeeCustomQuestionsError = attendee => {
    return getSurveyQuestionError(
        attendee.customQuestions,
        isEmpty(attendee.customQuestionResponses) ? attendee.customQuestions : attendee.customQuestionResponses
    );
};

class RSVP extends Component {
    state = { viewportWidth: 0 };

    getQuery = () => {
        const { match, location } = this.props;
        const { eventId } = match.params;
        const { attending, ...restQuery } = qs.parse(location.search, {
            parseBooleans: true,
        });
        const query = '?' + qs.stringify(restQuery);
        const rsvpStatus = {
            true: ATTENDING_STATUSES.ATTENDING,
            false: ATTENDING_STATUSES.NOT_ATTENDING,
        }[attending];

        return { eventId, query, attending, rsvpStatus };
    };

    goToMeeting = user => {
        setCookie(user.authToken);
        this.props.userContext.setUserState({ ...user, loaded: true, token: user.authToken });

        const { eventId } = this.getQuery();
        this.props.history.push(`/meetings/${eventId}`);
    };

    async componentDidMount() {
        const { eventId, query, rsvpStatus } = this.getQuery();

        let rsvpInfo = {};
        try {
            rsvpInfo = await getRSVPInfo(eventId, query);
            let { event, team, attendee, formSettings } = rsvpInfo;

            if (rsvpStatus === ATTENDING_STATUSES.NOT_ATTENDING) {
                attendee = { ...attendee, status: rsvpStatus };
                const res = await updateRSVPInfo(eventId, query, attendee);

                if (res.user) {
                    return this.goToMeeting(res.user);
                }
            } else if (attendee.status === ATTENDING_STATUSES.ATTENDING) {
                if (rsvpInfo.user) {
                    return this.goToMeeting(rsvpInfo.user);
                }
            }

            this.setState({
                event,
                team,
                attendee,
                submitted: attendee.status === ATTENDING_STATUSES.ATTENDING,
                formSettings,
            });
        } catch (e) {
            console.error(`Could not fetch data for meeting ${eventId}`);
            this.setState({ error: true });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.snackbarKey && prevState.snackbarKey !== this.state.snackbarKey) {
            this.props.closeSnackbar(prevState.snackbarKey);
        }
    }

    handleSubmit = async () => {
        const { eventId, query, rsvpStatus } = this.getQuery();
        const { attendee, formSettings } = this.state;

        const error =
            getAttendeeBaseError(attendee) ||
            getAttendeeDefaultsError(attendee, formSettings) ||
            getAttendeeCustomQuestionsError(attendee);
        if (error) {
            return this.setState({
                snackbarKey: this.props.enqueueSnackbar(error, {
                    variant: 'error',
                    persist: true,
                    action: (
                        <Button warning onClick={() => this.setState({ snackbarKey: null })}>
                            Dismiss
                        </Button>
                    ),
                }),
            });
        }

        this.setState({ saving: true });
        try {
            const updatedAttendee = { ...attendee, status: rsvpStatus || ATTENDING_STATUSES.ATTENDING };
            const res = await updateRSVPInfo(eventId, query, updatedAttendee);
            this.setState(updatedAttendee);

            if (res.user) {
                return this.goToMeeting(res.user);
            }
        } catch (e) {
            this.props.enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
        this.setState({ submitted: true, saving: false });
    };

    editResponse = () => this.setState({ submitted: false });

    handleChange = ({ value }) => {
        this.setState({ attendee: value, snackbarKey: null });
    };

    render() {
        const { attending = true } = this.getQuery();
        const { event, error, attendee, formSettings, team, saving, submitted } = this.state;
        const { userAnonymous } = this.props;

        if (error) {
            return <Route component={PageNotFound} />;
        } else if (!attendee) {
            return <Spinner />;
        }

        const { startDate, startTime, endDate, endTime } = event;
        const parcelDates = startDate && startTime && endDate && endTime && { startDate, startTime, endDate, endTime };

        return (
            <>
                <ResponsiveMetaTag />
                <ParcelRSVP
                    userAnonymous={userAnonymous}
                    attending={attending}
                    {...event}
                    parcelDates={parcelDates}
                    team={team}
                    saveButtonDisabled={saving}
                    attendee={attendee}
                    formSettings={formSettings}
                    submitted={submitted}
                    onChange={this.handleChange}
                    handleSubmit={this.handleSubmit}
                    editResponse={this.editResponse}
                />
            </>
        );
    }
}

export default withUserContext(withRouter(withSnackbar(RSVP)));
