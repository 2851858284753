import {
    TFilterValueBase,
    TFilterValue,
    gradeLabels,
    venueTypesDict,
    venueStylesDict,
    formatRadius,
    schema,
} from './VenueSearchFilters';

/*
  A Filter Form is generally a responsive version of a form and provides a summary of applied changes

  To convert a form into a filter form and have a summary that tells us what filters are applied,
  usually we have to create helper functions to determine the number of filters applied

  To tokenize a form, it's the same process
*/
type TFilterApplied = {
    [filter in keyof TFilterValueBase]: (value: TFilterValueBase[filter] | any) => boolean;
};
const filterApplied: TFilterApplied = {
    grades: value => (Array.isArray(value) ? value.length > 0 && (value[0] !== 0 || value[1] !== 2) : !!value),
    hideUngraded: value => value,
    types: value => (Array.isArray(value) ? value.length > 0 : !!value),
    decors: value => (Array.isArray(value) ? value.length > 0 : !!value),
    preferredOnly: value => value,
    radius: value => value !== 15,
};

type TEntries = [keyof TFilterValue, TFilterValue[keyof TFilterValue]][];
export const getFilterCount = (appliedFilters: TFilterValue) =>
    (Object.entries(appliedFilters) as TEntries).filter(([filter, value]) =>
        filterApplied[filter] ? filterApplied[filter](value) : false
    ).length;

type TToken = {
    id: string;
    label: string;
    onRemove: () => void;
};
type TTokenizers = {
    [filter in keyof TFilterValue]: (options: {
        value: TFilterValueBase[filter];
        appliedFilters: TFilterValue;
        setFilters: (newFilterValue: TFilterValue) => void;
        options: { [key: string]: any };
    }) => TToken[];
};
const tokenizers: TTokenizers = {
    grades: ({ value, appliedFilters, setFilters }) => {
        // ignore defaults:
        const [start, end] = value;
        if (start === 0 && end === 2) return [];

        return [
            {
                id: 'grades',
                label: start === end ? gradeLabels[start] : `${gradeLabels[start]} to ${gradeLabels[end]}`,
                onRemove: () => {
                    const { grades, ...filters } = appliedFilters;
                    setFilters(filters);
                },
            },
        ];
    },
    hideUngraded: ({ value, appliedFilters, setFilters }) =>
        value
            ? [
                  {
                      id: 'nongraded-hidden',
                      label: 'Hide Nongraded',
                      onRemove: () => {
                          const { hideUngraded, ...filters } = appliedFilters;
                          setFilters(filters);
                      },
                  },
              ]
            : [],
    types: ({ value, appliedFilters, setFilters, options }) =>
        value.map(id => ({
            id: `type-${id}`,
            label: id === 'corporate office' ? options.teamName : (venueTypesDict[id] || {}).label,
            onRemove: () => {
                setFilters({
                    ...appliedFilters,
                    types: value.filter(selectedType => selectedType !== id),
                });
            },
        })),
    decors: ({ value, appliedFilters, setFilters }) =>
        value.map(id => ({
            id: `style-${id}`,
            label: venueStylesDict[id].label,
            onRemove: () => {
                setFilters({
                    ...appliedFilters,
                    decors: value.filter(selectedStyle => selectedStyle !== id),
                });
            },
        })),
    preferredOnly: ({ value, appliedFilters, setFilters }) =>
        value
            ? [
                  {
                      id: 'only-preferred',
                      label: 'Only Preferred',
                      onRemove: () => {
                          const { preferredOnly, ...filters } = appliedFilters;
                          setFilters(filters);
                      },
                  },
              ]
            : [],
    radius: ({ value, appliedFilters, setFilters }) => {
        // ignore defaults:
        if (value === 15) return [];

        return [
            {
                id: 'radius',
                label: formatRadius(value),
                onRemove: () => {
                    const { radius, ...filters } = appliedFilters;
                    setFilters(filters);
                },
            },
        ];
    },
};

type TTokenizerFn = (options: {
    value: TFilterValueBase[keyof TFilterValueBase];
    appliedFilters: TFilterValue;
    setFilters: (newFilterValue: TFilterValue) => void;
    options: { [key: string]: any };
}) => TToken[];

/** Type workaround for https://github.com/Microsoft/TypeScript/issues/7294#issuecomment-465794460 */
type ArrayElem<A> = A extends readonly (infer E)[] ? E : A extends (infer F)[] ? F : never;

export function elements<T>(array: T): Array<ArrayElem<T>> {
    return array as any;
}

export const getTokens = (
    appliedFilters: TFilterValue,
    setFilters: (newFilterVal: TFilterValue) => void,
    options: { [key: string]: any } = {}
) => {
    return schema
        .map(row => row.fields)
        .reduce((tokens, fields) => {
            const fieldTokens = elements(fields)
                .map(field => {
                    const value = appliedFilters[field];
                    const tokenizer = tokenizers[field] as TTokenizerFn;
                    return tokenizer && value !== undefined
                        ? tokenizer({
                              value,
                              appliedFilters,
                              setFilters,
                              options,
                          })
                        : [];
                })
                .flat();

            return [...tokens, ...fieldTokens];
        }, [] as TToken[]);
};
