const pluckFileName = (url?: string) => {
    if (!url) return url;
    const segments = url.split('/');
    const fileName = segments[segments.length - 1];

    if (fileName.length > 21) {
        const [name, ext] = fileName.split('.');
        return `${name.substr(0, 17 - ext.length)}... .${ext}`;
    }

    return segments[segments.length - 1];
};

export default pluckFileName;
