import React from 'react';
import styled from 'styled-components/macro';

import { Column, Copy, Row, Spacer } from '../ui';
import { Headline } from '../components/ui/Headline';
import TextButton from './ui/Button/TextButton';
import { SpinnerOverlay } from './Spinner';

import Dialog from '@material-ui/core/Dialog';

const Modal = styled(Column)`
    width: 400px;
    padding: 24px;
`;

const WarningHeadline = styled(Headline)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.warningText)};
    text-transform: none;
`;

const EndRow = styled(Row)`
    justify-content: flex-end;
`;

type TDeleteConfirmationModal = {
    isActive?: boolean;

    onDismiss: (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>,
        reason?: 'backdropClick' | 'escapeKeyDown'
    ) => any;
    onProceed: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;

    prompt?: React.ReactNode;
    ctaLabel?: React.ReactNode;
    dismissLabel?: React.ReactNode;

    loading?: boolean;
};

export const DeleteConfirmationModal = ({
    isActive,
    onDismiss,
    onProceed,
    prompt = '',
    ctaLabel = 'Delete',
    dismissLabel = 'Cancel',
    loading,
}: TDeleteConfirmationModal) => {
    return (
        <Dialog open={!!isActive} onClose={onDismiss}>
            <Modal>
                <WarningHeadline>Are you sure?</WarningHeadline>
                <Copy>{prompt}</Copy>
                <Spacer largest />
                <EndRow itemSpacing="smallish">
                    <TextButton secondary onClick={onDismiss}>
                        {dismissLabel}
                    </TextButton>
                    <TextButton warning onClick={onProceed}>
                        {ctaLabel}
                    </TextButton>
                </EndRow>
                {loading && <SpinnerOverlay />}
            </Modal>
        </Dialog>
    );
};

export default DeleteConfirmationModal;
