import React, { useState } from 'react';
import styled from 'styled-components';
import Menu from '@material-ui/core/Menu';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { Button as UIButton } from '../ui';

const Button = styled(UIButton)`
    ${({ secondary, theme: { getColor, EColors } }) =>
        secondary &&
        `
        background-color: ${getColor(EColors.pureWhite)};
        color: ${getColor(EColors.darkerGrey)};
    `}
`;

type TButtonMenu = {
    children: React.ReactNode;
    variant?: string;
    label?: string;
    secondary?: boolean;
};

export const ButtonMenu = ({ children, variant, label, secondary }: TButtonMenu) => {
    const [menuAnchor, setMenuAnchor] = useState(null);
    return (
        <>
            <Button
                fullWidth={false}
                onClick={(e: any) => setMenuAnchor(menuAnchor ? null : e.target)}
                variant={variant || 'contained'}
                secondary={secondary}
            >
                {label}
                <KeyboardArrowDownIcon style={{ marginLeft: '8px' }} />
            </Button>
            <Menu
                anchorEl={menuAnchor}
                open={!!menuAnchor}
                onClick={() => setMenuAnchor(null)}
                onClose={() => setMenuAnchor(null)}
            >
                {children}
            </Menu>
        </>
    );
};
