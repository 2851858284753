import { addDays } from 'date-fns';

const ENV = process.env.REACT_APP_ENV === 'dev' ? 'dev_bizly_authentication_token' : 'bizly_authentication_token';
const developmentDomain = window.location.hostname === 'localhost' || window.location.hostname.includes('.netlify');
const DOMAIN = developmentDomain ? '' : 'domain=.bizly.com;';
const newCookieString = (key: string, value: string, expiration: string) =>
    `${key}=${value}; path=/; ${DOMAIN} expires=${expiration}; secure=true;`;

export const setCookie = (token: string) => {
    const today = new Date();
    const validExpirationDate = addDays(today, 3).toUTCString();

    document.cookie = newCookieString(ENV, token, validExpirationDate);
};

export const getCookie = () => {
    const cookies = document.cookie || '';
    const parsedCookie = cookies.split(' ').find(cookie => cookie.includes(ENV));
    const token = parsedCookie && parsedCookie.split('=')[1];
    const hasToken = typeof token === 'string';

    return hasToken && token && token.replace(';', '');
};

export const removeCookie = () => {
    const expiredDate = new Date(0).toUTCString();
    document.cookie = newCookieString(ENV, '', expiredDate);
};
