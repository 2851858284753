import { TSpace, spacesToOptions } from './utils';

import VenueSpaceSelectField from './VenueSpacesSelectField';

const VENUE_SPACES_MODAL_LEFT_COLUMN = 360;

export type TModalValue = {
    id: number;
    name: string;
    imageUrl: string;
    description: string | null;
    size: number | string | null;
    maxCapacity: number | string | null;
};

const modalFields = (spaces: TSpace[]) => ({
    id: {
        type: 'select',
        fixedWidth: VENUE_SPACES_MODAL_LEFT_COLUMN,
        options: {
            options: spacesToOptions(spaces),
            placeholder: 'Select a space...',
        },
    },
    name: {
        type: 'text',
        perRow: '2/3',
        prompt: 'Name',
    },
    imageUrl: {
        type: 'image_uploader',
        prompt: 'Image',
        fixedWidth: VENUE_SPACES_MODAL_LEFT_COLUMN,
    },
    description: {
        type: 'textarea',
        prompt: 'Description',
        optional: true,
        options: {
            placeholder: 'Description that shows up on our platform. Make it awesome!',
            maxLength: 275,
        },
    },
    size: {
        type: 'text',
        prompt: 'Size',
        options: {
            numberType: 'integer',
            placeholder: 'sq ft.',
        },
    },
    maxCapacity: {
        type: 'text',
        prompt: 'Max Capacity',
        options: {
            numberType: 'integer',
            placeholder: 'people',
        },
    },
});

const detailsSchema = {
    type: 'nested',
    schema: [
        {
            fields: ['name'],
            itemSpacing: 'small',
            spacing: 'default',
        },
        {
            fields: ['description'],
            itemSpacing: 'small',
            spacing: 'default',
        },
        {
            fields: ['size', 'maxCapacity'],
            itemSpacing: 'default',
            spacing: false,
        },
    ],
};

const modalSchema = [
    { fields: ['id'], itemSpacing: 'larger', spacing: 'larger' },
    { fields: ['imageUrl', detailsSchema], itemSpacing: 'larger', spacing: false },
];

const smallFields = (spaces: TSpace[], onEdit?: () => void) => ({
    id: {
        type: VenueSpaceSelectField,
        options: {
            spaces,
            onEdit,
        },
    },
});

const smallSchema = [{ fields: ['id'], spacing: false }];

export { smallFields, smallSchema, modalFields, modalSchema };
