import React from 'react';
import styled from 'styled-components/macro';

import StepsColumn from './StepsColumn';
import { Headline as UIHeadline } from '../ui/Headline';
import { Copy as UICopy, Spacer } from '../../ui';
import Button from '../ui/Button';

import VPaymentModal from './VPaymentModal';

const Headline = styled(UIHeadline)`
    margin-top: 0;
    text-transform: none;
`;

const Copy = styled(UICopy)`
    font-size: 15px;
    line-height: 1.47;

    ul {
        padding-inline-start: 1.2em;
    }
`;

const GreyBox = styled.div`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.softAccentedBackground)};
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 8px;
`;

type VirtualPaymentPromptTypes = {
    booking: Bizly.Booking;
    paymentCardRules: Bizly.PaymentCardRules;
    openModal: () => void;
};

const DEFAULT_COPY = {
    PROMPT:
        'The venue will send a credit card authorization form or a payment link. You may also be required to make payment on site.',
    MIN_SPEND: 'Your estimate is less than the spend minimum.',
    MAX_SPEND: 'Your estimate is greater than the spend maximum.',
    REQUEST_SIGNED_DOC: 'Please upload your signed documents to request a card for your meeting.',
    REQUEST_PAYMENT:
        'Please use the link below to generate an American Express vPayment account to pay the venue directly.',
};

const VirtualPaymentsPrompt = ({ booking, paymentCardRules, openModal }: VirtualPaymentPromptTypes) => {
    const { minSpend, maxSpend } = paymentCardRules;
    const { minSpendCopy, maxSpendCopy } = paymentCardRules;
    const estimatedTotal = booking.estimates.total;
    const hasContractAndEstimates = booking.contractUrl && estimatedTotal;
    const isLessThanMinSpend = estimatedTotal && estimatedTotal < minSpend && (minSpendCopy || DEFAULT_COPY.MIN_SPEND);
    const isGreaterThanMaxSpend =
        estimatedTotal && estimatedTotal > maxSpend && (maxSpendCopy || DEFAULT_COPY.MAX_SPEND);
    const estimateOutOfRange = isLessThanMinSpend || isGreaterThanMaxSpend;
    const canRequestVirtualPayment = hasContractAndEstimates && !estimateOutOfRange;
    const requestError =
        canRequestVirtualPayment || booking.paymentCard
            ? undefined
            : hasContractAndEstimates
            ? estimateOutOfRange
            : DEFAULT_COPY.REQUEST_SIGNED_DOC;

    return (
        <GreyBox>
            <Headline>Pay with vPayment</Headline>
            {requestError ? (
                <Copy>{requestError}</Copy>
            ) : (
                <>
                    {!booking.paymentCard && (
                        <>
                            <Copy>{DEFAULT_COPY.REQUEST_PAYMENT}</Copy>
                            <Spacer small />
                        </>
                    )}
                    <Button onClick={openModal}>{booking.paymentCard ? 'View Card' : 'Request'}</Button>
                </>
            )}
        </GreyBox>
    );
};

type PaymentDetailsProps = {
    number: number;
    venue: Bizly.VenueProposal;
    copy: string;
    paymentCardRules?: Bizly.PaymentCardRules;
    enableVirtualPayment: boolean;
    updatePaymentCard: (paymentCard: Bizly.PaymentCard) => void;
};

const PaymentDetails = ({
    number,
    venue,
    copy = DEFAULT_COPY.PROMPT,
    paymentCardRules,
    enableVirtualPayment,
    updatePaymentCard,
}: PaymentDetailsProps) => {
    const [showModal, setShowModal] = React.useState(false);

    return (
        <StepsColumn number={number} headline={'Provide your payment details'}>
            <Copy dangerouslySetInnerHTML={{ __html: copy }} />
            <Spacer small />
            {enableVirtualPayment && showModal && paymentCardRules && (
                <VPaymentModal
                    paymentCardRules={paymentCardRules}
                    paymentCard={venue.booking.paymentCard}
                    estimates={venue.booking.estimates}
                    booking={venue.booking}
                    updatePaymentCard={updatePaymentCard}
                    onClose={() => setShowModal(false)}
                />
            )}
            {enableVirtualPayment && paymentCardRules && (
                <VirtualPaymentsPrompt
                    paymentCardRules={paymentCardRules}
                    booking={venue.booking}
                    openModal={() => setShowModal(true)}
                />
            )}
        </StepsColumn>
    );
};

export default PaymentDetails;
