import React from 'react';

import styled from 'styled-components';
import { format, parseISO } from 'date-fns';
import keyBy from 'lodash/keyBy';

import { Column, Spacer } from 'ui';
import { H5Headline } from 'components/ui/Headline';
import { Pane } from 'components/Pane';
import { ReactComponent as SpaceIconSVG } from 'images/icons/space.svg';

import useThemedColor from 'hooks/useThemedColor';
import fontFns from 'fontFns';

import Form from 'components/Form';
import {
    timeAndSpaceFields,
    timeAndSpaceSchema,
    setupFields,
    setupSchema,
    costsFields,
    costsSchema,
    AVFields,
    AVSchema,
    FBFields,
    FBSchema,
} from './eventSpacesFormSchema';
import {
    replaceObjInArray,
    sanitizedSpaces,
    selectedASpace,
    TEventSpacesFormValue,
    proposalFormToFormData,
    getErrorMessage,
    formDataToProposalForm,
    TESFormBooking,
    setFeeTaxOnAllES,
    TSpace,
    TOption,
    TOptionsDictionary,
    TFBOption,
} from './utils';
import { useRegisterValidator, TFormSectionProps } from '../utils';
import { TSpacePOST } from 'api';

const Copy = styled.div`
    font-size: 18px;
    line-height: 1.5;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkerGrey)};
`;

const FormContent = styled(Column)`
    margin: 0 48px;
`;

const paneStyles = (background: Themed.Color, borderColor: Themed.Color) => ({
    background,
    border: `1px solid ${borderColor}`,
    borderTop: '0px',
    padding: 0,
    overflow: 'hidden',
});

const SpaceFormContent = styled(Column)<{ header?: boolean }>`
    padding: 36px 48px 48px 48px;
    ${({ header, theme: { getColor, EColors } }) =>
        header ? `background: ${getColor(EColors.softAccentedBackground)};` : 'padding: 48px 48px 36px 48px;'}

    position: relative;
`;

const SingleBorder = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
`;

const FormSectionHeading = styled(Copy)`
    font-size: 20px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formHeading)};
    ${fontFns.formHeading}
`;

const SpaceIcon = styled(SpaceIconSVG)`
    position: absolute;
    left: 8px;
`;

type TBaseProps = {
    spaceUpdater: (
        dayIndex: number,
        spaceIndex: number,
        { field, value: newSpaceData }: { field: string; value: TESFormBooking }
    ) => void;
    spaces: TSpace[];
    onUpdateVenueSpaces: (space: TSpacePOST) => Promise<TSpace[] | false>;
    AVOptions: TOptionsDictionary<TOption>;
    FBOptions: TOptionsDictionary<TFBOption>;
    DSOptions: TOptionsDictionary<TOption>;
    setupOptions: TOption[];
    disabled?: boolean;
};

const SpaceForm = ({
    space,
    spaceIndex,
    dayIndex,
    spaceUpdater,
    onUpdateVenueSpaces,
    spaces,
    AVOptions,
    FBOptions,
    DSOptions,
    setupOptions,
    disabled,
}: {
    space: TESFormBooking;
    dayIndex: number;
    spaceIndex: number;
} & TBaseProps) => {
    const onChange = React.useCallback(
        (update: any) => {
            spaceUpdater(dayIndex, spaceIndex, update);
        },
        // we should not regenerate this function unless these change,
        // moving it into baseFormProps would regenerate this when space changes which happens all the time
        [dayIndex, spaceIndex, spaceUpdater]
    );

    const baseFormValueProps = React.useMemo(
        () => ({
            value: space,
            onChange,
            disabled,
        }),
        [space, onChange, disabled]
    );

    const requestedAv = React.useMemo(() => space.requestedAvIds || [], [space.requestedAvIds]);
    const requestedFb = React.useMemo(() => space.requestedFb || [], [space.requestedFb]);

    const venueSpaceSelected = React.useMemo(() => selectedASpace(space.proposedVenueSpaceId), [
        space.proposedVenueSpaceId,
    ]);
    const onlyShowRequest = !venueSpaceSelected;

    const timeSpaceFields = React.useMemo(() => timeAndSpaceFields(onlyShowRequest, spaces, onUpdateVenueSpaces), [
        onlyShowRequest,
        spaces,
        onUpdateVenueSpaces,
    ]);
    const mTimeSpaceSchema = React.useMemo(
        () => timeAndSpaceSchema(space.requestedSpaceName || `Space ${spaceIndex + 1}`),
        [space.requestedSpaceName, spaceIndex]
    );
    const mSetupFields = React.useMemo(() => setupFields(setupOptions), [setupOptions]);
    const mSetupSchema = React.useMemo(() => setupSchema(onlyShowRequest), [onlyShowRequest]);

    const mAVFields = React.useMemo(() => AVFields(requestedAv, AVOptions, onlyShowRequest), [
        requestedAv,
        onlyShowRequest,
        AVOptions,
    ]);
    const mAVSchema = AVSchema;
    const mFBFields = React.useMemo(() => FBFields(requestedFb, FBOptions, DSOptions, onlyShowRequest), [
        requestedFb,
        onlyShowRequest,
        FBOptions,
        DSOptions,
    ]);
    const mFBSchema = FBSchema;

    return React.useMemo(
        () => (
            <Column>
                <SpaceFormContent>
                    <FormSectionHeading>
                        <SpaceIcon />
                        Client Space Request
                    </FormSectionHeading>
                    <Spacer larger />

                    <Form fields={timeSpaceFields} schema={mTimeSpaceSchema} {...baseFormValueProps} />
                </SpaceFormContent>
                <SingleBorder />

                <SpaceFormContent header>
                    <FormSectionHeading>Setup Request(s)</FormSectionHeading>
                    <Spacer xsmall />
                    <Copy>
                        This is how the client wants the space to be set up.
                        <br />
                        What can be accommodated? {onlyShowRequest && ' Select a space above to begin responding.'}
                    </Copy>
                    <Spacer larger />

                    <Form fields={mSetupFields} schema={mSetupSchema} {...baseFormValueProps} />
                    {requestedAv.length > 0 && (
                        <>
                            <Spacer largest />
                            <Form fields={mAVFields} schema={mAVSchema} {...baseFormValueProps} />
                        </>
                    )}
                    {requestedFb.length > 0 && (
                        <>
                            <Spacer largest />
                            <Form fields={mFBFields} schema={mFBSchema} {...baseFormValueProps} />
                        </>
                    )}
                    {venueSpaceSelected && (
                        <>
                            <Spacer largest />
                            <Form fields={costsFields} schema={costsSchema} {...baseFormValueProps} />
                        </>
                    )}
                </SpaceFormContent>
            </Column>
        ),
        [
            baseFormValueProps,
            timeSpaceFields,
            mTimeSpaceSchema,
            mSetupFields,
            mSetupSchema,
            mAVFields,
            mAVSchema,
            mFBFields,
            mFBSchema,
            venueSpaceSelected,
            onlyShowRequest,
            requestedAv,
            requestedFb,
        ]
    );
};

const EventSpacesDayForm = ({
    index,
    data,
    ...childProps
}: {
    index: number;
    data: TESFormBooking[];
} & TBaseProps) => {
    const { pureWhite, lightGrey } = useThemedColor();

    return (
        <Pane
            key={data[0].requestedDate}
            label={`Day ${index + 1} - ${data.length} Space${data.length > 1 ? 's' : ''}`}
            secondaryLabel={format(parseISO(data[0].requestedDate + 'T00:00:00Z'), 'EEEE, MMMM dd, yyyy')}
            stackedLabels
            beginExpanded
            invert
            stickyHeader
            contentStyle={paneStyles(pureWhite, lightGrey)}
        >
            {() => (
                <Column>
                    {data.map((space, spaceIndex) => (
                        <Column key={space.requestedDate + space.requestedStartTime}>
                            <SpaceForm dayIndex={index} spaceIndex={spaceIndex} space={space} {...childProps} />
                            {spaceIndex < data.length - 1 && <SingleBorder />}
                        </Column>
                    ))}
                </Column>
            )}
        </Pane>
    );
};

export default function EventSpacesForm({
    onChange,
    options,
    onUpdateVenueSpaces,
    registerValidator,
    disabled,
    ...rest
}: TFormSectionProps) {
    const { spaceSetups = [], avOptions = [], fbOptions = [], diningStyles = [], venueSpaces = [] } = options || {};
    const spaces = React.useMemo(() => sanitizedSpaces(venueSpaces), [venueSpaces]);
    const AVOptionsDict = React.useMemo(() => keyBy(avOptions, option => option.id), [avOptions]);
    const FBOptionsDict = React.useMemo(() => keyBy(fbOptions, option => option.id), [fbOptions]);
    const DSOptionsDict = React.useMemo(() => keyBy(diningStyles, option => option.id), [diningStyles]);

    const [data, setData] = React.useState<TEventSpacesFormValue>(proposalFormToFormData(rest));

    useRegisterValidator(data, registerValidator, getErrorMessage, formDataToProposalForm);

    const spaceUpdater = React.useMemo(
        () => (
            dayIndex: number,
            spaceIndex: number,
            { field, value: newSpaceData }: { field: string; value: TESFormBooking }
        ) => {
            setData(prevData => {
                const prevDayData = prevData.eventSpacesByDay[dayIndex];

                let newEventSpaces = replaceObjInArray(
                    prevData.eventSpacesByDay,
                    dayIndex,
                    replaceObjInArray(prevDayData, spaceIndex, newSpaceData)
                );

                newEventSpaces = (() => {
                    const { gratuity, salesTax, serviceCharge } = newSpaceData;

                    switch (field) {
                        case 'gratuity':
                            return setFeeTaxOnAllES(newEventSpaces, { gratuity });
                        case 'salesTax':
                            return setFeeTaxOnAllES(newEventSpaces, { salesTax });
                        case 'serviceCharge':
                            return setFeeTaxOnAllES(newEventSpaces, { serviceCharge });
                        default:
                            return newEventSpaces;
                    }
                })();

                onChange();
                return {
                    ...prevData,
                    eventSpacesByDay: newEventSpaces,
                };
            });
        },
        [setData, onChange]
    );

    return (
        <Column>
            <H5Headline>Almost there! Propose the spaces that will work best.</H5Headline>
            <Spacer small />
            <Copy>
                If you or someone on your team has sent a proposal before, we may have some saved spaces!
                <br />
                Please select a saved space or provide a new one. You can use the same space multiple times.
                <br />
                You'll be able to provide additional notes and documents before submitting.
            </Copy>

            <Spacer largest />

            {(rest.eventSpaces || []).length === 0 ? <Copy>There are no meeting space requests.</Copy> : null}

            <FormContent>
                {data.eventSpacesByDay.map((day, dayIndex) => (
                    <Column key={`space-${day[0].requestedDate}`}>
                        <EventSpacesDayForm
                            index={dayIndex}
                            data={day}
                            spaces={spaces}
                            spaceUpdater={spaceUpdater}
                            onUpdateVenueSpaces={onUpdateVenueSpaces}
                            AVOptions={AVOptionsDict}
                            FBOptions={FBOptionsDict}
                            DSOptions={DSOptionsDict}
                            setupOptions={spaceSetups}
                            disabled={disabled}
                        />
                        {dayIndex === data.eventSpacesByDay.length - 1 ? null : <Spacer larger />}
                    </Column>
                ))}
            </FormContent>
        </Column>
    );
}
