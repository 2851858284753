import React from 'react';

import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';

import { currentInquiryActions } from './store';

export default function LoadCurrentInquiry() {
    const { eventId } = useParams<{ eventId: string }>();

    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        const load = async () => {
            try {
                await currentInquiryActions.load(parseInt(eventId));
            } catch (e) {
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            }
        };

        load();
    }, [eventId, enqueueSnackbar]);

    return null;
}
