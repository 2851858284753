import React from 'react';

import { Column } from 'ui';

export default function Errors({ errors }: { errors: Partial<Record<string, string>> }) {
    const errorKeys = Object.keys(errors);

    return (
        <Column>
            Some fields are missing:
            <ul>{errorKeys.map(key => (errors[key] ? <li key={key}>{errors[key]}</li> : null))}</ul>
        </Column>
    );
}
