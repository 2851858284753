import { timeOptions, durationOptions } from 'utils/date_util';
import VirtualMeetingField from './VirtualMeetingField';

export const defaultScheduleFormFields = ({
    schedItemDate,
    integratedVmServices,
    vmOnAdd,
    vmOnEdit,
    vmOnDelete,
}: {
    schedItemDate?: string;
    integratedVmServices?: string[];
    vmOnAdd: (service?: string) => void;
    vmOnEdit: () => void;
    vmOnDelete: () => void;
}) => ({
    name: {
        prompt: 'Title',
        type: 'text',
    },
    date: {
        prompt: 'Date',
        type: 'date_outlined',
        options: {
            minDate: Date.now(),
            parseFormat: 'yyyy-MM-dd',
            placeholder: 'Date',
        },
    },
    startTime: {
        prompt: 'Start Time',
        type: 'select',
        options: {
            options: timeOptions,
            placeholder: 'Start Time',
            autoFocusOptionKey: '12:00:00',
        },
    },
    endTime: {
        prompt: 'End Time',
        type: 'select',
        options: {
            options: timeOptions,
            placeholder: 'End Time',
            autoFocusOptionKey: '12:00:00',
        },
    },
    timeZone: {
        prompt: ' ',
        type: 'timezone_select',
        perRow: 'auto',
        options: {
            date: schedItemDate,
        },
    },
    location: {
        prompt: 'Location',
        type: 'text',
    },
    virtualMeeting: {
        prompt: 'Virtual Meeting',
        type: VirtualMeetingField,
        options: {
            onAdd: vmOnAdd,
            onEdit: vmOnEdit,
            onDelete: vmOnDelete,
            cta: 'Add Virtual Meeting',
            limit: 1,
            integratedVmServices,
        },
    },
    description: {
        prompt: 'Description',
        type: 'rich_text',
    },
    attachments: {
        prompt: 'Attach Documents',
        type: 'upload_list',
        perRow: 1,
        options: {
            prompt: 'Add Document',
        },
    },
    colorId: {
        prompt: 'Select a color',
        type: 'color_picker',
        perRow: 1,
    },
});

export const scheduleFormSchema = [
    {
        key: 'schedName',
        fields: ['name'],
        spacing: 'medium',
    },
    {
        key: 'schedDates',
        fields: ['date', 'startTime', 'endTime'],
        spacing: 'medium',
    },
    {
        key: 'schedLocation',
        fields: ['location'],
        spacing: 'medium',
    },
    {
        key: 'schedVirtualMeeting',
        fields: ['virtualMeeting'],
        spacing: 'medium',
    },
    {
        key: 'schedDescription',
        fields: ['description'],
        spacing: 'medium',
    },
    {
        key: 'schedAttachments',
        fields: ['attachments'],
        spacing: 'medium',
    },
    {
        key: 'schedColor',
        fields: ['colorId'],
        spacing: false,
    },
];

export const scheduleFormSchemaWithTz = [
    // Todo: make this the default schema once Templates V2 is officially released for all
    {
        key: 'schedName',
        fields: ['name'],
        spacing: 'medium',
    },
    {
        key: 'schedDates',
        fields: ['date', 'startTime', 'endTime', 'timeZone'],
        spacing: 'medium',
    },
    {
        key: 'schedLocation',
        fields: ['location'],
        spacing: 'medium',
    },
    {
        key: 'schedVirtualMeeting',
        fields: ['virtualMeeting'],
        spacing: 'medium',
    },
    {
        key: 'schedDescription',
        fields: ['description'],
        spacing: 'medium',
    },
    {
        key: 'schedAttachments',
        fields: ['attachments'],
        spacing: 'medium',
    },
    {
        key: 'schedColor',
        fields: ['colorId'],
        spacing: false,
    },
];

export const agendaFields = {
    title: {
        prompt: 'Title',
        type: 'text',
        options: {
            placeholder: 'Ex: Introductions',
        },
    },
    duration: {
        prompt: 'Duration',
        type: 'select',
        options: {
            options: durationOptions,
            placeholder: '-',
            autoFocusOptionKey: 35,
        },
    },
    description: {
        prompt: 'Description',
        type: 'rich_text',
        options: {
            placeholder: 'Include any description or additional information',
        },
    },
};

export const agendaFieldsShort = {
    title: {
        prompt: 'Title',
        type: 'text',
        options: {
            placeholder: 'Ex: Introductions',
        },
    },
    duration: {
        prompt: 'Duration',
        type: 'select',
        options: {
            options: durationOptions,
            placeholder: '-',
            autoFocusOptionKey: 35,
        },
    },
    description: {
        prompt: 'Description',
        type: 'rich_text',
        collapsed: true,
        options: {
            placeholder: 'Include any description or additional information',
        },
    },
};

export const agendaSchema = [
    {
        key: 'titleDuration',
        fields: ['title', 'duration'],
        spacing: 'medium',
    },
    {
        key: 'description',
        fields: ['description'],
        spacing: false,
    },
];

export const defaultVMFields = (teamServiceTypes: Partial<Bizly.VirtualMeetingServiceProvider>[] = []) => ({
    serviceProvider: {
        prompt: 'Service Type',
        type: 'select',
        fixedWidth: 168,
        options: {
            options: teamServiceTypes,
            placeholder: 'Select service',
            overflowTruncate: true,
            valueAsOption: true,
        },
    },
    link: {
        prompt: 'Link',
        type: 'text',
        options: {
            placeholder: 'https://www.host.com/meeting_id',
        },
    },
    notes: {
        prompt: 'Additional Meeting Information',
        type: 'rich_text',
    },
});

export const VMSchemaLink = [
    {
        key: 'serviceProvider',
        fields: ['link'],
        spacing: false,
    },
];

export const VMSchemaNotes = [
    {
        key: 'additionalInfo',
        fields: ['notes'],
        spacing: false,
    },
];
