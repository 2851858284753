import React from 'react';

import { useUser } from 'providers/user';

import { Column } from 'ui';

import RSVPCard from 'components/RSVP/RSVPCard';

export const PREVIEW_CARD_WIDTH = 600;

export type TParcelPreviewCard = {
    image?: string;
    onImageUpload?: (file: { title: string; cloudinarId: string; url: string } | null) => void;
    name?: string;
    headline?: string;
    description?: string;
    startsAt?: string;
    endsAt?: string;

    schedule?: { start: string; end: string }[];
    plannerSchedule?: (BizlyAPI.ScheduleBlock & Partial<{ timezone?: string }>)[];
    plannedBy: Partial<{ firstName?: string | null; lastName?: string | null }>;
    parcelDates?: {
        startDate: string;
        startTime: string;
        endDate: string;
        endTime: string;
    } | null;
    useParcelDates?: boolean;

    location?: string;
    address?: string;
    cityState?: string;
    vmSharingMethod?: 'share' | 'dont_share' | null;
    virtualMeeting?: Partial<Bizly.VirtualMeeting>;

    isNote?: boolean;
    hasQuestions?: boolean;
    timezone?: string;
};

export default function ParcelPreviewCard({
    image,
    onImageUpload,
    name,
    headline,
    description,
    startsAt,
    endsAt,
    schedule = undefined,
    plannerSchedule,
    plannedBy,
    parcelDates = null,
    useParcelDates,
    location,
    address,
    cityState,
    vmSharingMethod,
    virtualMeeting,

    isNote,
    hasQuestions = undefined,
    timezone = undefined,
}: TParcelPreviewCard) {
    const { user } = useUser();

    return (
        <Column style={{ width: PREVIEW_CARD_WIDTH }}>
            <RSVPCard
                team={user.team}
                image={image}
                onImageUpload={onImageUpload}
                name={name}
                headline={headline}
                description={description}
                startsAt={startsAt}
                endsAt={endsAt}
                schedule={schedule}
                plannerSchedule={plannerSchedule}
                plannedBy={plannedBy}
                parcelDates={parcelDates}
                useParcelDates={useParcelDates}
                timezone={timezone}
                location={location}
                address={address}
                cityState={cityState}
                vmSharingMethod={vmSharingMethod}
                virtualMeeting={virtualMeeting}
                isNote={isNote}
                hasQuestions={hasQuestions}
            />
        </Column>
    );
}
