import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { withStyles } from '@material-ui/core/styles';

const styledTab = withStyles(({ getColor, EColors }) => ({
    root: {
        minWidth: 'unset',
    },
    selected: {
        borderBottomColor: getColor(EColors.pureBlack),
    },
}))(Tab);

const styledTabs = withStyles(({ getColor, EColors }) => ({
    flexContainer: {
        justifyContent: 'space-between',
    },
    indicator: {
        backgroundColor: getColor(EColors.primaryAction),
    },
}))(Tabs);

const TabsSecondary = withStyles(({ getColor, EColors }) => ({
    flexContainer: {
        justifyContent: 'space-between',
    },
    indicator: {
        backgroundColor: getColor(EColors.secondaryTextAction),
    },
}))(Tabs);

export { styledTab as Tab, styledTabs as Tabs, TabsSecondary };
