import React from 'react';
import { MenuItem } from '@material-ui/core';

import { TCalendarValues, formatCalendarData, formatGoogleUrl } from './helpers';
import downloadFileFromLink from 'utils/downloadFromLink';
import { ButtonMenu } from 'components/ButtonMenu';

const AddToCalendarButton = ({ fileName, ...calendarProps }: TCalendarValues & { fileName?: string }) => {
    const handleIcalExport = () => {
        const blob = new Blob([formatCalendarData(calendarProps)], {
            type: 'text/calendar;charset=utf-8',
        });
        downloadFileFromLink(blob, fileName || 'calendar', 'ics');
    };

    const hangleGoogleLink = () => {
        const url = formatGoogleUrl(calendarProps);
        window.open(url, '_blank');
    };

    return (
        <ButtonMenu label="Add to Calendar" secondary>
            <MenuItem onClick={handleIcalExport}>Outlook</MenuItem>
            <MenuItem onClick={hangleGoogleLink}>Google</MenuItem>
            <MenuItem onClick={handleIcalExport}>Apple</MenuItem>
        </ButtonMenu>
    );
};

export default AddToCalendarButton;
