import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { ContentState, Modifier, DraftHandleValue, Editor, EditorState, RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { useSnackbar } from 'notistack';
import * as URI from 'uri-js';

import { contentToHtml, getInitialEditorState } from './helpers';
import colorFns from 'colorFns';
import { Row } from 'ui';
import SetLinkModal from './SetLinkModal';
import { ReactComponent as BoldIcon } from 'images/icons/Bold.svg';
import { ReactComponent as ItalicIcon } from 'images/icons/Italics.svg';
import { ReactComponent as UnderlineIcon } from 'images/icons/Underline.svg';
import { ReactComponent as LinkIcon } from 'images/icons/link.svg';
import { ReactComponent as OrderedListIcon } from 'images/icons/OL.svg';
import { ReactComponent as UnorderedListIcon } from 'images/icons/UL.svg';

const BOLD = 'BOLD',
    ITALIC = 'ITALIC',
    UNDERLINE = 'UNDERLINE',
    ORDERED_LIST = 'ORDERED_LIST',
    UNORDERED_LIST = 'UNORDERED_LIST';
type TStyle = typeof BOLD | typeof ITALIC | typeof UNDERLINE;
type TListType = typeof ORDERED_LIST | typeof UNORDERED_LIST;
type TKeyCommand = 'bold' | 'italic' | 'underline';

const ToolBar = styled(Row)`
    border-bottom: 1px solid ${colorFns.lightGrey};
    align-items: center;
    padding: 8px 12px;
    width: auto;

    span {
        margin-right: 8px;

        svg {
            color: ${colorFns.pureWhite};
            cursor: pointer;
            opacity: 0.74;
        }
    }
`;

const ToolbarButton = ({ Icon, onClick }: { Icon: React.ReactNode; onClick: () => void }) => (
    // We must preventDefault() on MouseDown so that the highlighted text does not de-select when clicking buttons
    <span onMouseDown={e => e.preventDefault()} onClick={onClick}>
        {Icon}
    </span>
);

const DividerLine = styled.div`
    border-left: 1px solid ${colorFns.grey};
    margin: 0 16px 0 10px;
    height: 32px;
`;

const EditBox = styled.div<{ readonly?: boolean; disabled?: boolean; hidePlaceholder: boolean }>`
    ${({ readonly }) =>
        readonly
            ? ''
            : `
    padding: 12px;

    .public-DraftEditor-content {
        min-height: 80px;
    }
    `}

    ${({ disabled, theme: { getColor, EColors } }) =>
        disabled &&
        `
        .public-DraftEditor-content {
            color: ${getColor(EColors.darkGrey)};
        }
    `}

    ${({ hidePlaceholder }) =>
        hidePlaceholder &&
        /* Workaround for known bug: https://github.com/facebook/draft-js/issues/1205 */
        `
        .public-DraftEditorPlaceholder-root {
            display: none;
        }
    `}
`;

const Container = styled.div<{ backgroundWhite?: boolean }>`
    border: 1px solid ${colorFns.lightGrey};
    border-radius: 8px;
    margin-top: 8px;

    display: flex;
    flex-flow: column;
    max-height: 70vh;

    > ${EditBox} {
        overflow: auto;
    }

    ${({ backgroundWhite }) =>
        backgroundWhite
            ? `
        overflow: hidden;
        > ${ToolBar} {
            background: white;
        }

        > ${EditBox} {
            background: white;
        }
    `
            : ''}
`;

const RichTextEditor = ({
    field,
    value,
    onChange,
    placeholder,

    readonly,
    disabled,

    backgroundWhite,
}: {
    field: string;
    value?: string;
    onChange: ({ field, value, errors }: { field: string; value: string; errors: any }) => void;
    placeholder?: string;

    readonly?: boolean;
    disabled?: boolean;

    backgroundWhite?: boolean;
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [editorState, setEditorState] = useState<EditorState>(getInitialEditorState(value));
    const [showLinkModal, setShowLinkModal] = useState<boolean>(false);
    const [selectionUrl, setSelectionUrl] = useState<string | null>(null);

    useEffect(() => {
        readonly && setEditorState(getInitialEditorState(value));
    }, [value, readonly]);

    const hasText = editorState.getCurrentContent().hasText();

    const hidePlaceholder = useMemo(() => {
        const contentState = editorState.getCurrentContent();
        return !!(contentState.hasText() || contentState.getFirstBlock().getType() !== 'unstyled');
    }, [editorState]);

    const handleChange = (newEditorState: EditorState) => {
        const htmlString = contentToHtml(newEditorState);
        onChange({
            field,
            value: htmlString,
            errors: null,
        });
        setEditorState(newEditorState);
    };

    const handleKeyCommand = (keyCommand: TKeyCommand) => {
        const newEditorState = RichUtils.handleKeyCommand(editorState, keyCommand);

        if (newEditorState) {
            handleChange(newEditorState);
            return 'handled';
        }
        return 'not-handled';
    };
    const toggleStyle = (style: TStyle) => {
        const newEditorState = RichUtils.toggleInlineStyle(editorState, style);
        handleChange(newEditorState);
    };

    const editLink = () => {
        setSelectionUrl(null);
        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const startKey = selection.getStartKey();
        const startOffset = selection.getStartOffset();
        const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
        const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

        if (linkKey) {
            const currentUrl = contentState.getEntity(linkKey).getData().url;
            setSelectionUrl(currentUrl);
        }

        setShowLinkModal(true);
    };

    const handleCloseLinkModal = () => {
        setSelectionUrl(null);
        setShowLinkModal(false);
    };

    const handleClearLinkOnSelection = () => {
        const selection = editorState.getSelection();
        handleChange(RichUtils.toggleLink(editorState, selection, null));
        handleCloseLinkModal();
    };

    const handleSaveLinkOnSelection = () => {
        const parsedLink = selectionUrl && URI.parse(selectionUrl);

        if (parsedLink && !parsedLink.scheme) {
            enqueueSnackbar('Make sure the link includes the protocol (http or https)', { variant: 'error' });
        } else {
            const contentState = editorState.getCurrentContent();
            const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url: selectionUrl });
            const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
            const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });

            handleChange(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey));
            handleCloseLinkModal();
        }
    };

    const toggleList = (listType: TListType) => {
        const listTypes = {
            ORDERED_LIST: 'ordered-list-item',
            UNORDERED_LIST: 'unordered-list-item',
        };
        const newEditorState = RichUtils.toggleBlockType(editorState, listTypes[listType]);
        setEditorState(newEditorState);
    };

    const handlePastedText = (text: string): DraftHandleValue => {
        const currentContent = editorState.getCurrentContent();
        const selection = editorState.getSelection();
        const pastedBlocks = ContentState.createFromText(text).getBlockMap();
        const newState = Modifier.replaceWithFragment(currentContent, selection, pastedBlocks);
        const newEditorState = EditorState.push(editorState, newState, 'insert-fragment');
        setEditorState(newEditorState);
        return 'handled';
    };

    const editField = (
        <>
            {!disabled && !readonly && (
                <ToolBar>
                    <ToolbarButton Icon={<BoldIcon />} onClick={() => toggleStyle(BOLD)} />
                    <ToolbarButton Icon={<ItalicIcon />} onClick={() => toggleStyle(ITALIC)} />
                    <ToolbarButton Icon={<UnderlineIcon />} onClick={() => toggleStyle(UNDERLINE)} />
                    <DividerLine />
                    <ToolbarButton Icon={<OrderedListIcon />} onClick={() => toggleList(ORDERED_LIST)} />
                    <ToolbarButton Icon={<UnorderedListIcon />} onClick={() => toggleList(UNORDERED_LIST)} />
                    <ToolbarButton Icon={<LinkIcon />} onClick={() => editLink()} />
                </ToolBar>
            )}
            {!hasText && readonly ? null : (
                <EditBox readonly={readonly} disabled={disabled} hidePlaceholder={hidePlaceholder}>
                    <Editor
                        placeholder={placeholder}
                        editorState={editorState}
                        handlePastedText={handlePastedText}
                        onChange={handleChange}
                        handleKeyCommand={handleKeyCommand}
                        readOnly={readonly || disabled}
                    />
                </EditBox>
            )}
        </>
    );

    return !hasText && readonly ? null : (
        <>
            {!readonly ? <Container backgroundWhite={backgroundWhite}>{editField}</Container> : editField}
            <SetLinkModal
                open={showLinkModal}
                value={selectionUrl}
                onChange={setSelectionUrl}
                onClear={handleClearLinkOnSelection}
                onSave={handleSaveLinkOnSelection}
                handleOnClose={handleCloseLinkModal}
            />
        </>
    );
};

export default RichTextEditor;

export function RichTextDisplay({ value }: { value?: string }) {
    const rendered = RichTextEditor({ field: '', value, onChange: () => {}, readonly: true });
    return rendered;
}
