import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import { LoadEventTemplates } from 'stores/eventTemplates';
import { LoadEventTemplateTags } from 'stores/event-template-tags';
import { PlaybooksPage, Playbook } from 'pages/Playbooks';

import CreatePlaybook from 'pages/CreatePlaybook';
import SideNav from 'components/SideNav';
import NavButtons from 'pages/CreatePlaybook/NavButtons';

const ANIMATION_KEY = '1';

export default function TemplatesRoute() {
    const match = useRouteMatch();

    return (
        <>
            <LoadEventTemplateTags />
            <Route>
                <Switch>
                    <Route path={`${match.path}/new`}>
                        <SideNav fillWidth hideUser routeChildren={<NavButtons />} key={ANIMATION_KEY}>
                            <CreatePlaybook />
                        </SideNav>
                    </Route>
                    <Route
                        path={`${match.path}/:playbookId`}
                        render={(props: RouteComponentProps<{ playbookId: string }>) => (
                            <>
                                <LoadEventTemplates />
                                <Playbook playbookId={props.match.params.playbookId} />
                            </>
                        )}
                    />
                    <Route>
                        <LoadEventTemplates />
                        <SideNav key={ANIMATION_KEY}>
                            <PlaybooksPage />
                        </SideNav>
                    </Route>
                </Switch>
            </Route>
        </>
    );
}
