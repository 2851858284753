import React from 'react';
import styled from 'styled-components';

import { ReactComponent as CalculatorIcon } from '../../images/icons/calculator.svg';
import { withIconStyles } from 'shared';

import { Copy as UICopy } from '../../ui';
import Button from '../ui/Button';

const Container = styled.div`
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    margin-top: 72px;
    height: 88px;
    width: 100%;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.strongAccentedBackground)};

    div {
        display: flex;
        align-items: center;
    }
`;

const Bubble = styled.div`
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    height: 48px;
    width: 48px;
    margin-left: 24px;
    margin-right: 40px;
`;

const CalcIcon = withIconStyles(CalculatorIcon);

const Copy = styled(UICopy)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    font-size: 15px;
    margin-right: 12px;
`;

const WhiteButton = styled(Button)`
    margin-right: 27px;
`;

const Shadow = styled.div`
    height: 88px;
    visiblility: hidden;
`;
type FinalSpendBannerProps = {
    openFlyout: () => void;
};

const FinalSpendBanner = ({ openFlyout }: FinalSpendBannerProps) => {
    return (
        <>
            <Container>
                <div>
                    <Bubble>
                        <CalcIcon />
                    </Bubble>
                    <Copy>
                        Now that your meeting is over, don’t forget to enter in final spend and upload the final
                        invoice. This will help you track your meeting spending.
                    </Copy>
                </div>
                <WhiteButton onClick={openFlyout} width={191} secondary>
                    Go to Spend Tracker
                </WhiteButton>
            </Container>
            <Shadow />
        </>
    );
};

export default FinalSpendBanner;
