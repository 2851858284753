import React from 'react';
import { useHistory } from 'react-router-dom';

import { TMSAuth } from './types';

import AzureAuthenticationContext from 'api/msGraphAuth';
import { useSnackbar } from 'notistack';
import { SpinnerOverlay } from 'components/Spinner';

export default function MSAuth() {
    const history = useHistory<{ msAuth: TMSAuth }>();

    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        const authContext = new AzureAuthenticationContext();

        const getMsToken = async () => {
            try {
                const resp = await authContext.handleRedirect();

                const { account, idToken, accessToken } = resp ?? {};
                if (!account || !idToken || !accessToken)
                    return enqueueSnackbar('Something went wrong, please try again.', {
                        variant: 'error',
                    });
                history.replace(history.location.pathname.replace('/msauth', ''), {
                    ...history.location.state,
                    msAuth: {
                        client: 'ms',
                        email: account.username,
                        idToken,
                        accessToken,
                    },
                });
            } catch (e) {
                return enqueueSnackbar('Something went wrong, please try again.', {
                    variant: 'error',
                });
            }
        };
        getMsToken();
    }, [history, enqueueSnackbar]);

    return <SpinnerOverlay />;
}
