import React from 'react';

import styled, { css } from 'styled-components/macro';

import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';

import { format, parseISO } from 'date-fns';
import { serializeDate, API_DATE_FORMAT } from 'utils/date_util';
import { formatCurrency, formatPercentage, formatDate, padArray } from '../util';

import { getInquiry, getVenueInquiriesNotAdded, getVenueProposal, serializeDay } from '../api';
import { currentInquiryActions } from 'stores/current-inquiry';

import { EventContext } from '../providers/event';

import { Column, Copy, SmallerCopy, Row, SpacedRow, Spacer } from '../ui';
import colorFns from 'colorFns';
import fontFns from 'fontFns';

import Button from '../components/ui/Button';
import { Headline } from '../components/ui/Headline';
import VenueTile, { VenueTileEmpty } from './VenueTile/VenueTile';

import ProposalAcceptModal from './ProposalAcceptModal';
import ProposalRejectModal from './ProposalRejectModal';
import InquiryDetailsModal from './InquiryDetailsModal';
import VenueCancelModal from './VenueCancelModal';

const STATUS_TO_RANK: { [status: string]: number } = {
    Booked: 3,
    'Contract Pending': 2,
    'Proposal Received': 1,
    Submitted: 0,
    'Proposal Rejected': -1,
    'Proposal Cancelled': -1,
    'Inquiry Rejected': -1,
    'Inquiry Cancelled': -1,
};

function sortGalleryInquiries(inquiries: any[]) {
    return inquiries.sort(
        (a, b) => (STATUS_TO_RANK[b.status as string] || 0) - (STATUS_TO_RANK[a.status as string] || 0)
    );
}

function inquiryToSchedule({ eventSpaces, guestrooms }: BizlyAPI.Inquiry) {
    const NO_DATE = '';

    const spacesWithDates = eventSpaces.map(eventSpace => {
        const isoDate = serializeDate(eventSpace.start);
        return {
            ...eventSpace,
            isoDate: isoDate ? format(isoDate, API_DATE_FORMAT) : NO_DATE,
        };
    });
    const roomsWithDate = guestrooms.map(guestroom => {
        const isoDate = serializeDate(guestroom.date);
        return {
            ...guestroom,
            isoDate: isoDate ? format(isoDate, API_DATE_FORMAT) : NO_DATE,
        };
    });

    const spacesByDate = groupBy(spacesWithDates, space => space.isoDate);
    const roomsByDate = groupBy(roomsWithDate, room => room.isoDate);

    const allIsoDates = [...new Set([...Object.keys(spacesByDate), ...Object.keys(roomsByDate)])];

    type ScheduleAgenda = {
        id: string;
        name?: string;
        start?: string;
        end?: string;
        roomSetup?: number;
        amenities: {
            av: number[];
            foodAndBeverage: { optionId?: number }[];
        };
    };

    type ScheduleGuestroom = { count?: number };

    type Day = {
        start?: string;
        agenda: ScheduleAgenda[];
        guestrooms: ScheduleGuestroom[];
    };

    type Schedule = Day[];

    const ZERO_ISO_TIME = 'T00:00:00';

    const schedule: Schedule = allIsoDates.map(isoDate => ({
        start: isoDate === NO_DATE ? undefined : isoDate + ZERO_ISO_TIME,
        agenda: (spacesByDate[isoDate] || []).map(item => ({
            ...item,
            roomSetup: item.setup?.id,
            amenities: {
                av: item.avNeeds.map(need => need.id),
                foodAndBeverage: item.fbNeeds.map(need => ({ optionId: need.id })),
            },
        })),
        guestrooms: roomsByDate[isoDate] || [],
    }));

    return schedule;
}

const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
    margin: 10px 0;
`;

const FormHeading = styled.h5`
    font-size: 18px;
    letter-spacing: -0.1px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
`;

const VenueLabel = styled('label')<{ small?: boolean }>`
    font-size: ${props => (props.small ? '15px' : '18px')};
    letter-spacing: -0.1px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formLabel)};
    ${fontFns.formLabel}
`;

const NoSpaceImage = styled.div`
    width: 242px;
    height: 140px;
    border-radius: 8px;

    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.softAccentedBackgroundNested)};

    display: flex;
    align-items: center;
    justify-content: center;
    &::after {
        content: 'No Image Available';
        font-size: 15px;
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};
    }
`;

const EventSpaceHeader = styled.div`
    display: flex;
`;

const VenueInquiry = ({
    viewProposal,
    submitKey,
    setVenueBooked,
    readonly,
}: {
    viewVenueListing: (venueId: number) => void;
    viewProposal: (proposalId: number) => void;
    submitKey: number;
    setVenueBooked: (booked: boolean) => void;
    readonly: boolean;
}) => {
    const { event } = React.useContext(EventContext);

    const [inquiredVenues, setInquiredVenues] = React.useState<any[]>([]);
    const onUpdateVenues = (venues: any[]) => {
        setInquiredVenues(venues);
        currentInquiryActions.mergeVenues(event.id, venues);
    };
    const [acceptModalOpen, setAcceptModalOpen] = React.useState<Bizly.VenueProposal | undefined>();

    const [rejectModalOpen, setRejectModalOpen] = React.useState<number | undefined>();

    const [inquiryDetails, setInquiryDetails] = React.useState<
        { schedule: any[]; submittedAt?: string; notes?: string; datesFlexible?: boolean } | undefined
    >();
    const [showInquiryDetails, setShowInquiryDetails] = React.useState(false);

    const [venueDetails, setVenueDetails] = React.useState<{
        id: number;
        inquiryId: number;
    }>();

    const [venueProposals, setVenueProposals] = React.useState<{
        [id: number]: Bizly.VenueProposal;
    }>({});

    const [compareVenues, setCompareVenues] = React.useState(false);

    React.useEffect(() => {
        getVenueInquiriesNotAdded(event.id).then(venues => {
            setInquiredVenues(venues);
            getProposals(venues);
            if (
                venues.filter(venueInquiry => venueInquiry.status === 'Proposal Received' && venueInquiry.proposalId)
                    .length > 1
            ) {
                setCompareVenues(true);
            }
        });

        function getProposals(venues: Bizly.Venue[]) {
            const proposalsPromises = venues
                .filter(venue => venue.status === 'Proposal Received')
                .map(venue => venue.proposalId && getVenueProposal(venue.proposalId));

            Promise.all(proposalsPromises).then(responses => {
                const proposals = responses.map(response => response.proposal);

                setVenueProposals(keyBy(proposals, 'id'));
            });
        }
    }, [event.id, submitKey]);

    const venueProposalsEmpty = isEmpty(venueProposals);

    function getProposalsSubmitted() {
        return inquiredVenues.filter(
            (i: { status: string; proposalId: number }) => i.status === 'Proposal Received' && i.proposalId
        );
    }

    function venueHasProposal(venue: Bizly.Venue) {
        return ['Proposal Received', 'Proposal Rejected', 'Contract Pending', 'Booked'].includes(venue.status);
    }

    const getMenuItems = (venue: Bizly.Venue) => [
        ...(['Booked', 'Contract Pending', 'Proposal Received', 'Proposal Rejected', 'Proposal Cancelled'].includes(
            venue.status
        )
            ? [
                  {
                      label: 'View Proposal',
                      handler: () => {
                          viewProposal(venue.proposalId!);
                      },
                  },
              ]
            : []),
        ...(['Proposal Received', 'Submitted', 'Proposal Cancelled', 'Inquiry Cancelled', 'Inquiry Rejected'].includes(
            venue.status
        )
            ? [
                  {
                      label: 'Inquiry Details',
                      handler: async () => {
                          setShowInquiryDetails(true);
                          const { inquiry } = await getInquiry(venue.inquiryId!);

                          setInquiryDetails({
                              schedule:
                                  inquiry.scheduleFragment?.map(day => serializeDay(day)) ?? inquiryToSchedule(inquiry),
                              submittedAt: inquiry.submittedAt,
                              notes: inquiry.notes,
                              datesFlexible: inquiry.flexibleDates,
                          });
                      },
                  },
              ]
            : []),
        ...(!readonly && venue.status === 'Proposal Received'
            ? [
                  {
                      label: 'Accept',
                      handler: () => {
                          setAcceptModalOpen(venueProposals[venue.proposalId!]);
                      },
                  },
                  {
                      label: 'Reject',
                      handler: () => {
                          setRejectModalOpen(venue.proposalId!);
                      },
                  },
              ]
            : []),
        ...(!readonly && ['Submitted'].includes(venue.status)
            ? [
                  {
                      label: 'Cancel',
                      handler: () =>
                          setVenueDetails(
                              venue as {
                                  id: number;
                                  inquiryId: number;
                              }
                          ),
                  },
              ]
            : []),
    ];

    return (
        <>
            <main>
                <SpacedRow>
                    <Headline large>Your Inquiries</Headline>
                    {getProposalsSubmitted().length > 1 && (
                        <Button onClick={() => setCompareVenues(!compareVenues)}>Compare</Button>
                    )}
                </SpacedRow>
                {!inquiredVenues.length && (
                    <Copy>
                        Your submitted inquiries will appear here and you’ll be able to view, accept and decline them.
                    </Copy>
                )}
                <Row
                    css={css`
                        margin: 40px -10px;
                        width: calc(100% + 20px);
                        flex-wrap: wrap;
                    `}
                >
                    {padArray(sortGalleryInquiries(inquiredVenues), 4).map((venue: Bizly.Venue | undefined) =>
                        venue ? (
                            <div
                                css={css`
                                    position: relative;
                                    width: calc(25% - 20px);
                                    max-width: calc(25% - 20px);

                                    padding: 0 10px;
                                `}
                            >
                                <Column
                                    css={css`
                                        width: 100%;
                                        ${compareVenues && `padding-bottom: 20px;`}
                                    `}
                                >
                                    <VenueTile
                                        key={venue.id}
                                        venue={{ ...venue.venue, status: venue.status }}
                                        format="thumb"
                                        pillType="inquiryStatus"
                                        width="100%"
                                        fixedRatio="calc(172 / 266 * 100%)"
                                        padding="0 0 24px 0"
                                        menuOptions={getMenuItems(venue)}
                                        onClick={
                                            venueHasProposal(venue) ? () => viewProposal(venue.proposalId!) : undefined
                                        }
                                    />
                                    {compareVenues && !venueProposalsEmpty && venue.status === 'Proposal Received' ? (
                                        <>
                                            {venueProposals[venue.proposalId!].byDay.map((day, idx) => (
                                                <>
                                                    <Spacer />
                                                    <section
                                                        css={css`
                                                            background-color: ${colorFns.softAccentedBackground};

                                                            border-bottom-left-radius: 8px;
                                                            border-bottom-right-radius: 8px;
                                                        `}
                                                    >
                                                        <div
                                                            css={css`
                                                                border-top-left-radius: 8px;
                                                                border-top-right-radius: 8px;
                                                                background-color: ${colorFns.agendaDayPane};
                                                                color: ${colorFns.agendaDayPaneText};

                                                                display: flex;
                                                                justify-content: space-between;

                                                                padding: 12px;
                                                            `}
                                                        >
                                                            <div>Day {idx + 1}</div>
                                                            <div>{format(parseISO(day.day), 'iii, LLL dd, yyyy')}</div>
                                                        </div>
                                                        <div
                                                            css={css`
                                                                display: flex;
                                                                justify-content: center;
                                                                margin: 0 25px;

                                                                padding-bottom: 20px;
                                                            `}
                                                        >
                                                            <Spacer />
                                                            <Column>
                                                                <Spacer />
                                                                {day.guestRooms[0] && (
                                                                    <>
                                                                        <Copy>
                                                                            {day.guestRooms[0].quantity} guest rooms
                                                                        </Copy>
                                                                        <Spacer />
                                                                        {day.guestRooms[0].imageUrl ? (
                                                                            <img
                                                                                css={css`
                                                                                    width: 242px;
                                                                                    height: 140px;
                                                                                    border-radius: 8px;
                                                                                `}
                                                                                src={day.guestRooms[0].imageUrl}
                                                                                alt="venue-space"
                                                                            />
                                                                        ) : (
                                                                            <NoSpaceImage />
                                                                        )}

                                                                        <Spacer />
                                                                        <FormHeading as="label">
                                                                            Guest Room Rental
                                                                        </FormHeading>
                                                                        <div
                                                                            css={css`
                                                                                font-size: 40px;
                                                                                font-weight: 300;
                                                                                line-height: 0.95;
                                                                                letter-spacing: -1.22px;
                                                                                color: ${colorFns.displayPricing};
                                                                            `}
                                                                        >
                                                                            {formatCurrency(day.guestRooms[0].subTotal)}
                                                                        </div>
                                                                        <Spacer />
                                                                        <SmallerCopy>
                                                                            {venueProposals[venue.proposalId!]
                                                                                .commissionable ? (
                                                                                'Guestrooms are Commissionable.'
                                                                            ) : (
                                                                                <>&nbsp;</>
                                                                            )}
                                                                        </SmallerCopy>
                                                                        <SmallerCopy>
                                                                            Amount excludes taxes and fees.
                                                                        </SmallerCopy>
                                                                        <Spacer />
                                                                        <Spacer />
                                                                        <SpacedRow>
                                                                            <VenueLabel small>Room Name</VenueLabel>
                                                                            <div>Deluxe Suite</div>
                                                                        </SpacedRow>
                                                                        <Spacer />
                                                                        <SpacedRow>
                                                                            <VenueLabel small>
                                                                                Room Size (sq ft)
                                                                            </VenueLabel>
                                                                            <div>430</div>
                                                                        </SpacedRow>
                                                                        <Spacer />
                                                                        <Spacer />
                                                                        <SpacedRow>
                                                                            <VenueLabel small>Room Rate</VenueLabel>
                                                                            <div>
                                                                                {formatCurrency(day.guestRooms[0].rate)}
                                                                            </div>
                                                                        </SpacedRow>
                                                                        <Line />
                                                                        <SpacedRow>
                                                                            <VenueLabel small>Occupancy Tax</VenueLabel>
                                                                            <div>
                                                                                {Number(
                                                                                    venueProposals[venue.proposalId!]
                                                                                        .occupancyTax
                                                                                )}
                                                                                %
                                                                            </div>
                                                                        </SpacedRow>
                                                                        <Line />
                                                                        <SpacedRow>
                                                                            <VenueLabel small>Resort Fee</VenueLabel>
                                                                            <div>
                                                                                {formatCurrency(
                                                                                    venueProposals[venue.proposalId!]
                                                                                        .resortFee
                                                                                )}
                                                                            </div>
                                                                        </SpacedRow>
                                                                        <Spacer />
                                                                        <Line />
                                                                        <Spacer />
                                                                    </>
                                                                )}
                                                                {day.eventSpaces.map(
                                                                    ({
                                                                        fbMinimum,
                                                                        rate,
                                                                        requestedSpaceName,
                                                                        imageUrl: eventSpaceImageUrl,
                                                                        venueSpace,
                                                                        setup,
                                                                        startsAt,
                                                                        endsAt,
                                                                    }) => (
                                                                        <>
                                                                            <EventSpaceHeader>
                                                                                <img
                                                                                    src={setup.iconUrl}
                                                                                    alt="meetingspace-icon"
                                                                                />
                                                                                <Spacer xsmall />
                                                                                <Copy>
                                                                                    {requestedSpaceName || setup.name}
                                                                                    <Spacer xsmall />
                                                                                    {formatDate(startsAt)} -{' '}
                                                                                    {formatDate(endsAt)}
                                                                                </Copy>
                                                                            </EventSpaceHeader>
                                                                            <Spacer />
                                                                            {eventSpaceImageUrl ? (
                                                                                <>
                                                                                    <Spacer />
                                                                                    <img
                                                                                        css={css`
                                                                                            width: 242px;
                                                                                            height: 140px;
                                                                                            border-radius: 8px;
                                                                                        `}
                                                                                        src={eventSpaceImageUrl}
                                                                                        alt="venue-space"
                                                                                    />
                                                                                </>
                                                                            ) : (
                                                                                <NoSpaceImage />
                                                                            )}
                                                                            <Spacer />
                                                                            <FormHeading as="label">
                                                                                F&amp;B Min.
                                                                            </FormHeading>
                                                                            <div
                                                                                css={css`
                                                                                    font-size: 40px;
                                                                                    font-weight: 300;
                                                                                    line-height: 0.95;
                                                                                    letter-spacing: -1.22px;
                                                                                    color: ${colorFns.displayPricing};
                                                                                `}
                                                                            >
                                                                                {formatCurrency(fbMinimum)}
                                                                            </div>
                                                                            <Spacer />
                                                                            <FormHeading as="label">
                                                                                Room Rental
                                                                            </FormHeading>
                                                                            <div
                                                                                css={css`
                                                                                    font-size: 40px;
                                                                                    font-weight: 300;
                                                                                    line-height: 0.95;
                                                                                    letter-spacing: -1.22px;
                                                                                    color: ${colorFns.displayPricing};
                                                                                `}
                                                                            >
                                                                                {formatCurrency(rate)}
                                                                            </div>
                                                                            <Spacer />
                                                                            <SmallerCopy>
                                                                                Amount excludes taxes and fees.
                                                                            </SmallerCopy>
                                                                            <Spacer />
                                                                            <Spacer />
                                                                            <SpacedRow>
                                                                                <VenueLabel small>Room Name</VenueLabel>
                                                                                <div>{venueSpace.name}</div>
                                                                            </SpacedRow>
                                                                            <Spacer />
                                                                            <SpacedRow>
                                                                                <VenueLabel small>
                                                                                    Room Size (sq ft)
                                                                                </VenueLabel>
                                                                                <div>
                                                                                    {Number(
                                                                                        venueSpace.size
                                                                                    ).toLocaleString()}
                                                                                </div>
                                                                            </SpacedRow>
                                                                            <Spacer />
                                                                            <SpacedRow>
                                                                                <VenueLabel small>
                                                                                    Room Max Capacity
                                                                                </VenueLabel>
                                                                                <div>
                                                                                    {venueSpace.maxCapacity?.toLocaleString()}
                                                                                </div>
                                                                            </SpacedRow>
                                                                            <Spacer />
                                                                            <Spacer />
                                                                            <SpacedRow>
                                                                                <VenueLabel small>
                                                                                    Service Charge
                                                                                </VenueLabel>
                                                                                <div>
                                                                                    {formatPercentage(
                                                                                        venueProposals[
                                                                                            venue.proposalId!
                                                                                        ].serviceCharge
                                                                                    )}
                                                                                </div>
                                                                            </SpacedRow>
                                                                            <Line />
                                                                            <SpacedRow>
                                                                                <VenueLabel small>Sales Tax</VenueLabel>
                                                                                <div>
                                                                                    {formatPercentage(
                                                                                        venueProposals[
                                                                                            venue.proposalId!
                                                                                        ].salesTax
                                                                                    )}
                                                                                </div>
                                                                            </SpacedRow>
                                                                            <Line />
                                                                            <SpacedRow>
                                                                                <VenueLabel small>Gratuity</VenueLabel>
                                                                                <div>
                                                                                    {formatPercentage(
                                                                                        venueProposals[
                                                                                            venue.proposalId!
                                                                                        ].gratuity
                                                                                    )}
                                                                                </div>
                                                                            </SpacedRow>
                                                                        </>
                                                                    )
                                                                )}
                                                            </Column>
                                                        </div>
                                                    </section>
                                                </>
                                            ))}
                                            {!readonly && (
                                                <Row
                                                    css={css`
                                                        position: sticky;
                                                        width: 100%;
                                                        height: 68px;
                                                        background: ${({ theme: { getColor, EColors } }) =>
                                                            getColor(EColors.pureWhite)};

                                                        bottom: 0;
                                                        align-items: center;
                                                        justify-content: space-evenly;

                                                        border: 1px solid
                                                            ${({ theme: { getColor, EColors } }) =>
                                                                getColor(EColors.lightGrey)};

                                                        box-sizing: border-box;

                                                        padding: 0;
                                                    `}
                                                >
                                                    <Button onClick={() => setRejectModalOpen(venue.proposalId!)}>
                                                        Reject
                                                    </Button>
                                                    <Button
                                                        onClick={() =>
                                                            setAcceptModalOpen(venueProposals[venue.proposalId!])
                                                        }
                                                    >
                                                        Accept
                                                    </Button>
                                                </Row>
                                            )}
                                        </>
                                    ) : null}
                                </Column>
                            </div>
                        ) : (
                            <VenueTileEmpty width="25%" fixedRatio="calc(172 / 266 * 100%)" padding="0 10px" withPin />
                        )
                    )}
                </Row>
            </main>
            <ProposalAcceptModal
                open={!!acceptModalOpen}
                closeModal={() => setAcceptModalOpen(undefined)}
                eventId={event.id}
                proposal={acceptModalOpen}
                setVenueBooked={setVenueBooked}
            />
            <ProposalRejectModal
                open={!!rejectModalOpen}
                closeModal={() => setRejectModalOpen(undefined)}
                eventId={event.id}
                proposalId={rejectModalOpen}
            />
            <VenueCancelModal
                open={!!venueDetails}
                closeModal={() => setVenueDetails(undefined)}
                venueDetails={venueDetails}
                inquiredVenues={inquiredVenues}
                onCancelled={onUpdateVenues}
            />
            {showInquiryDetails && (
                <InquiryDetailsModal
                    open
                    closeModal={() => {
                        setShowInquiryDetails(false);
                        setInquiryDetails(undefined);
                    }}
                    inquiryDetails={inquiryDetails}
                />
            )}
        </>
    );
};

export default VenueInquiry;
