import React, { useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { parse } from 'query-string';

import { setCookie } from 'utils/tokens';
import { TAuthForm } from './types';
import { useUser } from 'providers/user';

import LogoBackground from 'components/ui/LogoBackground';
import SignInPage from './SignInPage';
import SignUpPage from './SignUpPage';

export default function AuthenticationPage() {
    const [authFormValue, setAuthFormValue] = useState<TAuthForm>({});

    const { setUserState } = useUser();
    const history = useHistory();

    const { redirect } = parse(window.location.search);
    const parsedRedirect = typeof redirect === 'string' ? redirect : '/';

    const onFormChange = ({ value }: { value: TAuthForm }) =>
        setAuthFormValue(prevAuthForm => ({
            ...prevAuthForm,
            ...value,
        }));

    const finalizeLogin = async (user: BizlyAPI.User) => {
        await setCookie(user.authToken);
        await setUserState({ ...user, loaded: true, token: user.authToken });
        history.replace(parsedRedirect);
    };

    return (
        <LogoBackground>
            <Switch>
                <Route path="/sign-in">
                    <SignInPage authFormValue={authFormValue} onChange={onFormChange} onSignInSuccess={finalizeLogin} />
                </Route>
                <Route path="/sign-up">
                    <SignUpPage authFormValue={authFormValue} onChange={onFormChange} onSignUpSuccess={finalizeLogin} />
                </Route>
            </Switch>
        </LogoBackground>
    );
}
