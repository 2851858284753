import { get, post } from 'api';
import moment from 'moment';

export const getMeetings = (
    startOfWeek: Date,
    limit: number = 100
): Promise<{
    attendingUsers: BizlyAPI.AttendingUser[];
    calendarItems: BizlyAPI.CalendarItem[];
    meta: { itemCount: number; totalPeopleHours: number };
}> => get(`calendar?date_start=${moment(startOfWeek).format('YYYY-MM-DD')}&limit=${limit}`);

export const subsume = (id: string | number) =>
    post('calendar/subsume', { id }) as Promise<{ meeting: BizlyAPI.Meeting }>;
