import React from 'react';

import styled from 'styled-components';
import { format, parseISO } from 'date-fns';

import { Column, Spacer, Copy as UICopy } from 'ui';
import { H5Headline, H3Headline } from 'components/ui/Headline';

import { EColors } from 'theme';
import useThemedColor from 'hooks/useThemedColor';

import Form from 'components/Form';
import { dayForm, commissionableForm } from './guestroomsFormSchema';
import { replaceObjInArray } from '../EventSpacesForm/utils';
import { Pane } from 'components/Pane';

import {
    proposalFormToFormData,
    setFeeTaxOnAllGR,
    TGuestRoomsFormValue,
    getErrorMessage,
    formDataToProposalForm,
    TGRFormBooking,
} from './utils';
import { useRegisterValidator, TFormSectionProps } from '../utils';

const Copy = styled.div`
    font-size: 18px;
    line-height: 1.5;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkerGrey)};
`;

const FormContent = styled(Column)`
    margin: 0 48px;
`;

type TCommissionableValue = { commissionable?: boolean | null };

const CommissionableField = ({
    value,
    onChange,
    disabled,
}: {
    value: TCommissionableValue;
    onChange: (update: { value: TCommissionableValue }) => void;
    disabled?: boolean;
}) => (
    <>
        <H3Headline $color={EColors.accentedHeadline}>Are these guest room rates commissionable?</H3Headline>
        <UICopy>You can refer to the attached document or reach out to the NSO for this client.</UICopy>
        <Spacer small />
        <Form {...commissionableForm} value={value} onChange={onChange} disabled={disabled} />
    </>
);

const paneStyles = (background: Themed.Color, borderColor: Themed.Color) => ({
    background,
    border: `1px solid ${borderColor}`,
    borderTop: '0px',
    padding: 48,
});

const { fields, schemaRooms, schemaDetails } = dayForm;

const GuestroomsDayForm = ({
    dayIndex,
    data,
    onChange: onChangeProp,
    disabled,
}: {
    dayIndex: number;
    data: TGRFormBooking;
    onChange: (dayIndex: number, update: { field: string; value: TGRFormBooking }) => void;
    disabled?: boolean;
}) => {
    const { pureWhite, lightGrey } = useThemedColor();

    const onChange = React.useCallback(
        (update: { field: string; value: TGRFormBooking }) => onChangeProp(dayIndex, update),
        [dayIndex, onChangeProp]
    );

    const formBaseProps = React.useMemo(
        () => ({
            fields: fields({ requestedGuests: data.requestedGuests || 0 }),
            value: data,
            onChange,
            disabled,
        }),
        [data, onChange, disabled]
    );

    const form = React.useMemo(
        () => (
            <Column>
                <Form {...formBaseProps} schema={schemaRooms} />
                {(data.proposedRoomCount || 0) > 0 && (
                    <>
                        <Spacer largest />
                        <Form {...formBaseProps} schema={schemaDetails} />
                    </>
                )}
            </Column>
        ),
        [data, formBaseProps]
    );

    return (
        <Pane
            label={`Day ${dayIndex + 1}`}
            secondaryLabel={format(parseISO(data.date), 'EEEE, MMMM dd, yyyy')}
            stackedLabels
            beginExpanded
            invert
            contentStyle={paneStyles(pureWhite, lightGrey)}
        >
            {() => form}
        </Pane>
    );
};

export default function GuestroomsForm({ onChange, registerValidator, disabled, ...rest }: TFormSectionProps) {
    const [data, setData] = React.useState<TGuestRoomsFormValue>(proposalFormToFormData(rest));

    useRegisterValidator(data, registerValidator, getErrorMessage, formDataToProposalForm);

    const singleDayUpdater = React.useCallback(
        (index: number, { field, value: newGRBooking }: { field: string; value: TGRFormBooking }) => {
            setData(prevData => {
                let newGuestRooms = replaceObjInArray(prevData.guestRooms, index, newGRBooking);

                newGuestRooms = (() => {
                    const { resortFee, occupancyTax } = newGRBooking;
                    switch (field) {
                        case 'resortFee':
                            return setFeeTaxOnAllGR(newGuestRooms, { resortFee });
                        case 'occupancyTax':
                            return setFeeTaxOnAllGR(newGuestRooms, { occupancyTax });
                        default:
                            return newGuestRooms;
                    }
                })();

                onChange();
                return {
                    ...prevData,
                    guestRooms: newGuestRooms,
                };
            });
        },
        [setData, onChange]
    );

    const onCommissionableChange = ({ value: newVal }: any) => {
        setData(newVal);
        onChange();
    };

    return (
        <Column>
            <H5Headline>Next, let's talk guest rooms.</H5Headline>
            <Spacer small />
            <Copy>
                This meeting requires overnight accommodations. Please share what's available below.
                <br />
                Don't worry! You'll be able to provide additional notes and documents before submitting.
                <br />
                If you cannot accommodate one or more nights, just leave it blank.
            </Copy>

            <Spacer largest />

            {(rest.guestRooms || []).length === 0 ? <Copy>There are no guestroom requests.</Copy> : null}

            <FormContent itemSpacing="larger">
                {data.guestRooms.map((day, index) => (
                    <GuestroomsDayForm dayIndex={index} data={day} onChange={singleDayUpdater} disabled={disabled} />
                ))}
            </FormContent>

            {data.guestRooms.filter(grBooking => (grBooking.proposedRoomCount || 0) > 0).length > 0 && (
                <FormContent>
                    <Spacer largest />
                    <CommissionableField value={data} onChange={onCommissionableChange} disabled={disabled} />
                </FormContent>
            )}
        </Column>
    );
}
