import moment from 'moment';

const ordinal: Partial<Record<number, string>> = {
    1: 'first',
    2: 'second',
    3: 'third',
    4: 'fourth',
};

export const weekdayFromMonday = (date: Date | moment.Moment = moment()) => (moment(date).weekday() + 7 - 1) % 7;

export function weekdayOfMonth(date = moment()) {
    const firstWeekday = moment(date).startOf('month');
    const useLastForFourth =
        moment(date)
            .endOf('month')
            .daysInMonth() === 28;

    let count = 1;
    let priorWeekday = moment(firstWeekday);
    while (date.diff(priorWeekday, 'days') > 6) {
        priorWeekday.add(1, 'week');
        count++;
    }

    const last = count === 5 || (count === 4 && useLastForFourth);
    const weekdayWithMondayStart = weekdayFromMonday(date);

    return {
        bySetPos: last ? -1 : count,
        byDay: weekdayWithMondayStart,
        count: last ? 'last' : ordinal[count] ?? '',
    };
}
