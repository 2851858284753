type HiddenUserIds = number[];

const localStorageKey = 'calendarHiddenUserIds';

export function loadHiddenUserIds() {
    return JSON.parse(localStorage.getItem(localStorageKey) || '[]') as HiddenUserIds;
}

export function saveHiddenUserIds(hiddenUserIds: number[]) {
    localStorage.setItem(localStorageKey, JSON.stringify(hiddenUserIds));
}
