import React from 'react';
import styled, { css } from 'styled-components';

import { useHistory } from 'react-router-dom';

import { createMeetingActions, useCreateMeeting, ESteps, selCurStep } from './store';

import { Column, Row } from 'ui';
import { ReactComponent as BackArrowSvg } from 'images/icons/StealthLeftArrow.svg';
import { ReactComponent as OverviewIconSvg } from 'images/icons/overview.svg';
import { ReactComponent as AgendaIconSvg } from 'images/icons/agenda.svg';
import { ReactComponent as GuestsIconSvg } from 'images/icons/guests.svg';
import { ReactComponent as SendIconSvg } from 'images/icons/send.svg';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

import { SIDE_NAV_WIDTH } from 'components/SideNav';
import colorFns from 'colorFns';

const AutoRow = styled(Row)`
    width: auto;
`;

const SidebarLink = styled.span<{ active?: boolean; icon?: string; disabled?: boolean }>`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    width: calc(${SIDE_NAV_WIDTH}px - 72px);
    display: block;
    padding: 11px 0;
    display: flex;
    align-items: center;
    opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

    ${props =>
        props.active &&
        `
        background: ${colorFns.pureWhite(props)};
        color: ${colorFns.brand(props)};
        font-weight:600;
        border-radius:8px;
        box-shadow: 0px 2px 4px 0px ${colorFns.brand(props)};

        svg {
            color: ${colorFns.brand(props)};
        }
    `}
`;

const iconStyles = css<{ active?: boolean }>`
    height: 16px;
    width: 15px;
    color: ${colorFns.pureWhite};
    fill: ${colorFns.pureWhite};
    margin-left: 18px;
    margin-right: 9px;
`;

const OverviewIcon = styled(OverviewIconSvg)`
    ${iconStyles}
`;
const AgendaIcon = styled(AgendaIconSvg)`
    ${iconStyles}
`;
const GuestsIcon = styled(GuestsIconSvg)`
    ${iconStyles}
`;
const SendIcon = styled(SendIconSvg)`
    ${iconStyles}
`;

const BackArrowIcon = styled(BackArrowSvg)`
    height: 11px;
    width: 20px;
`;

const BackLink = styled.a`
    color: ${colorFns.pureWhite};
    cursor: pointer;
`;

const stepToIcon = {
    [ESteps.basic]: OverviewIcon,
    [ESteps.agenda]: AgendaIcon,
    [ESteps.attendees]: GuestsIcon,
    [ESteps.invites]: SendIcon,
};

const stepToName = {
    [ESteps.basic]: 'Overview',
    [ESteps.agenda]: 'Agenda',
    [ESteps.attendees]: 'Attendees',
    [ESteps.invites]: 'Send',
};

export default function NavButtons() {
    const curStep = useCreateMeeting(selCurStep);
    const { stepIdx: curStepIdx, stepList, isPublished } = useCreateMeeting();
    const history = useHistory();

    const goBack = () => {
        history.goBack();
    };

    return (
        <Column itemSpacing="medium">
            <BackLink onClick={goBack}>
                <AutoRow alignItems="baseline" itemSpacing="smallish">
                    <BackArrowIcon />
                    Back
                </AutoRow>
            </BackLink>
            {stepList.map((step, stepIdx) => {
                const Icon = stepToIcon[step];
                const name = stepToName[step];

                const disabled = !isPublished && stepIdx > curStepIdx;

                return (
                    <Tooltip title={name} enterDelay={1000} TransitionComponent={Zoom} placement="right">
                        <SidebarLink
                            active={curStep === step}
                            disabled={disabled}
                            onClick={disabled ? () => {} : () => createMeetingActions.goToStep(step)}
                        >
                            <Icon active={curStep === step} />
                        </SidebarLink>
                    </Tooltip>
                );
            })}
        </Column>
    );
}
