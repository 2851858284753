import React from 'react';
import styled from 'styled-components';

import { Copy } from 'ui';
import { H2Headline } from 'components/ui/Headline';
import UIButton from 'components/ui/Button';

const SubCopy = styled(Copy)`
    font-size: 16px;
    line-height: 24px;
`;

const Button = styled(UIButton)`
    width: auto;
`;

export default function SSOView({ SSOLink }: { SSOLink: string }) {
    return (
        <>
            <H2Headline>Sign in with Single Sign-on</H2Headline>
            <SubCopy>Your company has set up Single Sign-on (SSO), so you're all set to use Bizly!</SubCopy>
            <Button onClick={() => window.open(SSOLink)}>Sign In With Your Company Credentials</Button>
        </>
    );
}
