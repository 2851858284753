import React from 'react';

import { RSVPFormPhoneField } from 'pages/RSVPForm';
import { ExternalLink, Copy } from 'ui';

export const fields = (agreementLink?: string) => ({
    firstName: {
        prompt: 'First Name',
        type: 'text',
    },
    lastName: {
        prompt: 'Last Name',
        type: 'text',
    },
    title: {
        prompt: 'Title',
        type: 'text',
        perRow: 2,
    },
    phone: {
        prompt: 'Phone Number',
        type: RSVPFormPhoneField,
    },
    email: {
        prompt: 'Email Address',
        type: 'text',
    },
    agreementRead: {
        type: 'checkbox',
        options: {
            label: (
                <Copy>
                    I have downloaded and read the{' '}
                    <ExternalLink
                        onClick={
                            e => e.stopPropagation() // buttons in a form will be treated as submit
                        }
                        href={agreementLink}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        client agreement
                    </ExternalLink>
                    .
                </Copy>
            ),
        },
    },
});

export const schema = (agreementLink?: string) => [
    {
        key: 'rowOne',
        fields: ['firstName', 'lastName'],
        spacing: 'medium',
    },
    {
        key: 'rowTwo',
        fields: ['email', 'phone'],
        spacing: 'medium',
    },
    {
        key: 'rowThree',
        fields: ['title'],
        spacing: 'large',
    },
    ...(agreementLink
        ? [
              {
                  key: 'rowFour',
                  fields: ['agreementRead'],
                  spacing: false,
              },
          ]
        : []),
];
