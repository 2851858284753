// Our backend API server, using the Carbon library for date/time interpretation, only supports the following list of timezones:

const carbonSupportedTimeZones = [
    'Pacific/Midway',
    'Pacific/Pago_Pago',
    'Pacific/Honolulu',
    'America/Adak',
    'America/Anchorage',
    'America/Juneau',
    'America/Metlakatla',
    'America/Nome',
    'America/Sitka',
    'America/Yakutat',
    'America/Creston',
    'America/Dawson',
    'America/Dawson_Creek',
    'America/Fort_Nelson',
    'America/Hermosillo',
    'America/Los_Angeles',
    'America/Phoenix',
    'America/Tijuana',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Belize',
    'America/Boise',
    'America/Cambridge_Bay',
    'America/Chihuahua',
    'America/Costa_Rica',
    'America/Denver',
    'America/Edmonton',
    'America/El_Salvador',
    'America/Guatemala',
    'America/Inuvik',
    'America/Managua',
    'America/Mazatlan',
    'America/Ojinaga',
    'America/Regina',
    'America/Swift_Current',
    'America/Tegucigalpa',
    'America/Yellowknife',
    'America/Atikokan',
    'America/Bahia_Banderas',
    'America/Cancun',
    'America/Cayman',
    'America/Chicago',
    'America/Indiana/Knox',
    'America/Indiana/Tell_City',
    'America/Jamaica',
    'America/Matamoros',
    'America/Menominee',
    'America/Merida',
    'America/Mexico_City',
    'America/Monterrey',
    'America/North_Dakota/Beulah',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/Panama',
    'America/Rainy_River',
    'America/Rankin_Inlet',
    'America/Resolute',
    'America/Winnipeg',
    'America/Anguilla',
    'America/Antigua',
    'America/Aruba',
    'America/Barbados',
    'America/Blanc-Sablon',
    'America/Curacao',
    'America/Detroit',
    'America/Dominica',
    'America/Grand_Turk',
    'America/Grenada',
    'America/Guadeloupe',
    'America/Havana',
    'America/Indiana/Indianapolis',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Vevay',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Iqaluit',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Kralendijk',
    'America/Lower_Princes',
    'America/Marigot',
    'America/Martinique',
    'America/Montserrat',
    'America/Nassau',
    'America/New_York',
    'America/Nipigon',
    'America/Pangnirtung',
    'America/Port-au-Prince',
    'America/Port_of_Spain',
    'America/Puerto_Rico',
    'America/Santo_Domingo',
    'America/St_Barthelemy',
    'America/St_Kitts',
    'America/St_Lucia',
    'America/St_Thomas',
    'America/St_Vincent',
    'America/Thunder_Bay',
    'America/Toronto',
    'America/Tortola',
    'America/Glace_Bay',
    'America/Goose_Bay',
    'America/Halifax',
    'America/Moncton',
    'America/Thule',
    'Atlantic/Bermuda',
    'America/St_Johns',
    'Africa/Algiers',
    'Africa/Bangui',
    'Africa/Brazzaville',
    'Africa/Douala',
    'Africa/Kinshasa',
    'Africa/Lagos',
    'Africa/Libreville',
    'Africa/Luanda',
    'Africa/Malabo',
    'Africa/Ndjamena',
    'Africa/Niamey',
    'Africa/Porto-Novo',
    'Africa/Tunis',
    'Atlantic/Canary',
    'Atlantic/Faroe',
    'Atlantic/Madeira',
    'Europe/Dublin',
    'Europe/Guernsey',
    'Europe/Isle_of_Man',
    'Europe/Jersey',
    'Europe/Lisbon',
    'Europe/London',
    'Africa/Blantyre',
    'Africa/Bujumbura',
    'Africa/Cairo',
    'Africa/Ceuta',
    'Africa/Gaborone',
    'Africa/Harare',
    'Africa/Johannesburg',
    'Africa/Khartoum',
    'Africa/Kigali',
    'Africa/Lubumbashi',
    'Africa/Lusaka',
    'Africa/Maputo',
    'Africa/Maseru',
    'Africa/Mbabane',
    'Africa/Tripoli',
    'Africa/Windhoek',
    'Arctic/Longyearbyen',
    'Europe/Amsterdam',
    'Europe/Andorra',
    'Europe/Belgrade',
    'Europe/Berlin',
    'Europe/Bratislava',
    'Europe/Brussels',
    'Europe/Budapest',
    'Europe/Busingen',
    'Europe/Copenhagen',
    'Europe/Gibraltar',
    'Europe/Kaliningrad',
    'Europe/Ljubljana',
    'Europe/Luxembourg',
    'Europe/Madrid',
    'Europe/Malta',
    'Europe/Monaco',
    'Europe/Oslo',
    'Europe/Paris',
    'Europe/Podgorica',
    'Europe/Prague',
    'Europe/Rome',
    'Europe/San_Marino',
    'Europe/Sarajevo',
    'Europe/Skopje',
    'Europe/Stockholm',
    'Europe/Tirane',
    'Europe/Vaduz',
    'Europe/Vatican',
    'Europe/Vienna',
    'Europe/Warsaw',
    'Europe/Zagreb',
    'Europe/Zurich',
    'Africa/Addis_Ababa',
    'Africa/Asmara',
    'Africa/Dar_es_Salaam',
    'Africa/Djibouti',
    'Africa/Juba',
    'Africa/Kampala',
    'Africa/Mogadishu',
    'Africa/Nairobi',
    'Asia/Amman',
    'Asia/Beirut',
    'Asia/Damascus',
    'Asia/Famagusta',
    'Asia/Gaza',
    'Asia/Hebron',
    'Asia/Jerusalem',
    'Asia/Nicosia',
    'Europe/Athens',
    'Europe/Bucharest',
    'Europe/Chisinau',
    'Europe/Helsinki',
    'Europe/Kiev',
    'Europe/Mariehamn',
    'Europe/Moscow',
    'Europe/Riga',
    'Europe/Simferopol',
    'Europe/Sofia',
    'Europe/Tallinn',
    'Europe/Uzhgorod',
    'Europe/Vilnius',
    'Europe/Zaporozhye',
    'Indian/Antananarivo',
    'Indian/Comoro',
    'Indian/Mayotte',
    'Asia/Karachi',
    'Asia/Kolkata',
    'Asia/Jakarta',
    'Asia/Pontianak',
    'Asia/Hong_Kong',
    'Asia/Macau',
    'Asia/Makassar',
    'Asia/Manila',
    'Asia/Shanghai',
    'Asia/Taipei',
    'Australia/Perth',
    'Asia/Jayapura',
    'Asia/Pyongyang',
    'Asia/Seoul',
    'Asia/Tokyo',
    'Australia/Darwin',
    'Australia/Brisbane',
    'Australia/Lindeman',
    'Pacific/Guam',
    'Pacific/Saipan',
    'Australia/Adelaide',
    'Australia/Broken_Hill',
    'Australia/Currie',
    'Australia/Hobart',
    'Australia/Melbourne',
    'Australia/Sydney',
    'Antarctica/McMurdo',
    'Pacific/Auckland',
    'UTC',
];

export default carbonSupportedTimeZones;
