import React from 'react';
import styled from 'styled-components';
import useShowModal from 'hooks/useShowModal';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';

import { useUser } from 'providers/user';
import { LoadTeams, teamsActions, useTeams } from 'stores/team-memberships';

import { Column, Copy, CopyFaded, InlineRow, Row } from 'ui';
import { H2Headline } from './ui/Headline';
import { Dialog } from '@material-ui/core';
import { Spinner, SpinnerOverlay } from './Spinner';
import Button from './ui/Button';

import { ReactComponent as CloseIconSvg } from 'images/icons/close.svg';
import { ReactComponent as CheckMarkSvg } from 'images/icons/check-mark.svg';
import { withInteractibleIconStyles } from 'shared';
import colorFns from 'colorFns';

const TeamName = styled.div`
    cursor: pointer;
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction, 0.41)};
    width: 100%;
    margin: 0 !important;
    text-align: center;
    display: block;
    padding: 20px 0;
    font-weight: 600;
`;

const PaddedDialog = styled(Dialog)`
    .MuiPaper-root > *:not(:last-child) {
        border-bottom: 1px solid ${colorFns.softBorder};
    }
`;

const ModalContent = styled.div`
    overflow: auto;
    padding: 20px 26px;
    box-sizing: border-box;
    min-width: 460px;
    max-height: 600px;
`;

const Header = styled(Row)`
    padding: 20px;
    box-sizing: border-box;
`;

const CloseIcon = styled(CloseIconSvg)`
    cursor: pointer;
`;

const TeamLogo = styled.img`
    height: 29px;
    width: 29px;

    vertical-align: middle; /* remove white space under img and align it with rest of row https://stackoverflow.com/questions/7774814/remove-white-space-below-image */
`;

const Teams = styled(Column)`
    > *:first-child:not(:last-child) {
        border-bottom: 1px solid ${colorFns.softBorder};
        padding-bottom: 12px;
    }
`;

const RightCheckMark = styled(withInteractibleIconStyles(CheckMarkSvg))``;

const TeamMembership = styled.div``;
const TeamInfo = styled(Row)`
    border-radius: 8px;
    padding: 12px;
    box-sizing: border-box;

    ${({ onClick, ...props }) =>
        onClick
            ? `
    cursor: pointer;

    &:hover {
        background-color: ${colorFns.dropdownItemHover(props)};
    }
    `
            : ''}

    ${RightCheckMark} {
        margin-left: auto;
    }
`;

const MembershipsList = ({
    onSelect,
    onAccept,
    onReject,
    processing,
    memberships,
}: {
    onSelect: (teamId: number) => Promise<any>;
    onAccept: (teamId: number, membershipId: number) => Promise<any>;
    onReject: (teamId: number, membershipId: number) => Promise<any>;
    processing?: boolean;
    memberships: BizlyAPI.TeamMembership[];
}) => {
    return (
        <Teams itemSpacing="small">
            {memberships.map(({ id, role, team, status }) => (
                <TeamMembership key={id}>
                    <TeamInfo
                        alignItems="center"
                        justifyContent="space-between"
                        onClick={team.currentlySelected || status.int !== 1 ? undefined : () => onSelect(team.id)}
                    >
                        <InlineRow alignItems="center" itemSpacing="small">
                            <TeamLogo src={team.imageUrl} />
                            <Column>
                                <Copy>{`${team.name} (#${team.id})`}</Copy>
                                <Copy small>{role.name}</Copy>
                            </Column>
                        </InlineRow>
                        <InlineRow alignItems="center" itemSpacing="small">
                            {status.int === 2 && (
                                <>
                                    <Button onClick={() => onAccept(team.id, id)} disabled={processing}>
                                        Accept
                                    </Button>
                                    <Button onClick={() => onReject(team.id, id)} warning disabled={processing}>
                                        Reject
                                    </Button>
                                </>
                            )}
                            {status.int === 4 && <CopyFaded>Rejected Invite</CopyFaded>}
                            {team.currentlySelected && <RightCheckMark />}
                        </InlineRow>
                    </TeamInfo>
                </TeamMembership>
            ))}
        </Teams>
    );
};

export default function TeamSelector({ initOpen }: { initOpen?: boolean }) {
    const { user, setTeam } = useUser();

    const { modalShown, showModal, hideModal } = useShowModal(initOpen);

    const teamsStore = useTeams();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const [updating, setUpdating] = React.useState(false);
    const changeTeam = async (teamId: number) => {
        if (!user.id || updating) {
            return;
        }

        try {
            setUpdating(true);
            const updatedUser = await teamsActions.changeTeam(user.id, teamId);
            if (updatedUser?.team) {
                setTeam(updatedUser.team);
            }
            history.go(0); // all state is invalid if team is changed
        } catch (e) {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        } finally {
            setUpdating(false);
        }
    };

    const accept = async (teamId: number, membershipId: number) => {
        if (teamsStore.accepting || teamsStore.rejecting) return;

        try {
            await teamsActions.acceptTeam(teamId, membershipId);
        } catch (e) {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    };

    const reject = async (teamId: number, membershipId: number) => {
        if (teamsStore.accepting || teamsStore.rejecting) return;

        try {
            await teamsActions.rejectTeam(teamId, membershipId);
        } catch (e) {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    };

    return (
        <>
            <TeamName onClick={showModal}>{user.team?.name}</TeamName>
            {modalShown && (
                <PaddedDialog open maxWidth="lg" onBackdropClick={hideModal}>
                    {user.id && <LoadTeams userId={user.id} />}
                    <Header>
                        <Row justifyContent="space-between" alignItems="center">
                            <H2Headline>Switch Team</H2Headline>
                            <CloseIcon onClick={hideModal} />
                        </Row>
                    </Header>
                    <ModalContent>
                        {teamsStore.loaded ? (
                            <MembershipsList
                                memberships={teamsStore.memberships}
                                onSelect={changeTeam}
                                onAccept={accept}
                                onReject={reject}
                                processing={teamsStore.accepting || teamsStore.rejecting}
                            />
                        ) : (
                            <Spinner />
                        )}

                        {updating && <SpinnerOverlay />}
                    </ModalContent>
                </PaddedDialog>
            )}
        </>
    );
}
