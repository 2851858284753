import createStore from 'zustand';
import { withCache } from 'stores/utils';

import camelize from 'camelcase-keys';
import orderBy from 'lodash/orderBy';
import uuid from 'uuid-random';
import { tzMoment } from 'utils/moment';
import { WebSocketSubject } from 'rxjs/webSocket';
// @ts-ignore
import snakeize from 'snakeize';

import { getMessages, getWebsocket, sendMessage } from 'api/chime';
import { attendeesActions, attendeesStoreApi } from 'stores/attendees';
import { meetingsActions } from 'stores/meetings';

const snakeizeFn: <T>(obj: T) => T = snakeize;

type Metadata = { sentId: string } & Record<string, any>;

type State = {
    loading: boolean;
    pendingMessages: {
        id: string;
        content: string;
        time: string;
        metadata: Metadata;
    }[];
    pendingId: number;

    socketConn: WebSocketSubject<BizlyChime.WebsocketRaw> | null;
} & (
    | {
          loaded: false;
          channelArn?: string;
          nextToken?: null;
          messages: null;
      }
    | {
          loaded: true;
          channelArn: string;
          nextToken: string | null;
          messages: BizlyChime.ChatMessage[];
      }
);

type Store = State;

const initialState: State = {
    loading: false,
    loaded: false,
    pendingMessages: [],
    pendingId: 1,

    messages: null,
    nextToken: null,
    socketConn: null,
};
export const [useChimeChat, chimeChatStoreApi] = createStore<Store>(() => initialState);

const { setState, getState } = chimeChatStoreApi;
const loadWithCache = withCache({
    storeApi: chimeChatStoreApi,
    cacheFn: state => (state.messages ? { chimeInfo: state.messages, channelArn: state.channelArn } : {}),
    key: 'channelArn',
    shouldCache: state => !!state.messages,
});

const messageIsChat = (message: BizlyChime.ProcessedMessage): message is BizlyChime.CreateChannelMessage.Processed => {
    return message.type === 'CREATE_CHANNEL_MESSAGE';
};

const processMessage = (message: BizlyChime.RawMessage): BizlyChime.ProcessedMessage => {
    const standardMessage = {
        headers: message.Headers,
        payload: message.Payload || '{}',
        type: message.Headers['x-amz-chime-event-type'],
    };

    let parsedPayload = {};
    try {
        parsedPayload = JSON.parse(standardMessage.payload);
    } catch (e) {}

    const payload = camelize(parsedPayload, { deep: true }) as BizlyChime.ProcessedMessage['payload'] & {
        metadata?: string;
    };

    return {
        ...standardMessage,
        payload: { ...payload, ...(payload.metadata ? { metadata: processMetadata(payload.metadata) } : {}) },
    };
};

const processMetadata = (metadata: string) => {
    let parsedPayload = {};
    try {
        parsedPayload = JSON.parse(metadata);
    } catch (e) {}

    return camelize(parsedPayload) as BizlyChime.ProcessedMetadata;
};

const stringifyMetadata = (metadata: Metadata) => JSON.stringify(snakeizeFn(metadata));

const refreshAttendees = (arns: string[]) => {
    const { attendees, meetingId } = attendeesStoreApi.getState();
    const arnsSet = new Set(arns);
    if (
        meetingId &&
        attendees &&
        attendees.some(
            attendee => attendee.chimeInfo?.appInstanceUserArn && !arnsSet.has(attendee.chimeInfo?.appInstanceUserArn)
        )
    ) {
        attendeesActions.load(meetingId);
    }
};

const refreshDocuments = () => {
    const { meetingId } = attendeesStoreApi.getState();
    if (meetingId) meetingsActions.loadSingle(meetingId);
};

export const chimeChatActions = {
    load: loadWithCache(async (channelArn: string) => {
        let { channelArn: curChannelArn } = getState();
        if (channelArn !== curChannelArn) chimeChatActions.disconnectSocket();

        setState({
            loading: true,
            ...(channelArn !== curChannelArn
                ? {
                      messages: null,
                      channelArn,
                      loaded: false,
                  }
                : {}),
        });

        try {
            const { messages, nextToken } = await getMessages(channelArn);
            const sorted = orderBy(messages, m => tzMoment(m.createdTimestamp).valueOf(), 'desc');

            ({ channelArn: curChannelArn } = getState());
            if (curChannelArn !== channelArn) return;

            refreshAttendees(messages.map(message => message.sender.arn));

            setState({
                messages: sorted,
                nextToken,
                loading: false,
                loaded: true,
            });

            return messages;
        } catch (e) {
            setState({ loading: false });

            throw e;
        }
    }),

    loadMore: async (channelArn: string) => {
        let curState = getState();
        if (!curState.loaded) return;
        if (curState.loading) return;
        if (!curState.nextToken) return;
        if (curState.channelArn !== channelArn) return;

        setState({ loading: true });

        try {
            const { messages, nextToken } = await getMessages(channelArn, curState.nextToken);
            const sorted = orderBy(messages, m => tzMoment(m.createdTimestamp).valueOf(), 'desc');

            curState = getState();
            if (!curState.messages) return;
            if (curState.channelArn !== channelArn) return;

            refreshAttendees(messages.map(message => message.sender.arn));

            setState({
                loading: false,
                messages: [...curState.messages, ...sorted],
                nextToken,
            });

            return messages;
        } catch (e) {
            setState({ loading: false });

            throw e;
        }
    },

    connectSocket: async (userEmail: string) => {
        try {
            chimeChatActions.disconnectSocket();
            const socketConn = await getWebsocket([userEmail, new Date().toISOString()].join('/'));
            socketConn.subscribe(
                raw => {
                    const message = processMessage(raw);
                    if (messageIsChat(message) && message.payload.channelArn === getState().channelArn) {
                        chimeChatActions.addMessage(message.payload);
                    }
                }, // Called whenever there is a message from the server.
                err => {
                    socketConn.unsubscribe();
                    setState({ socketConn: null });
                },
                () => {
                    socketConn.unsubscribe();
                    setState({ socketConn: null });
                }
            );

            setState({ socketConn });
        } catch (e) {
            throw e;
        }
    },

    disconnectSocket: async () => {
        const curState = getState();
        try {
            if (curState.socketConn) curState.socketConn.complete();
            setState({ socketConn: null });
        } catch (e) {
            throw e;
        }
    },

    addMessage: (message: BizlyChime.ChatMessage) => {
        const curState = getState();
        if (!curState.messages) return;

        refreshAttendees([message.sender.arn]);
        if (message.metadata?.attachments ?? [].length > 0) {
            refreshDocuments();
        }

        setState({
            messages: [message, ...curState.messages],
            pendingMessages: curState.pendingMessages.filter(pending => pending.id !== message.metadata?.sentId),
        });
    },

    send: async (channelArn: string, message: string, metadata?: Record<string, any>) => {
        let curState = getState();

        if (!curState.loaded) return;
        if (curState.loading) return;
        if (curState.channelArn !== channelArn) return;

        const id = uuid();
        const metadataPayload = { sentId: id, ...metadata };
        const pendingMessage = {
            id,
            content: message,
            time: new Date().toISOString(),
            metadata: metadataPayload,
        };

        setState({
            pendingMessages: [pendingMessage, ...curState.pendingMessages],
            pendingId: curState.pendingId + 1,
        });

        try {
            await sendMessage(channelArn, message, stringifyMetadata(metadataPayload));

            curState = getState();
            if (!curState.messages) return;

            return pendingMessage;
        } catch (e) {
            setState({
                pendingMessages: curState.pendingMessages.filter(m => m.id !== id),
            });
        }
    },
};

export const getPending = (state: State) => state.pendingMessages;
export const getMessagesList = (state: State) => state.messages;
