import React from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';

import { Column, Row, PromptedTextField } from 'ui';
import Button from 'components/ui/Button';

const Container = styled(Column)`
    padding: 24px 16px;
    width: 500px;
`;

const ControlsRow = styled(Row)`
    justify-content: flex-end;
`;

const SetLinkModal = ({
    open,
    value,
    onChange,
    onClear,
    onSave,
    handleOnClose,
}: {
    open: boolean;
    value: string | null;
    onChange: (url: string) => void;
    onClear: () => void;
    onSave: () => void;
    handleOnClose: () => void;
}) => (
    <Dialog open={open} onClose={handleOnClose} maxWidth="md">
        <Container>
            <PromptedTextField
                prompt="What URL should this link go to?"
                onChange={e => onChange(e.target.value)}
                placeholder="http://www.yourlink.com/"
                value={value}
                variant="outlined"
            />
            <ControlsRow itemSpacing="smallish">
                <Button secondary onClick={handleOnClose}>
                    Cancel
                </Button>
                <Button secondary onClick={onClear}>
                    Clear
                </Button>
                <Button onClick={onSave} disabled={!value?.length}>
                    Save
                </Button>
            </ControlsRow>
        </Container>
    </Dialog>
);

export default SetLinkModal;
