import React, { useMemo, useState, useEffect, useCallback } from 'react';

import styled, { keyframes } from 'styled-components/macro';

import useThemedColor from 'hooks/useThemedColor';

import { ReactComponent as MapPin } from '../images/icons/venue_map_pin_nofill.svg';

import GoogleMapReact, { Coords } from 'google-map-react';

const MapStyles = [
    {
        elementType: 'geometry',
        stylers: [
            {
                color: '#f5f5f5',
            },
        ],
    },
    {
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#616161',
            },
        ],
    },
    {
        elementType: 'labels.text.stroke',
        stylers: [
            {
                color: '#f5f5f5',
            },
        ],
    },
    {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#bdbdbd',
            },
        ],
    },
    {
        featureType: 'landscape',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#e6e5e3',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
            {
                color: '#eeeeee',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#757575',
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e5e5e5',
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#9e9e9e',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#757575',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
            {
                color: '#dadada',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#616161',
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#f8f2e7',
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#f8f2e7',
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#9e9e9e',
            },
        ],
    },
    {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e5e5e5',
            },
        ],
    },
    {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [
            {
                color: '#eeeeee',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
            {
                color: '#c9c9c9',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#94cee0',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#9e9e9e',
            },
        ],
    },
];

const Map = styled.div`
    border-radius: 8px;
    overflow: hidden;

    .zIndex2 {
        z-index: 2;
    }

    .zIndex1 {
        z-index: 1;
    }
`;
const bounce = keyframes`
  0%   { transform: scale(1,1)      translateX(-50%)  translateY(calc(-100% + 5px)); }
  10%  { transform: scale(1.1,.9)   translateX(-50%)  translateY(calc(-100% + 5px)); }
  30%  { transform: scale(.9,1.1)   translateX(-50%)  translateY(calc(-100% - 5px)); }
  50%  { transform: scale(1.05,.95) translateX(-50%)  translateY(calc(-100% + 5px)); }
  75% { transform: scale(1,1)      translateX(-50%)  translateY(calc(-100% + 5px)); }
  100% { transform: scale(1,1)      translateX(-50%)  translateY(calc(-100% + 5px)); }
`;

const MapPinMarker = MapPin as React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & Coords & { $markerHolderClassName?: string }
>;

const AnimatedPin = styled(MapPinMarker)<{ isHighlighted: boolean }>`
    transform: translate(-50%, calc(-100% + 5px));
    animation: ${bounce} 2s cubic-bezier(0.28, 0.84, 0.42, 1) infinite;

    ${props => (props.isHighlighted ? `` : `animation: unset;`)}
    ${props => (props.color ? `color: ${props.color};` : ``)}
`;

type TProps = {
    center: { lat: number; lng: number };
    venues: Bizly.Venue[];
    highlightedVenueId?: number;
    className?: string;
};

const MAP_STYLES = { styles: MapStyles };
const BLANK_STYLES = { style: {} };
const MAP_KEY = { key: process.env.REACT_APP_GMAPS_KEY || '' };

type TGoogleMapExtraProps = {
    onBoundsChange: (center: any, zoom: any, bounds: number[]) => void;
};
const GoogleMap = styled(GoogleMapReact)<TGoogleMapExtraProps>``;

const VenueMap = ({ center, venues = [], highlightedVenueId, className = '' }: TProps) => {
    const { venuePinOffice, venuePinBase } = useThemedColor();

    const defaultCenter = useMemo(() => ({ lat: center.lat, lng: center.lng }), [center.lat, center.lng]);
    const [mapCenter, setCenter] = useState(defaultCenter);
    const [mapProps, setMapProps] = useState({} as any);
    const [bounds, setBounds] = useState([] as number[]);

    // TODO: type these things from google api properly
    const getMapBounds = (maps: any, places: any[]) => {
        const bounds = new maps.LatLngBounds();

        places.forEach(place => {
            bounds.extend(new maps.LatLng(place.lat, place.lng));
        });
        return bounds;
    };

    const loaded = useCallback(props => {
        setMapProps(props);
    }, []);

    useEffect(() => {
        if (mapProps.maps && venues.length > 1) {
            mapProps.map.fitBounds(getMapBounds(mapProps.maps, venues), 10);
        }
    }, [mapProps, venues]);

    const boundsChange = useCallback((center: any, zoom: any, newBounds: number[]) => setBounds(newBounds), [
        setBounds,
    ]);

    useEffect(() => {
        const highlightedVenue = venues.find(p => p.id === highlightedVenueId);
        if (highlightedVenue) {
            const { lat, lng } = highlightedVenue;
            if (lat < bounds[2] || lat > bounds[0] || lng < bounds[1] || lng > bounds[3])
                setCenter({ lat: highlightedVenue.lat, lng: highlightedVenue.lng });
        }
    }, [highlightedVenueId, venues, bounds]);

    return (
        <Map className={className}>
            <GoogleMap
                bootstrapURLKeys={MAP_KEY}
                defaultCenter={defaultCenter}
                center={mapCenter}
                defaultZoom={15}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={loaded}
                onBoundsChange={boundsChange}
                options={MAP_STYLES}
                style={BLANK_STYLES}
                /* ^stops the internal map component from copying the style of this component 
          and preventing it from growing to the correct size defined by consumer
        */
            >
                {venues.map(({ id, lat, lng, type }) => {
                    const color = type?.id === 10 ? venuePinOffice : venuePinBase;
                    return (
                        <AnimatedPin
                            key={id}
                            lat={lat}
                            lng={lng}
                            color={color}
                            isHighlighted={highlightedVenueId === id}
                            $markerHolderClassName={highlightedVenueId === id ? 'zIndex2' : 'zIndex1'}
                        />
                    );
                })}
            </GoogleMap>
        </Map>
    );
};

export default VenueMap;
