import React from 'react';
import styled from 'styled-components';
import { generatePath, useHistory, useLocation, useParams } from 'react-router';
import pick from 'lodash/pick';
import useUnsavedPrompt from 'hooks/useUnsavedPrompt';

import { createMeetingActions, useCreateMeeting, ESteps, selCurStep, LoadCreateMeeting } from './store';
import { LoadPlaybooks, selectPlaybook, usePlaybooks } from 'stores/playbooks';
import { useUser } from 'providers/user';

import { Column, Spacer } from 'ui';
import Header from './components/Header';
import { SpinnerOverlay } from 'components/Spinner';
import BasicInfoForm from './BasicInfoForm';
import AgendaForm from './AgendaForm';
import AttendeesForm from './AttendeesForm';
import InvitesForm from './InvitesForm';

const Container = styled(Column)`
    position: relative;
    width: 100%;
    min-height: 100vh;
    align-items: flex-start;
    flex: 0 0 auto;
`;

const SpinnerOverlayAbove = styled(SpinnerOverlay)`
    z-index: 10;
`;

const WideHeader = styled(Header)`
    width: calc(100% + 64px);
    margin-left: -32px;
`;

const Sticky = styled.div<{ float?: boolean }>`
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;
    ${({ float }) =>
        float
            ? `
        & > ${WideHeader} {
            margin-bottom: -100%;
        }
    `
            : ''}
`;

const Fullscreen = styled.div`
    min-width: calc(100% + 94px);
`;

const stepToForm = {
    [ESteps.basic]: <BasicInfoForm />,
    [ESteps.agenda]: <AgendaForm />,
    [ESteps.attendees]: <AttendeesForm />,
    [ESteps.invites]: <InvitesForm />,
};

export default function CreateMeeting() {
    React.useEffect(() => createMeetingActions.reset, []);
    const curStep = useCreateMeeting(selCurStep);
    const { loading, saving, publishing, isPublished, saved } = useCreateMeeting();

    const { id } = useParams<{ id?: string }>();
    const location = useLocation<Partial<{ skipLoading?: boolean; playbookId?: number | string; step?: ESteps }>>();
    const history = useHistory();

    React.useEffect(() => {
        if (location.state?.skipLoading) {
            history.replace({ ...history.location, state: undefined });
        }
    }, [location, history]);

    const playbookId = location.state?.playbookId;
    const { loaded: loadedPlaybooks } = usePlaybooks();
    const playbook = usePlaybooks(selectPlaybook(playbookId));

    const waitingForPlaybooks = playbookId && !loadedPlaybooks;

    React.useEffect(() => {
        if (playbookId && playbook) {
            createMeetingActions.applyPlaybook(playbook);
            history.replace({
                ...history.location,
                state: history.location.state
                    ? { ...history.location.state, playbookId: undefined }
                    : history.location.state,
            });
        }
    }, [playbookId, playbook, history]);

    const initStep = location.state?.step;
    React.useEffect(() => {
        if (initStep) {
            createMeetingActions.goToStep(initStep);
        }
    }, [initStep]);

    React.useEffect(() => {
        if (!id || isPublished === false) {
            history.replace({
                ...history.location,
                state: history.location.state ? { ...history.location.state, step: undefined } : history.location.state,
            });
        }
    }, [id, history, isPublished]);

    React.useEffect(() => {
        if (isPublished) {
            history.replace({
                ...history.location,
                state: { ...history.location.state, step: curStep },
            });
        }
    }, [isPublished, curStep, history]);

    const fullscreenForm = curStep === ESteps.invites;
    const form = stepToForm[curStep];

    React.useEffect(() => {
        if (!id) createMeetingActions.setSteps([ESteps.basic, ESteps.agenda, ESteps.attendees, ESteps.invites]);
    }, [id, isPublished]);

    const { user } = useUser();
    const useCreateEventModal = user.team?.useCreateEventModal;
    React.useEffect(() => {
        if (useCreateEventModal)
            createMeetingActions.setAdditionalRequired(['location', 'type', 'costCenter', 'internalReference']);
    }, [useCreateEventModal]);

    React.useEffect(() => {
        if (!id) {
            createMeetingActions.setAttendees([{ ...pick(user, ['firstName', 'lastName', 'email', 'phone']) }]);
            createMeetingActions.initDefaultDate();
            createMeetingActions.setSaved(true);
        }
    }, [id, user]);

    const renderPrompt = useUnsavedPrompt(!saved, newLocation => {
        const path = id ? generatePath('/meetings/:id/edit', { id }) : generatePath('/meetings/new');
        return newLocation.pathname === history.location.pathname || path === newLocation.pathname;
    });

    return (
        <Container>
            <LoadCreateMeeting id={id} skip={location.state?.skipLoading} />
            <LoadPlaybooks />
            <Sticky float={fullscreenForm}>
                <WideHeader noBackground={fullscreenForm} />
            </Sticky>
            {renderPrompt()}

            {!fullscreenForm && <Spacer larger />}
            {!loading && fullscreenForm ? <Fullscreen>{form}</Fullscreen> : form}
            {(loading || saving || publishing || waitingForPlaybooks) && <SpinnerOverlayAbove fixed />}
        </Container>
    );
}
