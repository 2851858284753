import React from 'react';

import { Spinner } from 'components/Spinner';

import { BizlyLogoPageWithCard } from './ui';

import { useAuthenticate } from './util';

import * as copy from 'copy/login.json';

export default function ActivateNewUser() {
    useAuthenticate(true);

    return (
        <BizlyLogoPageWithCard heading={copy.acceptInvite.heading}>
            <Spinner />
        </BizlyLogoPageWithCard>
    );
}
