import React from 'react';

import styled from 'styled-components';

import { Column, Row, Copy } from '../ui';
import { findPlaces, findCities } from '../api';

import { ReactComponent as LocationPinIcon } from '../images/icons/location_pin.svg';

import AutoComplete from './ui/AutoComplete';
import { InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { withIconStyles } from 'shared';

const Container = styled(Column)`
    .MuiTextField-root {
        transform: unset; // Prevents text blur in Safari
        background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.1)} !important;
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)} !important;
        height: 41px;
    }
`;

const Suggestion = styled(Row)`
    align-items: center;
`;

const LocationPin = styled(withIconStyles(LocationPinIcon))`
    min-width: 21px;
    max-width: 21px;

    padding-right: 1px;
`;

const SuggestionText = styled(Copy).attrs({ regular: true })`
    overflow: hidden;
    text-overflow: ellipsis;
`;

type TPlaceInput<TInputAsSuggestion extends boolean | undefined> = {
    disabled?: boolean;
    defaultValue?: string;
    defaultValueId?: string;
    asFormField?: boolean;
    inModalOrPopover?: boolean;
    placeholder?: string;
    citiesOnly?: boolean;
    locationBias?: { lat: number; lng: number };

    showSearchIcon?: boolean;
    onBlur?: () => void;
    autoFocus?: boolean;
    selectInputOnBlur?: boolean;
} & ({} & TInputAsSuggestion extends true
    ? {
          inputAsSuggestion: true;
          onChange: (city: string, googlePlaceId?: string | undefined) => void;
      }
    : {
          inputAsSuggestion?: false | undefined;
          onChange: (city: string, googlePlaceId: string) => void;
      });

type TOption = {
    description: string;
    placeId: string;
    noIcon?: boolean;
};

export default function PlaceInput(props: TPlaceInput<true>): JSX.Element;
export default function PlaceInput(props: TPlaceInput<false>): JSX.Element;
export default function PlaceInput(props: TPlaceInput<true> | TPlaceInput<false>) {
    const {
        disabled,
        defaultValue,
        defaultValueId,
        onChange,
        asFormField,
        inModalOrPopover,
        placeholder,
        citiesOnly,
        locationBias,

        showSearchIcon,
        onBlur,
        autoFocus,
        inputAsSuggestion,
        selectInputOnBlur,
    } = props;

    const [query, setQuery] = React.useState(defaultValue);
    const [options, setOptions] = React.useState<TOption[]>();
    const [loading, setLoading] = React.useState(false);
    const [noOptionsText, setNoOptionsText] = React.useState(
        `We couldn’t find any locations matching "${query}". Try again.`
    );
    const inputOption = { description: query || defaultValue || '', placeId: defaultValueId || '', noIcon: true };
    const stagedVal = React.useRef<TOption | undefined>(inputOption);

    const resetOptions = () => {
        setLoading(false);
        setOptions(undefined);
    };

    async function handleSuggestions(query: string) {
        if (query) stagedVal.current = undefined;
        setQuery(query);
        setNoOptionsText(`We couldn’t find any locations matching "${query}". Try again.`);

        if (!query) {
            resetOptions();
            return;
        }

        setLoading(true);
        try {
            const response = await (citiesOnly
                ? findCities(query)
                : findPlaces(query, locationBias?.lat, locationBias?.lng));
            // to handle race conditions, we should check if query is blank here and not update options
            // but AutoComplete will also hide options if there is not query/input value

            if (response.status === 'OK') {
                setOptions(response.predictions);
            } else {
                setOptions([]); // allows the component to show no options text
            }
        } catch {
            setNoOptionsText('Something went wrong. Please try again.');
        } finally {
            setLoading(false);
        }
    }

    function handleSelection(city: string, placeId: string) {
        resetOptions();
        onChange?.(city, placeId);
    }

    return (
        <Container>
            <AutoComplete
                blurOnSelect
                onBlur={() => {
                    resetOptions();
                    if (selectInputOnBlur && props.inputAsSuggestion) {
                        if (!stagedVal.current) props.onChange(inputOption.description);
                    }
                    onBlur && onBlur();
                }}
                loading={loading}
                options={options ? [...(inputAsSuggestion ? [inputOption] : []), ...options] : options}
                disabled={disabled}
                getOptionLabel={option => option?.description || ''}
                getOptionSelected={(option, value) => option?.description === value?.description}
                noOptionsText={noOptionsText}
                InputProps={{
                    placeholder,
                    autoFocus,
                    ...(defaultValue ? { value: defaultValue } : {}),
                    ...(showSearchIcon
                        ? {
                              startAdornment: (
                                  <InputAdornment position="start">
                                      <SearchIcon />
                                  </InputAdornment>
                              ),
                          }
                        : {}),
                }}
                // needed for autoHighlight
                filterOptions={o => o}
                onInputChange={val => handleSuggestions(val)}
                defaultValue={{ description: defaultValue || '', placeId: defaultValueId || '' }}
                onChange={(e, val) => {
                    stagedVal.current = val;
                    handleSelection(val.description, val.placeId);
                }}
                renderOption={option => (
                    <Suggestion>
                        {!option.noIcon && <LocationPin />}
                        <SuggestionText>{option.description}</SuggestionText>
                    </Suggestion>
                )}
                asField={asFormField}
                inModalOrPopover={inModalOrPopover}
            />
        </Container>
    );
}
