import React from 'react';

import styled from 'styled-components';

import { emailIsValid } from 'utils';

import { Column, Row, Copy } from '../../ui';
import { searchCollaborators } from 'api/collaborators';

import AutoComplete, { AdornmentPill } from '../ui/AutoComplete';
import Avatar from '../ui/Avatar';

type CollaboratorSuggestion = BizlyAPI.EventCollaboratorSuggestion;
type NewCollaboratorSuggestion = PartialExcept<CollaboratorSuggestion, 'email'>;

const Suggestion = styled(Row)`
    align-items: center;

    & > *:not(:last-child) {
        margin-right: 13px;
    }
`;

const SuggestionName = styled(Copy)`
    overflow: hidden;
    text-overflow: ellipsis;

    font-size: 15px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
`;

const SuggestionEmail = styled(SuggestionName)`
    font-size: 13px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};
`;

const getUserName = ({ firstName = '', lastName = '' }: CollaboratorSuggestion | NewCollaboratorSuggestion) =>
    [firstName, lastName].join(' ').trim();
const getUserLabel = (user: CollaboratorSuggestion | NewCollaboratorSuggestion) => getUserName(user) || user.email;

type TSearchCollaborators = {
    existingCollaboratorEmails?: string[];
    onChange?: (collaborators: BizlyAPI.NewEventCollaborator[]) => void;
    eventId: number | string;
    allowNewEmails?: boolean;
};

const SearchCollaborators = ({
    existingCollaboratorEmails = [],
    onChange,
    eventId,
    allowNewEmails,
}: TSearchCollaborators) => {
    const [options, setOptions] = React.useState<(CollaboratorSuggestion | NewCollaboratorSuggestion)[]>();
    const [loading, setLoading] = React.useState(false);

    const existingEmailsSet = React.useMemo(() => new Set(existingCollaboratorEmails), [existingCollaboratorEmails]);

    const resetOptions = () => {
        setLoading(false);
        setOptions(undefined);
    };

    async function handleSuggestions(query: string) {
        if (!query) {
            resetOptions();
            return;
        }

        setLoading(true);
        const response = await searchCollaborators(query, eventId);
        setLoading(false);

        const queryIsEmail = emailIsValid(query);
        const notExisting = !response.suggestions.find(suggestion => suggestion.email === query);
        const queryIsNewEmail = queryIsEmail && notExisting && allowNewEmails;

        setOptions([...(queryIsNewEmail ? [{ email: query }] : []), ...response.suggestions]);
    }

    async function handleSelection(value: (CollaboratorSuggestion | NewCollaboratorSuggestion)[]) {
        resetOptions();
        if (onChange) {
            onChange(value.map(collaborator => ({ ...collaborator, editor: false })));
        }
    }

    return (
        <Column>
            <AutoComplete
                multiple
                renderTag={tag => ({
                    key: tag.email,
                    component: getUserLabel(tag),
                })}
                onBlur={resetOptions}
                loading={loading}
                options={options}
                getOptionLabel={getUserLabel}
                getOptionSelected={(option, value) => option?.email === value?.email}
                noOptionsText={
                    allowNewEmails
                        ? 'Please enter a valid email address.'
                        : "Don't see who you’re looking for? Ask your administrator to ensure they've been added to your Bizly team."
                }
                filterOptions={options => options.filter(o => !existingEmailsSet.has(o.email))}
                InputProps={{
                    placeholder: 'Invite by name or email',
                    endAdornment: <AdornmentPill>can view</AdornmentPill>,
                    autoFocus: true,
                }}
                onInputChange={handleSuggestions}
                onChange={(e, val) => handleSelection(val)}
                renderOption={option =>
                    option.userId ? (
                        <Suggestion>
                            <Avatar user={option} />
                            <Column>
                                {getUserName(option) && <SuggestionName>{getUserName(option)}</SuggestionName>}
                                <SuggestionEmail>{option.email}</SuggestionEmail>
                            </Column>
                        </Suggestion>
                    ) : (
                        <Suggestion>
                            <Column itemSpacing="small">
                                <SuggestionName>
                                    <b>{option.email}</b>
                                </SuggestionName>
                                <SuggestionEmail>
                                    By adding someone new to collaborate with, you’ll invite them to join your team.
                                    <br />
                                    They’ll just need to activate their account to join!
                                </SuggestionEmail>
                            </Column>
                        </Suggestion>
                    )
                }
            />
        </Column>
    );
};

export default SearchCollaborators;
