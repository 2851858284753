import React from 'react';
import styled from 'styled-components';

import usePersistentSnackbar from 'hooks/usePersistentSnackbar';

import { createPlaybookActions, useCreatePlaybook } from './store';

import { default as FormBase } from 'components/Form';
import Errors from './components/Errors';

const fields = {
    imageUrl: {
        type: 'image_uploader',
        prompt: 'Image',
        fixedWidth: 360,
    },
    name: {
        prompt: 'Playbook Name',
        type: 'text',
        options: {},
    },
    purpose: {
        prompt: 'Objective',
        type: 'textarea',
        options: {
            rows: 2,
            rowsMax: 4,
        },
    },
    description: {
        prompt: 'Description',
        type: 'textarea',
        options: {
            rows: 2,
            rowsMax: 4,
        },
    },
    agendaDescription: {
        prompt: 'How To Prepare',
        type: 'rich_text',
    },
};

const detailsSchema = {
    type: 'nested',
    schema: [
        {
            fields: ['name'],
            spacing: 'default',
        },
        {
            fields: ['purpose'],
            spacing: 'default',
        },
        {
            fields: ['description'],
            spacing: 'default',
        },
        {
            fields: ['agendaDescription'],
            spacing: false,
        },
    ],
};

const schema = [{ fields: ['imageUrl', detailsSchema], itemSpacing: 'larger', spacing: false }];

export type TBasicInfoValue = Partial<{
    imageUrl: string;
    name: string;
    purpose: string;
    description: string;
    agendaDescription: string;
}>;

const Form = styled(FormBase)`
    min-width: 800px;
`;

export default function BasicInfoForm() {
    const { basicInfo, basicInfoErrors, stepIdx } = useCreatePlaybook();

    const enqueueSnackbar = usePersistentSnackbar([basicInfoErrors, stepIdx]);
    React.useEffect(() => {
        const hasErrors = Object.values(basicInfoErrors).some(v => v);
        if (hasErrors) {
            enqueueSnackbar(<Errors errors={basicInfoErrors} />, { variant: 'error' });
        }
    }, [basicInfoErrors, enqueueSnackbar]);

    return (
        <Form
            fields={fields}
            schema={schema}
            value={basicInfo}
            onChange={({ value }: { value: TBasicInfoValue }) => {
                createPlaybookActions.setBasicForm(value);
                createPlaybookActions.setBasicFormErrors({});
            }}
        />
    );
}
