// Mostly derived from https://github.com/jacobworrel/react-counter-input/blob/master/src/CounterInput.js

import React, { ChangeEvent } from 'react';
import { css } from 'styled-components/macro';

import Input from '.';
import SmallCircleButton from '../SmallCircleButton';
import { ReactComponent as PlusIcon } from '../../../images/icons/plus.svg';
import { ReactComponent as MinusIcon } from '../../../images/icons/minus.svg';
import colorFns from 'colorFns';

function CounterInput({
    count = 0,
    min = -Infinity,
    max = Infinity,
    onCountChange,
    disabled = false,
    tertiary = false,
    className,
}: {
    count?: number;
    min?: number;
    max?: number;
    onCountChange: (newVal: number) => void;
    disabled?: boolean;
    tertiary?: boolean;
    className?: string;
}) {
    const [inputValue, setInputValue] = React.useState('');
    const setInputToCount = () => setInputValue(count.toString());

    React.useEffect(setInputToCount, [count]);

    function decrement() {
        if (count <= min) return;

        onCountChange(count - 1);
    }

    function increment() {
        if (count >= max) return;

        onCountChange(count + 1);
    }

    function handleChangeInput({ target: { value: inputValue } }: ChangeEvent<HTMLInputElement>) {
        const isValidInteger = (input: string) => /^-?[0-9]+$/.test(input);
        const isPartialInteger = (input: string) => input === '' || input === '-' || isValidInteger(input);

        if (isValidInteger(inputValue)) {
            let num = parseInt(inputValue);
            num = num > max ? max : num;
            num = num < min ? min : num;

            onCountChange(num);
            setInputValue(num.toString()); // if the erase and re-type "1"
        } else if (isPartialInteger(inputValue)) {
            setInputValue(inputValue);
        }
    }

    return (
        <div
            css={css`
                display: flex;
                align-items: center;

                & > *:not(:last-child) {
                    margin-right: 12px;
                }
            `}
            className={className}
        >
            {!disabled && (
                <SmallCircleButton tertiary={tertiary} onClick={decrement}>
                    <MinusIcon />
                </SmallCircleButton>
            )}
            <Input
                css={css`
                    padding: 0;
                    width: 36px;
                    height: 36px;

                    text-align: center;

                    background: ${colorFns.pureWhite.alpha(0.15)};
                    color: ${colorFns.pureWhite};
                    border: 0;
                `}
                disabled={disabled}
                type="text"
                value={inputValue}
                onBlur={setInputToCount}
                onChange={handleChangeInput}
            />
            {!disabled && (
                <SmallCircleButton tertiary={tertiary} onClick={increment}>
                    <PlusIcon />
                </SmallCircleButton>
            )}
        </div>
    );
}

export default CounterInput;
