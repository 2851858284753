import React from 'react';
import styled from 'styled-components';

import { Row } from 'ui';
import TextButton from 'components/ui/Button/TextButton';
import { Dialog } from '@material-ui/core';
import Form from 'components/Form';
import colorFns from 'colorFns';

const CardDialog = styled(Dialog)`
    .MuiPaper-root {
        max-width: 300px;

        background: ${colorFns.darkGrey};
        border: 0px solid ${colorFns.darkGrey};
        color: unset;

        padding: 24px;
    }
`;

const fields = {
    type: {
        type: 'radioselect',
        perRow: 1,
        options: {
            perRow: 1,
            options: [
                { id: 'current', name: 'This meeting' },
                { id: 'forward', name: 'This and following meetings' },
                { id: 'all', name: 'All meetings' },
            ],
            tertiary: true,
        },
    },
};

const schema = [{ header: 'Edit recurring meeting', fields: ['type'] }];

type TypeValue = 'current' | 'forward' | 'all';

export default function SeriesUpdatePrompt({
    onProceed,
    onCancel,
}: {
    onProceed: (type: TypeValue) => void;
    onCancel: () => void;
}) {
    const [type, setType] = React.useState<TypeValue>('current');

    return (
        <CardDialog open maxWidth="xs">
            <Form
                fields={fields}
                schema={schema}
                value={{ type }}
                onChange={({ field, value }: { field: string; value: { type: TypeValue } }) => setType(value.type)}
            />
            <Row justifyContent="flex-end" itemSpacing="smallish">
                <TextButton secondary onClick={() => onCancel()}>
                    Cancel
                </TextButton>
                <TextButton secondary onClick={() => onProceed(type)}>
                    OK
                </TextButton>
            </Row>
        </CardDialog>
    );
}
