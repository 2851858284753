import { get, post, put } from './';

export const getContacts = () => get(`contacts`) as Promise<{ contacts: BizlyAPI.Contact[] }>;

export const getContactGroups = () => get(`contact-groups`) as Promise<{ groups: BizlyAPI.ContactGroup[] }>;

export const createContactGroup = (group: Pick<BizlyAPI.ContactGroup, 'name'>) =>
    post(`contact-groups`, group) as Promise<{ group: BizlyAPI.ContactGroup }>;

export const updateContactGroup = (group: BizlyAPI.ContactGroup) =>
    put(`contact-groups/${group.id}`, group) as Promise<{ group: BizlyAPI.ContactGroup }>;
