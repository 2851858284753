import { DefaultTheme } from 'styled-components';

import { EColors, ETeams } from 'theme';

type EColorsKeys = keyof typeof EColors;

type Alpha<Return> = (opacity: number) => Return;
type SolidHex<Return> = () => Return;
type ThemeOverride<Return> = (themeOverride: ETeams) => Return;

type StyleFnBase = {
    (props: { theme: DefaultTheme }): Themed.Color;
};

type ColorFn<TKeys = 'alpha' | 'asSolidHex' | 'themeOverride'> = StyleFnBase &
    ('alpha' extends TKeys
        ? {
              alpha: Alpha<ColorFn<Exclude<TKeys, 'alpha'>>>;
          }
        : {}) &
    ('asSolidHex' extends TKeys
        ? {
              asSolidHex: SolidHex<ColorFn<Exclude<TKeys, 'asSolidHex'>>>;
          }
        : {}) &
    ('themeOverride' extends TKeys
        ? {
              themeOverride: ThemeOverride<ColorFn<Exclude<TKeys, 'themeOverride'>>>;
          }
        : {});

export const makeColorFn = (
    color: EColorsKeys,
    options: Partial<{ alpha: number; themeOverride: ETeams; asSolidHex: boolean }> = {}
) => {
    const { alpha, themeOverride, asSolidHex } = options;
    const colorFn = ({ theme: { getColor } }: { theme: DefaultTheme }) =>
        getColor(EColors[color], alpha, { themeOverride, asSolidHex });

    if (alpha === undefined) {
        colorFn.alpha = (opacity: number) => makeColorFn(color, { ...options, alpha: opacity });
    }
    if (asSolidHex === undefined) {
        colorFn.asSolidHex = () => makeColorFn(color, { ...options, asSolidHex: true });
    }
    if (themeOverride === undefined) {
        colorFn.themeOverride = (override: ETeams) => makeColorFn(color, { ...options, themeOverride: override });
    }

    return colorFn;
};

type ColorFnsDict = Record<EColorsKeys, ColorFn>;

const colorFns = (Object.keys(EColors) as EColorsKeys[]).reduce<Partial<ColorFnsDict>>(
    (dict, colorRef: EColorsKeys) => {
        Object.defineProperty(dict, colorRef, {
            get: () => makeColorFn(colorRef),
        });

        return dict;
    },
    {}
) as ColorFnsDict;

export default colorFns;
