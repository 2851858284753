import React, { useMemo } from 'react';
import styled from 'styled-components';

import { ACCOMMODATIONS } from 'components/Planner/utils';
import { ReactComponent as TrashIcon } from 'images/icons/trash-can.svg';
import { withInteractibleIconStyles } from 'shared';
import { Column as UIColumn, Row, Copy } from 'ui';
import Button from 'components/ui/Button';
import LongButton from 'components/ui/Button/LongButton';
import TextButton from 'components/ui/Button/TextButton';
import Form from 'components/Form';
import { TAccommodation, TPlannerDataChange, TPlannerSections } from 'components/Planner/types';
import { defaultAccommodationsFields, accommodationsSchema } from './plannerFormSchema';

const Column = styled(UIColumn)`
    width: 100%;
`;

const Content = styled(UIColumn)`
    border-radius: 8px;
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    overflow: hidden;
`;

const Head = styled(Row)`
    border-bottom: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    padding: 16px 0 15px 0;
    justify-content: space-between;
    align-items: center;
    div {
        margin: 0 16px;
    }
`;

const Body = styled(UIColumn)`
    padding: 16px 16px 16px;
`;

const ClearTextButton = styled(TextButton)`
    align-self: flex-end;
    margin: 0 16px 16px 0;
    font-weight: 500;
`;

const SaveButton = styled(Button)`
    align-self: flex-end;
    margin-top: 24px;
    font-weight: 400;
`;

const AddAccommodationCTA = styled(Copy)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack)};
    font-size: 16px;
    font-weight: 500;
`;

const RemoveAccommodationButton = styled(withInteractibleIconStyles(TrashIcon))`
    align-self: center;
    margin-right: 8px;
`;

type TAccommodationUpdate = TPlannerDataChange & { value: TAccommodation };

type TAccommodationsDetailsProps = {
    editable: boolean;
    accommodations: TAccommodation[];
    addAccommodation: (targetSection: TPlannerSections) => void;
    removeAccommodation: (idx: number, targetSection: TPlannerSections) => void;
    setAccommodationsData: (
        accommodationFormData: TAccommodationUpdate,
        idx: number,
        targetSection: TPlannerSections
    ) => void;
    clearAccommodationsData: (targetIdx: number) => void;
    hasSaved: boolean;
    onSave: () => void;
};

const AccommodationsDetails = ({
    editable,
    accommodations,
    addAccommodation,
    removeAccommodation,
    setAccommodationsData,
    clearAccommodationsData,
    hasSaved,
    onSave,
}: TAccommodationsDetailsProps) => {
    const accommodationsFields = useMemo(() => {
        const existingDates = accommodations.map(accommodation => accommodation.date);
        return defaultAccommodationsFields({ datesToDisable: existingDates });
    }, [accommodations]);
    return (
        <Column itemSpacing="small">
            {!!accommodations.length &&
                accommodations
                    .map((accommodation, originIndex) => ({ ...accommodation, originIndex }))
                    .filter(accommodation => !accommodation.delete)
                    .map((accommodation, idx: number) => (
                        <div key={accommodation.id || `accommodation-${idx}`}>
                            <Content>
                                <Head>
                                    <Copy>Guest Rooms {idx + 1}</Copy>
                                    {editable && (
                                        <RemoveAccommodationButton
                                            onClick={() =>
                                                removeAccommodation(accommodation.originIndex, ACCOMMODATIONS)
                                            }
                                        />
                                    )}
                                </Head>
                                <Body>
                                    <Form
                                        fields={accommodationsFields}
                                        schema={accommodationsSchema}
                                        value={accommodation}
                                        onChange={(e: TAccommodationUpdate) =>
                                            setAccommodationsData(e, accommodation.originIndex, ACCOMMODATIONS)
                                        }
                                        disabled={!editable}
                                    />
                                </Body>
                                {editable && (
                                    <ClearTextButton onClick={() => clearAccommodationsData(accommodation.originIndex)}>
                                        Clear
                                    </ClearTextButton>
                                )}
                            </Content>
                        </div>
                    ))}

            {editable && (
                <LongButton onClick={() => addAccommodation(ACCOMMODATIONS)} isSecondary>
                    <AddAccommodationCTA>Add Guest Rooms</AddAccommodationCTA>
                </LongButton>
            )}

            {editable && (
                <SaveButton onClick={onSave} disabled={!editable || hasSaved} small width={68}>
                    Save
                </SaveButton>
            )}
        </Column>
    );
};

export default AccommodationsDetails;
