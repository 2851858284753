export const fields = {
    expiryDate: {
        type: 'date_outlined',
        prompt: 'Expiration Date',
        perRow: '3',
        options: {
            format: 'MMM d, yyyy',
            parseFormat: 'yyyy-MM-dd',
        },
    },
    additionalDetails: {
        type: 'textarea',
        prompt: 'Additional Details',
        perRow: '2/3',
        options: {
            maxLength: 275,
        },
    },
    attachments: {
        type: 'upload_list',
        perRow: '3',
        options: {
            prompt: 'Add Attachment',
        },
    },
};

export const schema = [
    { key: 'expiryDate', fields: ['expiryDate'] },
    { key: 'additionalDetails', fields: ['additionalDetails'], spacing: 'default' },
    { key: 'attachments', fields: ['attachments'] },
];
