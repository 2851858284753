import React from 'react';
import styled from 'styled-components';

import { colorStyledFn, EColors } from 'theme';
import { statusColors } from 'shared';
import { capitalize } from '../../util';

import { Column, SpacedRow } from 'ui';

const Digit = styled.span`
    font-size: 51px;
    line-height: 51px;
    margin-top: -10px;
`;

const TallyColumn = styled(Column)`
    ${colorStyledFn}
`;

type TTally = { label: keyof typeof statusColors; tally?: number };

const Tally = ({ label, tally = 0 }: TTally) => (
    <TallyColumn $color={statusColors[label] || EColors.pureBlack}>
        <Digit>{tally}</Digit>
        <span>{capitalize(label)}</span>
    </TallyColumn>
);

const TallyDisplay = ({ tallies = [], ...props }: { tallies?: TTally[]; style?: React.CSSProperties }) => (
    <SpacedRow {...props}>
        {tallies.map(({ label, tally }) => (
            <Tally label={label} tally={tally} />
        ))}
    </SpacedRow>
);

export default TallyDisplay;
