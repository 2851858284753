import React from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation } from 'react-router';

import moment from 'moment';
import { tzMoment } from 'utils/moment';
import partition from 'lodash/partition';
import keyBy from 'lodash/keyBy';
import uniqBy from 'lodash/uniqBy';
import { is12AM } from 'utils/date_util';
import ColorHash from 'color-hash';
import { parse, stringify } from 'query-string';
import { takeFirstIfArray } from 'utils';
import { loadHiddenUserIds, saveHiddenUserIds } from './utils';

import { useUser } from 'providers/user';
import {
    useMeetings,
    selectMeetings,
    isLoading as isLoadingMeetings,
    isLoadingLimit as isLoadingMeetingsLimit,
    LoadMeetings,
    meetingsActions,
} from 'stores/meetings';
import {
    LoadCalendar,
    isLoading as isLoadingCalendar,
    selectMeta,
    selectUsers,
    selectBlocks,
    useCalendar,
    ItemWithUId,
    isBizly,
} from 'stores/calendar';
import { LoadTeamMembers, useTeam } from 'stores/team-members';
import { createMeetingActions } from 'pages/CreateMeeting/store';

import { Row, Spacer, Column } from 'ui';
import { H5Headline } from 'components/ui/Headline';
import { SpinnerOverlay } from 'components/Spinner';
import { Tab, Tabs } from 'components/Tabs';
import Button from 'components/ui/Button';
import ScheduledList from './ScheduledList';
import UnschedList from './UnschedList';
import BigCalendar from 'components/BigCalendar';
import TeamMembersList from './Components/TeamMembersList';
import NavBar from './Components/NavBar';

import { ReactComponent as PlusSVG } from 'images/icons/plus.svg';
import { mapValues } from 'lodash';

const colorHash = new ColorHash({ lightness: 0.5 });
const CAL_MIN = moment()
    .startOf('day')
    .set('h', 6)
    .toDate();
const CAL_MAX = moment()
    .startOf('day')
    .set('h', 21)
    .add(-1, 'ms')
    .toDate();

const TABS_CONTAINER_STYLES = {
    width: 'auto',
    display: 'inline-block',
    margin: '0 -10px',
};

const TAB_STYLES = {
    padding: 0,
    margin: '0 10px',
};

const ContentCol = styled(Column)`
    position: relative;
    width: 100%;
    flex: 1 0 0;
`;

const PlusIcon = styled(PlusSVG)``;
const CreateButton = styled(Button)`
    height: 45px;
    padding-left: 10px;
    border-radius: 8px;

    ${PlusIcon} {
        display: inline-flex;
        white-space: nowrap;
        padding: 0;
        width: 16px;
        height: 16px;

        background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};

        border-radius: 50%;
        min-width: 0;
        align-items: center;
        justify-content: center;

        margin-right: 10px;
    }
`;

const CalendarRow = styled(Row)`
    flex: 1 0 0;
    min-height: 0;

    margin-left: -56px;
    width: calc(100% + 62px);
`;

const APIToCalBlock = <
    TEvent extends {
        startsAt: string;
        endsAt: string;
        name?: string;
        timeZone?: string;
        origin?: string | null;
        uId?: string;
    }
>(
    event: TEvent
) => {
    const start = tzMoment(event.startsAt, event.timeZone);
    const end = tzMoment(event.endsAt, event.timeZone);

    const diffDays = end.diff(start, 'day');
    const sameDayEndsAt12 = diffDays === 1 && is12AM(end.toDate());

    return {
        ...event,
        name: event.name || undefined,
        start: start.toDate(),
        end: end.toDate(),
        multiDay: diffDays > 0 && !sameDayEndsAt12,
        published: true,
        source: isBizly(event) ? 'internal' : 'external',
        uId: event.uId,
    };
};

const TabsRoute = ({
    unscheduled,
    asPublished,
    goToScheduled,
    goToUnscheduled,
}: {
    unscheduled?: boolean;
    asPublished?: boolean;
    goToScheduled: () => void;
    goToUnscheduled: () => void;
}) => (
    <Tabs value={Number(!!unscheduled)} style={TABS_CONTAINER_STYLES}>
        <Tab label={asPublished ? 'Meetings' : 'Scheduled'} value={0} onClick={goToScheduled} style={TAB_STYLES} />
        <Tab label={asPublished ? 'Drafts' : 'Unscheduled'} value={1} onClick={goToUnscheduled} style={TAB_STYLES} />
    </Tabs>
);

const involvesUser = (calItem: ItemWithUId, user: Bizly.User) =>
    calItem.createdBy?.email === user.email || (user.id && (calItem.attendingUserIds ?? []).includes(user.id));

export default function Meetings({ unscheduled, calendar }: { unscheduled?: boolean; calendar?: boolean }) {
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useUser();

    const history = useHistory();
    const goToScheduled = () => history.push(`/meetings`);
    const goToUnscheduled = () => history.push(`/meetings/drafts`);

    const viewEvent = async (event: {
        id: string | number;
        published?: boolean;
        createdBy?: BizlyAPI.CreatedBy;
        calId?: number;
        uId?: string;
        immutableId?: string | null;
        origin?: string | null;
    }) => {
        if (isBizly(event)) {
            return history.push(`/meetings/${event.id}${!event.published ? '/edit' : ''}`);
        }

        if (event.calId !== undefined || event.uId !== undefined) {
            if (event.createdBy?.email !== user.email) {
                return history.push(`/meetings/${event.immutableId}`);
            }

            try {
                const meeting = await meetingsActions.subsume(
                    event.calId ?? event.id,
                    event.uId ?? event.id,
                    startOfWeek
                );
                if (meeting) return history.push(`/meetings/${meeting.id}`);
                return;
            } catch (e) {
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            }
        }
    };

    const location = useLocation();
    const { date: urlDate } = takeFirstIfArray(parse(location.search));
    const date = React.useMemo(
        () =>
            moment(urlDate)
                .startOf('day')
                .set('h', 8)
                .toDate(),
        [urlDate]
    );
    const setDate = React.useCallback(
        (date: Date) => history.replace({ ...history.location, search: stringify({ date: date.toISOString() }) }),
        [history]
    );
    const view = calendar ? 'cal' : 'list';
    const [day, setDay] = React.useState(false);

    const startOfWeekStr = React.useMemo(
        () =>
            moment(date)
                .startOf('week')
                .toDate()
                .toISOString(),
        [date]
    );
    const startOfWeekStrPrev = React.useMemo(
        () =>
            moment(date)
                .startOf('week')
                .add(-1, 'weeks')
                .toDate()
                .toISOString(),
        [date]
    );
    const startOfWeekStrNext = React.useMemo(
        () =>
            moment(date)
                .startOf('week')
                .add(1, 'weeks')
                .toDate()
                .toISOString(),
        [date]
    );
    const startOfWeek = React.useMemo(() => moment(startOfWeekStr).toDate(), [startOfWeekStr]);

    const { meetings: calMeetings } = useCalendar();
    const calItemsWeek = useCalendar(selectBlocks(startOfWeek));

    const ofWeek = useMeetings(selectMeetings(startOfWeek));
    const isLoadingWeekList = useMeetings(isLoadingMeetings(startOfWeek));
    const isLoadingWeekCal = useCalendar(isLoadingCalendar(startOfWeek)) || isLoadingWeekList;
    const shortUnsched = useMeetings(selectMeetings(5));
    const unsched = useMeetings(selectMeetings(1000));
    const loadingUnscheduled = useMeetings(isLoadingMeetingsLimit(1000));

    const { subsuming } = useMeetings();

    const weekUsers = useCalendar(selectUsers(startOfWeek));
    const weekUsersSet = React.useMemo(() => new Set(weekUsers), [weekUsers]);
    const [prevSet, setPrevSet] = React.useState(new Set<string>());
    React.useEffect(() => {
        if (weekUsersSet.size > 0) setPrevSet(weekUsersSet);
    }, [weekUsersSet]);

    const usersSet = React.useMemo(() => (isLoadingWeekCal ? prevSet : weekUsersSet), [
        isLoadingWeekCal,
        prevSet,
        weekUsersSet,
    ]);

    const { members } = useTeam();
    const activeMembers = React.useMemo(() => (members ?? []).filter(member => member.status.string === 'Active'), [
        members,
    ]);
    const idToUser = React.useMemo(
        () => keyBy(activeMembers, member => member.user.id) as Partial<Record<number, BizlyAPI.TeamMember>>,
        [activeMembers]
    );

    const [onCal, notOnCal] = partition(
        (activeMembers || []).sort((mA, mB) =>
            mA.user.id === user?.id ? -1 : mB.user.id === user?.id ? 1 : mA.user.id - mB.user.id
        ),
        member => usersSet?.has(member.user.email)
    );

    const [hidden, setHidden] = React.useState<Set<number>>(new Set(loadHiddenUserIds()));
    const shown = React.useMemo(
        () => new Set((activeMembers || []).filter(m => !hidden.has(m.user.id)).map(m => m.user.id)),
        [activeMembers, hidden]
    );
    React.useEffect(() => {
        saveHiddenUserIds([...hidden]);
    }, [hidden]);
    const add = (id: number) => setHidden(curHidden => new Set([...curHidden, id]));
    const del = (id: number) => setHidden(curHidden => new Set([...curHidden].filter(curId => curId !== id)));
    const toggle = (id: number) => (hidden.has(id) ? del(id) : add(id));

    const calItems = React.useMemo(
        () =>
            Object.values(calItemsWeek).filter(
                item => !isBizly(item) && item.startsAt && item.duration > 0 && involvesUser(item, user)
            ),
        [calItemsWeek, user]
    );

    const asList = React.useMemo(
        () =>
            Object.values(calMeetings)
                .filter(m => m.startsAt && m.endsAt && m.duration > 0)
                .filter(m => m.attendingUserIds?.some(id => shown.has(id)) ?? true)
                .map(m => {
                    const ids = m.attendingUserIds
                        ?.sort((idA, idB) => (idA === user?.id ? -1 : idB === user?.id ? 1 : idA - idB))
                        .filter(id => shown.has(id));

                    const simpleColors = ids
                        ?.map(id => {
                            const user = idToUser[id]?.user;
                            const { firstName, lastName, email } = user || {};
                            return email
                                ? {
                                      color: colorHash.hex(email) as Themed.Color,
                                      label: [firstName, lastName].join(' ').trim() || email,
                                  }
                                : undefined;
                        })
                        .filter(s => s) as { color: Themed.Color; label?: string }[];

                    return {
                        ...APIToCalBlock(m),
                        id: m.uId,
                        calId: m.id,
                        simpleColors: !isBizly(m) ? simpleColors : undefined,
                    };
                }),
        [calMeetings, user, idToUser, shown]
    );

    const publishedWeek = React.useMemo(
        () =>
            (ofWeek.filter(m => m.published && m.startsAt !== m.endsAt) as (BizlyAPI.Meeting & {
                startsAt: string;
                endsAt: string;
                name?: string;
            })[]).map(m => ({ ...APIToCalBlock(m), id: m.id.toString() })),
        [ofWeek]
    );

    const eventsList = React.useMemo(() => uniqBy([...asList, ...publishedWeek], e => e.id), [asList, publishedWeek]);

    const meta = useCalendar(selectMeta(startOfWeek));

    const viewableIds = new Set([
        ...ofWeek.map(meeting => meeting.id.toString()),
        ...calItems.map(meeting => meeting.uId),
    ]);

    const handleCreateMeetingButtonClick = async () => {
        return history.push(`/meetings/new`);
    };

    const goToCreateMeeting = ({ start, end }: { start: string | Date; end: string | Date }) => {
        history.push(`/meetings/new`);
        createMeetingActions.mergeBasicForm({
            startDate: moment(start)
                .startOf('day')
                .toDate(),
            endDate: moment(end)
                .startOf('day')
                .toDate(),
            startTime: moment(start).format('HH:mm:ss'),
            endTime: moment(end).format('HH:mm:ss'),
        });
        createMeetingActions.addAttendees(
            (members || [])
                .filter(m => m.user.id !== user?.id)
                .filter(m => shown.has(m.user.id))
                .map(m => ({
                    email: m.user.email,
                }))
        );
        createMeetingActions.setChanged(false);
        createMeetingActions.setSaved(true);
    };

    const quickGoToCreateMeeting = async (date: moment.Moment) => {
        return goToCreateMeeting({
            start: date
                .clone()
                .set({ h: 12, m: 0, s: 0, ms: 0 })
                .toDate(),
            end: date
                .clone()
                .set({ h: 12, m: 30, s: 0, ms: 0 })
                .toDate(),
        });
    };

    const { users, meetings: allMeetings } = useCalendar();
    const meetingIdToUsers = mapValues(allMeetings, meeting =>
        (meeting.attendingUserIds || []).map(id => users[id]).filter(u => u)
    );

    const Header = () => (
        <>
            <H5Headline>All Meetings</H5Headline>
            <Spacer small />
            <CreateButton width="auto" onClick={handleCreateMeetingButtonClick}>
                <Row alignItems="center">
                    <PlusIcon />
                    Create New Meeting
                </Row>
            </CreateButton>
            <Spacer />
        </>
    );

    const Overlays = () => <>{subsuming && <SpinnerOverlay fixed />}</>;

    return (
        <>
            <Header />
            <TabsRoute
                unscheduled={unscheduled}
                asPublished
                goToScheduled={goToScheduled}
                goToUnscheduled={goToUnscheduled}
            />
            {!unscheduled && (
                <NavBar
                    calendar={calendar}
                    day={day}
                    setDay={setDay}
                    date={date}
                    setDate={setDate}
                    isLoadingCal={isLoadingWeekCal}
                    isLoadingList={isLoadingWeekCal}
                />
            )}
            {unscheduled && <Spacer larger />}
            <ContentCol>
                {unscheduled ? (
                    <LoadMeetings limit={1000} />
                ) : (
                    <>
                        <LoadMeetings key={startOfWeekStrPrev} date={startOfWeekStrPrev} />
                        <LoadMeetings key={startOfWeekStr} date={startOfWeekStr} />
                        <LoadMeetings key={startOfWeekStrNext} date={startOfWeekStrNext} />
                        <LoadCalendar key={startOfWeekStrPrev} date={startOfWeekStrPrev} />
                        <LoadCalendar key={startOfWeekStr} date={startOfWeekStr} />
                        <LoadCalendar key={startOfWeekStrNext} date={startOfWeekStrNext} />
                    </>
                )}
                {view === 'cal' && (
                    <CalendarRow itemSpacing="small">
                        {user.team && <LoadTeamMembers teamId={user.team.id} />}
                        <BigCalendar
                            events={eventsList}
                            view={day ? 'day' : 'week'}
                            date={date}
                            onNavigate={setDate}
                            onView={view => view === 'day' && setDay(true)}
                            lockView
                            noToolbar
                            onSelectEvent={e => {
                                if (viewableIds.has(e.id)) viewEvent(e);
                            }}
                            deferFocus={isLoadingWeekCal}
                            showMultiDayTimes
                            clickableIds={viewableIds}
                            selectable
                            onSelectSlot={goToCreateMeeting}
                            min={CAL_MIN}
                            max={CAL_MAX}
                        />
                        <TeamMembersList
                            colorHash={colorHash}
                            peopleHours={meta?.totalPeopleHours}
                            active={onCal}
                            inactive={notOnCal}
                            isChecked={id => shown.has(id)}
                            onToggle={toggle}
                            onSelectAll={() => setHidden(new Set())}
                            onClear={() => setHidden(new Set((members || []).map(member => member.user.id)))}
                        />
                    </CalendarRow>
                )}
                {view === 'list' ? (
                    unscheduled ? (
                        <UnschedList
                            meetings={loadingUnscheduled && unsched.length === 0 ? shortUnsched : unsched}
                            loading={loadingUnscheduled && shortUnsched.length === 0}
                            onClick={viewEvent}
                            onDelete={meetingsActions.delete}
                        />
                    ) : (
                        <ScheduledList
                            meetings={ofWeek}
                            calItems={calItems}
                            meetingIdToUsers={meetingIdToUsers}
                            onClick={meeting => viewEvent({ ...meeting, published: true })}
                            onQuickAdd={quickGoToCreateMeeting}
                            handleCreateMeetingButtonClick={handleCreateMeetingButtonClick}
                            loading={isLoadingWeekCal}
                            noLoadingOverlay
                        />
                    )
                ) : null}
            </ContentCol>

            <Overlays />
        </>
    );
}
