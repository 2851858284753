import React from 'react';

import { alertsActions } from './store';
import throttle from 'lodash/throttle';

export default function PollAlerts() {
    const [active, setActive] = React.useState(true);
    const poller = React.useRef<number | null>(null);
    const activityWatch = React.useRef<number | null>(null);

    const errors = React.useRef<number>(0);

    React.useEffect(() => {
        const pull = async () => {
            // don't if there's been 2 consecutive errors
            if (errors.current >= 2) return;

            try {
                await alertsActions.pullNewer();
                errors.current = 0;
            } catch (e) {
                errors.current = errors.current + 1;
            }
        };

        if (active) {
            pull();
            poller.current = setInterval(pull, 5000);
        }

        return () => {
            if (active) {
                if (poller.current) clearInterval(poller.current);
                poller.current = null;
            }
        };
    }, [active, errors]);

    // don't create a legion of DDOS zombies
    React.useEffect(() => {
        const refreshInactiveTimeout = () => {
            if (activityWatch.current) {
                clearTimeout(activityWatch.current);
                activityWatch.current = null;
            }

            activityWatch.current = setTimeout(() => {
                setActive(false);
            }, 60000);
        };

        const activityWatchHandler = throttle(() => {
            setActive(true);

            refreshInactiveTimeout();
        }, 300);

        refreshInactiveTimeout();
        document.body.addEventListener('mousemove', activityWatchHandler);

        return () => document.body.removeEventListener('mousemove', activityWatchHandler);
    }, []);

    return null;
}
