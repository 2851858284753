import React from 'react';
import styled from 'styled-components/macro';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import { ReactComponent as UIArrowIcon } from '../../../images/icons/down-arrow.svg';
import { withInteractibleIconStyles } from 'shared';

const Panel = styled(ExpansionPanel)`
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
    border-radius: 8px;
    box-shadow: none;
    overflow: hidden;

    &::before {
        display: none;
    }

    &:last-child {
        border-radius: 8px;
    }
`;

const PanelSummary = styled(ExpansionPanelSummary)``;

const PanelDetails = styled(ExpansionPanelDetails)`
    border-top: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.softAccentedBackground)};

    padding: 24px;
`;

const ArrowIcon = styled(withInteractibleIconStyles(UIArrowIcon))`
    height: 15px;
    width: 15px;
`;

type TAccordion = {
    identity: string;
    classNames?: string;
    expanded?: boolean;
    summaryContent?: any;
    detailContent?: any;
    handleChange: (identity: string) => void;
};

const Accordion = ({ identity, classNames, expanded, summaryContent, detailContent, handleChange }: TAccordion) => {
    return (
        <Panel className={`accordion ${classNames}`} expanded={expanded} onChange={() => handleChange(identity)}>
            <PanelSummary
                aria-label={`${identity}-expand`}
                aria-controls={`${identity}-content`}
                id={`${identity}-header`}
                expandIcon={<ArrowIcon />}
            >
                {summaryContent}
            </PanelSummary>
            <PanelDetails>{detailContent}</PanelDetails>
        </Panel>
    );
};

export default Accordion;
