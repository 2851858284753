import React, { useMemo } from 'react';
import styled, { css } from 'styled-components/macro';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Divider from '@material-ui/core/Divider';
import { parseISO } from 'date-fns';

import isEmpty from 'lodash/isEmpty';

import { contentWidth } from '../shared';
import colorFns from 'colorFns';
import fontFns from 'fontFns';
import { BizlyThemeOverride } from 'ThemedApp';
import { getDateTimeStrings } from 'utils/date_util';

import {
    createParcel,
    copyParcel,
    loadAttendees,
    getPlannerData,
    loadParcel,
    updateParcel,
    sendParcel,
    loadQuestions,
    deleteQuestion,
} from '../api';

import { Button, Column, Copy, PromptedTextArea, PromptedTextField, Row, Spacer, TextField, Prompted } from '../ui';
import { LargeHeadline, Headline, H5Headline as UIH5Headline } from 'components/ui/Headline';

import { OptionList } from '../components/OptionList';
import { Spinner, SpinnerOverlay } from '../components/Spinner';
import { StickyHeader, stickyHeaderHeight } from '../components/StickyHeader';
import { Questions } from '../components/Questions';
import { Uploader } from '../components/Uploader';
import EditParcelPreview from './EditParcelPreview';
import { defaultEditParcelFields, editParcelSchemaWithTz } from 'components/Communication/editParcelSchema';
import SurveySection from 'components/Communication/SurveySection';

import { SelectField } from 'components/FormFields';
import { FieldHeader } from 'components/Form';
import { RichTextEditor } from 'components/Form/fields';
import Form from 'components/Form';
import { SIDE_NAV_WIDTH } from 'components/SideNav';

import { capitalize } from '../util';

import { useUnsavedPrompt } from 'components/ProposalForm/utils';
import { useUser } from 'providers/user';

const H5Headline = styled(UIH5Headline)`
    line-height: 1.8em;
`;

const Subheader = styled(Copy)`
    line-height: 1.5em;
    font-size: 18px;
`;

const Centered = styled(Column)`
    width: ${contentWidth};
    margin: ${stickyHeaderHeight} auto;
    padding: 36px 0;
`;

const FormLabel = styled(Copy)`
    color: ${colorFns.formLabel};
    ${fontFns.formLabel}
`;

const initialParcelState = (
    type: 'invite' | 'note',
    recipients: number[],
    event: Bizly.Event,
    vmServiceProvider?: Bizly.VirtualMeetingServiceProvider
) => {
    return {
        name: '',
        recipients,
        subject: '',
        content: '',
        locationName: event.bookedVenue?.name,
        locationAddress: event.bookedVenue?.address,
        locationCityState: event.bookedVenue?.cityState,
        traits: type === 'invite' ? { rsvp: [] } : [],
        ...(vmServiceProvider
            ? {
                  vmSharingMethod: 'share' as 'share' | 'dont_share',
              }
            : {}),
    };
};

const isSendable = (parcel: Bizly.Parcel, parcelType: 'invite' | 'note', useParcelDates?: boolean) => {
    const { subject, content, recipients, startDate, startTime, endDate, endTime } = parcel;
    const validDates = parcelType === 'invite' && useParcelDates ? startDate && startTime && endDate && endTime : true;
    return (
        subject &&
        content &&
        recipients &&
        subject.length > 0 &&
        content.length > 0 &&
        recipients.length > 0 &&
        validDates
    );
};

const vmSharingOptions = [
    { id: 'share', name: 'Share Virtual Meeting details' },
    { id: 'dont_share', name: 'Do not share Virtual Meeting details' },
] as const;

type EditParcelState = {
    customImage: unknown;
    pending: boolean;
    parcel: Bizly.Parcel;
    recipients: number[];
    showPreview: boolean;
    attendees: BizlyAPI.Attendee[];
    event: unknown;
    createdParcelId: number | null;
    plannerSchedule: BizlyAPI.ScheduleBlock[];
};

const CreateParcel = ({
    eventId,
    parcel,
    type,
}: {
    eventId: number | string;
    parcel: Bizly.Parcel;
    type: 'invite' | 'note';
}) => {
    const { enqueueSnackbar } = useSnackbar();

    const [newParcelId, setNewParcelId] = React.useState<number>();
    const [hasError, setHasError] = React.useState(false);

    React.useEffect(() => {
        const createNewParcel = async () => {
            try {
                const newParcelRes = await createParcel(eventId, parcel);
                setNewParcelId(newParcelRes.parcel.id);
                enqueueSnackbar('Draft created.', { variant: 'success' });
            } catch (e) {
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
                setHasError(true);
            }
        };

        createNewParcel();
    }, [eventId, parcel, enqueueSnackbar]);

    if (hasError) {
        return <Redirect to={`/event/${eventId}/communication`} />;
    }

    return newParcelId === undefined ? (
        <SpinnerOverlay />
    ) : (
        <Redirect to={`/event/${eventId}/communication/edit/${type}/${newParcelId}`} />
    );
};

export default function EditParcel(props: {
    event: Bizly.Event;
    updateAttendeesCount: (attendeesCounts: Bizly.AttendeeCounts) => void;
}) {
    const { user, toggles } = useUser();
    const history = useHistory();
    const { type: typeParam, parcelId: parcelIdParam } = useParams();
    const { event } = props;

    const type = typeParam as 'invite' | 'note';
    const parcelId = parcelIdParam ? Number(parcelIdParam) : undefined;

    const parcelIdRef = React.useRef();

    const { enqueueSnackbar } = useSnackbar();

    const initialLoad = React.useRef(false);

    const [state, setState] = React.useState<EditParcelState>({
        customImage: null,
        pending: false,
        parcel: {},
        recipients: [],
        showPreview: false,
        attendees: [],
        event: null,
        createdParcelId: null,
        plannerSchedule: [],
    });

    const [hasQuestions, setHasQuestions] = React.useState<boolean>(false);

    const virtualMeetingServiceProvider = React.useMemo(
        () =>
            user.team?.virtualMeetingServiceProviders?.find(
                serviceProvider => serviceProvider.id === event.virtualMeeting?.serviceProvider?.id
            ),
        [user.team, event.virtualMeeting]
    );

    React.useEffect(() => {
        initialLoad.current = false;
    }, [parcelId]);

    function preLoadFormatting(parcel: Bizly.Parcel, event: Bizly.Event) {
        const timeZone = parcel.timeZone || event.timeZone;
        const { date: eventStartDate, time: eventStartTime } = getDateTimeStrings(event.startsAt, timeZone);
        const { date: eventEndDate, time: eventEndTime } = getDateTimeStrings(event.endsAt, timeZone);

        let parcelCopy = {
            ...parcel,
            startDate: parcel.startDate || eventStartDate,
            startTime: parcel.startTime || eventStartTime,
            endDate: parcel.endDate || eventEndDate,
            endTime: parcel.endTime || eventEndTime,
        };

        parcelCopy.vmSharingMethod = event.virtualMeeting ? parcelCopy.vmSharingMethod || 'dont_share' : null;

        return parcelCopy;
    }

    React.useEffect(() => {
        async function load() {
            const { event } = props;
            const eventId = event.id;

            initialLoad.current = true;

            setState({
                ...state,
                pending: true,
            });

            let parcel: Bizly.Parcel | null = null,
                attendees,
                recipients: number[] = [],
                plannerSchedule: BizlyAPI.ScheduleBlock[] = [];

            try {
                attendees = await loadAttendees(eventId);
                plannerSchedule = (await getPlannerData(eventId)).planner.schedule || [];
                if (parcelId) {
                    const loadedParcel = await loadParcel(eventId, parcelId);
                    parcel = preLoadFormatting(loadedParcel, event);
                    recipients = parcel.recipients || recipients;
                } else {
                    recipients = attendees.map(({ id }: { id: number }) => id);
                    parcel = initialParcelState(type, recipients, event, virtualMeetingServiceProvider);
                }
            } catch (e) {
                console.error(`Could not fetch parcel ${parcelId} for meeting ${eventId}`);
            }

            setState({
                ...state,
                ...(parcel ? { parcel } : {}),
                attendees: attendees || [],
                recipients,
                plannerSchedule,
                pending: false,
            });
        }

        if (!initialLoad.current) {
            load();
        }
    }, [props, state, virtualMeetingServiceProvider, type, parcelId]);

    const [unsavedForm, setUnsavedForm] = React.useState(false);

    const unsavedPrompt = useUnsavedPrompt(unsavedForm);

    function handleChange(value: any, key: string) {
        setUnsavedForm(true);
        setState((state: EditParcelState) => {
            const { parcel, createdParcelId } = state;

            let newParcel = {
                ...parcel,
                id: parcel.id || createdParcelId,
                [key]: value,
            };

            return { ...state, parcel: newParcel };
        });
    }

    function preSaveFormat(
        { preparationNotes, serviceNotes, facilitationNotes, ...parcel }: Bizly.Parcel,
        saveDates?: boolean
    ) {
        let updatedParcel = { ...parcel };

        const isCopying = parcel.sent;
        const newSubject = isCopying ? `Copy of ${parcel.subject}` : parcel.subject;
        updatedParcel = {
            ...updatedParcel,
            subject: newSubject,
            name: newSubject,
            surveyTitle: newSubject,
        };

        if (!saveDates) {
            const { startDate, startTime, endDate, endTime, ...filteredParcel } = updatedParcel;
            updatedParcel = { ...filteredParcel };
        }

        return updatedParcel;
    }

    async function handleSaveClick({ redirect = true, saveDates }: { redirect: boolean; saveDates?: boolean }) {
        const {
            event: { id: eventId },
        } = props;
        const { parcel, recipients } = state;

        const successUrl = `/event/${eventId}/communication`;
        const parcelIdNew = parcelId || parcel.id;

        const mergedParcel = preSaveFormat(
            {
                ...parcel,
                recipients,
            },
            saveDates
        );

        setState({
            ...state,
            pending: true,
        });

        try {
            const shouldCreateNew = parcel.sent || !parcelIdNew;
            const method = shouldCreateNew ? createParcel : updateParcel;

            try {
                const res =
                    parcel.sent && parcel.id ? await copyParcel(parcel.id) : await method(eventId, mergedParcel);

                if (res.success) {
                    parcelIdRef.current = res.parcel.id;
                }
            } catch (error) {
                console.error(`Error updating or creating parcel: ${error}`);
            }

            setUnsavedForm(false);

            if (redirect) {
                const message = shouldCreateNew ? 'Created' : 'Saved';
                enqueueSnackbar(`${capitalize(type)} ${message}`, {
                    variant: 'info',
                });
                history.push(successUrl);
            }
        } catch (e) {
            enqueueSnackbar(`Error creating ${capitalize(type)}!`, {
                variant: 'error',
            });
            setState({
                ...state,
                pending: false,
            });
        }
    }

    async function preSend() {
        async function scrubAttendanceTypeQ() {
            const questions = await loadQuestions(parcel.id);
            const attendanceTypeQ = questions.find((q: any) => q.type === 'attendanceType');
            if (attendanceTypeQ) {
                await deleteQuestion(parcel.id, attendanceTypeQ.id);
            }
        }

        if (!toggles.gate.useNewPlanner) {
            await scrubAttendanceTypeQ();
        }
    }

    async function handleSendClick() {
        const {
            event: { id: eventId, attendeeCounts },

            updateAttendeesCount,
        } = props;
        const { attendees, recipients } = state;
        const successUrl = `/event/${eventId}/communication`;

        try {
            await handleSaveClick({ redirect: false, saveDates: true });
            await preSend();
            await sendParcel(eventId, parcelId || parcelIdRef.current);

            if (type === 'invite') {
                const newReciptientCount = attendees.filter(
                    ({ id, status }) => status === 'not sent' && recipients.includes(id)
                ).length;

                const updatedAttendeeCounts = {
                    ...attendeeCounts,
                    notSent: attendeeCounts.notSent - newReciptientCount,
                    invited: attendeeCounts.invited + newReciptientCount,
                };
                updateAttendeesCount(updatedAttendeeCounts);
            }
            enqueueSnackbar(`${capitalize(type)} Sent`, { variant: 'info' });
            history.push(successUrl);
        } catch (error) {
            enqueueSnackbar(error, {
                variant: 'error',
            });

            setState({
                ...state,
                pending: false,
            });
        }
    }

    const showPreview = () => setState({ ...state, showPreview: true });
    const hidePreview = () => setState({ ...state, showPreview: false });

    function renderPreview() {
        let { event } = props;
        let { parcel, plannerSchedule } = state;

        // destructuring defaults don't work for null values
        // (these values are initially null)
        event = event || {};
        parcel = parcel || {};

        const { startDate, startTime, endDate, endTime } = parcel;
        const parcelDates = startDate && startTime && endDate && endTime && { startDate, startTime, endDate, endTime };

        return (
            <BizlyThemeOverride>
                <EditParcelPreview
                    isNote={type === 'note'}
                    onClose={hidePreview}
                    name={event.name}
                    startsAt={event.startsAt}
                    endsAt={event.endsAt}
                    image={parcel.customImage?.url}
                    headline={parcel.headline}
                    description={parcel.content}
                    plannerSchedule={plannerSchedule}
                    plannedBy={event.plannedBy}
                    parcelDates={toggles.gate.useParcelDates && !!parcelDates ? parcelDates : undefined}
                    useParcelDates={!!parcelDates || (toggles.gate.useParcelDates && !parcel.sent)}
                    location={parcel.locationName}
                    address={parcel.locationAddress}
                    cityState={parcel.locationCityState}
                    vmSharingMethod={parcel.vmSharingMethod}
                    virtualMeeting={event.virtualMeeting}
                    {...(toggles.gate.surveysEnabled ? { hasQuestions } : {})}
                    timezone={parcel.sent ? parcel.timeZone : event.timeZone}
                />
            </BizlyThemeOverride>
        );
    }

    const { attendees, parcel, pending, recipients, showPreview: showPreviewState } = state;

    const editParcelFields = useMemo(
        () => defaultEditParcelFields({ parcelStartDate: parcel.startDate ? parseISO(parcel.startDate) : null }),
        [parcel.startDate]
    );

    const eventId = event.id;
    const copy = {
        oldSubheader: {
            // Delete after Surveys feature is released
            invite:
                'Your invite will be sent as an email with buttons for guests to select ‘attending’ or ‘not attending’. If they select ‘attending’, they’ll be linked to a form where they can provide additional details. Customize the email and the form below.',
            note: 'Your note will be sent as an email, which can be customized below.',
        },
        subheader: {
            invite:
                'Your invite will be sent as an email with buttons for guests to select ‘attending’ or ‘not attending’. If they select ‘attending’, they’ll be linked to a form where they can provide additional details. Customize the email and the form below.',
            note: 'Create a simple branded message. You can include a customized survey below.',
        },
        title: {
            invite: 'Invite Name',
            note: 'Note Name',
        },
    };

    if (showPreviewState) {
        return renderPreview();
    }

    if (!parcelId) {
        // we must wait for the initial load to get the attendees and generate the initial parcel object
        return initialLoad.current === false || pending ? (
            <SpinnerOverlay />
        ) : (
            <CreateParcel eventId={event.id} parcel={parcel} type={type} />
        );
    }

    return (
        <Column style={{ width: '100%' }}>
            {unsavedPrompt()}
            <StickyHeader pageOffset={SIDE_NAV_WIDTH}>
                <Row
                    style={{
                        justifyContent: 'flex-end',
                    }}
                >
                    {!parcel.sent && event.editable && (
                        <Button
                            disabled={pending}
                            variant="outlined"
                            style={{ marginRight: '16px' }}
                            onClick={() => {
                                handleSaveClick({ redirect: true });
                            }}
                        >
                            Save
                        </Button>
                    )}
                    <Button disabled={pending} variant="outlined" style={{ marginRight: '16px' }} onClick={showPreview}>
                        Preview
                    </Button>
                    {!parcel.sent && event.editable && (
                        <Button
                            disabled={!isSendable(parcel, type, toggles.gate.useParcelDates)}
                            onClick={async () => {
                                await handleSendClick();
                            }}
                        >
                            Send
                        </Button>
                    )}
                    {parcel.sent && event.editable && (
                        <Button onClick={() => handleSaveClick({ redirect: true })}>Copy</Button>
                    )}
                </Row>
            </StickyHeader>
            {pending || !parcel ? (
                <Spinner />
            ) : (
                <Centered>
                    {toggles.gate.surveysEnabled ? (
                        <>
                            <H5Headline>
                                {parcelId ? 'Edit ' : type === 'invite' ? 'Create an ' : 'Create a '}
                                {type === 'invite' ? 'Invite' : 'Note/Survey'}
                            </H5Headline>
                            <Subheader>{copy.subheader[type]}</Subheader>
                        </>
                    ) : (
                        <>
                            <LargeHeadline
                                css={css`
                                    text-transform: none;
                                `}
                            >
                                {parcelId ? 'Edit' : type === 'invite' ? 'Create an' : 'Create a'} {type}
                            </LargeHeadline>
                            <Copy>{copy.oldSubheader[type]}</Copy>
                        </>
                    )}
                    <Spacer />
                    <Spacer />

                    {parcel.sent && parcel.name && parcel.name !== parcel.subject && (
                        <>
                            <PromptedTextField
                                onChange={e => handleChange(e.target.value, 'name')}
                                disabled
                                prompt={copy.title[type]}
                                value={parcel.name}
                                variant="outlined"
                            />
                            <Spacer />
                        </>
                    )}

                    <FormLabel style={{ marginBottom: '16px' }}>Recipients</FormLabel>
                    <OptionList
                        eventId={eventId}
                        disableAll={parcel.sent || !event.editable}
                        prompt="Who would you like to send this to?"
                        onChange={(selection: number[]) => {
                            setState({ ...state, recipients: selection }); // We track recipients seperately from the parcel, to maintain an up to date list.
                            handleChange(selection, 'recipients');
                        }}
                        optionFormatter={(attendee: BizlyAPI.Attendee) =>
                            attendee.firstName && attendee.lastName
                                ? `${attendee.firstName} ${attendee.lastName} - ${attendee.email}`
                                : attendee.email
                        }
                        options={attendees}
                        optionsLabel="Attendees"
                        selected={parcel.recipients || recipients}
                    />
                    <Spacer />
                    <PromptedTextField
                        onChange={e => handleChange(e.target.value, 'subject')}
                        disabled={parcel.sent || !event.editable}
                        placeholder="Enter a subject line for the email"
                        prompt="Subject"
                        value={parcel.subject}
                        variant="outlined"
                    />
                    <Spacer />
                    {toggles.gate.surveysEnabled && type === 'note' && (
                        <>
                            <PromptedTextField
                                onChange={e => handleChange(e.target.value, 'headline')}
                                disabled={parcel.sent || !event.editable}
                                placeholder="Enter a message title for the email"
                                prompt="Headline"
                                value={parcel.headline}
                                variant="outlined"
                                charLimit={45}
                                optional
                            />
                            <Spacer />
                        </>
                    )}

                    {toggles.gate.useRichText ? (
                        <Prompted prompt={'Message'}>
                            <RichTextEditor
                                field="content"
                                disabled={parcel.sent || !event.editable}
                                placeholder="Include a message in the email"
                                value={parcel.content}
                                onChange={e => handleChange(e.value, 'content')}
                            />
                        </Prompted>
                    ) : (
                        <PromptedTextArea
                            onChange={e => handleChange(e.target.value, 'content')}
                            disabled={parcel.sent || !event.editable}
                            placeholder="Include a message in the email"
                            prompt="Message"
                            value={parcel.content}
                            variant="outlined"
                        />
                    )}

                    <Spacer />
                    {(!parcel.sent || parcel.customImage) && (
                        <>
                            <FormLabel style={{ marginBottom: '16px' }}>Image</FormLabel>
                            <Uploader
                                disabled={parcel.sent || !event.editable}
                                ctaLabel="Add Image"
                                description="Upload a JPG or PNG file (under 2MB, minimum 680 x 400px). Your team color will be used if no custom image is uploaded."
                                onChange={(newValue: any) => {
                                    handleChange(newValue?.url, 'customImageUrl');
                                    handleChange(newValue, 'customImage');
                                }}
                                prompt="Add a custom image to your invite."
                                resource={isEmpty(parcel.customImage) ? null : parcel.customImage}
                            />
                            <Spacer />
                        </>
                    )}
                    {type === 'invite' && (
                        <>
                            {toggles.gate.useParcelDates && (
                                <Form
                                    fields={editParcelFields}
                                    schema={editParcelSchemaWithTz}
                                    value={{ ...parcel, timeZone: parcel.sent ? parcel.timeZone : event.timeZone }}
                                    onChange={(e: any) => handleChange(e.value[e.field], e.field)}
                                    disabled={parcel.sent || !event.editable}
                                />
                            )}
                            <Spacer />
                            <PromptedTextField
                                onChange={e => handleChange(e.target.value, 'locationName')}
                                disabled={parcel.sent || !event.editable}
                                placeholder="Venue Name"
                                optional
                                prompt="Venue"
                                value={parcel.locationName}
                                variant="outlined"
                            />
                            <TextField
                                onChange={e => handleChange(e.target.value, 'locationAddress')}
                                disabled={parcel.sent || !event.editable}
                                placeholder="Street Address"
                                value={parcel.locationAddress}
                                variant="outlined"
                                fullWidth={true}
                            />
                            <TextField
                                onChange={e => handleChange(e.target.value, 'locationCityState')}
                                disabled={parcel.sent || !event.editable}
                                placeholder="City, State"
                                value={parcel.locationCityState}
                                variant="outlined"
                                fullWidth={true}
                            />
                            {toggles.gate.useNewPlanner && (
                                <>
                                    <Spacer />
                                    <FieldHeader>Virtual Meeting</FieldHeader>
                                    <Spacer xsmall />
                                    {event.virtualMeeting ? (
                                        <SelectField
                                            field="vmSharingMethod"
                                            value={vmSharingOptions.find(
                                                option => option.id === parcel.vmSharingMethod
                                            )}
                                            readonly={false}
                                            disabled={parcel.sent || !event.editable}
                                            onChange={(e: any) => handleChange(e.value.target.value, 'vmSharingMethod')}
                                            options={vmSharingOptions}
                                        />
                                    ) : (
                                        <>
                                            <Spacer xsmall />
                                            <Copy>No Virtual Meetings have been setup in Agenda.</Copy>
                                        </>
                                    )}
                                </>
                            )}
                            <Spacer />
                            {parcel.id && type === 'invite' && (
                                <>
                                    <Headline>Customize RSVP Form</Headline>
                                    <Copy>
                                        Customize the fields on your RSVP form to collect information from your
                                        attendees. Responses are only visible to you and collaborators.
                                    </Copy>
                                    <Spacer />
                                    <Divider />
                                    <Spacer />
                                    <Questions
                                        disabled={parcel.sent || !event.editable}
                                        parcelId={parcel.id}
                                        hideAttendanceType={!toggles.gate.useNewPlanner}
                                        hasLinearScale={toggles.gate.surveysEnabled}
                                    />
                                </>
                            )}
                        </>
                    )}
                    {toggles.gate.surveysEnabled && type === 'note' && (
                        <>
                            <Divider />
                            <SurveySection
                                event={event}
                                parcel={parcel}
                                attendees={attendees}
                                handleChange={handleChange}
                                editable={event.editable}
                                hasQuestions={hasQuestions}
                                setHasQuestions={setHasQuestions}
                            />
                        </>
                    )}
                </Centered>
            )}
        </Column>
    );
}
