import { css } from 'styled-components';

import colorFns from 'colorFns';

const HEADER_HEIGHT = 70;
const HEADER_DATES = 70;
export const HEADER_BORDER = 1;
const SLOT_HEIGHT = 70;
export const LABEL_OFFSET = 5;
export const TIME_GUTTER_WIDTH = 50;
const TIME_GUTTER_PADDING = 14;
const SLOT_MARGIN_RIGHT_WEEK = 0;
const SLOT_MARGIN_RIGHT_DAY = '0%';
export const BLOCK_PADDING = 12;

export const BACKDROP_Z_INDEX = 1000;
export const BLOCK_Z_INDEX = 1100;
export const POPPER_Z_INDEX = 1300;

/*
    Header
*/

const WeekViewHeaderContent = css`
    .rbc-time-header-cell {
        min-height: ${HEADER_DATES}px;
        .rbc-header {
            text-overflow: unset;
            white-space: normal;
            padding-top: 12px;
            border-bottom: 0;

            a,
            span {
                display: inline-block;
            }

            span {
                white-space: pre;

                font-size: 12px;
                line-height: 1.4;
                font-weight: 700;
                text-transform: uppercase;

                color: ${colorFns.pureWhite};
                &:hover {
                    font-weight: 600;
                }

                &::first-line {
                    font-size: 16px;
                }
            }

            &.rbc-today span {
                font-weight: 900;
                color: ${colorFns.secondaryTextAction};
            }
        }
    }

    .rbc-allday-cell {
        max-height: ${HEADER_HEIGHT - HEADER_DATES}px;

        .rbc-row-content {
            display: none; /* don't block clicks */
        }
    }
`;

const HideHeaderOverflowScrollbarBorder = css`
    .rbc-time-header.rbc-overflowing {
        border-right: 0;
    }
`;

const FadeTimeGutterTimesBackground = css``;

const Header = css`
    .rbc-time-header {
        left: 0;
        right: 0;

        z-index: 100;

        .rbc-time-header-content {
            height: ${HEADER_HEIGHT}px;

            border-bottom: ${HEADER_BORDER}px solid #ddd;

            background-color: ${colorFns.primaryAction};
        }
    }

    ${HideHeaderOverflowScrollbarBorder};

    ${FadeTimeGutterTimesBackground};

    ${WeekViewHeaderContent};
`;

/*
    Time View day/week view excluding header
*/

const TimeView = css`
    .rbc-time-view {
        border: 0;
    }

    .rbc-time-content {
        border-top: 0;

        ${({ animateScroll }: { animateScroll?: boolean }) =>
            animateScroll
                ? `
        scroll-behavior: smooth;
            `
                : ''}
    }
`;

/*
    Time Gutter (on the left in day/week view)
*/

const TimeGutterNoRightBorder = css`
    .rbc-time-header .rbc-time-header-content {
        border-left: 0;
        margin-left: 1px;
    }

    .rbc-day-slot {
        border-left: 1px solid #ddd;
    }

    .rbc-time-gutter + .rbc-day-slot {
        border-left-color: transparent;
    }
`;

const TimeGutter = css`
    .rbc-time-gutter {
        min-width: ${TIME_GUTTER_WIDTH}px;

        .rbc-timeslot-group {
            border: 0;
            min-height: ${SLOT_HEIGHT}px;
        }

        .rbc-label {
            transform: translateY(calc(-50% - 1px));
            display: block;

            font-size: 12px;
            line-height: 12px;
            color: ${colorFns.darkGrey};

            padding: 0 ${TIME_GUTTER_PADDING}px 0 0;
            text-align: right;
        }
    }

    ${TimeGutterNoRightBorder}
`;

/*
    Day/Week Columns
*/

const RightMarginDayWeekColumn = css`
    /* day */
    .rbc-day-slot:last-child {
        .rbc-events-container {
            margin-right: ${SLOT_MARGIN_RIGHT_DAY};
        }
        .rbc-slot-selection {
            width: calc(100% - ${SLOT_MARGIN_RIGHT_DAY});
        }
    }

    /* week */
    .rbc-day-slot + .rbc-day-slot {
        .rbc-events-container {
            margin-right: ${SLOT_MARGIN_RIGHT_WEEK}px;
        }
        .rbc-slot-selection {
            width: calc(100% - ${SLOT_MARGIN_RIGHT_WEEK}px);
        }
    }
`;

const WeekColumn = css`
    .rbc-day-slot {
        border-bottom: 1px solid #ddd;
    }

    ${RightMarginDayWeekColumn}
`;

/*
    Slots
*/

const SlotRangeSelectionDisplay = css`
    .rbc-day-slot .rbc-slot-selection {
        z-index: 10;
        position: absolute;
        left: -1px;

        font-size: 12px;
        font-weight: 700;
        color: ${colorFns.pureBlack};

        background-color: ${colorFns.listItemBackground};
        border: 1px solid ${colorFns.softBorder};
        border-radius: 8px;

        padding: ${BLOCK_PADDING}px;
    }
`;

const RemoveExtraBorders = css`
    .rbc-day-slot {
        > .rbc-events-container,
        > .rbc-timeslot-group {
            border-left: 0;
            border-bottom: 0;
        }

        > .rbc-timeslot-group {
            > .rbc-time-slot:not(:first-child) {
                border-top: 0;
            }
        }
    }
`;

// normally, the top time value in the time gutter would be cut off due to overflow: auto
// we adjust all slots a bit to have it show:
const OffsetSlotsForLabelOverflow = css`
    .rbc-time-header {
        margin-bottom: -${LABEL_OFFSET + HEADER_BORDER}px;
    }

    .rbc-time-gutter,
    .rbc-day-slot {
        .rbc-timeslot-group {
            min-height: ${SLOT_HEIGHT - LABEL_OFFSET}px;
            margin-top: ${LABEL_OFFSET}px;
        }
    }

    .rbc-day-slot {
        .rbc-events-container > div {
            margin-top: ${LABEL_OFFSET}px;
        }

        .rbc-slot-selection {
            margin-top: ${LABEL_OFFSET}px;
        }
    }
`;

const CurrentTime = css`
    .rbc-current-time-indicator {
        background-color: ${colorFns.strongAccentedBackground};
    }
`;

const Slots = css`
    .rbc-day-slot {
        .rbc-timeslot-group {
            min-height: ${SLOT_HEIGHT}px;
        }

        .rbc-time-slot {
            border-top: 1px solid #ddd;
        }
    }

    ${SlotRangeSelectionDisplay}

    ${RemoveExtraBorders};

    ${OffsetSlotsForLabelOverflow};

    ${CurrentTime};
`;

const NoShadedToday = css`
    .rbc-today {
        background-color: unset;
    }
`;

export default {
    Header,
    TimeView,
    TimeGutter,
    WeekColumn,
    Slots,
    NoShadedToday,
};
