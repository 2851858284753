import styled from 'styled-components';

import Button from 'components/ui/Button';

const SmallCircleButton = styled(Button).attrs(({ secondary }: { secondary?: boolean; small?: boolean }) => ({
    secondary: secondary === undefined ? true : secondary,
}))`
    display: inline-flex;
    white-space: nowrap;
    padding: 0;
    width: 28px;
    height: 28px;
    box-sizing: border-box;

    border-radius: 50%;
    min-width: 0;
    align-items: center;
    justify-content: center;

    ${({ small }) =>
        small
            ? `
    width: 16px;
    height: 16px;
    `
            : ``}
`;

export const LargeCircleButton = styled(SmallCircleButton)`
    width: 48px;
    height: 48px;
`;

export default SmallCircleButton;
