import React from 'react';

import { Copy } from '../../ui';

import StepsColumn from './StepsColumn';

type CompleteAndSignProps = {
    number: number;
    copy: string;
};

const defaultCopy = `<p>Fill out the details of your meeting and forward to your venue contact.</p>
<p>A few things to be aware of as you’re reviewing the agreement:</p>
<p>
    <ul>
        <li>Check the dates, times and number of attendees for any meeting space</li>
        <li>Check the dates and number of guest rooms for each day</li>
        <li>
            Review the cancellation section of the agreement so you’re aware of the financial liability
            should you need to cancel
        </li>
        <li>Don’t sign a contract if there’s any uncertainty that the meeting will occur</li>
    </ul>
</p>`;

const CompleteAndSign = ({ number, copy = defaultCopy }: CompleteAndSignProps) => {
    return (
        <StepsColumn number={number} headline={'Complete and sign documents'}>
            <Copy dangerouslySetInnerHTML={{ __html: copy }} />
        </StepsColumn>
    );
};

export default CompleteAndSign;
