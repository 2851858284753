import React from 'react';

import { useAsync } from '../hooks/useAsync';

import { loadEventOptions } from '../api';

type EventOptions = {
    attendeeQuestionDefinitions: Array<any>;
    avOptions: { id: number; name: string; iconUrl: string }[];
    dietaryRestrictionOptions: Array<any>;
    fbOptions: { id: number; name: string; iconUrl: string; diningStyles: Array<{ id: number; name: string }> }[];
    foodAllergyOptions: Array<any>;
    setupOptions: Array<{ id: number; name: string; iconUrl: string }>;
};

const initialState: EventOptions = {
    attendeeQuestionDefinitions: [],
    avOptions: [],
    dietaryRestrictionOptions: [],
    fbOptions: [],
    foodAllergyOptions: [],
    setupOptions: [],
};

export const EventOptionsContext = React.createContext(initialState);

export function useEventOptions() {
    const context = React.useContext(EventOptionsContext);
    if (context === undefined) {
        throw new Error('useEventOptions must be used within a EventOptionsProvider');
    }
    return context;
}

type EventOptionsProviderProps = {
    eventId: string;
    children: React.ReactNode;
};

function EventOptionsProvider({ eventId, children }: EventOptionsProviderProps) {
    const { data: eventOptions } = useAsync(
        React.useCallback(() => loadEventOptions(eventId), [eventId]),
        initialState
    );

    return <EventOptionsContext.Provider value={eventOptions as EventOptions}>{children}</EventOptionsContext.Provider>;
}

export default EventOptionsProvider;
