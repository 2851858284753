import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { isEqual, maxBy } from 'lodash';

import { userTimeZone } from 'utils/moment';
import {
    getScheduleItem,
    addScheduleItem,
    updateScheduleItem,
    deleteScheduleItem,
    createVirtualMeetingFromConnector,
} from 'api';
import { useEvent } from 'providers/event';
import { scheduleActions } from 'stores/schedule';

import { Column as UIColumn, Row } from 'ui';
import Button from 'components/ui/Button';
import TextButton from 'components/ui/Button/TextButton';
import { StickyHeader, stickyHeaderHeight } from 'components/StickyHeader';
import Form from 'components/Form';
import { defaultScheduleFormFields, scheduleFormSchemaWithTz } from 'components/Schedule/scheduleFormSchema';
import { SpinnerOverlay } from 'components/Spinner';
import { useUnsavedPrompt } from 'components/ProposalForm/utils';
import ManageAgenda, { MOVE_UP, MOVE_DOWN, TDirection } from 'components/Schedule/ManageAgenda';
import { PageNotFound } from './PageNotFound';
import ManageVirtualMeeting from 'components/Schedule/ManageVirtualMeeting';
import ConfirmationModal from 'components/ConfirmationModal';
import { useUser } from 'providers/user';

const Container = styled(UIColumn)`
    width: 100%;
    margin-left: 96px;
`;

const Body = styled(Row)`
    margin: ${stickyHeaderHeight} auto;
    padding: 36px 0;
`;

const Column = styled(UIColumn)`
    width: 502px;
`;

export type TNewScheduleItem = Partial<Pick<BizlyAPI.ScheduleItem, 'name' | 'date' | 'startTime' | 'endTime'>>;

type TFormScheduleItem = Partial<BizlyAPI.ScheduleItem & { timeZone: Bizly.Event['timeZone'] }>;

const EditSchedule = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);

    const [scheduleItem, setScheduleItem] = useState<TFormScheduleItem>({});
    const [scheduleSnapshot, setScheduleSnapshot] = useState<TFormScheduleItem>({});
    const [deleteSchedItemIntent, setDeleteSchedItemIntent] = useState<boolean>(false);

    const [editVMIntent, setEditVMIntent] = useState<boolean>(false);
    const [editingVirtualMeeting, setEditingVirtualMeeting] = useState<boolean>(false);

    const { eventId, agendaId } = useParams();
    const { user } = useUser();
    const { event, refreshEvent } = useEvent();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory<TNewScheduleItem>();

    const isNewScheduleItem = useMemo(() => agendaId === 'new', [agendaId]);
    const matchSnapshot = useMemo(() => isEqual(scheduleItem, scheduleSnapshot), [scheduleSnapshot, scheduleItem]);

    const renderUnsavedPrompt = useUnsavedPrompt(
        !matchSnapshot,
        newLocation => newLocation.state?.pathname === history.location.pathname
    );

    const goToSchedule = (updatedItem?: BizlyAPI.ScheduleItem) =>
        history.push(`/event/${event.id}/agenda`, updatedItem);

    const backToSchedulePage = () => {
        const cameFromApp = history.action === 'PUSH';

        if (cameFromApp) history.goBack();
        else goToSchedule();
    };

    useEffect(() => {
        if (!isNewScheduleItem && eventId && agendaId) {
            (async () => {
                setIsLoading(true);
                try {
                    const { scheduleItem: scheduleItemResp } = await getScheduleItem(Number(eventId), Number(agendaId));
                    const initialScheduleItem = { ...scheduleItemResp, timeZone: event.timeZone };
                    setScheduleItem(initialScheduleItem);
                    setScheduleSnapshot(initialScheduleItem);
                } catch (e) {
                    setHasError(true);
                } finally {
                    setIsLoading(false);
                }
            })();
        } else {
            const historySchedItem = history.location.state && { ...history.location.state, timeZone: event.timeZone };
            setScheduleItem(historySchedItem || {});
            setScheduleSnapshot(historySchedItem || {});
        }
    }, [isNewScheduleItem, eventId, event.timeZone, agendaId, history]);

    const handleUpdateScheduleItem = ({ value }: { value: BizlyAPI.ScheduleBlock }) => {
        setScheduleItem(prevScheduleItem => ({
            ...prevScheduleItem,
            ...value,
        }));
    };

    const handleAddAgendaEntry = () =>
        setScheduleItem(prevScheduleItem => {
            const { agenda = [] } = prevScheduleItem;
            const maxIndex = maxBy(agenda, ({ id }) => id)?.id || 0; // TOOD: Update this random UUID once the Backend is setup to manage UUIDs on Agenda Entries
            const updatedAgenda = [...agenda, { id: Number(maxIndex) + 1 }];

            return {
                ...prevScheduleItem,
                agenda: updatedAgenda,
            };
        });

    const handleUpdateAgendaEntry = (updatedData: { value: BizlyAPI.ScheduleAgendaEntry }, idx: number) =>
        setScheduleItem(prevScheduleItem => {
            const { agenda = [] } = prevScheduleItem;
            const updatedAgenda = agenda.slice();
            updatedAgenda[idx] = updatedData.value;

            return {
                ...prevScheduleItem,
                agenda: updatedAgenda,
            };
        });

    const handleArrangeAgendaEntry = (targetIdx: number, direction: TDirection) =>
        setScheduleItem(prevScheduleItem => {
            const { agenda = [] } = prevScheduleItem;
            const updatedAgenda = agenda.slice();
            const targetEntry = { ...updatedAgenda[targetIdx] };

            if (direction === MOVE_UP && !!updatedAgenda[targetIdx - 1]) {
                const entryBefore = { ...updatedAgenda[targetIdx - 1] };
                updatedAgenda[targetIdx - 1] = targetEntry;
                updatedAgenda[targetIdx] = entryBefore;
            } else if (direction === MOVE_DOWN && !!updatedAgenda[targetIdx + 1]) {
                const entryAfter = { ...updatedAgenda[targetIdx + 1] };
                updatedAgenda[targetIdx + 1] = targetEntry;
                updatedAgenda[targetIdx] = entryAfter;
            }

            return {
                ...prevScheduleItem,
                agenda: updatedAgenda,
            };
        });

    const handleRemoveAgendaEntry = (targetIdx: number) => {
        setScheduleItem(prevScheduleItem => {
            const { agenda = [] } = prevScheduleItem;
            const updatedAgenda = agenda.filter((entry, idx) => idx !== targetIdx);

            return {
                ...prevScheduleItem,
                agenda: updatedAgenda,
            };
        });
    };

    const handleAddScheduleItem = async () => {
        if (eventId) {
            const invalidAgenda = scheduleItem.agenda?.some(entry => !entry.title?.length);
            if (scheduleItem.agenda?.length && invalidAgenda) {
                return enqueueSnackbar(`Agenda items require a title.`, { variant: 'error' });
            }

            setIsLoading(true);
            try {
                const { scheduleItem: newScheduleItem } = await addScheduleItem(Number(eventId), {
                    ...scheduleItem,
                    addToInquiry: false,
                } as BizlyAPI.ScheduleBlock);
                setScheduleSnapshot(scheduleItem);

                scheduleActions.mergeItem(Number(eventId), newScheduleItem);
                history.replace(`/event/${event.id}/agenda/${newScheduleItem.id}`);
                goToSchedule(newScheduleItem);
            } catch {
                setIsLoading(false);
                enqueueSnackbar(`Something went wrong. Unable to create this item.`, { variant: 'error' });
            }
        }
    };

    const onAddVirtualMeeting = useCallback(
        async (service?: string) => {
            if (event.virtualMeeting?.id) {
                return setScheduleItem(prevSchedule => ({
                    ...prevSchedule,
                    virtualMeeting: event.virtualMeeting,
                }));
            }

            if (service) {
                try {
                    const { virtualMeeting: newVirtualMeeting } = await createVirtualMeetingFromConnector(event.id, {
                        connectorType: service,
                        startsAt: event.startsAt || `${scheduleItem.date} ${scheduleItem.startTime}`,
                        endsAt: event.endsAt || `${scheduleItem.date} ${scheduleItem.endTime}`,
                        timeZone: event.timeZone || userTimeZone,
                    });
                    refreshEvent();
                    return setScheduleItem(prevSchedule => ({
                        ...prevSchedule,
                        virtualMeeting: newVirtualMeeting,
                    }));
                } catch {
                    return enqueueSnackbar(`Something went wrong. Unable to create this item.`, {
                        variant: 'error',
                    });
                }
            }

            return setEditingVirtualMeeting(true);
        },
        [event, enqueueSnackbar, refreshEvent, scheduleItem]
    );

    const startEditingVirtualMeeting = useCallback(() => {
        if (!event.virtualMeeting) {
            setEditingVirtualMeeting(true);
        } else {
            setEditVMIntent(true);
        }
    }, [event.virtualMeeting]);

    const handleSaveScheduleItem = async () => {
        if (eventId && agendaId) {
            const invalidAgenda = scheduleItem.agenda?.some(entry => !entry.title?.length);
            if (scheduleItem.agenda?.length && invalidAgenda) {
                return enqueueSnackbar(`Agenda items require a title.`, { variant: 'error' });
            }

            try {
                const { scheduleItem: updatedItem } = await updateScheduleItem(
                    Number(eventId),
                    Number(agendaId),
                    scheduleItem
                );
                setScheduleSnapshot(scheduleItem);

                scheduleActions.mergeItem(Number(eventId), updatedItem);
                goToSchedule(updatedItem);
            } catch {
                enqueueSnackbar(`Something went wrong. Unable to save this item.`, { variant: 'error' });
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleDeleteScheduleItem = async () => {
        if (eventId && agendaId) {
            try {
                await deleteScheduleItem(Number(eventId), Number(agendaId));
                setScheduleSnapshot(scheduleItem);

                scheduleActions.remove(Number(eventId), Number(agendaId));
                history.replace(`/event/${event.id}/agenda`);
                backToSchedulePage();
            } catch {
                enqueueSnackbar(`Something went wrong. Unable to delete this item.`, { variant: 'error' });
                setDeleteSchedItemIntent(false);
            }
        }
    };

    const refreshScheduleItemVM = (virtualMeeting: Bizly.VirtualMeeting) => {
        setScheduleItem(prevScheduleItem => ({
            ...prevScheduleItem,
            virtualMeeting,
        }));
    };

    const scheduleFormFields = useMemo(
        () =>
            defaultScheduleFormFields({
                schedItemDate: scheduleItem.date,
                integratedVmServices: event.virtualMeeting?.id
                    ? []
                    : user?.connectors?.map(connector => connector.type),
                vmOnAdd: onAddVirtualMeeting,
                vmOnEdit: startEditingVirtualMeeting,
                vmOnDelete: () => {
                    setScheduleItem(prevSchedule => {
                        return {
                            ...prevSchedule,
                            virtualMeeting: null,
                        };
                    });
                },
            }),
        [scheduleItem.date, user, event.virtualMeeting, onAddVirtualMeeting, startEditingVirtualMeeting]
    );
    return hasError ? (
        <PageNotFound />
    ) : (
        <Container>
            {renderUnsavedPrompt()}
            <ConfirmationModal
                warning
                headline="Are you sure?"
                onDismiss={() => setDeleteSchedItemIntent(false)}
                onProceed={handleDeleteScheduleItem}
                prompt="Deleting this item will remove it from your meeting. This process cannot be undone."
                isActive={deleteSchedItemIntent}
            />
            <ConfirmationModal
                headline="Edit Virtual Meeting"
                onDismiss={() => setEditVMIntent(false)}
                onProceed={() => {
                    setEditingVirtualMeeting(true);
                    setEditVMIntent(false);
                }}
                prompt="Editing your virtual meeting will affect changes to all other activities with virtual meetings. Do you still want to continue to edit your virtual meeting details?"
                isActive={!editingVirtualMeeting && editVMIntent}
            />
            <StickyHeader>
                <Button onClick={backToSchedulePage} variant="outlined" secondary>
                    Cancel
                </Button>
                <Row itemSpacing="smallish" justifyContent="flex-end" alignItems="center">
                    {!isNewScheduleItem ? (
                        <>
                            <TextButton
                                onClick={() => setDeleteSchedItemIntent(true)}
                                disabled={!event.editable || isLoading}
                            >
                                Delete
                            </TextButton>
                            <Button
                                onClick={handleSaveScheduleItem}
                                disabled={!event.editable || matchSnapshot || isLoading}
                            >
                                Save
                            </Button>
                        </>
                    ) : (
                        <Button onClick={handleAddScheduleItem} disabled={!event.editable || isLoading}>
                            Add
                        </Button>
                    )}
                </Row>
            </StickyHeader>

            {isLoading && <SpinnerOverlay />}

            <Body itemSpacing="larger">
                {!isLoading && (
                    <>
                        <Column>
                            <Form
                                fields={scheduleFormFields}
                                schema={scheduleFormSchemaWithTz}
                                value={scheduleItem}
                                disabled={!event.editable}
                                onChange={handleUpdateScheduleItem}
                            />
                        </Column>
                        <Column>
                            {editingVirtualMeeting ? (
                                <ManageVirtualMeeting
                                    onSave={refreshScheduleItemVM}
                                    onClose={() => setEditingVirtualMeeting(false)}
                                />
                            ) : (
                                <ManageAgenda
                                    agendaEntries={scheduleItem?.agenda || []}
                                    addAgendaEntry={handleAddAgendaEntry}
                                    updateAgendaEntry={handleUpdateAgendaEntry}
                                    arrangeAgendaEntry={handleArrangeAgendaEntry}
                                    removeAgendaEntry={handleRemoveAgendaEntry}
                                    disabled={!event.editable}
                                />
                            )}
                        </Column>
                    </>
                )}
            </Body>
        </Container>
    );
};

export default EditSchedule;
