import createStore from '../';
import keyBy from 'lodash/keyBy';

import { createContactGroup, getContactGroups, updateContactGroup } from 'api/contacts';
import { contactsApi } from 'stores/contacts';

type State = {
    loading: boolean;
    updating: boolean;
} & (
    | {
          loaded: false;
          groups: undefined;
      }
    | {
          loaded: true;
          groups: BizlyAPI.ContactGroup[];
      }
);
type Store = State;

const initialState: State = {
    loading: false,
    loaded: false,
    groups: undefined,
    updating: false,
};

export const [useContactGroups, contactGroupsApi] = createStore<Store>(() => initialState);

const { setState, getState } = contactGroupsApi;

export const contactGroupsActions = {
    load: async () => {
        setState({
            ...getState(),
            loading: true,
        });

        try {
            const { groups } = await getContactGroups();

            setState({
                loaded: true,
                loading: false,
                groups,
            });

            return groups;
        } catch (e) {
            setState({
                loading: false,
            });
            throw e;
        }
    },

    create: async (name: string) => {
        if (!getState().loaded) return;
        if (getState().updating) return;

        setState({
            ...getState(),
            updating: true,
        });

        try {
            const { group } = await createContactGroup({ name });

            setState({
                updating: false,
                groups: [...(getState().groups ?? []), group],
            });

            return group;
        } catch (e) {
            setState({
                updating: false,
            });
            throw e;
        }
    },

    update: async (updateGroup: BizlyAPI.ContactGroup) => {
        if (!getState().loaded) return;
        if (getState().updating) return;

        setState({
            ...getState(),
            updating: true,
        });

        try {
            const { group } = await updateContactGroup(updateGroup);

            setState({
                updating: false,
                groups: [...(getState().groups ?? []).filter(curGroup => curGroup.id !== group.id), group],
            });

            return group;
        } catch (e) {
            setState({
                updating: false,
            });
            throw e;
        }
    },
};

export const hydrateGroup = (group: BizlyAPI.ContactGroup) => {
    const { contacts = [], loaded } = contactsApi.getState();
    if (!loaded) return group as BizlyAPI.ContactGroup & { contacts: undefined };

    const contactsDict = keyBy(contacts, contact => contact.id);

    return { ...group, contacts: group.userIds.map(userId => contactsDict[userId]).filter(contact => contact) };
};
