import React from 'react';
import { css } from 'styled-components/macro';
import { useSnackbar } from 'notistack';

import { useAsync } from '../../hooks/useAsync';
import { useUser } from 'providers/user';

import { loadEventOptions } from 'api';

import { Button, Column, Row } from '../../ui';
import { statusColors, statusMap } from 'shared';

import RespondentModal from './RespondentModal';
import AttendeeForm from './AttendeeForm';

type TAttendeeModal = {
    attendee: BizlyAPI.Attendee;
    onDismiss: () => void;

    onSave: (attendee: BizlyAPI.Attendee) => void;
    eventId: number | string;

    editable?: boolean;
};

export const AttendeeModal = ({ attendee, onDismiss, onSave: onSaveProp, eventId, editable }: TAttendeeModal) => {
    const { enqueueSnackbar } = useSnackbar();

    const [isEditing, setIsEditing] = React.useState(false);
    const [draft, setDraft] = React.useState(attendee);
    const [attendeeFormError, setAttendeeFormError] = React.useState(false);

    const { user } = useUser();

    const { data: eventOptions } = useAsync(React.useCallback(() => loadEventOptions(eventId), [eventId]));

    const { firstName, lastName, status, ...respondentBase } = draft;

    function phoneNumberValid(phoneNumber: string) {
        return phoneNumber.length === 12;
    }

    function onSave(draft: BizlyAPI.Attendee) {
        if (!phoneNumberValid(draft.phone || '')) {
            setDraft({ ...draft, phone: attendee.phone });
            enqueueSnackbar('Please enter a valid phone number', { variant: 'error' });
            setAttendeeFormError(true);
            return;
        }

        onSaveProp(draft);
    }

    const respondent = {
        ...respondentBase,
        name: [firstName, lastName].join(' ') || 'Guest',
        status: statusMap[status],
        statusColor: statusColors[status],
    };
    return (
        <RespondentModal
            respondent={respondent}
            content={
                <Column>
                    <AttendeeForm attendee={attendee} eventOptions={eventOptions || {}} readonly />
                </Column>
            }
            width={680}
            onDismiss={onDismiss}
            {...(editable ? { onEdit: () => setIsEditing(true) } : {})}
            isEditing={isEditing}
            editingContent={
                <>
                    <AttendeeForm
                        attendee={draft}
                        eventOptions={eventOptions || {}}
                        externalIdFieldLabel={user.team?.externalIdFieldLabel}
                        error={attendeeFormError}
                        onChange={newVal => {
                            setAttendeeFormError(false);
                            setDraft(newVal);
                        }}
                    />
                    <Row
                        css={css`
                            justify-content: flex-end;
                        `}
                    >
                        <Button
                            style={{ marginRight: '16px' }}
                            variant="outlined"
                            onClick={() => {
                                setDraft(attendee);
                                setIsEditing(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button disabled={attendeeFormError} onClick={() => onSave(draft)}>
                            Save
                        </Button>
                    </Row>
                </>
            }
        />
    );
};

export default AttendeeModal;
