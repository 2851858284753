import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import { EColors } from 'theme';
import colorFns from 'colorFns';
import { useUser } from 'providers/user';

import { Column, Copy, Row } from 'ui';
import Button, { ButtonProps } from 'components/ui/Button';
import { H1Headline, H2Headline } from 'components/ui/Headline';

import { ReactComponent as BackArrowIcon } from '../images/icons/back_arrow.svg';

import { StickyHeader as _StickyHeader } from 'components/StickyHeader';

const stickyHeaderHeight = '71px';
const StickyHeader = styled(_StickyHeader)`
    height: ${stickyHeaderHeight};
`;

const StyledColumn = styled(Column)`
    flex-shrink: 0;
`;

const BackButtonWrapper = styled(Button)`
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    align-items: center;
    justify-content: center;
`;

const BackButton = ({ onClick }: { onClick: ButtonProps['onClick'] }) => {
    return (
        <BackButtonWrapper aria-label="navigate-back" onClick={onClick} noBorder width={30} height={30}>
            <BackArrowIcon />
        </BackButtonWrapper>
    );
};

const PageHeader = styled(Column)`
    width: 706px;
    margin: ${stickyHeaderHeight} auto 32px;
    padding-top: 60px;

    ${Copy} {
        margin-top: 16px;

        & a {
            color: ${colorFns.primaryAction};
            text-decoration: underline;
        }
    }
`;

const PolicyContainer = styled(Row)`
    width: 551px;
    margin: 0 auto 5px;
`;

const PolicyCategoryIcon = styled.div`
    flex-shrink: 0;
    width: 101px;
    margin-right: 20px;
    text-align: center;
`;

const Policies = styled.ul`
    padding-left: 20px;
    margin-bottom: 19px;
`;

const Policy = styled.li`
    margin-bottom: 16px;
`;

const HealthSafety = () => {
    const history = useHistory();
    const { user } = useUser();

    const healthSafety = user?.team?.healthAndSafety;
    const healthSafetyCategories = (healthSafety?.categories || []).filter(
        (category: Bizly.HealthAndSafetyCategory) => category?.policies.length > 0
    );

    return (
        <StyledColumn>
            <StickyHeader>
                <BackButton onClick={() => history.goBack()} />
            </StickyHeader>
            <PageHeader>
                <H1Headline>Health and Safety</H1Headline>
                {!!healthSafety?.subHeader && <Copy dangerouslySetInnerHTML={{ __html: healthSafety.subHeader }} />}
            </PageHeader>

            {healthSafetyCategories.map((category: Bizly.HealthAndSafetyCategory) => (
                <PolicyContainer key={category.id}>
                    <PolicyCategoryIcon>
                        <img src={category.iconUrl} alt="" />
                    </PolicyCategoryIcon>
                    <Column>
                        <H2Headline $color={EColors.accentedHeadline}>{category.name}</H2Headline>
                        <Policies>
                            {category.policies.map(policy => (
                                <Policy key={policy.id}>{policy.definition}</Policy>
                            ))}
                        </Policies>
                    </Column>
                </PolicyContainer>
            ))}
        </StyledColumn>
    );
};

export default HealthSafety;
