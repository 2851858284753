import React from 'react';
import styled from 'styled-components/macro';

import { Row, Column } from 'ui';

import { ReactComponent as FileIconSVG } from 'images/icons/file.svg';
import { withIconStyles } from 'shared';

const Bubble = styled.div`
    width: 262px;
    padding: 18px 16px 17px 16px;
    border-radius: 8px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.listItemBackground)};
    font-size: 13px;
`;

const AttachmentText = styled.a`
    font-size: 15px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
`;

type AvatarTypes = {
    imageUrl: string;
    name: string;
};

const Avatar = ({ imageUrl, name }: AvatarTypes) => <img alt={name} src={imageUrl} height="29" width="29" />;

const FileIcon = withIconStyles(FileIconSVG);

type TBaseAttachment = {
    id: number;
    fileName: string;
    url: string;
};

type TMessageBubbleProps = {
    author: {
        firstName: string;
        lastName: string;
        imageUrl: string;
    };
    text?: string;
    attachments?: TBaseAttachment[];
};

const MessageBubble = ({ author, text, attachments }: TMessageBubbleProps) => {
    return (
        <Row itemSpacing="small" alignItems="center">
            <Avatar imageUrl={author.imageUrl} name={[author.firstName, author.lastName].join(' ')} />
            <Bubble>
                <Column itemSpacing="small">
                    {text && <Row>{text}</Row>}
                    {attachments?.map(attachment => (
                        <Row key={attachment.id} alignItems="center">
                            <FileIcon />
                            <AttachmentText href={attachment.url} target="_blank">
                                {attachment.fileName}
                            </AttachmentText>
                        </Row>
                    ))}
                </Column>
            </Bubble>
        </Row>
    );
};

export default MessageBubble;
